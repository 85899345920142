// import { Button, FormControl, MenuItem, Select } from "@mui/base";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import {
  addItem,
  addItemCatagory,
  addItemManufacturar,
  addItemUOM,
  editItem,
  editItemValues,
  geItemCatagory,
  getItemManufacturers,
  getItemType,
  getItemUOM,
} from "../../../../../services/AdminService";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const AddItemModal = (props) => {
  const { t } = useTranslation();
  const { register, handleSubmit, setValue, watch, control } = useForm();
  const { defaultUom } = watch();
  const loginUserDetails = getLoginUserDetails();
  const [priority, setPriority] = useState("0");
  const [itemType, setItemType] = useState();
  const selectPriority = (event) => {
    if (event && event.target) {
      setPriority(event.target.value);
    }
  };
  const itemUOM = useMemo(() => {
    return defaultUom;
  }, [defaultUom]);
  const [activity, setActivity] = useState();
  const [itemCategory, setItemCategory] = useState();
  // const [itemUOM, setItemUOM] = useState();
  const [itemManufacture, setItemManufacture] = useState();

  const [itemTypeList, setItemTypeList] = useState([]);
  const [catagoryList, setCatagoryList] = useState([]);
  const [itemUOMList, setItemUOMList] = useState([]);
  const [itemManufacturersList, setItemManufacturersList] = useState([]);

  const [showAddUomModal, setShowAddUomModal] = useState(false);
  const [showAddManufacturarModal, setShowAddManufacturarModal] =
    useState(false);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [isTangible, setIsTangible] = useState("tangible");

  const orgId = useRef();
  const userId = useRef();

  useEffect(() => {
    userId.current = loginUserDetails.userId;
    orgId.current = loginUserDetails.orgId;
    if (props.showAddModal === true) {
      geItemCatagory(orgId.current).then((response) => {
        // console.log(response)
        setCatagoryList(response.data);
      });
      getItemUOM(orgId.current).then((response) => {
        setItemUOMList(response.data);
      });

      getItemManufacturers(orgId.current).then((response) => {
        setItemManufacturersList(response.data);
      });
      setValue("itemId", "");

      if (props.from === "admin") {
        setValue("verified", "Y");
      } else {
        setValue("verified", "P");
      }

      if (props.type) {
        setItemType(props.type);
        setValue("type", props.type);
      }

      // if (props.data) {
      //   console.log("props data found", props.data);
      //   setCatagory(props.data.category);
      //   setItemUOM(props.data.defaultUom);
      //   setItemManufacturer(props.data.manufacturer);

      //   setValue("itemId", props.data.itemId);
      //   setValue("itemName", props.data.itemName);
      //   setValue("hsnCd", props.data.hsnCd);
      //   setValue("expirable", props.data.expirable === "Y" ? true : false);
      //   setExpirable(props.data.expirable === "Y" ? true : false);
      //   setValue("category", props.data.category);
      //   // setValue("expirable", props.data.expirable)
      //   setValue("defaultUom", props.data.defaultUom);
      //   setValue("manufacturer", props.data.manufacturer);
      //   setValue("itemDescription", props.data.itemDescription);
      //   getItemType(props.data.category, orgId.current).then((response) => {
      //     setItemTypeList(response.data);
      //     setItemType(props.data.type);
      //     setValue("type", props.data.type);
      //   });
      // }
    } else {
      // setCatagoryList([]);
      // setCatagory("");
    }
  }, []);

  const onSubmit = (data) => {
    console.log(data);
    const {
      category,
      defaultUom,
      expirable,
      hsnCd,
      itemName,
      itemId,
      type,
      manufacturer,
      verified,
      itemDescription,
      guarantee,
    } = data;
    // return
    // if (itemId.trim() === "") {
    //     toast.error(`Please Provide itemId`, {
    //         position: toast.POSITION.TOP_RIGHT,
    //     });
    // }
    if (itemName.trim() === "") {
      toast.error(t("please_provide_item_name") , {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (itemDescription.trim() === "") {
      toast.error(t("please_provide_item_description"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (category.trim() === "") {
      toast.error(t("please_provide_category") , {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (type === "") {
      toast.error(t("please_provide_item_type") , {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (defaultUom.trim() === "") {
      toast.error(t("please_provide_uom"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (type !== "services" && manufacturer.trim() === "") {
      toast.error(t("please_provide_item_manufacturer") , {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const reqDto = {
        itemId: 0,
        itemName,
        category,
        type: props.type,
        defaultUom,
        hsnCd,
        manufacturer,
        expirable: expirable === true ? "Y" : "N",
        tangible: isTangible === "tangible" ? "Y" : "N",
        verified,
        itemDescription,
      };
      console.log(reqDto);
      // return;
      if (props.editMode && props.editMode === true) {
        editItemValues(reqDto, userId.current).then((response) => {
          console.log(response);
          if (response.data.status === "Success") {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            props.setShouldUpdate(true);
            props.handleClose();
            // props.setShowAddModal(false)
          } else if (response.data.status === "Failure") {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
      } else {
        addItem(orgId.current, userId.current, reqDto).then((response) => {
          if (response.data.status === "Success") {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            if (props.setShouldUpdate) {
              props.setShouldUpdate(true);
            }
            props.handleClose();
            // props.setShowAddModal(false)
          } else if (response.data.status === "Failure") {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
      }
    }
  };

  const handleNewItemManufacturer = (data) => {
    console.log(data);
    const { newItemManufacturar } = data;
    if (newItemManufacturar.trim() === "") {
      toast.error(t("please_enter_item_manufacturer") , {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      addItemManufacturar(
        orgId.current,
        userId.current,
        newItemManufacturar
      ).then((response) => {
        if (response.data.status === "Success") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          getItemManufacturers(orgId.current).then((response) => {
            setItemManufacturersList(response.data);
            setValue("manufacturer", newItemManufacturar);
            setValue("manufacturer", newItemManufacturar);
            setValue("newItemManufacturar", undefined);
          });
          setShowAddManufacturarModal(false);
        } else if (response.data.status === "Failure") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const handleNewUOM = (data) => {
    console.log(data);
    const { newItemUOM } = data;
    if (newItemUOM.trim() === "") {
      toast.error(t("please_enter_item_uom") , {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      addItemUOM(orgId.current, userId.current, newItemUOM).then((response) => {
        if (response.data.status === "Success") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          getItemUOM(orgId.current).then((response) => {
            setItemUOMList(response.data);
            // setItemUOM(newItemUOM);
            setValue("defaultUom", newItemUOM);
            setValue("defaultUom", newItemUOM);
            setRefresh((prev) => prev + 1);

            setValue("newItemUOM", undefined);
            setShowAddUomModal(false);
          });
        } else if (response.data.status === "Failure") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const handleNewCatagory = (data) => {
    console.log(data);
    const { newCategory } = data;
    if (newCategory.trim() === "") {
      toast.error(t("please_enter_category_name") , {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      addItemCatagory(newCategory, orgId.current, userId.current).then(
        (response) => {
          console.log(response);
          if (response.data.status === "Success") {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            geItemCatagory(orgId.current).then((response) => {
              console.log(response);
              setCatagoryList(response.data);
              // setCatagory(newCategory);
              setValue("category", newCategory);
              setValue("category", newCategory);
              setValue("newCategory", undefined);
              setShowAddCategoryModal(false);
            });
            getItemType(newCategory, orgId.current).then((response) => {
              setItemTypeList(response.data);
              setItemType("");
              setValue("type", "");
            });
            // setValue('newItemType', undefined)
          } else if (response.data.status === "Failure") {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );
    }
  };

  return (
    <>
      <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
        <div className="addMoreAgendaModel">
          <div className="modelTitle">{t("add_item")}</div>

          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="AddItemType">{t("item_type")}</InputLabel>
                <Controller
                  name="type"
                  control={control}
                  disabled={props.type ? true : false}
                  defaultValue={props.type && props.type}
                  render={({ field }) => (
                    <Select
                      required
                      className="modelSelectFild"
                      labelId="AddItemType"
                      id="Item_Type"
                      label={t("item_type")}
                      {...field}
                    >
                      <MenuItem value="goods">{t("goods")}</MenuItem>
                      <MenuItem value="services">{t("services")}</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  required
                  className="modelTextFild"
                  label={t("item_name")}
                  variant="outlined"
                  multiline
                  {...register("itemName")}
                  inputProps={{ maxLength: 100 }}
                />
              </FormControl>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  required
                  className="modelTextFild"
                  label={t("description")}
                  variant="outlined"
                  multiline
                  rows={4}
                  maxRows={7}
                  autoComplete="off"
                  {...register("itemDescription")}
                  inputProps={{ maxLength: 100 }}
                />
              </FormControl>
            </div>

            <div className="elementWithAddElm">
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="selectCategory">{t("category")}</InputLabel>
                  <Controller
                    name="category"
                    control={control}
                    defaultValue={""}
                    render={({ field }) => (
                      <Select
                        required
                        className="modelSelectFild"
                        labelId="selectCategory"
                        id="ItCategory"
                        {...register("category")}
                        label={t("category")}
                        {...field}
                      >
                        {catagoryList.map((item) => {
                          return (
                            <MenuItem value={item} key={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  />
                </FormControl>
              </div>
              <div
                className="addelemBtn"
                onClick={(e) => setShowAddCategoryModal(true)}
              >
                {t("add_category")}
              </div>
            </div>

            <div className="elementWithAddElm">
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="selectUOM">{t("defult_uom")}</InputLabel>
                  <Controller
                    name="defaultUom"
                    control={control}
                    defaultValue={""}
                    render={({ field }) => (
                      <Select
                        required
                        className="modelSelectFild"
                        labelId="selectUOM"
                        id="ItmUOM"
                        label={t("defult_uom")}
                        // value={watch("defaultUom")}
                        {...field}
                      >
                        {itemUOMList.map((item) => {
                          return (
                            <MenuItem value={item} key={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                        {/* <MenuItem value="uom1">UOM One</MenuItem>
                        <MenuItem value="uom2">UOM Two</MenuItem>
                        <MenuItem value="uom3">UOM Three</MenuItem> */}
                      </Select>
                    )}
                  />
                </FormControl>
              </div>
              <div
                className="addelemBtn"
                onClick={(e) => setShowAddUomModal(true)}
              >
                {t("add_uom")}
              </div>
            </div>

            <div className="elementWithAddElm">
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="selectManufacturer">{t("manufacturer")}</InputLabel>
                  <Controller
                    name="manufacturer"
                    control={control}
                    defaultValue={"OperatingExpenditure"}
                    render={({ field }) => (
                      <Select
                        className="modelSelectFild"
                        labelId="selectManufacturer"
                        id="ItmManufacturer"
                        label={t("manufacturer")}
                        {...field}
                      >
                        {itemManufacturersList.map((item) => {
                          return (
                            <MenuItem value={item} key={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  />
                </FormControl>
              </div>
              <div
                className="addelemBtn"
                onClick={(e) => setShowAddManufacturarModal(true)}
              >
                {t("add_manufacturer")}
              </div>
            </div>

            <div className="formElementGroup">
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    className="modelTextFild"
                    label={t("hsn_code")}
                    variant="outlined"
                    multiline
                    {...register("hsnCd")}
                  />
                </FormControl>
              </div>

              <div className="formElement">
                <FormControl className="formControl">
                  <Controller
                    name="expirable"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        className="formRadioField"
                        control={<Checkbox {...field} />}
                        label={t("expirable")}
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>

            <div className="formElementGroup">
              {/* <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="selectManufacturer">
                    Guarantee Type
                  </InputLabel>
                  <Controller
                    name="guaranty type"
                    control={control}
                    defaultValue={"OperatingExpenditure"}
                    render={({ field }) => (
                      <Select
                        className="modelSelectFild"
                        labelId="selectManufacturer"
                        id="ItmManufacturer"
                        label="Manufacturer"
                        {...field}
                      >
                        <MenuItem value={"guarantee"} key={"guarantee"}>
                          {"Guarantee"}
                        </MenuItem>
                        <MenuItem value={"warrenty"} key={"warrenty"}>
                          {"Warrenty"}
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </div> */}
              <div className="formElement">
                <FormControl className="formControl">
                  <Controller
                    name="guarantee"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <FormControlLabel
                        className="formRadioField"
                        control={<Checkbox {...field} />}
                        label={t("guarantee_warantee_sla")}
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <FormLabel id="radioRowLable">{t("is_tangible")}</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="radioRowLable"
                  name="row-radio-buttons-group"
                  className="pdl10"
                  value={isTangible}
                  onChange={(e) => setIsTangible(e.target.value)}
                >
                  <FormControlLabel
                    value="tangible"
                    control={<Radio />}
                    label={t("tangible")}
                  />
                  <FormControlLabel
                    value="intangible"
                    control={<Radio />}
                    label={t("intangible")}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>

          <div className="modActionBtnGrp">
            <Button
              className="dfultPrimaryBtn"
              onClick={handleSubmit(onSubmit)}
            >
              {t("submit")}
            </Button>
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={props.handleClose}
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
        {showAddCategoryModal && (
          <>
            <Box className="ModelBox smallModal" sx={{ boxShadow: 24, p: 4 }}>
              <div className="addMoreAgendaModel">
                <div className="modelTitle">{t("add_category")}</div>

                <div className="elementFormContainer">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        required
                        className="modelTextFild"
                        label={t("category")}
                        variant="outlined"
                        multiline
                        rows={1}
                        maxRows={3}
                        autoComplete="off"
                        {...register("newCategory")}
                        inputProps={{ maxLength: 100 }}
                      />
                    </FormControl>
                  </div>
                </div>

                <div className="modActionBtnGrp">
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={handleSubmit(handleNewCatagory)}
                  >
                    {t("submit")}
                  </Button>
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={(e) => setShowAddCategoryModal(false)}
                  >
                    {t("cancel")}
                  </Button>
                </div>
              </div>
            </Box>
          </>
        )}
      </Box>
      {showAddUomModal && (
        <>
          <Box className="ModelBox smallModal" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">{t("add_uom")}</div>

              <div className="elementFormContainer">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      required
                      className="modelTextFild"
                      label={t("Uom")}
                      variant="outlined"
                      multiline
                      rows={1}
                      maxRows={3}
                      autoComplete="off"
                      {...register("newItemUOM")}
                      inputProps={{ maxLength: 100 }}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={handleSubmit(handleNewUOM)}
                >
                  {t("submit")}
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={(e) => setShowAddUomModal(false)}
                >
                  {t("cancel")}
                </Button>
              </div>
            </div>
          </Box>
        </>
      )}

      {showAddManufacturarModal && (
        <>
          <Box className="ModelBox smallModal" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">{t("add_manufacturer")}</div>

              <div className="elementFormContainer">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      required
                      className="modelTextFild"
                      label={t("item_manufacturer")}
                      variant="outlined"
                      multiline
                      rows={1}
                      maxRows={3}
                      {...register("newItemManufacturar")}
                      autoComplete="off"
                      // {...register("itemDescription")}
                      inputProps={{ maxLength: 100 }}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={handleSubmit(handleNewItemManufacturer)}
                >
                  {t("submit")}
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={(e) => setShowAddManufacturarModal(false)}
                >
                  {t("cancel")}
                </Button>
              </div>
            </div>
          </Box>
        </>
      )}
    </>
  );
};
