import {
  ArrowBackIosNew,
  ArrowForwardIos,
  Cancel,
  DescriptionOutlined,
  Download,
  Edit,
  Refresh,
  Save,
} from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { DeviceHddFill, HeartPulse, Play, Plus } from "react-bootstrap-icons";
import Comorbidity from "./Comorbidity";
import DrugAllergy from "./DrugAllergy";
import FamilyHistory from "./FamilyHistory";
import FoodAllergy from "./FoodAllergy";
import HistoryRiskFactor from "./HistoryRiskFactor";
import Investigations from "./Investigations";
import Medication from "./Medication";
import PossibleDiagnosis from "./PossibleDiagnosis";
import Signs from "./Signs";
import Symptoms from "./Symptoms";
import TestResult from "./TestResult";
import Diagnosis from "./Diagnosis";
import Recommendations from "./Recommendations";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import OtherOptions from "./OtherOptions";
import { downloadFileFromUrl } from "../../../../../../../utils/FileUtils";
import {
  getHealthDto,
  getVitalSvgAccordingToVitalType,
} from "./consultationUtil";
import DashboardServices from "../../../../../../../services/DashboardServices";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { CustomModal } from "../../../../../../task/view/component/Modals/CustomModal";
import RightFlotingAdmin from "../../../../../../admin/view/component/RightFlotingAdmin";
import DifferentialDiagnosis from "./DifferentialDiagnosis";
import { useTranslation } from "react-i18next";
import PhysicalExamination from "./PhysicalExamination";
import axios from "axios";
import zhAIService from "../../../../../../../services/ZhAIService";
import DrugSafety from "./DrugSafety";
import TestResultInput from "./TestResultInput";
import PatientFileUpload from "../../../../../../analyticsDashboard/view/component/healthcare/PatientFileUpload";
import zhPatientService from "../../../../../../../services/ZhPatientService";
import { getLoginUserDetails } from "../../../../../../../utils/Utils";
import { centerSocket, doctorSocket } from "../../../../../../../socket";
import { FileCursor } from "../../../../../../../common/FileCursor";
import { PatientVitals } from "../../../../../../analyticsDashboard/view/component/healthcare/PatientVitals";
import { Message } from "./Message";
import DateUtils from "../../../../../../../utils/DateUtils";

export default function PrescriptionEdit({ updateData, data, extraData }) {
  const { t } = useTranslation();
  const loginUserDetails = getLoginUserDetails();
  const [vitals, setVitals] = useState([]);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const audioUrl = useRef();
  const [height, setHeight] = useState(null);
  const [weight, setWeight] = useState(null);
  const [bmi, setBmi] = useState("");
  const [heightOutput, setHeightOutput] = useState("");
  const [weightOutput, setWeightOutput] = useState("");
  const [bmiClass, setBmiClass] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(1);
  const [pescriptionData, setPescriptionData] = useState({});
  const [patientType, setPatientType] = useState("statNormal");
  const [vitalUnitList, setVitalUnitList] = useState([]);

  const [vitalUnitMap, setVitalUnitMap] = useState(null);
  const [showAudioModal, setShowAudioModal] = useState(false);

  const [lmp, setLmp] = useState(null);

  const [VitalErrorList, setVitalErrorList] = useState([]);
  const [showFileUploadDialog, setShowFileUploadDialog] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [previousMedicalRecords, setPreviousMedicalRecords] = useState(null);
  const [prevDocuments, setPreviousDocuments] = useState([]);

  const calculateBmi = () => {
    const heightInMeters = height / 100;
    const bmiValue = parseFloat(weight / heightInMeters ** 2).toFixed(2);
    const bmiGroupIndex = [
      [0, 18.49], //under weight
      [18.5, 24.99], //normal
      [25, 29.99], //overweight
      [30, 34.99], //
      [35, 39.99],
      [40, 600],
    ].findIndex((e) => e[0] <= bmiValue && bmiValue < e[1]);

    const bmiGroupNames = [
      "Underweight",
      "Normal",
      "Pre-obesity",
      "Obese I",
      "Obese II",
      "Obese III",
    ];

    const heightInInches = (height * 0.393700787).toFixed(0);
    const feet = Math.floor(heightInInches / 12);
    const inches = heightInInches % 12;

    setHeightOutput(`${height} cm / ${feet}' ${inches}"`);
    setWeightOutput(`${weight} kg / ${(2.2046 * weight).toFixed(2)} lb`);
    setBmi(bmiValue);
    setSelectedGroup(bmiGroupIndex);

    updateData({ bmiValue: bmiValue });
    updateData({ bmiType: bmiGroupNames[bmiGroupIndex] });

    const bmiClasses = [
      "bmi-underweight",
      "bmi-normal",
      "bmi-preobesity",
      "bmi-obese1",
      "bmi-obese2",
      "bmi-obese3",
    ];
    setBmiClass(bmiClasses[bmiGroupIndex]);
  };

  const convertHeightInCm = (value, unit) => {
    if (unit === "m") {
      return value * 100;
    } else if (unit === "cm") {
      return value;
    }
  };

  const convertWeightInKg = (value, unit) => {
    if (unit === "kgs") {
      return value;
    } else if (unit === "lbs") {
      return value * 0.45359237;
    }
  };

  const getAge = () => {
    const ageComponent = extraData.queuePatientAge.split(" ");
    if (ageComponent[0].includes("Y")) {
      return parseInt(ageComponent[0].split("Y")[0]) || 0;
    } else {
      return 0;
    }
  };

  const handleChangeSistolic = (index, e) => {
    let value = e.target.value;
    let tempVital = [...vitals];
    let sistolic = tempVital[index].updatedData.vitalValue.split("/")[0];
    let diastolic = tempVital[index].updatedData.vitalValue.split("/")[1];

    if (value === "") {
      const tempValue = `${value}/${diastolic}`;
      tempVital[index].updatedData.vitalValue = tempValue;
      setVitals(tempVital);
      return;
    }
    if (value) {
      value = value.slice(0, 3);
    }

    sistolic = parseInt(value);
    if (isNaN(sistolic)) {
      return;
    }

    const tempValue = `${sistolic}/${diastolic}`;
    tempVital[index].updatedData.vitalValue = tempValue;

    if (sistolic > 140 || sistolic < 90 || diastolic > 95 || diastolic < 61) {
      tempVital[index].isNormal = "N";
    } else {
      tempVital[index].isNormal = "Y";
    }

    setVitals(tempVital);
  };

  const getUnitFromVitalCode = (vitalCode) => {
    const tempValue = vitalUnitMap?.[vitalCode];
    return tempValue?.[0] || null;
  };

  const handleChangeDiastolic = (index, e) => {
    let value = e.target.value;
    let tempVital = [...vitals];
    let sistolic = tempVital[index].updatedData.vitalValue.split("/")[0];
    let diastolic = tempVital[index].updatedData.vitalValue.split("/")[1];

    if (value === "") {
      const tempValue = `${sistolic}/${value}`;
      tempVital[index].updatedData.vitalValue = tempValue;
      setVitals(tempVital);
      return;
    }

    if (value) {
      value = value.slice(0, 3);
    }

    diastolic = parseInt(value);
    if (isNaN(diastolic)) {
      return;
    }

    const tempValue = `${sistolic}/${diastolic}`;
    tempVital[index].updatedData.vitalValue = tempValue;

    if (sistolic > 140 || sistolic < 90 || diastolic > 95 || diastolic < 61) {
      tempVital[index].isNormal = "N";
    } else {
      tempVital[index].isNormal = "Y";
    }

    tempVital[index].updatedData.vitalValue = tempValue;
    setVitals(tempVital);
  };

  const validateInput = (value, type, index) => {
    if (type === "SPO2") {
      validateSpo2(value, index);
    } else if (type === "RESPIRATION_RATE") {
      validateRR(value, index);
    } else if (type === "HEART_RATE") {
      validatePulse(value, index);
    } else if (type === "BP") {
      validatePressure(value, index);
    } else if (type === "HEIGHT") {
      validateHeight(value, index);
    } else if (type === "TEMPERATURE") {
      validateTemparature(value, index);
    } else if (type === "WEIGHT") {
      validateWeight(value, index);
    } else {
      const newValue = [...vitals];
      newValue[index].updatedData.vitalValue = value;
      newValue[index].updatedData.unit = getUnitFromVitalCode(type) || "";
      setVitals(newValue);
    }
  };

  const validateTemparature = (value, index) => {
    const inputValue = value ? value.trim() : value;

    const parsedValue = Number(value);
    if (isNaN(parsedValue)) {
      return;
    }

    let tempVitals = [...vitals];
    let spotIndex = index;

    let tmparr = inputValue.split(".");
    let tmpBD = "";
    let tmpAD = "";

    let tmpTemparature = "";

    const unit = tempVitals[index].updatedData.unit;

    if (tmparr[0] && tmparr[0].length > 0) {
      tmpBD = tmparr[0];
    }
    if (tmparr[1] && tmparr[1].length > 0) {
      tmpAD = tmparr[1];
    }

    if (unit.includes("F")) {
      if (tmparr[0] && tmparr[0].length > 3) {
        tmpBD = tmparr[0].slice(0, 3);
      }
      if (tmparr[1] && tmparr[1].length > 2) {
        tmpAD = tmparr[1].slice(0, 2);
      }

      if (inputValue.includes(".")) {
        if (tmpAD.length === 0) {
          tmpTemparature = tmpBD + ".";
        } else {
          tmpTemparature = `${tmpBD}.${tmpAD}`;
        }
      } else {
        tmpTemparature = tmpBD;
      }

      tempVitals[index].updatedData.vitalValue = tmpTemparature;

      if (parseFloat(tmpTemparature) < 95 || parseFloat(tmpTemparature) > 99) {
        tempVitals[index].isNormal = "N";
      } else {
        tempVitals[index].isNormal = "Y";
      }
    } else if (unit.includes("C")) {
      if (tmparr[0] && tmparr[0].length > 3) {
        tmpBD = tmparr[0].slice(0, 2);
      }
      if (tmparr[1] && tmparr[1].length > 2) {
        tmpAD = tmparr[1].slice(0, 2);
      }

      if (inputValue.includes(".")) {
        if (tmpAD.length === 0) {
          tmpTemparature = tmpBD + ".";
        } else {
          tmpTemparature = `${tmpBD}.${tmpAD}`;
        }
      } else {
        tmpTemparature = tmpBD;
      }

      tempVitals[index].updatedData.vitalValue = tmpTemparature;

      if (parseFloat(tmpTemparature) < 36 || parseFloat(tmpTemparature) > 39) {
        tempVitals[index].isNormal = "N";
      } else {
        tempVitals[index].isNormal = "Y";
      }
    }
    setVitals(tempVitals);
  };

  const validateWeight = (value, index) => {
    const inputValue = value ? value.trim() : value;
    const parsedValue = Number(value);
    if (isNaN(parsedValue)) {
      return;
    }
    let tempVitals = [...vitals];
    let spotIndex = index;

    let weight = parseFloat(inputValue);

    let wtarr = inputValue.split(".");
    let wtBD = "";
    let wtAD = "";

    if (wtarr[0] && wtarr[0].length > 0) {
      wtBD = wtarr[0];
    }
    if (wtarr[1] && wtarr[1].length > 0) {
      wtAD = wtarr[1];
    }

    if (wtarr[0] && wtarr[0].length > 3) {
      wtBD = wtarr[0].slice(0, 3);
    }
    if (wtarr[1] && wtarr[1].length > 2) {
      wtAD = wtarr[1].slice(0, 2);
    }

    if (inputValue === "") {
      tempVitals[index].updatedData.vitalValue = inputValue;
      setVitals(tempVitals);
      return;
    }

    if (!weight || isNaN(weight)) {
      return;
    }

    let tmpWeight = "";

    if (inputValue.includes(".")) {
      if (wtAD.length === 0) {
        tmpWeight = wtBD + ".";
      } else {
        tmpWeight = `${wtBD}.${wtAD}`;
      }
    } else {
      tmpWeight = wtBD;
    }

    tempVitals[spotIndex].updatedData.vitalValue = tmpWeight;

    setVitals(tempVitals);
  };

  const validateHeight = (value, index) => {
    const inputValue = value ? value.trim() : value;

    const parsedValue = Number(value);
    if (isNaN(parsedValue)) {
      return;
    }
    let tempVitals = [...vitals];
    let spotIndex = index;
    const unit = tempVitals[index].updatedData.unit;
    let height = parseFloat(inputValue);

    let htarr = inputValue.split(".");
    let htBD = "";
    let htAD = "";

    if (htarr[0] && htarr[0].length > 0) {
      htBD = htarr[0];
    }
    if (htarr[1] && htarr[1].length > 0) {
      htAD = htarr[1];
    }

    if (inputValue === "") {
      tempVitals[index].updatedData.vitalValue = inputValue;
      setVitals(tempVitals);
      return;
    }

    if (!height || isNaN(height)) {
      return;
    }

    if (unit === "cm") {
      if (htarr[0] && htarr[0].length > 3) {
        htBD = htarr[0].slice(0, 3);
      }
      if (htarr[1] && htarr[1].length > 2) {
        htAD = htarr[1].slice(0, 2);
      }
      let tempStr = "";
      if (inputValue.includes(".")) {
        if (htAD.length === 0) {
          tempStr = htBD + ".";
        } else {
          tempStr = `${htBD}.${htAD}`;
        }
      } else {
        tempStr = htBD;
      }

      tempVitals[index].updatedData.vitalValue = tempStr;
    } else if (unit === "ft~in") {
      tempVitals[index].updatedData.vitalValue = inputValue;
    }
    setVitals(tempVitals);
  };

  const validateSpo2 = (value, index) => {
    // let spo2arr = value.split(".");
    // let spoBD = "";
    // let spoAD = "";

    const inputValue = value ? value.trim() : value;

    const parsedValue = Number(value);
    if (isNaN(parsedValue)) {
      return;
    }

    let tempVitals = [...vitals];
    let spotIndex = index;

    if (!inputValue) {
    } else if (!parseFloat(inputValue)) {
      return;
    }

    let [spoBD, spoAD] = inputValue.split(".").map((part) => part || "");

    if (!spoAD) {
      spoAD = "";
    }

    if (spoBD.length > 2) {
      if (parseFloat(spoBD) === 100) {
        spoBD = "100";
        spoAD = "";
      } else {
        return;
        spoBD = spoBD.slice(0, 2);
      }
    }

    if (spoAD.length > 2 && parseFloat(spoAD) > 0) {
      if (parseFloat(spoBD) >= 100) {
        spoAD = "";
      } else {
        spoAD = spoAD.slice(0, 2);
      }
    }

    let temp = "";
    if (spoAD.length > 0) {
      temp = `${spoBD}.${spoAD}`;
    } else {
      temp = inputValue.includes(".") ? `${spoBD}.` : spoBD;
    }

    tempVitals[spotIndex].updatedData.vitalValue = temp;

    // let spo = inputValue.split(".");
    let spo = parseFloat(temp);

    if (temp === "") {
      tempVitals[spotIndex].isNormal = "Y";
    } else {
      if (spo >= 95 && spo <= 100) {
        tempVitals[spotIndex].isNormal = "Y";
      } else if (spo >= 91 && spo < 95) {
        tempVitals[spotIndex].isNormal = "M";
      } else {
        tempVitals[spotIndex].isNormal = "N";
      }
    }

    setVitals(tempVitals);
  };

  const validateRR = (value, index) => {
    const inputValue = value ? value.trim() : value;

    const parsedValue = Number(value);
    if (isNaN(parsedValue)) {
      return;
    }
    let tempVitals = [...vitals];
    let spotIndex = index;
    const rrValue = parseFloat(inputValue.slice(0, 3));

    if (value === "") {
      tempVitals[spotIndex].updatedData.vitalValue = inputValue;
      tempVitals[spotIndex].isNormal = "Y";
      setVitals(tempVitals);
    }

    if (!rrValue || isNaN(rrValue) || rrValue < 0) {
      return;
    }

    tempVitals[spotIndex].updatedData.vitalValue = rrValue;
    if (rrValue > 20 || rrValue < 10) {
      tempVitals[spotIndex].isNormal = "N";
    } else {
      tempVitals[spotIndex].isNormal = "Y";
    }
    setVitals(tempVitals);
  };

  const validatePulse = (value, index) => {
    const parsedValue = Number(value);
    if (isNaN(parsedValue)) {
      return;
    }
    let tempVitals = [...vitals];
    let spotIndex = index;
    const prValue = parseFloat(value.slice(0, 3));

    if (value === "" || value === null) {
      tempVitals[spotIndex].updatedData.vitalValue = value;
      tempVitals[spotIndex].isNormal = "Y";
      setVitals(tempVitals);
    }

    if (!prValue || isNaN(prValue) || prValue < 0) {
      return;
    }
    tempVitals[spotIndex].updatedData.vitalValue = prValue;

    const age = getAge();

    if (age >= 6 && age <= 12) {
      if (prValue >= 70 && prValue <= 120) {
        tempVitals[spotIndex].isNormal = "Y";
      } else if (prValue >= 121 && prValue <= 140) {
        tempVitals[spotIndex].isNormal = "M";
      } else {
        tempVitals[spotIndex].isNormal = "N";
      }
    } else {
      if (prValue >= 60 && prValue <= 100) {
        tempVitals[spotIndex].isNormal = "Y";
      } else if (prValue >= 101 && prValue <= 120) {
        tempVitals[spotIndex].isNormal = "M";
      } else {
        tempVitals[spotIndex].isNormal = "N";
      }
    }

    setVitals(tempVitals);
  };
  const validateLMP = (value, index) => {
    let tempVitals = { ...vitals };
    if (value) {
      tempVitals[index].vitalValue = value;
      setVitals(tempVitals);
    }
  };

  const validatePressure = (value, index) => {
    const parsedValue = Number(value);
    if (isNaN(parsedValue)) {
      return;
    }
    let tempVitals = [...vitals];
    let spotIndex = index;
    const sistolicDiastolicList = value.split("/");
    const sistolic = parseInt(sistolicDiastolicList[0]);
    const diastolic = parseInt(sistolicDiastolicList[1]);

    if (!sistolic || isNaN(sistolic) || !diastolic || isNaN(diastolic)) {
      toast.error(t("both_sistolic_and_diastolic_value_must_be_present"));
      return;
    }

    tempVitals[spotIndex].updatedData.vitalValue = value;

    if (value === "/") {
      tempVitals[spotIndex].isNormal = "Y";
    } else {
      if (sistolic > 140 || sistolic < 90 || diastolic > 95 || diastolic < 61) {
        tempVitals[spotIndex].isNormal = "N";
      } else {
        tempVitals[spotIndex].isNormal = "Y";
      }
    }
    setVitals(tempVitals);
  };

  const getUserPreviousDocuments = () => {
    if (extraData?.queuePatientId) {
      const visitData = {
        visitId: extraData?.queueVisitId,
        patientId: extraData?.queuePatientId,
        orgId: loginUserDetails.orgId,
        doctorId: loginUserDetails.userId,
        unitId: loginUserDetails.signleUnitId,
      };

      DashboardServices.getVisitInfoBeforeConsult(visitData).then(
        (response) => {
          if (response.data) {
            const { docs } = response.data;
            setPreviousDocuments(docs);
          }
        }
      );
    }
  };

  useEffect(() => {
    if (data && data.vitals) {
      setVitals(data.vitals);
    }
  }, [data]);

  useEffect(() => {
    console.log("extraData=", extraData);
    getUserPreviousDocuments();

    if (extraData) {
      audioUrl.current = extraData?.reqValue?.audioNoteFileUrl;
    }
    getVitalUnits();
    const getDummyVital = (vitalCode) => {
      let vitalNameMap = {
        TEMPERATURE: "Temperature",
        BP: "Blood Pressure",
        HEART_RATE: "Heart Rate",
        RESPIRATION_RATE: "Respiration Rate",
        SPO2: "SPO2",
        WEIGHT: "Weight",
        HEIGHT: "Height",
        HEAD_CIRCUMFERENCE: "Head Circumference",
        UPPER_ARM_CIRCUMFERENCE: "Upper Arm Circumference",
        LMP: "LMP",
      };

      let vitalUnitMap = {
        TEMPERATURE: "°F",
        BP: "mmHg",
        HEART_RATE: "bps",
        RESPIRATION_RATE: "bpm",
        SPO2: "%",
        WEIGHT: "kg",
        HEIGHT: "cm",
        HEAD_CIRCUMFERENCE: "cm",
        UPPER_ARM_CIRCUMFERENCE: "cm",
        LMP: "",
      };

      let tempVital = {
        vitalName: vitalNameMap[vitalCode],
        unit: vitalUnitMap[vitalCode] || null,
        vitalValue: vitalCode === "BP" ? "/" : "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode,
        updatedData: {
          vitalValue: vitalCode === "BP" ? "/" : "",
          unit: vitalUnitMap[vitalCode] || null,
          foot: "",
          inch: "",
        },
      };
      return tempVital;
    };

    let vitalList = [
      "TEMPERATURE",
      "BP",
      "HEART_RATE",
      "RESPIRATION_RATE",
      "SPO2",
      "WEIGHT",
      "HEIGHT",
    ];

    if (getAge(extraData.queuePatientAge) <= 4) {
      vitalList.push("HEAD_CIRCUMFERENCE");
      vitalList.push("UPPER_ARM_CIRCUMFERENCE");
    }

    if (
      getAge(extraData.queuePatientAge) >= 12 &&
      extraData.queuePatientGender === "Female"
    ) {
      vitalList.push("LMP");
    }

    let currentVitals = null;
    if (data?.vitals?.length >= 0) {
      currentVitals = data?.vitals;
    } else {
      currentVitals = extraData?.reqValue?.vitals || [];
    }

    let tempVitalList = [];
    currentVitals?.forEach((vital) => {
      const { vitalCode, unit, vitalValue } = vital;
      vitalList = vitalList.filter((item) => item !== vitalCode);
      if (!vital.vitalValue) {
        setEditMode(true);
      }
      let foot = "";
      let inch = "";
      if (vitalCode === "HEIGHT" && unit === "ft~in") {
        const tempHeight = parseFloat(vitalValue);
        foot = Math.floor(tempHeight / 12);
        inch = tempHeight % 12;
      }
      tempVitalList.push({
        ...vital,
        updatedData: {
          vitalValue: vital.vitalValue,
          unit: vital.unit || null,
          foot: foot,
          inch: inch,
        },
      });
    });
    if (vitalList?.length > 0) {
      setEditMode(true);
    }
    vitalList?.forEach((item) => {
      tempVitalList.push(getDummyVital(item));
    });
    //
    console.log("vitallist=", tempVitalList);
    setVitals(tempVitalList);
    updateData({ vitals: tempVitalList });

    // if (!data || !data.vitals) {
    //   if (extraData && extraData.reqValue && extraData.reqValue.vitals) {
    //     const temp = extraData?.reqValue?.vitals?.filter((item) => {
    //       return (
    //         (item.vitalCode !== "LMP" &&
    //           item.vitalCode !== "HEAD_CIRCUMFERENCE" &&
    //           item.vitalCode !== "UPPER_ARM_CIRCUMFERENCE") ||
    //         ((item.vitalCode === "HEAD_CIRCUMFERENCE" ||
    //           item.vitalCode === "UPPER_ARM_CIRCUMFERENCE") &&
    //           getAge(extraData.queuePatientAge) <= 4) ||
    //         (item.vitalCode === "LMP" &&
    //           extraData.queuePatientGender === "Female")
    //       );
    //     });
    //     // setVitals(temp);
    //     const tempHeight = extraData?.reqValue?.vitals?.find(
    //       (item) => item.vitalCode === "HEIGHT"
    //     );
    //     const tempWeight = extraData?.reqValue?.vitals?.find(
    //       (item) => item.vitalCode === "WEIGHT"
    //     );

    //     const convertedHeight = convertHeightInCm(
    //       tempHeight?.vitalValue,
    //       tempHeight?.unit
    //     );
    //     const convertWeight = convertWeightInKg(
    //       tempWeight?.vitalValue,
    //       tempWeight?.unit
    //     );

    //     setHeight(convertedHeight);
    //     setWeight(convertWeight);
    //     updateData({ vitals: temp });
    //   }
    // }

    if (extraData?.reqValue?.docs?.length > 0) {
      setPreviousDocuments(extraData?.reqValue?.docs);
    }

    if (
      extraData &&
      extraData.queueConsultationType &&
      extraData.queueConsultationType.toLowerCase().includes("opd")
    ) {
      setPatientType("statNormal");
    } else if (
      extraData &&
      extraData.queueConsultationType &&
      extraData.queueConsultationType.toLowerCase().includes("urgent")
    ) {
      setPatientType("statImportant");
    } else if (
      extraData &&
      extraData.queueConsultationType &&
      extraData.queueConsultationType.toLowerCase().includes("review")
    ) {
      setPatientType("statReview");
    } else if (
      extraData &&
      extraData.queueConsultationType &&
      extraData.queueConsultationType.toLowerCase().includes("emergency")
    ) {
      setPatientType("statUrgent");
    }

    // calculateBmi();
  }, []);

  const handleFileData = (payload) => {
    const { data } = payload;
    const { vitals, files } = data;
    if (files) {
      getUserPreviousDocuments();
    }
    if (vitals) {
      setVitals(vitals);
    }
    // setPreviousDocuments((prev) => {
    //   return [...prev, ...data];
    // });
  };

  useEffect(() => {
    doctorSocket.on("uploadFileFromCenter", handleFileData);
    return () => {
      doctorSocket.off("uploadFileFromCenter", handleFileData);
    };
  }, [doctorSocket]);

  const getVitalUnits = () => {
    const diseasetring = localStorage.getItem("vitalUnitList");
    if (diseasetring) {
      setVitalUnitList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getVitalUnits(reqDto).then((response) => {
      if (response.data) {
        setVitalUnitList(response.data);
        localStorage.setItem("vitalUnitList", JSON.stringify(response.data));
      }
    });
  };

  useEffect(() => {
    const height = vitals.find((item) => item.vitalCode === "HEIGHT");
    const weight = vitals.find((item) => item.vitalCode === "WEIGHT");
    if (height) {
      let htValue = parseFloat(height?.vitalValue);
      let htUnit = height.unit;
      if (htUnit === "ft~in") {
        htValue = htValue * 2.54;
      }
      setHeight(htValue);
    }

    if (weight) {
      let wtValue = parseFloat(weight?.vitalValue);
      let wtUnit = weight.unit;
      if (wtUnit === "lb") {
        wtValue = Math.floor(wtValue * 0.453592);
      }
      setWeight(wtValue);
    }
  }, [vitals]);

  useEffect(() => {
    if (height && weight) {
      calculateBmi();
    }
  }, [height, weight]);

  // document start
  const elementRef = useRef(null);
  const fileElementRef = useRef(null);
  const [filearrowDisable, setFilearrowDisable] = useState(true);
  const filehandleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setFilearrowDisable(true);
      } else {
        setFilearrowDisable(false);
      }
    }, speed);
  };

  const [fileDivWidth, setFileDivWidth] = useState(0);

  useEffect(() => {
    if (fileElementRef.current) {
      setFileDivWidth(fileElementRef.current.offsetWidth);
    }
  }, [fileElementRef]);

  const [tempDivWidth, setTempDivWidth] = useState(0);

  useEffect(() => {
    if (elementRef.current) {
      setTempDivWidth(elementRef.current.offsetWidth);
    }
  }, [elementRef]);

  // const getVitalSvgAccordingToVitalType = (vitalType) => {
  //   if (vitalType === "BP") {
  //     return <BP />;
  //   } else if (vitalType === "Pulse") {
  //     return <HeartPulse />;
  //   } else if (vitalType === "SPO2") {
  //     return <SPO2 />;
  //   } else if (vitalType === "Temperature") {
  //     return <ThermometerHalf />;
  //   } else if (vitalType === "RR") {
  //     return <Lungs />;
  //   } else if (vitalType === "Weight") {
  //     return <MonitorWeightOutlined />;
  //   } else if (vitalType === "Height") {
  //     return <HeightOutlined />;
  //   }
  // };
  // document end

  // edit vitals
  const [editMode, setEditMode] = useState(false);
  const [editedValues, setEditedValues] = useState({});

  const handleSaveVitals = () => {
    if (editMode) {
      if (!validateVitals()) {
        return;
      }

      const tempVitals = vitals.map((item) => ({
        ...item,
        vitalValue: item?.updatedData?.vitalValue,
        unit: item.updatedData.unit,
      }));
      setVitals(tempVitals);
      updateData({ vitals: tempVitals });
      setEditMode(!editMode);
    } else {
      const tempVitals = vitals.map((item) => {
        let foot = 0;
        let inch = 0;
        if (item.unit === "ft~in") {
          foot = Math.floor(parseInt(item?.vitalValue) / 12);
          inch = parseInt(item?.vitalValue) % 12;
        }
        if (isNaN(foot)) {
          foot = 0;
        }
        if (isNaN(inch)) {
          inch = 0;
        }
        return {
          ...item,
          updatedData: {
            vitalValue: item?.vitalValue,
            unit: item.unit,
            foot,
            inch,
          },
        };
      });
      setVitals(tempVitals);
      setEditMode(!editMode);
      centerSocket.emit("uploadFileToDoctor", {
        doctorId: extraData?.doctorId,
        data: { vitals: tempVitals },
      });
    }
  };

  const validateVitals = () => {
    const tempVitals = [...vitals];
    let isValid = true;
    const problemList = [];

    const bp = vitals.find((item) => item.vitalCode === "BP");
    const temp = vitals.find((item) => item.vitalCode === "TEMPERATURE");
    if (bp) {
      const bpList = bp?.updatedData?.vitalValue.split("/");
      const sistolic = parseInt(bpList[0]);
      const diastolic = parseInt(bpList[1]);
      if (sistolic || diastolic) {
        if (sistolic === "" || !sistolic) {
          problemList.push("Sistolic pressure cannot be empty");
          isValid = false;
        }

        if (diastolic === "" || !diastolic) {
          problemList.push("Diastolic pressure cannot be empty");
          isValid = false;
        }

        if (sistolic < diastolic) {
          problemList.push("Sistolic pressure must be greater than diastolic");
          toast.error(t("sistolic_pressure_must_be_greater_than_diastolic"));
          isValid = false;
        }

        if (sistolic - diastolic < 30) {
          problemList.push(
            "Sistolic and diastolic pressure difference must be greater than 30"
          );
          toast.error(
            t(
              "sistolic_and_diastolic_pressure_difference_must_be_greater_than_thirty"
            )
          );
          isValid = false;
        }
      }
    }

    if (temp) {
      const unit = temp.updatedData.unit;
      const value = parseFloat(temp?.updatedData?.vitalValue);
      if (unit.includes("C")) {
        if (value > 41.1 || value < 32.22) {
          problemList.push("Temparature must be within 32.22 to 41.10");
          toast.error(t("temparature_must_be_within_thirty_two_and_forty_one"));
          isValid = false;
        }
      } else if (unit.includes("F")) {
        if (value > 106 || value < 90) {
          problemList.push("Temparature must be within 90 to 106");
          toast.error(
            t("temparature_must_be_within_ninety_to_one_hundred_six")
          );
          isValid = false;
        }
      }
    }

    setVitalErrorList(problemList);

    if (!isValid) {
      setShowConfirmation(true);
    }

    return isValid;
  };

  const mapUnitsToVitals = () => {
    const tempVitalUnitMap = {};
    vitalUnitList.forEach((item) => {
      if (!tempVitalUnitMap[item.vitalCode]) {
        tempVitalUnitMap[item.vitalCode] = [item.vitalUnit];
      } else {
        tempVitalUnitMap[item.vitalCode].push(item.vitalUnit);
      }
    });
    setVitalUnitMap(tempVitalUnitMap);
  };

  useEffect(() => {
    if (vitalUnitList && vitalUnitList.length > 0) {
      mapUnitsToVitals();
    }
  }, [vitalUnitList]);

  const handleInputChange = (index, event) => {
    // const newValues = { ...editedValues, [index]: event.target.value };
    const vitalCode = vitals[index].vitalCode;
    validateInput(event.target.value, vitalCode, index);
    // const newValue = [...vitals];
    // newValue[index].updatedData.vitalValue = event.target.value;
    // setVitals(newValue);
  };

  //DIFFENTIAL DIAGNOSTICS
  const [showDifferentialDiagonesis, setShowDifferentialDiagonesis] =
    useState(false);
  const closeDifferentialDiagonesis = () => {
    setShowDifferentialDiagonesis(false);
  };

  const [differentialDiagnosisData, setDifferentialDiagnosisData] =
    useState(null);

  //Drug Safety
  const [showDrugSafety, setShowDrugSafety] = useState(false);
  const closeDrugSafety = () => {
    setShowDrugSafety(false);
  };

  const [drugSafetyData, setDrugSafetyData] = useState(null);
  return (
    <>
      {/* <div className="MeetingTranscriptionContainer">
      </div> */}
      <div className="Meeting_Transcription">
        <div className="elementFormContainer">
          <div className="tskPriotGrp">
            <div class={`tskPriot ${patientType}`}>
              <div class="priStat">{extraData.queueConsultationType}</div>
            </div>
            {/* <div class="tskPriot statImportant">
                <div class="priStat">Urgent</div>
              </div>
              <div class="tskPriot statReview">
                <div class="priStat">Review</div>
              </div>
              <div class="tskPriot statUrgent">
                <div class="priStat">Emergency</div>
              </div> */}
          </div>
          <div className="pDemography">
            <div className="pDemogInd">
              {/* <span>Name:</span> */}
              <span className="pDVlue">{extraData.queuePatientName}</span>
            </div>
            <div className="pDemogInd">
              {/* <span>Gender:</span> */}
              <span className="pDVlue">{extraData.queuePatientGender}</span>
            </div>
            <div className="pDemogInd">
              {/* <span>Age:</span> */}
              <span className="pDVlue">{extraData.queuePatientAge}</span>
            </div>

            {extraData?.reqValue?.insurance && (
              <div className="pDemogInd">
                <span>{t("insurance")}:</span>
                <span className="pDVlue">{t("yes")}</span>
              </div>
            )}

            {/* <div className="pDemogInd">
                <span>Card:</span>
                <span className="pDVlue">DADS2012541LP</span>
              </div> */}
          </div>
          <div className="pDemography">
            {extraData?.bloodGroup && (
              <div className="pDemogInd">
                <span>{"Blood Group"}:</span>
                <span className="pDVlue">{extraData?.bloodGroup}</span>
              </div>
            )}
          </div>
          {extraData?.queueReferredBy && (
            <div className="taskElementsGrpWithTwo mt10">
              <div className="taskElementGrp">
                <div className="tskElemHeding">{t("refer_by")}</div>
                <div className="tskElemntInfo">{extraData.queueReferredBy}</div>
              </div>
            </div>
          )}
          {}
          {vitals?.length > 0 && (
            <>
              <div className="tskElemHeding mt10">{t("vitals")}</div>
              <div className="vitalsGroup">
                {vitals?.map((vital, index) => {
                  return (
                    <>
                      {(editMode || (!editMode && vital.vitalValue)) && (
                        <div key={index} className="vitalsInd">
                          <div className="vitIcon">
                            {getVitalSvgAccordingToVitalType(vital?.vitalCode)}
                          </div>
                          <div className="vitInfo">
                            <div className="vitNm">{vital?.vitalName}</div>
                            <div
                              className={`vitValue ${
                                vital.isNormal === "Y"
                                  ? "vNormal"
                                  : vital.isNormal === "M"
                                  ? "vModarate"
                                  : "vRisk"
                              }`}
                            >
                              {/* {console.log(
                                  "********** vitals Edit Mode =",
                                  editMode
                                )} */}
                              {editMode ? (
                                <>
                                  {vital.vitalCode !== "LMP" &&
                                    vital.vitalCode !== "BP" &&
                                    vital.vitalCode !== "HEIGHT" &&
                                    vital.vitalCode !== "RR" && (
                                      // vital.updatedData &&
                                      // vital.updatedData.unit !== "ft~in" &&
                                      <input
                                        className="editInputVitals"
                                        type="text"
                                        value={vital?.updatedData?.vitalValue}
                                        onChange={(event) => {
                                          handleInputChange(index, event);
                                        }}
                                      />
                                    )}
                                  {vital.vitalCode === "LMP" && (
                                    <>
                                      {console.log(
                                        "values are =",
                                        dayjs(
                                          DateUtils.convertToDate(
                                            vital?.updatedData?.vitalValue
                                          )
                                        ).format("YYYY-MM-DD")
                                      )}
                                      <input
                                        className="editInputVitals"
                                        type="date"
                                        max={dayjs(new Date()).format(
                                          "YYYY-MM-DD"
                                        )}
                                        value={
                                          lmp ||
                                          dayjs(
                                            DateUtils.convertToDate(
                                              vital?.updatedData?.vitalValue
                                            )
                                          ).format("YYYY-MM-DD")
                                        }
                                        onChange={(event) => {
                                          handleInputChange(index, event);
                                          setLmp(event.target.value);
                                        }}
                                      />
                                    </>
                                  )}
                                  {vital?.vitalCode === "BP" && (
                                    <>
                                      <input
                                        className="editInputVitals"
                                        type="text"
                                        value={
                                          vital?.updatedData?.vitalValue &&
                                          vital?.updatedData?.vitalValue.split(
                                            "/"
                                          )[0]
                                        }
                                        onChange={(event) => {
                                          handleChangeSistolic(index, event);
                                        }}
                                      />
                                      <span className="slash"></span>
                                      <input
                                        className="editInputVitals"
                                        type="text"
                                        value={
                                          vital?.updatedData?.vitalValue &&
                                          vital?.updatedData?.vitalValue.split(
                                            "/"
                                          )[1]
                                        }
                                        onChange={(event) => {
                                          handleChangeDiastolic(index, event);
                                        }}
                                      />
                                    </>
                                  )}

                                  {vital.vitalCode === "HEIGHT" &&
                                    vital.updatedData?.unit === "cm" && (
                                      <input
                                        className="editInputVitals"
                                        type="text"
                                        value={vital?.updatedData?.vitalValue}
                                        onChange={(event) => {
                                          handleInputChange(index, event);
                                        }}
                                      />
                                    )}

                                  {vital.unit &&
                                    vital?.updatedData?.unit === "ft~in" && (
                                      <>
                                        <input
                                          className="editInputVitals"
                                          type="text"
                                          value={vital.updatedData.foot}
                                          onChange={(event) => {
                                            const tempVital = [...vitals];
                                            let foot = parseInt(
                                              event.target.value
                                            );
                                            if (isNaN(foot)) {
                                              foot = 0;
                                            }
                                            tempVital[
                                              index
                                            ].updatedData.vitalValue =
                                              foot * 12 +
                                              parseInt(
                                                tempVital[index].updatedData
                                                  .inch
                                              );

                                            tempVital[index].updatedData.foot =
                                              foot;
                                            setVitals(tempVital);
                                            // handleInputChange(index, event);
                                          }}
                                        />

                                        {vital.unit &&
                                          vital.updatedData &&
                                          (vitalUnitMap?.[vital.vitalCode]
                                            ?.length > 1 ? (
                                            <select
                                              className="editInputUnits"
                                              value={vital.updatedData.unit}
                                              onChange={(event) => {
                                                const newValue = [...vitals];
                                                const userUnit =
                                                  newValue[index].unit;

                                                newValue[
                                                  index
                                                ].updatedData.unit =
                                                  event.target.value;
                                                setVitals(newValue);
                                              }}
                                            >
                                              {vitalUnitMap?.[
                                                vital.vitalCode
                                              ]?.map((item) => {
                                                return (
                                                  <option
                                                    key={item}
                                                    value={item}
                                                  >
                                                    {item === "ft~in"
                                                      ? "ft"
                                                      : item}
                                                  </option>
                                                );
                                              })}
                                            </select>
                                          ) : vitalUnitMap?.[vital.vitalCode]
                                              ?.length === 1 ? (
                                            <>
                                              <span>
                                                {
                                                  vitalUnitMap[
                                                    vital.vitalCode
                                                  ][0]
                                                }
                                              </span>
                                            </>
                                          ) : (
                                            <></>
                                          ))}

                                        <input
                                          className="editInputVitals"
                                          type="text"
                                          value={vital.updatedData.inch}
                                          onChange={(event) => {
                                            const tempVital = [...vitals];
                                            let inch = parseInt(
                                              event.target.value
                                            );

                                            if (isNaN(inch) || inch < 0) {
                                              inch = 0;
                                            }
                                            if (inch > 11) {
                                              return;
                                            }
                                            tempVital[
                                              index
                                            ].updatedData.vitalValue =
                                              tempVital[index].updatedData
                                                .foot *
                                                12 +
                                              inch;

                                            tempVital[index].updatedData.inch =
                                              inch;
                                            setVitals(tempVital);
                                            // handleInputChange(index, event);
                                          }}
                                        />
                                        <span>in</span>
                                      </>
                                    )}
                                  {vital.unit &&
                                    vital.updatedData &&
                                    vital.updatedData.unit !== "ft~in" &&
                                    (vitalUnitMap?.[vital.vitalCode]?.length >
                                    1 ? (
                                      <select
                                        className="editInputUnits"
                                        value={vital.updatedData.unit}
                                        onChange={(event) => {
                                          const newValue = [...vitals];
                                          const userUnit = newValue[index].unit;
                                          let foot = 0;
                                          let inch = 0;
                                          if (event.target.value === "ft~in") {
                                            foot = Math.floor(
                                              newValue[index].updatedData
                                                .vitalValue / 12
                                            );
                                            inch =
                                              newValue[index].updatedData
                                                .vitalValue % 12;
                                            newValue[index].updatedData.foot =
                                              foot;
                                            newValue[index].updatedData.inch =
                                              inch;
                                          }

                                          newValue[index].updatedData.unit =
                                            event.target.value;
                                          setVitals(newValue);
                                        }}
                                      >
                                        {vitalUnitMap[vital.vitalCode].map(
                                          (item) => {
                                            return (
                                              <option key={item} value={item}>
                                                {item === "ft~in" ? "ft" : item}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>
                                    ) : vitalUnitMap?.[vital.vitalCode]
                                        ?.length === 1 ? (
                                      <>
                                        <span>
                                          {vitalUnitMap[vital.vitalCode][0]}
                                        </span>
                                      </>
                                    ) : (
                                      <></>
                                    ))}
                                </>
                              ) : (
                                <>
                                  <>
                                    {vital.vitalValue &&
                                      vital.vitalValue !== "" &&
                                      vital.unit === "ft~in" &&
                                      `${Math.floor(
                                        vital.vitalValue / 12
                                      )} ft ${vital.vitalValue % 12}  in`}
                                  </>
                                  <>
                                    {vital.vitalValue &&
                                      vital.vitalValue !== "" &&
                                      vital.unit !== "ft~in" &&
                                      `${vital.vitalValue}  ${
                                        vital.unit
                                          ? `${
                                              vital.unit === "per minute"
                                                ? "/min"
                                                : vital.unit
                                            }`
                                          : ""
                                      }`}
                                  </>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}

                <div>
                  <Button
                    className="joinMetBtn fullHeight"
                    startIcon={<>{editMode ? <Save /> : <Edit />}</>}
                    onClick={handleSaveVitals}
                  >
                    {t("vitals")}
                  </Button>
                </div>
                <Button
                  startIcon={<DeviceHddFill />}
                  className={"dfultPrimaryBtn"}
                  onClick={() => {
                    if (extraData.isDoctor) {
                      updateData({ getVitalsFromDevice: true });
                      updateData({ getVitalsFromDevice: false });
                    }
                  }}
                >
                  {t("get_vitals_from_device")}
                </Button>
              </div>
              {/* <PatientVitals
                  data={vitals}
                  patientAge={getAge(extraData.queuePatientAge)}
                  patientGender={extraData.queuePatientGender}
                  showBloodgroup={false}
                  handleDataChange={(data) => {}}
                  editMode={editMode}
                  handleChangeEditMode={(mode) => {
                    setEditMode(mode);
                  }}
                  showSaveButton={true}
                  handleSaveVitals={(data) => {
                    if (data) {
                      setVitals(data);
                    }
                  }}
                /> */}
            </>
          )}
          {height && weight && (
            <div className="bmiGroup">
              <div className="c-bmi__result">
                {t("bmi")}:
                <span name="r" className={` ${bmiClass}`}>
                  {bmi}
                </span>
              </div>
              <div className="c-bmi__groups" readOnly>
                {[
                  "Underweight",
                  "Normal",
                  "Pre-obesity",
                  "Obese I",
                  "Obese II",
                  "Obese III",
                ].map((label, index) => (
                  <div key={index}>
                    <input
                      type="radio"
                      className="bmiInputRadio"
                      id={`bmi-g${index}`}
                      name="g"
                      checked={selectedGroup === index}
                      readOnly
                    />
                    <label htmlFor={`bmi-g${index}`}>{label}</label>
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* <div className="taskElementGrp mt10">
              <div className="tskElemHeding">{t("patient_complaints")}</div>
              <div className="tskElemntInfo">{extraData?.queueComplaints}</div>
            </div> */}

          {extraData?.reqValue?.systemicIllnesses?.length > 0 && (
            <div className="taskElementGrp mt10">
              <div className="tskElemHeding">{t("systemic_illness")}</div>
              <div className="tskElemntInfo">
                {extraData?.reqValue?.systemicIllnesses?.join(",")}
              </div>
            </div>
          )}

          {extraData?.reqValue?.substanceAbuse?.length > 0 && (
            <div className="taskElementGrp mt10">
              <div className="tskElemHeding">{t("substance_abuse")}</div>
              <div className="tskElemntInfo">
                {extraData?.reqValue?.substanceAbuse?.join(",")}
              </div>
            </div>
          )}

          {extraData?.reqValue?.audioNoteFileUrl && (
            <div className="taskElementGrp">
              <div className="tskElemntInfo">
                <div className="playAudioNotes">
                  <IconButton
                    variant="outlined"
                    onClick={() => {
                      setShowAudioModal(true);
                    }}
                  >
                    <Play />
                  </IconButton>
                  <span>{t("patient_audio_message")}</span>
                </div>
              </div>
            </div>
          )}

          <div className="taskElementGrp mt10">
            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center ",
              }}
            >
              <div className="tskElemHeding">{t("files")}</div>

              <IconButton
                className="goPrevousBtn"
                onClick={() => {
                  getUserPreviousDocuments();
                }}
              >
                <Refresh />
              </IconButton>
            </div>
            <div class="elementFileListedGrp">
              <FileCursor>
                <>
                  {prevDocuments?.map((item) => {
                    return (
                      <div className="elemntFile">
                        <Tooltip
                          title={item.fileName}
                          arrow
                          className="elmFileTolTip"
                        >
                          <span className="elemntFilIoc">
                            <DescriptionOutlined />
                          </span>
                          <span className="elemntFilNm">{item.fileName}</span>
                          <span
                            className="elemntFilIoc"
                            onClick={() => {
                              downloadFileFromUrl(item.fileLink, item.fileName);
                            }}
                          >
                            <Download />
                          </span>
                        </Tooltip>
                      </div>
                    );
                  })}
                </>
              </FileCursor>
              {!extraData?.isDoctor && (
                <div class="elmntAddMrFiles">
                  <Button
                    className="elemntMrFileBtn"
                    variant="outlined"
                    onClick={(e) => {
                      setShowFileUploadDialog(true);
                    }}
                  >
                    <Plus />
                  </Button>
                  {/* <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleChangeFile}
                    style={{ display: "none" }}
                  ></input> */}
                </div>
              )}
            </div>
          </div>
          <div className="bb"></div>

          {/* start symptoms */}
          {(extraData?.isDoctor ||
            data?.symptoms?.length > 0 ||
            extraData?.symptoms?.length > 0) && (
            <Symptoms
              updateData={updateData}
              data={data}
              extraData={extraData}
            />
          )}
          {/* end symptoms */}

          {/* start DrugAllergy */}
          {(extraData.isDoctor || data?.allergicDrug?.length > 0) && (
            <DrugAllergy
              updateData={updateData}
              data={data}
              extraData={extraData}
            />
          )}
          {/* end DrugAllergy */}

          {/* start FoodAllergy */}
          {(extraData.isDoctor || data?.allergicFood?.length > 0) && (
            <FoodAllergy
              updateData={updateData}
              data={data}
              extraData={extraData}
            />
          )}
          {/* end FoodAllergy */}

          {/* start Comorbidity */}
          {(extraData.isDoctor || data?.comorbidity?.length > 0) && (
            <Comorbidity
              updateData={updateData}
              data={data}
              extraData={extraData}
            />
          )}
          {/* end Comorbidity */}

          {/* start Signs */}
          {(extraData.isDoctor || data?.singObj?.length > 0) && (
            <Signs updateData={updateData} data={data} extraData={extraData} />
          )}
          {/* end Signs */}
          {/* start PhysicalExamination */}
          {(extraData.isDoctor || data?.physicalExamList?.length > 0) && (
            <PhysicalExamination
              updateData={updateData}
              data={data}
              extraData={extraData}
            />
          )}
          {/* end PhysicalExamination */}

          {/* start HistoryRiskFactor */}
          {(extraData.isDoctor ||
            data?.prevSurgery?.length > 0 ||
            data?.pastIllness?.length > 0 ||
            data?.riskFactor?.length > 0) && (
            <HistoryRiskFactor
              updateData={updateData}
              data={data}
              extraData={extraData}
            />
          )}
          {/* end HistoryRiskFactor */}

          {/* start FamilyHistory */}
          {(extraData.isDoctor || data?.familyHistory?.length > 0) && (
            <FamilyHistory
              updateData={updateData}
              data={data}
              extraData={extraData}
            />
          )}
          {/* end FamilyHistory */}
          {/* start possible Diagnosis */}
          {(extraData.isDoctor || data?.possibleDiagonesis?.length > 0) && (
            <PossibleDiagnosis
              updateData={updateData}
              data={data}
              extraData={extraData}
              handleDiffDiagData={(diffDiagData) => {
                setDifferentialDiagnosisData(diffDiagData);
                setShowDifferentialDiagonesis(true);
              }}
            />
          )}
          {/* end possible Diagnosis */}
          {/* start Investigations */}
          {(extraData.isDoctor || data?.investigation?.length > 0) && (
            <Investigations
              updateData={updateData}
              data={data}
              extraData={extraData}
            />
          )}
          {/* end Investigations */}
          {/* start TestRsult */}
          {/* {extraData?.reqValue?.tests?.length > 0 && (
            )} */}
          {extraData?.isDoctor && (
            <TestResult
              updateData={updateData}
              data={data}
              extraData={extraData}
            />
          )}
          {/* end TestRsult */}
          {/* start test result input */}
          {!extraData?.isDoctor && (
            <TestResultInput data={data} extraData={extraData} />
          )}
          {/* end test result input */}
          {/* start Diagnosis */}
          {(extraData.isDoctor ||
            data?.possibleDiagonesis?.length > 0 ||
            data?.diagonesis?.length > 0) && (
            <Diagnosis
              updateData={updateData}
              data={data}
              extraData={extraData}
            />
          )}
          {/* end Diagnosis */}
          {/* start Medication */}

          {console.log(
            "****************** data =",
            extraData?.reqValue?.currentMedications?.length
          )}

          {(extraData.isDoctor ||
            data?.medication?.length > 0 ||
            extraData?.reqValue?.currentMedications?.length > 0) && (
            <Medication
              updateData={updateData}
              data={data}
              setShowDrugSafety={setShowDrugSafety}
              extraData={extraData}
            />
          )}
          {/* end Medication */}
          {/* start Recommendations */}
          {(extraData.isDoctor ||
            data?.lifeStyle?.length > 0 ||
            data?.diet?.length > 0) && (
            <Recommendations
              updateData={updateData}
              data={data}
              extraData={extraData}
            />
          )}
          {/* end Recommendations */}
          {/* start OtherOptions */}

          {(extraData.isDoctor ||
            data?.itemToMonitor?.length > 0 ||
            data?.reviewDate ||
            data?.hospital ||
            data?.referDoctorSpeciality ||
            data?.referralNote ||
            data?.referType) && (
            <OtherOptions
              updateData={updateData}
              data={data}
              extraData={extraData}
            />
          )}

          {/* end OtherOptions */}

          {/* start message */}

          {!extraData?.isDoctor && (
            <Message
              updateData={updateData}
              data={data}
              extraData={extraData}
            />
          )}

          {/* end message */}
        </div>
      </div>
      {showAudioModal && (
        <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
          <div className="addMoreAgendaModel">
            <div className="modelTitle">{t("audio_message")}</div>
            <div className="modActionBtnGrp">
              <audio controls>
                <source src={`${audioUrl.current}`} />
              </audio>
            </div>

            <div className="modActionBtnGrp">
              <Button
                className="dfultPrimaryBtn"
                onClick={() => {
                  setShowAudioModal(false);
                }}
              >
                {t("cancel")}
              </Button>
            </div>
          </div>
        </Box>
      )}

      {showConfirmation && (
        <CustomModal
          modalTitle={t("do_you_want_to_continue")}
          // style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowConfirmation(false);
          }}
          className={"width90"}
        >
          <div>
            <ul>
              {VitalErrorList &&
                VitalErrorList.map((item) => {
                  return <li>{item}</li>;
                })}
            </ul>
          </div>

          <div className="modActionBtnGrp actionsCreateOrg">
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => {
                const tempVitals = vitals.map((item) => ({
                  ...item,
                  vitalValue: item.updatedData.vitalValue,
                  unit: item.updatedData.unit,
                }));
                setVitals(tempVitals);
                updateData({ vitals: tempVitals });
                setEditMode(!editMode);
                setShowConfirmation(false);
                centerSocket.emit("uploadFileToDoctor", {
                  doctorId: extraData?.doctorId,
                  data: { vitals: tempVitals },
                });
              }}
            >
              {t("save")}
            </Button>
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                setShowConfirmation(false);
              }}
            >
              {t("edit")}
            </Button>
          </div>
        </CustomModal>
      )}
      {showDifferentialDiagonesis && differentialDiagnosisData && (
        <DifferentialDiagnosis
          data={data}
          closeDifferentialDiagonesis={closeDifferentialDiagonesis}
          differentialDiagnosisData={differentialDiagnosisData}
          setDiffDiagnosisDisease={(diffDiagdata) => {
            if (
              "possibleDiagonesis" in data &&
              data.possibleDiagonesis.length > 0
            ) {
              const difference = diffDiagdata.filter(
                (item1) =>
                  !data.possibleDiagonesis.some(
                    (item2) => item1.id === item2.id
                  )
              );
              const temp = [...data.possibleDiagonesis, ...difference];
              updateData({ possibleDiagonesis: temp });
            } else {
              updateData({ possibleDiagonesis: diffDiagdata });
            }

            setShowDifferentialDiagonesis(false);
          }}
        />
      )}
      {showDrugSafety && (
        <DrugSafety data={data} closeDrugSafety={closeDrugSafety} />
      )}

      {showFileUploadDialog && (
        <div className="RightFlotingContainerArea">
          <PatientFileUpload
            documents={documents}
            setDocuments={setDocuments}
            onClose={(e) => {
              setShowFileUploadDialog(false);
            }}
            previousMedicalRecords={previousMedicalRecords}
            setPreviousMedicalRecords={setPreviousMedicalRecords}
            onSave={(data) => {
              const reqDto = data.map((item) => {
                return {
                  fileName: item.fileName,
                  fileLink: item.fileLink,
                  fileData: item.fileData,
                  id: item.fileLink,
                };
              });
              // ;
              //
              // centerSocket.emit("uploadFileToDoctor", {
              //   doctorId: extraData?.doctorId,
              //   data: reqDto,
              // });
              zhPatientService
                .uploadDocumentsInVC(
                  extraData?.queueVisitId,
                  extraData?.queuePatientId,
                  loginUserDetails.orgId,
                  reqDto
                )
                .then((response) => {
                  if (response.data) {
                    toast.success(t("document_uploaded_successfully"));
                    setPreviousDocuments((prev) => {
                      if (!prev) {
                        return [...response.data];
                      } else {
                        return [...prev, ...response.data];
                      }
                    });

                    if (extraData?.doctorId) {
                      centerSocket.emit("uploadFileToDoctor", {
                        doctorId: extraData?.doctorId,
                        data: { files: response.data },
                      });
                    }
                  }
                })
                .catch((e) => {
                  toast.error(t("document_upload_unsuccessful"));
                });
            }}
          />
        </div>
      )}
    </>
  );
}
