import React, { useEffect, useState } from "react";
import "../css/scheduler.css";
import SchduleRightFlotingContainer from "./SchduleRightFlotingContainer";
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Settings,
  SettingsOutlined,
} from "@mui/icons-material";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import WeekView from "./component/calendar/WeekView";
import MonthView from "./component/calendar/MonthView";
import DayView from "./component/calendar/DayView";
import AgendaView from "./component/calendar/AgendaView";
import DateUtils, { weekDays } from "../../../utils/DateUtils";
import {
  getDateWiseTasks,
  getHolidaysList,
  getLeaveList,
  getMyEventsInDateRanges,
  getRefreshToken,
} from "../../../services/SchedulerService";
import { getLoginUserDetails } from "../../../utils/Utils";
import RightSideMeetingsContainer from "../../dashboard/view/component/right-panel/RightSideMeetingsContainer";
import SchRightMeetingDtlsContainer from "./SchRightMeetingDtlsContainer";
import SchRightAdditionalEventDtls from "./SchRightAdditionalEventDtls";
import ConvenientTimeView from "./component/calendar/ConvenientTimeView";
import { toast } from "react-toastify";
import SchRightSideSettings from "./SchRightSideSettings";
import { meetingSocket, schedulerSocket } from "../../../socket";
import AppUtils from "../../../utils/AppUtils";
import { socketEndPoint } from "../../../constants/url_provider";
import axios from "axios";
import { useTranslation } from "react-i18next";

export default function SchedulerContainer({
  setEventData,
  bottomShortcutDto,
}) {
  const loginUser = getLoginUserDetails();
  const { t } = useTranslation();

  const handleGoogle = async () => {
    let startDate = currentWeek[0].toISOString();
    let endDate = currentWeek[currentWeek.length - 1].toISOString();
    const respEventData = await axios.get(
      `${socketEndPoint}/api/gcal/getAllEvents/${startDate}/${endDate}/${loginUser.userId}`
    );
    console.log("respEventData", respEventData.data);
    console.log("respEventData", respEventData.data.data.data.items);
    // return;
    if (respEventData.data.returnValue !== "1") {
      var SCOPES = "https://www.googleapis.com/auth/calendar.events";
      const client = window.google.accounts.oauth2.initCodeClient({
        client_id:
          "791849580758-02itp5peod9g01s5bapsku3621stqc6r.apps.googleusercontent.com",
        scope: SCOPES,
        ux_mode: "popup",
        callback: async (response) => {
          try {
            if (!response.code) {
              toast.error(t("something_went_wrong"));
              return;
            }

            const reqDto = {
              code: response.code,
            };
            const responseSaveToken = await axios.post(
              `${socketEndPoint}/api/gcal/storeRefreshToken/${loginUser.userId}`,
              reqDto
            );
            toast.success(responseSaveToken.data.message);
          } catch (error) {
            toast.error(t("something_went_wrong"));
            console.log(error);
          }
        },
      });
      client.requestCode();
    } else {
      const gCalEventsData = respEventData.data.data.data.items;
      let gCalEvents = [];

      for (let index = 0; index < gCalEventsData.length; index++) {
        const gCalEventDto = gCalEventsData[index];
        const startTimeRounded = DateUtils.roundTimeToNearest15Minutes(
          new Date(gCalEventDto.start.dateTime)
        );
        const endTimeRounded = DateUtils.roundTimeToNearest15Minutes(
          new Date(gCalEventDto.end.dateTime)
        );
        if (!events.some((obj) => obj.eventId === gCalEventDto.id)) {
          let conferenceLink = gCalEventDto.htmlLink;

          if (
            gCalEventDto.conferenceData &&
            gCalEventDto.conferenceData.entryPoints &&
            gCalEventDto.conferenceData.entryPoints.length > 0
          ) {
            for (
              let index = 0;
              index < gCalEventDto.conferenceData.entryPoints.length;
              index++
            ) {
              const element = gCalEventDto.conferenceData.entryPoints[index];

              if (element.entryPointType === "video") {
                conferenceLink = element.uri;
              } else if (element.entryPointType === "more") {
                gCalEventDto.moreEntryPoint = element.uri;
              }
            }
          }

          let reqDto = {
            source: "google",
            eventId: gCalEventDto.id,
            title: gCalEventDto.summary,
            description: gCalEventDto.summary,
            length: 15,
            startTimeOrg: gCalEventDto.start.dateTime,
            endTimeOrg: gCalEventDto.end.dateTime,
            start: startTimeRounded,
            end: endTimeRounded,
            location: gCalEventDto.location,
            link: conferenceLink,
            createdByUserId: loginUser.userId,
            eventType: "Event",
            convener: "Y",
            chairperson: "N",
            calledBy: "N",
            futureEvent: "N",
            renderChairBy: null,
            showMOM: "N",
            webinarStreamKey: null,
            webinarSlug: null,
            sourceData: gCalEventDto,
          };
          gCalEvents.push(reqDto);
        }
      }

      console.log("gCalEvents", gCalEvents);
      setEvents((prevData) => prevData.concat(gCalEvents));
    }
  };

  const [isGcalSynced, setIsGcalSynced] = useState(false);
  const [gcalLastSynced, setGcalLastSynced] = useState(new Date());
  const [showFlotingContainer, setFlotingContainer] = useState(false);
  const [additionalEventDetails, setAdditionalEventDetails] = useState([]);
  const [showAdditionalEventDetails, setShowAdditionalEventDetails] =
    useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showCalendarSettings, setShowCalendarSettings] = useState(false);
  const [createOP, setCreateOP] = useState("");

  const selectCreateOP = (event) => {
    setCreateOP(event.target.value);
  };

  const handleCloseMenuButton = () => {
    if (showConvenientTime) {
      setHideForm(true);
    } else {
      setFlotingContainer(false);
    }
  };

  const [initialEvent, setInitialevent] = useState("");

  // const [startDate, setStartDate] = useState(new Date());

  const [activeButton, setActiveButton] = useState(2);
  const [showWeekView, setWeekView] = useState(true);
  const [showMonthView, setMonthView] = useState(false);
  const [showDayView, setDayView] = useState(false);
  const [showAgendaView, setAgendaView] = useState(false);
  const [showConvenientTime, setShowConvenientTime] = useState(false);
  const [convenientTimeData, setConvenientTimeData] = useState(null);
  const [showConvenientTimeView, setShowConvenientTimeView] = useState("W");

  const handelShowConvenientTime = (buttonId) => {
    setWeekView(false);
    setMonthView(false);
    setDayView(false);
    setAgendaView(false);
    setShowConvenientTime(true);
    setActiveButton(buttonId);
    if (showWeekView) {
      setShowConvenientTimeView("W");
    } else if (showMonthView) {
      setShowConvenientTimeView("M");
    } else if (showDayView) {
      setShowConvenientTimeView("D");
    } else if (showAgendaView) {
      setShowConvenientTimeView("A");
    }
  };

  const handelShowWeekView = (buttonId) => {
    setWeekView(true);
    setMonthView(false);
    setDayView(false);
    setAgendaView(false);
    setActiveButton(buttonId);
  };
  const handelShowMonthView = (buttonId) => {
    setWeekView(false);
    setMonthView(true);
    setDayView(false);
    setAgendaView(false);
    setActiveButton(buttonId);
    console.log(buttonId);
  };
  const handelShowDayView = (buttonId) => {
    setWeekView(false);
    setMonthView(false);
    setDayView(true);
    setAgendaView(false);
    setActiveButton(buttonId);
  };
  const handelShowAgendaView = (buttonId) => {
    setWeekView(false);
    setMonthView(false);
    setDayView(false);
    setAgendaView(true);
    setActiveButton(buttonId);
  };

  //fetch data
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [currentWeek, setCurrentWeek] = React.useState([]);
  // const [showAgenda, setShowAgenda] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [holidayList, setHolidayList] = React.useState([]);
  const [leaveList, setLeaveList] = React.useState([]);
  const [weekStartDay, setWeekStartDay] = React.useState(1);
  const [defaultWorkWeek, setDefaultWorkWeek] = React.useState([
    {
      id: 1,
      day: `Monday`,
    },
    {
      id: 2,
      day: `Tuesday`,
    },
    {
      id: 3,
      day: `Wednesday`,
    },
    {
      id: 4,
      day: `Thursday`,
    },
    {
      id: 5,
      day: `Friday`,
    },
  ]);
  const [slotDuration, setSlotDuration] = React.useState(15);
  const [startTime, setStartTime] = React.useState(0); //0 for 12:00 AM
  const [endTime, setEndTime] = React.useState(24); //23 for 11:00 PM
  const [rows, setRows] = React.useState([
    ...Array((endTime - startTime) * (60 / slotDuration)).keys(),
  ]); //23 for 11:00 PM
  const [cols, setCols] = React.useState([...Array(currentWeek.length).keys()]); //23 for 11:00 PM
  const [startTimeList, setStartTimeList] = React.useState([]);
  const [endTimeList, setEndTimeList] = React.useState([]);
  const [minStartTimeEvent, setMinStartTimeEvent] = React.useState(25);
  const [maxEndTimeEvent, setMaxEndTimeEvent] = React.useState(-1);
  const [dateWiseTask, setDateWiseTask] = React.useState([]);
  const handleChangeSlotDuration = (event) => {
    // console.log(event.target.value);
    setSlotDuration(event.target.value);
    setRows([
      ...Array((endTime - startTime) * (60 / event.target.value)).keys(),
    ]);
  };
  // React.useEffect(() => {
  //   console.log("useEffect Called");
  // }, []);

  const changeCurrentDate = (newDate) => {
    setSelectedDate(newDate);
    // console.log("changeCurrentDate-->", newDate);
    let modifiedDate = newDate;
    let day = modifiedDate.getDay();
    let diff = null;
    if (weekStartDay > day) {
      diff =
        modifiedDate.getDate() - day - (day === 10 ? -6 : 7 - weekStartDay);
    } else {
      diff = modifiedDate.getDate() - day + (day === 10 ? -6 : weekStartDay);
    }

    let currentWeekTemp = [];
    let tempDate = new Date(modifiedDate);
    currentWeekTemp.push(new Date(tempDate.setDate(diff)));
    for (let counter = 0; counter < 6; counter++) {
      var date = new Date(currentWeekTemp[counter]);
      date.setDate(currentWeekTemp[counter].getDate() + 1);
      currentWeekTemp.push(date);
    }
    console.log("currentWeekTemp modified selected date", currentWeekTemp);
    setCurrentWeek(currentWeekTemp);
    // refreshEvents();
  };

  const [dataFetched, setDataFetched] = useState(false);

  // React.useEffect(() => {
  //   console.log("useEffect Called selectedDate", selectedDate, dataFetched);
  //   const fetchData = async () => {
  //     await refreshEvents();
  //   };
  //   if (dataFetched) {
  //     fetchData();
  //   }
  // }, [dataFetched]);

  React.useEffect(() => {
    console.log("useEffect Called selectedDate", selectedDate, dataFetched);
    const fetchData = async () => {
      await refreshEvents();
    };
    if (dataFetched) {
      fetchData();
    }
  }, [dataFetched]);

  const refreshEvents = async () => {
    console.log(
      "useEffect Called selectedDate refreshEvents-1",
      selectedDate,
      showWeekView
    );
    let startDateEvent = null;
    let endDateEvent = null;
    if (showWeekView && currentWeek.length > 0) {
      startDateEvent = DateUtils.getDateInYYYYMMDD(currentWeek[0]);
      endDateEvent = DateUtils.getDateInYYYYMMDD(
        currentWeek[currentWeek.length - 1]
      );
    } else if (showDayView) {
      startDateEvent = DateUtils.getDateInYYYYMMDD(currentWeek[0]);
      endDateEvent = DateUtils.getDateInYYYYMMDD(
        currentWeek[currentWeek.length - 1]
      );
    } else if (showMonthView) {
      let currentMonthTemp = DateUtils.getDaysInMonth(
        selectedDate.getMonth(),
        selectedDate.getFullYear()
      );
      let dateEndTemp = new Date(currentMonthTemp[currentMonthTemp.length - 1]);
      for (let counter = 0; counter < 7; counter++) {
        dateEndTemp.setDate(dateEndTemp.getDate() + 1);
      }
      let dateStartTemp = new Date(currentMonthTemp[0]);
      for (let counter = 0; counter < 7; counter++) {
        dateStartTemp.setDate(dateStartTemp.getDate() - 1);
      }
      startDateEvent = DateUtils.getDateInYYYYMMDD(dateStartTemp);
      endDateEvent = DateUtils.getDateInYYYYMMDD(dateEndTemp);
    } else if (showAgendaView) {
      startDateEvent = DateUtils.getDateInYYYYMMDD(currentWeek[0]);
      endDateEvent = DateUtils.getDateInYYYYMMDD(
        currentWeek[currentWeek.length - 1]
      );
    }

    console.log("currentWeek refresh selectedDate useEffect", currentWeek);

    if (startDateEvent && endDateEvent) {
      await getMyEventsInDateRanges(
        loginUser.userId,
        startDateEvent,
        endDateEvent
      ).then(async (response) => {
        console.log(
          "getMyEventsInDateRanges-->",
          new Date().getTime(),
          response.data
        );
        let gCalEvents = [];
        try {
          // let startDate = currentWeek[0].toISOString();
          // let endDate = currentWeek[currentWeek.length - 1].toISOString();
          const { startDate, endDate } =
            DateUtils.generateDatesForGoogleCalendar(selectedDate);

          const respEventData = await axios.get(
            `${socketEndPoint}/api/gcal/getAllEvents/${startDate.toISOString()}/${endDate.toISOString()}/${
              loginUser.userId
            }`
          );

          console.log("respEventData", respEventData);
          if (respEventData.data.returnValue === "1") {
            const gCalEventsData = respEventData.data.data.data.items;
            setIsGcalSynced(true);
            setGcalLastSynced(new Date());
            console.log("respEventData", respEventData);
            for (let index = 0; index < gCalEventsData.length; index++) {
              const gCalEventDto = gCalEventsData[index];
              if (!events.some((obj) => obj.eventId === gCalEventDto.id)) {
                const startTimeRounded = DateUtils.roundTimeToNearest15Minutes(
                  new Date(gCalEventDto.start.dateTime)
                );
                const endTimeRounded = DateUtils.roundTimeToNearest15Minutes(
                  new Date(gCalEventDto.end.dateTime)
                );
                let conferenceLink = gCalEventDto.htmlLink;

                if (
                  gCalEventDto.conferenceData &&
                  gCalEventDto.conferenceData.entryPoints &&
                  gCalEventDto.conferenceData.entryPoints.length > 0
                ) {
                  for (
                    let index = 0;
                    index < gCalEventDto.conferenceData.entryPoints.length;
                    index++
                  ) {
                    const element =
                      gCalEventDto.conferenceData.entryPoints[index];

                    if (element.entryPointType === "video") {
                      conferenceLink = element.uri;
                    } else if (element.entryPointType === "more") {
                      gCalEventDto.moreEntryPoint = element.uri;
                    }
                  }
                }

                let reqDto = {
                  source: "google",
                  eventId: gCalEventDto.id,
                  title: gCalEventDto.summary,
                  description: gCalEventDto.summary,
                  length: 15,
                  startTimeOrg: gCalEventDto.start.dateTime,
                  endTimeOrg: gCalEventDto.end.dateTime,
                  start: startTimeRounded,
                  end: endTimeRounded,
                  location: gCalEventDto.location,
                  link: conferenceLink,
                  createdByUserId: loginUser.userId,
                  eventType: "Event",
                  convener: "Y",
                  chairperson: "N",
                  calledBy: "N",
                  futureEvent: "N",
                  renderChairBy: null,
                  showMOM: "N",
                  webinarStreamKey: null,
                  webinarSlug: null,
                  sourceData: gCalEventDto,
                };
                gCalEvents.push(reqDto);
              }
            }

            console.log("gCalEvents", gCalEvents);
          } else {
            setIsGcalSynced(false);
          }

          // setEvents((prevData) => prevData.concat(gCalEvents));
        } catch (err) {
          console.log("err", err);
        }
        console.log("isGcalSynced", isGcalSynced);
        if (response.data && response.data.length > 0) {
          setEvents(response.data.concat(gCalEvents));
          setDataFetched(true);
        } else {
          setEvents(gCalEvents);
          setDataFetched(true);
        }
      });
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      getHolidaysList(loginUser.orgId).then((response) => {
        if (response.data) {
          setHolidayList(response.data);
        }
      });

      getLeaveList(loginUser.userId).then((response) => {
        if (response.data) {
          setLeaveList(response.data);
        }
      });

      let day = selectedDate.getDay();
      let diff = null;
      if (weekStartDay > day) {
        diff =
          selectedDate.getDate() - day - (day === 10 ? -6 : 7 - weekStartDay);
      } else {
        diff = selectedDate.getDate() - day + (day === 10 ? -6 : weekStartDay);
      }

      let currentWeekTemp2 = [];
      let tempDate = new Date(selectedDate);
      currentWeekTemp2.push(new Date(tempDate.setDate(diff)));
      for (let counter = 0; counter < 6; counter++) {
        var date = new Date(currentWeekTemp2[counter]);
        date.setDate(currentWeekTemp2[counter].getDate() + 1);
        currentWeekTemp2.push(date);
      }
      console.log("currentWeek empty useEffect", currentWeekTemp2);

      setCurrentWeek(currentWeekTemp2);
      setCols([...Array(currentWeekTemp2.length).keys()]);

      let tempListTime = [];
      for (let time = 0; time < 24; time++) {
        let timeObj = {
          value: time,
          label: DateUtils.convert24hrIn12(time),
        };
        // console.log("timeLabel", timeObj);
        tempListTime.push(timeObj);
      }

      setStartTimeList(tempListTime);
      setEndTimeList(tempListTime);

      // setDataFetched(true);

      if (bottomShortcutDto) {
        setCreateOP(bottomShortcutDto.eventType);
        setFlotingContainer(true);
      }

      let startDateEvent = null;
      let endDateEvent = null;

      let currentMonthTemp = DateUtils.getDaysInMonth(
        selectedDate.getMonth(),
        selectedDate.getFullYear()
      );
      let dateEndTemp = new Date(currentMonthTemp[currentMonthTemp.length - 1]);
      for (let counter = 0; counter < 7; counter++) {
        dateEndTemp.setDate(dateEndTemp.getDate() + 1);
      }
      let dateStartTemp = new Date(currentMonthTemp[0]);
      for (let counter = 0; counter < 7; counter++) {
        dateStartTemp.setDate(dateStartTemp.getDate() - 1);
      }
      startDateEvent = DateUtils.getDateInYYYYMMDD(dateStartTemp);
      endDateEvent = DateUtils.getDateInYYYYMMDD(dateEndTemp);
      console.log("getMyEventsInDateRanges-->", startDateEvent, endDateEvent);
      if (startDateEvent && endDateEvent) {
        await getMyEventsInDateRanges(
          loginUser.userId,
          startDateEvent,
          endDateEvent
        ).then((response) => {
          console.log("getMyEventsInDateRanges-->22222", response.data);
          if (response.data && response.data.length > 0) {
            // setEvents(response.data);
            // let tempEvents = response.data;
            // let timeMap = {};

            // for (let hour = 0; hour < 24; hour++) {
            //   for (let minute = 0; minute < 60; minute += 5) {
            //     let timeKey = `${hour.toString().padStart(2, "0")}:${minute
            //       .toString()
            //       .padStart(2, "0")}`;
            //     timeMap[timeKey] = [];
            //   }
            // }

            // for (let i = 0; i < tempEvents.length; i++) {
            //   let obj = tempEvents[i];
            //   let start_date = new Date(obj.start);
            //   let minute = Math.floor(start_date.getMinutes() / 5) * 5;
            //   let timeKey = `${start_date
            //     .getHours()
            //     .toString()
            //     .padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
            //   if (timeKey in timeMap) {
            //     timeMap[timeKey].push(obj);
            //   }
            // }
            // console.log("timeMap", timeMap);
            // setEventsMergeTimings(timeMap);
            setDataFetched(true);
          }
        });
      }
    };

    setDataFetched(false);
    fetchData();

    //CODE TO SHOW TASKS
  }, [selectedDate, showMonthView, showDayView, showWeekView, showAgendaView]);

  const handleLeftClickView = () => {
    let date = new Date(selectedDate);
    let offset = 0;
    if (showDayView) {
      offset = 1;
    } else if (showWeekView || showAgendaView) {
      offset = 7;
      setMaxEndTimeEvent(-1);
      setMinStartTimeEvent(24);
    }

    if (showMonthView) {
      date.setMonth(date.getMonth() - 1);
    } else {
      date.setDate(date.getDate() - offset);
    }

    // console.log("handleLeftClickView", date);
    //setSelectedDate(date);
    changeCurrentDate(date);
  };

  const handleRightClickView = () => {
    let date = new Date(selectedDate);
    let offset = 0;
    if (showDayView) {
      offset = 1;
    } else if (showWeekView || showAgendaView) {
      offset = 7;
      setMaxEndTimeEvent(-1);
      setMinStartTimeEvent(24);
    }
    if (showMonthView) {
      date.setMonth(date.getMonth() + 1);
    } else {
      date.setDate(date.getDate() + offset);
    }
    // date.setDate(date.getDate() + offset);
    // console.log("handleRightClickView", date);
    // setSelectedDate(date);
    changeCurrentDate(date);
  };

  //fetch data

  //settings
  const handleChangeFirstDayWeek = (event) => {
    // console.log("handleChangeFirstDayWeek", event.target.value);
    let selectedDay = event.target.value;
    setWeekStartDay(selectedDay);
    let day = selectedDate.getDay();
    let diff = null;
    if (selectedDay > day) {
      diff = selectedDate.getDate() - day - (day === 10 ? -6 : 7 - selectedDay);
    } else {
      diff = selectedDate.getDate() - day + (day === 10 ? -6 : selectedDay);
    }

    let currentWeekTemp = [];
    let tempDate = new Date(selectedDate);
    currentWeekTemp.push(new Date(tempDate.setDate(diff)));
    for (let counter = 0; counter < 6; counter++) {
      var date = new Date(currentWeekTemp[counter]);
      date.setDate(currentWeekTemp[counter].getDate() + 1);
      currentWeekTemp.push(date);
    }
    // console.log("currentWeekTemp", currentWeekTemp);
    setCurrentWeek(currentWeekTemp);
  };

  const handleChanged = (event) => {
    const {
      target: { value },
    } = event;
    console.log("value", value);
    let defaultWorkWeekTemp = [];

    // console.log("weekStartDay", weekStartDay);
    // console.log("weekStartDay", weekDays[weekStartDay]);

    if (value.indexOf(weekDays[weekStartDay]) === -1) {
      // toast.error(
      //   `${"please_change"} ${
      //     weekDays[weekStartDay]
      //   } ${"from_first_day_of_week_before_removing_it_from_working_day"}`,
      //   {
      //     position: toast.POSITION.TOP_RIGHT,
      //   }
      // );
      toast.error(
        t("please_change_first_day_of_week", {
          day: weekDays[weekStartDay],
        }),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      
      return;
    }
    if (value.indexOf("Sunday") !== -1) {
      let weekObj = {
        id: 0,
        day: "Sunday",
      };
      defaultWorkWeekTemp.push(weekObj);
    }
    if (value.indexOf("Monday") !== -1) {
      let weekObj = {
        id: 1,
        day: "Monday",
      };
      defaultWorkWeekTemp.push(weekObj);
    }
    if (value.indexOf("Tuesday") !== -1) {
      let weekObj = {
        id: 2,
        day: "Tuesday",
      };
      defaultWorkWeekTemp.push(weekObj);
    }
    if (value.indexOf("Wednesday") !== -1) {
      let weekObj = {
        id: 3,
        day: "Wednesday",
      };
      defaultWorkWeekTemp.push(weekObj);
    }
    if (value.indexOf("Thursday") !== -1) {
      let weekObj = {
        id: 4,
        day: "Thursday",
      };
      defaultWorkWeekTemp.push(weekObj);
    }
    if (value.indexOf("Friday") !== -1) {
      let weekObj = {
        id: 5,
        day: "Friday",
      };
      defaultWorkWeekTemp.push(weekObj);
    }
    if (value.indexOf("Saturday") !== -1) {
      let weekObj = {
        id: 6,
        day: "Saturday",
      };
      defaultWorkWeekTemp.push(weekObj);
    }

    setDefaultWorkWeek(
      // On autofill we get a stringified value.
      defaultWorkWeekTemp
    );
  };

  const sortedWeekDays = [...weekDays].sort((a, b) => {
    const indexA = (weekDays.indexOf(a) - weekStartDay + 7) % 7;
    const indexB = (weekDays.indexOf(b) - weekStartDay + 7) % 7;

    return indexA - indexB;
  });

  const defaultValues = sortedWeekDays
    .filter((day) => defaultWorkWeek.map(({ day }) => day).includes(day))
    .map((day) => day);
  //settings

  useEffect(() => {
    schedulerSocket.on(
      "updateSchedulerOfEventParticipantsServer",
      async (data) => {
        console.log("updateSchedulerOfEventParticipantsServer", data);
        setDataFetched(false);
        refreshEvents();

        // setSelectedData(null);
      }
    );
  }, [schedulerSocket]);

  const [hideForm, setHideForm] = useState(false);
  const [floatingSelRadio, setFloatingSelRadio] = useState(null);
  const [selectedConvenientTime, setSelectedConvenientTime] = useState(null);

  return (
    <>
      <Box className="schedulerContainerArea" sx={{ bgcolor: "dark.pageBg" }}>
        <Grid container spacing={0} className="schedulerContainArea">
          <Grid item xs={12} className="pRelative">
            <div className="schedulerContainer">
              <div
                className="SchedulerHeader"
                onClick={() => setFlotingContainer(false)}
              >
                {t("scheduler")}
              </div>
              {/* <div onClick={() => handleGoogle()}>Google Calendar</div> */}
              {!showConvenientTime && (
                <div className="schedulerTopBtnGroup">
                  <div className="schdulerTopBtnLeft">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel id="selectCreateOP">{t("create")}</InputLabel>
                        <Select
                          className="formInputField"
                          variant="outlined"
                          labelId="selectCreateOP"
                          id="select_CreateOP"
                          value={createOP}
                          label={t("create")}
                          onChange={selectCreateOP}
                        >
                          <MenuItem
                            value={"Event"}
                            onClick={() => setFlotingContainer(true)}
                          >
                            {t("event")}
                          </MenuItem>
                          {!AppUtils.isPersonal(loginUser) && (
                            <MenuItem
                              value={"aplyLeave"}
                              onClick={() => setFlotingContainer(true)}
                            >
                              {t("leave_request")}
                            </MenuItem>
                          )}
                          {!AppUtils.isPersonal(loginUser) && (
                            <MenuItem
                              value={"webinar"}
                              onClick={() => setFlotingContainer(true)}
                            >
                              {t("webinar")}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="formBtnGroup">
                      <ButtonGroup variant="contained">
                        <IconButton
                          onClick={() => {
                            handleLeftClickView();
                          }}
                        >
                          <KeyboardArrowLeft />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            handleRightClickView();
                          }}
                        >
                          <KeyboardArrowRight />
                        </IconButton>
                      </ButtonGroup>
                    </div>
                    <div
                      className="formSingBtn"
                      onClick={() => {
                        changeCurrentDate(new Date());
                      }}
                    >
                      <Button variant="contained">{t("today")}</Button>
                    </div>
                    <div className="formDatePick">
                      {/* {showDayView && showWeekView && showAgendaView && ( */}
                      <ReactDatePicker
                        // dateFormat="yyyy/MM/dd"
                        dateFormat={"dd-MMM-yyyy"}
                        selected={selectedDate}
                        onChange={(date) => changeCurrentDate(date)}
                      />
                      {/* )} */}
                    </div>

                    <div className="schedulerLeagents">
                      <div className="schLeagendsInd">
                        <div className="legHead myCalendar">M</div>
                        <div className="legLabel">{t("my_calendar")}</div>
                      </div>
                      <div className="schLeagendsInd">
                        <div className="legHead gCalendar">G</div>
                        <div className="legLabel">{t("google")}</div>
                      </div>
                      <div className="schLeagendsInd">
                        <div className="legHead weekend">W</div>
                        <div className="legLabel">{t("weekend")}</div>
                      </div>
                      <div className="schLeagendsInd">
                        <div className="legHead holiday">H</div>
                        <div className="legLabel">{t("holiday")}</div>
                      </div>
                      <div className="schLeagendsInd">
                        <div className="legHead leave">L</div>
                        <div className="legLabel">{t("leave")}</div>
                      </div>
                    </div>
                  </div>
                  <div className="schdulerTopBtnRight">
                    <div className="selectCalenderGrp"></div>
                    <div className="CalendarBtnViewGroup">
                      <ButtonGroup variant="contained">
                        <Button
                          onClick={() => handelShowDayView(1)}
                          className={`button ${
                            activeButton === 1 ? "activeBtn" : ""
                          }`}
                        >
                          {t("day")}
                        </Button>
                        <Button
                          onClick={() => handelShowWeekView(2)}
                          className={`button ${
                            activeButton === 2 ? "activeBtn" : ""
                          }`}
                        >
                          {t("week")}
                        </Button>
                        <Button
                          onClick={() => handelShowMonthView(3)}
                          className={`button ${
                            activeButton === 3 ? "activeBtn" : ""
                          }`}
                        >
                          {t("month")}
                        </Button>
                        <Button
                          onClick={() => handelShowAgendaView(4)}
                          className={`button ${
                            activeButton === 4 ? "activeBtn" : ""
                          }`}
                        >
                          {t("agenda")}
                        </Button>
                      </ButtonGroup>
                    </div>
                    <div
                      className="settingsView"
                      onClick={() => {
                        setShowCalendarSettings(!showCalendarSettings);
                      }}
                    >
                      <Badge
                        color="secondary"
                        variant="dot"
                        invisible={isGcalSynced}
                        className="schedularSettingsInfo"
                      >
                        <IconButton>
                          <SettingsOutlined />
                        </IconButton>
                      </Badge>
                    </div>
                  </div>
                </div>
              )}
              <div className="schedulerCalendarView">
                {showWeekView && currentWeek.length > 0 && (
                  <WeekView
                    currentWeek={currentWeek}
                    selectedDate={selectedDate}
                    events={events}
                    holidayList={holidayList}
                    leaveList={leaveList}
                    defaultWorkWeek={defaultWorkWeek}
                    loginUser={loginUser}
                    startTime={startTime}
                    endTime={endTime}
                    showWeekView={showWeekView}
                    rows={rows}
                    slotDuration={slotDuration}
                    cols={cols}
                    setSelectedEvent={(eventDto) => {
                      console.log("MonthView, setSelectedEvent", eventDto);
                      let tempDto = {
                        eventTitle: eventDto.title,
                        eventId: eventDto.eventId,
                        eventDescription: eventDto.description,
                        eventStartTime: eventDto.start,
                        eventEndTime: eventDto.end,
                        eventLocation: eventDto.location,
                        eventLink: eventDto.link,
                        eventType: eventDto.eventType,
                        eventSource: eventDto.source,
                        eventSourceData: eventDto.sourceData,
                        eventWebinarSlug: eventDto.webinarSlug,
                        eventWebinarStreamKey: eventDto.webinarStreamKey,
                      };
                      setSelectedEvent(tempDto);
                      // setFlotingContainer(true);
                    }}
                    setAdditionalEventDetails={(eventList) => {
                      setAdditionalEventDetails(eventList);
                      // setShowAdditionalEventDetails(true);
                    }}
                    setShowAdditionalEventDetails={
                      setShowAdditionalEventDetails
                    }
                    showAdditionalEventDetails={showAdditionalEventDetails}
                    setSelectedConvenientTime={(data) => {
                      setCreateOP("Event");
                      setFlotingContainer(true);
                      setSelectedConvenientTime(data);
                    }}
                  />
                )}
                {showMonthView && (
                  <MonthView
                    dateWiseTask={dateWiseTask}
                    currentWeek={currentWeek}
                    selectedDate={selectedDate}
                    events={events}
                    holidayList={holidayList}
                    leaveList={leaveList}
                    defaultWorkWeek={defaultWorkWeek}
                    loginUser={loginUser}
                    startTime={startTime}
                    endTime={endTime}
                    setSelectedEvent={(eventDto) => {
                      console.log("MonthView, setSelectedEvent", eventDto);
                      let tempDto = {
                        eventTitle: eventDto.title,
                        eventId: eventDto.eventId,
                        eventDescription: eventDto.description,
                        eventStartTime: eventDto.start,
                        eventEndTime: eventDto.end,
                        eventLocation: eventDto.location,
                        eventLink: eventDto.link,
                        eventType: eventDto.eventType,
                        eventSource: eventDto.source,
                        eventSourceData: eventDto.sourceData,
                        eventWebinarSlug: eventDto.webinarSlug,
                        eventWebinarStreamKey: eventDto.webinarStreamKey,
                      };
                      setSelectedEvent(tempDto);
                      // setFlotingContainer(true);
                    }}
                    setAdditionalEventDetails={(eventList) => {
                      setAdditionalEventDetails(eventList);
                      // setShowAdditionalEventDetails(true);
                    }}
                    setShowAdditionalEventDetails={
                      setShowAdditionalEventDetails
                    }
                    setSelectedConvenientTime={(data) => {
                      setCreateOP("Event");
                      setFlotingContainer(true);
                      setSelectedConvenientTime(data);
                    }}
                  />
                )}
                {showDayView && (
                  <DayView
                    currentWeek={currentWeek}
                    selectedDate={selectedDate}
                    events={events}
                    holidayList={holidayList}
                    leaveList={leaveList}
                    defaultWorkWeek={defaultWorkWeek}
                    loginUser={loginUser}
                    startTime={startTime}
                    endTime={endTime}
                    showWeekView={showWeekView}
                    rows={rows}
                    slotDuration={slotDuration}
                    cols={cols}
                    setSelectedEvent={(eventDto) => {
                      console.log("MonthView, setSelectedEvent", eventDto);
                      let tempDto = {
                        eventTitle: eventDto.title,
                        eventId: eventDto.eventId,
                        eventDescription: eventDto.description,
                        eventStartTime: eventDto.start,
                        eventEndTime: eventDto.end,
                        eventLocation: eventDto.location,
                        eventLink: eventDto.link,
                        eventType: eventDto.eventType,
                        eventSource: eventDto.source,
                        eventSourceData: eventDto.sourceData,
                        eventWebinarSlug: eventDto.webinarSlug,
                        eventWebinarStreamKey: eventDto.webinarStreamKey,
                      };
                      setSelectedEvent(tempDto);
                      // setFlotingContainer(true);
                    }}
                    setAdditionalEventDetails={(eventList) => {
                      setAdditionalEventDetails(eventList);
                      // setShowAdditionalEventDetails(true);
                    }}
                    setShowAdditionalEventDetails={
                      setShowAdditionalEventDetails
                    }
                    showAdditionalEventDetails={showAdditionalEventDetails}
                    setSelectedConvenientTime={(data) => {
                      setCreateOP("Event");
                      setFlotingContainer(true);
                      setSelectedConvenientTime(data);
                    }}
                  />
                )}
                {showAgendaView && (
                  <AgendaView
                    dateWiseTask={dateWiseTask}
                    events={events}
                    currentWeek={currentWeek}
                    holidayList={holidayList}
                    leaveList={leaveList}
                    defaultWorkWeek={defaultWorkWeek}
                    setSelectedEvent={(eventDto) => {
                      console.log("AgendaView, setSelectedEvent", eventDto);
                      let tempDto = {
                        eventTitle: eventDto.title,
                        eventId: eventDto.eventId,
                        eventDescription: eventDto.description,
                        eventStartTime: eventDto.start,
                        eventEndTime: eventDto.end,
                        eventLocation: eventDto.location,
                        eventLink: eventDto.link,
                        eventType: eventDto.eventType,
                        eventSource: eventDto.source,
                        eventSourceData: eventDto.sourceData,
                        eventWebinarSlug: eventDto.webinarSlug,
                        eventWebinarStreamKey: eventDto.webinarStreamKey,
                      };
                      setSelectedEvent(tempDto);
                      // setFlotingContainer(true);
                    }}
                  />
                )}
                {showConvenientTime && convenientTimeData && (
                  <ConvenientTimeView
                    usersList={convenientTimeData.usersList}
                    meetingStartTime={convenientTimeData.meetingStartTime}
                    meetingEndTime={convenientTimeData.meetingEndTime}
                    // usersList={[
                    //   "AHL000000000894",
                    //   "AHL000000000703",
                    //   "AHL000000000895",
                    //   "AHL000000000742",
                    //   "AHL000000000879",
                    //   "AHL000000000860",
                    //   "AHL000000000877",
                    //   "AHL000000000748",
                    //   "AHL000000000728",
                    //   "AHL000000000751",
                    //   "AHL000000000730",
                    //   "AHL000000000745",
                    //   "AHL000000000743",
                    // ]}
                    // meetingStartTime={new Date()}
                    // meetingEndTime={new Date()}
                    loginUser={loginUser}
                    startTime={startTime}
                    endTime={endTime}
                    rows={rows}
                    slotDuration={slotDuration}
                    cols={cols}
                    defaultWorkWeek={defaultWorkWeek}
                    viewForm={(data) => {
                      if (data === "select") {
                        setHideForm(false);
                      } else {
                        setHideForm(!hideForm);
                      }
                    }}
                    hideForm={hideForm}
                    setSelectedConvenientTime={setSelectedConvenientTime}
                  />
                )}
              </div>
            </div>
            {showFlotingContainer && (
              <SchduleRightFlotingContainer
                setHideForm={setHideForm}
                createOP={createOP}
                setCreateOP={setCreateOP}
                handleCloseMenuButton={handleCloseMenuButton}
                setConvenientTimeData={(data) => {
                  console.log("setConvenientTimeData", data);
                  if (data) {
                    handelShowConvenientTime(5);
                    setConvenientTimeData(data);
                    // setFlotingContainer(false);
                  } else {
                    setShowConvenientTime(false);
                    setConvenientTimeData(data);
                    switch (showConvenientTimeView) {
                      case "W":
                        handelShowWeekView(2);
                        break;
                      case "M":
                        handelShowMonthView(3);
                        break;
                      case "D":
                        handelShowDayView(1);
                        break;
                      case "A":
                        handelShowAgendaView(4);
                        break;
                      default:
                        handelShowWeekView(2);
                    }
                  }
                }}
                hideForm={hideForm}
                setFloatingSelRadio={setFloatingSelRadio}
                floatingSelRadio={floatingSelRadio}
                setSelectedConvenientTime={setSelectedConvenientTime}
                selectedConvenientTime={selectedConvenientTime}
                closeFromDashboard={(data) => {
                  console.log("SchRightMeetingDtlsContainer", data);
                  if ("refreshNC" === data) {
                    setDataFetched(false);
                    refreshEvents();

                    if (showConvenientTimeView) {
                      setConvenientTimeData(null);
                      setShowConvenientTimeView(false);
                      setSelectedConvenientTime(null);
                      switch (showConvenientTimeView) {
                        case "W":
                          handelShowWeekView(2);
                          break;
                        case "M":
                          handelShowMonthView(3);
                          break;
                        case "D":
                          handelShowDayView(1);
                          break;
                        case "A":
                          handelShowAgendaView(4);
                          break;
                        default:
                          handelShowWeekView(2);
                      }
                    }
                    // setSelectedEvent(null);
                    // if (selectedEvent?.additional) {
                    //   setShowAdditionalEventDetails(false);
                    //   setAdditionalEventDetails([]);
                    // }
                  } else if ("refresh" === data) {
                    setDataFetched(false);
                    refreshEvents();
                    if (showConvenientTimeView) {
                      setConvenientTimeData(null);
                      setShowConvenientTimeView(false);
                      setSelectedConvenientTime(null);
                      switch (showConvenientTimeView) {
                        case "W":
                          handelShowWeekView(2);
                          break;
                        case "M":
                          handelShowMonthView(3);
                          break;
                        case "D":
                          handelShowDayView(1);
                          break;
                        case "A":
                          handelShowAgendaView(4);
                          break;
                        default:
                          handelShowWeekView(2);
                      }
                    }
                    // setSelectedEvent(null);
                    // if (selectedEvent?.additional) {
                    //   setShowAdditionalEventDetails(false);
                    //   setAdditionalEventDetails([]);
                    // }
                  } else {
                    if (showConvenientTimeView) {
                      setConvenientTimeData(null);
                      setShowConvenientTimeView(false);
                      setSelectedConvenientTime(null);
                      handelShowWeekView(2);
                    }
                    // setSelectedEvent(null);
                    // if (selectedEvent?.additional) {
                    //   setShowAdditionalEventDetails(false);
                    //   setAdditionalEventDetails([]);
                    // }
                  }
                }}
              />
            )}
            {selectedEvent && (
              <SchRightMeetingDtlsContainer
                selectedData={selectedEvent}
                setSelectedData={setSelectedEvent}
                closeFromDashboard={(data) => {
                  console.log("SchRightMeetingDtlsContainer", data);
                  if ("refreshNC" === data) {
                    setDataFetched(false);
                    refreshEvents();
                    setSelectedEvent(null);
                    if (selectedEvent?.additional) {
                      setShowAdditionalEventDetails(false);
                      setAdditionalEventDetails([]);
                    }
                  } else if ("refresh" === data) {
                    setDataFetched(false);
                    refreshEvents();
                    setSelectedEvent(null);
                    if (selectedEvent?.additional) {
                      setShowAdditionalEventDetails(false);
                      setAdditionalEventDetails([]);
                    }
                  } else {
                    setSelectedEvent(null);
                    if (selectedEvent?.additional) {
                      setShowAdditionalEventDetails(false);
                      setAdditionalEventDetails([]);
                    }
                  }
                }}
                setEventData={setEventData}
                events={events}
                setEvents={setEvents}
                selectedDate={selectedDate}
              />
            )}
            {showAdditionalEventDetails && (
              <SchRightAdditionalEventDtls
                additionalEventDetails={additionalEventDetails}
                closeFromDashboard={() => {
                  setShowAdditionalEventDetails(false);
                  setAdditionalEventDetails([]);
                }}
                setSelectedEvent={(eventDto) => {
                  console.log(
                    "SchRightAdditionalEventDtls, setSelectedEvent",
                    eventDto
                  );
                  let tempDto = {
                    eventTitle: eventDto.title,
                    eventId: eventDto.eventId,
                    eventDescription: eventDto.description,
                    eventStartTime: eventDto.start,
                    eventEndTime: eventDto.end,
                    eventLocation: eventDto.location,
                    eventLink: eventDto.link,
                    eventType: eventDto.eventType,
                    additional: eventDto.additional,
                  };
                  setShowAdditionalEventDetails(false);
                  setSelectedEvent(tempDto);
                  // setFlotingContainer(true);
                }}
              />
            )}
            {showCalendarSettings && (
              <SchRightSideSettings
                additionalEventDetails={additionalEventDetails}
                closeFromDashboard={() => {
                  setShowCalendarSettings(false);
                }}
                weekStartDay={weekStartDay}
                handleChangeFirstDayWeek={handleChangeFirstDayWeek}
                defaultWorkWeek={defaultWorkWeek}
                defaultValues={defaultValues}
                handleChanged={handleChanged}
                sortedWeekDays={sortedWeekDays}
                slotDuration={slotDuration}
                handleChangeSlotDuration={handleChangeSlotDuration}
                startTime={startTime}
                setStartTime={setStartTime}
                setRows={setRows}
                startTimeList={startTimeList}
                endTime={endTime}
                minStartTimeEvent={minStartTimeEvent}
                setEndTime={setEndTime}
                endTimeList={endTimeList}
                maxEndTimeEvent={maxEndTimeEvent}
                selectedDate={selectedDate}
                events={events}
                setEvents={setEvents}
                isGcalSynced={isGcalSynced}
                setIsGcalSynced={setIsGcalSynced}
                setGcalLastSynced={setGcalLastSynced}
                gcalLastSynced={gcalLastSynced}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
