import { Add, Edit, Search } from "@mui/icons-material";
import { Button, CircularProgress, IconButton, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Trash } from "react-bootstrap-icons";
import { getUserListWIthMenus } from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";

export default function DefineAccessControl({
  handelOpenAssignAccessControl,
  refreshAccessControl,
  handleOpenUpdatedAccessControl
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  const [userMenuData, setUserMenuData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const [filteredUserMenuData, setFilteredUserMenuData] = useState([]);

  useEffect(() => {
    fetchMenuListData();
  }, [refreshAccessControl]);

  const fetchMenuListData = () => {
    getUserListWIthMenus(userDetails.orgId)
      .then((response) => {
        console.log("getAllDepartment", response.data);
        setUserMenuData(response.data);
        setFilteredUserMenuData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
        setLoading(false);
      });
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = userMenuData.filter(
      (userMenu) =>
        userMenu.userName &&
        userMenu.userName.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredUserMenuData(filteredData);
  };

  return (
    <>
      <div className="fullContainArea">
        <div className="cuHeaderGrp">
          {/* <div className="cuhederTxt">Departmment List</div> */}
          <div className="searchTasklist">
            <input
              type="text"
              class="serchTaskInput"
              placeholder={t("search_user")}
              value={searchQuery}
              onChange={handleSearch}
            />
            <IconButton className="seacrhIcon">
              <Search />
            </IconButton>
          </div>

          <div className="addUnitsBtn">
            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => handelOpenAssignAccessControl()}
            >
              {t("access_permission")}
            </Button>
          </div>
        </div>

        <div className="cuContainArea">
          {loading ? (
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          ) : userMenuData.length === 0 ? (
            <div class="noDataCard ">
              <span>{t("oops")}</span>{t("no_access_permission_added_yet")} !!
              <br />
              <span className="sallMsg">
              {t("click_add_access_permission_button_to_create_new_access_permission")}
              </span>
            </div>
          ) : (
            <div className="TableContainer">
              <table className="taskListTable">
                <thead className="taskListtableHeader">
                  <tr>
                    <th className="width100">{t("sl_no")}</th>
                    <th className="minMax200">{t("user_name")}</th>
                    <th>{t("access_permission")}</th>
                    <th className="width100">{t("action")}</th>
                  </tr>
                </thead>
                {/* <tbody className="scrolableTableBody">
                  
                    <tr>
                        <td>
                            <div className="tdText">1.</div>
                        </td>
                        <td>
                            <div className="tdText">Sanket Santra</div>
                        </td>
                        <td>
                            <div className="tdText flex-wrap">
                                <Tooltip arrow title="Holiday / Working Schedule - Add Holiday">
                                  <span className='accessPerSpan'>Holiday / Working Schedule - Add Holiday</span>
                                </Tooltip>
                                <Tooltip arrow title="Functions - Add / Delete Routine Activity">
                                  <span className='accessPerSpan'>Functions - Add / Delete Routine Activity</span>
                                </Tooltip>
                                <Tooltip arrow title="Functions - Add / Delete Subactivity">
                                  <span className='accessPerSpan'>Functions - Add / Delete Subactivity</span>
                                </Tooltip>
                                <Tooltip arrow title="External User - Add External User">
                                  <span className='accessPerSpan'>External User - Add External User</span>
                                </Tooltip>
                                <Tooltip arrow title="Drive - Drive Details">
                                  <span className='accessPerSpan'>Drive - Drive Details</span>
                                </Tooltip>
                                <Tooltip arrow title="Contracts - List Of Contracts">
                                  <span className='accessPerSpan'>Contracts - List Of Contracts</span>
                                </Tooltip>
                                <Tooltip arrow title="Functions - Add / Edit Functions">
                                  <span className='accessPerSpan'>Functions - Add / Edit Functions</span>
                                </Tooltip>
                                
                             </div>
                        </td>
                        
                        <td>
                            <div className="tblActionBtnGrp">
                            <IconButton>
                                <Edit />
                            </IconButton>
                            <IconButton className="removeRowBtn">
                                <Trash />
                            </IconButton>
                            </div>
                        </td>
                    </tr>
                </tbody> */}
                <tbody className="scrolableTableBody">
                  {filteredUserMenuData.map((user, index) => (
                    <tr key={index}>
                      <td>
                        <div className="tdText">{index + 1}.</div>
                      </td>
                      <td>
                        <div className="tdText">{user.userName}</div>
                      </td>
                      <td>
                        <div className="tdText flex-wrap">
                          {user.menus.split(",").map((menu, menuIndex) => (
                            <Tooltip key={menuIndex} arrow title={menu.trim()}>
                              <span className="accessPerSpan">
                                {menu.trim()}
                              </span>
                            </Tooltip>
                          ))}
                        </div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <IconButton onClick={()=>handleOpenUpdatedAccessControl(user)}>
                            <Edit />
                          </IconButton>
                          {/* <IconButton className="removeRowBtn">
                            <Trash />
                          </IconButton> */}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
