import { Add, AttachFile, Autorenew } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { Info, Trash, XLg } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getCallingCodeList,
  getUnits,
  getAllDepartment,
  getPositionsOfOrganization,
  addEmployee,
  getOrgEmployees,
  getEmploymentTypes,
  // getRightsToSelect,
  getStaticDDOfOrg,
  getUserPositions,
  editEmployee,
  getPositionsOfUnit,
  getReportingPositionEmployee,
  getShifts,
  getUserShifts,
  getCurrencyList,
  getOrgByOrgId,
  getOrgEmployeeIds,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import DateUtils from "../../../../utils/DateUtils";
import { toast } from "react-toastify";
import { validateEmail } from "../../../../utils/Utils";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { getHealthDto } from "../../../scheduler/view/component/meetings/view/consultation/consultationUtil";
import DashboardServices from "../../../../services/DashboardServices";
import { convertFileToBase64_Split } from "../../../../utils/FileUtils";
import CoustomTooltip from "../../../../common/CustomTooltip";
import {
  ADD_DOCTOR_FILE_EXTENSION_MEETING,
  MAX_FILE_SIZE_TASK,
  VALID_FILE_EXTENSION_MEETING,
} from "../../../../constants/const_values";
import AppUtils from "../../../../utils/AppUtils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function EditPeople({
  handelCloseEditEmployee,
  refreshViewPeopleData,
  fetchDashBoardData,
  editEmployeesData,
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();

  // console.log("editEmployeesData", editEmployeesData);

  const [doctorEditdata, setDoctorEditData] = useState({});
  const [HealthcareProvider, setHealthcareProvider] = useState("");
  const [orgDetailsDoiData, setorgDetailsDoiData] = useState("");
  const [selectHealtProfession, setSelectHealthProfession] = useState("");

  useEffect(() => {
    fetchDoctorDetails();
  }, [editEmployeesData, HealthcareProvider]);

  const fetchDoctorDetails = () => {
    DashboardServices.getDoctorDetails(editEmployeesData.userId).then(
      (response) => {
        // console.log("response", response.data);
        const val = response.data;
        if (val.activeFlag === "Y") {
          setSelectHealthProfession("DOCTOR");
        }
        setDoctorEditData(response.data);
      }
    );
  };

  useEffect(() => {
    fetchgetOrgDetails();
  }, []);

  const fetchgetOrgDetails = () => {
    getOrgByOrgId(userDetails.orgId)
      .then((response) => {
        // console.log("getOrgDetails", response.data);
        const isHealthcareProvider = response.data.isHealthcareProvider;
        setHealthcareProvider(isHealthcareProvider);
        setorgDetailsDoiData(response.data.doi);
      })
      .catch((error) => {
        // console.error("Error fetching getOrgDetails", error);
      });
  };

  const parseDateString = (dateString) => {
    // console.log("dateString", dateString);
    if (!dateString) {
      return null;
    }
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  // console.log("doctorEditdata", doctorEditdata);

  // console.log("userDetails", userDetails);

  const [loading, setLoading] = useState(false);

  // const [emplyeeUnits, setEmplyeeUnits] = useState("");
  // const [unitsData, setUnitData] = useState([]);
  // const [userUnitList, setUserUnitList] = useState([]);

  const [joiningdate, setjoiningdate] = useState(new Date());
  const handleJoiningDate = (date) => {
    // console.log("new date = ", date);
    setjoiningdate(date);
  };

  // useEffect(() => {
  //   const inputDate = editEmployeesData.joiningDate;
  //   let tempDate = new Date();
  //   const [day , month, year] = inputDate.split('-');
  //   // tempDate.setDate(day)
  //   // tempDate.setMonth(parseInt(month)-1)
  //   tempDate.setFullYear(parseInt(year), parseInt(month)-1 , day);
  //   setjoiningdate(tempDate);
  // }, [editEmployeesData]);

  useEffect(() => {
    const inputDate = editEmployeesData?.joiningDate;

    if (inputDate) {
      let tempDate = new Date();
      const [day, month, year] = inputDate.split("-");
      tempDate.setFullYear(parseInt(year), parseInt(month) - 1, parseInt(day));
      setjoiningdate(tempDate);
    }
  }, [editEmployeesData]);

  // console.log("joiningDate", joiningdate);

  const [primaryPosdate, setPrimaryPosdate] = useState(new Date());
  const [formattedprimaryPosdate, setFormattedPrimaryPosdate] = useState("");

  const [selectedEmployeeShift, setSelectedEmployeeShift] = useState([]);
  const [shiftList, setShiftList] = useState([]);

  const handleJoiningprimaryPosdate = (date) => {
    // console.log("new date = ", date);
    setPrimaryPosdate(date);
    const formatDate = DateUtils.getDateInDDMMYYYY(date);
    setFormattedPrimaryPosdate(formatDate);
  };

  // console.log("FirstPositionDate", formattedprimaryPosdate);

  const [openAddNewPosition, setOpenAddNewPosition] = useState(false);
  const handelOpenNewPosition = () => {
    setOpenAddNewPosition(true);
  };

  const [emplyTitle, setEmplyTitle] = useState(editEmployeesData.title);

  const handleChangeEmplyTitle = (event) => {
    // console.log("handleChangeEmplyTitle", event.target.value);
    setEmplyTitle(event.target.value);
  };

  const [emplyFirstName, setEmplyFirstName] = useState(
    editEmployeesData.firstName
  );

  const handleChangeEmplyFirstName = (event) => {
    // console.log("handleChangeEmplyFirstName", event.target.value);
    setEmplyFirstName(event.target.value);
  };

  const handleChangeEmploymentType = (event) => {
    // console.log("handleChangeEmploymentType", event.target.value);
    const {
      target: { value },
    } = event;

    // const shiftString = shiftList.map((item) => item.shiftId===);

    setSelectedEmployeeShift(value);
  };

  const [emplyMiddleName, setEmplyMiddleName] = useState(
    editEmployeesData.middleName
  );

  const handleChangeEmplyMiddleName = (event) => {
    // console.log("handleChangeEmplyMiddleName", event.target.value);
    setEmplyMiddleName(event.target.value);
  };

  const [emplyLastName, setEmplyLastName] = useState(
    editEmployeesData.lastName
  );

  const handleChangeEmplyLastName = (event) => {
    // console.log("handleChangeEmplyLastName", event.target.value);
    setEmplyLastName(event.target.value);
  };

  const [emplyPhnCC, setEmplyPhnCC] = useState(
    editEmployeesData.officialContactCallingNo
  );
  const [cdCodeList, setCdCodeList] = useState([]);

  const handleChangeemplyPhnCC = (event) => {
    setEmplyPhnCC(event.target.value);
  };

  useEffect(() => {
    getUserShifts(editEmployeesData.userId).then((response) => {
      setSelectedEmployeeShift(response.data);
    });
    if (editEmployeesData.unitId) {
      fetchPositionsOfUnit(editEmployeesData.unitId);
    }
    getShifts(userDetails.orgId, userDetails.userId).then((response) => {
      if (response.data) {
        setShiftList(response.data);
      }
    });

    getCallingCodeList().then((response) => {
      // console.log("getCallingCodeList", response.data);
      setCdCodeList(response.data);
    });
  }, []);

  const [emplyGender, setEmplyGender] = useState(editEmployeesData.gender);

  const handleChangeEmplyGender = (event) => {
    // console.log("handleChangeEmplyGender", event.target.value);
    setEmplyGender(event.target.value);
  };

  const [emplyEmail, setEmplyEmail] = useState(
    editEmployeesData.officialEmailId
  );

  const handleChangeEmplyEmail = (event) => {
    // console.log("handleChangeEmplyEmail", event.target.value);
    setEmplyEmail(event.target.value);
  };

  const [emplyPhoneNumber, setEmplyPhoneNumber] = useState(
    editEmployeesData.officialContactNo
  );

  const handleChangeEmplyPhoneNumber = (event) => {
    const input = event.target.value;
    const numbersOnly = input.replace(/\D/g, "");
    setEmplyPhoneNumber(numbersOnly);
    // console.log("numbersOnly", numbersOnly);
  };

  const [emplymentType, setEmplymentType] = useState(
    editEmployeesData.employmentType
  );
  // const [emplymentTypeInput, setEmplymentTypeInput] = useState("");

  const [emplyTypes, setEmplyTypes] = useState([]);

  // useEffect(() => {
  //   getEmploymentTypes(userDetails.orgId).then((response) => {
  //     console.log("Get Employment Types", response.data);
  //     setEmplyTypes(response.data);
  //   });
  // }, [userDetails.orgId]);
  useEffect(() => {
    getStaticDDOfOrg(userDetails.orgId, "SALARY_TYPE").then((response) => {
      // console.log("SALARY_TYPE", response.data);
      setEmplyTypes(response.data);
    });
  }, []);

  // const handleChangeEmplyType = (event , newValue) => {
  //   console.log("handleChangeEmplyType", newValue);
  //   setEmplymentType(newValue);
  // };

  // const handleChangeEmplyTypeInput = (event , newValue) => {
  //   console.log("handleChangeEmplyType", newValue);
  //   setEmplymentTypeInput(newValue);
  // };

  const handleChangeEmplyType = (event) => {
    setEmplymentType(event.target.value);
  };

  // const handleChangeEmplyTypeInput = (event, value) => {
  //   setEmplymentTypeInput(value);
  // };

  const filterEmplyOptions = (options, { inputValue }) => {
    if (!inputValue) {
      return []; // If no input value, return all options
    }
    return options.filter((option) =>
      option.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const [emplymentId, setEmplymentId] = useState(editEmployeesData.employeeId);
  const [orgEmplymentIds, setOrgEmplymentIds] = useState([]);
  const [prefixMaxMap, setPrefixMaxMap] = useState({});

  useEffect(() => {
    const fetchOrgEmployeeIds = async () => {
      try {
        const response = await getOrgEmployeeIds(userDetails.orgId);
        // console.log('Response:', response.data);
        setOrgEmplymentIds(response.data);

        const map = {};
        const pattern = /([A-Z]+)(\d+)/;

        response.data.forEach((empId) => {
          const match = empId.match(pattern);
          if (match) {
            const prefix = match[1];
            const number = parseInt(match[2], 10);

            if (!map[prefix] || number > map[prefix]) {
              map[prefix] = number;
            }
          }
        });

        setPrefixMaxMap(map);
      } catch (error) {
        // console.error('Error fetching employee IDs:', error);
      }
    };

    fetchOrgEmployeeIds();
  }, [userDetails.orgId]);

  const handleChangeEmplyId = (event) => {
    // console.log("handleChangeEmplyId", event.target.value);
    setEmplymentId(event.target.value);
  };

  const [emplymentStatus, setEmplymentStatus] = useState(
    editEmployeesData.empStatus
  );

  const [emplyStatusData, setEmplyStatusData] = useState([]);

  useEffect(() => {
    getStaticDDOfOrg(userDetails.orgId, "EMPLOYMENT_STATUS").then(
      (response) => {
        // console.log("EMPLOYMENT_STATUS", response.data);
        setEmplyStatusData(response.data);
      }
    );
  }, []);

  const handleChangeEmplyStatus = (event) => {
    // console.log("handleChangeEmplyStatus", event.target.value);
    setEmplymentStatus(event.target.value);
  };

  // const handleChangeEmplyStatusInput = (event , newValue) => {
  //   console.log("handleChangeEmplyStatus", newValue);
  //   setEmplymentStatusInput(newValue);
  // };

  const [emplyeeUnits, setEmplyeeUnits] = useState(editEmployeesData.unitId);
  const [unitsData, setUnitData] = useState([]);
  const [userUnitList, setUserUnitList] = useState([]);

  useEffect(() => {
    getUnits(userDetails.orgId)
      .then((response) => {
        // console.log("getUnits", response.data);
        const sortedUnits = response.data.sort((a, b) => a.unitId - b.unitId);
        setUnitData(sortedUnits);
        // setLoading(false);
      })
      .catch((error) => {
        // console.error("Error fetching units", error);
        // setLoading(false);
      });
  }, [userDetails.orgId]);

  useEffect(() => {
    if (doctorEditdata && doctorEditdata.unitIds) {
      // console.log("doctorEditdata.unitIds:", doctorEditdata.unitIds);
      const matchedUnits = unitsData.filter((unit) =>
        doctorEditdata.unitIds.includes(unit.unitId)
      );
      // console.log("Matched Units:", matchedUnits);
      setUserUnitList(matchedUnits);
    }
  }, [doctorEditdata, unitsData]);

  const fetchPositionsOfUnit = (unitId) => {
    getPositionsOfUnit(unitId).then((response) => {
      // allPositionData
      //
      if (response.data) {
        setAllPositionData(response.data);
      }
    });
  };

  const handleChangEemplyeeUnits = (event) => {
    const unitId = event.target.value;
    setEmplyeeUnits(unitId);
    if (unitId) {
      fetchPositionsOfUnit(unitId);
      const unitIdNum = unitId;
      const getUnit = unitsData.find((val) => val.unitId === unitIdNum);
      // console.log("getUnit", getUnit);
      if (getUnit) {
        setUserUnitList([getUnit]);
      } else {
        setUserUnitList([]);
      }
    }
  };

  const handleUnitListChange = (e, data) => {
    // console.log("data", data);
    // console.log("emplyeeUnits", emplyeeUnits);

    const selectedUnitExists = data.some(
      (unit) => unit.unitId === emplyeeUnits
    );

    if (!selectedUnitExists) {
      toast.error(t("you_cannot_remove_your_unit"));
      return;
    }

    setUserUnitList(data);
  };

  const [departmentData, setDepartmentData] = useState([]);
  const [emplyeeDepartment, setEmplyeeDepartment] = useState(
    editEmployeesData.functionId
  );

  useEffect(() => {
    getAllDepartment(userDetails.orgId)
      .then((response) => {
        // console.log("getAllDepartment", response.data);
        setDepartmentData(response.data);
        // setFilteredDepartmentData(response.data);
        // setLoading(false);
      })
      .catch((error) => {
        // console.error("Error fetching units", error);
        // setLoading(false);
      });
  }, [userDetails.orgId]);

  const handleChangemplyeeDepartment = (event) => {
    // console.log("handleChangemplyeeDepartment", event.target.value);
    setEmplyeeDepartment(event.target.value);
  };

  const [reportingHeadData, setReportingHeadData] = useState([]);
  const [emplyeeReportingHead, setEmplyeeReportingHead] = useState({});
  const [selectedReportHeadId, setSelectedReportHeadId] = useState("");
  const [reportingHeadInputValue, setReportingHeadInputValue] = useState("");
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    getOrgEmployees(userDetails.orgId)
      .then((response) => {
        // console.log("getAllDepartment", response.data);
        setReportingHeadData(response.data);
      })
      .catch((error) => {
        // console.error("Error fetching units", error);
      });
  }, [userDetails.orgId]);

  useEffect(() => {
    // console.log("reportingHeadData in UseEffect", reportingHeadData);

    const matchingReportingHead = reportingHeadData.find(
      (head) => head.userId === editEmployeesData.reportingHeadUserId
    );

    // console.log("matchingReportingHead", matchingReportingHead);

    if (matchingReportingHead) {
      const obj = {
        userId: matchingReportingHead.userId,
        userName: matchingReportingHead.userName,
        userDtl: matchingReportingHead.userDtl || "",
        profileUrl: matchingReportingHead.profileUrl || "",
        userType: matchingReportingHead.userType || "",
      };
      setEmplyeeReportingHead(obj);
      setSelectedReportHeadId(obj.userId);
    } else {
      setEmplyeeReportingHead({});
      setSelectedReportHeadId("");
    }
  }, [reportingHeadData, editEmployeesData.reportingHeadUserId]);

  // const handleChangemplyeeReportingHead = (event , newInputValue) => {
  //   console.log("handleChangemplyeeDepartment", event.target.value , newInputValue);
  //   setEmplyeeReportingHead(newInputValue);
  // };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  // const filterOptions = (options, { inputValue }) => {
  //   return options.filter(
  //     (option) =>
  //       option.userName.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
  //   );
  // };

  const filterOptions = (options, { inputValue }) => {
    if (!inputValue) {
      return []; // If no input value, return all options
    }
    return options.filter((option) =>
      option.userName.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const [emplyeePosition, setEmplyeePosition] = useState("");
  const [allPositionData, setAllPositionData] = useState([]);
  const [emplyeeName, setEmplyeeName] = useState("");

  const [additionalPositions, setAdditionalPositions] = useState([]);

  const [emplyPositionData, setEmplyPositionData] = useState([]);

  useEffect(() => {
    if (editEmployeesData && editEmployeesData.userId) {
      let isMounted = true;

      getUserPositions(editEmployeesData.userId)
        .then((response) => {
          if (isMounted) {
            // console.log("response.data", response.data);
            setEmplyPositionData(response.data);
          }
        })
        .catch((error) => {
          if (isMounted) {
            // console.error("Error fetching user positions:", error);
          }
        });

      return () => {
        isMounted = false;
      };
    }
  }, [editEmployeesData]);

  // console.log("emplyPositionData", emplyPositionData);

  ///////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const primaryPos = emplyPositionData.find(
      (pos) => pos.positionType === "Primary"
    );
    const additionalPos = emplyPositionData.filter(
      (pos) => pos.positionType === "Additional"
    );

    if (primaryPos) {
      setEmplyeePosition(primaryPos.positionId);
      const inputDate = primaryPos?.effectiveDate;
      if (inputDate) {
        let tempDate = new Date();
        const [day, month, year] = inputDate.split("-");
        tempDate.setFullYear(
          parseInt(year),
          parseInt(month) - 1,
          parseInt(day)
        );
        setPrimaryPosdate(tempDate);
      }
    }

    if (additionalPos.length > 0) {
      setAdditionalPositions(
        additionalPos.map((pos) => ({
          id: pos.id,
          positionId: pos.positionId,
          effectiveDate: new Date(
            pos.effectiveDate.split("-").reverse().join("-")
          ),
        }))
      );
    }
  }, [emplyPositionData]);

  ////////////////////////////////////////////////////////////////////////////////////////

  // useEffect(() => {
  //   getPositionsOfOrganization(userDetails.orgId)
  //     .then((response) => {
  //       setAllPositionData(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching positions", error);
  //     });
  // }, [userDetails.orgId]);

  const handleChangEmplyeePosition = (event) => {
    const newValue = event.target.value;
    // console.log("handleChangEmplyeePosition", newValue);

    getReportingPositionEmployee(newValue).then((response) => {
      if (response.data) {
        // console.log("response data =", response.data);

        if (response.data) {
          setSelectedReportHeadId(response.data.userId);
          setEmplyeeReportingHead(response.data);
        }
      }
    });
    setEmplyeePosition(newValue);
    const selectedPosition = allPositionData.find(
      (position) => position.positionId === newValue
    );
    if (selectedPosition) {
      setEmplyeeName(selectedPosition.position);
      // console.log("selectedPosition.position", selectedPosition.position);
    }
  };

  const [additionalPosition, setAdditionalPosition] = useState("");
  // const handleChangadditionalPosition = (event) => {
  //   setAdditionalPosition(event.target.value);
  // };

  const positionDetails = {
    positionId: 0,
    positionType: "",
    effectiveDate: "",
  };

  const [secondPosdate, setSecondPosdate] = useState(new Date());

  // const handleAddPosition = () => {
  //   setAdditionalPositions([...additionalPositions, positionDetails]);
  // };

  const handleAddPosition = () => {
    setAdditionalPositions([...additionalPositions, { ...positionDetails }]);
  };

  // const handleRemovePosition = (index) => {
  //   setAdditionalPositions([...additionalPositions.slice(0, index), ...additionalPositions.slice(index + 1)]);
  // };

  const handleRemovePosition = (index) => {
    const updatedPositions = [...additionalPositions];
    updatedPositions.splice(index, 1);
    setAdditionalPositions(updatedPositions);
  };

  // const handleChangadditionalPosition = (index, event) => {
  //   const newPositions = [...additionalPositions];
  //   newPositions[index].position = event.target.value;
  //   setAdditionalPositions(newPositions);
  // };

  const handleChangadditionalPosition = (index, event) => {
    const positionId = event.target.value;
    const position = allPositionData.find(
      (pos) => pos.positionId === positionId
    );
    const newPositions = [...additionalPositions];
    // const formattedDate = DateUtils.getDateInDDMMYYYY(newPositions[index].date);
    newPositions[index] = {
      ...newPositions[index],
      positionId: position.positionId,
      positionType: "Additional",
      // effectiveDate: formattedDate
    };
    setAdditionalPositions(newPositions);
  };

  // const handleSecondPosdateChange = (index, date) => {
  //   const newPositions = [...additionalPositions];
  //   // const formattedDate = DateUtils.getDateInDDMMYYYY(date);
  //   newPositions[index].effectiveDate = date;
  //   setAdditionalPositions(newPositions);
  // };

  const handleSecondPosdateChange = (index, date) => {
    const newPositions = [...additionalPositions];
    newPositions[index] = {
      ...newPositions[index],
      effectiveDate: date,
    };
    setAdditionalPositions(newPositions);
  };

  // console.log("additionalPositions", additionalPositions);

  // console.log("emplymentType", emplymentType);

  // console.log("emplymentTypeInput", emplymentTypeInput);

  //////////////////////////////////////////////////////////////////////////////////////////////////

  //  useEffect(()=>{

  //   if(editEmployeesData.zhUserType === "DOCTOR"){
  //     setSelectHealthProfession("DOCTOR")
  //   }

  //   if(editEmployeesData.zhUserType === "NURSE"){
  //     setSelectHealthProfession("NURSE")
  //   }

  //   if(editEmployeesData.zhUserType === "LT"){
  //     setSelectHealthProfession("LT")
  //   }

  //  },[editEmployeesData])

  useEffect(() => {
    // if (doctorEditdata.activeFlag === "Y") {
    switch (editEmployeesData.zhUserType) {
      case "DOCTOR":
        setSelectHealthProfession("DOCTOR");
        break;
      case "NURSE":
        setSelectHealthProfession("NURSE");
        break;
      case "LT":
        setSelectHealthProfession("LT");
        break;
      default:
        setSelectHealthProfession("");
        break;
    }
    // }
  }, [editEmployeesData]);

  const handleSelectedProfessionalValue = (event) => {
    // console.log("handleSelectedProfessionalValue", event.target.value);
    setSelectHealthProfession(event.target.value);
  };

  const [salutationValue, setSalutionValue] = useState("");

  useEffect(() => {
    if (selectHealtProfession === "DOCTOR") {
      setSalutionValue("Doctor");
    }
  }, [selectHealtProfession]);

  const handleSalutationValue = (event) => {
    // console.log("handleSalutationValue", event.target.value);
    setSalutionValue(event.target.value);
  };

  const [qualificationValue, setQualificationValue] = useState("");

  useEffect(() => {
    // console.log("doctorEditdata ------", doctorEditdata);
    if (doctorEditdata) {
      setQualificationValue(doctorEditdata.qualification);
    }
  }, [editEmployeesData, doctorEditdata]);

  const handleQualificationValue = (event) => {
    // console.log("handleQualificationValue", event.target.value);
    setQualificationValue(event.target.value);
  };

  //  const [specializationValue, setSpecializationValue] = useState(null);
  const [specializationList, setSpecializationList] = useState([]);
  const [specializationName, setSpecializationName] = useState("");
  const [specializationId, setSpecializationId] = useState(0);

  useEffect(() => {
    if (doctorEditdata) {
      const lifeStyleString = localStorage.getItem("specializationList");

      if (lifeStyleString && lifeStyleString !== "[]") {
        const specializationList = JSON.parse(lifeStyleString);
        const result = specializationList.find(
          (item) => item.specializationId === doctorEditdata.specializationId
        );

        if (result) {
          setSpecializationId(result.specializationId);
          setSpecializationName(result.specializationName);
        }
      } else {
        const reqDto = getHealthDto();
        DashboardServices.getSpecializations(reqDto).then((response) => {
          if (response.data) {
            const specializationList = response.data;
            const result = specializationList.find(
              (item) =>
                item.specializationId === doctorEditdata.specializationId
            );

            if (result) {
              setSpecializationId(result.specializationId);
              setSpecializationName(result.specializationName);
            }

            localStorage.setItem(
              "specializationList",
              JSON.stringify(specializationList)
            );
          }
        });
      }
    }
  }, [doctorEditdata, editEmployeesData, specializationList]);

  useEffect(() => {
    getSpecializations();
  }, []);

  const getSpecializations = () => {
    const lifeStyleString = localStorage.getItem("specializationList");
    // console.log("lifeStyleString", lifeStyleString);
    if (lifeStyleString && lifeStyleString !== "[]") {
      setSpecializationList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = getHealthDto();
    DashboardServices.getSpecializations(reqDto).then((response) => {
      if (response.data) {
        setSpecializationList(response.data);
        // console.log("getSpecializations", response.data);
        localStorage.setItem(
          "specializationList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  // console.log("specializationList", specializationList);

  const handleSpecializationValue = (event) => {
    const selectedSpecializationId = event.target.value;
    // console.log("handleSpecializationValue", selectedSpecializationId);

    setSpecializationId(selectedSpecializationId);

    const result = specializationList.find(
      (item) => item.specializationId === selectedSpecializationId
    );

    if (result) {
      setSpecializationName(result.specializationName);
    }
  };

  const [practiceStartdate, setPracticeStartdate] = useState("");
  const [yearOfExpValue, setYearOfExpValue] = useState("");

  //  useEffect(() => {
  //    if (selectHealtProfession === "DOCTOR") {
  //      setPracticeStartdate(new Date());
  //      setYearOfExpValue("0")
  //    }
  //  }, [selectHealtProfession]);

  useEffect(() => {
    const inputDate = doctorEditdata?.practiceStartDate;

    if (inputDate) {
      let tempDate = new Date();
      const [day, month, year] = inputDate.split("-");
      tempDate.setFullYear(parseInt(year), parseInt(month) - 1, parseInt(day));
      setPracticeStartdate(tempDate);
      expInYear(tempDate);
    }
  }, [doctorEditdata]);

  const handlePracticeStartDate = (date) => {
    // console.log("Practice Start date = ", date);
    setPracticeStartdate(date);
    expInYear(date);
  };

  const expInYear = (date) => {
    const userDate = new Date(date);
    const currentDate = new Date();
    const diffInDateInMiliSEc = currentDate - userDate;
    const millisecondsInAYear = 1000 * 60 * 60 * 24 * 365.25;
    const diffInYears = diffInDateInMiliSEc / millisecondsInAYear;
    const diffInYearsRounded = Math.floor(diffInYears);
    const diffInYearsInString = diffInYearsRounded.toString();
    setYearOfExpValue(diffInYearsInString);
  };

  const handleYearOfExpValue = (event) => {
    // console.log("handleYearOfExpValue", event.target.value);
    setYearOfExpValue(event.target.value);
  };

  const [languageMasterList, setLanguageMasterList] = useState([]);
  const [userLanguageList, setUserLanguageList] = useState([]);

  useEffect(() => {
    DashboardServices.getBcp47CodeName().then((response) => {
      if (response.data) {
        setLanguageMasterList(response.data);
      }
    });
  }, []);

  //  useEffect(()=>{

  //   if(doctorEditdata){
  //     const val = doctorEditdata?.languages
  //     console.log('val' , val)
  //   }
  //  },[editEmployeesData , doctorEditdata])

  useEffect(() => {
    if (doctorEditdata) {
      const val = doctorEditdata?.languages;
      // console.log("val", val);

      if (val) {
        const languageNames = val.split(",");

        const matchedLanguages = languageMasterList.filter((lang) =>
          languageNames.includes(lang.id)
        );

        if (matchedLanguages.length === 0) {
          // setUserLanguageList(
          //   languageMasterList.filter((lang) =>
          //     languageNames.includes(lang.name)
          //   )
          // );
        } else {
          setUserLanguageList(matchedLanguages);
        }

        // console.log("matchedLanguages", matchedLanguages);
      }
    }
  }, [editEmployeesData, doctorEditdata, languageMasterList]);

  // console.log("languageMasterList", languageMasterList);
  // console.log("userLanguageList", userLanguageList);

  const [currencyList, setCurrencyList] = useState([]);
  const [currencyValue, setCurrencyValue] = useState("");

  useEffect(() => {
    getCurrencyList().then((response) => {
      // console.log("getCurrencyList", response.data);
      setCurrencyList(response.data);
    });
  }, []);

  useEffect(() => {
    if (doctorEditdata && currencyList.length > 0) {
      const val = doctorEditdata?.feesCurrency;
      setCurrencyValue(val);
    }
  }, [editEmployeesData, doctorEditdata, currencyList]);

  const handleChangeCurrency = (event) => {
    // console.log("handleChangeCurrency", event.target.value);
    setCurrencyValue(event.target.value);
  };

  const [doctorFeesValue, setDoctorFeesValue] = useState(0);

  useEffect(() => {
    if (doctorEditdata) {
      const val = doctorEditdata?.fees;
      setDoctorFeesValue(val);
    }
  }, [editEmployeesData, doctorEditdata]);

  const handleDoctorFeesChange = (event) => {
    // console.log("handleDoctorFeesChange", event.target.value);
    setDoctorFeesValue(event.target.value);
  };

  const [licenseIssueAuthValue, setLicenseIssueAuthValue] = useState("");

  useEffect(() => {
    if (doctorEditdata) {
      const val = doctorEditdata?.licenseIssuingAuth;
      setLicenseIssueAuthValue(val);
    }
  }, [editEmployeesData, doctorEditdata]);

  const handleLicenseIssueAuthChange = (event) => {
    // console.log("handleLicenseIssueAuthChange", event.target.value);
    setLicenseIssueAuthValue(event.target.value);
  };

  const [medicalLicenseNum, setMedicalLicenseNum] = useState("");

  useEffect(() => {
    if (doctorEditdata) {
      const val = doctorEditdata?.medicalLicenseNo;
      setMedicalLicenseNum(val);
    }
  }, [editEmployeesData, doctorEditdata]);

  const handleMedicalLicenseNumChange = (event) => {
    // console.log("handleMedicalLicenseNumChange", event.target.value);
    setMedicalLicenseNum(event.target.value);
  };

  const fileRef = useRef();
  const [fileData_Base64, setFileData_Base64] = useState("");
  const [fileName, setFileName] = useState("");
  const [MedicalLicenseUrl, setMedicalLicenseUrl] = useState("");

  useEffect(() => {
    if (doctorEditdata) {
      const val = doctorEditdata?.medicalLicenseCertificateUrl;
      if (val) {
        // const fileName = val.substring(val.lastIndexOf("/") + 1);
        const fileName = doctorEditdata?.medicalLicenseCertificate;
        // console.log("val", val);
        // console.log("fileName", fileName);
        setMedicalLicenseUrl(val);
        setFileName(fileName);
      }
    }
  }, [doctorEditdata]);

  const handleFileChange = async (e) => {
    // console.log(e);
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    setFileData_Base64(await convertFileToBase64_Split(file));
    setFileName(file.name);
  };

  const handleDeleteFile = () => {
    setFileName("");
    setFileData_Base64("");
    setMedicalLicenseUrl("");
  };

  // console.log("fileData_Base64", fileData_Base64);
  // console.log("fileName", fileName);

  const signImageRef = useRef(null);
  const [doctorSignatureUrl, setDoctorSignatureUrl] = useState("");
  const [signature_Base64, setSignatureFileData_Base64] = useState("");
  const [signfileName, setSignFileName] = useState("");

  useEffect(() => {
    if (doctorEditdata) {
      const val = doctorEditdata?.doctorSignImageUrl;
      setDoctorSignatureUrl(val);
    }
  }, [doctorEditdata]);

  const handleUpdateSignature = async (e, userId) => {
    setDoctorSignatureUrl("");
    try {
      if (!e.target.files) {
        return;
      }
      const file = e.target.files[0];
      // console.log("handleFileUpload", file);

      const validTypes = ["image/png", "image/jpg", "image/jpeg"];
      if (!validTypes.includes(file.type)) {
        toast.error(t("invalid_file_format_allowed_formats_png_jpg_jpeg"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      const fileSizeInKB = file.size / 1024;
      if (fileSizeInKB < 2 || fileSizeInKB > 200) {
        toast.error(t("file_size_must_be_between_ten_kb_and_two_hundred_kb"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      const image = new Image();
      image.src = URL.createObjectURL(file);

      image.onload = async () => {
        const { width, height } = image;
        if (height < 50 || height > 512) {
          toast.error(t("image_height_details"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          return;
        }

        const fileData = await convertFileToBase64_Split(file);
        const fileName = file.name;

        if (fileData && fileName) {
          // console.log("fileData", fileData);
          // console.log("fileName", fileName);
          setSignatureFileData_Base64(fileData);
          setSignFileName(fileName);

          // const reqDto = {
          //   fileName: fileName,
          //   fileData: fileData,
          // };

          // const response = await DashboardServices.updateSignature(
          //   userDetails.userId,
          //   userDetails.orgId,
          //   reqDto
          // );

          // console.log(response.data);
          // setSignature(response.data);
          // toast.success("Profile Signature Uploaded Successfully", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        }
      };
    } catch (error) {
      // console.error("Error occurred during file upload:", error);
      toast.error(
        t("an_error_occurred_during_file_upload_Please_try_again_later"),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    const val = event.target.checked;
    if (editEmployeesData.zhUserType === "DOCTOR") {
      DashboardServices.deactivateZhAccount(
        editEmployeesData.zhUserType,
        editEmployeesData.userId,
        userDetails.userId
      )
        .then((response) => {
          // console.log("response:", response.data);

          if (response.data.returnValue === "1") {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setSelectHealthProfession("");
            setIsChecked(false);
            refreshViewPeopleData();
            fetchDoctorDetails();
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          // console.error("Error deactivating account:", error);
          toast.error(t("an_error_occurred_while_deactivating_the_account"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleActivateProfessional = () => {
    DashboardServices.activateZhAccount(
      editEmployeesData.zhUserType,
      editEmployeesData.userId,
      userDetails.userId
    )
      .then((response) => {
        // console.log("response:", response.data);
        if (response.data.returnValue === "1") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchDoctorDetails();
          refreshViewPeopleData();
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        // console.error("Error activating account:", error);
        toast.error(t("an_error_occurred_while_activating_the_account"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  ////////////////////////////////////////////////////////////////////////////

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const finalSubmit = async () => {
    setLoading(true);

    const unitIds = userUnitList.map((unit) => unit.unitId.toString());

    for (let i = 0; i < additionalPositions.length; i++) {
      const { positionId, effectiveDate } = additionalPositions[i];

      if (positionId === 0 && effectiveDate === "") {
        // toast.error(`Please select both Position and Effective Date for Additional Position in Additional Position ${i + 1}`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        toast.error(t("selectPositionEffectiveDate", { index: i + 1 }), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }

      if (positionId !== 0 && effectiveDate === "") {
        // toast.error(`Please select the Effective Date for Additional Position in Additional Position ${i + 1}`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        toast.error(t("selectEffectiveDate", { index: i + 1 }), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }

      if (positionId === 0 && effectiveDate !== "") {
        // toast.error(`Please select the Position for Additional Position in Additional Position ${i + 1}`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        toast.error(t("selectPosition", { index: i + 1 }), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }
    }

    let positions = [];

    const primaryPos = allPositionData.find(
      (pos) => pos.positionId === emplyeePosition
    );
    if (primaryPos) {
      // console.log("primaryPosition =", primaryPos);
      positions = [
        {
          id:
            emplyPositionData.find(
              (item) => item.positionId === emplyeePosition
            )?.id || 0,
          positionId: emplyeePosition,
          positionType: "Primary",
          effectiveDate: formatDate(primaryPosdate),
          positionName: primaryPos?.position || "",
        },
        ...additionalPositions.map((pos) => ({
          id: pos.id || 0,
          positionId: pos.positionId,
          positionType: "Additional",
          effectiveDate: formatDate(pos.effectiveDate),
          positionName:
            allPositionData.find((p) => p.positionId === pos.positionId)
              ?.position || "",
        })),
      ];
    }

    if (emplyEmail.trim() === "") {
      toast.error(t("please_enter_email"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (!validateEmail(emplyEmail)) {
      toast.error(t("please_enter_correct_email"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (emplyPhoneNumber.trim() === "") {
      toast.error(t("please_enter_phone_number"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (emplymentType === "" || !emplymentType) {
      toast.error(t("please_enter_salary_type"));
      setLoading(false);
      return;
    }

    if ((emplymentId && emplymentId === "") || !emplymentId) {
      toast.error(t("please_fill_employment_id"));
      setLoading(false);
      return;
    }

    const pattern = /^[A-Z]+[0-9]*$/;

    if (emplymentId && !pattern.test(emplymentId)) {
      toast.error(
        t(
          "invalid_employee_id_it_should_start_with_uppercase_letters_and_end_with_numbers"
        ),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setLoading(false);
      return;
    }

    // console.log('orgEmplymentIds', orgEmplymentIds);

    let isEmployeeIdPresent = false;

    // console.log("editEmployeesData.employeeId", editEmployeesData.employeeId);
    // console.log("emplymentId", emplymentId);

    if (editEmployeesData.employeeId === emplymentId) {
      const filterCurrentEmpId = orgEmplymentIds.filter(
        (id) => id !== emplymentId
      );
      isEmployeeIdPresent = filterCurrentEmpId.some((id) => id === emplymentId);
    } else {
      isEmployeeIdPresent = orgEmplymentIds.some((id) => id === emplymentId);
    }

    if (isEmployeeIdPresent) {
      toast.error(t("employee_id_already_present"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (emplyeeUnits === "") {
      toast.error(t("please_select_unit"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (positions.length === 0) {
      toast.error(t("please_select_position"));
      setLoading(false);
      return;
    }

    let tmpCodeLangList = [];

    if (
      doctorEditdata &&
      Object.keys(doctorEditdata).length > 0 &&
      selectHealtProfession === "DOCTOR"
    ) {
      if (!qualificationValue || qualificationValue.trim() === "") {
        toast.error(t("please_enter_qualification"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }

      if (!specializationId || specializationId === 0) {
        toast.error(t("please_select_specialization"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }

      if (!userLanguageList || userLanguageList.length === 0) {
        toast.error(t("please_select_languages"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }

      if (userLanguageList.length > 3) {
        toast.error(t("you_cannot_add_more_then_three_languages"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      } else {
        for (let i = 0; i < userLanguageList.length; i++) {
          const { id } = userLanguageList[i];
          tmpCodeLangList.push(id);
        }
      }

      if (!currencyValue || currencyValue.trim() === "") {
        toast.error(t("please_select_currency"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }

      if (!doctorFeesValue || doctorFeesValue === 0) {
        toast.error(t("please_enter_fees"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }

      if (!licenseIssueAuthValue || licenseIssueAuthValue.trim() === "") {
        toast.error(t("please_enter_license_issuing_authority"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }

      if (!medicalLicenseNum || medicalLicenseNum.trim() === "") {
        toast.error(t("please_enter_medical_license_number"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }

      if (!MedicalLicenseUrl && (!fileData_Base64.trim() || !fileName.trim())) {
        toast.error(t("please_upload_a_medical_license_attachment"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }

      if (
        !doctorSignatureUrl &&
        (!signature_Base64.trim() || !signfileName.trim())
      ) {
        toast.error(t("please_upload_doctor_signature"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }

      if (
        selectHealtProfession === "DOCTOR" &&
        emplyTitle !== "Dr" &&
        emplyTitle !== "Prof"
      ) {
        toast.error(t("please_change_the_title_for_doctor"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }
    }

    // const reqDto = {
    //   title: emplyTitle,
    //   positions: positions,
    //   functionId: emplyeeDepartment,
    //   gender: emplyGender,
    //   employmentType: emplymentType,
    //   empStatus: emplymentStatus,
    //   joiningDate: formatDate(joiningdate),
    //   officialContactNo: emplyPhoneNumber,
    //   officialContactCallingNo: emplyPhnCC,
    //   unitId: emplyeeUnits,
    //   reportingHeadUserId: selectedReportHeadId,
    //   userId: editEmployeesData.userId,
    //   orgId: userDetails.orgId,
    //   employeeId: emplymentId,
    //   officialMailId: emplyEmail,
    //   shiftIds: selectedEmployeeShift,
    //   zhUnitDoctorDto: {
    //     doctorId: doctorEditdata.doctorId || "",
    //     doctorName: doctorEditdata.doctorName || "",
    //     specializationId: specializationId || 0,
    //     fees: doctorFeesValue || 0,
    //     feesCurrency: currencyValue || "",
    //     specializationName: specializationName || "",
    //     queueCount: 0,
    //     languages: tmpCodeLangList?.join(",") || "",
    //     experience: yearOfExpValue || "",
    //     avgConsultTime: 0,
    //     licenseIssuingAuth: licenseIssueAuthValue || "",
    //     salutation: selectHealtProfession === "DOCTOR" ? emplyTitle : "",
    //     qualification: qualificationValue || "",
    //     practiceStartDate: practiceStartdate
    //       ? DateUtils.getDateInDDMMYYYY(practiceStartdate)
    //       : "",
    //     doctorSignImageUrl: doctorUrlData || "",
    //     medicalLicenseNo: medicalLicenseNum || "",
    //     medicalLicenseCertificate: MedicalLicenseUrl ? "" : fileName,
    //     medicalLicenseCertificateUrl: MedicalLicenseUrl || "",
    //     medicalLicenseCertificateBase64Data: fileData_Base64 || "",
    //     doctorGender: "",
    //     doctorDob: "",
    //     unitIds: selectHealtProfession === "DOCTOR" ? unitIds : [],
    //     doctorProfileImageUrl: "",
    //     orgId:
    //       doctorEditdata && Object.keys(doctorEditdata).length > 0
    //         ? userDetails.orgId
    //         : "",
    //   },
    // };

    const zhUnitDoctorDto =
      selectHealtProfession === "DOCTOR"
        ? {
            doctorId: doctorEditdata.doctorId || null,
            doctorName:
              doctorEditdata.doctorName || editEmployeesData.personName,
            specializationId: specializationId || 0,
            fees: doctorFeesValue || 0,
            feesCurrency: currencyValue || "",
            specializationName: specializationName || "",
            queueCount: 0,
            languages: tmpCodeLangList?.join(",") || "",
            experience: yearOfExpValue || "",
            avgConsultTime: 0,
            licenseIssuingAuth: licenseIssueAuthValue || "",
            salutation: selectHealtProfession === "DOCTOR" ? emplyTitle : "",
            qualification: qualificationValue || "",
            practiceStartDate: practiceStartdate
              ? DateUtils.getDateInDDMMYYYY(practiceStartdate)
              : "",
            doctorSignImageUrl: doctorSignatureUrl,
            medicalLicenseNo: medicalLicenseNum || "",
            medicalLicenseCertificate: fileName,
            medicalLicenseCertificateUrl: MedicalLicenseUrl || "",
            medicalLicenseCertificateBase64Data: fileData_Base64 || "",
            doctorGender: emplyGender,
            doctorDob: "",
            unitIds: selectHealtProfession === "DOCTOR" ? unitIds : [],
            doctorProfileImageUrl: "",
            orgId: userDetails.orgId,
          }
        : {
            doctorId: null,
            doctorName: "",
            specializationId: 0,
            fees: 0,
            feesCurrency: "",
            specializationName: "",
            queueCount: 0,
            languages: "",
            experience: "",
            avgConsultTime: 0,
            licenseIssuingAuth: "",
            salutation: "",
            qualification: "",
            practiceStartDate: "",
            doctorSignImageUrl: "",
            medicalLicenseNo: "",
            medicalLicenseCertificate: "",
            medicalLicenseCertificateUrl: "",
            medicalLicenseCertificateBase64Data: "",
            doctorGender: "",
            doctorDob: "",
            unitIds: [],
            doctorProfileImageUrl: "",
            orgId: "",
          };

    const reqDto = {
      title: emplyTitle,
      positions,
      functionId: emplyeeDepartment,
      gender: emplyGender,
      employmentType: emplymentType,
      empStatus: emplymentStatus,
      joiningDate: formatDate(joiningdate),
      officialContactNo: emplyPhoneNumber,
      officialContactCallingNo: emplyPhnCC,
      unitId: emplyeeUnits,
      reportingHeadUserId: selectedReportHeadId,
      userId: editEmployeesData.userId,
      orgId: userDetails.orgId,
      employeeId: emplymentId,
      officialMailId: emplyEmail,
      shiftIds: selectedEmployeeShift,
      healthcareProfessionalType: selectHealtProfession,
      zhUnitDoctorDto,
    };

    // console.log("reqDto", reqDto);

    // setLoading(false);
    // return;

    editEmployee(userDetails.userId, reqDto)
      .then((response) => {
        // console.log("editEmployee", response.data);
        setLoading(false);
        if (response.data.returnValue === "1") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          if (signfileName && signature_Base64) {
            const reqDto = {
              fileName: signfileName,
              fileData: signature_Base64,
            };

            DashboardServices.updateSignature(
              doctorEditdata.doctorId,
              userDetails.orgId,
              reqDto
            ).then((response) => {
              // console.log("response", response.data);
              setDoctorSignatureUrl(response.data);
            });
          }
          refreshViewPeopleData();
          handelCloseEditEmployee();
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#4c4c4c",
      color: "white",
      maxWidth: 300,
      fontSize: "14px",
      border: "1px solid #4c4c4c",
    },
  }));

  return (
    <>
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">{t("edit_employee")}</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseEditEmployee()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            <div className="rfContContainDetails">
              {loading ? (
                <div className="meetingVEContainer">
                  <div className="center-progress" style={{ height: "65vh" }}>
                    <CircularProgress sx={{ marginTop: "180px" }} />
                  </div>
                </div>
              ) : (
                <div className="elementFormContainer">
                  <div class="tskElemHeding">{t("employee_demography")}</div>
                  <div className="formElementGrp">
                    <div className="formElement mxW-100">
                      <FormControl className="formControl">
                        <InputLabel id="demo-simple-select-label">
                          {t("title")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={emplyTitle}
                          label={t("title")}
                          onChange={handleChangeEmplyTitle}
                          className="formInputField"
                          variant="outlined"
                          // disabled
                        >
                          <MenuItem value="Mr">{t("mr")}</MenuItem>
                          <MenuItem value="Ms">{t("ms")}</MenuItem>
                          <MenuItem value="Mrs">{t("mrs")}</MenuItem>
                          <MenuItem value="Miss">{t("miss")}</MenuItem>
                          <MenuItem value="Dr">{t("dr")}</MenuItem>
                          <MenuItem value="Prof">{t("Prof")}</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          label={t("first_name")}
                          required
                          placeholder={t("first_name")}
                          variant="outlined"
                          value={emplyFirstName}
                          className="formTextFieldArea"
                          onChange={handleChangeEmplyFirstName}
                          disabled
                        />
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          label={t("middle_name")}
                          placeholder={t("middle_name")}
                          variant="outlined"
                          value={emplyMiddleName}
                          className="formTextFieldArea"
                          onChange={handleChangeEmplyMiddleName}
                          disabled
                        />
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          required
                          label={t("last_name")}
                          placeholder={t("last_name")}
                          variant="outlined"
                          value={emplyLastName}
                          className="formTextFieldArea"
                          onChange={handleChangeEmplyLastName}
                          disabled
                        />
                      </FormControl>
                    </div>
                  </div>

                  <div className="formElementGrp">
                    <div className="formElement mxW-100">
                      <FormControl className="formControl">
                        <InputLabel id="demo-simple-select-label">
                          {t("gender")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={emplyGender}
                          label={t("gender")}
                          onChange={handleChangeEmplyGender}
                          className="formInputField"
                          variant="outlined"
                        >
                          <MenuItem value="Male">{t("male")}</MenuItem>
                          <MenuItem value="Female">{t("female")}</MenuItem>
                          <MenuItem value="Others">{t("others")}</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          label={t("official_email_id")}
                          required
                          autoComplete="off"
                          placeholder={t("official_email_id")}
                          variant="outlined"
                          value={emplyEmail}
                          className="formTextFieldArea"
                          onChange={handleChangeEmplyEmail}
                          // disabled
                          disabled={editEmployeesData.officialEmailId !== ""}
                        />
                      </FormControl>
                    </div>

                    <div className="formElement mxW-100">
                      <FormControl className="formControl">
                        <InputLabel id="ccode">{t("country_code")}</InputLabel>
                        <Select
                          labelId="ccode"
                          id="demo-simple-select"
                          value={emplyPhnCC}
                          label={t("country_code")}
                          onChange={handleChangeemplyPhnCC}
                          className="formInputField"
                          variant="outlined"
                        >
                          {/* <MenuItem value="91">+91</MenuItem>
                        <MenuItem value="1">+1</MenuItem>
                        <MenuItem value="374">+374</MenuItem> */}
                          {cdCodeList.map((cdCodeList) => (
                            <MenuItem key={cdCodeList} value={cdCodeList}>
                              {cdCodeList}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          required
                          autoComplete="off"
                          label={t("official_phone_number")}
                          variant="outlined"
                          value={emplyPhoneNumber}
                          className="formTextFieldArea"
                          onChange={handleChangeEmplyPhoneNumber}
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                        />
                      </FormControl>
                    </div>
                  </div>

                  <div class="tskElemHeding mt10">
                    {t("employement_demography")}
                  </div>

                  <div className="formElementGrp flex-wrap">
                    <div className="formElement width13">
                      <FormControl className="formControl">
                        <InputLabel
                          id="employeeJoingDate"
                          className="setCompletionDate"
                        >
                          {t("joining_date")}
                        </InputLabel>
                        <ReactDatePicker
                          showIcon
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          labelId="employeeJoingDate"
                          className="formDatePicker"
                          selected={joiningdate}
                          // minDate={new Date()}
                          minDate={parseDateString(orgDetailsDoiData)}
                          maxDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => {
                            handleJoiningDate(date);
                          }}
                        />
                      </FormControl>
                    </div>

                    {/* <div className="formElement width13">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          required
                          label="Employment Type"
                          placeholder="Employment Type"
                          variant="outlined"
                          value={emplymentType}
                          className="formTextFieldArea"
                          onChange={handleChangeEmplyType}
                        />
                      </FormControl>
                    </div> */}

                    <div className="formElement width13">
                      <FormControl className="formControl">
                        <InputLabel id="selectUnits">
                          {t("salary_type")} *
                        </InputLabel>
                        <Select
                          required
                          labelId="selectUnits"
                          id="employee-units"
                          value={emplymentType}
                          label={t("salary_type")}
                          onChange={handleChangeEmplyType}
                          className="formInputField"
                          variant="outlined"
                        >
                          {emplyTypes.map((value) => (
                            <MenuItem key={value.id} value={value.id}>
                              {value.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    {/* <div className="formElement width13">
                      <FormControl className="formControl">
                        <Autocomplete
                          disablePortal
                          id="selectEmployee"
                          className="formAutocompleteField"
                          variant="outlined"
                          options={emplyTypes}
                          getOptionLabel={(option) => option}
                          value={emplymentType}
                          inputValue={emplymentTypeInput}
                          onInputChange={handleChangeEmplyTypeInput}
                          filterOptions={filterEmplyOptions}
                          onChange={handleChangeEmplyType}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Salary Type *"
                              variant="outlined"
                              className="formAutoComInputField autocompFildPlaceholder"
                            />
                          )}
                        />
                      </FormControl>
                    </div> */}

                    {/* <div className="formElement width13">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          label={t("employee_id")}
                          required
                          autoComplete="off"
                          placeholder={t("employee_id")}
                          variant="outlined"
                          value={emplymentId}
                          className="formTextFieldArea"
                          onChange={handleChangeEmplyId}
                          // disabled
                        />
                      </FormControl>
                    </div> */}

                    <div className="formElement width13">
                      <FormControl className="formControl" variant="outlined">
                        <InputLabel id="employee-id-label">
                          {t("employee_id")}*
                        </InputLabel>
                        <OutlinedInput
                          labelId="employee-id-label"
                          label={t("employee_id")}
                          required
                          autoComplete="off"
                          placeholder={t("employee_id")}
                          value={emplymentId}
                          className="formTextFieldArea"
                          onChange={handleChangeEmplyId}
                          endAdornment={
                            <InputAdornment position="end">
                              {/* <Tooltip
                                title={
                                  <>
                                  {t("last_employee_ids")} :
                                  <ul>
                                    {Object.entries(prefixMaxMap).map(
                                      ([prefix, maxNumber]) => (
                                        <li key={prefix}>
                                          {prefix}{maxNumber}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                  </>
                                }
                                arrow
                              >
                                <IconButton className="infoBtn">
                                  <Info />
                                </IconButton>
                              </Tooltip> */}
                              <Tooltip
                                title={
                                  <>
                                    <span
                                      style={{
                                        display: "inline-block",
                                        marginRight: "5px",
                                      }}
                                    >
                                      {t("last_employee_ids")}:
                                    </span>
                                    <ul
                                      style={{
                                        paddingLeft: "15px",
                                        marginTop: "0",
                                        marginBottom: "0",
                                      }}
                                    >
                                      {Object.entries(prefixMaxMap).map(
                                        ([prefix, maxNumber]) => (
                                          <li key={prefix}>
                                            {prefix} {maxNumber}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </>
                                }
                                arrow
                              >
                                <IconButton className="infoBtn">
                                  <Info />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </div>

                    <div className="formElement width13">
                      <FormControl className="formControl">
                        <InputLabel id="employeeStstus">
                          {t("employement_status")}
                        </InputLabel>
                        <Select
                          labelId="employeeStstus"
                          id="employee-Ststus"
                          value={emplymentStatus}
                          label={t("employement_status")}
                          onChange={handleChangeEmplyStatus}
                          className="formInputField"
                          variant="outlined"
                        >
                          {/* <MenuItem value="In Probation">In Probation</MenuItem>
                          <MenuItem value="Confirmed">Confirmed</MenuItem> */}
                          {emplyStatusData.map((status) => (
                            <MenuItem key={status.id} value={status.id}>
                              {status.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="formElement width13">
                      <FormControl className="formControl">
                        <InputLabel id="selectUnits">
                          {t("select_unit")}{" "}
                        </InputLabel>
                        <Select
                          labelId="selectUnits"
                          id="employee-units"
                          value={emplyeeUnits}
                          label={t("select_unit")}
                          onChange={handleChangEemplyeeUnits}
                          className="formInputField"
                          variant="outlined"
                        >
                          <MenuItem key={"null_unit"} value={null}>
                            {t("select_unit")}
                          </MenuItem>
                          {unitsData.map((unit, index) => (
                            <MenuItem key={index} value={unit.unitId}>
                              {unit.unitName}
                            </MenuItem>
                          ))}
                          {/* <MenuItem value="kolkataho">Kolkata HO</MenuItem>
                        <MenuItem value="delhi">Delhi</MenuItem>
                        <MenuItem value="pune">Pune</MenuItem> */}
                        </Select>
                      </FormControl>
                    </div>

                    {/* <div className="formElement width13">
                      <FormControl className="formControl">
                        <InputLabel id="employeeDepartment">
                          Department
                        </InputLabel>
                        <Select
                          labelId="employeeDepartment"
                          id="employee-Position"
                          value={emplyeeDepartment}
                          label="Department "
                          onChange={handleChangemplyeeDepartment}
                          className="formInputField"
                          variant="outlined"
                        >
                          
                          {departmentData.map((department) => (
                            <MenuItem
                              key={department.functionId}
                              value={department.functionId}
                            >
                              {department.functionName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div> */}
                  </div>

                  <div class="tskElemHeding mt10">{t("employee_position")}</div>

                  <div className="formElementGrp">
                    <div className="formElement width13">
                      <FormControl className="formControl">
                        <InputLabel id="primaryPosition">
                          {t("primary_position")} *
                        </InputLabel>
                        <Select
                          labelId="primaryPosition"
                          id="employee-Position"
                          value={emplyeePosition}
                          label={t("primary_position")}
                          onChange={handleChangEmplyeePosition}
                          className="formInputField"
                          variant="outlined"
                        >
                          {allPositionData.map((position) => (
                            <MenuItem
                              key={position.positionId}
                              value={position.positionId}
                            >
                              {position.position}
                            </MenuItem>
                          ))}

                          {/* <MenuItem value="manager">Manager</MenuItem>
                        <MenuItem value="teamLead">Team lead</MenuItem>
                        <MenuItem value="developer">Developer</MenuItem> */}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="formElement width13">
                      <FormControl className="formControl">
                        <InputLabel
                          id="primPosition"
                          className="setCompletionDate"
                        >
                          {t("with_effect_from")}
                        </InputLabel>
                        <ReactDatePicker
                          showIcon
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          labelId="primPosition"
                          className="formDatePicker"
                          selected={primaryPosdate}
                          // minDate={new Date()}
                          minDate={parseDateString(orgDetailsDoiData)}
                          maxDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => {
                            handleJoiningprimaryPosdate(date);
                          }}
                        />
                      </FormControl>
                    </div>

                    <div className="addNewPosition">
                      <HtmlTooltip title="Additional Position">
                        <Button
                          startIcon={<Add />}
                          className="dfultPrimaryBtn"
                          // onClick={() => handelOpenNewPosition()}
                          onClick={handleAddPosition}
                        >
                          <span className="addPosBtnPrm">
                            {t("additional_position")}
                          </span>
                        </Button>
                      </HtmlTooltip>
                    </div>
                  </div>

                  {additionalPositions.map((position, index) => (
                    <div key={index} className="formElementGrp">
                      <div className="formElement width13">
                        <FormControl className="formControl">
                          <InputLabel id={`addPosition-${index}`}>
                            {t("additional_position")}
                          </InputLabel>
                          <Select
                            required
                            labelId={`addPosition-${index}`}
                            value={position.positionId}
                            label="Select Position"
                            onChange={(event) =>
                              handleChangadditionalPosition(index, event)
                            }
                            className="formInputField"
                            variant="outlined"
                          >
                            {allPositionData.map((position) => (
                              <MenuItem
                                key={position.positionId}
                                value={position.positionId}
                              >
                                {position.position}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>

                      <div className="formElement width13">
                        <FormControl className="formControl">
                          <InputLabel
                            id={`secondPosition-${index}`}
                            className="setCompletionDate"
                          >
                            {t("with_effect_from")}
                          </InputLabel>
                          <ReactDatePicker
                            showIcon
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            showMonthDropdown
                            labelId={`secondPosition-${index}`}
                            className="formDatePicker"
                            selected={position.effectiveDate}
                            maxDate={new Date()}
                            minDate={parseDateString(orgDetailsDoiData)}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) =>
                              handleSecondPosdateChange(index, date)
                            }
                          />
                        </FormControl>
                      </div>

                      <div className="addNewPosition tblActionBtnGrp">
                        {/* <HtmlTooltip title="Additional Position">
                          <Button
                            startIcon={<Add />}
                            className="dfultPrimaryBtn"
                            onClick={handleAddPosition}
                          >
                            <span className="addPosBtnPrm">
                              Addition Position
                            </span>
                          </Button>
                        </HtmlTooltip> */}
                        <IconButton
                          onClick={() => handleRemovePosition(index)}
                          className="removeRowBtn"
                        >
                          <Trash />
                        </IconButton>
                      </div>
                    </div>
                  ))}

                  <div class="tskElemHeding mt10"></div>

                  <div className="formElement ">
                    <FormControl className="formControl">
                      <Autocomplete
                        noOptionsText="Please type to search"
                        // autoHighlight={true}
                        // disablePortal
                        id="selectEmployee"
                        className="formAutocompleteField"
                        variant="outlined"
                        // freeSolo
                        options={reportingHeadData}
                        getOptionLabel={(option) =>
                          option && option.userName && option.userDtl
                            ? `${option.userName}  ( ${option.userDtl} )`
                            : option && option.userName
                            ? `${option.userName} `
                            : ""
                        }
                        value={emplyeeReportingHead}
                        inputValue={inputValue}
                        onInputChange={handleInputChange}
                        filterOptions={filterOptions}
                        onChange={(e, data) => {
                          if (data && data.userId) {
                            setSelectedReportHeadId(data.userId);
                            setEmplyeeReportingHead(data);
                          } else {
                            setSelectedReportHeadId(null);
                            setEmplyeeReportingHead({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="formAutoComInputField autocompFildPlaceholder"
                            {...params}
                            label={t("reporting_head")}
                            variant="outlined"
                          />
                        )}
                      />
                    </FormControl>
                  </div>

                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="Employment_Type">{t("shift")}</InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="Employment_Type"
                        id="EmploymentType"
                        multiple
                        value={selectedEmployeeShift}
                        onChange={handleChangeEmploymentType}
                        input={<OutlinedInput label={t("salary_type")} />}
                        renderValue={(selected) => {
                          const sList = selectedEmployeeShift.map((shift) => {
                            const index = shiftList.findIndex(
                              (item) => item.shiftId === shift
                            );
                            if (index >= 0) {
                              return shiftList[index].name;
                            }
                            return "";
                          });

                          return sList.join(", ");
                        }}
                        MenuProps={MenuProps}
                      >
                        {shiftList.map((shift) => (
                          <MenuItem key={shift.shiftId} value={shift.shiftId}>
                            <Checkbox
                              checked={
                                selectedEmployeeShift.indexOf(shift.shiftId) >
                                -1
                              }
                            />
                            <ListItemText primary={shift.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  {HealthcareProvider === "Y" && (
                    <div className="formElementGrp">
                      <div className="formElement">
                        <FormControl className="formControl">
                          <InputLabel id="health_care_professional">
                            {t("select_health_care_profession")}
                          </InputLabel>
                          <Select
                            className="formInputField"
                            variant="outlined"
                            labelId="health_care_professional"
                            id="health_care-professional"
                            value={selectHealtProfession}
                            onChange={handleSelectedProfessionalValue}
                            disabled={
                              selectHealtProfession === "NURSE" ||
                              selectHealtProfession === "LT"
                            }
                          >
                            <MenuItem value="">{t("select_menu")}</MenuItem>
                            <MenuItem value="DOCTOR">{t("doctor")}</MenuItem>
                            <MenuItem value="NURSE">{t("nurse")}</MenuItem>
                            <MenuItem value="LT">
                              {t("lab_technician")}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      {doctorEditdata.activeFlag === "N" && (
                        <div className="taskVDContBtn">
                          <Button
                            variant="outlined"
                            onClick={() => handleActivateProfessional()}
                          >
                            {t("activate")}
                          </Button>
                        </div>
                      )}
                    </div>
                  )}

                  {selectHealtProfession === "DOCTOR" &&
                    // doctorEditdata &&
                    // doctorEditdata.activeFlag === "Y" &&
                    HealthcareProvider === "Y" && (
                      <>
                        {/* <div class="tskElemHeding mt10">
                        Health Care Profession
                      </div> */}

                        <div class="tskElemHeding mt10">
                          {t("health_care_profession")}

                          {selectHealtProfession === "DOCTOR" &&
                            doctorEditdata &&
                            doctorEditdata.activeFlag === "Y" &&
                            HealthcareProvider === "Y" && (
                              <label style={{ marginLeft: "10px" }}>
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={handleCheckboxChange}
                                />
                                {t("deactivate_health_care_professional")}
                              </label>
                            )}
                        </div>

                        <div className="formElementGrp">
                          {/* <div className="formElement mxW-100">
                          <FormControl className="formControl">
                            <TextField
                              label="Salutation"
                              // required
                              autoComplete="off"
                              placeholder="Enter Salutation"
                              variant="outlined"
                              value={salutationValue}
                              className="formTextFieldArea"
                              onChange={handleSalutationValue}
                            />
                          </FormControl>
                        </div> */}

                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label={t("qualification")}
                                required
                                autoComplete="off"
                                placeholder={t("enter_qualification")}
                                variant="outlined"
                                value={qualificationValue || ""}
                                className="formTextFieldArea"
                                onChange={handleQualificationValue}
                              />
                            </FormControl>
                          </div>

                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel id="health_care_professional">
                                {t("specialization")}*
                              </InputLabel>
                              <Select
                                className="formInputField"
                                variant="outlined"
                                labelId="health_care_professional"
                                id="health_care-professional"
                                value={specializationId || 0}
                                onChange={handleSpecializationValue}
                              >
                                {specializationList.map((value) => (
                                  <MenuItem
                                    key={value.specializationId}
                                    value={value.specializationId}
                                  >
                                    {value.specializationName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        </div>

                        <div className="formElementGrp">
                          <div className="formElement ">
                            <FormControl className="formControl">
                              <InputLabel
                                id="employeeJoingDate"
                                className="setCompletionDate"
                              >
                                {t("practice_start_date")}*
                              </InputLabel>
                              <ReactDatePicker
                                toggleCalendarOnIconClick
                                showYearDropdown
                                yearDropdownItemNumber={100}
                                scrollableYearDropdown
                                showMonthDropdown
                                showIcon
                                labelId="employeeJoingDate"
                                className="formDatePicker"
                                selected={practiceStartdate}
                                // minDate={new Date()}
                                maxDate={new Date()}
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => {
                                  handlePracticeStartDate(date);
                                }}
                              />
                            </FormControl>
                          </div>

                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label={t("year_of_experience")}
                                required
                                autoComplete="off"
                                placeholder={t("enter_year_of_experience")}
                                variant="outlined"
                                value={yearOfExpValue}
                                className="formTextFieldArea"
                                onChange={handleYearOfExpValue}
                                disabled
                              />
                            </FormControl>
                          </div>
                        </div>

                        <div className="formElementGrp">
                          <div className="formElement">
                            <FormControl className="formControl">
                              <Autocomplete
                                // disabled={enableLanguage}
                                multiple
                                className="formAutocompleteField"
                                variant="outlined"
                                value={userLanguageList}
                                options={languageMasterList}
                                onChange={(e, data) => {
                                  if (data.length > 3) {
                                    toast.error(
                                      t(
                                        "you_cannot_select_more_than_three_languages"
                                      )
                                    );
                                    return;
                                  }
                                  setUserLanguageList(data);
                                }}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    className="formAutoComInputField autocompFildPlaceholder"
                                    placeholder={t("select_languages")}
                                    // label="Languages*"
                                    required
                                    label={t("languages")}
                                    InputProps={{
                                      ...params.InputProps,
                                      type: "search",
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </div>
                        </div>

                        <div className="formElementGrp">
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel id="demo-simple-select-label">
                                {t("currency")}*
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={currencyValue}
                                label={t("currency")}
                                onChange={handleChangeCurrency}
                                className="formInputField"
                                variant="outlined"
                              >
                                {currencyList.map((currency) => (
                                  <MenuItem key={currency} value={currency}>
                                    {currency}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>

                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label={t("fees")}
                                required
                                autoComplete="off"
                                placeholder={t("enter_fees")}
                                variant="outlined"
                                value={doctorFeesValue}
                                className="formTextFieldArea"
                                onChange={handleDoctorFeesChange}
                              />
                            </FormControl>
                          </div>
                        </div>

                        <div className="formElementGrp">
                          <div className="formElement">
                            <FormControl className="formControl">
                              <Autocomplete
                                // disabled={enableLanguage}
                                multiple
                                className="formAutocompleteField"
                                variant="outlined"
                                value={userUnitList}
                                // options={unitsData}
                                options={
                                  Array.isArray(unitsData) ? unitsData : []
                                }
                                // onChange={(e, data) => {
                                //   console.log("data", data);
                                //   setUserUnitList(data);
                                // }}
                                onChange={handleUnitListChange}
                                getOptionLabel={(option) => option.unitName}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    className="formAutoComInputField autocompFildPlaceholder"
                                    placeholder={t(
                                      "select_doctor_available_units"
                                    )}
                                    // label="Doctor available in units*"
                                    required
                                    label={t("doctor_available_in_units")}
                                    InputProps={{
                                      ...params.InputProps,
                                      type: "search",
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </div>
                        </div>

                        <div className="formElementGrp">
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label={t("license_issuing_authority")}
                                required
                                autoComplete="off"
                                placeholder={t(
                                  "enter_license_issuing_authority"
                                )}
                                variant="outlined"
                                value={licenseIssueAuthValue || ""}
                                className="formTextFieldArea"
                                onChange={handleLicenseIssueAuthChange}
                              />
                            </FormControl>
                          </div>
                        </div>

                        <div className="formElementGrp">
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label={t("medical_license_number")}
                                required
                                autoComplete="off"
                                placeholder={t("enter_medical_license_number")}
                                variant="outlined"
                                value={medicalLicenseNum || ""}
                                className="formTextFieldArea"
                                onChange={handleMedicalLicenseNumChange}
                              />
                            </FormControl>
                          </div>

                          <div className="formElement">
                            {!MedicalLicenseUrl && (
                              <div className="formBtnElement">
                                <CoustomTooltip
                                  title={
                                    <>
                                      <div className="uploadedFilesTooltip">
                                        <div>
                                          {t("allowed_file_types")}:{" "}
                                          <div>
                                            {ADD_DOCTOR_FILE_EXTENSION_MEETING.join(
                                              ","
                                            )}
                                          </div>
                                        </div>
                                        {/* <div>Maximum file size {MAX_FILE_SIZE_TASK} MB</div> */}
                                        <div>
                                          {t("maximum_file_size", {
                                            maxFileSize: MAX_FILE_SIZE_TASK,
                                          })}
                                        </div>
                                      </div>
                                    </>
                                  }
                                >
                                  <Button
                                    variant="outlined"
                                    startIcon={<AttachFile />}
                                    // className={`comBtnOutline  ${document ? "activeStatus" : ""}`}
                                    className="comBtnOutline"
                                    onClick={(e) => {
                                      fileRef.current.click();
                                    }}
                                  >
                                    {t("attach_file")}*{/* Attach File* */}
                                  </Button>
                                </CoustomTooltip>
                                <input
                                  ref={fileRef}
                                  hidden
                                  type="file"
                                  style={{ display: "none" }}
                                  // onChange={handleFileChange}
                                  onChange={(e) => {
                                    handleFileChange(e);
                                    fileRef.current.value = null;
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        {fileName && (
                          <div className="formElementGrp">
                            <div class="elementListGrp">
                              <div class="elementHeding">{t("file_added")}</div>
                              <ul class="elmListInfo">
                                <li>
                                  <span
                                    className="rolsNm"
                                    onClick={() => {
                                      window.open(MedicalLicenseUrl, "_blank");
                                      // AppUtils.downloadFileAws()
                                    }}
                                  >
                                    {fileName}
                                  </span>
                                  <IconButton
                                    className="rmvBtn"
                                    onClick={handleDeleteFile}
                                  >
                                    <Trash />
                                  </IconButton>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}

                        {/* <div className="formElementGrp">
                      <div className="formElement">
                        <FormControl className="formControl">
                          <h4>Signature</h4>
                        </FormControl>
                      </div>

                      <div className="formElement">
                        <div className="formBtnElement">
                          <Button
                            variant="outlined"
                            startIcon={<AttachFile />}
                            className={`comBtnOutline  ${document ? "activeStatus" : ""}`}
                          >
                            {t("attach_file")}
                          </Button>
                        </div>
                      </div>


                    </div> */}

                        <div className="profileFormElementGroup fromWithEdtUpldBtn">
                          <div className="signatureUploadArea">
                            {!signfileName && !doctorSignatureUrl && (
                              <HtmlTooltip
                                title={t("details_of_file_to_upload_in_sign")}
                              >
                                <div
                                  className="noSignAvl"
                                  onClick={() => signImageRef.current.click()}
                                >
                                  <div className="noSignAvlTxt">
                                    {t("no_sign_avalable")}
                                  </div>
                                  <div className="btnTouploadSign">
                                    {t("click_here_to_upload_sign")}*
                                  </div>
                                </div>
                              </HtmlTooltip>
                            )}
                            <input
                              ref={signImageRef}
                              type="file"
                              hidden
                              accept=".jpg, .jpeg, .png"
                              onChange={(e) => {
                                handleUpdateSignature(e);
                                signImageRef.current.value = null;
                              }}
                            />
                            {/* {signfileName || doctorSignatureUrl && (
                            <>
                            {doctorSignatureUrl && (
                              <div className="signAvl">
                              <img
                                src={doctorSignatureUrl}
                                alt="Signature"
                                onError={(e) => {
                                  console.error(
                                    "Error loading image:",
                                    e.target.src
                                  );
                                }}
                              />
                            </div>
                            ) }
                            {signfileName && (
                              <div className="signAvl">
                                <img
                                  src={`data:image/png;base64,${signature_Base64}`}
                                  alt="Signature"
                                  onError={(e) => {
                                    console.error(
                                      "Error loading image:",
                                      e.target.src
                                    );
                                  }}
                                />
                              </div>
                              )}

                            </>
                          )} */}
                            {(doctorSignatureUrl || signfileName) && (
                              <>
                                {doctorSignatureUrl ? (
                                  <div className="signAvl">
                                    <img
                                      src={doctorSignatureUrl}
                                      alt={t("signature")}
                                      // onError={(e) => {
                                      //   console.error(
                                      //     "Error loading image:",
                                      //     e.target.src
                                      //   );
                                      // }}
                                    />
                                  </div>
                                ) : (
                                  signfileName && (
                                    <div className="signAvl">
                                      <img
                                        src={`data:image/png;base64,${signature_Base64}`}
                                        alt={t("signature")}
                                        // onError={(e) => {
                                        //   console.error(
                                        //     "Error loading image:",
                                        //     e.target.src
                                        //   );
                                        // }}
                                      />
                                    </div>
                                  )
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        {(doctorSignatureUrl || signfileName) && (
                          <div className="formBtnElement">
                            <HtmlTooltip title="Allowed File PNG, JPG, or JPEG format and size range of 10 KB to 200 KB. Image dimension, height 50 px to 512 px, with background either transparent or white.">
                              <Button
                                className="comBtnOutline"
                                startIcon={<Autorenew />}
                                onClick={() => signImageRef.current.click()}
                              >
                                {t("re_upload_sign")}
                              </Button>
                            </HtmlTooltip>
                          </div>
                        )}
                      </>
                    )}
                </div>
              )}
            </div>
          </div>
          <div className="rfContFooter">
            <div className="formBtnElement">
              {/* <Button className="dfultPrimaryBtn" onClick={() => finalSubmit()}>
                Save
              </Button> */}
              <Button
                className="dfultPrimaryBtn"
                onClick={() => finalSubmit()}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <>{t("submit")}</>
                )}
              </Button>
              {!loading && (
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => handelCloseEditEmployee()}
                >
                  {t("cancel")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
