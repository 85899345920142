import { Add, Edit } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import zhPatientService from "../../../../../services/ZhPatientService";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import { Trash } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { ViewCompound } from "./ViewCompound";
import { CustomModal } from "../../../../task/view/component/Modals/CustomModal";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 320,
    maxHeight: 200,
    overflow: "overlay",
    padding: "6px 10px",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export default function DefineHealthcareConfig({
  handelOpenAddNewSTP,
  handleOpenEditSTP,
}) {
  const { t } = useTranslation();
  const loginUserDetails = getLoginUserDetails();
  const [stpList, setStpList] = useState([]);
  const [regimeComponent, setRegimeComponent] = useState({});
  const [showCustomComponent, setShowCustomComponent] = useState(false);
  const [customCompoundData, setShowCustomCompoundData] = useState(null);

  function fetchStp() {
    zhPatientService.getStpList(loginUserDetails.orgId).then((response) => {
      if (response.data) {
        const tempStpList = response.data?.map((item) => {
          const {
            test,
            procedure,
            ptm,
            lifestyle,
            regime,
            diseaseName,
            stpId,
          } = item;
          const testList = test?.split("~") || [];
          const procedureList = procedure?.split("~") || [];
          const ptmList = ptm?.split("~") || [];
          const lifeStyleList = lifestyle?.split("~") || [];
          const regimeComponent = regime?.split("~");
          const regimes = regimeComponent?.map((regime) => {
            const temp = regime.split("$$$");
            const regimeName = temp?.[0];
            const ageGroup = temp?.[1];

            fetchRegimeCompound(regimeName, ageGroup, stpId);
            return {
              regimeName,
              ageGroup,
            };
          });
          const ageGroup = regimeComponent?.[1] || "";
          const dto = {
            ...item,
            test: testList,
            procedure: procedureList,
            ptm: ptmList,
            lifestyle: lifeStyleList,
            regimes,
            ageGroup,
          };

          return dto;
        });
        setStpList(tempStpList);
      }
    });
  }

  const fetchRegimeCompound = (regimeName, ageGroup, stpId) => {
    const dto = {
      stpId,
      regime: regimeName,
      ageGroup,
    };
    function buildTree(data) {
      const map = {}; // A map to store nodes by indexId
      const tree = []; // Array to hold the root nodes

      // Initialize nodes and store in map
      data.forEach((item) => {
        map[item.indexId] = { ...item };
      });

      // Construct tree structure
      data.forEach((item) => {
        const node = map[item.indexId];
        if (item.previousIndexId === 0) {
          // No parent, so it is a root node
          tree.push(node);
        } else {
          // Find the parent node using previousIndexId
          const parent = map[item.previousIndexId];
          if (parent) {
            if (node.operatorName === "Then") {
              if (parent.thenList) {
                parent.thenList.push(node);
              } else {
                parent.thenList = [node];
              }
            } else if (node.operatorName === "Or") {
              if (parent.orList) {
                parent.orList.push(node);
              } else {
                parent.orList = [node];
              }
            }
          }
        }
      });

      return tree;
    }
    if (!regimeComponent?.[`${stpId}~${regimeName}~${ageGroup}`]) {
      zhPatientService.getCompoundsOfRegime(dto).then((response) => {
        if (response.data) {
          const tree = buildTree(response.data);
          setRegimeComponent((prev) => {
            const temp = { ...prev };
            temp[`${stpId}~${regimeName}~${ageGroup}`] = tree;
            console.log("tree value=", temp);
            return temp;
          });
        }
      });
    }
  };

  useEffect(() => {
    fetchStp();
  }, []);

  const deactivateStp = (stpId) => {
    zhPatientService
      ?.deactivatStp(loginUserDetails.userId, stpId)
      .then((response) => {
        if (response.data) {
          toast.success("Stp deleted.");
          //TODO: manually handle data to avoid re render
          fetchStp();
        }
      });
  };

  return (
    <>
      <div className="fullContainArea">
        <div className="cuHeaderGrp">
          <div className="CalendarBtnViewGroup">
            <ButtonGroup>
              <Button>Vitals Unit</Button>
              <Button className="activeBtn">Standard Treatment Protocol</Button>
              <Button>Unit, Doctor Mapping</Button>
              <Button>Unit Investigation Mapping</Button>
            </ButtonGroup>
          </div>

          <div className="addUnitsBtn">
            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => handelOpenAddNewSTP()}
            >
              {t("add")}
            </Button>
          </div>
        </div>

        <div className="cuContainArea">
          <div className="TableContainer">
            <table className="taskListTable">
              <thead className="taskListtableHeader">
                <tr>
                  <th>Disease</th>
                  <th>Investigation</th>
                  <th>Monitoring Parameters</th>
                  <th>Lifestyle</th>
                  <th>Procedure</th>
                  <th>Regime Name</th>
                  <th className="width100">{t("action")}</th>
                </tr>
              </thead>
              <tbody className="scrolableTableBody">
                {stpList?.map((item) => {
                  return (
                    <>
                      <tr>
                        <td>
                          <div className="tskLstTaskNM">
                            {item?.diseaseName}
                          </div>
                        </td>
                        <td>
                          <Tooltip title={item?.test?.join(",")} arrow>
                            <div className="tskLstTaskNM eclipTextlin2">
                              {item?.test?.join(",")}
                            </div>
                          </Tooltip>
                        </td>
                        <td>
                          <Tooltip title={item?.ptm?.join(",")} arrow>
                            <div className="tskLstTaskNM eclipTextlin2">
                              {item?.ptm?.join(",")}
                            </div>
                          </Tooltip>
                        </td>
                        <td>
                          <Tooltip title={item?.lifestyle?.join(",")} arrow>
                            <div className="tskLstTaskNM eclipTextlin2">
                              {item?.lifestyle?.join(",")}
                            </div>
                          </Tooltip>
                        </td>
                        <td>
                          <Tooltip title={item?.procedure?.join(",")} arrow>
                            <div className="tskLstTaskNM eclipTextlin2">
                              {item?.procedure?.join(",")}
                            </div>
                          </Tooltip>
                        </td>

                        <td>
                          <ul className="regimeLstVewPg">
                            {item?.regimes?.map((regime, index) => {
                              return (
                                <>
                                  {console.log(
                                    "regime vaue  = ",
                                    regimeComponent[
                                      `${item.stpId}~${regime.regimeName}~${regime.ageGroup}`
                                    ]
                                  )}
                                  {regimeComponent[
                                    `${item.stpId}~${regime.regimeName}~${regime.ageGroup}`
                                  ] ? (
                                    <HtmlTooltip
                                      className="regimeTooltip"
                                      title={
                                        <>
                                          <ViewCompound
                                            compoundList={
                                              regimeComponent[
                                                `${item.stpId}~${regime.regimeName}~${regime.ageGroup}`
                                              ]
                                            }
                                          />
                                        </>
                                      }
                                    >
                                      <li
                                        onClick={() => {
                                          setShowCustomComponent(true);
                                          setShowCustomCompoundData(
                                            regimeComponent[
                                              `${item.stpId}~${regime.regimeName}~${regime.ageGroup}`
                                            ]
                                          );
                                          // fetchRegimeCompound(
                                          //   regime.regimeName,
                                          //   regime.ageGroup,
                                          //   item.stpId
                                          // );
                                        }}
                                      >
                                        <div className="tskLstTaskNM">
                                          {regime.regimeName}
                                        </div>
                                      </li>
                                    </HtmlTooltip>
                                  ) : (
                                    <li
                                      onClick={() => {
                                        fetchRegimeCompound(
                                          regime.regimeName,
                                          regime.ageGroup,
                                          item.stpId
                                        );
                                      }}
                                    >
                                      <div className="tskLstTaskNM">
                                        {regime.regimeName}
                                      </div>
                                    </li>
                                  )}
                                </>
                              );
                            })}
                          </ul>
                        </td>

                        <td>
                          <div className="tblActionBtnGrp">
                            <IconButton
                              className="removeRowBtn"
                              onClick={() => {
                                handleOpenEditSTP(item);
                              }}
                            >
                              <Edit />
                            </IconButton>
                            <IconButton
                              className="removeRowBtn"
                              onClick={() => {
                                deactivateStp(item.stpId);
                              }}
                            >
                              <Trash />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {showCustomComponent && (
        <CustomModal
          modalTitle={"Compound Items"}
          // style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowCustomComponent(false);
            setShowCustomCompoundData(null);
          }}
          className={"width90"}
        >
          <>
            <ViewCompound compoundList={customCompoundData} />
          </>

          <div className="modActionBtnGrp actionsCreateOrg">
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                setShowCustomComponent(false);
                setShowCustomCompoundData(null);
              }}
            >
              {t("cancel")}
            </Button>
          </div>
        </CustomModal>
      )}
    </>
  );
}
