import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  CircularProgress,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import { toast } from "react-toastify";
import React, { useState, useEffect, useRef } from "react";
import { XLg } from "react-bootstrap-icons";
import { jwtDecode } from "jwt-decode";
import { GpsFixed } from "@mui/icons-material";
import {
  getCountryList,
  getOrgCountry,
  getStateList,
  getCurrencyList,
  getCallingCodeList,
  getUnits,
  editUnit,
  getAllTimeZones,
} from "../../../../services/AdminService";
import { validateEmail } from "../../../../utils/Utils";
import { useTranslation } from "react-i18next";

export default function UpdateUnit({
  handleCloseUpdateUnits,
  refreshUnitData,
  selectedUpdateUnits,
}) {
  console.log("selectedUpdateUnits", selectedUpdateUnits);

  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();

  const [defultCurrency, setdefultCurrency] = useState("");

  const [loading, setLoading] = useState(false);

  const [timezone, setTimezone] = useState("");
  const [timezoneInput, setTimezoneInput] = useState("");
  const [timeZoneData, setTimeZoneData] = useState([]);

  ////////////////////////////////////////////////////////////////////////////////////////////
  const [userUnitName, setUserUnitName] = useState(
    selectedUpdateUnits.unitName
  );
  const [userTan, setUserTan] = useState(selectedUpdateUnits.tan);
  const [epfNo, setEpfNo] = useState(selectedUpdateUnits.epfNo);
  const [esiNo, setEsiNo] = useState(selectedUpdateUnits.esiNo);
  const [gstin, setGstin] = useState(selectedUpdateUnits.gstin);
  // const [updatedUnitName, setUpdatedUnitName] = useState("");

  const handleUserUnitName = (e) => {
    const value = e.target.value;
    console.log("handleUserUnitName", value);
    setUserUnitName(value);
    // if (selectedUpdateUnits) {
    //   selectedUpdateUnits.unitName = value;
    //   setUpdatedUnitName(value);
    // }
  };

  const [latitudeValue, setLatitudeValue] = useState(
    selectedUpdateUnits.latitude
  );

  // const handleUserlatitudeValue = (e) => {
  //   const numericValue = e.target.value.replace(/[^0-9]/g, "");
  //   console.log("handleUserlatitudeValue", numericValue);
  //   setLatitudeValue(numericValue);
  // };

  const handleUserlatitudeValue = (event) => {
    const value = event.target.value;
    const regex = /^-?[0-9]*\.?[0-9]*$/;
    if (regex.test(value)) {
      setLatitudeValue(value);
    } else {
      console.log("Invalid value: ", value);
    }
  };

  const [longitudeValue, setLongitudeValue] = useState(
    selectedUpdateUnits.longitude
  );

  // const handleUserlongitudeValue = (e) => {
  //   const numericValue = e.target.value.replace(/[^0-9]/g, "");
  //   console.log("handleUserlongitudeValue", numericValue);
  //   setLongitudeValue(numericValue);
  // };

  const handleUserlongitudeValue = (event) => {
    const value = event.target.value;
    const regex = /^-?[0-9]*\.?[0-9]*$/;
    if (regex.test(value)) {
      setLongitudeValue(value);
    } else {
      console.log("Invalid value: ", value);
    }
  };

  const handleTimezone = (e, newValue) => {
    console.log("handleTimezone", newValue);
    setTimezone(newValue);
  };

  const handleTimezoneInput = (e, newValue) => {
    console.log("handleTimezoneInput", newValue);
    setTimezoneInput(newValue);
  };

  const handleFetchCoordinates = () => {
    // setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          console.log("Latitude:", latitude);
          console.log("Longitude:", longitude);
          setLatitudeValue(latitude);
          setLongitudeValue(longitude);
          // setLoading(false);
          // You can use the latitude and longitude as needed here
        },
        (error) => {
          console.error("Error getting location:", error);
          // setLoading(false);
          // Handle errors gracefully
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser");
      // setLoading(false);
      // Handle browser not supporting geolocation
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////

  const [userBuildingName, setUserBuildingName] = useState("");
  const [updatedBuildingName, setUpdatedBuildingName] = useState("");

  const handleUserBuildingName = (e) => {
    const value = e.target.value;
    console.log("handleUserBuildingName", value);
    setUserBuildingName(value);
    if (selectedUpdateUnits) {
      selectedUpdateUnits.organizationAddressDto.buildingNameNo = value;
      setUpdatedBuildingName(value);
    }
  };

  const [userArea, setUserArea] = useState("");
  const [updatedArea, setUpdatedArea] = useState("");

  const handleUserArea = (e) => {
    const value = e.target.value;
    console.log("handleUserArea", value);
    setUserArea(value);
    if (selectedUpdateUnits) {
      selectedUpdateUnits.organizationAddressDto.area = value;
      setUpdatedArea(value);
    }
  };

  const [userLandmark, setUserLandMark] = useState("");
  const [updatedLandmark, setUpdatedLandmark] = useState("");

  const handleUserLandMark = (e) => {
    const value = e.target.value;
    console.log("handleUserLandMark", value);
    setUserLandMark(value);
    if (selectedUpdateUnits) {
      selectedUpdateUnits.organizationAddressDto.landMark = value;
      setUpdatedLandmark(value);
    }
  };

  const [userCity, setUserCity] = useState("");
  const [updatedCity, setUpdatedCity] = useState("");

  const handleUserCity = (e) => {
    const value = e.target.value;
    console.log("handleUserCity", value);
    setUserCity(value);
    if (selectedUpdateUnits) {
      selectedUpdateUnits.organizationAddressDto.city = value;
      setUpdatedCity(value);
    }
  };

  const [userPincode, setUserPincode] = useState("");
  const [updatedPincode, setUpdatedPincode] = useState("");

  const handleUserPincode = (e) => {
    const value = e.target.value;
    console.log("handleUserCity", value);
    setUserPincode(value);
    if (selectedUpdateUnits) {
      selectedUpdateUnits.organizationAddressDto.pinCd = value;
      setUpdatedPincode(value);
    }
  };

  const [userPan, setUserPan] = useState(selectedUpdateUnits.pan);

  const handleUserPan = (e) => {
    setUserPan(e.target.value);
  };

  ////////////////////////////////////////// cost center and profit center ///////////////////

  const [isCostCenterChecked, setIsCostCenterChecked] = useState(
    selectedUpdateUnits.costCenter === "Y"
  );
  const [costCenterValue, setCostCenterValue] = useState(
    selectedUpdateUnits.costCenter
  );
  // const [updatedCostCentervalue , setUpdatedCostCentervalue] = useState()
  const [isProfitCenterChecked, setIsProfitCenterChecked] = useState(
    selectedUpdateUnits.profitCenter === "Y"
  );
  const [profitCenterValue, setProfitCenterValue] = useState(
    selectedUpdateUnits.profitCenter
  );
  // const [updatedProfitCenterValue , setUpdatedProfitCenterValue] = useState();

  const handleCostCenterChange = (event) => {
    console.log(
      "selectedUpdateUnits.costCenter",
      selectedUpdateUnits.costCenter
    );
    console.log("handleCostCenterChange", event.target.checked);
    const checked = event.target.checked;
    setIsCostCenterChecked(checked);
    setCostCenterValue(checked ? "Y" : "N");
  };

  const handleProfitCenterChange = (event) => {
    const checked = event.target.checked;
    setIsProfitCenterChecked(checked);
    console.log("event.target.checked", event.target.checked);
    setProfitCenterValue(checked ? "Y" : "N");
  };

  // console.log('costCenterValue', costCenterValue)
  // console.log('profitCenterValue',profitCenterValue)

  //////////////////////////////////////////////////////////////////////////////////////////

  const [balanceSheetOfOwn, setBalanceSheetOfOwn] = useState(
    selectedUpdateUnits.ownBalanceSheet
  );

  const handleBalanceSheetOfOwnChange = (event) => {
    setBalanceSheetOfOwn(event.target.value);
    console.log("handleBalanceSheetOfOwnChange", event.target.value);
  };

  const [statutory, setStatutory] = useState(
    selectedUpdateUnits.statutoryCompliances
  );

  const handleStatutoryChange = (event) => {
    setStatutory(event.target.value);
    console.log("handleStatutoryChange", event.target.value);
  };

  const [localtax, setLocalTax] = useState(selectedUpdateUnits.localTax);

  const handleLocaltaxChange = (event) => {
    setLocalTax(event.target.value);
    console.log("handleLocaltaxChange", event.target.value);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const [selectCountry, setSelectCountry] = useState(
    selectedUpdateUnits.organizationAddressDto.countryCode
  );
  // const [selectUpdatedCountry, setSelectUpdatedCountry] = useState("");
  const [CountryList, setCountryList] = useState([]);
  const responseRef = useRef(null);
  const [selectState, setSelectState] = useState([]);
  const [selectStateId, setSelectStateId] = useState(
    selectedUpdateUnits.organizationAddressDto.stateId
  );
  // const [selectUpdatedStateId, setSelectUpdatedStateId] = useState("");

  useEffect(() => {
    getAllTimeZones().then((response) => {
      console.log("Zone Data", response.data);
      setTimeZoneData(response.data);
      if (selectedUpdateUnits.timezone) {
        const currentTimezone = response.data.find(
          (item) => (item.name = selectedUpdateUnits.timezone)
        );
        setTimezone(currentTimezone);
      }
    });
    getCountryList().then((response) => {
      console.log("getCountryList", response.data);
      console.log("getCountryList", response.data[1].countryName);
      setCountryList(response.data);
    });
  }, []);

  // useEffect(() => {
  //   getOrgCountry(userDetails.orgId).then((response) => {
  //     console.log("getOrgCountry", response.data);
  //     console.log("getOrgCountry", response.data.countryName);
  //     setSelectCountry(response.data.countryCode);

  //     const selectedCurrency = response.data.currencyAlphaCd;
  //     setdefultCurrency(selectedCurrency ? selectedCurrency.toLowerCase() : "");

  //     responseRef.current = response.data.countryCode;
  //     console.log(" responseRef.current", responseRef.current);
  //     selectedState();
  //   });
  // }, []);

  useEffect(() => {
    selectedState();
  }, [selectCountry]);

  const handleChangeCountry = (event) => {
    console.log("handleChangeCountry", event.target.value);
    const value = event.target.value;
    setSelectCountry(value);
    // if (selectedUpdateUnits) {
    //   selectedUpdateUnits.organizationAddressDto.countryCode = value;
    //   setSelectUpdatedStateId(value)
    // }
  };

  const selectedState = () => {
    console.log("selectCountry in state", selectCountry);
    if (selectCountry) {
      const countryCd = selectCountry;
      getStateList(countryCd).then((response) => {
        console.log("getStateList", response.data);
        setSelectState(response.data);
      });
    } else {
      const countryCd = responseRef.current;
      getStateList(countryCd).then((response) => {
        console.log("getStateList", response.data);
        setSelectState(response.data);
      });
    }
  };

  const handleChangeState = (event) => {
    console.log("handleChangeState", event.target.value);
    const value = event.target.value;
    setSelectStateId(value);
  };

  /////////////////////////////////////////////////////////////////////////////////////////
  const [currencyList, setCurrencyList] = useState([]);
  const [updatedCurrency, setUpdatedCurrency] = useState("");
  useEffect(() => {
    getCurrencyList().then((response) => {
      console.log("getCurrencyList", response.data);
      setCurrencyList(response.data);
    });
  }, []);

  const handleChangeCurrency = (event) => {
    // console.log("handleChangeCurrency", event.target.value);
    // setdefultCurrency(event.target.value);
    const value = event.target.value;
    console.log("handleChangeCurrency", value);
    setdefultCurrency(value);
    if (selectedUpdateUnits) {
      selectedUpdateUnits.currency = value;
      setUpdatedCurrency(value);
    }
  };

  //////////////////////////////////////////email////////////////////////////////////////////
  const [userEmail, setUserEmail] = useState("");
  const [updatedEmail, setUpdatedEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [updatedPhone, setUpdatedPhone] = useState("");
  const [cdCodeList, setCdCodeList] = useState([]);
  const [selectCdCode, setSelectCdCode] = useState("");
  const [updatedCdCode, setUpdatedCdCode] = useState("");

  const handleUserEmail = (event) => {
    // console.log("handleUserEmail", event.target.value);
    // setUserEmail(event.target.value);
    const value = event.target.value;
    console.log("handleUserEmail", value);
    setUserEmail(value);
    if (selectedUpdateUnits) {
      selectedUpdateUnits.organizationAddressDto.emailId = value;
      setUpdatedEmail(value);
    }
  };

  useEffect(() => {
    getCallingCodeList().then((response) => {
      console.log("getCallingCodeList", response.data);
      setCdCodeList(response.data);
    });
  }, []);

  const handleSelectCdCode = (event) => {
    // console.log("handleUserEmail", event.target.value);
    // setSelectCdCode(event.target.value);
    const value = event.target.value;
    console.log("handleUserEmail", value);
    setSelectCdCode(value);
    if (selectedUpdateUnits) {
      selectedUpdateUnits.organizationAddressDto.callingCd = value;
      setUpdatedCdCode(value);
    }
  };

  const handleUserPhone = (event) => {
    console.log("handleUserPhone", event.target.value);
    // setUserPhone(event.target.value)
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    setUserPhone(numericValue);
    if (selectedUpdateUnits) {
      selectedUpdateUnits.organizationAddressDto.contactNo = numericValue;
      setUpdatedPhone(numericValue);
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  const [unitsData, setUnitData] = useState([]);
  const [parentUnits, setParentUnits] = useState("");
  const [updatedParentsUnit, setUpdatedParentsUnit] = useState("");

  useEffect(() => {
    getUnits(userDetails.orgId).then((response) => {
      console.log("getUnits", response.data);
      setUnitData(response.data);
    });
  }, []);

  const handleChangePUnits = (event) => {
    // console.log("handleChangePUnits", event.target.value);
    // setParentUnits(event.target.value);
    const value = event.target.value;
    console.log("handleUserEmail", value);
    setParentUnits(value);
    if (selectedUpdateUnits) {
      selectedUpdateUnits.parUnitId = value;
      setUpdatedParentsUnit(value);
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////

  const finalUpdateSubmit = () => {
    console.log("finalUpdateSubmit");
    setLoading(true);

    // const newUnitName = updatedUnitName
    //   ? updatedUnitName
    //   : selectedUpdateUnits.unitName;

    const latitudeNum = parseFloat(latitudeValue);
    if (isNaN(latitudeNum) || latitudeNum < -90 || latitudeNum > 90) {
    toast.error(t("please_enter_valid_latitude_range"), {
    position: toast.POSITION.TOP_RIGHT,
    });
    setLoading(false);
    return;
    }

    const longitudeNum = parseFloat(longitudeValue);
    if (isNaN(longitudeNum) || longitudeNum < -180 || longitudeNum > 180) {
    toast.error(t("please_enter_valid_longitude_range"), {
    position: toast.POSITION.TOP_RIGHT,
    });
    setLoading(false);
    return;
    }

    const newBuildingName = updatedBuildingName
      ? updatedBuildingName
      : selectedUpdateUnits.organizationAddressDto.buildingNameNo;

    const newAreaName = updatedArea
      ? updatedArea
      : selectedUpdateUnits.organizationAddressDto.area;

    const newLandMarkName = updatedLandmark
      ? updatedLandmark
      : selectedUpdateUnits.organizationAddressDto.landMark;

    const newCityName = updatedCity
      ? updatedCity
      : selectedUpdateUnits.organizationAddressDto.city;

    const newPinCode = updatedPincode
      ? updatedPincode
      : selectedUpdateUnits.organizationAddressDto.pinCd;

    // const newCountryCode = selectUpdatedCountry ? selectUpdatedCountry : selectedUpdateUnits.organizationAddressDto.countryCode ;

    const NewcountryName =
      CountryList.find((country) => country.countryCode === selectCountry)
        ?.countryName || selectedUpdateUnits.organizationAddressDto.countryName;

    //  const newStateCode = selectUpdatedStateId ? selectUpdatedStateId : selectedUpdateUnits.organizationAddressDto.stateId ;

    const NewstateName =
      selectState.find((state) => state.id === selectStateId)?.name || " ";

    const newCurrency = updatedCurrency
      ? updatedCurrency
      : selectedUpdateUnits.currency;

    const newparentId = updatedParentsUnit
      ? updatedParentsUnit
      : selectedUpdateUnits.parUnitId;

    const newEmailId = updatedEmail
      ? updatedEmail
      : selectedUpdateUnits.organizationAddressDto.emailId;

    const newCdCode = updatedCdCode
      ? updatedCdCode
      : selectedUpdateUnits.organizationAddressDto.callingCd;

    const newPhoneNumber = updatedPhone
      ? updatedPhone
      : selectedUpdateUnits.organizationAddressDto.contactNo;

    const reqDto = {
      unitName: userUnitName,
      organizationAddressDto: {
        id: selectedUpdateUnits.organizationAddressDto.id,
        countryCode: selectCountry,
        countryName: NewcountryName,
        contactNo: newPhoneNumber,
        stateId: selectState.length !== 0 ? selectStateId : "",
        stateName: NewstateName,
        buildingNameNo: newBuildingName,
        area: newAreaName,
        pinCd: newPinCode,
        landMark: newLandMarkName,
        city: newCityName,
        streetNo: "",
        emailId: newEmailId,
        callingCd: newCdCode,
        addressDisplay: "",
      },
      costCenter: costCenterValue,
      profitCenter: profitCenterValue,
      ownBalanceSheet: balanceSheetOfOwn,
      statutoryCompliances: statutory,
      localTax: localtax,
      parUnitId: newparentId,
      currency: newCurrency,
      unitId: selectedUpdateUnits.unitId,
      latitude: latitudeValue,
      longitude: longitudeValue,
      pan: userPan,
      tan: userTan,
      epfNo: epfNo,
      esiNo: esiNo,
      gstin: gstin,
      timezone: timezone.name,
      contactNo : newPhoneNumber
    };

    console.log("reqDto", reqDto);

    // setLoading(false);
    // return;

    editUnit(userDetails.userId, reqDto).then((response) => {
      console.log("editUnit", response.data);
      setLoading(false);
      if (response.data.returnValue === "1") {
        toast.success(t("event_updated_successfully") , {
          position: toast.POSITION.TOP_RIGHT,
        });
        refreshUnitData();
        handleCloseUpdateUnits();
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">{t("edit_units")}</div>
          <IconButton
            className="CloseBtn"
            onClick={() => handleCloseUpdateUnits()}
          >
            <XLg />
          </IconButton>
        </div>

        {loading ? (
          <div className="meetingVEContainer">
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          </div>
        ) : (
          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  label={t("unit_name")}
                  required
                  disabled
                  variant="outlined"
                  className="formTextFieldArea"
                  value={userUnitName}
                  // value={selectedUpdateUnits?.unitName || userUnitName}
                  onChange={(e) => handleUserUnitName(e)}
                />
              </FormControl>
            </div>

            <div class="tskElemHeding">{t("gps_location")} *</div>
            <div className="elementWithAddMrElm">
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("latitude")}
                    required
                    variant="outlined"
                    className="formTextFieldArea"
                    value={latitudeValue}
                    onChange={handleUserlatitudeValue}
                    inputProps={{
                      inputMode: "decimal",
                      pattern: "^-?[0-9]*\\.?[0-9]*$",
                    }}
                  />
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("longitude")}
                    required
                    variant="outlined"
                    className="formTextFieldArea"
                    value={longitudeValue}
                    onChange={handleUserlongitudeValue}
                    inputProps={{
                      inputMode: "decimal",
                      pattern: "^-?[0-9]*\\.?[0-9]*$",
                    }}
                  />
                </FormControl>
              </div>

              <Tooltip arrow title={t("fetch_gps_longitude_and_latitude")}>
                <IconButton
                  className="addMrElemBtn"
                  onClick={handleFetchCoordinates}
                >
                  <GpsFixed />
                </IconButton>
              </Tooltip>
            </div>
            <div className="elementWithAddMrElm">
              <div className="formElement width50p">
                <FormControl className="formControl">
                  <Autocomplete
                    id="selectActivity"
                    className="formAutocompleteField"
                    variant="outlined"
                    options={timeZoneData}
                    getOptionLabel={(option) => option.name || ""}
                    value={timezone}
                    inputValue={timezoneInput}
                    onInputChange={handleTimezoneInput}
                    onChange={handleTimezone}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        className="formAutoComInputField autocompFildPlaceholder"
                        {...params}
                        label={t("timezone")}
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>

            <div class="tskElemHeding">{t("unit_location")} *</div>
            <div className="formElementGroup">
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("house_no_building_name")}
                    required
                    variant="outlined"
                    className="formTextFieldArea"
                    // value={selectedUpdateUnits.organizationAddressDto.buildingNameNo || userBuildingName}
                    value={
                      selectedUpdateUnits &&
                      selectedUpdateUnits.organizationAddressDto &&
                      selectedUpdateUnits.organizationAddressDto.buildingNameNo
                        ? selectedUpdateUnits.organizationAddressDto
                            .buildingNameNo
                        : userBuildingName
                    }
                    onChange={(e) => handleUserBuildingName(e)}
                  />
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("area_colony")}
                    required
                    variant="outlined"
                    className="formTextFieldArea"
                    value={
                      selectedUpdateUnits.organizationAddressDto.area ||
                      userArea
                    }
                    onChange={(e) => handleUserArea(e)}
                  />
                </FormControl>
              </div>
            </div>
            <div className="formElementGroup">
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("land_mark")}
                    variant="outlined"
                    className="formTextFieldArea"
                    value={
                      selectedUpdateUnits.organizationAddressDto.landMark ||
                      userLandmark
                    }
                    onChange={(e) => handleUserLandMark(e)}
                  />
                </FormControl>
              </div>

              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("city")}
                    required
                    variant="outlined"
                    className="formTextFieldArea"
                    value={
                      selectedUpdateUnits.organizationAddressDto.city ||
                      userCity
                    }
                    onChange={(e) => handleUserCity(e)}
                  />
                </FormControl>
              </div>
            </div>

            <div className="formElementGroup">
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="select_Country">{t("select_country")}*</InputLabel>
                  <Select
                    className="formInputField"
                    variant="outlined"
                    labelId="select_Country"
                    id="unitSelectCountry"
                    value={selectCountry}
                    label={t("select_country")}
                    onChange={handleChangeCountry}
                  >
                    {CountryList.map((country) => (
                      <MenuItem
                        key={country.countryCode}
                        value={country.countryCode}
                      >
                        {country.countryName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="select_state">{t("select_state")}*</InputLabel>
                  <Select
                    className="formInputField"
                    variant="outlined"
                    labelId="select_state"
                    id="unitSelectState"
                    value={selectStateId}
                    label={t("select_state")}
                    onChange={handleChangeState}
                  >
                    {
                      // selectState &&
                      selectState.map((NewState) => (
                        <MenuItem key={NewState.id} value={NewState.id}>
                          {NewState.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="formElementGroup">
              <div className="formElement width50p">
                <FormControl className="formControl">
                  <TextField
                    label={t("pin")}
                    required
                    variant="outlined"
                    className="formTextFieldArea"
                    value={
                      selectedUpdateUnits.organizationAddressDto?.pinCd ||
                      userPincode
                    }
                    onChange={(e) => handleUserPincode(e)}
                  />
                </FormControl>
              </div>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <FormGroup aria-label="position" row className="feformGroup">
                  <FormControlLabel
                    className="formCheckBox"
                    // value="Cost Center"
                    control={
                      <Checkbox
                        checked={isCostCenterChecked}
                        onChange={handleCostCenterChange}
                      />
                    }
                    label={t("cost_center")}
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    className="formCheckBox"
                    // value="Profit Center"
                    control={
                      <Checkbox
                        checked={isProfitCenterChecked}
                        onChange={handleProfitCenterChange}
                      />
                    }
                    label={t("profit_center")}
                    labelPlacement="start"
                  />
                </FormGroup>
              </FormControl>
            </div>

            <div className="formElement">
              <div className="textWithOption">
                <div className="fromText">{t("maintains_own_balance_Sheet")}</div>
                <FormControl className="formControl">
                  <RadioGroup
                    className="formRadioGroup"
                    labelId="setTasktype"
                    name="setTasktype"
                    value={balanceSheetOfOwn}
                    onChange={handleBalanceSheetOfOwnChange}
                  >
                    <FormControlLabel
                      className="formRadioField"
                      value="Y"
                      control={<Radio />}
                      label="Y"
                    />
                    <FormControlLabel
                      className="formRadioField"
                      value="N"
                      control={<Radio />}
                      label="N"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="formElement">
              <div className="textWithOption">
                <div className="fromText">{t("statutory_compliances")}</div>
                <FormControl className="formControl">
                  <RadioGroup
                    className="formRadioGroup"
                    labelId="setTasktype"
                    name="setTasktype"
                    value={statutory}
                    onChange={handleStatutoryChange}
                  >
                    <FormControlLabel
                      className="formRadioField"
                      value="Y"
                      control={<Radio />}
                      label="Y"
                    />
                    <FormControlLabel
                      className="formRadioField"
                      value="N"
                      control={<Radio />}
                      label="N"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="formElement">
              <div className="textWithOption">
                <div className="fromText">{t("local_tax_applicable")}</div>
                <FormControl className="formControl">
                  <RadioGroup
                    className="formRadioGroup"
                    labelId="setTasktype"
                    name="setTasktype"
                    value={localtax}
                    onChange={handleLocaltaxChange}
                  >
                    <FormControlLabel
                      className="formRadioField"
                      value="Y"
                      control={<Radio />}
                      label="Y"
                    />
                    <FormControlLabel
                      className="formRadioField"
                      value="N"
                      control={<Radio />}
                      label="N"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="formElementGroup">
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="Defult_Currency">{t("default_currency")}</InputLabel>
                  <Select
                    className="formInputField"
                    variant="outlined"
                    labelId="Defult_Currency"
                    id="compTime-select"
                    value={selectedUpdateUnits?.currency || defultCurrency}
                    // value='usd'
                    label={t("default_currency")}
                    onChange={handleChangeCurrency}
                  >
                    {/* <MenuItem value={"inr"}>IND Rupee</MenuItem>
                  <MenuItem value={"usd"}> USD </MenuItem> */}
                    {currencyList.map((currency) => (
                      <MenuItem key={currency} value={currency}>
                        {currency}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="patentUnits">
                  {t("select_parent_unit")}
                  </InputLabel>
                  <Select
                    className="formInputField"
                    variant="outlined"
                    labelId="patentUnits"
                    id="selectpatentUnits"
                    value={selectedUpdateUnits.parUnitId || parentUnits}
                    label={t("select_parent_unit")}
                    onChange={handleChangePUnits}
                  >
                    <MenuItem value="">
                      {t("select_menu")}
                    </MenuItem>
                    {unitsData.map((unitsData) => (
                      <MenuItem key={unitsData.unitId} value={unitsData.unitId}>
                        {unitsData.unitName}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value={"kol"}>Kolkata</MenuItem>
                    <MenuItem value={"dur"}> Durgapur </MenuItem> */}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="formElementGroup">
              <div className="formElement width50p">
                <FormControl className="formControl">
                  <TextField
                    label={t("pan_id")}
                    variant="outlined"
                    className="formTextFieldArea"
                    value={userPan}
                    onChange={(e) => handleUserPan(e)}
                  />
                </FormControl>
              </div>
              <div className="formElement width50p">
                <FormControl className="formControl">
                  <TextField
                    label={t("tan_id")}
                    variant="outlined"
                    className="formTextFieldArea"
                    value={userTan}
                    onChange={(e) => setUserTan(e.target.value)}
                  />
                </FormControl>
              </div>
            </div>
            <div className="formElementGroup">
              <div className="formElement width50p">
                <FormControl className="formControl">
                  <TextField
                    label={t("epf_no")}
                    variant="outlined"
                    className="formTextFieldArea"
                    value={epfNo}
                    onChange={(e) => setEpfNo(e.target.value)}
                  />
                </FormControl>
              </div>
              <div className="formElement width50p">
                <FormControl className="formControl">
                  <TextField
                    label={t("esi_no")}
                    variant="outlined"
                    className="formTextFieldArea"
                    value={esiNo}
                    onChange={(e) => setEsiNo(e.target.value)}
                  />
                </FormControl>
              </div>
            </div>
            <div className="formElementGroup">
              <div className="formElement width50p">
                <FormControl className="formControl">
                  <TextField
                    label={t("gst_no")}
                    variant="outlined"
                    className="formTextFieldArea"
                    value={gstin}
                    onChange={(e) => setGstin(e.target.value)}
                  />
                </FormControl>
              </div>
            </div>

            <div class="tskElemHeding">{t("contact_details_optional")}</div>
            <div className="formElementGroup">
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("email_id")}
                    variant="outlined"
                    className="formTextFieldArea"
                    value={
                      selectedUpdateUnits.organizationAddressDto.emailId ||
                      userEmail
                    }
                    onChange={(e) => handleUserEmail(e)}
                  />
                </FormControl>
              </div>
              <div className="formElement mxW-100">
                <FormControl className="formControl">
                  <InputLabel id="countryCode">{t("country_code")}</InputLabel>
                  <Select
                    className="formInputField"
                    labelId="countryCode"
                    id="demo-simple-select"
                    label={t("country_code")}
                    value={
                      selectedUpdateUnits.organizationAddressDto.callingCd ||
                      selectCdCode
                    }
                    onChange={(e) => handleSelectCdCode(e)}
                  >
                    {cdCodeList.map((cdCodeList) => (
                      <MenuItem key={cdCodeList} value={cdCodeList}>
                        {cdCodeList}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("phone_no")}
                    variant="outlined"
                    className="formTextFieldArea"
                    value={
                      selectedUpdateUnits.organizationAddressDto.contactNo ||
                      userPhone
                    }
                    onChange={(e) => handleUserPhone(e)}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                  />
                </FormControl>
              </div>
            </div>
          </div>
        )}

        <div className="elementFooter">
          <div className="formBtnElement">
            {/* <Button className="dfultPrimaryBtn" onClick={()=>finalUpdateSubmit()}>Save</Button> */}
            <Button
              className="dfultPrimaryBtn"
              onClick={() => finalUpdateSubmit()}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>{t("submit")}</>
              )}
            </Button>
            {/* <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handleCloseMenuButton()}
              >
                Cancel
              </Button> */}
            {!loading && (
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handleCloseUpdateUnits()}
              >
                {t("cancel")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
