import {
  ArrowBack,
  Check,
  Close,
  Edit,
  KeyboardArrowDown,
  PeopleRounded,
  PersonAdd,
  PersonAddAlt1,
  PhotoCamera,
  Verified,
} from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
} from "@mui/material";
import { Xmark } from "iconoir-react";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { MIN_USER_IN_CHAT_GROUP } from "../../../../constants/const_values";
import {
  addMembersToGroup,
  dismissGropuAdmin,
  getChatAllContacts,
  getGroupChatUsers,
  makeGroupAdmin,
  removeMemberOfGroup,
  updateGroupIcon,
  updateGroupName,
} from "../../../../services/ChatService";
import {
  convertFileToBase64,
  getLoginUserDetails,
} from "../../../../utils/Utils";
import { chatSocket } from "../../../../socket";
import { useTranslation } from "react-i18next";

export default function ChatGroupDetails({
  selectedContact,
  setShowGroupInfo,
  groupDetails,
}) {
  const loginUser = getLoginUserDetails();
  const { t } = useTranslation();
  const fileInputRef = useRef(null);

  const [groupName, setGroupName] = useState("");
  const [groupIcon, setGroupIcon] = useState("");

  const [nameEditable, setNameEditable] = useState(false);
  const [groupUserList, setGroupUserList] = useState([]);
  const [isGroupAdmin, setIsGroupAdmin] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);

  const [showAddMoreUser, setShowAddMoreUser] = useState(false);
  const [userContactList, setUserContactList] = useState([]);
  const [filterUserContactList, setFilterUserContactList] = useState([]);
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [refreshCount, setRefreshCount] = useState(1);

  useEffect(() => {
    setIsGroupAdmin(false);
    setSelectedUserList([]);

    if (groupDetails != null) {
      fetchGroupUserData();
      setGroupName(groupDetails.chatName);
      setGroupIcon(groupDetails.chatProfileUrl);
    }
  }, []);

  // fetch group user list data from api start
  const fetchGroupUserData = async () => {
    await getGroupChatUsers(groupDetails.chatId).then((response) => {
      if (response.data != null) {
        if (response.data.length > 0) {
          let tempList = [];
          for (let index = 0; index < response.data.length; index++) {
            const user = response.data[index];
            if (user.activeFlag === "Y") {
              tempList.push(user);
              if (user.userId === loginUser.userId && user.adminUser === "Y") {
                setIsGroupAdmin(true);
              }
            }
          }
          setGroupUserList(tempList);
        }
      }
    });
  };
  // fetch group user list data from api end

  // upload image and edit group icon start
  const handleChangeGroupIcon = async (e) => {
    const file = e.target.files[0];
    const reqDto = {
      fileName: file.name,
      fileData: await convertFileToBase64(file),
    };

    await updateGroupIcon(groupDetails.chatId, loginUser.userId, reqDto).then(
      (response) => {
        if (response.data != null) {
          if (response.data !== "") {
            setGroupIcon(response.data);
            groupDetails.chatProfileUrl = response.data;
          }
        }
      }
    );

    fileInputRef.current.value = null;
  };
  // upload image and edit group icon end

  // edit group name or close edit start
  const handleSubmitEditName = async () => {
    if (nameEditable === true) {
      if (groupDetails.chatName !== groupName) {
        await updateGroupName(
          groupDetails.chatId,
          loginUser.userId,
          groupName
        ).then((response) => {
          if (response.data != null) {
            if (response.data > 0) {
              groupDetails.chatName = groupName;
            }
          }
        });
      }
    }
    setNameEditable((s) => !s);
  };
  // edit group name or close edit end

  // remove user from the group start
  const handleRemoveUser = async () => {
    if (menuAnchor != null) {
      const { userId } = JSON.parse(menuAnchor.id);
      await removeMemberOfGroup(
        groupDetails.chatId,
        loginUser.userId,
        userId
      ).then((response) => {
        if (response.data != null) {
          if (response.data > 0) {
            chatSocket.emit(
              "requestDeleteGroupUser",
              userId,
              groupDetails.chatId
            );
            setGroupUserList(groupUserList.filter((x) => x.userId !== userId));
          } else {
            toast.error(t("something_went_wrong_please_try_again"));
          }
        }
      });
    }
    setMenuAnchor(null);
  };
  // remove user from the group end

  // make user as admin start
  const handleMakeAdminUser = async () => {
    if (menuAnchor != null) {
      const { userId } = JSON.parse(menuAnchor.id);
      await makeGroupAdmin(groupDetails.chatId, loginUser.userId, userId).then(
        (response) => {
          if (response.data != null) {
            if (response.data > 0) {
              toast.success(t("admin_rights_granted"));
              if (groupUserList && groupUserList.length > 0) {
                let tempList = groupUserList;
                const index = tempList.findIndex(
                  (val) => val.userId === userId
                );
                if (index != null && index !== -1) {
                  tempList[index].adminUser = "Y";
                  setGroupUserList(tempList);
                }
              }
            } else {
              toast.error(t("something_went_wrong_please_try_again"));
            }
          }
        }
      );
    }
    setMenuAnchor(null);
  };
  // make user as admin end

  // remove from group admin start
  const handleDismissAdmin = () => {
    if (menuAnchor != null) {
      const { userId } = JSON.parse(menuAnchor.id);

      dismissGropuAdmin(selectedContact.chatId, loginUser.userId, userId).then(
        (response) => {
          if (response.data === 1) {
            setIsGroupAdmin(false);
            fetchGroupUserData();
            toast.success(t("admin_successfully_updated"));
          } else {
            toast.error(t("could_not_dismiss_admin_user"));
          }
        }
      );
    }
  };
  // remove from group admin end

  // when add button click call contact api start
  // remove the users those are already added in the group
  useEffect(() => {
    if (showAddMoreUser) {
      getChatAllContacts(loginUser.userId, loginUser.orgId).then((response) => {
        if (response.data != null) {
          if (response.data.length > 0) {
            let tempList = [];
            for (let index = 0; index < response.data.length; index++) {
              const item = response.data[index];
              if (!groupUserList.find((x) => x.userId === item.userId)) {
                tempList.push(item);
              }
            }
            setUserContactList(tempList);
            setFilterUserContactList(tempList);
          }
        }
      });
    }
  }, [showAddMoreUser]);
  // when add button click call contact api end

  // search user by name start
  useEffect(() => {
    if (searchKey !== "") {
      setFilterUserContactList(
        filterUserContactList.filter((x) =>
          x.userName.toLowerCase().includes(searchKey.toLowerCase())
        )
      );
    } else {
      setFilterUserContactList(userContactList);
    }
  }, [searchKey]);
  // search user by name end

  // handle select user start
  const handleSelectUser = (user) => {
    if (selectedUserList.find((x) => x.userId === user.userId)) {
      // toast.error(`${user.userName} already selected`);
      toast.error(t("user_already_selected", { userName: user.userName }));
    } else {
      const userObj = {
        ...user,
        adminUser: false,
        readPrevious: false,
      };
      setSelectedUserList([...selectedUserList, userObj]);
    }
  };
  // handle select user end

  // handle submit added user start
  const handleSubmitAddedUser = () => {
    let tempList = [];
    for (let index = 0; index < selectedUserList.length; index++) {
      const user = selectedUserList[index];
      tempList.push({
        userId: user.userId,
        adminUser: user.adminUser ? "Y" : "N",
        readPrevious: user.readPrevious ? "Y" : "N",
      });
    }
    addMembersToGroup(groupDetails.chatId, loginUser.userId, tempList).then(
      (response) => {
        if (response.data != null) {
          if (response.data > 0) {
            fetchGroupUserData();
            setSelectedUserList([]);
            setShowAddMoreUser(false);
          } else {
            toast.error(t("something_went_wrong"));
          }
        }
      }
    );
  };
  // handle submit added user end

  return (
    <>
      <div className="chatGrpDtlContainer">
        {/* group info header start */}
        <div className="chatGrpDtlHeader">
          <div className="chtGrpHedText">
            {showAddMoreUser ? (
              <>
                <ArrowBack onClick={() => setShowAddMoreUser(false)} />
                {t("add_user")}
              </>
            ) : (
              t("group_info")
            )}
          </div>
          <div className="chatGrpInfoClosBtn">
            <IconButton
              aria-label="Close Group info"
              onClick={setShowGroupInfo}
            >
              <Close />
            </IconButton>
          </div>
        </div>
        {/* group info header end */}

        {!showAddMoreUser ? (
          <>
            {/* show group dtl  start*/}
            <div className="chatGrpDtlArea ">
              <div className="cNwGrpImgNmContener">
                <div className="cNwGrpImgNmArea">
                  <div className="cNwGrpImgContain">
                    {/* group icon or image update start */}
                    <div className="cNwGrpImgArea" role="button">
                      <div className="cNwGrpAddGrpIco" role="button">
                        <div className="cNwGrpSvg">
                          <img alt="Group Icon" src={groupIcon} />
                        </div>
                        <span className="ipChngGrpImg">
                          <label
                            role="button"
                            for="inputChangeGroupImg"
                            className="labelInpImgChng"
                          >
                            <PhotoCamera />
                            <span className="imgchngHint">
                            {t("change_group_icon")}
                            </span>
                          </label>
                          <input
                            ref={fileInputRef}
                            className="inputChangeGroupImg"
                            id="inputChangeGroupImg"
                            type="file"
                            accept="image/gif,image/jpeg,image/jpg,image/png"
                            onChange={handleChangeGroupIcon}
                          />
                        </span>
                      </div>
                    </div>
                    {/* group icon or image update end */}
                  </div>

                  {/* group name input start */}
                  <div className="cNwGrpNmContain">
                    <div className="chatGroupNmInput">
                      <FormControl
                        className="chtGrpNmTextFild chatGroupNmInput"
                        variant="standard"
                      >
                        <InputLabel htmlFor="chtGrpNmTextFild">
                        {t("group_name")} *
                        </InputLabel>
                        <Input
                          className="chtGrpNmTextFild"
                          id="chtGrpNmTextFild"
                          type="text"
                          disabled={!nameEditable}
                          value={groupName}
                          onChange={(e) => setGroupName(e.target.value)}
                          endAdornment={
                            isGroupAdmin && (
                              <InputAdornment position="end">
                                <IconButton
                                  className="gnmTroggleBtn"
                                  aria-label="toggle input disable"
                                  onClick={handleSubmitEditName}
                                >
                                  {nameEditable ? <Check /> : <Edit />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }
                        />
                      </FormControl>

                      {/* group user count and add more button start */}
                      <div className="grpMembrsCunt">
                        <div>
                          <span>{groupUserList && groupUserList.length} </span>
                          {t("group_members")}
                        </div>
                        {isGroupAdmin && (
                          <Button
                            className="addMemBerBtn"
                            endIcon={<PersonAddAlt1 />}
                            onClick={() => setShowAddMoreUser(true)}
                          >
                            {t("add")}
                          </Button>
                        )}
                      </div>
                      {/* group user count and add more button end */}
                    </div>
                  </div>
                  {/* group name input end */}
                </div>
              </div>

              {/* show existing group user list start */}
              <div className="shwGrpParticpent ">
                <div className="createGroupCont">
                  {groupUserList && groupUserList.length > 0 && (
                    <>
                      <div className="contactListGroup">
                        {groupUserList.map((user) => (
                          <div className="chatContactList">
                            <div className="contUserImg">
                              <img alt={t("profile_photo")} src={user.profileUrl} />
                            </div>
                            <div className="userDtlWlMsg">
                              <div className="usrNmDT">
                                <div className="usrNmIn">
                                  {user.userId === loginUser.userId
                                    ? t("you")
                                    : user.userName}
                                  {user.adminUser === "Y" && "(Admin)"}
                                </div>
                              </div>
                              <div className="usrLstMsgDtStus">
                                <div className="lstMsgShw">{user.userDtl}</div>
                              </div>

                              {isGroupAdmin &&
                                user.userId !== loginUser.userId &&
                                user.userId !==
                                  selectedContact.chatCreatedBy && (
                                  <>
                                    <div
                                      className={`prtcAct`}
                                      id={JSON.stringify(user)}
                                      onClick={(e) =>
                                        setMenuAnchor(e.currentTarget)
                                      }
                                    >
                                      <KeyboardArrowDown />
                                    </div>

                                    <Menu
                                      className="grpUsrActMenu"
                                      anchorEl={menuAnchor}
                                      open={Boolean(menuAnchor)}
                                      onClose={() => setMenuAnchor(null)}
                                      anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      {groupUserList &&
                                        groupUserList.length >
                                          MIN_USER_IN_CHAT_GROUP && (
                                          <MenuItem onClick={handleRemoveUser}>
                                            {t("remove")}
                                          </MenuItem>
                                        )}

                                      {menuAnchor != null &&
                                        JSON.parse(menuAnchor.id).adminUser !==
                                          "Y" && (
                                          <MenuItem
                                            onClick={handleMakeAdminUser}
                                          >
                                            {t("make_group_admin")}
                                          </MenuItem>
                                        )}

                                      {menuAnchor != null &&
                                        JSON.parse(menuAnchor.id).adminUser ===
                                          "Y" && (
                                          <MenuItem
                                            onClick={handleDismissAdmin}
                                          >
                                            {t("dismiss_as_admin")}
                                          </MenuItem>
                                        )}
                                    </Menu>
                                  </>
                                )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/* show existing group user list end */}
            </div>
            {/* show group dtl  end*/}
          </>
        ) : (
          <>
            {/* Add more member in group start*/}
            <div className="chatGrpDtlArea">
              <div className="addGrpParticpent">
                <div className="selectedPartList">
                  <div className="slctedChtUserListGrp">
                    {selectedUserList &&
                      selectedUserList.length > 0 &&
                      refreshCount > 0 && (
                        <>
                          {selectedUserList.map((user) => (
                            <div className="slctedChtUser">
                              <div className="dlctUsrImgNmPerm">
                                <div className="scuProImg">
                                  <img
                                    alt={t("profile_photo")}
                                    src={user?.profileUrl}
                                  />
                                </div>
                                <div className="adNewPerDiv">
                                  <div className="scuNm">{user.userName}</div>
                                  <div className="usrPermisGrp">
                                    {/* don't remove */}
                                    {/* <div className="usrPerLst">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={user.readPrevious}
                                            size="small"
                                            onClick={() => {
                                              user.readPrevious =
                                                !user.readPrevious;
                                              setRefreshCount((r) => r + 1);
                                            }}
                                          />
                                        }
                                        label="View past chat"
                                      />
                                    </div> */}
                                    <div className="usrPerLst">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={user.adminUser}
                                            size="small"
                                            onClick={() => {
                                              user.adminUser = !user.adminUser;
                                              setRefreshCount((r) => r + 1);
                                            }}
                                          />
                                        }
                                        label={t("make_admin")}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* handle remove selected user start */}
                              <div
                                className="scuRmv"
                                role="button"
                                onClick={() =>
                                  setSelectedUserList(
                                    selectedUserList.filter(
                                      (x) => x.userId !== user.userId
                                    )
                                  )
                                }
                              >
                                <Xmark />
                              </div>
                              {/* handle remove selected user end */}
                            </div>
                          ))}
                        </>
                      )}
                  </div>
                </div>

                {/* search user input start */}
                <div className="chtsrchUsr">
                  <div className="srchUsrIpG">
                    <div className="searchUser">
                      <div className="searchIcon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M19.6 21L13.3 14.7C12.8 15.1 12.225 15.4167 11.575 15.65C10.925 15.8833 10.2333 16 9.5 16C7.68333 16 6.146 15.3707 4.888 14.112C3.63 12.8533 3.00067 11.316 3 9.5C3 7.68333 3.62933 6.146 4.888 4.888C6.14667 3.63 7.684 3.00067 9.5 3C11.3167 3 12.8543 3.62933 14.113 4.888C15.3717 6.14667 16.0007 7.684 16 9.5C16 10.2333 15.8833 10.925 15.65 11.575C15.4167 12.225 15.1 12.8 14.7 13.3L21 19.6L19.6 21ZM9.5 14C10.75 14 11.8127 13.5627 12.688 12.688C13.5633 11.8133 14.0007 10.7507 14 9.5C14 8.25 13.5627 7.18767 12.688 6.313C11.8133 5.43833 10.7507 5.00067 9.5 5C8.25 5 7.18767 5.43767 6.313 6.313C5.43833 7.18833 5.00067 8.25067 5 9.5C5 10.75 5.43767 11.8127 6.313 12.688C7.18833 13.5633 8.25067 14.0007 9.5 14Z"
                            fill="#A8A8A8"
                          />
                        </svg>
                      </div>

                      <input
                        type="text"
                        className="serchInput"
                        placeholder={t("search_name_to_add_contact")}
                        value={searchKey}
                        onChange={(e) => setSearchKey(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* search user input end */}

                <div className="createGroupCont">
                  <div className="contactListGroup">
                    {filterUserContactList &&
                      filterUserContactList.length > 0 && (
                        <>
                          {filterUserContactList.map((user) => (
                            <div
                              className="chatContactList"
                              onClick={() => handleSelectUser(user)}
                            >
                              <div className="contUserImg">
                                <img
                                  alt={t("profile_photo")}
                                  src={user?.profileUrl}
                                />
                                {/* <span className="usrActStatus"></span> */}
                              </div>

                              <div className="userDtlWlMsg">
                                <div className="usrNmDT">
                                  <div className="usrNmIn">
                                    {user?.userName}
                                    {user?.userType === "I" && <Verified />}
                                    {user?.userType === "G" && (
                                      <PeopleRounded />
                                    )}
                                  </div>
                                </div>

                                <div className="usrLstMsgDtStus">
                                  <div className="lstMsgShw">
                                    {user?.userDtl}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>

            {selectedUserList && selectedUserList.length > 0 && (
              <div className="grpCretFooter">
                <IconButton
                  aria-label="Create Group"
                  className="cretGrpButton"
                  onClick={handleSubmitAddedUser}
                >
                  <PersonAdd />
                </IconButton>
              </div>
            )}
            {/* Add more member in group end*/}
          </>
        )}
      </div>
    </>
  );
}
