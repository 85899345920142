import {
  ExpandMoreRounded,
  Add,
  Save,
  AttachFile,
  Biotech,
  Cable,
  Replay,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setHours, setMinutes } from "date-fns";
import { Trash } from "react-bootstrap-icons";
import zhPatientService from "../../../../../../../services/ZhPatientService";
import { getLoginUserDetails } from "../../../../../../../utils/Utils";
import dayjs from "dayjs";
import { getUnits } from "../../../../../../../services/AdminService";
import {
  MAX_FILE_SIZE_TASK,
  VALID_FILE_EXTENSION_TASK,
} from "../../../../../../../constants/const_values";
import { toast } from "react-toastify";
import { convertFileToBase64_Split } from "../../../../../../../utils/FileUtils";
import { CustomModal } from "../../../../../../task/view/component/Modals/CustomModal";
import zhMedicalEquipmentService from "../../../../../../../services/ZhMedicalEquipmentService";

export default function TestResultInput({ data, extraData }) {
  const loginUserDetails = getLoginUserDetails();
  const { t } = useTranslation();
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const [addParameter, setAddParameter] = useState(false);
  const [sampleCollected, setSampleCollected] = useState();
  const [sampleType, setSampleType] = useState();
  const [testMethod, setTestMethod] = useState();
  const [testItemMap, setTestItemMap] = useState({});
  const [testList, setTestList] = useState([]);
  const [timezone, setTimezone] = useState();
  const [testParameterName, setTestParameterName] = useState();
  const [tempFilteredTest, setTempFilteredTest] = useState([]);
  const [testValue, setTestValue] = useState("");
  const [testUnit, setTestUnit] = useState("");
  const [testReferenceRange, setTestReferenceRange] = useState("");
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const toggleaddParameter = () => {
    setAddParameter(!addParameter);
  };
  const handleUpdateSampleTypes = (event) => {
    setSampleType(event.target.value);
  };
  const handleUpdateSampleCollectedBy = (event) => {
    setSampleCollected(event.target.value);
  };
  const [completion, setCompletion] = useState(
    setHours(setMinutes(new Date(), 0), 9)
  );

  //   const filterPassedTime = (time) => {
  //     const currentDate = new Date();
  //     const selectedDate = new Date(time);

  //     return currentDate.getTime() < selectedDate.getTime();
  //   };

  // const filteredTest = useMemo(() => {
  //   const tempData = data?.investigation?.filter((item) => {
  //     return item.goFortest === true;
  //   });
  //   return tempData;
  // }, [data]);

  const initializeAddParameter = () => {
    setTestParameterName("");
    setTestUnit("");
    setTestValue("");
    setTestMethod("");
    setTestReferenceRange("");
  };

  const handelFileUpload = async (event) => {
    const files = event.target.files;
    let tempFileList = [];

    for (var i = 0; i < files.length; i++) {
      const file = files[i];

      if (
        !VALID_FILE_EXTENSION_TASK.includes(
          file.name.split(".").pop().toLowerCase()
        )
      ) {
        // toast.warning(`Invalid File Type Of '${file.name}'`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        toast.warning(t("invalidFileType", { fileName: file.name }), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (file.size > 1024 * 1024 * MAX_FILE_SIZE_TASK) {
        // toast.warning(
        //   `'${file.name}' file need to be less then ${MAX_FILE_SIZE_TASK}MB`,
        //   {
        //     position: toast.POSITION.TOP_RIGHT,
        //   }
        // );
        toast.warning(
          t("fileTooLarge", {
            fileName: file.name,
            maxFileSize: MAX_FILE_SIZE_TASK,
          }),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else {
        const dto = {
          fileName: file.name,
          fileDataLink: await convertFileToBase64_Split(files[i]),
        };
        tempFileList = dto;
      }
    }
    return tempFileList;
  };

  const handleNewTests = async () => {
    setTestItemMap({});
    const tempData = data?.investigation?.filter((item) => {
      return item.goFortest === true;
    });
    let temp = {};
    tempData?.forEach(async (element) => {
      if (!testItemMap[element.testId]) {
        let reqDto = {
          testId: element?.testId,
          gender: element?.gender,
          age: extraData?.queuePatientAge,
        };
        let parameterData = await zhPatientService.getTestParameters(reqDto);
        if (parameterData.data) {
          temp[element.testId] = parameterData.data;
          setTestItemMap((prev) => {
            let testValue = { ...prev };
            testValue[element.testId] = parameterData.data;
            return testValue;
          });
        }
      }
    });

    if (data?.investigation) {
      const setB = new Set(data?.investigation?.map((item) => item.testId));
      let filteredTestList = tempFilteredTest?.filter((item) =>
        setB.has(item.testId)
      );
      const idsInA = new Set(filteredTestList?.map((item) => item.testId));

      data?.investigation?.forEach((item) => {
        if (!idsInA.has(item.testId)) {
          filteredTestList?.push(item);
        }
      });

      // let tempFiltered = filteredTestList?.map(async (item, index) => {
      //   let temp = { ...item };
      //   let reqDto = {
      //     testId: item?.testId,
      //     gender: item?.gender,
      //     age: extraData?.queuePatientAge,
      //   };
      //   if (!filteredTestList?.parameterList) {
      //     let parameterData = await zhPatientService.getTestParameters(reqDto);

      //     temp.parameterList = parameterData.data;
      //   }
      //   return temp;
      // });

      console.log("filteredTestList", filteredTestList);
      setTempFilteredTest(filteredTestList);
      // setTempFilteredTest(tempFiltered);
    }
  };

  useEffect(() => {
    setTestList(data?.investigation);
    getUnits(loginUserDetails.orgId).then((response) => {
      if (response.data) {
        const temp = response.data?.find(
          (item) => item.unitId == loginUserDetails.signleUnitId
        );
        setTimezone(temp?.timezone || "");
      }
    });
  }, []);

  useEffect(() => {
    handleNewTests();
  }, [data]);

  useEffect(() => {
    console.log("filtered Test =", tempFilteredTest);
    tempFilteredTest?.forEach(async (test, index) => {
      console.log("filtered TEst=", test);
      if (!test.parameterList) {
        let reqDto = {
          testId: test?.testId,
          gender: test?.gender,
          age: extraData?.queuePatientAge,
        };
        let parameterData = await zhPatientService.getTestParameters(reqDto);
        if (parameterData.data) {
          setTempFilteredTest((prev) => {
            let temp = [...prev];
            temp[index].parameterList = parameterData.data;
            return temp;
          });
        }
      }
    });
  }, [tempFilteredTest]);

  const invList = [
    { invName: "Lipid Profile" },
    { invName: "MRI - Pituitary" },
    { invName: "Serum Immunoglobulins" },
    { invName: "BNP" },
    { invName: "Estrogen" },
    { invName: "Blood For R/E" },
    { invName: "Digoxin Serum Concentration" },
    { invName: "MRI - Both Shoulders" },
    { invName: "Epstein-Barr Virus Antibodies - Serum" },
    { invName: "Skin Scraping Of Lesion" },
  ];

  const filterPassedTime = (time, test) => {
    const selectedDate = new Date(test.sampleCollectionTime);
    const currentDate = new Date();

    // Only restrict if the selected date is today
    if (
      selectedDate.getDate() === currentDate.getDate() &&
      selectedDate.getMonth() === currentDate.getMonth() &&
      selectedDate.getFullYear() === currentDate.getFullYear()
    ) {
      // Return true only for times that are in the past (<= current time)
      return time.getTime() <= currentDate.getTime();
    }

    // For past dates, allow all times
    return true;
  };

  const [loadingBiochemData, setLoadingBiochemData] = useState(false);
  const [biochemTestRequisitionId, setBiochemTestRequisitionId] =
    useState(null);
  const [fetchBiochemDataIntervalId, setFetchBiochemDataIntervalId] =
    useState(null);
  const [biochemTestResult, setBiochemTestResult] = useState(null);

  useEffect(() => {
    let intervalId = null;
    if (biochemTestRequisitionId > 0) {
      intervalId = setInterval(async () => {
        const response = await zhMedicalEquipmentService.getBiochemTestResults(
          biochemTestRequisitionId
        );
        console.log("getBiochemTestResults-1--->>>>", response.data);
        if (response.data && response.data.rawDataDecoded) {
          console.log("getBiochemTestResults-2--->>>>", response.data);
          setBiochemTestResult(response.data);
          clearInterval(fetchBiochemDataIntervalId);
          setLoadingBiochemData(false);
        }
      }, 5000);
      setFetchBiochemDataIntervalId(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [biochemTestRequisitionId]);

  //ecg integration

  const [ecgDeviceDetails, setEcgDeviceDetails] = useState(null);

  const [loadingEcgTestData, setLoadingEcgTestData] = useState(false);
  const [ecgTestRequisitionId, setEcgTestRequisitionId] = useState(null);
  const [fetchEcgDataIntervalId, setFetchEcgDataIntervalId] = useState(null);
  const [ecgTestResult, setEcgTestResult] = useState(null);

  useEffect(() => {
    zhMedicalEquipmentService
      .getDeviceDetailsInUnitByType(loginUserDetails.signleUnitId, "ecg")
      .then((response) => {
        console.log("getDeviceDetailsInUnitByType", response.data);
        setEcgDeviceDetails(response.data);
      });
  }, []);

  useEffect(() => {
    let intervalId = null;
    if (ecgTestRequisitionId > 0) {
      intervalId = setInterval(async () => {
        const response = await zhMedicalEquipmentService.getEcgTestResults(
          ecgTestRequisitionId
        );
        console.log("getEcgTestResults-1--->>>>", response.data);
        if (response.data && response.data.fileData) {
          console.log("getEcgTestResults-2--->>>>", response.data);
          setEcgTestResult(response.data);
          clearInterval(fetchEcgDataIntervalId);
          setLoadingEcgTestData(false);
        }
      }, 5000);
      setFetchEcgDataIntervalId(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [ecgTestRequisitionId]);

  return (
    <>
      {tempFilteredTest?.length > 0 && (
        <Accordion
          className="docInputElmntAcod"
          expanded={accordionExpanded}
          onChange={() => {
            setAccordionExpanded((prev) => !prev);
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreRounded />}
            aria-controls="panel1-content"
            id="panel1-header"
            className="acodHeader"
          >
            <div className="tskElemHeding">
              {t("test_result")}:
              <span>{/*symptoms gose here when acordion not expend*/}</span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="testResult">
              <table className="testResulttable testResultInput">
                <thead>
                  <tr>
                    <th className="w50p">{t("test_name")}</th>
                    <th className="w15p textCenter">{t("value")}</th>
                    <th className="w15p textCenter">{t("unit")}</th>
                    <th className="w20p">{t("bio_ref_interval")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {tempFilteredTest
                    // ?.filter((item) => (item.hide === false ? false : true))
                    ?.map((test, index) => {
                      return (
                        <>
                          {!test.hide &&
                            !(
                              test?.testName === "ECG" &&
                              ecgDeviceDetails?.deviceId
                            ) &&
                            (!test?.testGroup ||
                              test?.testGroup?.toLowerCase() !==
                                "biochemistry") && (
                              <>
                                <tr>
                                  <td colSpan={5} className="singlRow">
                                    <div className="testNMTstloc">
                                      <div className="testNameH">
                                        {test.testName}
                                      </div>

                                      <div className="formElement">
                                        <FormControl className="formControl">
                                          <FormGroup
                                            className="formRadioGroup"
                                            labelId="setTasktype"
                                            name="setTasktype"
                                          >
                                            <FormControlLabel
                                              className="formRadioField"
                                              control={
                                                <Checkbox
                                                  checked={
                                                    test.outSource || false
                                                  }
                                                  onChange={(e) => {
                                                    let temp = [
                                                      ...tempFilteredTest,
                                                    ];
                                                    temp[index].outSource =
                                                      e.target.checked;
                                                    setTestList(temp);
                                                  }}
                                                />
                                              }
                                              label={t("external_report")}
                                            />
                                          </FormGroup>
                                        </FormControl>
                                      </div>
                                    </div>
                                    <div className="testInformation mt5">
                                      <div className="formElementGroup">
                                        <div className="formElement testResultInputCalendarDiv">
                                          <FormControl className="formControl">
                                            <InputLabel
                                              id="setCompletionDate"
                                              className="setCompletionDate"
                                            >
                                              {t("collection_date_time")}*
                                            </InputLabel>
                                            <ReactDatePicker
                                              showYearDropdown
                                              yearDropdownItemNumber={100}
                                              scrollableYearDropdown
                                              showMonthDropdown
                                              showIcon
                                              toggleCalendarOnIconClick
                                              labelId="setCompletionDate"
                                              className="formDatePicker"
                                              calendarClassName="testResultInputCalendar"
                                              selected={
                                                test.sampleCollectionTime
                                              }
                                              maxDate={new Date()}
                                              showTimeSelect
                                              filterTime={(time) =>
                                                filterPassedTime(time, test)
                                              }
                                              dateFormat="dd-MMM-yyyy h:mm aa"
                                              onChange={(date) => {
                                                if (date > new Date()) {
                                                  toast.error(
                                                    t(
                                                      "please_select_a_past_time"
                                                    )
                                                  );
                                                  return false;
                                                }
                                                let temp = [
                                                  ...tempFilteredTest,
                                                ];
                                                temp[
                                                  index
                                                ].sampleCollectionTime = date;
                                                setTestList(temp);
                                                // setCompletion(date);
                                              }}
                                            />
                                          </FormControl>
                                        </div>
                                        <div className="formElement">
                                          <FormControl className="formControl">
                                            <InputLabel id="sampleType-label">
                                              {t("sample_type")}
                                            </InputLabel>
                                            <Select
                                              labelId="sampleType-label"
                                              id="sampleType-select"
                                              value={test.sampleType}
                                              // value={testList[index].sampleType}
                                              label={t("sample_type")}
                                              onChange={(e) => {
                                                let temp = [
                                                  ...tempFilteredTest,
                                                ];
                                                temp[index].sampleType =
                                                  e.target.value;
                                                setTestList(temp);
                                              }}
                                              className="formInputField"
                                              variant="outlined"
                                            >
                                              <MenuItem value="Blood">
                                                {t("blood")}
                                              </MenuItem>
                                              <MenuItem value="Urine">
                                                {t("urine")}
                                              </MenuItem>
                                              <MenuItem value="Sputum">
                                                {t("sputum")}
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        </div>
                                        <div className="formElement">
                                          <FormControl className="formControl">
                                            <TextField
                                              // value={testList[index].reagent}
                                              value={test.reagent}
                                              label={t("reagent")}
                                              autoComplete="off"
                                              placeholder={t("enter_reagent")}
                                              variant="outlined"
                                              className="formTextFieldArea"
                                              onChange={(e) => {
                                                let temp = [
                                                  ...tempFilteredTest,
                                                ];
                                                temp[index].reagent =
                                                  e.target.value;
                                                setTestList(temp);
                                              }}
                                            />
                                          </FormControl>
                                        </div>
                                      </div>
                                      {/* <div className="formElementGroup">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              value={testReagent}
                              label="Reagent"
                              autoComplete="off"
                              placeholder="Enter Reagent"
                              variant="outlined"
                              className="formTextFieldArea"
                              onChange={(e) => {
                                setTestReagent(e.target.value);
                              }}
                            />
                          </FormControl>
                        </div>
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              value={testMethod}
                              label="Method"
                              autoComplete="off"
                              placeholder="Enter Method"
                              variant="outlined"
                              className="formTextFieldArea"
                              onChange={(e) => {
                                setTestMethod(e.target.value);
                              }}
                            />
                          </FormControl>
                        </div>
                        <div className="formElement">
                          <Button
                            className="dfultPrimaryBtn"
                            startIcon={<Add />}
                          >
                            Parameters
                          </Button>
                        </div>
                      </div> */}
                                    </div>
                                  </td>
                                </tr>
                                {test?.parameterList?.map(
                                  (parameterItem, parIndex) => {
                                    return (
                                      <tr>
                                        <td>
                                          <div className="testElmGrp">
                                            <div className="testElmNM">
                                              {parameterItem.parameter}
                                            </div>
                                            {parameterItem.method && (
                                              <div className="testElmDtl">
                                                {/* {` Method: ${parameterItem.method}`} */}
                                                {t("methodMessage", {
                                                  method: parameterItem.method,
                                                })}
                                              </div>
                                            )}
                                          </div>
                                        </td>
                                        <td>
                                          <div className="testValue textCenter opc1">
                                            <div className="formElement minWidth100px">
                                              <FormControl className="formControl">
                                                <TextField
                                                  label={t("value")}
                                                  placeholder={t("eg_120")}
                                                  autoComplete="off"
                                                  variant="outlined"
                                                  className="formTextFieldArea"
                                                  required
                                                  value={
                                                    // testList?.[index]?.testResult?.[
                                                    //   parIndex
                                                    // ]?.parameterValue || ""
                                                    tempFilteredTest?.[index]
                                                      ?.parameterList?.[
                                                      parIndex
                                                    ]?.value || ""
                                                  }
                                                  onChange={(e) => {
                                                    const value =
                                                      e.target.value;
                                                    const temp = [
                                                      ...tempFilteredTest,
                                                    ];
                                                    // let tempPar = {
                                                    //   parameterName:
                                                    //     parameterItem.parameter,
                                                    //   parameterValue: value,
                                                    //   parameterUnit: parameterItem.unit,
                                                    //   parameterRefRange:
                                                    //     parameterItem.refRange[0],
                                                    //   parameterId: 0,
                                                    // };
                                                    // if (!temp[index].testResult) {
                                                    //   temp[index].testResult = [];
                                                    // }
                                                    // temp[index].testResult[parIndex] =
                                                    //   tempPar;
                                                    temp[index].parameterList[
                                                      parIndex
                                                    ].value = value;
                                                    setTestList(temp);
                                                  }}
                                                />
                                              </FormControl>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="testUnit textCenter">
                                            {parameterItem.unit}
                                          </div>
                                        </td>
                                        <td>
                                          <div className="testNrmlRange">
                                            <div className="tstnRangeElm">
                                              {/* Desirable : &lt; 150 */}
                                              {t("desirable_value")}
                                            </div>
                                            <div className="tstnRangeElm">
                                              {/* Borderline High: 150-199 */}
                                              {t("borderlinehigh")}
                                            </div>
                                            <div className="tstnRangeElm">
                                              {" "}
                                              {/* High: 200-499 */}
                                              {t("high")}
                                            </div>
                                            <div className="tstnRangeElm">
                                              {/* Very High : &gt; 500 */}
                                              {t("veryhigh")}
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <IconButton
                                            className="deleteRowBtn"
                                            onClick={() => {
                                              const temp = [
                                                ...tempFilteredTest,
                                              ];
                                              console.log("temp =", temp);
                                              temp[index].parameterList.splice(
                                                parIndex,
                                                1
                                              );
                                              setTempFilteredTest(temp);
                                            }}
                                          >
                                            <Trash />
                                          </IconButton>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                                {test?.openAddParameter && (
                                  <>
                                    <tr>
                                      <td colSpan={5}>
                                        <div className="addNewformGrp ">
                                          {/* <div className="formElement">
                                  <FormControl className="formControl">
                                    <Autocomplete
                                      freeSolo
                                      className="formAutocompleteField"
                                      variant="outlined"
                                      options={invList.map(
                                        (option) => option.invName
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          className="formAutoComInputField autocompFildPlaceholder"
                                          label={t("parameter_name")}
                                          InputProps={{
                                            ...params.InputProps,
                                          }}
                                        />
                                      )}
                                    />
                                  </FormControl>
                                </div> */}

                                          <div className="formElement">
                                            <FormControl className="formControl">
                                              <TextField
                                                value={testParameterName}
                                                label={t("parameter_name")}
                                                autoComplete="off"
                                                placeholder={t(
                                                  "parameter_name"
                                                )}
                                                variant="outlined"
                                                required
                                                className="formTextFieldArea"
                                                onChange={(e) => {
                                                  setTestParameterName(
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </FormControl>
                                          </div>

                                          <div className="formElement">
                                            <FormControl className="formControl">
                                              <TextField
                                                value={testMethod}
                                                label={t("method")}
                                                autoComplete="off"
                                                placeholder={t("enter_method")}
                                                variant="outlined"
                                                className="formTextFieldArea"
                                                onChange={(e) => {
                                                  setTestMethod(e.target.value);
                                                }}
                                              />
                                            </FormControl>
                                          </div>

                                          <div className="formElement minWidth100px">
                                            <FormControl className="formControl">
                                              <TextField
                                                label={t("value")}
                                                placeholder={t("eg_120")}
                                                autoComplete="off"
                                                variant="outlined"
                                                className="formTextFieldArea"
                                                // required
                                                value={testValue}
                                                onChange={(e) =>
                                                  setTestValue(e.target.value)
                                                }
                                              />
                                            </FormControl>
                                          </div>

                                          <div className="formElement minWidth100px">
                                            <FormControl className="formControl">
                                              <TextField
                                                label={t("unit")}
                                                placeholder={t("eg_120")}
                                                autoComplete="off"
                                                variant="outlined"
                                                className="formTextFieldArea"
                                                value={testUnit}
                                                onChange={(e) =>
                                                  setTestUnit(e.target.value)
                                                }
                                              />
                                            </FormControl>
                                          </div>
                                        </div>
                                        <div className="addNewformGrp ">
                                          <div className="formElement">
                                            <FormControl className="formControl">
                                              <TextField
                                                label={t("reference_range")}
                                                autoComplete="off"
                                                variant="outlined"
                                                className="formTextFieldArea"
                                                value={testReferenceRange}
                                                onChange={(e) => {
                                                  setTestReferenceRange(
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </FormControl>
                                          </div>

                                          <IconButton
                                            className="updateRowBtn"
                                            onClick={(e) => {
                                              let isValid = true;
                                              if (
                                                !testParameterName ||
                                                !testParameterName?.trim("")
                                              ) {
                                                toast.error(
                                                  t("please_enter_test_name")
                                                );
                                                isValid = false;
                                              }

                                              if (!isValid) {
                                                return;
                                              }

                                              const dto = {
                                                parameter: testParameterName,
                                                method: testMethod,
                                                unit: testUnit,
                                                value: testValue,
                                                refRange: [],
                                              };
                                              const temp = [
                                                ...tempFilteredTest,
                                              ];
                                              temp?.[
                                                index
                                              ]?.parameterList?.push(dto);
                                              initializeAddParameter();
                                              setTempFilteredTest(temp);
                                            }}
                                          >
                                            <Add />
                                          </IconButton>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                )}
                                <tr>
                                  <td colSpan={5}>
                                    <div className="updateNewTest formBtnElement">
                                      <input
                                        // ref={fileRef}
                                        hidden
                                        onChange={async (e) => {
                                          const fileData =
                                            await handelFileUpload(e);
                                          const temp = [...tempFilteredTest];
                                          temp[index].fileData = fileData;
                                          setTempFilteredTest(temp);
                                        }}
                                        type="file"
                                        id={`upload-button-${test.testId}`}
                                      />
                                      <div className="atchFilWthfilNm">
                                        <Button
                                          variant="outlined"
                                          startIcon={<AttachFile />}
                                          className={
                                            tempFilteredTest?.[index]?.fileData
                                              ? "dfultPrimaryBtn"
                                              : "comBtnOutline"
                                          }
                                          onClick={() => {
                                            document
                                              .getElementById(
                                                `upload-button-${test.testId}`
                                              )
                                              .click();
                                          }}
                                        >
                                          {t("upload_report")}
                                        </Button>
                                        {test?.fileData && (
                                          <>
                                            <div className="attachedFileDtl">
                                              <span>
                                                {test?.fileData?.fileName}
                                              </span>
                                              <IconButton
                                                // className="goPrevousBtn"
                                                // className="deleteRowBtn"
                                                onClick={() => {
                                                  let temp = [
                                                    ...tempFilteredTest,
                                                  ];
                                                  temp[index].fileData = null;
                                                  setTestList(temp);
                                                }}
                                              >
                                                <Trash />
                                              </IconButton>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={5}>
                                    <div className="updateNewTest formBtnElement">
                                      <Button
                                        startIcon={<Add />}
                                        className={
                                          test.openAddParameter
                                            ? "dfultPrimaryBtn"
                                            : "dfultDarkSecondaryBtn"
                                        }
                                        onClick={(e) => {
                                          let temp = [...tempFilteredTest];
                                          temp[index].openAddParameter = temp[
                                            index
                                          ].openAddParameter
                                            ? false
                                            : true;
                                          setTestList(temp);
                                          // toggleaddParameter();
                                        }}
                                      >
                                        {t("add_parameter")}
                                      </Button>

                                      <Button
                                        startIcon={<Save />}
                                        className="dfultPrimaryBtn"
                                        onClick={() => {
                                          let isValid = true;
                                          if (!test.sampleCollectionTime) {
                                            toast.error(
                                              t(
                                                "collection_time_cannot_be_empty"
                                              )
                                            );
                                            isValid = false;
                                          }

                                          if (!isValid) {
                                            return;
                                          }

                                          let missingParameterList = [];
                                          //validate if all the parameters has been given or not.
                                          test?.parameterList?.forEach(
                                            (item) => {
                                              if (!item.value) {
                                                missingParameterList.push(
                                                  item.parameter
                                                );
                                                isValid = false;
                                              }
                                            }
                                          );

                                          if (missingParameterList.length > 0) {
                                            setOpenConfirmationModal(true);
                                          }

                                          if (!isValid) {
                                            return;
                                          }

                                          let taskResult = test?.parameterList
                                            ?.filter((item) =>
                                              item.value ? true : false
                                            )
                                            ?.map((parameterItem) => {
                                              return {
                                                parameterName:
                                                  parameterItem.parameter,
                                                parameterValue:
                                                  parameterItem.value,
                                                parameterUnit:
                                                  parameterItem.unit,
                                                parameterRefRange:
                                                  parameterItem.refRange[0],
                                                parameterId: 0,
                                              };
                                            });

                                          if (
                                            (!taskResult ||
                                              taskResult?.length === 0) &&
                                            !test?.fileData
                                          ) {
                                            toast.error(
                                              t("no_test_data_provided")
                                            );
                                            isValid = false;
                                          }

                                          if (!isValid) {
                                            return;
                                          }

                                          const reqDto = {
                                            sampleType: test.sampleType || "",
                                            testId: test.testId,
                                            requestionId: 0,
                                            testName: test.testName,
                                            visitId: extraData?.queueVisitId,
                                            patientId:
                                              extraData?.queuePatientId,
                                            outSource: test.outSource
                                              ? "Y"
                                              : "N",
                                            fileName:
                                              test?.fileData?.fileName || "",
                                            reagent: test.reagent || "",
                                            sampleCollectionTime: dayjs(
                                              test.sampleCollectionTime
                                            ).format("DD-MM-YYYY HH:mm:ss"),
                                            timezone: timezone,
                                            fileData:
                                              test?.fileData?.fileDataLink ||
                                              "",
                                            orgId: loginUserDetails.orgId,
                                            testResult: taskResult || [],
                                          };
                                          console.log("reqdto =", reqDto);
                                          // return;
                                          zhPatientService
                                            .submitTestResultWithoutRequestion(
                                              loginUserDetails.userId,
                                              reqDto
                                            )
                                            .then((response) => {
                                              if (response.data != 0) {
                                                toast.success(
                                                  t(
                                                    "test_result_submitted_successfully"
                                                  )
                                                );
                                                let temp = [
                                                  ...tempFilteredTest,
                                                ];
                                                temp[index].hide = true;
                                                setTestList(temp);
                                              }
                                            });
                                        }}
                                      >
                                        {t("save")}
                                      </Button>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={5}>
                                    <div className="bb"></div>
                                  </td>
                                </tr>
                              </>
                            )}
                          {!(
                            test?.testName === "ECG" &&
                            ecgDeviceDetails?.deviceId
                          ) &&
                            test?.testGroup?.toLowerCase() ===
                              "biochemistry" && (
                              <>
                                <tr>
                                  <td colSpan={5} className="singlRow">
                                    <div className="testNMTstloc">
                                      <div className="testNameH">
                                        <div className="tNameH">
                                          {test.testName}
                                        </div>
                                      </div>

                                      <div className="testRmvExtarnalfil">
                                        {/* {!biochemTestResult &&
                                                !loadingBiochemData && (
                                                  <Tooltip
                                                    title="Remove Test"
                                                    arrow
                                                  >
                                                    <div
                                                      className="removeTestfrmList"
                                                      onClick={() => {
                                                        const temp = [
                                                          ...tempFilteredTest,
                                                        ];
                                                        temp.splice(index, 1);
                                                        setUserInvestigationList(
                                                          temp
                                                        );
                                                      }}
                                                    >
                                                      {t("remove")}
                                                    </div>
                                                  </Tooltip>
                                                )} */}
                                      </div>
                                    </div>
                                    <div className="testInformation mt5">
                                      <div className="startTestGrp">
                                        {!biochemTestResult && (
                                          <>
                                            <Button
                                              disabled={loadingBiochemData}
                                              className={`startTestBtn ${
                                                !loadingBiochemData
                                                  ? "active"
                                                  : "loading"
                                              }`}
                                              startIcon={<Biotech />}
                                              onClick={async (e) => {
                                                setLoadingBiochemData(true);
                                                console.log(
                                                  "biochem start",
                                                  test
                                                );
                                                const testDto = test;
                                                const reqDto = {
                                                  unitId:
                                                    loginUserDetails.signleUnitId,
                                                  orgId: loginUserDetails.orgId,
                                                  testId: testDto.testId,
                                                  testName: testDto.testName,
                                                  patientId:
                                                    extraData?.queuePatientId,
                                                  outsource: "N",
                                                  sampleCollectionTime: dayjs(
                                                    new Date()
                                                  ).format(
                                                    "DD-MM-YYYY HH:mm:ss"
                                                  ),
                                                  timezone:
                                                    timezone || "Asia/Calcutta",
                                                  visitId:
                                                    extraData?.queueVisitId,
                                                  sampleType: "Blood",
                                                  reagent: null,
                                                };
                                                console.log("reqDto", reqDto);
                                                // return;
                                                const response =
                                                  await zhMedicalEquipmentService.createMachineTestRequisition(
                                                    loginUserDetails.userId,
                                                    reqDto
                                                  );
                                                console.log(
                                                  "response",
                                                  response.data
                                                );
                                                if (
                                                  response.data.returnValue ===
                                                  "1"
                                                ) {
                                                  toast.success(
                                                    "Biochemistry test requisition created successfully, please wait for test results"
                                                  );
                                                  setBiochemTestRequisitionId(
                                                    response.data.requisitionId
                                                  );
                                                } else {
                                                  toast.error(
                                                    "Something went wrong"
                                                  );
                                                }
                                              }}
                                            >
                                              {!loadingBiochemData
                                                ? "Start Test"
                                                : "Please wait"}
                                              <span class="loader__dot">.</span>
                                              <span class="loader__dot">.</span>
                                              <span class="loader__dot">.</span>
                                            </Button>
                                          </>
                                        )}
                                        {biochemTestResult && (
                                          <Button
                                            disabled={true}
                                            className={`startTestBtn`}
                                            startIcon={<Biotech />}
                                          >
                                            Result fetched
                                          </Button>
                                        )}
                                        <div className="tNameSubH">
                                          {"Disk Name: "}
                                          {test.diskName}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={5}>
                                    <div className="waitforTestResult">
                                      {loadingBiochemData &&
                                        !biochemTestResult &&
                                        "The results will appear automatically once the test is done, It will take 8 to 15 mins  complete."}
                                    </div>
                                  </td>
                                </tr>
                                {biochemTestResult &&
                                  biochemTestResult.rawDataDecoded &&
                                  biochemTestResult.testResult &&
                                  biochemTestResult.testResult.map(
                                    (testParamDto) => {
                                      let isResultOutOfRange = false;
                                      if (
                                        testParamDto.parameterRefRange?.split(
                                          "-"
                                        ).length === 2
                                      ) {
                                        isResultOutOfRange =
                                          parseFloat(
                                            testParamDto.parameterValue
                                          ) <
                                            parseFloat(
                                              testParamDto.parameterRefRange?.split(
                                                "-"
                                              )[0]
                                            ) ||
                                          parseFloat(
                                            testParamDto.parameterValue
                                          ) >
                                            parseFloat(
                                              testParamDto.parameterRefRange?.split(
                                                "-"
                                              )[1]
                                            );
                                      } else if (
                                        testParamDto.parameterRefRange?.split(
                                          "<"
                                        ).length === 2
                                      ) {
                                        isResultOutOfRange =
                                          parseFloat(
                                            testParamDto.parameterValue
                                          ) < 0 ||
                                          parseFloat(
                                            testParamDto.parameterValue
                                          ) >
                                            parseFloat(
                                              testParamDto.parameterRefRange?.split(
                                                ">"
                                              )[1]
                                            );
                                      }
                                      return (
                                        <>
                                          <tr>
                                            <td>
                                              <div className="testElmGrp">
                                                <div className="testElmNM">
                                                  {testParamDto.parameterName}
                                                </div>

                                                {/* <div className="testElmDtl">
                                                        Zoyel Infusion Device
                                                        Analyzer
                                                      </div> */}
                                              </div>
                                            </td>
                                            <td>
                                              <div
                                                className={`testValue textCenter opc1 ${
                                                  isResultOutOfRange
                                                    ? "outOfRange"
                                                    : ""
                                                }`}
                                              >
                                                {testParamDto.parameterValue}
                                              </div>
                                            </td>
                                            <td>
                                              <div className="testUnit textCenter">
                                                {testParamDto.parameterUnit}
                                              </div>
                                            </td>
                                            <td>
                                              <div className="testNrmlRange">
                                                <div className="tstnRangeElm">
                                                  {t("desirable")} :{" "}
                                                  {
                                                    testParamDto.parameterRefRange
                                                  }
                                                </div>
                                              </div>
                                            </td>
                                            {/* <td>
                                                    <Tooltip
                                                      title="Remove Parameter"
                                                      arrow
                                                    >
                                                      <IconButton className="deleteRowBtn">
                                                        <Trash />
                                                      </IconButton>
                                                    </Tooltip>
                                                  </td> */}
                                          </tr>
                                        </>
                                      );
                                    }
                                  )}
                              </>
                            )}
                          {test?.testName === "ECG" &&
                            ecgDeviceDetails?.deviceId && (
                              <>
                                <tr>
                                  <td colSpan={5} className="singlRow">
                                    <div className="testNMTstloc">
                                      <div className="testNameH">
                                        <div className="tNameH">
                                          {test.testName}
                                        </div>
                                      </div>

                                      <div className="testRmvExtarnalfil">
                                        {!ecgTestResult &&
                                          !loadingEcgTestData && (
                                            <Tooltip title="Remove Test" arrow>
                                              <div
                                                className="removeTestfrmList"
                                                onClick={() => {
                                                  const temp = [
                                                    ...tempFilteredTest,
                                                  ];
                                                  temp.splice(index, 1);
                                                  setTempFilteredTest(temp);
                                                }}
                                              >
                                                {t("remove")}
                                              </div>
                                            </Tooltip>
                                          )}
                                      </div>
                                    </div>
                                    <div className="testInformation mt5">
                                      <div className="startTestGrp">
                                        {!ecgTestResult && (
                                          <div style={{ display: "flex" }}>
                                            <Button
                                              disabled={loadingEcgTestData}
                                              className={`startTestBtn ${
                                                !loadingEcgTestData
                                                  ? "active"
                                                  : "loading"
                                              }`}
                                              startIcon={<Biotech />}
                                              onClick={async (e) => {
                                                setLoadingEcgTestData(true);
                                                console.log("Ecg start", test);
                                                const testDto = test;
                                                const reqDto = {
                                                  unitId:
                                                    loginUserDetails.signleUnitId,
                                                  orgId: loginUserDetails.orgId,
                                                  testId: testDto.testId,
                                                  testName: testDto.testName,
                                                  patientId:
                                                    extraData?.queuePatientId,
                                                  outsource: "N",
                                                  sampleCollectionTime: dayjs(
                                                    new Date()
                                                  ).format(
                                                    "DD-MM-YYYY HH:mm:ss"
                                                  ),
                                                  timezone:
                                                    timezone || "Asia/Calcutta",
                                                  visitId:
                                                    extraData?.queueVisitId,
                                                  sampleType: null,
                                                  reagent: null,
                                                };
                                                console.log("reqDto", reqDto);
                                                // return;
                                                const response =
                                                  await zhMedicalEquipmentService.createMachineTestRequisition(
                                                    loginUserDetails.userId,
                                                    reqDto
                                                  );
                                                console.log(
                                                  "response",
                                                  response.data
                                                );
                                                if (
                                                  response.data.returnValue ===
                                                  "1"
                                                ) {
                                                  window.open(
                                                    `wellnest-12l://new-recording?source=glocal&age=${
                                                      extraData?.queuePatientAge.split(
                                                        "Y"
                                                      )[0]
                                                    }&gender=${
                                                      extraData.queuePatientGender
                                                    }&recNo=${
                                                      response.data
                                                        .requisitionId
                                                    }`
                                                  );
                                                  toast.success(
                                                    "Ecg test requisition created successfully, please wait for test results"
                                                  );
                                                  setEcgTestRequisitionId(
                                                    response.data.requisitionId
                                                  );
                                                } else {
                                                  toast.error(
                                                    "Something went wrong"
                                                  );
                                                }
                                              }}
                                            >
                                              {!loadingEcgTestData
                                                ? "Start ECG Test"
                                                : "Please wait"}
                                              <span class="loader__dot">.</span>
                                              <span class="loader__dot">.</span>
                                              <span class="loader__dot">.</span>
                                            </Button>
                                            {loadingEcgTestData && (
                                              <Button
                                                title="Retry Ecg Test"
                                                // className={`startTestBtn active`}
                                                startIcon={<Replay />}
                                                onClick={(item) =>
                                                  // console.log(
                                                  //   "extra data",
                                                  //   extraData
                                                  // )
                                                  window.open(
                                                    `wellnest-12l://new-recording?source=glocal&age=${
                                                      extraData?.queuePatientAge.split(
                                                        "Y"
                                                      )[0]
                                                    }&gender=${
                                                      extraData.queuePatientGender
                                                    }&recNo=${ecgTestRequisitionId}`
                                                  )
                                                }
                                              ></Button>
                                            )}
                                          </div>
                                        )}
                                        {/* {ecgTestResult && (
                                                  <Button
                                                    disabled={true}
                                                    className={`startTestBtn`}
                                                    startIcon={<Cable />}
                                                  >
                                                    Result fetched
                                                  </Button>
                                                )} */}
                                        {ecgTestResult &&
                                          ecgTestResult?.fileData && (
                                            // <img
                                            //   alt="Ecg test result"
                                            //   src={ecgTestResult.fileData}
                                            // ></img>
                                            <Button
                                              onClick={() => {
                                                window.open(
                                                  ecgTestResult.fileData,
                                                  "_blank",
                                                  "noopener,noreferrer"
                                                );
                                              }}
                                              className={`startTestBtn active`}
                                              startIcon={<Cable />}
                                            >
                                              View Report
                                            </Button>
                                          )}
                                        {/* <div className="tNameSubH">
                                                  {"Disk Name: "}
                                                  {item.diskName}
                                                </div> */}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                {/* <tr>
                                          <td colSpan={5}>
                                            <div className="waitforTestResult">
                                              {loadingEcgTestData &&
                                                !ecgTestResult &&
                                                "The results will appear automatically once the ECG test is done"}
                                            </div>
                                          </td>
                                        </tr> */}
                                {/* ecg test result section */}
                                {/* {ecgTestResult &&
                                          ecgTestResult?.fileData && (
                                            <img
                                              alt="Ecg test result"
                                              src={ecgTestResult.fileData}
                                            ></img>
                                          )} */}
                                <></>
                              </>
                            )}
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="bb"></div>
          </AccordionDetails>
        </Accordion>
      )}

      {openConfirmationModal && (
        <CustomModal
          modalTitle={t("value_not_provided")}
          // style={{ zIndex: 10001 }}
          closeModal={() => {
            setOpenConfirmationModal(false);
          }}
          className={"width90"}
        >
          <div>
            {t(
              "you_have_not_provided_values_for_some_of_the_parameters_please_provide_value_or_delete_the_parameter"
            )}
          </div>

          <div className="modActionBtnGrp actionsCreateOrg">
            {/* <Button className="dfultDarkSecondaryBtn" onClick={() => {}}>
              {t("continue")}
            </Button> */}
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                setOpenConfirmationModal(false);
              }}
            >
              {t("close")}
            </Button>
          </div>
        </CustomModal>
      )}
    </>
  );
}
