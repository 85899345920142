import { Add, Edit, Search } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  Modal,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Eye, Trash } from "react-bootstrap-icons";
import { jwtDecode } from "jwt-decode";
import {
  getActiveTemplatesOfOrganization,
  deactivateTemplate,
  getActivityList,
  addActivity,
  deleteSubActivity,
} from "../../../../services/AdminService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
// import { downloadFileFromUrl } from "../../../../utils/FileUtils";

export default function DefineActivityTemplate({
  handelOpenActivityTemplate,
  refreshActivityTemplate,
  handelOpenViewActivityTemplate,
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();

  ///////////////////////////////////////////////////////////////////////

  const [userActivity, setUserActivity] = useState("");
  const [activityListData, setActivityListData] = useState([]);

  const [load, setLoad] = useState(true);

  const [openModal, setOpenModal] = useState(false);

  const [dataUploading, setDataUpLoading] = useState(false);

  const [templateToDelete, setTemplateToDelete] = useState(null);

  const handleuserActivity = (e) => {
    console.log("handleuserActivity", e.target.value);
    setUserActivity(e.target.value);
  };

  useEffect(() => {
    fetchgetActivityList();
  }, [userDetails.orgId]);

  const fetchgetActivityList = () => {
    getActivityList(userDetails.orgId).then((response) => {
      console.log("getActivityList", response.data);
      setActivityListData(response.data);
      setLoad(false);
    });
  };

  const handleActivityFinalsubmit = () => {
    setDataUpLoading(true);
    console.log("handleActivityFinalsubmit");

    if (userActivity.trim() === "") {
      toast.error(t("please_enter_subactivity_work_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      // setLoading(false);
      setDataUpLoading(false);
      return;
    }

    addActivity(userDetails.userId, userDetails.orgId, userActivity).then(
      (response) => {
        if (response.data.returnValue == "1") {
          toast.success(t("activity_added_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          setUserActivity("");
          fetchgetActivityList();
          setDataUpLoading(false);
          // this.setState({ subActivityName: "" });
          // this.handleSearchSubActivity(this.state.selectedActivityId);
        } else {
          toast.error(t("error_adding_activity_please_try_again"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          setDataUpLoading(false);
        }
      }
    );
  };

  // const handleDeleteActivity = (subActivityId) => {
  //   deleteSubActivity(userDetails.userId, subActivityId).then((response) => {
  //     if (response.data.returnValue == "1") {
  //       toast.success(response.data.message, {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       fetchgetActivityList();
  //     } else {
  //       toast.error(response.data.message, {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     }
  //   });
  // };

  const handleDeleteActivity = (subActivityId) => {
    console.log("handleDeleteActivity", subActivityId);
    setTemplateToDelete(subActivityId);
    setOpenModal(true);
  };

  const confirmDelete = () => {
    if (templateToDelete) {
      setOpenModal(false);
      deleteSubActivity(userDetails.userId, templateToDelete).then(
        (response) => {
          console.log("data deleted", response.data);
          if (response.data.returnValue === "1") {
            toast.success(t("activity_deleted"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchgetActivityList();
            setOpenModal(false);
          } else {
            toast.error(t("error_deleting_activity_please_try_again"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  ////////////////////////////////////////////////////////////////////////

  const [loading, setLoading] = useState(true);

  const [activeTemplateData, setActiveTemplateData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const [filterActiveTemplateData, setFilterActiveTemplateData] = useState([]);

  useEffect(() => {
    fetchTemplateData();
  }, [refreshActivityTemplate]);

  const fetchTemplateData = () => {
    getActiveTemplatesOfOrganization(userDetails.orgId)
      .then((response) => {
        console.log("getActiveTemplatesOfOrganization", response.data);
        setActiveTemplateData(response.data);
        setFilterActiveTemplateData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
        setLoading(false);
      });
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = activeTemplateData.filter(
      (Template) =>
        Template.activity &&
        Template.activity.toLowerCase().includes(query.toLowerCase())
    );

    setFilterActiveTemplateData(filteredData);
  };

  // const handleDelete = (templateId) =>{
  //   deactivateTemplate(userDetails.userId, templateId)
  //   .then((response)=>{
  //     if (response.data.returnValue == "1") {
  //       toast.success(response.data.message, {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       // this.fetchActiveTemplateData();
  //     } else {
  //       toast.error(response.data.message, {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     }
  //   })
  // };

  return (
    <>
      <div className="fullContainArea">
        <div className="cuContainArea">
          <div className="expenditureContainer">
            <div className="expenElement">
              {/* <div className="expenElement"> */}
              <div className="expElemhead">{t("activity")}</div>
              <div className="expContain">
                <div className="elementFormContainer">
                  <div className="formElementGroupWthBtn">
                    <div className="formElement">
                      <FormControl className="formControl">
                        {/* <Autocomplete
                          noOptionsText="Please type to search"
                          multiple
                          className="formAutocompleteField"
                          variant="outlined"
                          disablePortal
                          freeSolo
                          options={activityListData}
                          getOptionLabel={(option) => option.name} // Corrected
                          // value={userActivity}
                          // onChange={handleuserActivity}
                          inputValue={userActivity}
                          onInputChange={handleuserActivity}
                          renderInput={(params) => (
                            <TextField {...params} label="Activity" />
                          )}
                          filterOptions={(options, { inputValue }) => {
                            return options.filter(
                              (option) =>
                                option.name
                                  .toLowerCase()
                                  .includes(inputValue.toLowerCase()) // Corrected
                            );
                          }}
                          getOptionDisabled={(option) =>
                            activityListData.some(
                              (item) => item.name === option.name
                            )
                          }
                        /> */}
                        <TextField
                        className="formAutoComInputField autocompFildPlaceholder"
                          label={t("activity")}
                          variant="outlined"
                          value={userActivity}
                          onChange={handleuserActivity}
                        />
                      </FormControl>
                    </div>

                    <Button
                      startIcon={<Add />}
                      className="dfultPrimaryBtn"
                      onClick={() => handleActivityFinalsubmit()}
                      disabled={dataUploading}
                    >
                      {dataUploading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        <>{t("add")}</>
                      )}
                    </Button>
                  </div>

                  {load ? (
                    <div className="center-progress" style={{ height: "65vh" }}>
                      <CircularProgress
                        sx={{ marginTop: "100px", marginLeft: "150px" }}
                      />
                    </div>
                  ) : activityListData.length === 0 ? (
                    <div class="noDataCard ">
                      <span>{t("oops")}</span>{t("no_activity_added_yet")}
                      <br />
                      <span className="sallMsg">
                        {t("click_add_button_to_create_new_activity")}
                      </span>
                    </div>
                  ) : (
                    <div class="elementListGrp">
                      <div class="elementHeding">{t("activity_added")}</div>
                      <ul class="elmListInfo custlist">
                        {activityListData.map((item) => (
                          <li key={item.id}>
                            <span className="rolsNm">{item.name}</span>
                            <IconButton
                              className="rmvBtn"
                              onClick={() => handleDeleteActivity(item.id)}
                            >
                              <Trash />
                            </IconButton>
                          </li>
                        ))}
                        {/* <li>
                        <span className="rolsNm">AGM </span>
                        <IconButton className="rmvBtn">
                          <Trash />
                        </IconButton>
                      </li>
                        <li>
                        <span className="rolsNm">Compliance</span>
                        <IconButton className="rmvBtn">
                          <Trash />
                        </IconButton>
                      </li> */}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="critiaclElement ">
              <div className="criHeadContenr">
                <div className="critElemntHead">{t("activity_template")}</div>
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn"
                  onClick={() => handelOpenActivityTemplate()}
                >
                  {t("template")}
                </Button>
              </div>
              <div className="critContain">
                {loading ? (
                  <div className="center-progress" style={{ height: "65vh" }}>
                    <CircularProgress
                      sx={{ marginTop: "100px", marginLeft: "150px" }}
                    />
                  </div>
                ) : activeTemplateData.length === 0 ? (
                  <div class="noDataCard ">
                    <span>{t("oops")}</span>{t("no_template_define_yet")}
                    <br />
                    <span className="sallMsg">
                    {t("click_add_template_button_to_create_new_template")}
                    </span>
                  </div>
                ) : (
                  <div className="TableContainer">
                    <table className="taskListTable">
                      <thead className="taskListtableHeader">
                        <tr>
                          <th>{t("activity_name")}</th>
                          <th>{t("template_list")}</th>
                          <th className="width100">{t("action")}</th>
                        </tr>
                      </thead>

                      <tbody className="scrolableTableBody">
                        {Object.values(
                          filterActiveTemplateData.reduce((acc, template) => {
                            if (!acc[template.activity]) {
                              acc[template.activity] = {
                                activity: template.activity,
                                fileDisplayNames: [template.fileDisplayName],
                                templateId: template.templateId,
                              };
                            } else {
                              acc[template.activity].fileDisplayNames.push(
                                template.fileDisplayName
                              );
                            }
                            return acc;
                          }, {})
                        ).map((groupedTemplate) => (
                          <tr key={groupedTemplate.activity}>
                            <td>
                              <div className="tdText">
                                {groupedTemplate.activity}
                              </div>
                            </td>
                            <td>
                              <div className="tdText">
                                {groupedTemplate.fileDisplayNames.join(", ")}
                              </div>
                            </td>
                            <td>
                              <div className="tblActionBtnGrp">
                                <IconButton
                                  onClick={() =>
                                    handelOpenViewActivityTemplate(
                                      groupedTemplate
                                    )
                                  }
                                >
                                  <Eye />
                                </IconButton>
                                {/* <IconButton
                                  className="removeRowBtn"
                                  onClick={() =>
                                    handleDelete(groupedTemplate.templateId)
                                  } 
                                >
                                  <Trash />
                                </IconButton> */}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>

                      {/* <tbody className="scrolableTableBody">
                        {filterActiveTemplateData.map((template) => (
                          <tr key={template.templateId}>
                            <td>
                              <div className="tdText">{template.activity}</div>
                            </td>
                            <td>
                              <div className="tdText">
                                {template.fileDisplayName}
                              </div>
                            </td>
                            <td>
                              <div className="tblActionBtnGrp">
                                <IconButton>
                                  <Eye />
                                </IconButton>
                                <IconButton
                                  className="removeRowBtn"
                                  onClick={() =>
                                    handleDelete(template.templateId)
                                  }
                                >
                                  <Trash />
                                </IconButton>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody> */}
                    </table>
                  </div>
                )}
                <Modal
                  open={openModal}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      maxWidth: "600px",
                      minHeight: "150px",
                      backgroundColor: "white",
                      padding: "20px",
                      borderRadius: "10px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <h2 id="modal-modal-title">
                    {t("do_you_want_to_delete_this_activity")} ?
                    </h2>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        className="dfultPrimaryBtn"
                        onClick={confirmDelete}
                      >
                        {t("yes")}
                      </Button>
                      <Button
                        // variant="contained"
                        className="dfultDarkSecondaryBtn"
                        onClick={handleCloseModal}
                      >
                        {t("no")}
                      </Button>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
