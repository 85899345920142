export const esLang = {
  // new dashboard
  open_setting: "Abrir Configuración",
  accounts: "Cuentas",
  scheduler: "Programador",
  manage_tasks: "Gestionar Tareas",
  manage_meetings: "Gestionar Reuniones",
  chat: "Chat",
  drive: "Drive",
  google_search: "Búsqueda en Google",
  sheets: "Hojas de Cálculo",
  word_processor: "Procesador de Textos",
  slides: "Presentaciones",
  analysis: "Análisis",
  profile: "Perfil",
  sign_out: "Cerrar Sesión",
  show_hide: "Mostrar/Ocultar",
  minimize: "Minimizar",
  maximize: "Maximizar",
  close: "Cerrar",
  meeting: "Reunión",
  pad: "Bloc",
  white_board: "Pizarra",
  task: "Tarea",
  pending_task: "Tarea Pendiente",
  completed_task: "Tarea Completada",
  concern: "Preocupación",
  delayed_task: "Tarea Retrasada",
  under_process: "En Proceso",
  create_new_task: "Crear Nueva Tarea",
  choose_department: "Elegir Departamento",
  choose_activity: "Elegir Actividad",
  activity: "Actividad",
  select_contract: "Seleccionar Contrato",
  description: "Descripción",
  fill_the_form: "Rellenar el Formulario",
  upload: "Subir",
  assign_user: "Asignar Usuario",
  select_time: "Seleccionar Hora",
  select_priority: "Seleccionar Prioridad",
  select_meeting: "Seleccionar Reunión",
  select_template: "Seleccionar Plantilla",
  assign_user_to_selected_task: "Asignar Usuario a la Tarea Seleccionada",
  select_date_time: "Seleccionar Fecha y Hora de Finalización",
  added_templates: "Plantillas Añadidas",
  available_templates: "Plantillas Disponibles",
  view_template: "Ver Plantilla",
  full_screen: "Pantalla Completa",
  exit_full_screen: "Salir de Pantalla Completa",
  close_template: "Cerrar Plantilla",
  no_files_uploaded: "No se han Subido Archivos",
  my_accounts: "Mis Cuentas",
  notifications: "Notificaciones",
  opened_files: "Archivos Abiertos",
  active_files: "Archivos Activos",
  active_file: "Archivo Activo",
  opened: "Abierto",
  // new dashboard

  // user profile
  change_avtar: "Cambiar Avatar",
  edit: "Editar",
  first_name: "Nombre",
  middle_name: "Segundo Nombre",
  last_name: "Apellido",
  cancel: "Cancelar",
  save: "Guardar",
  update_your_photo_and_personal_details:
    "Actualiza Tu Foto y Detalles Personales",
  employee_id: "ID de Empleado",
  department: "Departamento",
  designations: "Designaciones",
  joining_date: "Fecha de Ingreso",
  reporting_head: "Jefe de Reporte",
  date_of_birth: "Fecha de Nacimiento",
  martial_status: "Estado Civil",
  choose_martial_status: "Elige Estado Civil",
  select: "Seleccionar",
  single: "Soltero/a",
  married: "Casado/a",
  widowed: "Viudo/a",
  divorced: "Divorciado/a",
  separated: "Separado/a",
  official_phone_no: "Teléfono Oficial",
  personal_phone_no: "Teléfono Personal",
  emergency_phone_no: "Teléfono de Emergencia",
  official_email_id: "Correo Electrónico Oficial",
  personal_email_id: "Correo Electrónico Personal",
  password: "Contraseña",
  reset_password: "Restablecer Contraseña",
  current_password: "Contraseña Actual",
  new_password: "Nueva Contraseña",
  confirm_password: "Confirmar Contraseña",
  language: "Idioma",
  select_languages: "Seleccionar Idiomas",
  skills: "Habilidades",
  add_skills: "Agregar Habilidades",
  choose_your_own_avtar: "Elige Tu Propio Avatar",
  our_pre_biuld_avtar: "Preconstruido",
  upload_form_pc: "Subir",
  avtar_by_ai: "Avatar de IA",
  ai_avtar_coomiing_soon: "¡Avatar de IA Próximamente!",
  // user profile

  // task container
  please_give_a_comment: "Por Favor Deja un Comentario",
  please_give_a_concern: "Por Favor Expresa una Preocupación",
  your_progress_of_task_cant_not_decrease:
    "Tu Progreso en la Tarea No Puede Disminuir",
  please_type_your_reason: "Por Favor Escribe Tu Razón",
  please_select_a_user: "Por Favor Selecciona un Usuario",
  no_templates_found: "No se Encontraron Plantillas",
  created_by: "Creado Por",
  name: "Nombre",
  work: "Trabajo",
  task: "Tarea",
  status: "Estado",
  detail: "Detalle",
  add_concern_here: "Agrega una Preocupación Aquí",
  comments: "Comentarios",
  view: "Ver",
  add_link_task: "Agregar Tarea Vinculada",
  transfer: "Transferir",
  meetings: "Reuniones",
  from: "De",
  templates: "Plantillas",
  link_task: "Vincular Tarea",
  submit: "Enviar",
  ok: "OK",
  assignd_to: "Asignado a",
  re_assign: "Reasignar",
  disagree: "No Estoy de Acuerdo",
  sign_off: "Cerrar Sesión",
  view_attachment: "Ver Adjunto",
  view_task_history: "Ver Historial de Tareas",
  reason_for_transfer: "Razón Para la Transferencia",
  manage_your_files: "Gestionar Tus Archivos",
  upload_document: "Subir Documento",
  file_details: "Detalles del Archivo",
  please_enter_subTask_details: "Por Favor Ingresa Detalles de la Subtarea",
  please_select_users: "Por Favor Selecciona Usuarios",
  please_enter_task_description: "Por Favor Ingresa la Descripción de la Tarea",
  create_link_task: "Crear Tarea Vinculada",
  linked_task: "Tarea Vinculada",
  // task container

  gender: "Género",
  job_description: "Descripción del Trabajo",
  timezone: "Zona Horaria",
  current_address: "Dirección Actual",
  permanent_address: "Dirección Permanente",
  guardian_name: "Nombre del Tutor",
  guardian_relationship: "Relación con el Tutor",
  spouse_Name: "Nombre del Cónyuge",
  blood_groud: "Grupo Sanguíneo",
  religion: "Religión",
  bank_name: "Nombre del Banco",
  ifsc_code: "Código IFSC",
  bank_branch: "Sucursal del Banco",
  beneficiary_name: "Nombre del Beneficiario",
  bic_code: "Código BIC",
  swift_code: "Código SWIFT",
  iban_number: "Número IBAN",
  highest_qualification: "Máxima Calificación",
  highest_qualification_year: "Año de Máxima Calificación",
  highest_qualification_institute: "Instituto de Máxima Calificación",
  kra: "KRA",
  experiences: "Experiencias",
  govt_ids: "Identificaciones Gubernamentales",
  highest_qualification_certificate: "Certificado de Máxima Calificación",
  fitness_certificate: "Certificado de Aptitud",
  job_title: "Título del Trabajo",
  nature_of_appointment: "NATURALEZA DEL NOMBRAMIENTO",
  type_of_position: "TIPO DE POSICIÓN",
  new: "Nuevo",
  replacement: "Reemplazo",
  location_required_for: "UBICACIÓN REQUERIDA PARA",
  qualification: "CALIFICACIÓN",
  reset_form: "Restablecer Formulario",
  event: "Evento",
  booked_event: "Evento Reservado",
  canceled_event: "Evento Cancelado",
  reschedule_event: "Reprogramar Evento",
  contract_wise_pending_task: "Tarea Pendiente por Contrato",
  organization_tree: "Árbol de la Organización",
  employee_with: "Empleado con",
  most: "Más",
  few: "Pocos",
  no: "Ninguno",
  last_7_days: "Últimos 7 Días",
  last_30_days: "Últimos 30 Días",
  last_60_days: "Últimos 60 Días",
  activity_wise_completed_tasks: "Tareas Completadas por Actividad",
  title_wise_events: "Eventos por Título",
  day_wise_completed_task: "Tarea Completada por Día",
  day_wise_events: "Eventos por Día",
  routine: "Rutina",
  important: "Importante",
  critical: "Crítico",
  timeline: "Cronología",

  please_enter_room_id: "Por favor, ingrese el Id de la sala",
  room_number: "Número de Sala *",
  todays_meetings: "Reuniones de Hoy",
  join: "Unirse",
  schedule_meeting: "Programar Reunión",
  start_instant_meeting: "Iniciar Reunión Instantánea",

  meeting_details: "Detalles de la Reunión",
  please_enter_meeting_topic: "Por favor, ingrese el tema de la reunión",
  start_meeting: "Iniciar Reunión",
  end_meeting: "Finalizar Reunión",

  please_change: "Por favor, cambie",
  from_first_day_of_week_before_removing_it_from_working_day:
    "del Primer Día de la Semana antes de eliminarlo del día laboral",
  title: "Título",
  start_time: "Hora de Inicio",
  end_time: "Hora de Finalización",
  duration: "Duración",
  location: "Ubicación",
  new_event: "Nuevo Evento",
  new_recurring_event: "Nuevo Evento Recurrente",
  new_meeting: "Nueva Reunión",
  day: "Día",
  week: "Semana",
  month: "Mes",
  agenda: "Agenda",
  timeline_views: "Vistas de la Cronología",
  grouping: "Agrupación",
  gridlines: "Líneas de la Cuadrícula",
  row_auto_height: "Altura Automática de la Fila",
  tooltip: "Tooltip",
  allow_multi_drag: "Permitir Arrastre Múltiple",
  settings: "Configuraciones",
  previous: "Anterior",
  next: "Siguiente",
  current_event: "Evento Actual",
  today: "Hoy",
  my_calendar: "Mi Calendario",
  holiday: "Festivo",
  week_off: "Día Libre",
  on_leave: "De Licencia",

  first_day_of_week: "Primer Día de la Semana",
  work_week: "Semana Laboral",
  slot_duration: "Duración del Intervalo",
  select_slot_duration: "Seleccionar Duración del Intervalo",
  private_notes: "Notas Privadas",
  meeting_notes: "Notas de la Reunión",
  reschedule: "Reprogramar",

  add_private_notes: "Agregar Notas Privadas",
  add: "Agregar",

  end_time_should_be_after_start_time:
    "La hora de finalización debe ser después de la hora de inicio",
  please_select_participants: "Por favor, seleccione participantes",
  event_title: "Título del Evento",
  event_description: "Descripción del Evento",
  event_location: "Ubicación del Evento",
  event_start_time: "Hora de Inicio del Evento",
  event_end_time: "Hora de Finalización del Evento",
  recurring_event_details: "Detalles del Evento Recurrente",
  event_days: "Días del Evento",
  repeat_until: "Repetir Hasta",
  modify_event: "Modificar Evento",
  cancel_event: "Cancelar Evento",
  cancellation_reason: "Razón de la Cancelación",
  reschedule_reason: "Razón para Reprogramar",
  event_date: "Fecha del Evento",

  file_name: "Ingrese el Nombre del Archivo",
  choose_a_file: "Elija un archivo",
  please_enter_a_valid_file_name:
    "¡Por favor ingrese un nombre de archivo válido!",
  please_enter_a_valid_file_name_special_characters_are_not_allowed:
    "Por favor ingrese un nombre de archivo válido, no se permiten caracteres especiales",
  already_exists_replace_file_all_data_will_be_lost:
    "ya existe! Reemplace el archivo, se perderán todos los datos",

  please_enter_your_email: "Por favor, ingrese su correo electrónico",
  please_enter_your_password: "Por favor, ingrese su contraseña",
  invalid_credentials: "Credenciales inválidas",
  please_wait_redirecting_to_your_organization_home_page:
    "Por favor espere, redirigiendo a la página de inicio de su organización",
  otp_sent_to_email_id_successfully:
    "OTP enviado al correo electrónico con éxito",
  please_enter_otp: "Por favor, ingrese OTP",
  otp_verified_successfully: "OTP verificado con éxito",
  please_enter_password: "Por favor, ingrese la contraseña",
  please_enter_confirm_password:
    "Por favor, ingrese la confirmación de la contraseña",
  password_and_confirm_password_does_not_match:
    "La contraseña y la confirmación de la contraseña no coinciden",
  password_changed_successfully_redirecting:
    "Contraseña cambiada con éxito, redirigiendo...",

  lets_get_you: "Vamos a ponerte",
  on_the_digital_highway: "en la autopista digital",
  login: "Iniciar sesión",
  register: "Registrar",
  digitize: "DIGITALIZAR",
  processize: "PROCESAR",
  collaborate: "COLABORAR",
  deliver: "ENTREGAR",
  create_magic: "Crear magia",
  features: "CARACTERÍSTICAS",
  virtual_presence: "Presencia Virtual",
  create_your_web_presence_with_a_few_clicks_no_need_for_domains_webhosting_or_it_infra:
    "Cree su presencia web con unos pocos clics. No necesita dominios, alojamiento web o infraestructura de TI",
  organization: "Organización",
  create_your_organization_add_people_assign_departments_functions_and_go_live:
    "Cree su organización, agregue personas, asigne departamentos y funciones, y comience",
  task_management: "Gestión de Tareas",
  easily_create_tasks_and_monitor_them_remove_management_overheads:
    "Cree tareas fácilmente y monitoree su progreso. Elimine la sobrecarga de gestión",
  compliances: "Cumplimientos",
  hold_board_agm_meetings_issue_agenda_take_polls_record_them_keep_track_of_compliances:
    "Realice reuniones de Junta/AGM, emita agenda, tome votaciones, regístrelas. Mantenga un seguimiento de los cumplimientos",
  productivity: "Productividad",
  from_word_processor_to_spreadsheet_presenter_all_productivity_tools_built_in_with_drive_and_business_templates:
    "Desde procesadores de texto hasta hojas de cálculo y presentadores: todas las herramientas de productividad integradas con Drive y plantillas empresariales",
  collaboration: "Colaboración",
  complete_collaboration_suite_without_subscribing_to_any_other_provider:
    "Suite completa de colaboración sin necesidad de suscribirse a otro proveedor",
  security: "Seguridad",
  ensure_data_security_with_best_of_class_network_security_encryption_and_data_safety:
    "Garantice la seguridad de los datos con la mejor seguridad de red, encriptación y seguridad de datos",
  time_space_language: "Tiempo, espacio e idioma",
  with_multi_language_timezone_support_let_work_go_with_teams_clients_and_vendors_across_the_world:
    "Con soporte multilingüe y de zonas horarias, deje que el trabajo fluya con equipos, clientes y proveedores de todo el mundo",
  meetings_phygital: "Reuniones - Figital",
  synchronous_and_asynchronous_meetings_scheduled_and_instant_with_private_rooms_and_webinars:
    "Reuniones sincrónicas y asincrónicas, programadas e instantáneas, con salas privadas y seminarios web",
  complete_workflows: "Flujos de Trabajo Completos",
  hr_accounts_finance_business_sales_marketing_customizable_workflows:
    "Recursos Humanos, Cuentas, Finanzas, Negocios, Ventas y Marketing, flujos de trabajo personalizables",
  kill_wastage: "Eliminar Desperdicio",
  with_task_linked_meetings_and_result_based_task_stop_the_drift_and_save_crucial_time:
    "Con reuniones vinculadas a tareas y tareas basadas en resultados, detenga la deriva y ahorre tiempo crucial",
  analytics: "Analítica",
  manage_by_easily_monitoring_what_is_happening_and_allocate_resources_time_money_more_effectively:
    "Gestione fácilmente monitoreando lo que sucede y asigne recursos, tiempo y dinero de manera más efectiva",
  api_driven_menus_for_integrating_existing_tools_or_external_mail_messaging_etc_build_on_your_strengths_and_covers_your_gaps:
    "Menús impulsados por API para integrar herramientas existentes o correo externo, mensajería, etc. Aproveche sus fortalezas y cubra sus brechas",
  goals: "OBJETIVOS",
  effectivity: "Eficacia",
  remove_unnecessary_work_and_useless_meetings_with_our_effectivity_tools:
    "Elimine el trabajo innecesario y las reuniones inútiles con nuestras herramientas de eficacia",
  transcendence: "Trascendencia",
  with_ahlans_asynchronous_and_synchronous_collaboration_suite_allow_work_to_happen_with_and_against_time_zones_from_anywhere:
    "Con la suite de colaboración sincrónica y asincrónica de Ahlan, permita que el trabajo se realice con y contra las zonas horarias desde cualquier lugar",
  save_the_climate: "Salvar el Clima",
  stop_unnecessary_use_of_energy_paper_travel_to_protect_our_world_without_it_our_businesses_do_not_exist:
    "Detenga el uso innecesario de energía, papel y viajes para proteger nuestro mundo. Sin él, nuestros negocios no existirían",
  get_in_touch: "CONTACTO",
  mailing_address: "Dirección Postal",
  email_address: "Dirección de Correo Electrónico",
  phone_number: "Número de Teléfono",
  socials: "Redes Sociales",
  please_rotate_your_device: "Por favor, rote su dispositivo",
  we_dont_support_landscape_mode_yet_please_go_back_to_portrait_mode_for_the_best_experience:
    "Todavía no soportamos el modo horizontal. Por favor, vuelva al modo vertical para la mejor experiencia",
  forgot_password: "Olvidó la Contraseña",
  dont_have_an_account_sign_up: "¿No tiene una cuenta? Regístrese",
  proceed: "CONTINUAR",
  already_have_an_account_sign_in: "¿Ya tiene una cuenta? Inicie sesión",
  sign_up: "REGISTRARSE",
  send_otp: "Enviar OTP",
  submit_otp: "Enviar OTP",
  change: "CAMBIAR",

  ////////
  task_information: "Información de la Tarea",
  event_times: "Tiempos del Evento",
  start_time: "Hora de Inicio",
  end_time: "Hora de Fin",
  create_org: "Crear Organización",
  please_select_progress: "Por favor seleccione el Progreso",
  confirmation: "Confirmación",
  plese_enter_concern: "Por favor, ingrese la Preocupación",
  please_enter_comment: "Por favor, ingrese el Comentario",
  select_your_progress_of_task: "Seleccione su Progreso de la Tarea",
  recommended_templates: "Plantillas Recomendadas",
  more_templates: "Más Plantillas",
  list_of_meetings: "Lista de Reuniones",
  add_internal_user: "Agregar Usuario Interno",
  add_external_user: "Agregar Usuario Externo",
  attend: "Asistir",
  unable_to_attend: "No Puede Asistir",
  add_contacts: "Agregar Contactos",
  admin_dashboard: "Panel de Administración",
  // create_org: "Crear Organización",
  create_and_open_file: "Crear y Abrir Archivo",
  record_audio_note: "Grabar Nota de Audio",
  audio_note: "Nota de Audio",

  // dynamic response message
  something_went_wrong: "Algo salió mal",
  link_task_not_completed_against_this_task:
    "La tarea vinculada no se completó para esta tarea",
  task_completed_successfully: "Tarea completada con éxito",
  concern_raised_successfully: "Preocupación planteada con éxito",
  approved_successfully: "Aprobado con éxito",
  task_progress_report_submitted_successfully:
    "Informe de progreso de la tarea enviado con éxito",
  please_select_contacts: "Por favor seleccione contactos",
  vendor_partner_added_successfully: "Proveedor/Asociado añadido con éxito",
  email_address_already_exists: "La dirección de correo electrónico ya existe",

  //dashboard tooltips
  add_function: "Agregar Función",
  list_of_function: "Lista de Funciones",
  add_people: "Agregar Personas",
  list_of_people: "Lista de Personas",
  add_cost_center: "Agregar Centro de Costo",
  add_products: "Agregar Productos",
  list_of_products: "Lista de Productos",
  add_services: "Agregar Servicios",
  list_of_services: "Lista de Servicios",
  service_faq: "Preguntas Frecuentes del Servicio",
  add_template: "Agregar Plantilla",
  list_of_template: "Lista de Plantillas",
  add_contracts: "Agregar Contratos",
  list_of_contracts: "Lista de Contratos",
  add_accounts_and_invoices: "Agregar Cuentas y Facturas",
  list_of_accounts_and_invoices: "Lista de Cuentas y Facturas",
  edit_website: "Editar Sitio Web",
  drive_details: "Detalles del Disco",
  please_wait: "Por Favor Espere",
  copy_event_details: "Copiar Detalles del Evento",
  add_Items: "Agregar Elementos",
  list_of_Items: "Lista de Elementos",
  verified_items: "Elementos Verificados",
  non_verified_items: "Elementos No Verificados",
  custom_date: "Fecha Personalizada",
  enter_meeting: "Ingresar Reunión",
  zoyel_assistant: "Asistente Zoyel",
  enter_zoyel_mode: "Ingresar al Modo Zoyel",
  remove_notification: "Eliminar Notificación",
  back_to_normal_mode: "Volver al Modo Normal",
  switch_account: "Cambiar Cuenta",
  max_length_15: "La longitud máxima es 15",
  physical_meeting: "Reunión Física",
  start_convenient_meeting: "Iniciar Reunión Conveniente",
  select_and_drag_to_set_you_timing:
    "Seleccionar y arrastrar para establecer el horario",
  approved_by: "Aprobado Por",
  task_has_been_cancelled: "La tarea ha sido cancelada",
  task_has_been_closed: "La tarea ha sido cerrada",
  task_has_been_completed: "La tarea ha sido completada",
  documents_uploaded: "Documentos Subidos",
  please_fill_the_form_correctly:
    "Por Favor Complete el Formulario Correctamente",
  document_viewer: "Visor de Documentos",
  reject: "Rechazar",
  add_rejection_reason_here: "Agregar Razón de Rechazo Aquí",
  rejected_by: "Rechazado Por",
  rejection_reason: "Razón de Rechazo",
  add_cancelation_reason_here: "Agregar Razón de Cancelación Aquí",
  change_mode: "Cambiar Modo",
  word: "Palabra",
  assistant: "Asistente",

  menu: "Menú",
  open_main_chat_window: "Abrir Ventana de Chat Principal",
  view_form: "Ver Formulario",
  postponed: "Pospuesto",
  postpone_by: "Pospuesto Por",
  add_postponed_details_here: "Agregar Detalles de Posposición Aquí",
  postponed_reason: "Razón de Posposición",
  postponed_date: "Fecha de Posposición",
  please_select_correct_date: "Por Favor Seleccione la Fecha Correcta",
  postpone_till: "Posponer Hasta",
  reopen_postpone_task: "Reabrir Tarea Pospuesta",
  password_changed_successfully: "Contraseña Cambiada Exitosamente",
  password_changed_successfully_please_login:
    "Contraseña Cambiada Exitosamente, Por Favor Inicie Sesión...",

  //////////Analytical Dashboard/////////////
  dashboard: "Tablero",
  oops: "¡Ups!",
  permission_for_dashboard_modules:
    "Permiso para los módulos del tablero no encontrado, por favor contacta a tu Administrador",
  attendance: "Asistencia",
  budget: "Presupuesto",
  contract: "Contrato",
  external_user: "Usuario Externo",
  no_of_user: "Número de Usuarios",
  holiday_working_day: "Día Festivo / Día Laboral",
  no_of_holiday: "Número de Festivos",
  items: "Elementos",
  leave_rules: "Reglas de Permiso",
  payroll: "Nómina",
  active_employee: "Empleado Activo",
  tenure: "Antigüedad",
  employee: "Empleado",
  attrition: "Desgaste",
  routine_work: "Trabajo de Rutina",
  add_budget: "Agregar Presupuesto",
  consolidated: "Consolidado",
  unit_wise: "Por Unidad",
  select_unit: "Seleccionar Unidad",
  budget_name: "Nombre del Presupuesto",
  from_date: "Desde la Fecha",
  to_date: "Hasta la Fecha",
  capital_expenditure_head: "Encargado de Gasto de Capital",
  operating_expenditure_head: "Encargado de Gasto Operativo",
  submit: "Enviar",
  cancel: "Cancelar",
  add_contract: "Agregar Contrato",
  contract_name: "Nombre del Contrato",
  responsible_person_name: "Nombre de la Persona Responsable",
  contract_type: "Tipo de Contrato",
  contract_owner: "Propietario del Contrato",
  attach_file: "Adjuntar Archivo",
  add_external_user: "Agregar Usuario Externo",
  select_user_type: "Seleccionar Tipo de Usuario",
  vendor: "Proveedor",
  partner: "Socio",
  client: "Cliente",
  adviser: "Asesor",
  board_member: "Miembro de la Junta",
  consultant: "Consultor",
  title: "Título",
  mr: "Sr.",
  ms: "Sra.",
  mrs: "Sra.",
  miss: "Srta.",
  first_name: "Nombre",
  middle_name: "Segundo Nombre",
  last_name: "Apellido",
  official_email_id: "Correo Electrónico Oficial",
  country_code: "Código de País",
  phone_number: "Número de Teléfono",
  full_address: "Dirección Completa",
  fax_number: "Número de Fax",
  gst_number: "Número de GST",
  add_holiday: "Agregar Festivo",
  holiday_name: "Nombre del Festivo",
  holiday_date: "Fecha del Festivo",
  holiday_description_for_email: "Descripción del Festivo para el Correo",
  attach_image_for_emailer: "Adjuntar Imagen para el Correo",
  preview: "Vista Previa",
  holiday_emailer_preview: "Vista Previa del Correo del Festivo",
  greeting: "¡Saludos!",
  item_type: "Tipo de Artículo",
  goods: "Bienes",
  service: "Servicio",
  item_name: "Nombre del Artículo",
  description: "Descripción",
  category: "Categoría",
  add_category: "Agregar Categoría",
  default_uom: "UOM Predeterminado",
  uom: "UOM",
  add_uom: "Agregar UOM",
  manufacturer: "Fabricante",
  add_manufacturer: "Agregar Fabricante",
  hsn_code: "Código HSN",
  expirable: "Expirable",
  warrantee_guarantee_sla: "Garantía/ Garantía/ SLA",
  tangible: "Tangible",
  intangible: "Intangible",
  category_name: "Nombre de la Categoría",
  add_item_type: "Agregar Tipo de Artículo",
  enter_uom: "Ingresar UOM",
  manufacturer_name: "Nombre del Fabricante",
  add_leave_rules: "Agregar Reglas de Permiso",
  select_enter_leave_type: "Seleccionar/Ingresar Tipo de Permiso",
  select_gender: "Seleccionar Género",
  employee_status: "Estado del Empleado",
  salary_type: "Tipo de Salario",
  required_documents: "Documentos Requeridos",
  example_file_names: "Ej: Archivo Uno, Archivo Dos, etc.",
  this_is_need_for_supportive_documents:
    "Esto es necesario para documentos de apoyo, agrega nombres de archivos separados por una coma, por ej.: Archivo Uno, Archivo Dos, etc.",
  number_of_leaves: "Número de Días de Permiso",
  leave_calculation_can_be_done_either_based_on_total_number_of_leaves:
    "El cálculo de permisos se puede hacer ya sea en base al Número Total de Días de Permiso, permisos acumulados mensualmente o permisos acumulados anualmente (si algún permiso se acumula mensualmente, ingresa el valor en el cuadro de texto Permiso Acumulado Mensualmente y deja los demás cuadros de texto en blanco, es decir, Número Total de Días de Permiso y Permisos Acumulados Anualmente en blanco)",
  leave_accrued_monthly: "Permiso Acumulado Mensualmente",
  leave_accrued_yearly: "Permiso Acumulado Anualmente",
  minimum_leaves: "Mínimo de Días de Permiso",
  minimum_number_of_leaves_an_employee_may_apply_for_a_particular_leave_type:
    "Número mínimo de días de permiso que un empleado puede solicitar para un tipo de permiso particular",
  maximum_leaves: "Máximo de Días de Permiso",
  maximum_number_of_leaves_an_employee_may_apply_at_a_stretch:
    "Número máximo de días de permiso que un empleado puede solicitar de una vez",
  allow_half_day: "Permitir Medio Día",
  if_half_day_is_applicable_for_this_leave_type:
    "Si el medio día es aplicable para este tipo de permiso",
  combination_with_other_leaves: "Combinación con Otros Permisos",
  other_leave_type_that_may_be_combined_with_this_leave_type_as_per_the_organizational_leave_policy_for_eg_medical_sick_leave_may_be_combined_with_leave_x_leave_y:
    "Otro tipo de permiso que puede combinarse con este tipo de permiso según la política de permisos de la organización (por ej., Permiso Médico/Enfermedad puede combinarse con permiso X y permiso Y.)",
  minimum_employment_tenure_needed: "Antigüedad Mínima Necesaria",
  minimum_number_of_days_an_employee_need_to_be_working_in_the_organization_in_order_to_apply_this_leave_type:
    "Número mínimo de días que un empleado necesita estar trabajando en la organización para solicitar este tipo de permiso",
  prior_leave_request: "Solicitud de Permiso Anticipada",
  prior_leave_request_is_to_be_made_by_the_employee_for_applying_for_this_leave_type:
    "La solicitud de permiso anticipada debe ser realizada por el empleado para solicitar este tipo de permiso. Por ej., los empleados deben solicitar un tipo de permiso particular al menos un día de anticipación; si el tipo de permiso no requiere notificación anticipada, deja el cuadro de texto abajo en blanco. El número mínimo de días para el aviso anticipado de permisos debe establecerse según la política de la organización. Solo se debe ingresar el número (valor numérico) para esta condición.",
  leave_balance_expire_days: "Días de Caducidad del Saldo de Permiso",
  if_any_leave_type_has_a_specific_expiry_time_line_organizations_may_add_the_same_in_the_Leave_expiry_field:
    "Si algún tipo de permiso tiene una línea de caducidad específica, las organizaciones pueden agregarlo en el campo de Caducidad del Permiso",
  leaves_before_event: "Permisos Antes del Evento",
  leave_during_pregnancy_and_after_child_birth:
    "Por ej., Permiso durante el Embarazo y Después del Nacimiento del Niño, el número total de permisos antes y después del evento (Embarazo y Después del Nacimiento) debe ser igual al número total de permisos permitidos para todo el evento",
  leaves_after_event: "Permisos Después del Evento",
  leave_during_pregnancy_and_after_child_birth_total:
    "Por ej., Permiso durante el Embarazo y Después del Nacimiento del Niño, el número total de permisos antes y después del evento (Embarazo y Después del Nacimiento) debe ser igual al número total de permisos permitidos para todo el evento",
  carry_forward: "Acumulación",
  yes: "Sí",
  no: "No",
  maximum_carry_forward: "Máximo Acumulado",
  applicable_for_leaves_that_can_be_carried_forward_to_the_next_calender_year:
    "Aplicable para permisos que pueden ser acumulados para el próximo año calendario",
  encashable: "Canjeable",
  maximum_encashment_allowed_during_f_f: "Máximo Canje Permitido durante F&F",
  maximum_number_of_days_allowed_for_encashment_during_f_f:
    "Número máximo de días permitidos para canje durante F&F. El máximo canje permitido durante F&F no puede ser mayor a 3 dígitos",
  maximum_number_of_days_allowed_for_encashment:
    "Número Máximo de Días Permitidos para Canje",
  maximum_number_of_days_allowed_for_encashment_cannot_more_the_three_digit_for_current_employee:
    "Número Máximo de Días Permitidos para Canje No Puede Ser Más de 3 Dígitos para el Empleado Actual",
  minimum_leave_balance_needed_after_encashment:
    "Saldo Mínimo de Permiso Necesario Después del Canje",
  minimum_leave_balance_needed_after_encashment_cannot_more_the_three_digit_for_current_employee:
    "Saldo Mínimo de Permiso Necesario Después del Canje No Puede Ser Más de 3 Dígitos para el Empleado Actual",
  add_employee: "Agregar Empleado",
  employee_demography: "Demografía del Empleado",
  gender: "Género",
  male: "Masculino",
  female: "Femenino",
  others: "Otros",
  official_phone_number: "Número de Teléfono Oficial",
  employement_demography: "Demografía del Empleo",
  employee_id: "ID del Empleado",
  employement_status: "Estado del Empleo",
  select_unit: "Seleccionar Unidad",
  employee_position: "Posición del Empleado",
  primary_position: "Posición Principal",
  select_position: "Seleccionar Posición",
  with_effect_from: "Con Efecto Desde",
  additional_position: "Posición Adicional",
  reporting_head: "Jefe de Reporte",
  shift: "Turno",
  salary_type: "Tipo de Salario",
  add_routine_work: "Agregar Trabajo de Rutina",
  exit_full_screen: "Salir del Modo Pantalla Completa",
  enter_full_screen: "Entrar en Modo Pantalla Completa",
  select_activity: "Seleccionar Actividad",
  shift_name: "Nombre del Turno",
  set_as_default_shift: "Establecer como Turno Predeterminado",
  start_time: "Hora de Inicio",
  end_time: "Hora de Fin",
  add_shift: "Agregar Turno",
  shift_list: "Lista de Turnos",
  default_shift: "Turno Predeterminado",
  marked_as_default_shift: "Marcado como Turno Predeterminado",
  do_you_want_to_mark_this_shift_as_the_default_shift:
    "¿Deseas marcar este turno como el turno predeterminado?",
  working_days: "Días Laborales",
  do_you_want_to_edit_this: "¿Deseas editar esto?",
  select_week: "Seleccionar Semana",
  update: "Actualizar",
  edit_external_user: "Editar Usuario Externo",
  edit_holiday: "Editar Festivo",
  edit_employee: "Editar Empleado",
  edit_leave_rules: "Editar Reglas de Permiso",
  are_you_sure_to_add_this_as_leave_name:
    "¿Estás seguro de agregar esto como nombre de permiso?",
  from_issued_loi: "De LOI Emitido",
  candidate_name: "Nombre del Candidato",
  candidate_demography: "Demografía del Candidato",
  email_id: "ID de Correo Electrónico",
  ex_employe_org_com: "ej: empleado@org.com",
  select_department: "Seleccionar Departamento",
  select_position: "Seleccionar Posición",
  select_location: "Seleccionar Ubicación",
  reporting_head: "Jefe de Reporte",
  select_reporting_head: "Seleccionar Jefe de Reporte",
  permanent_address: "Dirección Permanente",
  address_line_one: "Línea de Dirección 1",
  address_line_two: "Línea de Dirección 2",
  city: "Ciudad",
  ex_kolkata: "Ej: Kolkata",
  select_country: "Seleccionar País",
  select_state: "Seleccionar Estado",
  pin: "PIN",
  ex_pin: "Ej: 700001",
  correspondence_address: "Dirección de Correspondencia",
  same_as_permanent_address: "Igual que la Dirección Permanente",
  probation_confirmation_period: "Periodo de Prueba/Confirmación",
  probation_days: "Días de Prueba",
  address_change_notice: "Aviso de Cambio de Dirección (en días)",
  prior_leave_notice: "Aviso de Permiso Anticipado",
  termination_conditions: "Condiciones de Terminación",
  continuous_absent_days: "Días de Ausencia Continua",
  notice_period: "Periodo de Aviso (en días)",
  salary_in_lieu_of_notice: "Salario en Lugar de Aviso (en salario de meses)",
  enter_number_of_months: "Ingresar número de meses",
  define_salary_breakup: "Definir Desglose del Salario",
  employment_type: "Tipo de Empleo",
  on_payroll: "En Nómina",
  fixed_term_contracts: "Contratos de Término Fijo",
  gross_per_month: "Bruto por Mes (GPM)",
  gpm_ex: "GPM Ej: 20000",
  cost_to_company: "Costo para la Empresa (CTC)",
  ctc_ex: "CTC Ej: 300000",
  other_terms_and_conditions: "Otros Términos y Condiciones",
  ex_add_new_terms_and_conditions: "Ej: agregar nuevos Términos y Condiciones",
  terms_and_condition_list: "Lista de Términos y Condiciones",
  save_for_this_future_appointment_letter:
    "Guardar para esta futura Carta de Designación",
  loi_candidate_demography: "Demografía del Candidato LOI",
  loi_expiry_date: "Fecha de Expiración del LOI",
  define_salary_breakup: "Definir Desglose del Salario",
  document_required: "Documento Requerido",
  document_name: "Nombre del Documento",
  ex_relieving_certificate_from_the_previous_employer:
    "Ej: Certificado de Liberación del Empleador Anterior",
  document_list: "Lista de Documentos",
  save_for_this_future_loi: "Guardar para este futuro LOI",
  replace_with: "Reemplazar Con",
  item_name: "Nombre del Artículo",
  description: "Descripción",
  category: "Categoría",
  type: "Tipo",
  hsncd: "HSNCD",
  uom: "UOM",
  manufacturer: "Fabricante",
  expirable: "Expirable",
  status: "Estado",
  verified: "Verificado",
  replace: "Reemplazar",
  amount: "Cantidad",
  view_budget: "Ver Presupuesto",
  view_leave_rules_details: "Ver Detalles de las Reglas de Permiso",
  present: "Presente",
  absent: "Ausente",
  weekend: "Fin de Semana",
  holiday: "Festivo",
  leave: "Permiso",
  download: "Descargar",
  employee_info: "Información del Empleado",
  payable_days: "Días Pagables",
  no_attendance_data_found: "No se Encontraron Datos de Asistencia",
  update_attendance: "Actualizar Asistencia",
  mark_as: "Marcar Como",
  comments: "Comentarios",
  update: "Actualizar",
  search_budget: "Buscar Presupuesto",
  no_budget_define_yet: "¡No se ha Definido Ningún Presupuesto aún!",
  click_add_budget_button_to_create_new_budget:
    "Haga clic en el Botón Agregar Presupuesto para crear un nuevo Presupuesto",
  sl_no: "N.º de serie",
  budget_name: "Nombre del Presupuesto",
  budget_for: "Presupuesto Para",
  unit_name: "Nombre de la Unidad",
  start_date: "Fecha de Inicio",
  end_date: "Fecha de Fin",
  budget_amount: "Monto del Presupuesto",
  consolidated: "Consolidado",
  unit: "Unidad",
  N_A: "N/A",
  search_contract: "Buscar Contrato",
  no_contract_added_yet: "¡No se ha Agregado Ningún Contrato aún!",
  click_add_contract_button_to_create_new_contract:
    "Haga clic en el Botón Agregar Contrato para crear un nuevo Contrato",
  contract_name: "Nombre del Contrato",
  contract_type: "Tipo de Contrato",
  responsible_person: "Persona Responsable",
  contract_owner_name: "Nombre del Propietario del Contrato",
  action: "Acción",
  search_user: "Buscar Usuario",
  no_external_user_define_yet: "¡No se ha Definido Ningún Usuario Externo aún!",
  click_add_external_user_button_to_create_new_external_user:
    "Haga clic en el Botón Agregar Usuario Externo para crear un nuevo Usuario Externo",
  user_name: "Nombre de Usuario",
  type: "Tipo",
  email: "Correo Electrónico",
  phone: "Teléfono",
  address: "Dirección",
  fax: "FAX",
  gst: "GST",
  do_you_want_to_delete_this_external_user:
    "¿Desea eliminar este Usuario Externo?",
  search_holiday: "Buscar Festivo",
  no_holiday_define_yet: "¡No se ha Definido Ningún Festivo aún!",
  click_add_holiday_button_to_create_new_holiday:
    "Haga clic en el Botón Agregar Festivo para crear un nuevo Festivo",
  date: "Fecha",
  do_you_want_to_delete_this_holiday: "¿Desea eliminar este Festivo?",
  items_list: "Lista de Artículos",
  search_items: "Buscar Artículos",
  add_items: "Agregar Artículos",
  verify: "Verificar",
  replace: "Reemplazar",
  search_leave_rules: "Buscar Reglas de Permiso",
  leave_balance: "Saldo de Permiso",
  no_leave_rule_define_yet: "¡No se ha Definido Ninguna Regla de Permiso aún!",
  click_add_leave_rule_button_to_create_leave_rule:
    "Haga clic en el Botón Agregar Regla de Permiso para crear una Regla de Permiso",
  leave_type: "Tipo de Permiso",
  gender: "Género",
  employee_status: "Estado del Empleado",
  employment_type: "Tipo de Empleo",
  minimum_leaves: "Permisos Mínimos",
  min_employment_days: "Días Mínimos de Empleo",
  min_notice_days: "Días Mínimos de Aviso",
  do_you_want_to_delete_this_leave_rule:
    "¿Desea eliminar esta Regla de Permiso?",
  employee_payroll: "Nómina de Empleados",
  search_employee: "Buscar Empleado",
  no_employee_payroll_added_yet:
    "¡No se ha Agregado Ninguna Nómina de Empleados aún!",
  employee_name: "Nombre del Empleado",
  id: "ID",
  position: "Posición",
  salary_type: "Tipo de Salario",
  employement_status: "Estado del Empleo",
  ctc: "CTC",
  action: "Acción",
  salary_structure: "Estructura Salarial",
  pay_slip: "Recibo de Pago",
  employee_pay_slip: "Recibo de Pago del Empleado",
  name: "Nombre",
  salary_type: "Tipo de Salario",
  employement_status: "Estado del Empleo",
  current_ctc: "CTC Actual",
  past_salary: "Salario Anterior",
  select_month: "Seleccionar Mes",
  month: "Mes",
  net_pay: "Pago Neto",
  deduction: "Deducción",
  employee_salary_structure: "Estructura Salarial del Empleado",
  account_details: "Detalles de la Cuenta",
  account_no: "Número de Cuenta",
  confirm_account_no: "Confirmar Número de Cuenta",
  bank_name: "Nombre del Banco",
  ifsc_code: "Código IFSC",
  beneficiary_name: "Nombre del Beneficiario",
  salary_structure: "Estructura Salarial",
  give_a_reasons_for_edit: "Proporcione una razón para editar",
  effective_date: "Fecha Efectiva",
  select_to_add_new_head: "Seleccione para agregar un nuevo concepto",
  annual_ctc: "CTC Anual",
  amount: "Cantidad",
  salary_head: "Concepto Salarial",
  monthly: "Mensual",
  annually: "Anualmente",
  configuration_head: "Concepto de Configuración",
  total: "Total",
  salary_head_configuration: "Configuración del Concepto Salarial",
  employee: "Empleado",
  search_user: "Buscar Usuario",
  no_employee_define_yet: "¡Aún no se ha definido ningún empleado!",
  click_add_employee_button_to_create_new_employee:
    "Haga clic en el botón Agregar Empleado para crear un nuevo empleado",
  employee_name: "Nombre del Empleado",
  joining_date: "Fecha de Incorporación",
  status: "Estado",
  salary_type: "Tipo de Salario",
  email_id: "ID de Correo Electrónico",
  function_department: "Función/Departamento",
  primary_position: "Posición Principal",
  reporting_head: "Jefe de Reportes",
  do_you_want_to_deactivate_this_employee: "¿Desea desactivar a este empleado?",
  please_select_a_date_to_confirm_deactivation_of_this_employee:
    "Por favor, seleccione una fecha para confirmar la desactivación de este empleado",
  routine_work: "Trabajo Rutina",
  search_activity: "Buscar Actividad",
  activity_name: "Nombre de la Actividad",
  date_time: "Fecha y Hora",
  attendance_report: "Informe de Asistencia",
  username_gose_here: "nombre de usuario aquí",
  select_month: "Seleccionar Mes",
  download: "Descargar",
  date: "Fecha",
  check_in: "Entrada",
  check_out: "Salida",
  hours: "Horas",
  status: "Estado",
  weekend_present: "Fin de Semana (Presente)",
  weekend_absent: "Fin de Semana (Ausente)",
  sending_positive_vibes_for_the_weekend_to_you_enjoy_your_well_deserved_break:
    "Enviando buenas vibras para el fin de semana, disfruta de tu merecido descanso.",
  weekend: "Fin de Semana",
  happy_holidays: "Felices Fiestas",
  leave: "Permiso",
  leave_balance: "Saldo de Permiso",
  search_leave_rules: "Buscar Reglas de Permiso",
  download: "Descargar",
  upload: "Subir",
  employee_name: "Nombre del Empleado",
  employee_id: "ID del Empleado",
  privilege_leave: "Permiso de Privilegio (PL)",
  casual_leave: "Permiso Casual (CL)",
  sick_leave: "Permiso por Enfermedad (SL)",
  maternity_leave: "Permiso de Maternidad (ML)",
  Comp_off: "Compensación",
  marriage_leave: "Permiso de Matrimonio",
  paternity_leave: "Permiso de Paternidad",
  bereavement_leave: "Permiso por Luto",
  loss_of_pay: "Pérdida de Pago (LOP)",
  uploaded_file_data: "Datos del Archivo Subido",
  close: "Cerrar",
  edit_leave_balance: "Editar Saldo de Permiso",
  leave_type: "Tipo de Permiso",
  leave_balance: "Saldo de Permiso",
  please_enter_budget_name: "Por favor, ingrese el nombre del presupuesto",
  please_enter_valid_amounts_for_budget_heads:
    "Por favor, ingrese cantidades válidas para los conceptos del presupuesto.",
  file_size_cannot_exceed_five_mb:
    "El tamaño del archivo no puede exceder los 5 MB",
  please_enter_contract_name: "Por favor, ingrese el nombre del contrato",
  please_enter_responsible_person: "Por favor, ingrese la persona responsable",
  please_enter_contract_type: "Por favor, ingrese el tipo de contrato",
  please_enter_contract_owner: "Por favor, ingrese el propietario del contrato",
  please_select_user_type: "Por favor, seleccione el tipo de usuario",
  please_select_title: "Por favor, seleccione el título",
  please_enter_first_name: "Por favor, ingrese el primer nombre",
  please_enter_last_name: "Por favor, ingrese el apellido",
  please_enter_email_id: "Por favor, ingrese el ID de correo electrónico",
  please_check_the_email_id: "Por favor, verifique el ID de correo electrónico",
  please_select_country_code: "Por favor, seleccione el código de país",
  please_enter_contact_number: "Por favor, ingrese el número de contacto",
  please_enter_address: "Por favor, ingrese la dirección",
  please_enter_valid_phone_number_for_country_code:
    "Por favor, ingrese un número de teléfono válido para el código de país",
  please_enter_holiday_name: "Por favor, ingrese el nombre del festivo",
  please_enter_item_type: "Por favor, ingrese el tipo de artículo",
  please_provide_item_category:
    "Por favor, proporcione la categoría del artículo",
  please_enter_category_name: "Por favor, ingrese el nombre de la categoría",
  please_enter_item_uom: "Por favor, ingrese la UOM del artículo",
  please_enter_item_manufacturer:
    "Por favor, ingrese el fabricante del artículo",
  please_provide_item_name: "Por favor, proporcione el nombre del artículo",
  please_provide_item_description:
    "Por favor, proporcione la descripción del artículo",
  please_provide_category: "Por favor, proporcione la categoría",
  please_provide_item_type: "Por favor, proporcione el tipo de artículo",
  please_provide_uom: "Por favor, proporcione la UOM",
  please_provide_item_manufacturer:
    "Por favor, proporcione el fabricante del artículo",
  please_select_or_enter_a_leave_type:
    "Por favor, seleccione o ingrese un tipo de permiso",
  please_select_at_least_one_gender: "Por favor, seleccione al menos un género",
  please_select_at_least_one_employee_status:
    "Por favor, seleccione al menos un estado del empleado",
  please_select_at_least_one_employment_type:
    "Por favor, seleccione al menos un tipo de empleo",
  maximum_encashment_allowed_during_fandf_cannot_be_more_than_three_digit:
    "El máximo de canje permitido durante el F&F no puede ser más de 3 dígitos",
  maximum_number_of_days_allowed_for_encashment_cannot_more_the_three_digit:
    "El número máximo de días permitidos para el canje no puede ser más de 3 dígitos",
  minimum_leave_balance_needed_after_encashment_cannot_more_the_three_digit:
    "El saldo mínimo de permiso necesario después del canje no puede ser más de 3 dígitos",
  please_enter_first_name: "Por favor, ingrese el primer nombre",
  please_enter_last_name: "Por favor, ingrese el apellido",
  please_enter_email: "Por favor, ingrese el correo electrónico",
  please_enter_correct_email:
    "El correo electrónico que ha ingresado no es válido, por favor ingrese un correo electrónico correcto",
  please_select_country_code: "Por favor, seleccione el código de país",
  please_enter_phone_number: "Por favor, ingrese el número de teléfono",
  please_enter_a_valid_phone_number:
    "Por favor, ingrese un número de teléfono válido",
  please_enter_salary_type: "Por favor, ingrese el tipo de salario",
  please_fill_employment_id: "Por favor, complete el ID de empleo",
  please_select_unit: "Por favor, seleccione la unidad",
  please_select_position: "Por favor, seleccione la posición",
  employee_added_successfully: "Empleado agregado exitosamente",
  please_enter_address_one: "Por favor, ingrese la dirección 1",
  please_enter_address_two: "Por favor, ingrese la dirección 2",
  please_enter_city: "Por favor, ingrese la ciudad",
  please_enter_state: "Por favor, ingrese el estado",
  please_enter_country: "Por favor, ingrese el país",
  please_enter_pin: "Por favor, ingrese el PIN",
  please_enter_department: "Por favor, ingrese el departamento",
  please_enter_position: "Por favor, ingrese la posición",
  please_enter_email: "Por favor, ingrese el correo electrónico",
  please_enter_valid_emailid:
    "Por favor, ingrese un ID de correo electrónico válido",
  please_enter_gross_per_month: "Por favor, ingrese el bruto por mes",
  please_enter_ctc: "Por favor, ingrese el CTC",
  please_provide_salary_informations:
    "Por favor, proporcione la información salarial",
  please_select_location: "Por favor, seleccione la ubicación",
  please_select_reporting_head: "Por favor, seleccione el jefe de reportes",
  please_select_probation_time: "Por favor, seleccione el tiempo de prueba",
  please_select_address_change_notice_period:
    "Por favor, seleccione el período de notificación para el cambio de dirección",
  please_select_leave_notice_period:
    "Por favor, seleccione el período de notificación de permiso",
  please_properly_fill_the_corresponding_address:
    "Por favor, complete adecuadamente la dirección correspondiente",
  please_fill_corresponding_address_one:
    "Por favor, complete la dirección correspondiente 1",
  please_fill_the_corresponding_city:
    "Por favor, complete la ciudad correspondiente",
  please_fill_the_corresponding_country:
    "Por favor, complete el país correspondiente",
  please_fill_the_corresponding_state:
    "Por favor, complete el estado correspondiente",
  please_provide_salary_informations:
    "Por favor, proporcione la información salarial",
  please_provide_absent_days: "Por favor, proporcione los días de ausencia",
  please_provide_notice_period:
    "Por favor, proporcione el período de notificación",
  please_enter_shift_name: "Por favor, ingrese el nombre del turno",
  shift_timing_added_successFully: "Horario del turno agregado exitosamente",
  something_went_wrong: "Algo salió mal",
  shift_timing_deleted_successFully: "Horario del turno eliminado exitosamente",
  shift_marked_as_default_successfully:
    "Turno marcado como predeterminado exitosamente",
  all_updates_have_been_successfully_applied:
    "Todas las actualizaciones se han aplicado exitosamente.",
  please_select_salary_head: "Por favor, seleccione el concepto salarial",
  please_select_percent: "Por favor, seleccione el porcentaje",
  please_select_gross_or_basic: "Por favor, seleccione bruto o básico",
  please_select_amount: "Por favor, seleccione la cantidad",
  please_select_a_status: "Por favor, seleccione un estado",
  please_give_some_comments: "Por favor, dé algunos comentarios",
  attendance_data_is_empty_unable_to_export:
    "Los datos de asistencia están vacíos, no se puede exportar",
  please_select_new_head: "Por favor seleccione Nueva Cabeza",
  salary_head_already_exists: "El encabezado de salario ya existe",
  please_enter_account_number: "Por favor ingrese el Número de Cuenta",
  account_number_and_confirm_account_number_should_be_the_same:
    "El Número de Cuenta y Confirmar Número de Cuenta deben ser iguales",
  please_enter_the_bank_name: "Por favor ingrese el nombre del banco",
  please_enter_the_ifsc_code: "Por favor ingrese el código IFSC",
  please_wait: "Por favor espere ....",
  employee_bank_details_updated_successfully:
    "Detalles bancarios del empleado actualizados con éxito",
  failed_to_update_employee_bank_details:
    "Error al actualizar los detalles bancarios del empleado",
  an_error_occurred_while_updating_employee_bank_details:
    "Ocurrió un error al actualizar los detalles bancarios del empleado",
  please_enter_the_reason_for_edit: "Por favor ingrese el motivo de la edición",

  ////////////////////////////////////////////////

  select_health_care_profession: "Seleccionar Profesión de Atención Médica",
  select_menu: "--Seleccionar Menú--",
  doctor: "Doctor",
  nurse: "Enfermero/a",
  lab_technician: "Técnico de Laboratorio",
  activate: "Activar",
  health_care_profession: "Profesión de Atención Médica",
  remove_health_care_professional: "Eliminar Profesional de Atención Médica",
  qualification: "Calificación",
  enter_qualification: "Ingresar Calificación",
  specialization: "Especialización",
  practice_start_date: "Fecha de Inicio de Práctica",
  year_of_experience: "Años de Experiencia",
  enter_year_of_experience: "Ingresar Años de Experiencia",
  languages: "Idiomas",
  currency: "Moneda",
  fees: "Tarifas",
  enter_fees: "Ingresar Tarifas",
  select_doctor_available_units:
    "Seleccionar Unidades Disponibles para el Doctor",
  doctor_available_in_units: "Doctor Disponible en Unidades",
  license_issuing_authority: "Autoridad Emisora de Licencia",
  enter_license_issuing_authority: "Ingresar Autoridad Emisora de Licencia",
  medical_license_number: "Número de Licencia Médica",
  enter_medical_license_number: "Ingresar Número de Licencia Médica",
  attach_file: "Adjuntar Archivo",
  file_added: "Archivo Agregado",
  no_sign_avalable: "¡No hay firma disponible!",
  click_here_to_upload_sign: "Haga clic aquí para subir la firma",
  signature: "Firma",
  re_upload_sign: "Volver a Subir Firma",
  select_languages: "Seleccionar Idiomas",
  you_cannot_remove_your_unit: "No puedes eliminar tu unidad",
  invalid_file_format_allowed_formats_png_jpg_jpeg:
    "Formato de archivo no válido. Formatos permitidos: PNG, JPG, JPEG",
  file_size_must_be_between_ten_kb_and_two_hundred_kb:
    "El tamaño del archivo debe estar entre 10KB y 200KB",
  image_height_details: "La altura de la imagen debe estar entre 50px y 512px",
  an_error_occurred_during_file_upload_Please_try_again_later:
    "Ocurrió un error durante la carga del archivo. Por favor, inténtelo más tarde",
  please_enter_qualification: "Por favor, ingrese la calificación",
  please_select_specialization: "Por favor, seleccione la especialización",
  please_select_languages: "Por favor, seleccione los idiomas",
  you_cannot_add_more_then_three_languages:
    "No puedes agregar más de 3 idiomas",
  please_select_currency: "Por favor, seleccione la moneda",
  please_enter_fees: "Por favor, ingrese las tarifas",
  please_enter_license_issuing_authority:
    "Por favor, ingrese la autoridad emisora de la licencia",
  please_enter_medical_license_number:
    "Por favor, ingrese el número de licencia médica",
  please_upload_a_medical_license_attachment:
    "Por favor, suba un archivo adjunto de la licencia médica",
  please_upload_doctor_signature: "Por favor, suba la firma del doctor",
  you_cannot_select_more_than_three_languages:
    "No puedes seleccionar más de 3 idiomas",
  an_error_occurred_while_deactivating_the_account:
    "Ocurrió un error al desactivar la cuenta",
  an_error_occurred_while_activating_the_account:
    "Ocurrió un error al activar la cuenta",
  details_of_file_to_upload_in_sign:
    "Archivo permitido en formato PNG, JPG o JPEG y rango de tamaño de 2 KB a 200 KB. Dimensión de la imagen, altura de 50 px a 512 px, con fondo transparente o blanco",
  dr: "Dr.",
  Prof: "Prof.",
  employee_type: "Tipo de empleado",

  //////////////////////////////////////////

  ////////////////////////////////////patient in dashboard  //////////////

  appointment_settings: "Configuración de Citas",
  select_time_zone: "Seleccionar Zona Horaria",
  booking_window_in_days: "Ventana de Reserva en Días",
  seven_days: "7 Días",
  fifteen_days: "15 Días",
  thirty_days: "30 Días",
  forty_five_days: "45 Días",
  sixty_days: "60 Días - (2 Meses)",
  seventy_five_days: "75 Días",
  ninety_days: "90 Días - (3 Meses)",
  one_hundred_eighty_days: "180 Días - (6 Meses)",
  set_slots_for_book_appointment: "Establecer horarios para reservar cita",
  start_time: "Hora de Inicio",
  end_time: "Hora de Fin",
  slot_duration_in_minutes: "Duración del Horario en minutos",
  select_week: "Seleccionar Semana",
  unavailable: "No Disponible",
  book_doctor_appointment: "Reservar cita con el doctor",
  search_by_dr: "Buscar por Dr",
  search_by_dr_speciality: "Buscar por Especialidad del Dr",
  no_booked_doctor_slots_found:
    "No se encontraron horarios de doctor reservados",
  more_slots: "Más Horarios",
  select_doctor: "Seleccionar Doctor",
  slots_date_from: "Fecha de horarios desde",
  available: "Disponible",
  slots: "horarios",

  ////////////////////////////////////patient queue////////////////////////
  start_consultation: "Iniciar Consulta",
  complaints: "Quejas",
  refer_by: "Referido Por",
  delegate: "Delegar",
  concern: "Preocupación",
  link_task: "Vincular Tarea",
  meeting: "Reunión",
  user: "Usuario",
  close: "Cerrar",
  type: "Tipo",
  vitals: "Signos Vitales",
  patient_audio_message: "Mensaje de Audio del Paciente",
  past_diseases: "Enfermedades Pasadas",
  date: "Fecha",
  status: "Estado",
  investigation: "Investigación",
  test_name: "Nombre de la Prueba",
  value: "Valor",
  unit: "Unidad",
  bio_ref_interval: "Intervalo de Referencia Biológica",
  audio_message: "Mensaje de Audio",
  files: "Archivos",

  insurance: "Seguro",
  bmi: "IMC",
  patient_complaints: "Quejas del paciente",
  past_medication_file_pdf: "Archivo de medicación anterior.pdf",
  audio_message: "Mensaje de audio",
  do_you_want_to_continue: "¿Desea continuar?",
  save: "Guardar",
  edit: "Editar",
  symptoms: "Síntomas",
  symptom_name: "Nombre del síntoma",
  severity: "Severidad",
  since: "Desde",
  examination_and_sign: "Examen y signo",
  note: "Nota",
  enter_sign: "Ingrese signo",
  notes: "Notas",
  enter_notes: "Ingrese notas",
  comorbidity: "Comorbilidad",
  enter_diseases: "Ingrese enfermedades",
  history_risk_factor: "Historia y factor de riesgo",
  search_histor_and_risk_factor: "Buscar historia y factor de riesgo",
  past_illness: "Enfermedad anterior",
  reported_date: "Fecha reportada",
  add_past_illness: "Agregar enfermedad anterior",
  select_date: "Seleccionar fecha",
  previous_procedure: "Procedimiento anterior",
  add_previous_procedure: "Agregar procedimiento anterior",
  surgery_date: "Fecha de cirugía",
  patient_is_allergic_to_the_following_medication:
    "El paciente es alérgico a la siguiente medicación",
  generic: "Genérico",
  brand: "Marca",
  search_medicine_brand_generic: "Buscar medicina Marca / Genérico",
  search_by_brand: "Buscar por marca",
  food_allergy: "Alergia alimentaria",
  food_name: "Nombre del alimento",
  relevant_family_history: "Historia familiar relevante",
  relationship: "Relación",
  family_disease_history: "Historia de enfermedades familiares",
  possible_conditions: "Posibles condiciones",
  these_features_are_experimental_it_would_be_best_if_you_reviewed_and_recommended_any_necessary_changes_the_data_has_been_generated_using_ai:
    "Esta es una función de IA experimental que no debe usarse para diagnóstico médico. Sería mejor si revisara y recomendara cualquier cambio necesario. Los datos han sido generados usando IA",
  run_differential_diagnosis: "Realizar diagnóstico diferencial",
  protocol: "Protocolo",
  search_diseases_name: "Buscar nombre de enfermedades",
  possible_investigations: "Posibles investigaciones",
  go_for_test: "Ir a prueba",
  search_investigations: "Buscar investigaciones",
  test_result: "Resultado de la prueba",
  diagnosis: "Diagnóstico",
  provisional: "Provisional",
  confirm: "Confirmar",
  prognosis: "Pronóstico",
  disease_name: "Nombre de la enfermedad",
  medication: "Medicación",
  generic_name: "Nombre genérico",
  dosage: "Dosis",
  rout: "Ruta",
  form: "Forma",
  frequency: "Frecuencia",
  duration: "Duración",
  timing: "Tiempo",
  time: "Hora",
  drug_safety_check: "VERIFICACIÓN DE SEGURIDAD DE MEDICAMENTOS",
  edit_medication: "Editar medicación",
  recommendations: "Recomendaciones",
  diet_recommendations: "Recomendaciones dietéticas",
  diet_name: "Nombre de la dieta",
  lifestyle_recommendations: "Recomendaciones de estilo de vida",
  life_style_name: "Nombre del estilo de vida",
  others_options: "Otras opciones",
  parameter_to_be_monitor: "Parámetro a monitorear",
  select_parameters: "Seleccionar parámetros",
  doctor_note: "Nota del doctor",
  procedure_surgery: "Procedimiento/Cirugía",
  add_previous_procedure: "Agregar procedimiento anterior",
  refer: "Referir",
  refer_type: "Tipo de referencia",
  select_refer_type: "Seleccionar tipo de referencia",
  advise_higher_facility: "Aconsejar instalación de mayor nivel",
  advise_immediate_hospitalization: "Aconsejar hospitalización inmediata",
  refer_to_other_speciality: "Referir a otra especialidad",
  refer_to_medico_legal: "Referir a médico legal",
  enter_remarks_here: "Ingrese observaciones aquí",
  treatment_plan: "Plan de tratamiento",
  select_speciality: "Seleccionar especialidad",
  acute_care: "Cuidados agudos",
  anaesthesia: "Anestesia",
  general_surgeon: "Cirujano general",
  cvts: "CVTS",
  cardiologist: "Cardiólogo",
  cardiologist_cvs_cardiac_surgeon: "Cardiólogo/CVS/Cirujano cardíaco",
  chief_cardio_vascular_thoracic_surgeon:
    "Jefe de cirugía cardiovascular y torácica",
  clinical_cardiologist: "Cardiólogo clínico",
  clinical_psychologist: "Psicólogo clínico",
  dermatologist_hair_transplant_surgeon:
    "Dermatólogo y cirujano de trasplante capilar",
  doctor_name_optional: "Nombre del doctor (Opcional)",
  enter_doctor_name: "Ingrese el nombre del doctor",
  review: "Revisión",
  next_review: "Próxima revisión",
  review_date: "Fecha de revisión",
  prescription: "Receta",
  symptoms_cannot_be_empty: "Los síntomas no pueden estar vacíos",
  please_select_doctor_speciality:
    "Por favor seleccione la especialidad del doctor",
  both_sistolic_and_diastolic_value_must_be_present:
    "Deben estar presentes tanto el valor sistólico como el diastólico",
  sistolic_pressure_cannot_be_empty:
    "La presión sistólica no puede estar vacía",
  diastolic_pressure_cannot_be_empty:
    "La presión diastólica no puede estar vacía",
  sistolic_pressure_must_be_greater_than_diastolic:
    "La presión sistólica debe ser mayor que la diastólica",
  sistolic_and_diastolic_pressure_difference_must_be_greater_than_thirty:
    "La diferencia entre la presión sistólica y diastólica debe ser mayor de 30",
  temparature_must_be_within_thirty_two_and_forty_one:
    "La temperatura debe estar entre 32.22 y 41.10",
  temparature_must_be_within_ninety_to_one_hundred_six:
    "La temperatura debe estar entre 90 y 106",
  please_provide_disease_name:
    "Por favor proporcione el nombre de la enfermedad",
  this_brand_generic_name_is_not_present_in_the_table:
    "El nombre genérico de esta marca no está presente en la tabla",
  please_provide_drug_name: "Por favor proporcione el nombre del medicamento",
  please_provide_family_disease_name:
    "Por favor proporcione el nombre de la enfermedad familiar",
  please_select_relationship: "Por favor seleccione la relación",
  please_provide_food_name: "Por favor proporcione el nombre del alimento",
  please_provide_risk_factor: "Por favor proporcione el factor de riesgo",
  please_provide_illness_name:
    "Por favor proporcione el nombre de la enfermedad",
  please_provide_procedure_name:
    "Por favor proporcione el nombre del procedimiento",
  please_provide_investigation_name:
    "Por favor proporcione el nombre de la investigación",
  please_select_medicine_name: "Por favor seleccione el nombre del medicamento",
  please_select_dosage: "Por favor seleccione la dosis",
  please_select_frequency: "Por favor seleccione la frecuencia",
  please_select_duration: "Por favor seleccione la duración",
  could_not_found_diet_list_fetching_it:
    "No se pudo encontrar la lista de dietas. Obteniendo la misma.",
  diet_recommendation_cannot_be_empty:
    "La recomendación dietética no puede estar vacía",
  please_provide_lifestyle: "Por favor proporcione el estilo de vida",
  please_provide_sign_name: "Por favor proporcione el nombre del signo",
  please_provide_user_note: "Por favor proporcione la nota del usuario",
  please_select_symptom: "Por favor seleccione el síntoma",
  please_select_severity: "Por favor seleccione la severidad",

  technician: "Técnico",
  contact: "Contacto",
  location: "Ubicación",
  consultation_with: "Consulta con",
  prescription_type: "Tipo de prescripción",
  confirm_prescription: "Confirmar prescripción",
  provisional_prescription: "Prescripción provisional",
  for: "Para",
  risk_factor: "Factor de riesgo",
  possible_diagnosis: "Posible diagnóstico",
  investigations: "Investigaciones",
  diagnosis_condition: "Diagnóstico/Condición",
  procedure: "Procedimiento",
  advice: "Consejo",
  quantity_dose: "Cantidad/Dosis",
  frequency_timing: "Frecuencia/Horario",
  duration: "Duración",
  refered_by: "Referido por",

  //////////////////////////////// chat ///////
  select_user_to_add_group:
    "Seleccione un usuario para agregar al grupo (Mínimo {{minUsers}})",
  search_name_to_add_contact: "Buscar nombre para agregar contacto",
  add_user: "Agregar usuario",
  group_info: "Información del grupo",
  change_group_icon: "Cambiar ícono del grupo",
  group_name: "Nombre del grupo",
  group_members: "Miembros del grupo",
  you: "Tú",
  remove: "Eliminar",
  make_group_admin: "Hacer administrador del grupo",
  dismiss_as_admin: "Destituir como administrador",
  make_admin: "Hacer administrador",
  profile_photo: "Foto de perfil",
  add_group_icon: "Agregar ícono del grupo",
  forward_chat: "Reenviar chat",
  search_or_find: "Buscar o encontrar",
  create_group: "Crear grupo",
  filtered_by_unread: "Filtrado por no leídos",
  no_unread_chat_available: "No hay chats no leídos disponibles",
  clear_filter: "Borrar filtro",
  contacts: "Contactos",
  forwarded: "Reenviado",
  unread: "No leído",
  reply: "Responder",
  forward: "Reenviar",
  copy: "Copiar",
  delete: "Eliminar",
  download: "Descargar",
  insert_emoji: "Insertar emoji",
  allowed_file_types: "Tipos de archivo permitidos",
  maximum_file_size: "Tamaño máximo de archivo {{maxFileSize}} MB",
  type_your_message_here: "Escribe tu mensaje aquí",
  click_to_stop: "Haz clic para detener",
  click_to_start: "Haz clic para empezar",
  selected_message_count: "{{count}} seleccionado(s)",
  no_preview_available: "No hay vista previa disponible",
  drop_files_here: "Suelta los archivos aquí",
  new_chat: "Nuevo chat",
  search_name_or_email: "Buscar nombre o correo electrónico",
  create_new_group: "Crear nuevo grupo",
  contacts_on: "Contactos en",
  something_went_wrong_please_try_again: "Algo salió mal. ¡Inténtalo de nuevo!",
  make_admin_successfully: "Administrador asignado con éxito",
  admin_successfully_updated: "Administrador actualizado con éxito",
  could_not_dismiss_admin_user: "No se pudo destituir al usuario administrador",
  user_already_selected: "{{userName}} ya está seleccionado",
  something_went_wrong: "¡Algo salió mal!",
  file_size_error: "Sube un archivo de menos de {{maxFileSize}}MB",
  message_forwarded_successfully: "Mensaje reenviado con éxito",
  please_select_user: "Por favor seleccione un usuario",
  you_have_been_removed_from_this_group: "Has sido eliminado de este grupo",
  maximum_ten_files_can_be_uploaded_at_a_time:
    "Se pueden subir un máximo de 10 archivos a la vez",
  invalid_file_type:
    "Tipo de archivo no válido. Los tipos de archivo válidos son {{validFileTypes}}",
  please_type_message: "Por favor escriba un mensaje",
  no_voice_recorded: "No se ha grabado ninguna voz",
  group_created_successfully: "Grupo creado con éxito",
  failed_to_create_group: "No se pudo crear el grupo",

  //////////////////////// Admin //////////////

  add_salary_head_group: "Agregar Cabezal/Grupo Salarial",
  select_orders: "Seleccionar Órdenes",
  select_salary_group: "Seleccionar Grupo Salarial",
  calculation_basis: "Base de Cálculo",
  enter_percentage: "Ingresar Porcentaje (%)",
  add_access_permission: "Agregar Permiso de Acceso",
  select_user: "Seleccionar Usuario",
  select_all: "Seleccionar Todo",
  enable: "Habilitar",
  disable: "Deshabilitar",
  add_activity_template: "Agregar Plantilla de Actividad",
  upload_files: "Subir Archivos",
  file_restrictions:
    "Tamaño Máximo de Archivo Permitido: {{maxFileSize}}MB, Extensiones Permitidas: {{validFileTypes}}",
  drag_and_drop_files_here: "Arrastra y suelta archivos aquí",
  select_files: "Seleccionar archivos",
  template_added: "Plantilla Agregada",
  edit_committee: "Editar Comité",
  add_committee: "Agregar Comité",
  committee_name: "Nombre del Comité",
  add_roles: "Agregar Roles",
  roles_added: "Roles Agregados",
  generate_discount_coupon: "Generar Cupón de Descuento",
  purpose: "Propósito",
  discount_precentage: "Porcentaje de Descuento",
  discount_percent: "Descuento (1-100 %)",
  no_of_use: "Número de Usos",
  no_of_use_times: "Número de Usos (1-1000)",
  expires_on: "Expira el",
  receiver_name: "Nombre del Receptor",
  receiver_email_id: "Correo Electrónico del Receptor",
  custom_code: "Código Personalizado",
  edit_department: "Editar Departamento",
  add_department: "Agregar Departamento",
  department_name: "Nombre del Departamento",
  department_description: "Descripción del Departamento",
  edit_designation_hierarchy: "Editar Designación/Jerarquía",
  add_designation_hierarchy: "Agregar Designación/Jerarquía",
  hierarchy_name: "Nombre de la Jerarquía",
  designation_name: "Nombre de la Designación",
  band_label: "Banda/Etiqueta",
  edit_expenditure_power: "Editar Poder de Gasto",
  add_expenditure_power: "Agregar Poder de Gasto",
  expense_type: "Tipo de Gasto",
  min_amount: "Monto Mínimo",
  max_amount: "Monto Máximo",
  edit_critical_rights: "Editar Derechos Críticos",
  add_critical_rights: "Agregar Derechos Críticos",
  select_rights: "Seleccionar Derechos",
  add_policy: "Agregar Política",
  enter_policy_name: "Ingresar Nombre de la Política",
  is_needed_while_onboarding: "Es necesario durante la incorporación",
  upload_file: "Subir Archivo",
  policies_added: "Políticas Agregadas",
  add_salary_component: "Agregar Componente Salarial",
  component_name: "Nombre del Componente",
  component_category: "Categoría del Componente",
  add_new_component_category: "Agregar Nueva Categoría de Componente",
  periodicity: "Periodicidad",
  calculation: "Cálculo",
  flat_amount: "Monto Fijo",
  percentage_of_ctc: "Porcentaje del CTC",
  percentage_of_basic: "Porcentaje del Básico",
  enter_amount: "Ingresar Monto",
  configuration: "Configuración",
  update_component_category: "Actualizar Categoría del Componente",
  add_component_category: "Agregar Categoría del Componente",
  enter_category_name: "Ingresar Nombre de la Categoría",
  categories: "Categorías",
  no_categories_found: "No se encontraron categorías",
  add_organization_storage: "Agregar Almacenamiento de la Organización",
  current: "Actual",
  storage: "Almacenamiento",
  upgraded: "Actualizado",
  to: "A",
  drive: "Unidad",
  add_more_storage: "Agregar Más Almacenamiento",
  item_name: "Nombre del Ítem",
  unit_price: "Precio Unitario ($)",
  amount: "Monto",
  new_storage_added: "Nuevo Almacenamiento Agregado",
  total: "Total",
  upgrade_now: "Actualizar Ahora",
  add_statutory_and_insurance: "Agregar Estatutario y Seguro",
  select_name: "Seleccionar Nombre",
  select_category: "Seleccionar Categoría",
  due_renewal_date_frequency: "Frecuencia de Fecha de Vencimiento/Renovación",
  quarterly: "Trimestral",
  yearly: "Anual",
  no_specific_date: "Sin Fecha Específica",
  execute_on_last_date_of_month: "Ejecutar en la Última Fecha del Mes",
  first_quarter: "1er Trimestre",
  second_quarter: "2do Trimestre",
  third_quarter: "3er Trimestre",
  fourth_quarter: "4to Trimestre",
  select_date: "Seleccionar Fecha",
  notify_to_positions: "Notificar a Posiciones",
  notify_lead_time: "Tiempo de Anticipación para Notificar",
  days: "Días",
  upload_document_if_any: "Subir Documento si lo hay",
  allowed_file_types: "Tipos de Archivos Permitidos",
  add_terms_and_conditions: "Agregar Términos y Condiciones",
  t_and_c_for: "T&C PARA",
  t_and_c_type: "TIPO DE T&C",
  t_and_c_heading: "Encabezado de T&C",
  terms_and_condition: "Términos y Condiciones",
  serial_number: "Número de Serie",
  add_units: "Agregar Unidades",
  unit_name: "Nombre de la Unidad",
  gps_location: "Ubicación GPS de la Unidad",
  latitude: "Latitud",
  longitude: "Longitud",
  fetch_gps_longitude_and_latitude: "Obtener Longitud y Latitud GPS",
  unit_location: "Ubicación de la Unidad",
  house_no_building_name: "Número de Casa, Nombre del Edificio",
  area_colony: "Área, Colonia",
  land_mark: "Punto de Referencia",
  city: "Ciudad",
  select_country: "Seleccionar País",
  select_state: "Seleccionar Estado",
  pin: "PIN",
  cost_center: "Centro de Costo",
  profit_center: "Centro de Beneficio",
  maintains_own_balance_Sheet: "Mantiene su Propio Balance",
  statutory_compliances: "Cumplimientos Estatutarios",
  local_tax_applicable: "Impuesto Local Aplicable",
  default_currency: "Moneda Predeterminada",
  select_parent_unit: "Seleccionar Unidad Principal (si la hay)",
  contact_details_optional: "Detalles de Contacto (Opcional)",
  phone_no: "Número de Teléfono",
  access_permission: "Permiso de Acceso",
  no_access_permission_added_yet:
    "¡Aún no se ha agregado ningún Permiso de Acceso!",
  click_add_access_permission_button_to_create_new_access_permission:
    "Haga clic en el Botón de Agregar Permiso de Acceso para crear un nuevo Permiso de Acceso",
  no_activity_added_yet: "¡Aún no se ha agregado ninguna Actividad!",
  click_add_button_to_create_new_activity:
    "Haga clic en el Botón de Agregar para crear una Nueva Actividad",
  activity_added: "Actividad Agregada",
  activity_template: "Plantilla de Actividad",
  template: "Plantilla",
  no_template_define_yet: "¡Aún no se ha definido ninguna Plantilla!",
  click_add_template_button_to_create_new_template:
    "Haga clic en el Botón de Agregar Plantilla para crear una Nueva Plantilla",
  template_list: "Lista de Plantillas",
  do_you_want_to_delete_this_activity: "¿Desea eliminar esta Actividad?",
  search_committee: "Buscar Comité",
  committee: "Comité",
  no_committee_added_yet: "¡Aún no se ha agregado ningún Comité!",
  click_add_committee_button_to_create_new_committee:
    "Haga clic en el Botón de Agregar Comité para crear un nuevo Comité",
  committee_roles: "Roles del Comité",
  do_you_want_to_delete_this_committee: "¿Desea eliminar este Comité?",
  search_coupon: "Buscar Cupón",
  add_coupon: "Agregar Cupón",
  no_coupon_added_yet: "¡Aún no se ha agregado ningún Cupón!",
  click_add_coupon_button_to_create_new_coupon:
    "Haga clic en el Botón de Agregar Cupón para crear un nuevo Cupón",
  discount: "Descuento",
  exp_date: "Fecha de Exp.",
  name: "Nombre",
  assigned_to: "Asignado a",
  code: "Código",
  status: "Estado",
  time: "Hora",
  active: "Activo",
  deactive: "Inactivo",
  critical_rights: "Derechos Críticos",
  rights: "Derechos",
  position: "Posición",
  notify_to: "Notificar a",
  add_remove: "Agregar/Quitar",
  save_critical_right: "Guardar Derecho Crítico",
  delete_right: "Eliminar Derecho",
  do_you_want_to_delete: "¿Desea eliminar?",
  search_department: "Buscar Departamento",
  add_department: "Agregar Departamento",
  no_department_added_yet: "¡Aún no se ha agregado ningún Departamento!",
  click_add_department_button_to_create_new_department:
    "Haga clic en el Botón de Agregar Departamento para crear un nuevo Departamento",
  description: "Descripción",
  action: "Acción",
  do_you_want_to_delete_this_department: "¿Desea eliminar este Departamento?",
  capital_expenditure: "Gasto de Capital",
  enter_expenditure_items: "Ingresar Ítems de Gasto",
  no_capital_expenditure_added_yet:
    "¡Aún no se ha agregado ningún Gasto de Capital!",
  select_and_add_capital_expenditure: "Seleccionar y Agregar Gasto de Capital",
  ce_items_added: "Ítems de Gasto de Capital Agregados",
  operating_expenditure: "Gasto Operativo",
  no_operating_expenditure_added_yet:
    "¡Aún no se ha agregado ningún Gasto Operativo!",
  select_and_add_operating_expenditure: "Seleccionar y Agregar Gasto Operativo",
  oe_items_added: "Ítems de Gasto Operativo Agregados",
  organization_details: "Detalles de la Organización",
  no_organization_details_not_define_yet:
    "No se han definido detalles de la organización todavía !!",
  registered_office_address: "Dirección de la oficina registrada",
  corresponding_office_address: "Dirección de la oficina correspondiente",
  organization_email_id: "Correo electrónico de la organización",
  organization_contact_no: "Número de contacto de la organización",
  organization_type: "Tipo de organización",
  date_of_incorporation: "Fecha de incorporación",
  authorized_person: "Persona autorizada",
  authorized_person_contact_number:
    "Número de contacto de la persona autorizada",
  trade_license: "Licencia comercial",
  company_identification_number: "Número de identificación de la empresa",
  tax_number: "Número de impuesto",
  time_zone: "Zona horaria",
  unit_name: "Nombre de la unidad",
  statutory_compliances: "Cumplimientos legales",
  local_tax: "Impuesto local",
  pan: "PAN",
  tan: "TAN",
  epf_no: "Número de EPF",
  esi_no: "Número de ESI",
  gst_no: "Número de GST",
  edit_organization: "Editar organización",
  otp_service_name: "Nombre del servicio OTP",
  api_name: "Nombre de la API",
  search_service: "Buscar servicio",
  performance_management_system: "Sistema de gestión del rendimiento (PMS)",
  pms_cycle_from: "Ciclo de PMS desde",
  select_max_rating: "Seleccionar calificación máxima",
  evaluation_metrics: "Métricas de evaluación",
  new_metrics: "Nuevas métricas",
  metric_name: "Nombre de la métrica",
  rating: "Calificación",
  feedback: "Retroalimentación",
  both: "Ambos",
  search_policy: "Buscar política",
  policy: "Política",
  no_policy_added_yet: "No se ha agregado ninguna política todavía !!",
  click_add_policy_button_to_create_new_policy:
    "Haga clic en el botón Agregar política para crear una nueva política",
  policy_name: "Nombre de la política",
  onboard_checklist: "Lista de verificación de incorporación",
  do_you_want_to_delete_this_policy: "¿Desea eliminar esta política?",
  search_component: "Buscar componente",
  component: "Componente",
  no_salary_component_added_yet:
    "No se ha agregado ningún componente salarial todavía !!",
  click_add_component_button_to_create_new_salary_component:
    "Haga clic en el botón Agregar componente para crear un nuevo componente salarial",
  category: "Categoría",
  do_you_want_to_activate_this_salary_component:
    "¿Desea activar este componente salarial?",
  do_you_want_to_deactivate_this_salary_component:
    "¿Desea desactivar este componente salarial?",
  add_designation: "Agregar designación",
  edit_designation: "Editar designación",
  add_ctc_band: "Agregar banda CTC",
  min_wage: "Salario mínimo",
  max_wage: "Salario máximo",
  view_edit_departments: "Ver/Editar departamentos",
  no_departments_available: "No hay departamentos disponibles",
  organization_position: "Posición de la organización",
  select_unit: "Seleccionar unidad",
  designation: "Designación",
  add_new_designation: "Agregar nueva designación",
  department: "Departamento",
  view_edit_department: "Ver/Editar departamento",
  reporting_to: "Reportando a",
  annual_ctc_band: "Banda CTC anual",
  add_new_ctc_band: "Agregar nueva banda CTC",
  jd: "Descripción del trabajo",
  kra: "Áreas clave de resultados",
  add_remove: "Agregar/Eliminar",
  no_unit_is_defined_please_add_a_new_unit:
    "No se ha definido ninguna unidad. Por favor, agregue una nueva unidad",
  no_position_defined_please_add_a_new_position:
    "No se ha definido ninguna posición. Por favor, agregue una nueva posición",
  position: "Posición",
  or: "O",
  select_unit_from_where_to_copy: "Seleccionar unidad desde donde copiar",
  select_unit: "Seleccionar unidad",
  copy_positions: "Copiar posiciones",
  select_department: "Seleccionar departamento",
  select_reporting_head: "Seleccionar jefe de informes",
  none: "Ninguno",
  search_user: "Buscar usuario",
  organization_storage:
    "Almacenamiento de la organización - {{totalUsedStorage}} GB de {{totalStorage}} GB utilizados",
  storage: "Almacenamiento",
  no_storage_define_yet: "No se ha definido ningún almacenamiento todavía !!",
  click_add_storage_button_to_create_new_storage:
    "Haga clic en el botón Agregar almacenamiento para crear un nuevo almacenamiento",
  user_name: "Nombre de usuario",
  email_id: "ID de correo electrónico",
  storage: "Almacenamiento",
  used: "Usado",
  modify: "Modificar",
  search: "Buscar",
  name: "Nombre",
  category: "Categoría",
  due_date_renewal_date: "Fecha de vencimiento/renovación",
  last_payment_date: "Fecha del último pago",
  last_payment_amount: "Monto del último pago",
  notify_to_positions: "Notificar a las posiciones",
  lead_time: "Tiempo de entrega",
  terms_and_conditions: "Términos y condiciones",
  no_terms_and_condition_define_yet:
    "No se han definido términos y condiciones todavía !!",
  click_add_terms_and_condition_button_to_create_new_terms_and_condition:
    "Haga clic en el botón Agregar términos y condiciones para crear nuevos términos y condiciones",
  t_and_c_heading: "Encabezado de términos y condiciones",
  t_and_c_for: "Términos y condiciones para",
  t_and_c_type: "TIPO DE T&C",
  do_you_want_to_delete_this_terms_and_conditions:
    "¿Desea eliminar estos Términos y Condiciones?",
  unit_list: "Lista de Unidades",
  unit_tree: "Árbol de Unidades",
  add_units: "Agregar Unidades",
  no_units_define_yet: "No se han definido unidades todavía !!",
  click_add_units_button_to_create_new_units:
    "Haga clic en el botón Agregar Unidades para crear nuevas Unidades",
  cost_center: "Centro de Costos",
  profit_center: "Centro de Beneficios",
  balance_sheet_maintain_by_own: "Hoja de balance mantenida por cuenta propia",
  statutory_compliances: "Cumplimientos legales",
  local_tax_applicable: "Impuesto local aplicable",
  default_currency: "Moneda predeterminada",
  delete_unit: "Eliminar Unidad",
  edit_unit: "Editar Unidad",
  do_you_want_to_delete_this_unit: "¿Desea eliminar esta Unidad?",
  job_description: "Descripción del Trabajo",
  job_summary: "Resumen del Trabajo",
  key_responsibilities: "Responsabilidades Clave",
  enter_title: "Ingrese Título",
  enter_description: "Ingrese Descripción",
  add_new_description: "Agregar Nueva Descripción",
  qualifications: "Calificaciones",
  education: "Educación",
  add_new_education: "Agregar Nueva Educación",
  delete_education: "Eliminar Educación",
  experience: "Experiencia",
  add_new_experience: "Agregar Nueva Experiencia",
  delete_experience: "Eliminar Experiencia",
  certificates: "Certificados",
  add_new_certificate: "Agregar Nuevo Certificado",
  skills_and_attributes: "Habilidades y Atributos",
  enter_title_skill_or_attribute: "Ingrese Título (Habilidad o Atributo)",
  enter_skills_or_attributes: "Ingrese Habilidades o Atributos",
  add_new_skills_or_attributes: "Agregar Nuevas Habilidades o Atributos",
  title_conflict_title_already_exist_consider_changing:
    "Conflicto de Título! El Título ya existe, considere cambiarlo",
  please_add_jd_data: "Por favor, agregue datos de JD",
  job_kra: "KRA del Trabajo",
  kpi: "KPI",
  enter_kpi: "Ingrese KPI",
  add_new_targets: "Agregar Nuevas Metas",
  targets: "Metas",
  enter_targets: "Ingrese Metas",
  add_kra_data: "Agregar Datos de KRA",
  units_tree: "Árbol de Unidades",
  edit_access_permission: "Editar Permisos de Acceso",
  menus: "Menús",
  edit_organization: "Editar Organización",
  change_logo: "Cambiar Logo",
  organization_name: "Nombre de la Organización",
  common_name: "Nombre Común",
  registered_address: "Dirección Registrada",
  country: "País",
  corresponding_address: "Dirección Correspondiente",
  organization_info: "Información de la Organización",
  organization_email_id: "Correo Electrónico de la Organización",
  organization_contact_no: "Número de Contacto de la Organización",
  organization_type: "Tipo de Organización",
  none: "Ninguno",
  public_limited_company: "Sociedad Anónima Pública",
  private_limited_company: "Sociedad Anónima Privada",
  joint_venture_company: "Empresa Conjunta",
  partnership_firm: "Firma de Sociedad",
  one_person_company: "Empresa Unipersonal",
  sole_proprietorship: "Propiedad Individual",
  branch_office: "Oficina Sucursal",
  non_government_organization: "Organización No Gubernamental (ONG)",
  date_of_incorporation: "Fecha de Incorporación",
  authorized_person: "Persona Autorizada",
  authorized_person_contact_no: "Número de Contacto de la Persona Autorizada",
  trade_license_no: "Número de Licencia Comercial",
  company_identification_no: "Número de Identificación de la Empresa",
  tax_no: "Número de Impuesto",
  facebook_link: "Enlace de Facebook",
  instagram_link: "Enlace de Instagram",
  linkedin_link: "Enlace de LinkedIn",
  twitter_link: "Enlace de Twitter",
  unit_info: "Información de la Unidad",
  balance_sheet_maintain_by_own: "Hoja de balance mantenida por cuenta propia",
  statutory_compliances: "Cumplimientos legales",
  local_tax_applicable: "Impuesto local aplicable",
  default_currency: "Moneda predeterminada",
  unit_name: "Nombre de la Unidad",
  pan_id: "ID de PAN",
  tan_id: "ID de TAN",
  epf_no: "Número de EPF",
  esi_no: "Número de ESI",
  gst_no: "Número de GST",
  edit_salary_component: "Editar componente salarial",
  edit_terms_and_conditions: "Editar Términos y Condiciones",
  edit_units: "Editar Unidades",
  view_activity_template: "Ver Plantilla de Actividades",
  failed_to_fetch_mapped_menus: "Error al obtener menús asignados",
  please_select_user: "Por favor, seleccione un usuario",
  please_type_to_search: "Por favor, escriba para buscar",
  please_enter_salary_head_name:
    "Por favor, ingrese el nombre del componente salarial",
  salary_head_name: "Nombre del componente salarial",
  file_size_warning: "'{{fileName}}' debe ser menor que {{maxFileSize}}MB",
  invalid_file_type_warning: "Tipo de archivo inválido de '{{fileName}}'",
  please_select_work: "Por favor, seleccione trabajo",
  Please_upload_at_least_one_file: "Por favor, suba al menos un archivo",
  please_enter_a_role: "Por favor, ingrese un rol",
  please_enter_committee_name: "Por favor, ingrese el nombre del comité",
  please_add_at_least_one_role: "Por favor, añada al menos un rol",
  please_enter_purpose: "Por favor, ingrese el propósito",
  please_enter_discount_precentage:
    "Por favor, ingrese el porcentaje de descuento",
  discount_precentage_cannot_be_less_then_one:
    "El porcentaje de descuento no puede ser menor que 1",
  discount_precentage_cannot_be_more_then_hundred:
    "El porcentaje de descuento no puede ser mayor que 100",
  please_enter_no_of_use: "Por favor, ingrese el número de usos",
  useable_time_cannot_be_less_then_one:
    "El tiempo de uso no puede ser menor que 1",
  please_enter_receiver_name: "Por favor, ingrese el nombre del receptor",
  please_enter_receiver_email_id:
    "Por favor, ingrese el correo electrónico del receptor",
  please_enter_valid_receiver_email_id:
    "Por favor, ingrese un correo electrónico válido del receptor",
  please_enter_custom_code: "Por favor, ingrese un código personalizado",
  custom_code_cannot_be_less_then_five_character:
    "El código personalizado no puede tener menos de 5 caracteres",
  custom_code_cannot_be_more_then_ten_character:
    "El código personalizado no puede tener más de 10 caracteres",
  please_enter_department_name: "Por favor, ingrese el nombre del departamento",
  please_enter_department_description:
    "Por favor, ingrese la descripción del departamento",
  please_enter_hierarchy_name: "Por favor, ingrese el nombre de la jerarquía",
  please_enter_designation_name: "Por favor, ingrese el nombre del cargo",
  please_select_expense_type: "Por favor, seleccione el tipo de gasto",
  please_select_a_position: "Por favor, seleccione un puesto",
  please_give_minimum_amount: "Por favor, indique la cantidad mínima",
  please_give_maximum_amount: "Por favor, indique la cantidad máxima",
  maximum_amount_should_be_greater_than_minimum_amount:
    "La cantidad máxima debe ser mayor que la cantidad mínima",
  please_select_rights: "Por favor, seleccione los derechos",
  please_enter_policy_name: "Por favor, ingrese el nombre de la política",
  please_upload_a_pdf_file: "Por favor, suba un archivo PDF",
  please_provide_only_pdf_file: "Por favor, proporcione solo un archivo PDF",
  please_enter_component_name: "Por favor, ingrese el nombre del componente",
  salary_component_name_is_already_present:
    "El nombre del componente salarial ya existe",
  please_enter_percentage: "Por favor, ingrese el porcentaje",
  please_enter_a_valid_percentage:
    "Por favor, ingrese un porcentaje válido (0-100)",
  please_enter_category_name: "Por favor, ingrese el nombre de la categoría",
  the_category_name_already_exists: "El nombre de la categoría ya existe",
  an_error_occurred_while_adding_the_category:
    "Se produjo un error al añadir la categoría",
  category_updated_successfully: "Categoría actualizada con éxito",
  failed_to_update_category: "No se pudo actualizar la categoría",
  an_error_occurred_while_updating_the_category:
    "Se produjo un error al actualizar la categoría",
  category_deleted_successfully: "Categoría eliminada con éxito",
  failed_to_delete_category: "No se pudo eliminar la categoría",
  an_error_occurred_while_deleting_the_category:
    "Se produjo un error al eliminar la categoría",
  please_provide_name: "Por favor, proporcione un nombre",
  please_select_category: "Por favor, seleccione una categoría",
  please_select_frequency: "Por favor, seleccione la frecuencia",
  please_select_monthly_period: "Por favor, seleccione el período mensual",
  please_select_quarterly_period: "Por favor, seleccione el período trimestral",
  please_select_notify_to_position:
    "Por favor, seleccione notificar a la posición",
  please_select_lead_notify_lead_time:
    "Por favor, seleccione el tiempo de notificación del líder",
  please_select_t_and_c_for: "Por favor, seleccione T&C para",
  please_select_t_and_c_type: "Por favor, seleccione el tipo de T&C",
  please_enter_terms_and_condition: "Por favor, ingrese términos y condiciones",
  terms_and_conditions_created_successfully:
    "Términos y condiciones creados con éxito",
  please_enter_unit_name: "Por favor, ingrese el nombre de la unidad",
  please_enter_or_fetch_current_latitude:
    "Por favor, ingrese o obtenga la latitud actual",
  please_enter_or_fetch_current_longitude:
    "Por favor, ingrese o obtenga la longitud actual",
  please_enter_house_no_building_name:
    "Por favor, ingrese el número de casa o el nombre del edificio",
  please_enter_area_colony: "Por favor, ingrese el área o colonia",
  please_enter_city: "Por favor, ingrese la ciudad",
  please_enter_pin: "Por favor, ingrese el PIN",
  please_enter_valid_email: "Por favor, ingrese un correo electrónico válido",
  please_select_country_code: "Por favor, seleccione el código del país",
  please_enter_phone_number: "Por favor, ingrese el número de teléfono",
  please_enter_a_valid_phone_number:
    "Por favor, ingrese un número de teléfono válido",
  please_enter_subactivity_work_name:
    "Por favor, ingrese el nombre de la subactividad o trabajo",
  minimum_amount_cannot_be_greater_than_maximum_amount:
    "La cantidad mínima no puede ser mayor que la cantidad máxima",
  please_select_position: "Por favor, seleccione la posición",
  please_save_modification_before_you_proceed:
    "Por favor, guarde las modificaciones antes de continuar",
  new_rights: "Nuevos derechos",
  please_enter_capital_expenditure_item:
    "Por favor, ingrese el ítem de gasto de capital",
  duplicate_capital_expenditure_item_please_add_a_new_item:
    "Ítem de gasto de capital duplicado. Por favor, añada un nuevo ítem",
  capital_expenditure_added_successfully: "Gasto de capital añadido con éxito",
  please_enter_operating_expenditure_item:
    "Por favor, ingrese el ítem de gasto operativo",
  duplicate_operating_expenditure_item_please_add_a_new_item:
    "Ítem de gasto operativo duplicado. Por favor, añada un nuevo ítem",
  operating_expenditure_added_successfully: "Gasto operativo añadido con éxito",
  balance_sheet_maintain_by_own: "Balance de hoja mantenido por cuenta propia",
  an_error_occurred_while_updating_the_otp_service_status:
    "Se produjo un error al actualizar el estado del servicio OTP",
  please_select_max_rating: "Por favor, seleccione la calificación máxima",
  please_select_option: "Por favor, seleccione una opción para {{metrics}}",
  data_updated_successfully: "Datos actualizados con éxito",
  to: "A",
  salary_component_activated_successfully:
    "Componente salarial activado con éxito",
  failed_to_activated_salary_component:
    "Error al activar el componente salarial",
  an_error_occurred_while_activated_salary_component:
    "Se produjo un error al activar el componente salarial",
  salary_component_deactivated_successfully:
    "Componente salarial desactivado con éxito",
  failed_to_deactivated_salary_component:
    "Error al desactivar el componente salarial",
  an_error_occurred_while_deactivated_salary_component:
    "Se produjo un error al desactivar el componente salarial",
  n_a: "N/A",
  designation_edit_unsuccessful: "Edición de designación fallida",
  designation_edited_successfully: "Designación editada con éxito",
  please_enter_department_name: "Por favor, ingrese el nombre del departamento",
  department_created_successfully: "Departamento creado con éxito",
  salary_cannot_be_negative: "El salario no puede ser negativo",
  minimum_salary_cannot_be_more_than_maximum_salary:
    "El salario mínimo no puede ser mayor que el salario máximo",
  please_select_current_salary: "Por favor, seleccione el salario actual",
  could_not_fetch_data_something_went_wrong:
    "No se pudo obtener los datos. Algo salió mal",
  please_select_unit_from_where_position_is_To_be_copied:
    "Por favor, seleccione la unidad de donde se copiará la posición",
  designation_copy_unsuccessful: "Copia de designación fallida",
  designation_copied_successfully: "Designación copiada con éxito",
  something_went_wrong: "Algo salió mal",
  please_add_designation: "Por favor, añada Designación",
  please_select_department: "Por favor, seleccione Departamento",
  please_define_designation_name:
    "Por favor, defina el nombre de la designación",
  please_select_department_name:
    "Por favor, seleccione el nombre del departamento",
  data_submitted_successfully: "Datos enviados con éxito",
  please_save_current_position: "Por favor, guarde la posición actual",
  item_already_present: "El elemento ya está presente",
  please_provide_description: "Por favor, proporcione una descripción",
  please_enter_target:
    "Por favor, introduzca el objetivo para {{label}} {{title}} en el índice {{index}}",
  key_already_present: "La clave ya está presente",
  add_new_title: "Añadir nuevo título",
  please_enter_title_index:
    "Por favor, introduzca el título en 'Responsabilidad clave' en el índice {{index}}",
  please_enter_desscription_index:
    "Por favor, introduzca la descripción en el índice {{index}}",
  please_enter_the_descriptions: "Por favor, introduzca las descripciones",
  please_provide_proper_skills_and_attribute:
    "Por favor, proporcione habilidades y atributos adecuados",
  please_enter_job_summary: "Por favor, introduzca el resumen del trabajo",
  title_is_needed_in_key_responsibility:
    "Se necesita un título en 'Responsabilidad clave'",
  title_already_present: "El título ya está presente",
  enter_summary: "Introduzca el resumen",
  delete_certificate: "Eliminar certificado",
  please_enter_target_value: "Por favor, introduzca el objetivo",
  please_provide_title: "Por favor, proporcione un título",
  please_enter_title_at_index:
    "Por favor, introduzca el título en el índice {{index}}",
  please_provide_kpi_for: "Por favor, proporcione KPI para {{title}}",
  please_select_user: "Por favor, seleccione usuario",
  please_type_to_search: "Por favor, escriba para buscar",
  orglogo: "orgLogo",
  minimum_twenty_five_gb_is_needed_for_storage_upgrade:
    "Se necesitan al menos 25 GB para la actualización de almacenamiento",
  storage_upgraded_successfully:
    "¡Almacenamiento de {{userName}} actualizado con éxito!",
  terms_and_conditions_updated_successfully:
    "Términos y condiciones actualizados con éxito",
  event_updated_successfully: "Evento actualizado con éxito",
  select_hospital: "Seleccionar hospital",

  organization: "Organización",
  units: "Unidades",
  position: "Posición",
  salary_component: "Componente Salarial",
  committee: "Comité",
  budget_head: "Encargado del Presupuesto",
  critical_rights: "Derechos Críticos",
  statutory_and_insurance: "Estatutario y Seguro",
  storage: "Almacenamiento",
  coupon: "Cupón",
  activity: "Actividad",
  policy: "Política",
  terms_and_conditions: "Términos y Condiciones",
  access_control: "Control de Acceso",
  website: "Sitio Web",
  pms: "PMS",
  otp_service: "Servicio OTP",

  /////////////////////////Scheduler/////////////////////////////////

  virtual: "Virtual",
  physical: "Físico",
  hybrid: "Híbrido",
  add_guests: "Agregar Invitados",
  belongs_to_your_organization: "Pertenece a su organización",
  new_guest: "Nuevo Invitado",
  add_a_new_guest: "Agregar un nuevo invitado",
  enter_user_details: "Ingrese los detalles del usuario",
  user_name: "Nombre de usuario",
  user_email: "Correo electrónico del usuario",
  convener: "Convocador",
  chairperson: "Presidente",
  make_convener: "Hacer Convocador",
  make_chairperson: "Hacer Presidente",
  confirmation: "Confirmación",
  remove_from_guest_list: "¿Eliminar a {{userName}} de la lista de invitados?",
  please_select_atleast_two_participants_for_viewing_convenient_time:
    "Por favor seleccione al menos dos participantes para ver el tiempo conveniente",
  select_a_convenient_time: "Seleccione un tiempo conveniente",
  to: "A",
  exclude_me: "Exclúyeme",
  add_title: "Agregar Título",
  agenda: "Agenda",
  information: "Información",
  max_files_that_can_be_uploaded: "Máximo de archivos que se pueden subir",
  max_size_per_file: "Tamaño máximo por archivo",
  allowed_file_types: "Tipos de archivos permitidos",
  enter_agenda_here: "Ingrese la agenda aquí",
  enter_agenda_description: "Ingrese la descripción de la agenda",
  upload_new_file: "Cargar nuevo archivo",
  polling: "Votación",
  show_of_hand: "Levantamiento de mano",
  add_agenda: "Agregar Agenda",
  special_resolution_will_be_proposed: "Se propondrán resoluciones especiales",
  special_resolution_name: "Nombre de la resolución especial",
  add_resolution: "Agregar Resolución",
  additional_documents_for_meeting: "Documentos adicionales para la reunión",
  additional_document_name: "Nombre del documento adicional",
  add_other_document: "Agregar otro documento",
  test_observation: "Observación de prueba",
  leave_type: "Tipo de permiso",
  available_leave_balance: "Saldo de permisos disponibles",
  enter_purpose_of_leave: "Ingrese el propósito del permiso",
  purpose_of_leave: "Propósito del permiso",
  half_day: "Medio Día",
  upload_files: "Cargar Archivos",
  file_name: "Nombre del archivo",
  upload: "Subir",
  max_file_size_allowed: "Tamaño máximo de archivo permitido {{size}}MB",
  extensions_allowed: "Extensiones permitidas {{extensions}}",
  hide_leave_details: "Ocultar detalles del permiso",
  show_leave_details: "Mostrar detalles del permiso",
  no_leave_history_found: "No se encontró historial de permisos",
  do_you_want_to_cancel_this_leave_request:
    "¿Desea cancelar esta solicitud de permiso?",
  do_you_want_to_withdraw_this_leave_request:
    "¿Desea retirar esta solicitud de permiso?",
  events_between: "Eventos entre",
  withdraw: "Retirar",
  audio_note_recorded: "Nota de audio grabada",
  do_you_want_to_continue: "¿Desea continuar?",
  do_you_want_to_continue_with_given_parameters:
    "¿Quieres continuar con los parámetros dados?",
  Webinar_title: "Título del Webinar",
  webinar_description: "Descripción del Webinar",
  webinar_slug: "Slug del Webinar",
  select_days: "Seleccionar días",
  select_all: "Seleccionar todo",
  event_repeat_until_date: "Repetir evento hasta la fecha",
  create_an_event: "Crear un Evento",
  apply_for_a_leave: "Solicitar un Permiso",
  create_a_webinar: "Crear un Webinar",
  event: "Evento",
  webinar: "Webinar",
  one_time_event: "Evento Único",
  recurring_event: "Evento Recurrente",
  annual_general_meeting: "Reunión General Anual",
  board_of_director_meeting: "Reunión del Consejo de Administración",
  special_bod_meeting: "Reunión Especial del Consejo de Administración",
  extraordinary_general_meeting: "Reunión General Extraordinaria",
  task_review_meeting: "Reunión de Revisión de Tareas",
  client_meeting: "Reunión con Cliente",
  review_meeting: "Reunión de Revisión",
  vendor_meeting: "Reunión con Proveedor",
  general_meeting: "Reunión General",
  scheduler: "Programador",
  create: "Crear",
  leave_request: "Solicitud de Permiso",
  today: "Hoy",
  my_calendar: "Mi Calendario",
  google: "Google",
  weekend: "Fin de semana",
  holiday: "Vacaciones",
  leave: "Permiso",
  day: "Día",
  week: "Semana",
  month: "Mes",
  agenda: "Agenda",
  calendar_settings: "Configuración del Calendario",
  sync_with_google_calendar: "Sincronizar con Google Calendar",
  re_sync_needed: "(se necesita re-sincronización)",
  last_synced: "Última sincronización:",
  first_day_of_the_week: "Primer día de la semana",
  select_days: "Seleccionar días",
  work_week: "Semana laboral",
  time_slot: "Intervalo de tiempo",
  select_slot_duration: "Seleccionar duración del intervalo",
  please_enter_user_name: "Por favor ingrese el nombre del usuario",
  please_enter_user_email:
    "Por favor ingrese el correo electrónico del usuario",
  please_enter_valid_email: "Por favor ingrese un correo electrónico válido",
  participant_with_this_email_already_exists:
    "Ya existe un participante con este correo electrónico",
  new_guest_added_successfully: "Nuevo invitado agregado exitosamente",
  please_select_file_less_than:
    "Por favor seleccione un archivo de menos de {{maxFileSize}}MB",
  invalid_file_type: "Tipo de archivo inválido",
  you_can_upload_a_maximum_of_five_files: "Puede subir un máximo de 5 archivos",
  please_enter_event_location: "Por favor ingrese la ubicación del evento",
  select_participants: "Seleccionar participantes",
  this_meeting_needs_at_least_two_participants:
    "Esta reunión necesita al menos 2 participantes",
  please_enter_event_title: "Por favor ingrese el título del evento",
  you_have_to_add_meeting_agenda: "Debe agregar la agenda de la reunión",
  you_have_exceeded_the_current_time_please_select_the_next_time_slot:
    "Ha excedido el tiempo actual. Por favor seleccione el siguiente intervalo de tiempo",
  all_guests_added_successfully:
    "Todos los invitados fueron agregados con éxito",
  failed_to_add_some_guests: "No se pudo agregar a algunos invitados",
  file_attached: "Archivo adjunto",
  please_enter_the_purpose: "Por favor ingrese el propósito",
  please_upload_all_the_required_files:
    "Por favor suba todos los archivos requeridos",
  you_cannot_take_more_leave_than_your_leave_balance:
    "No puede tomar más permisos de los que tiene disponibles",
  please_enter_event_description: "Por favor ingrese la descripción del evento",
  event_created_successfully: "Evento creado exitosamente",
  please_enter_webinar_title: "Por favor ingrese el título del Webinar",
  please_enter_webinar_description:
    "Por favor ingrese la descripción del Webinar",
  Please_fill_blogSlug: "Por favor complete el Slug del blog",
  webinar_created_successfully: "Webinar creado exitosamente",
  syncing_please_wait: "Sincronizando, por favor espere...",
  google_calendar_data_synced_successfully:
    "Datos de Google Calendar sincronizados con éxito",
  view_details_of: "Ver detalles de {{title}}",
  please_change_first_day_of_week:
    "Por favor cambie {{day}} del primer día de la semana antes de eliminarlo del día laboral",
  mark_as_working_day: "Marcar como Día Laboral",
  mark_attendance_is_unavailable_as_you_have_denied_location_access_to_this_site_please_allow_access_from_your_browser_settings:
    "El marcado de asistencia no está disponible porque ha denegado el acceso a la ubicación de este sitio. Por favor permita el acceso desde la configuración de su navegador",
  please_provide_location_access_to_mark_attendance:
    "Por favor proporcione acceso a la ubicación para marcar asistencia",
  mark_attendance: "Marcar Asistencia",
  face_liveness_test_failed_please_try_again:
    "La prueba de vida facial falló, por favor intente nuevamente...",
  facial_biometrics_added_to_account_successfully:
    "Biometría facial agregada a la cuenta exitosamente",
  please_register_your_facial_biometrics_to_mark_attendance:
    "Por favor registre su biometría facial para marcar asistencia",
  please_provide_your_facial_biometrics_to_mark_attendance:
    "Por favor proporcione su biometría facial para marcar asistencia",
  select_the_days: "Seleccione los días",
  please_select_current_time: "Por favor seleccione la hora actual",
  invite_sent_successfully: "Invitación enviada con éxito",

  /////// week days ////

  sun: "Dom",
  mon: "Lun",
  tue: "Mar",
  wed: "Mié",
  thu: "Jue",
  fri: "Vie",
  sat: "Sáb",
  advisory_board_consultant: "Consultora de la Junta Asesora",

  search_name: "Nombre de búsqueda",
  admin_rights_granted: "Derechos de administrador concedidos",
  contact_number: "Número de contacto",

  /////////health care ////////////

  register_patient: "Registrar Paciente",
  this_month: "Este Mes",
  search_by_patient_id: "Buscar por ID de paciente",
  dob: "Fecha de Nacimiento",
  years: "Años",
  months: "Meses",
  civil_status: "Estado civil",
  contact_number: "Número de contacto",
  proceed_to_book_consultation: "Proceder a reservar consulta",
  proceed_to_book_appointment: "Proceder a reservar cita",
  proceed_to_book_investigation: "Proceder a reservar investigación",
  scan_face: "Escanear rostro",
  matched_face: "Rostro coincidente",
  present_image: "Imagen presente",
  matched_image: "Imagen coincidente",
  recapture: "Volver a capturar",
  patient_documents: "Documentos del paciente",
  document_type: "Tipo de documento",
  no_patient_document_data_is_present:
    "No hay datos de documentos de paciente presentes",
  patient_name: "Nombre del paciente",
  search_patient: "Buscar paciente",
  consulting_doctor: "Médico consultante",
  consulting_start_time: "Hora de inicio de consulta",
  consulting_end_time: "Hora de fin de consulta",
  document: "Documento",
  book_appointment: "Reservar cita",
  view_appointment: "Ver cita",
  appointment: "Cita",
  book_consultation: "Reservar consulta",
  unit_queue: "Cola de unidad",
  queue: "Cola",
  consultations: "Consultas",
  count: "Conteo",
  patient_registrations: "Registros de pacientes",
  no_of_registraton: "N.º de registro",
  select_doctor: "Seleccionar doctor",
  slots_date_from: "Fecha de ranuras desde",
  booked: "Reservado",
  slots_unavailable: "Ranuras no disponibles",
  please_wait_file_is_getting_get_downloaded:
    "Por favor espere. El archivo se está descargando",
  the_file_cannot_be_downloaded: "El archivo no se puede descargar",
  download_prescription: "Descargar receta",
  appointment_booking: "Reserva de cita",
  booking_date_time: "Fecha y hora de reserva",
  patient_info: "Información del paciente",
  email_id: "Correo electrónico",
  add_or_search_by_mail_id: "Agregar o buscar por correo",
  confirm_booking: "Confirmar reserva",
  please_enter_email_id: "Por favor ingrese correo electrónico",
  please_enter_first_name: "Por favor ingrese el nombre",
  please_enter_last_name: "Por favor ingrese el apellido",
  please_select_gender: "Por favor seleccione el género",
  please_enter_a_valid_email: "Por favor ingrese un correo electrónico válido",
  appoinment_booked_successfully: "Cita reservada con éxito",
  appoinment_booking_unsuccessful: "Reserva de cita fallida",
  fetching_facial_biometrics_data:
    "Obteniendo datos biométricos faciales, por favor espere...",
  facial_biometrics_data_found:
    "Datos biométricos faciales encontrados, los detalles del paciente se obtuvieron con éxito",
  facial_biometrics_data_not_found:
    "Datos biométricos faciales no encontrados, por favor continúe para registrar un nuevo paciente",
  please_provide_facial_biometrics:
    "Por favor proporcione datos biométricos faciales",
  please_enter_patient_first_name: "Por favor ingrese el nombre del paciente",
  please_enter_patient_last_name: "Por favor ingrese el apellido del paciente",
  please_enter_patient_gender: "Por favor ingrese el género del paciente",
  please_enter_patient_date_of_birth:
    "Por favor ingrese la fecha de nacimiento del paciente",
  please_enter_patient_email_id:
    "Por favor ingrese el correo electrónico del paciente",
  please_enter_a_valid_patient_email_id:
    "Por favor ingrese un correo electrónico válido del paciente",
  processing_please_wait: "Procesando, por favor espere...",
  success_please_continue_to_book_consultation:
    "Éxito, por favor continúe para reservar consulta",
  patient_registered_successfully_please_continue_to_book_consultation:
    "Paciente registrado con éxito, por favor continúe para reservar consulta",
  start_time_less_than_end_time:
    "La 'Hora de inicio' debe ser menor que la 'Hora de fin' para {{dayName}}",
  time_ranges_overlap:
    "Los rangos de tiempo se superponen para {{dayName}} en la semana {{week}}",
  referral_note: "Nota de referencia",
  doctor_info: "Información del médico",
  register_face: "Registrar rostro",
  add_to_queue: "Agregar a la cola",
  cancel_appoinment: "Cancelar cita",
  blood_pressure: "Presión arterial",
  sys: " Síst",
  dys: "Diást",
  heart_rate: "Frecuencia cardíaca",
  example_eighty: "ej: 80",
  respiration_rate: "Frecuencia respiratoria",
  eg_12: "ej: 12",
  spo2: "SPO2",
  eg_98: "ej: 98",
  temperature: "Temperatura",
  eg_96_6: "ej: 96.6",
  eg_35: "ej: 35",
  weight: "Peso",
  eg_80: "ej: 80",
  height: "Altura",
  eg_182: "ej: 182",
  head_circumference: "Circunferencia de la cabeza",
  eg_34_5: "ej: 34.5",
  upper_arm_circumference: "Circunferencia del brazo superior",
  eg_12_5: "ej: 12.5",
  lmp: "Último período menstrual",
  blood_group: "Grupo sanguíneo",
  get_vitals_from_device: "Obtener signos vitales del dispositivo",
  systemic_illness: "Enfermedad sistémica",
  substance_abuse: "Abuso de sustancias",
  reference_details: "Detalles de referencia",
  referred_by: "Referido por",
  reschedule_date: "Reprogramar fecha",
  continue: "Continuar",
  please_give_patient_complaints: "Por favor, indique las quejas del paciente",
  submitting_details_please_wait: "Enviando detalles, por favor espere...",
  sistolic_pressure_cannot_be_empty:
    "La presión sistólica no puede estar vacía",
  diastolic_pressure_cannot_be_empty:
    "La presión diastólica no puede estar vacía",
  sistolic_pressure_must_be_greater_than_diastolic:
    "La presión sistólica debe ser mayor que la presión diastólica",
  sistolic_and_diastolic_pressure_difference_must_be_greater_than_30:
    "La diferencia entre la presión sistólica y diastólica debe ser mayor de 30",
  temperature_range_error_in_celsius:
    "La temperatura debe estar entre 32.22 y 41.10 grados Celsius",
  temperature_range_error_in_fahrenheit:
    "La temperatura debe estar entre 90 y 106 grados Fahrenheit",
  fetching_facial_biometrics_data_please_wait:
    "Obteniendo datos biométricos faciales, por favor espere...",
  facial_biometrics_data_found_patient_details_fetched_successfully:
    "Datos biométricos faciales encontrados, los detalles del paciente se obtuvieron con éxito",
  facial_biometrics_not_found_please_register_patient_first:
    "Datos biométricos faciales no encontrados, por favor registre al paciente primero",
  please_select_patient: "Por favor seleccione paciente",
  appoinment_canceled_successfully: "Cita cancelada con éxito",
  appoinment_could_not_be_canceled: "No se pudo cancelar la cita",
  appoinment_rescheduled_successfully: "Cita reprogramada con éxito",
  appoinment_could_not_be_rescheduled: "No se pudo reprogramar la cita",
  please_select_doctor: "Por favor seleccione doctor",
  relationship: "Relación",
  please_select_relationship: "Por favor seleccione relación",
  patient_is_allergic_to_the_following_medication:
    "El paciente es alérgico a la siguiente medicación",
  generic: "Genérico",
  brand: "Marca",
  search_medicine_brand_generic: "Buscar medicina genérica",
  search_by_brand: "Buscar por marca",
  this_brands_generic_name_is_not_present_in_the_table:
    "El nombre genérico de esta marca no está presente en la tabla",
  please_provide_drug_name: "Por favor proporcione el nombre del medicamento",
  food_allergy: "Alergia alimentaria",
  food_name: "Nombre del alimento",
  please_provide_food_name: "Por favor proporcione el nombre del alimento",
  medical_history: "Historial médico",
  medicine: "Medicina",
  dosage: "Dosis",
  frequency: "Frecuencia",
  since: "Desde",
  generic_name: "Nombre genérico",
  past_illness: "Enfermedad pasada",
  reported_date: "Fecha reportada",
  add_past_illness: "Agregar enfermedad pasada",
  select_date: "Seleccionar fecha",
  previous_procedure: "Procedimiento anterior",
  add_previous_procedure: "Agregar procedimiento anterior",
  surgery_date: "Fecha de cirugía",
  could_not_fetch_generic_ranking: "No se pudo obtener el ranking genérico",
  could_not_fetch_generic_data: "No se pudieron obtener los datos genéricos",
  please_select_medicine_name: "Por favor seleccione el nombre del medicamento",
  please_select_dosage: "Por favor seleccione la dosis",
  please_select_frequency: "Por favor seleccione la frecuencia",
  please_provide_correct_start_date:
    "Por favor proporcione la fecha de inicio correcta",
  registered_patients: "Pacientes Registrados",
  review_and_referred: "Revisados y Referidos",
  investigation_unit: "Unidad de Investigación",
  search_doctor: "Buscar doctor",
  cancel_facial_biometrics_registration_process:
    "¿Cancelar el proceso de registro de biometría facial?",
  mic: "Micrófono",
  video: "Video",
  share_screen: "Compartir Pantalla",
  white_board: "Pizarra",
  meeting_agenda: "Agenda de la Reunión",
  meeting_chat: "Chat de la Reunión",
  raise_hand: "Levantar la mano",
  invite_user: "Invitar usuario",
  start_live_stream: "Iniciar transmisión en vivo",
  stop_live_stream: "Detener transmisión en vivo",
  medication: "Medicación",
  leave_call: "Salir de la llamada",
  more: "Más",
  you_have_entered_a_room_number_please_use_join:
    "Ha introducido un número de sala, por favor use 'Unirse'",
  start_instant_meeting: "Iniciar reunión instantánea",
  polling: "Encuestas",
  show_of_hand: "Levantar la mano",
  no_agenda_available: "No hay agenda disponible",
  transcription_details: "Detalles de la transcripción",
  select_spoken_languages: "Seleccionar idiomas hablados",
  participants: "Participantes",
  meeting_notes: "Notas de la reunión",
  audio_devices: "Dispositivos de audio",
  grid_view: "Vista de cuadrícula",
  virtual_background: "Fondo virtual",
  file_share: "Compartir archivo",
  breakout_rooms: "Salas de trabajo",
  join: "Unirse",
  leave_end_webinar: "Salir/Finalizar Webinar",
  leave_webinar: "Salir del Webinar",
  end_the_webinar_for_all_users_the_webinar_will_be_closed_and_you_will_not_be_able_to_start_it_again:
    "Finalizar el webinar para todos los usuarios, el webinar se cerrará y no podrá volver a iniciarlo",
  end_webinar_for_all: "Finalizar webinar para todos",
  speakers: "Oradores",
  video_devices: "Dispositivos de video",
  video_camera: "Cámara de video",
  enter_room_number: "Introducir número de sala *",
  meeting_agendas: "Agendas de la reunión",
  subject: "Asunto",
  details: "Detalles",
  p_s: "P/S",
  record_patient_complaints: "Registrar quejas del paciente",
  patient_profile: "perfil del paciente",
  dys: "Dis",
  select: "Seleccionar",
  sos: "SOS",
  no_doctor_is_online: "Ningún doctor está en línea",
  please_give_patient_complaints: "Por favor, ingrese las quejas del paciente",
  submitting_details_please_wait: "Enviando detalles, por favor espere...",
  registered_patients: "Pacientes registrados",
  please_enter_other_terms_and_conditions:
    "Por favor, ingrese otros términos y condiciones",
  please_enter_document_name: "Por favor, ingrese el nombre del documento",
  face_details_found_please_associate_face_with_the_provided_data:
    "Detalles faciales encontrados, asocie la cara con los datos proporcionados",
  face_details_not_found_please_associate_face_with_the_provided_data:
    "Detalles faciales no encontrados, asocie la cara con los datos proporcionados",
  adding_facial_biometrics_data_to_account_please_wait:
    "Agregando datos de biometría facial a la cuenta, por favor espere...",
  facial_biometrics_data_added_to_account_successfully:
    "Datos de biometría facial agregados a la cuenta exitosamente",
  patient_booked_appointment_details_changes_successfully:
    "Detalles de la cita del paciente cambiados exitosamente",
  slot_already_booked_select_another_slot:
    "Franja ya reservada. Seleccione otra franja",
  doctor_image: "Imagen del doctor",
  patient_image: "Imagen del paciente",
  no_slot_selected: "No se ha seleccionado una franja",
  the_provided_facial_biometrics_are_associated_with_this_account:
    "Los datos biométricos faciales proporcionados están asociados con esta cuenta",
  profile_image: "Imagen de perfil",
  age: "Edad",
  contact_no: "Número de contacto",
  upload_file: "Subir archivo",
  upload_files: "Subir archivos",
  drag_and_drop_files_here: "Arrastra y suelta los archivos aquí",
  select_files: "Seleccionar archivos",
  selected_files: "Archivos seleccionados",
  previously_uploaded_files: "Archivos subidos anteriormente",
  invalidFileType: "Tipo de archivo inválido para '{{fileName}}'",
  fileSizeWarning:
    "El archivo '{{fileName}}' debe tener menos de {{maxFileSize}}MB",
  fileAlreadyUploaded: "'{{fileName}}' ya ha sido subido",
  selectStartTime: "Por favor, seleccione la 'Hora de inicio' para {{dayName}}",
  selectEndTime:
    "Por favor, seleccione la 'Hora de finalización' para {{dayName}}",
  selectDuration: "Por favor, seleccione la 'Duración' para {{dayName}}",
  search_doctor: "Buscar doctor",
  unit_list: "Lista de unidades",
  test_name: "Nombre del test",
  successfully_added_unit_for_test: "Unidad agregada para el test con éxito",
  error_in_adding_unit_for_test: "Error al agregar unidad para el test",
  conduct_investigation: "Realizar investigación",
  patient_profile: "perfil del paciente",
  investigations_from_prescriptions: "Investigaciones de prescripciones",
  add_new_investigation: "Agregar nueva investigación",
  outsource_report: "Subcontratar informe",
  collection_date_time: "Fecha y hora de recolección",
  sample_type: "Tipo de muestra",
  blood: "Sangre",
  urine: "Orina",
  sputum: "Esputo",
  reagent: "Reactivo",
  enter_reagent: "Introducir reactivo",
  value: "Valor",
  eg_120: "ej: 120",
  desirable: "Deseable",
  borderline_high: "Límite alto",
  high: "Alto",
  very_high: "Muy alto",
  parameter_name: "Nombre del parámetro",
  method: "Método",
  enter_method: "Introducir método",
  unit: "Unidad",
  reference_range: "Rango de referencia",
  upload_report: "Subir informe",
  test_is_not_available_in_this_center:
    "El test no está disponible en este centro",
  please_select_investigation: "Por favor, seleccione investigación",
  the_provided_email_id_is_currently_linked_with_the_patient_details_shown_below:
    "El correo electrónico proporcionado está actualmente vinculado con los detalles del paciente mostrados a continuación",
  on_clicking_continue_this_data_will_be_deleted_and_the_new_data_with_facial_biometrics_will_be_created:
    "Al hacer clic en continuar, estos datos serán eliminados y se crearán nuevos datos con biometría facial",
  email_id_is_linked_with_another_account:
    "El correo electrónico está vinculado a otra cuenta",
  review_patient: "Revisar Paciente",
  doctor_info: "Información del doctor",
  patient_info: "Información del paciente",
  blood_pressure: "Presión arterial",
  add_test_data: "Agregar datos del test",
  connect_to_doctor: "Conectar con el doctor",
  do_you_want_to_go_to_go_to_consultation: "¿Desea ir a la consulta?",
  doctor_consultations: "Consultas médicas",
  appointment_booked: "Cita reservada",
  view_appointment: "Ver cita",
  settings: "Configuraciones",
  investigation_admin: "Investigación - Admin",
  investigation_unit: "Investigación - Unidad",
  conduct: "Realizar",
  patient_documents: "Documentos del paciente",
  review_and_referred: "Revisado y derivado",
  referred_patient: "Paciente derivado",
  review_patient: "Revisar Paciente",
  search_name: "Buscar nombre",
  admin_rights_granted: "Derechos de administrador otorgados",
  contact_number: "Número de contacto",
  advisory_board_consultant: "Consultor del Consejo Asesor",
  last_employee_ids: "Últimos identificadores de empleados",
  please_enter_valid_latitude_range:
    "Por favor, ingrese un rango de latitud válido entre -90 y 90",
  please_enter_valid_longitude_range:
    "Por favor, ingrese un rango de longitud válido entre -180 y 180",
  search_by_dr: "Buscar por Dr",
  search_by_dr_speciality: "Buscar por Especialidad del Dr",
  consultationMessage:
    "{{patientName}} ha programado una consulta con {{doctorName}} el {{date}} a las {{fromTime}} - {{toTime}}. Por favor, asegúrese de llegar al centro al menos 15 minutos antes de la hora de su cita para evitar retrasos y permitir un proceso de consulta sin problemas.",
  eg_35: "ej: 35",
  audio: "Audio",
  patient_queue: "Cola de pacientes",
  patient: "Paciente",
  check_in_time: "Hora de check-in",
  doctor_queue: "Cola de doctores",
  ewt: "TME",
  status: "Estado",
  remark: "Observación",
  action: "Acción",
  appointment_consultation: "Consulta de Cita",
  patient_left: "Paciente Salió",
  patient_waiting_in_video: "Paciente Esperando en Video",
  doctor_writing_prescription: "Doctor Escribiendo Receta",
  doctor_cancelled: "Doctor Canceló",
  patient_switched: "Paciente Cambiado",
  start_conversation: "Iniciar Conversación",
  patient_leave_center: "Paciente Sale del Centro",
  patient_back_to_center: "Paciente Regresa al Centro",
  download_prescription: "Descargar Receta",
  switch_doctor: "Cambiar Doctor",
  add_test_result: "Agregar Resultado de Prueba",
  reconnect_doctor: "Reconectar Doctor",
  no_patient_found: "No Se Encontró Paciente",
  connect_to_doctor: "Conectar con el Doctor",
  consultation_in_progress: "Consulta en Progreso",
  doctor_canceled_patient_updating_queue:
    "Doctor Canceló al Paciente. Actualizando Cola",
  doctor_switched_patient_updating_queue:
    "Doctor Cambió Paciente(s). Actualizando Cola",
  doctor_switched_patient: "Doctor Cambió al Paciente",
  please_wait_the_file_is_being_downloaded:
    "Por Favor Espere. El archivo se está descargando",
  file_cannot_get_downloaded: "El archivo no se puede descargar",
  connecting_to_the_doctor: "Conectando con el Doctor",
  error_in_connecting_to_the_doctor: "Error al Conectar con el Doctor",
  successfully_logged_in_for_consultation:
    "Inicio de Sesión Exitoso para la Consulta",
  failed_to_log_in_for_consultation_please_try_again:
    "Error al Iniciar Sesión para la Consulta. Por Favor, Inténtelo de Nuevo.",
  an_unexpected_error_occurred_please_try_again_later:
    "Ocurrió un error inesperado. Por Favor, Inténtelo de Nuevo Más Tarde.",
  estimated_wait_time: "Tiempo Estimado de Espera",
  emergency_patient: "Paciente de Emergencia",
  conversation: "Conversación",
  update_comment_here: "Actualiza el comentario aquí",
  please_provide_a_rating_for_your_overall_experience_with_the_video_consultation:
    "Por favor, proporcione una calificación para su experiencia general con la consulta por video",
  please_select_below_issues_you_faces_during_consultation:
    "Por favor, seleccione a continuación los problemas que enfrentó durante la consulta",
  please_leave_your_valuable_feedback:
    "Por favor, deje sus valiosos comentarios",
  please_leave_your_valuable_feedback_regarding_your_overall_experience_with_the_video_consultation:
    "Por favor, deje sus valiosos comentarios sobre su experiencia general con la consulta por video",
  submit_your_feedback: "Enviar sus comentarios",
  please_select_rating: "Por favor, seleccione una calificación",
  feedback_submitted_successfully: "Comentarios enviados con éxito",
  feedback_submission_unsuccessful: "Envío de comentarios no exitoso",
  filter_patient: "Filtrar Paciente",
  no_data_found: "¡No se encontraron datos!",
  search_patient_name: "Buscar Nombre de Paciente",
  age: "Edad",
  gender: "Género",
  prescription: "Receta",
  investigation: "Investigación",
  generate_report: "Generar Informe",
  referred_patient: "Paciente Referido",
  select_date: "Seleccionar Fecha",
  search: "Buscar",
  no_refer_patient_data_is_found:
    "No se encontraron datos del Paciente Referido",
  patient_name: "Nombre del Paciente",
  doctor_name: "Nombre del Doctor",
  doctor_note: "Nota del Doctor",
  referral_note: "Nota de Referencia",
  no_doctor_notes: "No hay Notas del Doctor",
  no_referral_notes: "No hay Notas de Referencia",
  review_patients: "Revisar Pacientes",
  no_patient_review_data_found:
    "No se encontraron datos de revisión de pacientes",
  patient_name: "Nombre del Paciente",
  contact_info: "Información de Contacto",
  review_date: "Fecha de Revisión",
  doctor_info: "Información del Doctor",
  consulted_on: "Consultado el",
  notes: "Notas",
  last_consulted_with: "Última Consulta con",
  last_consulted_date: "Fecha de Última Consulta",
  no_notes_available: "No hay notas disponibles",
  process: "Proceso",
  view_appointments: "Ver Citas",
  search_doctor_or_speciality: "Buscar Doctor o especialidad",
  select_date: "Seleccionar Fecha",
  no_appointments_found: "No se encontraron citas",
  investigation: "Investigación",
  search_test_name: "Buscar Nombre de Prueba",
  no_investication_admin_data_found:
    "No se encontraron datos administrativos de investigación",
  test_group: "Grupo de Pruebas",
  test_name: "Nombre de la Prueba",
  gender: "Género",
  action: "Acción",
  both: "Ambos",
  male: "Hombre",
  female: "Mujer",
  link_units: "Vincular Unidades",
  view_booked_appointment: "Ver Cita Reservada",
  slots_date_from: "Fecha de los espacios desde",
  booked: "Reservado",
  slots_unavailable: "Espacios No Disponibles",
  slots: "Espacios",
  no_of_slots: "No. de ranuras",
  external_report: "Informe Externo",
  add_parameter: "Agregar Parámetro",
  consultation_list: "Lista de Consultas",
  from_date: "Desde la Fecha",
  to_date: "Hasta la Fecha",
  no_consultation_data_is_found: "No se encontraron datos de consulta",
  unit_name: "Nombre de la Unidad",
  unit_contact_number: "Número de Contacto de la Unidad",
  consultation_start_time: "Hora de Inicio de la Consulta",
  consultation_end_time: "Hora de Fin de la Consulta",
  prescription_time: "Hora de la Prescripción",
  waiting_time: "Tiempo de Espera",
  reason: "Razón",
  please_enter_a_reason_for_cancellation:
    "Por favor, ingrese una razón para la cancelación",
  Consultation_canceled_successfully: "Consulta cancelada con éxito",
  failed_to_cancel_the_consultation_please_try_again:
    "Error al cancelar la consulta. Por favor, inténtelo de nuevo.",
  an_unexpected_error_occurred_please_try_again_later:
    "Ocurrió un error inesperado. Por favor, inténtelo más tarde.",
  reschedule_appointment: "Reprogramar Cita",
  reschedule_date_on_before_after: "Reprogramar Fecha en/Antes/Después",
  mark_as_unavailable: "Marcar como No Disponible",
  remarks: "Observaciones",
  mark_as_available: "Marcar como Disponible",
  mark_slots_for_unavailability: "Marcar plazas como No Disponibles",
  select_all_for_unavailability: "Seleccionar Todo Para No Disponibilidad",
  please_check_reschedule_before_proceeding:
    "Por favor, revise Reprogramar antes de continuar",
  please_enter_a_reason_for_reschedule:
    "Por favor, ingrese una razón para la reprogramación",
  request_slot_reschedule_successful:
    "Solicitud de reprogramación de plaza exitosa",
  failed_to_reschedule_slot: "Error al reprogramar la plaza",
  an_error_occurred_while_rescheduling_the_slot:
    "Ocurrió un error al reprogramar la plaza",
  please_check_mark_as_unavailable_before_proceeding:
    "Por favor, revise Marcar como No Disponible antes de continuar",
  please_enter_remarks: "Por favor, ingrese observaciones",
  slot_marked_as_unavailable_successfully:
    "Plaza marcada como No Disponible con éxito",
  failed_to_mark_slot_as_unavailable:
    "Error al marcar la plaza como No Disponible",
  an_error_occurred_while_making_the_slot_unavailable:
    "Ocurrió un error al marcar la plaza como No Disponible",
  slotAlreadyBooked:
    "La plaza {{fromTime}} - {{toTime}} ya está reservada, por favor reprogramarla",
  slot_availability_unavailability_is_updated:
    "La disponibilidad/no disponibilidad de la plaza ha sido actualizada",
  an_error_occurred_while_updating_slots:
    "Ocurrió un error al actualizar las plazas",
  please_check_mark_as_available_before_proceeding:
    "Por favor, revise Marcar como Disponible antes de continuar",
  slot_marked_as_available_successfully:
    "Plaza marcada como Disponible con éxito",
  failed_to_mark_slot_as_available: "Error al marcar la plaza como Disponible",
  units: "Unidades",
  blood_group: "Grupo Sanguíneo",
  select_relationship: "-- Seleccionar Relación --",
  record_already_exist: "El registro ya existe",
  please_enter_cancellation_reason:
    "Por favor, ingrese la razón de cancelación",
  disMessage:
    "*Al presionar continuar, los datos del paciente anterior reemplazarán los datos del paciente asociados con el correo electrónico {{emailId}} con el que se reservó esta cita, tenga en cuenta que esto afectará a todas las demás citas reservadas con {{emailId}}.",
  the_provided_facial_biometrics_will_be_registered_with_the_account_details_shown_below:
    "Las biometrías faciales proporcionadas se registrarán con los detalles de la cuenta que se muestran a continuación",
  select_week: "Seleccionar Semana",
  associated_face: "rostro asociado",
  experience: "Experiencia",
  fees: "Honorarios",
  language: "Idioma",
  patient_in_queue: "Paciente en cola",
  substance_abuse: "Abuso de sustancias",
  doctor_switched: "Doctor cambiado",
  doctor_canceled_meeting: "Doctor canceló la reunión",
  please_get_vitals: "Por favor, obtenga signos vitales",
  consultation_ended: "Consulta Finalizada",
  please_enter_room_id: "Por favor, ingrese ID de la sala",
  meeting_with_provided_room_number_does_not_exists_please_check_once:
    "La reunión con el número de sala proporcionado no existe, por favor revise una vez",
  this_meeting_opens_5_minutes_before_the_start_time_please_join_after:
    "Esta reunión se abre 5 minutos antes de la hora de inicio, por favor únase después",
  invalid_room_number_room_number_can_have_only_digits:
    "Número de sala inválido, el número de sala solo puede tener dígitos",
  starting_instant_meeting_please_wait:
    "Iniciando reunión instantánea, por favor espere...",
  fileUploadMessage:
    "{{userName}} ha subido un nuevo archivo, por favor verifique...",
  the_webinar_has_ended: "El seminario web ha terminado",
  end_meeting_for_all: "¡Terminar la reunión para todos!",
  leave_meeting: "Salir de la Reunión",
  screen_sharing_on: "Compartiendo pantalla",
  screen_sharing_off: "Compartir pantalla desactivado",
  please_unmute_to_speak: "Por favor, active el sonido para hablar",
  whiteboardOpenedMessage: "{{userName}} ha abierto la pizarra",
  you_have_been_muted: "Se te ha silenciado",
  you_have_been_unmuted: "Se te ha activado el sonido",
  video_switched_off: "Video apagado",
  appointmentMessage:
    "{{fullName}} ha programado una consulta con {{doctorName}} el {{date}} de {{fromTime}} a {{toTime}}. Por favor asegúrese de llegar al centro al menos 15 minutos antes de su hora de cita para evitar retrasos y permitir un proceso de consulta sin problemas.",
  please_enter_contact_number: "Por favor, ingrese número de contacto",
  you_cannot_record_audio_for_more_than_120_sec:
    "No puedes grabar audio por más de 120 segundos",
  you_cannot_record_for_more_than_15_secs:
    "No puedes grabar por más de 15 segundos",
  playing_audio_note: "Reproduciendo nota de audio",
  record_your_voice_notes: "Graba tus notas de voz",
  processing_your_recording: "Procesando tu grabación",
  recorded_audio_note: "Nota de audio grabada",
  the_voice_recording_limit_is_restricted_to_2_minutes:
    "El límite de grabación de voz está restringido a 2 minutos.",
  delete_audio_note: "Eliminar Nota de Audio",
  external_report: "Informe Externo",
  collection_date_time: "Fecha y Hora de Recolección",
  please_select_a_past_time: "Por favor, seleccione una hora pasada",
  sample_type: "Tipo de Muestra",
  blood: "Sangre",
  urine: "Orina",
  sputum: "Esputo",
  reagent: "Reactivo",
  enter_reagent: "Ingrese Reactivo",
  methodMessage: "Método: {{method}}",
  value: "Valor",
  eg_120: "ej:120",
  desirable_value: "Deseable: < 150",
  borderlinehigh: "Límite Alto: 150-199",
  high: "Alto: 200-499",
  veryhigh: "Muy Alto: > 500",
  parameter_name: "Nombre del Parámetro",
  method: "Método",
  enter_method: "Ingrese Método",
  reference_range: "Rango de Referencia",
  please_enter_test_name: "Por favor, ingrese el nombre de la prueba",
  upload_report: "Subir Informe",
  collection_time_cannot_be_empty:
    "La hora de recolección no puede estar vacía",
  no_test_data_provided: "No se proporcionaron datos de prueba",
  test_result_submitted_successfully:
    "Resultado de la prueba enviado con éxito",
  value_not_provided: "Valor no proporcionado",
  you_have_not_provided_values_for_some_of_the_parameters_please_provide_value_or_delete_the_parameter:
    "No has proporcionado valores para algunos de los parámetros. Por favor proporcione un valor o elimine el parámetro.",
  doctor_image: "Imagen del Doctor",
  conversation: "Conversación",
  doctor_info: "Información del Doctor",
  patient_info: "Información del Paciente",
  update_comment_here: "Actualice el comentario aquí",
  message_sent_successfully: "Mensaje enviado con éxito",
  feedback: "Comentarios",
  thank_you: "Gracias",
  with_rating: "Calificación: {{rating}} / 5",
  no_rating: "No se seleccionó ninguna calificación",
  differential_diagnosis: "Diagnóstico Diferencial",
  generic_medicines: "Medicinas Genéricas",
  brand: "Marca",
  drug_safety: "Seguridad de Medicamentos",
  no_data_found: "No se encontraron datos",
  search_food: "Buscar comida",
  history_and_risk_factor: "Historia y Factor de Riesgo",
  select_since: "--Seleccionar Desde--",
  past_illnesses: "Enfermedades Pasadas",
  previous_procedure: "Procedimiento Anterior",
  please_select_surgery_date: "Por favor, seleccione la fecha de cirugía",
  investigations: "Investigaciones",
  reported_date: "Fecha Reportada",
  search_generic_medicine: "Buscar Medicina Genérica",
  branded_medicine: "Medicina de Marca",
  select_route: "--Seleccionar Ruta--",
  message: "Mensaje",
  update_comment_here: "Actualice el comentario aquí",
  enter_your_message: "Ingrese su mensaje",
  message_sent_successfully: "Mensaje enviado con éxito",
  procedure: "Procedimiento",
  please_select_surgery_date: "Por favor, seleccione la fecha de cirugía",
  refer_patient: "Referir Paciente",
  physical_examination: "Examen Físico",
  select_system: "Seleccionar Sistema",
  select_sub_system: "Seleccionar Sub Sistema",
  select_examination: "Seleccionar Examen",
  select_parameter: "Seleccionar Parámetro",
  select_issue: "Seleccionar Problema",
  others: "Otros",
  other_observations: "Otras observaciones",
  other_observation_field_cannot_be_empty:
    "El campo de otras observaciones no puede estar vacío",
  examination_name: "Nombre del Examen",
  loading_differential_diagnosis_please_wait:
    "Cargando diagnóstico diferencial, por favor espere...",
  please_enter_some_symptoms: "Por favor, ingrese algunos síntomas",
  no_differential_diagnosis_can_be_recommended_against_the_provided_symptoms_and_patient_data:
    "No se puede recomendar ningún diagnóstico diferencial con los síntomas proporcionados y los datos del paciente",
  differential_diagnosis_loaded_successfully:
    "Diagnóstico diferencial cargado con éxito",
  disease_name: "Nombre de la Enfermedad",
  something_went_wrong: "Algo salió mal...",
  systemic_illness: "Enfermedad Sistémica",
  document_uploaded_successfully: "Documento subido con éxito",
  document_upload_unsuccessful: "Subida del documento fallida",
  provisional: "Provisional",
  sign: "Firmar",
  oops_doctor_not_provide_any_data:
    "¡Vaya! El doctor no proporcionó ningún dato",
  test_result: "Resultado de la Prueba",
  invalidFileType: "Tipo de archivo inválido de '{{fileName}}'",
  fileTooLarge: "El archivo '{{fileName}}' es muy grande.",
  fileTooSmall: "El archivo '{{fileName}}' es demasiado pequeño.",
  genericMedicine: "Medicina Genérica",
  attach_license_document: "Adjuntar Documento de Licencia",

  /////////////////////////////////////// TASK ////////////////////////////////

  add_item: "Agregar Ítem",
  item_type: "Tipo de Ítem",
  goods: "Bienes",
  services: "Servicios",
  item_name: "Nombre del Ítem",
  description: "Descripción",
  category: "Categoría",
  add_category: "Agregar Categoría",
  defult_uom: "Unidad de Medida Predeterminada",
  add_uom: "Agregar Unidad de Medida",
  manufacturer: "Fabricante",
  add_manufacturer: "Agregar Fabricante",
  hsn_code: "Código HSN",
  expirable: "Caducable",
  guarantee_warantee_sla: "Garantía/Certificación/SLA",
  is_tangible: "Es Tangible",
  tangible: "Tangible",
  intangible: "Intangible",
  Uom: "Unidad de Medida",
  item_manufacturer: "Fabricante del Ítem",
  please_provide_item_name: "Por favor, proporcione el nombre del ítem",
  please_provide_item_description:
    "Por favor, proporcione la descripción del ítem",
  please_provide_category: "Por favor, proporcione la categoría",
  please_provide_item_type: "Por favor, proporcione el tipo de ítem",
  please_provide_uom: "Por favor, proporcione la unidad de medida",
  please_provide_item_manufacturer:
    "Por favor, proporcione el fabricante del ítem",
  please_enter_item_manufacturer: "Por favor, ingrese el fabricante del ítem",
  please_enter_item_uom: "Por favor, ingrese la unidad de medida del ítem",
  please_enter_category_name: "Por favor, ingrese el nombre de la categoría",

  ////////////////////////// Video meeting//////////////////

  breakout_rooms: "Salas de Grupo",
  create_breakout_room: "Crear Sala de Grupo",
  room_name: "Nombre de la Sala",
  join_leave: "Unirse / Abandonar",
  participants: "Participantes",
  joiningRoom: "Uniéndose a la sala de grupo {{roomName}}",
  joiningMainroom: "Uniéndose a la sala principal {{roomName}}",
  no_participant_is_present_in_breakout_room:
    "No hay participantes en la sala de grupo {{roomName}}",
  please_enter_breakout_room_name:
    "Por favor, ingrese el nombre de la sala de grupo",
  breakout_room_created: "Sala de grupo {{breakoutRoomName}} creada",
  cancel_consultation: "Cancelar Consulta",
  remark: "Comentario",
  cancellation_reason_cannot_be_empty:
    "El motivo de la cancelación no puede estar vacío",
  consultation_cancelled_successfully: "Consulta cancelada con éxito",
  consultation_cannot_be_cancelled: "La consulta no puede ser cancelada",
  consultationWithClinic: "Consulta con {{clinicName}}",
  file_share: "Compartir Archivo",
  upload_files: "Subir Archivos",
  information: "Información",
  max_file_size: "Tamaño Máximo del Archivo",
  allowed_file_types: "Tipos de Archivos Permitidos",
  drag_and_drop_files_here: "Arrastre y suelte archivos aquí",
  select_files_to_upload: "Seleccione archivos para subir",
  files_added: "Archivos Agregados",
  please_select_a_file_to_upload: "Por favor, seleccione un archivo para subir",
  file_size_exceeds_the_limit: "El tamaño del archivo excede el límite (10MB)",
  files_uploaded_successfully: "Archivo(s) subido(s) con éxito",
  fileSizeError: "El archivo '{{fileName}}' excede el límite de tamaño (5MB)",
  file_invalid_extension:
    "El archivo {{fileName}} tiene una extensión no válida",
  downloading_file_please_wait: "Descargando archivo, por favor espere",
  invite_user: "Invitar Usuario",
  add_guests: "Agregar Invitados",
  please_type_to_search: "Por favor, escriba para buscar",
  belongs_to_your_organization: "Pertenece a su organización",
  send_invite: "Enviar Invitación",
  sending_Call_invite_please_wait:
    "Enviando invitación a la llamada, por favor espere...",
  Call_invite_sent: "Invitación a la llamada enviada",
  joining_call_please_wait: "Uniéndose a la llamada, por favor espere...",
  your_join_meeting_request_was_rejected_by_the_moderator:
    "Su solicitud de unirse a la reunión fue rechazada por el moderador",
  meeting_has_ended_and_no_one_is_in_the_meeting:
    "La reunión ha terminado y no hay nadie en la reunión",
  the_webinar_has_been_closed_by_the_convenor:
    "El seminario web ha sido cerrado por el convocador",
  meeting_notes: "Notas de la Reunión",
  add_private_note: "Agregar nota privada",
  private_notes: "Notas privadas",
  audio_note: "Nota de audio",
  no_meeting_notes_present: "No hay notas de la reunión presentes",
  please_enter_private_notes: "Por favor, ingrese notas privadas",
  unable_to_attend: "Incapaz de asistir",
  please_change_the_convenor_chairperson_first:
    "Por favor, cambie al convocador/presidente primero",
  mark_unavailability_for: "Marcar No Disponibilidad para {{eventTitle}}",
  please_select_unavailability_reason:
    "Por favor seleccione el motivo de la no disponibilidad",
  another_meeting: "Otra reunión",
  sick_leave: "Baja por enfermedad",
  leave: "Permiso",
  travel: "Viaje",
  not_relevant_to_me: "No es relevante para mí",
  other: "Otro",
  please_type_your_unavailability_reason:
    "Por favor, escriba el motivo de su no disponibilidad....",
  delete_event_title: "Eliminar evento {{eventTitle}}",
  cancel_all_recurring_events: "Cancelar todos los eventos recurrentes",
  attend: "Asistir",
  save_changes: "Guardar cambios",
  following_changes_made_will_not_be_saved_discard_changes:
    "Los siguientes cambios realizados no se guardarán, ¿descartar los cambios?",
  re_scheduled_the_meeting: "Reprogramó la reunión",
  added_participants: "Participantes añadidos",
  deleted_participants: "Participantes eliminados",
  added_private_notes: "Notas privadas añadidas",
  deleted_private_notes: "Notas privadas eliminadas",
  changed_the_convenor: "Cambiado el convocador",
  changed_the_chairperson: "Cambiado el presidente",
  cancellingEvent: "Cancelando {{eventType}}, por favor espere...",
  cannotCancelEvent:
    "No puede cancelar el {{eventType}} dentro de los 5 minutos o después de la hora de inicio del {{eventType}}",
  cannotMarkUnavailable:
    "No puede marcarse como no disponible para el {{eventType}} dentro de los 5 minutos o después de la hora de inicio del {{eventType}}",
  please_select_a_reason_for_unavailability:
    "Por favor seleccione un motivo de no disponibilidad",
  please_give_some_unavailability_reason:
    "Por favor, proporcione una razón para la no disponibilidad...",
  updatingUnavailability:
    "Actualizando la no disponibilidad para {{eventType}}, por favor espere...",
  user_marked_unavailable: "Usuario marcado como no disponible",
  availability_marked_successfully: "Disponibilidad marcada con éxito",
  cannotEditEvent:
    "No puede editar el {{eventType}} dentro de los 5 minutos o después de la hora de inicio del {{eventType}}",
  type_meeting_title: "Escriba el título de la reunión",
  to: "A",
  reschedule_reason: "Motivo de reprogramación",
  agenda: "Agenda",
  available: "Disponible",
  unavailable: "No disponible",
  belongs_to_your_organization: "Pertenece a su organización",
  participantUnavailable:
    "{{userName}} no está disponible para la reunión, no se pueden transferir los derechos del convocador",
  participantUnavailableChairperson:
    "{{userName}} no está disponible para la reunión, no se pueden transferir los derechos del presidente",
  removeFromGuestList: "¿Eliminar a {{userName}} de la lista de invitados?",
  remove_for_all_recurring_events:
    "Eliminar para todos los eventos recurrentes",
  add_new_participants_for_all_related_recurring_events:
    "Agregar nuevos participantes para todos los eventos recurrentes relacionados",
  convenor_chairperson_needs_to_be_changed_individually_for_each_recurring_event:
    "El convocador/presidente debe cambiarse individualmente para cada evento recurrente",
  type_here: "Escriba aquí....",
  add_agenda: "Agregar agenda",
  attach_file: "Adjuntar archivo",
  cannotSaveChanges:
    "No puede guardar cambios en el {{eventType}} dentro de los 5 minutos o después de la hora de inicio del {{eventType}}",
  please_enter_the_reason_for_rescheduling_the_meeting:
    "Por favor, ingrese el motivo de la reprogramación de la reunión",
  saving_changes_please_wait: "Guardando cambios, por favor espere...",
  savingChanges:
    "Guardando los siguientes cambios, {{textToDisplay}} por favor espere...",
  meeting_room: "Sala de reuniones",
  more: "Más",
  transcription_details: "Detalles de la transcripción",
  select_spoken_languages: "Seleccionar idiomas hablados",
  participants: "Participantes",
  meeting_notes: "Notas de la reunión",
  microphones: "Micrófonos",
  speakers: "Altavoces",
  video_camera: "Cámara de video",
  grid_view: "Vista de cuadrícula",
  virtual_background: "Fondo virtual",
  file_share: "Compartir archivo",
  breakout_rooms: "Salas de grupos",
  join: "Unirse",
  enteredRoomMessage:
    "Ha introducido un número de sala, por favor use 'unirse'",
  start_instant_meeting: "Iniciar reunión instantánea",
  meeting_agendas: "Agendas de la reunión",
  subject: "Asunto",
  details: "Detalles",
  p_s: "P/S",
  files: "Archivos",
  file_not_allowed_please_upload_a_valid_file:
    "Archivo no permitido. Por favor suba un archivo válido.",
  failed_to_convert_file: "Error al convertir el archivo.",
  selectPositionEffectiveDate:
    "Por favor, seleccione tanto la Posición como la Fecha de Efectividad para la Posición Adicional en la Posición Adicional {{index}}",
  selectEffectiveDate:
    "Por favor, seleccione la Fecha de Efectividad para la Posición Adicional en la Posición Adicional {{index}}",
  selectPosition:
    "Por favor, seleccione la Posición para la Posición Adicional en la Posición Adicional {{index}}",
  invalid_employee_id_it_should_start_with_uppercase_letters_and_end_with_numbers:
    "ID de empleado no válido. Debe comenzar con letras mayúsculas y terminar con números.",
  employee_id_already_present: "ID de empleado ya presente",
  please_change_the_title_for_doctor:
    "Por favor, cambie el título a 'Dr.' o 'Prof.' para un Doctor",
  file_uploaded_successfully: "File uploaded successfully es lang",
  select_spoken_language: "Seleccionar idioma hablado",
  select_translate_language: "Seleccionar idioma de traducción",
  atleast_one_language_is_mandatory: "Al menos un idioma es obligatorio",
  maximum_three_languages_can_be_selected:
    "Se pueden seleccionar un máximo de 3 idiomas",
  please_select_atleast_one_language:
    "Por favor, seleccione al menos un idioma",
  spoken_language_updated_successfully:
    "Idioma hablado actualizado correctamente",
  joining_meeting_please_wait: "Uniéndose a la reunión, por favor espere",
  share_link: "Compartir enlace",
  gmail: "Gmail",
  facebook: "Facebook",
  twitter: "Twitter",
  reddit: "Reddit",
  whatsapp: "Whatsapp",
  copy_link: "Copiar enlace",
  copy_room_number: "Copiar número de sala",
  meeting_transcription: "Transcripción de la reunión",
  translate_all: "Traducir todo",
  translate: "Traducir",
  load_subtitles: "Cargar subtítulos",
  load_more: "Cargar más",
  no_subtitles_present: "No hay subtítulos disponibles",
  subtitles_loaded_successfully: "Subtítulos cargados con éxito",
  no_more_subtitles_present: "No hay más subtítulos disponibles",
  downloading_transcriptions_please_wait:
    "Descargando transcripciones, por favor espere",
  generate_mom: "Generar MOM",
  update_mom: "Actualizar MOM",
  write_about_your_product_here: "Escriba sobre su producto aquí...",
  download_pdf: "Descargar PDF",
  could_not_generate_mom: "No se pudo generar el MOM",
  webinar_public_chat: "Chat público del seminario web",
  type_your_message_here: "Escriba su mensaje aquí!",
  please_enter_some_message: "Por favor, ingrese algún mensaje",
  whiteboard: "Pizarra",

  //////////profile //////

  Profile: "Perfil",
  register_face: "Registrar rostro",
  photosensitivity_warning:
    "Advertencia de fotosensibilidad: esta verificación parpadea con diferentes colores. Use precaución si es fotosensible. Algunas personas pueden experimentar convulsiones epilépticas cuando se exponen a luces de colores. Use precaución si usted o alguien en su familia tiene una condición epiléptica.",
  default_landing_tab: "Pestaña de inicio predeterminada",
  marital_status: "Estado civil",
  dashboard: "Tablero",
  inbox: "Bandeja de entrada",
  apps: "Aplicaciones",
  click_pencil_button_to_edit_and_save:
    "Haga clic en el botón del lápiz para editar y guardar",
  employee_id: "ID de empleado",
  department: "Departamento",
  position: "Posición",
  date_of_joining: "Fecha de ingreso",
  reporting_manager: "Gerente informante",
  email_id: "ID de correo electrónico",
  select_languages: "Seleccionar idiomas",
  languages: "Idiomas",
  skills: "Habilidades",
  date_of_birth: "Fecha de nacimiento",
  select_marital_status: "Seleccionar estado civil",
  single: "Soltero",
  married: "Casado",
  divorced: "Divorciado",
  widowed: "Viudo",
  personal_contact_no: "Número de contacto personal",
  official_contact_no: "Número de contacto oficial",
  emergency_number: "Número de emergencia",
  old_password: "Contraseña anterior",
  new_password: "Nueva contraseña",
  password: "Contraseña",
  confirm_password: "Confirmar contraseña",
  password_policy: "Política de contraseña",
  new_and_confirm_password_should_match:
    "La nueva contraseña y la confirmación deben coincidir",
  at_least_one_uppercase_letter: "Al menos una letra mayúscula",
  at_least_one_lowercase_letter: "Al menos una letra minúscula",
  at_least_one_special_character: "Al menos un carácter especial",
  at_least_one_number_character: "Al menos un carácter numérico",
  password_between_8_and_20_characters: "Contraseña de entre 8 y 20 caracteres",
  reset_password: "Restablecer contraseña",
  update_password: "Actualizar contraseña",
  profile_sign_details:
    "Formato de archivo permitido PNG, JPG o JPEG y tamaño entre 10 KB y 200 KB. Dimensiones de la imagen, altura de 50 px a 512 px, con fondo transparente o blanco.",
  no_sign_avalable: "¡No hay firma disponible!",
  click_here_to_upload_sign: "Haga clic aquí para cargar la firma",
  re_upload_sign: "Volver a cargar firma",
  user_full_name: "Nombre completo del usuario",
  second_name: "Segundo nombre",
  last_name: "Apellido",
  update_name: "Actualizar nombre",
  invalid_file_format:
    "Formato de archivo no válido. Formatos permitidos: PNG, JPG, JPEG.",
  file_size_must_be_between_10KB_and_200KB:
    "El tamaño del archivo debe estar entre 10 KB y 200 KB.",
  image_height_must_be_between_50px_and_512px:
    "La altura de la imagen debe estar entre 50 px y 512 px.",
  profile_signature_uploaded_successfully: "Firma de perfil cargada con éxito",
  an_error_occurred_during_file_upload_please_try_again_later:
    "Ocurrió un error durante la carga del archivo. Inténtelo de nuevo más tarde.",
  the_password_should_be_atleast_8_characters:
    "La contraseña debe tener al menos 8 caracteres.",
  the_password_should_be_at_most_20_characters:
    "La contraseña debe tener como máximo 20 caracteres.",
  the_password_should_contain_atleast_one_special_character:
    "La contraseña debe contener al menos un carácter especial.",
  the_password_should_contain_at_least_one_numeric_letter:
    "La contraseña debe contener al menos una letra numérica.",
  the_password_should_contain_at_least_one_uppercase_letter:
    "La contraseña debe contener al menos una letra mayúscula.",
  the_password_should_contain_at_least_one_lowercase_letter:
    "La contraseña debe contener al menos una letra minúscula.",
  new_password_and_confirm_password_should_match:
    "La nueva contraseña y la confirmación deben coincidir.",
  new_password_and_confirm_password_should_be_the_same:
    "La nueva contraseña y la confirmación deben ser iguales",
  new_password_should_not_match_the_old_password:
    "La nueva contraseña no debe coincidir con la contraseña anterior",
  password_must_contain_at_least_one_digit:
    "La contraseña debe contener al menos un dígito",
  an_error_occurred_while_updating_the_data:
    "Ocurrió un error al actualizar los datos.",
  no_changes_made: "No se hicieron cambios",
  official_contact_no_cannot_be_less_than_10_character_long:
    "El número de contacto oficial no puede tener menos de 10 caracteres",
  contact_no_cannot_be_less_than_10_character_long:
    "El número de contacto no puede tener menos de 10 caracteres",
  you_cannot_add_more_then_3_languages: "No puede agregar más de 3 idiomas",
  profile_image_upload: "Cargar imagen de perfil",
  prebuild_avatar: "Avatar preconstruido",
  upload_pic: "Subir foto",
  zoom_out: "Alejar",
  zoom_in: "Acercar",
  face_liveness_passed: "Prueba de vivacidad facial pasada....",
  checking_facial_biometrics_uniqueness:
    "Verificando la unicidad de biometría facial...",
  facial_biometric_uniqueness_confirmed:
    "Unicidad de biometría facial confirmada, registrando rostro ahora...",
  facial_biometrics_added_successfully:
    "Biometría facial agregada a la cuenta con éxito",
  biometric_associated_with_another_account:
    "Estas biometrías están asociadas con otra cuenta, continúe con esa cuenta.",
  facial_biometrics_added_to_account_successfully:
    "Biometría facial agregada a la cuenta con éxito",
  deleting_face_please_wait: "Eliminando rostro, por favor espere...",
  face_deleted_successfully: "Rostro eliminado con éxito",
  cancel_facial_biometrics_registration:
    "¿Cancelar el proceso de registro de biometría facial?",
  your_facial_biometrics_are_already_registered_you_can_delete_and_register_again:
    "Sus biometrías faciales ya están registradas, puede eliminarlas y registrarse nuevamente",
  confirm_facial_biometrics_deletion:
    "¿Confirmar la eliminación de biometría facial?",
  delete_face: "Eliminar rostro",

  //  ////////    <---------- MENU -------->

  quick_actions: "Acciones Rápidas",
  create_task: "Crear Tarea",
  create_a_new_task: "Crear una nueva tarea",
  scheduler: "Programador",
  create_a_new_event: "Crear un nuevo evento",
  instant_meeting: "Reunión Instantánea",
  start_instant_meeting: "Iniciar reunión instantánea",
  Apps: "Aplicaciones",
  task: "Tarea",
  Chat: "Charlar",
  meeting_room: "Sala de Reunión",
  drive: "Drive",
  support_dashboard: "Panel de Soporte",
  Admin: "Administrador",
  vendor_menu: "Menú del Proveedor",
  inbox: "Bandeja de Entrada",
  dashboard: "Panel de Control",
  home: "Inicio",
  whiteboard: "Pizarra",

  // <------- Task ------->

  add_terms_and_condition: "Agregar Términos y Condiciones",
  item_name: "Nombre del Artículo",
  description: "Descripción",
  variance: "Varianza",
  indent_qty: "Cantidad de Indent",
  quotation_qty: "Cantidad de Cotización",
  quotation_rate: "Tasa de Cotización",
  tax: "Impuesto",
  quotation_value: "Valor de Cotización",
  variance_speciation: "Especificación de Varianza",
  remove: "Eliminar",
  charge_description: "Descripción del Cargo",
  price: "Precio",
  add_charge: "Agregar Cargo",
  add_other_charges: "Agregar Otros Cargos",
  banking_details: "Detalles Bancarios",
  t_and_c: "Términos y Condiciones",
  delivery_date: "Fecha de Entrega",
  advance_needed: "Anticipo Necesario",
  advance_payment_in_percentage: "Pago Anticipado (en %)",
  add_variance: "Agregar Varianza",
  variance_specification: "Especificación de Varianza",
  add_tax_details: "Agregar Detalles de Impuestos",
  sgst_percentage: "SGST %",
  sgst_amount: "Monto SGST",
  cgst_percentage: "CGST %",
  cgst_amount: "Monto CGST",
  igst_percentage: "IGST %",
  igst_amount: "Monto IGST",
  terms_and_conditions: "Términos y Condiciones",
  sl_no: "N.º",
  type: "Tipo",
  heading: "Encabezado",
  rejection_reason: "Motivo de Rechazo",
  accept_reject: "Aceptar/Rechazar",
  reject: "Rechazar",
  delete_terms_and_conditions: "Eliminar Términos y Condiciones",
  add_vendor_t_and_c: "Agregar Términos del Proveedor",
  activity: "Actividad",
  select_type: "Seleccionar Tipo",
  condition: "Condición",
  advance: "Anticipo",
  expected_cost: "Costo Esperado",
  budget_head: "Encabezado de Presupuesto",
  create_pms_request: "Crear Solicitud de PMS",
  exit_full_screen: "Salir de Pantalla Completa",
  Enter_full_screen: "Entrar en Pantalla Completa",
  pms_period_from: "Período de PMS desde",
  pms_period_to: "Período de PMS hasta",
  expected_effective_date: "Fecha Efectiva Esperada",
  applicable_for_employees_who_joined_before:
    "Aplicable a empleados que ingresaron antes de",
  numbers_of_effected_employees: "Cantidad de empleados afectados",
  already_pms_done_count: "Cantidad de PMS ya realizados",
  numbers_of_employees_who_joined_30_days_before_applicable_join_date:
    "Cantidad de empleados que ingresaron 30 días antes de la fecha aplicable",
  select_evaluation_metrics: "Seleccionar métricas de evaluación",
  rating: "Calificación",
  text: "Texto",
  both: "Ambos",
  rating_out_of: "Calificación de",
  preview: "Vista Previa",
  my_task: "Mi Tarea",
  search_or_find_task: "Buscar o encontrar tarea",
  reassign: "Reasignar",
  update: "Actualizar",
  concern: "Preocupación",
  approve: "Aprobar",
  postpone: "Posponer",
  reject: "Rechazar",
  delegate: "Delegar",
  sign_off: "Cerrar",
  disagree: "No estar de acuerdo",
  forward: "Reenviar",
  address_concern: "Atender Preocupación",
  create_task: "Crear Tarea",
  task: "Tarea",
  description: "Descripción",
  type: "Tipo",
  contract: "Contrato",
  status: "Estado",
  priority: "Prioridad",
  completion_date: "Fecha de Finalización",
  created_by_assigned_by: "Creado por/Asignado por",
  mark_ticket_resolved: "Marcar Ticket como Resuelto",
  confirmation: "Confirmación",
  please_give_closing_remarks: "Por favor, dar comentarios de cierre",
  ticket_resolved_remark: "Comentario de Ticket Resuelto",
  play_audio_message: "Reproducir Mensaje de Audio",
  timeline: "Cronología",
  form_data: "Datos del Formulario",
  regular: "Regular",
  important: "Importante",
  critical: "Crítico",
  activity: "Actividad",
  assigned_by: "Asignado por",
  me: "Yo",
  last_updated: "Última actualización",
  send_message: "Enviar Mensaje",
  delegate_task: "Delegar Tarea",
  reason_for_delegation: "Motivo de Delegación",
  please_type_recipient_name: "Por favor, ingrese el nombre del destinatario",
  assign_recipient: "Asignar Destinatario",
  meeting_list: "Lista de Reuniones",
  create_new_meeting: "Crear Nueva Reunión",
  meetingAt: "Reunión en {{date}}",
  create_link_task: "Crear Tarea Enlazada",
  linked_task_name: "Nombre de Tarea Enlazada",
  completion_date: "Fecha de Finalización",
  completion_time: "Hora de Finalización",
  assign_user: "Asignar Usuario",
  add_concern_here: "Agregar Preocupación Aquí",
  detail_the_concern: "Detallar la Preocupación",
  assignee_of_task: "Asignado a Tarea",
  belongs_to_your_organization: "Pertenece a su organización",
  audio_message: "Mensaje de Audio",
  templates: "Plantillas",
  selected_templates: "Plantillas Seleccionadas",
  suggested_templates: "Plantillas Sugeridas",
  view_all: "Ver Todo",
  more_templates: "Más Plantillas",
  search_or_find_by_name: "Buscar o encontrar por nombre",
  search: "Buscar",
  select_template: "Seleccionar Plantilla",
  sign_off: "Cerrar",
  disagree: "No estar de acuerdo",
  contract: "Contrato",
  timeline: "Cronología",
  priority: "Prioridad",
  approval_remark: "Comentario de Aprobación",
  last_updated: "Última actualización",
  update_comment_here: "Actualizar comentario aquí",
  address_concern: "Atender Preocupación",
  concern_remark: "Comentario de Preocupación",
  reassing_task: "Reasignar Tarea",
  select_your_progress_of_task: "Seleccionar su Progreso de Tarea",
  reassign_reason: "Motivo de Reasignación",
  assign_user: "Asignar Usuario",
  reason_over_disagreement: "Motivo de Desacuerdo",
  enter_reason: "Ingresar Motivo",
  select_your_progress_of_task: "Seleccionar su Progreso de Tarea",
  uploded_files: "Archivos Subidos",
  rfp_preview: "Vista Previa de RFP",
  indent_no: "No. de Indent",
  purpose: "Propósito",
  created_by: "Creado por",
  approved_by: "Aprobado por",
  service_name: "Nombre del Servicio",
  item_name: "Nombre del Artículo",
  description_specification_mandatory: "Descripción/Especificación obligatoria",
  desc_spec: "Desc./Espec.",
  budget_head: "Encabezado de Presupuesto",
  uom: "UOM",
  stock_qty: "Cantidad de Stock",
  indent_qty: "Cantidad de Indent",
  estimated_rate: "Tasa Estimada",
  estimated_value: "Valor Estimado",
  select_vendors: "Seleccionar Proveedores",
  response_date: "Fecha de Respuesta",
  terms_and_condition: "Términos y Condiciones",
  send: "Enviar",
  rfp_sent_proposal_awaited: "Propuesta de RFP enviada, en espera",
  quotation_submitted: "Cotización enviada",
  add_quotation: "Agregar Cotización",
  compare: "Comparar",
  terms_and_conditions: "Términos y Condiciones",
  select_payment_mode: "Seleccionar Modo de Pago",
  banking_method: "Método Bancario",
  select_activity: "Seleccionar Actividad",
  bank_account: "Cuenta Bancaria",
  upi: "UPI",
  cheque: "Cheque",
  account_details: "Detalles de la Cuenta",
  account_holder_name: "Nombre del Titular de la Cuenta",
  bank_name: "Nombre del Banco",
  bank_branch: "Sucursal Bancaria",
  account_no: "N.º de Cuenta",
  ifsc_code: "Código IFSC",
  swift_code: "Código SWIFT",
  sort_code: "Código de Clasificación",
  add_new_bank_account: "Agregar Nueva Cuenta Bancaria",
  payee_upi_ids: "UPI ID del Beneficiario",
  add_new_upi_id: "Agregar Nuevo ID UPI",
  payee_name_for_cheque: "Nombre del Beneficiario para Cheque",
  add_new_payee_name: "Agregar Nuevo Nombre del Beneficiario",
  add_banking_details: "Agregar Detalles Bancarios",
  accounts_no: "N.º de Cuenta",
  branch_name: "Nombre de la Sucursal",
  compare_quotation: "Comparar Cotización",
  download_vendor_quotation: "Descargar Cotización del Proveedor",
  item_description: "Descripción del Artículo",
  quantity: "Cantidad",
  rate: "Tasa",
  discount: "Descuento",
  delivery_terms: "Términos de Entrega",
  add_tax_details: "Agregar Detalles de Impuestos",
  sub_total: "Subtotal",
  grand_total: "Total General",
  quotation_value: "Valor de Cotización",
  choose_column: "Elegir Columna",
  select_all: "Seleccionar Todo",
  vendor_name: "Nombre del Proveedor",
  compare_quotation: "Comparar Cotización",
  approved_rejected: "Aprobado/Rechazado",
  specification_details: "Detalles de la Especificación",
  s_and_o_charges: "Cargos de S&O",
  total: "Total",
  advance: "Anticipo",
  reason_for_selection: "Razón de Selección",
  added_by_vendor: "Agregado por el Proveedor",
  purchase_order_preview: "Vista Previa de la Orden de Compra",
  approved_on: "Aprobado el",
  recommendation_reason: "Razón de Recomendación",
  po_effective_date: "Fecha de Efectividad de PO",
  details: "Detalles",
  po_preview: "Vista Previa de PO",
  validate_item_ledger: "Validar Libro Mayor de Ítems",
  validate_cost_center: "Validar Centro de Costos",
  ok: "Aceptar",
  quotation: "Cotización",
  purchase_order: "Orden de Compra",
  indent_details: "Detalles del Pedido",
  indent_date: "Fecha de Pedido",
  p_o_no: "Nro. de P.O.",
  not_yet_generated: "No Generado Aún",
  p_o_date: "Fecha de P.O.",
  effective_date: "Fecha de Efectividad",
  order_to: "Ordenar a",
  bill_to: "Facturar a",
  ship_to: "Enviar a",
  sl: "Nro.",
  items: "Ítems",
  description: "Descripción",
  qty: "Cant.",
  unit_price: "Precio Unitario",
  cgst: "CGST",
  sgst: "SGST",
  igst: "IGST",
  taxss: "Impuesto",
  gst_total: "Total GST",
  line_total: "Total de Línea",
  sub_total: "Subtotal",
  payment_mode: "Modo de Pago",
  internet_banking: "Banca en Línea",
  beneficiary_name: "Nombre del Beneficiario",
  account_number: "Número de Cuenta",
  bank_name: "Nombre del Banco",
  address: "Dirección",
  rtgs_code: "Código RTGS",
  upi: "UPI",
  upi_id: "ID de UPI",
  please_refer_to_the_po_no_in_all_your_correspondence:
    "Por favor, refiera el Nro. de PO en toda su correspondencia",
  please_send_correspondence_regarding_this_purchase_order_to:
    "Por favor, envíe correspondencia relacionada con esta orden de compra a",
  authorized_person: "Persona Autorizada",
  item_name: "Nombre del Ítem",
  item_type: "Tipo de Ítem",
  item_category: "Categoría de Ítem",
  ledger_head: "Encabezado del Libro Mayor",
  update_cost_center: "Actualizar Centro de Costos",
  cost_center: "Centro de Costos",
  patient_left_the_center: "El paciente dejó el centro",
  po_number: "Número de PO",
  invoice_number: "Número de Factura",
  invoice_date: "Fecha de Factura",
  invoice_amount: "Monto de la Factura",
  grn_info: "Información de GRN",
  dn_wb_no: "DN / Nro. WB",
  transport_co: "Compañía de Transporte",
  truck_no: "Nro. de Camión",
  origin: "Origen",
  driver: "Conductor",
  trailer_no: "Nro. de Remolque",
  itemName: "Nombre del Ítem",
  itemDescription: "Descripción del Ítem",
  dnWbUnitQty: "Cant. de Unidad DN / WB",
  grnDetails: "Detalles de GRN",
  rcptQty: "Cant. de Recibo",
  discountPercent: "Descuento %",
  discountAmount: "Monto del Descuento",
  tax: "Impuesto",
  uom: "UOM",
  batchNo: "Nro. de Lote",
  mrp: "Precio MRP",
  expDate: "Fecha de Exp.",
  notAvailable: "No disponible",
  select: "Seleccionar",
  from_organization: "De la Organización",
  invoiceDate: "Fecha de Factura",
  rcpt_amount: "Monto de Recibo",
  goodCondition: "Buena Condición",
  missingShortLandedUnits: "Unidades Faltantes o Cortas",
  brokenTornLeakingContainers: "Contenedores Rotos, Rasgados o con Fugas",
  damagedUnits: "Unidades Dañadas (mojadas, aplastadas, etc.)",
  emptyAndLightUnits: "Unidades Vacías y Ligeras",
  totalDamagedMissing: "Total Dañado y Faltante",
  paymentAgainstReimbursement: "Pago Contra Reembolso",
  enterFullScreen: "Entrar en Pantalla Completa",
  exitFullScreen: "Salir de Pantalla Completa",
  indentNo: "Nro. de Pedido",
  purpose: "Propósito",
  scheduleDate: "Fecha Programada",
  createdBy: "Creado por",
  approvedBy: "Aprobado por",
  description: "Descripción",
  reason: "Razón",
  budgetHead: "Encabezado de Presupuesto",
  invoiceReceiptNo: "Nro. de Factura / Recibo",
  invoiceDate: "Fecha de Factura",
  taxAmount: "Monto del Impuesto",
  totalAmount: "Monto Total",
  documents: "Documentos",
  total: "Total",
  travelStartDate: "Fecha de Inicio del Viaje",
  travelEndDate: "Fecha de Fin del Viaje",
  transportCost: "Costo de Transporte",
  accommodationCost: "Costo de Alojamiento",
  mealCost: "Costo de Comida",
  otherExpenses: "Otros Gastos",
  totalExpenses: "Gastos Totales",
  advanceReceived: "Anticipo Recibido",
  claimedOnActualBasis: "Reclamado en Base Real",
  claimedOnActualRate: "Reclamado a Tasa Real",
  remark: "Comentario",
  referenceNumber: "Número de Referencia",
  paymentMethod: "Método de Pago",
  approveRequest: "Aprobar Solicitud",
  cancel: "Cancelar",
  paymentAgainstAdvance: "Pago Contra Anticipo",
  payment_against_sip: "Pago Contra SIP",
  types: "Tipos",
  budgetHead: "Encabezado de Presupuesto",
  dueDate: "Fecha de Vencimiento",
  amount: "Monto",
  description: "Descripción",
  document: "Documento",
  search_man_power: "Buscar Mano de Obra",
  purpose: "Propósito",
  contract: "Contrato",
  createdBy: "Creado por",
  approvedBy: "Aprobado por",
  add_candidate: "Agregar Candidato",
  send_for_filter: "Enviar para Filtrar",
  schedule_meeting: "Programar Reunión",
  send_all_for_filter: "Enviar Todo para Filtrar",
  edit: "Editar",
  candidate_demography: "Demografía del Candidato",
  expected_ctc: "CTC Esperado",
  expected_ctc_required: "Se requiere CTC Esperado",
  experience_in_month: "Experiencia en Meses",
  experience_required: "Se requiere Experiencia",
  current_organization: "Nombre de la Organización Actual",
  current_organization_required:
    "Por favor, proporcione el nombre de la organización actual",
  skills: "Habilidades",
  notice_period: "Período de Aviso (en días)",
  notice_period_required: "Por favor, proporcione el período de aviso",
  reference_type: "Tipo de Referencia",
  select_type: "Seleccionar Tipo",
  online_portal: "Portal en Línea",
  employee: "Empleado",
  reference_detail: "Detalle de la Referencia",
  reference_detail_required:
    "Por favor, proporcione los detalles de la referencia",
  reference_by_employee: "Referencia por Nombre del Empleado",
  attach_resume: "Adjuntar Currículum",
  mode_of_interview: "Modo de Entrevista",
  physical: "Presencial",
  video: "Video",
  preferable_time: "Hora Preferible para la Entrevista",
  schedule_interview: "Programar Entrevista",
  start_time: "Hora de Inicio",
  end_time: "Hora de Fin",
  short_man_power: "Mano de Obra Corta",
  name: "Nombre",
  status: "Estado",
  contact_no: "Nro. de Contacto",
  email_id: "ID de Correo Electrónico",
  current_ctc: "CTC Actual",
  expected_ctc: "CTC Esperado",
  experience_in_month: "Experiencia en Meses",
  view_resume: "Ver Currículum",
  actions: "Acciones",
  send_for_shortlist: "Enviar para Preselección",
  accept: "Aceptar",
  reject: "Rechazar",
  accepted: "Aceptado",
  rejected: "Rechazado",
  acceptCandidate: "Aceptar Candidato",
  userPreferableTime: "Hora Preferida del Usuario",
  interviewerPreferedTime: "Hora Preferida del Entrevistador",
  typeInterviewerName: "Por favor, Escriba el Nombre del Entrevistador",
  selectInterviewer: "Seleccionar Entrevistador",
  errorMessageSomethingWrong: "Ocurrió un Error.",
  selectInterviewerTime:
    "Por favor, Seleccione la Hora Preferida del Entrevistador",
  selectInterviewers: "Por favor, Seleccione Entrevistadores",
  add: "Agregar",
  please_select_start_time: "Por favor, Seleccione Hora de Inicio",
  please_select_end_time: "Por favor, Seleccione Hora de Fin",
  please_select_interviewers: "Por favor, Seleccione Entrevistadores",
  preferable_interview_time: "Hora Preferible para la Entrevista",
  interviewFeedback: "Comentarios de la Entrevista",
  strengths: "Fortalezas",
  weaknesses: "Debilidades",
  feedback: "Comentarios",
  areasForImprovement: "Áreas de Mejora",
  rating: "Calificación",
  submitFeedback: "Enviar Comentarios",
  taskName: "Nombre de la Tarea",
  showJD: "Mostrar JD para su posición",
  showKRA: "Mostrar KRA para su posición",
  remark: "Comentario",
  submit: "Enviar",
  submitting: "Enviando...",
  close: "Cerrar",
  employeeName: "Nombre del Empleado",
  positions: "Posiciones",
  experienceInMonth: "Experiencia en Meses",
  initiatedBy: "Iniciado por",
  search: "Buscar",
  shortlist: "Preseleccionar",
  send_to_pool: "Enviar al Grupo",
  unitName: "Nombre de la Unidad",
  currentCTC: "CTC Actual",
  viewDetails: "Ver Detalles",
  employeeName: "Nombre del Empleado",
  positions: "Posiciones",
  lastIncrementDate: "Fecha del Último Incremento",
  reviewerName: "Nombre del Revisor",
  absentPercentage: "Porcentaje de Ausencias",
  metrics: "Métricas",
  selfRating: "Autoevaluación",
  reviewerRating: "Calificación del Revisor",
  selfEvaluation: "Autoevaluación",
  reviewerEvaluation: "Evaluación del Revisor",
  recommendForPromotion: "Recomendar para Promoción",
  selectPosition: "Seleccionar Posición",
  save: "Guardar",
  cancel: "Cancelar",
  evaluation: "Evaluación",
  exit_full_screen: "Salir de Pantalla Completa",
  enter_full_screen: "Entrar en Pantalla Completa",
  employee_name: "Nombre del Empleado",
  positions: "Posiciones",
  unit_name: "Nombre de la Unidad",
  current_ctc: "CTC Actual",
  view_details: "Ver Detalles",
  employee_name: "Nombre del Empleado:",
  positions: "Posiciones:",
  unit_name: "Nombre de la Unidad:",
  current_ctc: "CTC Actual:",
  last_increment_date: "Fecha del Último Incremento:",
  reviewer_name: "Nombre del Revisor:",
  absent_percentage: "Porcentaje de Ausencias:",
  metrics: "Métricas",
  self_rating: "Autoevaluación",
  reviewer_rating: "Calificación del Revisor",
  self_evaluation: "Autoevaluación",
  reviewer_evaluation: "Evaluación del Revisor",
  recommend_for_promotion: "Recomendar para Promoción",
  select_position: "Seleccionar Posición",
  hike_percent: "Porcentaje de Aumento",
  hike_amount: "Monto del Aumento",
  save: "Guardar",
  ctc_after_hike: "CTC después del Aumento:",
  pmsCycle: "Ciclo PMS:",
  cycleStartDate: "Fecha de Inicio del Ciclo:",
  cycleEndDate: "hasta",
  pmsApprovedBy: "Aprobado por PMS:",
  approvedByUser: "Aprobado por el Usuario:",
  pmsFinalReviewedBy: "Última Revisión de PMS por:",
  effective_date_of_pms: "Fecha Efectiva de PMS:",
  list_of_employees: "Lista de Empleados",
  name: "Nombre",
  position: "Posición",
  hikeRecommendation: "Recomendación de Aumento",
  newPosition: "Nueva Posición",
  status: "Estado",
  edit: "Editar",
  view: "Ver",
  done: "Hecho",
  important_notice: "Aviso Importante",
  please_ensure_all_information_in_this_document:
    "Por favor asegúrese de que toda la información en este documento esté ingresada con la máxima precisión y cuidado. Una vez enviado, no se permitirán modificaciones.",
  current_structure: "Estructura Actual",
  new_structure: "Nueva Estructura",
  salaryHead: "Concepto Salarial",
  monthly: "Mensual",
  annually: "Anual",
  enter_user_current_ctc: "Ingrese el CTC actual del usuario",
  updated_amount: "Monto Actualizado",
  total_ctc: "CTC Total",
  i_have_checked_statutory_compliance: "He verificado el Cumplimiento Legal",
  select_salary_component: "Seleccionar Componente Salarial",
  please_select_salary_head: "Por favor seleccione el concepto salarial",
  salary_head_already_exists: "El concepto salarial ya existe",
  add_new: "Agregar Nuevo",
  tax_id: "ID Fiscal",
  country_not_found: "País no encontrado",
  contract_added_successfully: "Contrato agregado con éxito",
  switchConsultation: "Cambiar Consulta",
  patientName: "Nombre del Paciente",
  experience: "Experiencia",
  fees: "Honorarios",
  language: "Idioma",
  searchDoctor: "Buscar Doctor",
  remark: "Observación",
  addMorePatient: "¿Agregar más paciente?",
  cannotRemovePatient:
    "No puede eliminar este paciente ya que es el paciente seleccionado actualmente",
  selectedPatient: "(Paciente Seleccionado)",
  pleaseWait: "Por favor espere...",
  submit: "Enviar",
  close: "Cerrar",
  onlineDoctorNotice:
    "Solo puede cambiar a doctores en línea de la misma especialidad",
  noAvailableDoctorsError:
    "No se pudo procesar la solicitud de cambio ya que no hay doctores de la misma especialidad disponibles, intente nuevamente más tarde...",
  noDoctorOnlineError: "No hay doctores en línea",
  noAvailableOnlineDoctorsError:
    "No se pudo procesar la solicitud de cambio ya que no hay doctores de la misma especialidad en línea actualmente, intente nuevamente más tarde...",
  noPatientError: "No se encontró ningún paciente. Intente nuevamente",
  selectDoctorError: "Por favor seleccione un doctor.",
  provideRemarkError: "Por favor proporcione alguna observación.",
  switchSuccessMessage: "Cambio procesado con éxito",
  switchFailureMessage: "No se pudo cambiar al paciente",

  //// Drive ////////////
  file_uploaded_successfully: "Archivo subido con éxito",
  new_folder: "Nueva Carpeta",
  upload_file: "Subir Archivo",
  new_slides: "Nuevas Diapositivas",
  new_word: "Nuevo Documento de Word",
  new_sheet: "Nueva Hoja",
  used: "Usado",
  editor: "Editor",
  viewer: "Visor",
  max_file_size: "Tamaño máximo de archivo",
  allowed_file_types: "Tipos de archivo permitidos",
  selected_files: "Archivos seleccionados {{count}}",
  delete_selected_files: "Eliminar Archivos Seleccionados",
  download_selected_files: "Descargar Archivos Seleccionados",
  drop_files_to_upload_them: "Suelta Archivos para subirlos",
  loading_drive_data: "Cargando datos de la unidad...",
  no_files_folder_found: "No se encontraron archivos / carpeta",
  drive: "Unidad",
  enter_name: "Ingresa un nombre",
  create: "Crear",
  create_slides: "Crear Diapositivas",
  enter_file_name: "Ingresa el Nombre del Archivo",
  create_word_document: "Crear Documento de Word",
  create_sheets: "Crear Hojas",
  share_file: "Compartir Archivo",
  are_you_sure_you_want_to_delete_the_following_files:
    "¿Estás seguro de que quieres eliminar los siguientes archivos?",
  uploaded_files: "Archivos Subidos",
  files: "Archivos",
  upload: "Subir",
  select_users: "Seleccionar usuarios",
  remove_access: "Eliminar Acceso",
  changes_made: "Cambios Realizados",
  no_changes_made: "No se realizaron cambios",
  file_name: "Nombre del Archivo",
  open_folder: "Abrir Carpeta",
  download_file: "Descargar archivo",
  open_file: "Abrir archivo",
  size: "Tamaño",
  owner: "Propietario",
  last_modified: "Última modificación",
  action: "Acción",
  download: "Descargar",
  delete: "Eliminar",
  delete_confirmation: "¿Estás seguro de que quieres eliminar {{name}}?",

  ///// login

  sign_in: "Iniciar Sesión",
  features: "Características",
  how_it_works: "Cómo Funciona",
  safety: "Seguridad",
  about_us: "Sobre Nosotros",
  terms: "Términos",
  and: "Y",
  conditions: "Condiciones",
  acceptance: "ACEPTACIÓN",
  acceptance_text_details:
    "Es importante que leas todos los términos y condiciones siguientes cuidadosamente. Este Acuerdo de Términos de Uso ('Acuerdo') es un acuerdo legal entre tú y el propietario y operador de Zoyel Technologies LLC ('Propietario') de este sitio web (el 'Sitio web'). Establece los términos y condiciones bajo los cuales puedes acceder y usar el Sitio web y todos los materiales escritos y otros materiales mostrados o puestos a disposición a través del Sitio web, incluidos, sin limitación, artículos, texto, fotografías, imágenes, ilustraciones, clips de audio, clips de video, software y código informático (el 'Contenido'). Al acceder y usar el Sitio web, indicas tu aceptación de estar sujeto a los términos y condiciones de este Acuerdo. Si no aceptas estos términos y condiciones, no debes acceder ni usar el Sitio web. El Propietario puede revisar este Acuerdo en cualquier momento actualizando esta publicación. El uso del Sitio web después de que se publiquen dichos cambios significará tu aceptación de estos términos revisados. Debes visitar esta página periódicamente para revisar este Acuerdo.",
  no_endorsements: "SIN RESPALDOS",
  no_endorsements_text_details:
    "A menos que se indique específicamente, el Propietario no recomienda ni respalda ninguna marca específica de productos, servicios, procedimientos u otra información que aparezca o que pueda ser anunciada en el Sitio web.",
  disclaimer_of_warranties: "RENUNCIA DE GARANTÍAS",
  disclaimer_of_warranties_text_details:
    "El Sitio web y el Contenido se proporcionan 'TAL CUAL'. Aunque el Propietario se esfuerza por proporcionar información que sea correcta, precisa, actual y oportuna, el Propietario no hace representaciones, garantías o convenios, expresos o implícitos, sobre el Sitio web y el Contenido, incluyendo, sin limitación, ninguna representación, garantía o convenio que",
  content_quality:
    "El Contenido contenido o puesto a disposición a través del Sitio web o cualquier artículo(s) puesto a disposición en o a través del Sitio web será de calidad comerciable y/o apto para un propósito particular.",
  accuracy:
    "El Sitio web o el Contenido será preciso, completo, actual, confiable, oportuno o adecuado para cualquier propósito particular.",
  operation:
    "Que la operación del Sitio web no será interrumpida ni tendrá errores.",
  error_correction:
    "Que los defectos o errores en el Sitio web o el Contenido, ya sean errores humanos o informáticos, serán corregidos.",
  virus_free: "Que el Sitio web estará libre de virus o componentes dañinos.",
  security_details:
    "Que las comunicaciones hacia o desde el Sitio web serán seguras y/o no serán interceptadas. Reconoces y aceptas que tu acceso y uso del Sitio web y del Contenido es completamente bajo tu propio riesgo y responsabilidad.",
  limitation_of_liability: "LIMITACIÓN DE RESPONSABILIDAD",
  liability_statement:
    "En ningún caso el Propietario, sus funcionarios, directores, empleados, agentes, licenciantes y sus respectivos sucesores y cesionarios serán responsables por daños de ningún tipo, incluyendo, sin limitación, cualquier daño directo, especial, indirecto, punitivo, incidental o consecuente, incluyendo, sin limitación, cualquier pérdida o daño de naturaleza, o relacionado con, la pérdida de negocios, información inexacta o cualquier otra pérdida incurrida en conexión con tu uso, mal uso o confianza en el Sitio web o el Contenido, o tu incapacidad para usar el Sitio web, independientemente de la causa y ya sea que surja en contrato (incluyendo incumplimiento fundamental), agravio (incluyendo negligencia) o de otro modo. La limitación anterior se aplicará incluso si el Propietario conocía o debía haber conocido la posibilidad de dichos daños.",
  disclaimer:
    "El Propietario también renuncia expresamente a cualquier y toda responsabilidad por los actos, omisiones y conductas de cualquier usuario de terceros del Sitio web, o de cualquier anunciante o patrocinador del Sitio web ('tercero'). En ninguna circunstancia el Propietario, sus funcionarios, directores, empleados, agentes, licenciantes y sus respectivos sucesores y cesionarios, serán responsables por cualquier lesión, pérdida, daño (incluidos daños directos, especiales, indirectos, punitivos, incidentales o consecuentes), o gasto que surja de cualquier manera de",
  third_party_actions: "Los actos, omisiones o conductas de cualquier tercero.",
  linked_sites:
    "Cualquier acceso, uso, confianza en o incapacidad para usar cualquier material, contenido, bienes o servicios ubicados en, o puestos a disposición en, cualquier sitio web vinculado al o desde el Sitio web, independientemente de la causa y ya sea que surja en contrato (incluyendo incumplimiento fundamental), agravio (incluyendo negligencia) o de otro modo. La limitación anterior se aplicará incluso si el propietario conocía o debía haber conocido la posibilidad de dichos daños.",
  indemnity: "INDEMNIZACIÓN",
  indemnity_statement:
    "Aceptas indemnizar, defender y mantener indemne al Propietario y sus funcionarios, directores, empleados, agentes, licenciantes y sus respectivos sucesores y cesionarios, de y contra cualquier y todas las reclamaciones, demandas, responsabilidades, costos o gastos que resulten directa o indirectamente de",
  breach_terms:
    "Tu incumplimiento de cualquiera de los términos y condiciones de este Acuerdo.",
  website_access:
    "Tu acceso, uso, mal uso, confianza o incapacidad para acceder o usar el Sitio web, el Contenido o cualquier sitio web al que el Sitio web está o puede estar vinculado de vez en cuando o",
  content_use:
    "Tu uso, confianza, publicación, comunicación, distribución, carga o descarga de cualquier cosa (incluido el Contenido) en o desde el Sitio web.",

  ////////homePage

  omni_presence: "Presencia Omni",
  omni_presence_description:
    "Crea tu presencia en la web con unos pocos clics. No necesitas dominios, alojamiento web o infraestructura de TI.",
  view_details: "Ver Detalles",
  organization: "Organización",
  organization_description:
    "Crea y define tu organización y su estructura, añade personas, asigna departamentos, funciones y ponla en marcha.",
  task_management: "Gestión de Tareas",
  task_management_description:
    "Crea tareas fácilmente y monitóralas. Elimina la carga de gestión.",
  compliances: "Cumplimientos",
  compliances_description:
    "Realiza reuniones de Junta / AGM, emite Agenda, realiza encuestas, regístralas. Mantén un seguimiento de los cumplimientos.",
  productivity: "Productividad",
  productivity_description:
    "Crea tu presencia en la web con unos pocos clics. No necesitas dominios, alojamiento web o infraestructura de TI.",
  collaboration: "Colaboración",
  collaboration_description:
    "Suite completa de colaboración sin suscribirte a ningún otro proveedor.",
  security: "Seguridad",
  security_description:
    "Asegura la seguridad de los datos con la mejor seguridad de red, cifrado y seguridad de datos.",
  time_space_language: "Tiempo, espacio y lenguaje",
  time_space_language_description:
    "Con soporte multilingüe y de zonas horarias, permite que el trabajo fluya con equipos, clientes y proveedores en todo el mundo.",
  meetings: "Reuniones",
  meetings_description:
    "Reuniones sincrónicas y asincrónicas - programadas e instantáneas con salas privadas y seminarios web.",
  complete_workflows: "Flujos de Trabajo Completos",
  complete_workflows_description:
    "Flujos de trabajo personalizables de RRHH, Contabilidad, Finanzas, Negocios, Ventas y Marketing.",
  kill_wastage: "Eliminar Desperdicios",
  kill_wastage_description:
    "Con reuniones vinculadas a tareas y Tareas basadas en Resultados, detén la deriva y ahorra tiempo crucial.",
  analytics: "Analíticas",
  analytics_description:
    "Administra monitoreando fácilmente lo que está sucediendo y asigna recursos, tiempo y dinero de manera más efectiva.",
  more_features: "Más Características",
  join_the_zoyel_network_and: "Únete a la Red Zoyel y",
  streamline: "optimiza",
  your_business: "tu negocio",
  join_zoyel_network_detail:
    "En el entorno empresarial competitivo y de ritmo acelerado de hoy, la eficiencia y la innovación son cruciales para el éxito. La Red Zoyel ofrece una solución integral diseñada para ayudar a tu negocio a prosperar. Al unirte a la Red Zoyel, puedes optimizar tus operaciones, mejorar la productividad y desbloquear nuevas oportunidades de crecimiento.",
  app_coming_soon: "APP PRÓXIMAMENTE",
  featuredIn: "Zoyel presentado en SecCon 2024",
  taskEffectiveness: "Cómo las Tareas ahorran tiempo y hacen equipos efectivos",
  allInOne: "Zoyel One: La Solución Empresarial Todo en Uno",
  recordGrowth: "Zoyel One preparado para un crecimiento récord en 2024",
  description1:
    "Zoyel ocupó el centro del escenario en SecCon 2024, la conferencia principal para profesionales de la seguridad y la tecnología. Este año, Zoyel mostró sus últimos avances en ciberseguridad y soluciones empresariales, atrayendo la atención significativa de líderes y expertos de la industria.",
  description2:
    "Zoyel One es una poderosa plataforma de gestión de tareas y colaboración diseñada para optimizar las operaciones y mejorar la productividad del equipo. Así es como el uso de Zoyel One puede ahorrar tiempo y hacer que tus equipos sean más efectivos:",
  description3:
    "Zoyel One está revolucionando la forma en que las empresas operan al ofrecer una solución integral, todo en uno, que se adapta a cada aspecto de la gestión empresarial moderna. Aquí te explicamos por qué Zoyel One se destaca como la elección definitiva para las empresas que buscan eficiencia, crecimiento e innovación:",
  description4:
    "Zoyel One está expandiendo rápidamente su presencia en nuevos y existentes mercados. La versatilidad y adaptabilidad de la plataforma la convierten en una opción atractiva para empresas de todos los tamaños, desde nuevas empresas hasta grandes empresas, en diversas industrias.",
  feb12: "12 de febrero de 2024",
  feb6: "6 de febrero de 2024",
  jan28: "28 de enero de 2024",
  jan21: "21 de enero de 2024",
  latest: "Último",
  news: "Noticias",
  aboutTitle: "Acerca de Zoyel",
  aboutText:
    "Zoyel One integra una amplia gama de funcionalidades en una sola plataforma, eliminando la necesidad de múltiples herramientas dispares. Este enfoque unificado simplifica las operaciones, reduce costos y mejora la productividad al proporcionar todo lo que una empresa necesita en un solo lugar.",
  importantLinksTitle: "Enlaces Importantes",
  privacyPolicy: "Política de Privacidad",
  termsConditions: "Términos y Condiciones",
  contactTitle: "Contacto",
  subscribeTitle: "Suscribirse",
  subscribeText:
    "Suscríbete a nuestro Boletín para recibir las últimas noticias",
  emailPlaceholder: "Correo Electrónico",
  submit: "Enviar",
  copyright: "Derechos de Autor",
  login: "Iniciar Sesión",
  email_id: "Correo Electrónico",
  select_organization: "Seleccionar Organización",
  password: "Contraseña",
  forgot_password: "¿Olvidaste tu Contraseña?",
  do_not_have_an_account: "¿No tienes una cuenta?",
  sign_up_now: "¡Regístrate Ahora!",
  registration: "Registro",
  full_name: "Nombre Completo",
  validate_otp: "Validar OTP",
  validate: "Validar",
  resend_otp: "Reenviar OTP",
  new_password: "Nueva Contraseña",
  confirm_password: "Confirmar Contraseña",
  password_strength: "Fuerza de la Contraseña",
  too_short: "Demasiado Corto",
  password_should_contain: "La Contraseña debe contener",
  one_upper_case_letter: "Una letra mayúscula",
  one_lower_case_letter: "Una letra minúscula",
  one_number: "Un Número",
  special_character: "Caracter especial",
  min_8_characters: "Mínimo 8 caracteres",
  passwords_mismatch: "Las contraseñas no coinciden",
  register: "REGISTRARSE",
  already_have_an_account: "¿Ya tienes una cuenta?",
  sign_in_now: "¡Inicia Sesión Ahora!",
  forgot_password: "Olvidé la Contraseña",
  email_id: "Correo Electrónico",
  select_organization: "Seleccionar Organización",
  validate_otp: "Validar OTP",
  validate: "Validar",
  new_password: "Nueva Contraseña",
  confirm_password: "Confirmar Contraseña",
  change_password: "Cambiar contraseña",
  send_otp: "Enviar OTP",
  you_cannot_change_password_for_this_email:
    "No Puedes Cambiar la Contraseña Para Este correo",
  login_to_your_account: "Inicia sesión en tu cuenta",
  rcpt_amount_after_discount: "Monto de Recibo Después del Descuento",
  guardian_name: "Nombre del Tutor",
  emergency_contact_no: "Número de Contacto de Emergencia",
  insurance_provider_name: "Nombre del Proveedor de Seguro",
  insurance_provider_no: "Número del Proveedor de Seguro",
  meeting_summary: "Resumen de la reunión",
  view_meeting_summary: "Ver resumen de la reunión",
  calling_code: "Código de llamada",
  external_user_added_successfully: "Usuario externo agregado exitosamente",
  queue: "Cola",
  all: "Todo",
  tasks: "Tareas",
  chats: "Charlas",
  meetings: "Reuniones",
  phermacy_copy_of_rx: "Copia de farmacia de Rx",
};
