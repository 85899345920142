import React, { useState, useEffect } from "react";
import {
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Tooltip,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

import { MeetingRoom, ExitToApp, PeopleAlt, Cancel } from "@mui/icons-material";
import { XLg } from "react-bootstrap-icons";
import { useTable } from "react-table";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { getLoginUserDetails } from "../../../../../../utils/Utils";
import { getChairpersonOrConvenerList } from "../../../../../../services/MeetingService";
import { useTranslation } from "react-i18next";

const BreakOutRoom = ({
  handleMenuCloseButton,
  jitsiObject,
  roomNumber,
  breakoutRoomId,
  setBreakoutRoomId,
  selEvent,
}) => {
  const userDetails = getLoginUserDetails();
  console.log("userDetails", userDetails);
  const { t } = useTranslation();

  const [breakoutRoomList, setBreakoutRoomList] = useState([]);
  const [breakoutRoomListSize, setBreakoutRoomListSize] = useState(0);
  const [breakoutRoomName, setBreakoutRoomName] = useState("");
  const [participantList, setParticipantList] = useState([]);

  const refreshBreakoutRooms = () => {
    jitsiObject.listBreakoutRooms().then((breakoutRooms) => {
      console.log("listBreakoutRooms", breakoutRooms);
      let valuesList = Object.values(breakoutRooms);
      valuesList = valuesList.filter((values) => !values.isMainRoom);
      setBreakoutRoomList(valuesList);
    });
  };

  useEffect(() => {
    refreshBreakoutRooms();
    const handleBreakoutRoomsUpdated = (obj) => {
      console.log("breakoutRoomsUpdated", obj);
      let breakoutRoomsTemp = obj.rooms;
      console.log("listBreakoutRooms", breakoutRoomsTemp);
      let valuesList = Object.values(breakoutRoomsTemp);
      valuesList = valuesList.filter((values) => !values.isMainRoom);
      console.log("breakoutRoomsUpdated", valuesList);
      setBreakoutRoomList(valuesList);
      setBreakoutRoomName("");
    };

    jitsiObject.addListener("breakoutRoomsUpdated", handleBreakoutRoomsUpdated);

    return () => {
      console.log("componentWillUnmount");
      jitsiObject.removeListener(
        "breakoutRoomsUpdated",
        handleBreakoutRoomsUpdated
      );
    };
  }, [breakoutRoomId]);

  const getCurrentRoom = () => {
    let roomName = "Main";
    breakoutRoomList.forEach((room) => {
      console.log("getCurrentRoom", room.jid === breakoutRoomId);

      if (room.jid === breakoutRoomId) {
        roomName = "Breakout Room " + room.name;
        // setBreakoutRoomId(room.jid);
      }
    });
    return roomName;
  };

  const handleJoinLeaveClick = (row) => {
    console.log("handleJoinLeaveClick", row);
    if (breakoutRoomId !== row.jid) {
      jitsiObject.executeCommand("joinBreakoutRoom", row.jid);
      setBreakoutRoomId(row.jid);
      let breakoutRName = breakoutRoomName;
      // toast.success(`Joining breakout room ${breakoutRName}`, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      toast.success(t('joiningRoom', { roomName: breakoutRName }), {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleMenuCloseButton();
    } else {
      jitsiObject.executeCommand("joinBreakoutRoom");
      setBreakoutRoomId(null);
      // toast.success(`Joining Main room ${breakoutRoomName}`, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      toast.success(t('joiningMainroom', { roomName: breakoutRoomName }), {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleMenuCloseButton();
    }
  };

  const handleParticipantsClick = (row) => {
    let valuesList = Object.values(row.participants);
    console.log("valuesList", valuesList, jitsiObject._myUserID);
    if (valuesList.length === 0) {
      // toast.error(
      //   `No participant is present in breakout room ${breakoutRoomName}`,
      //   {
      //     position: toast.POSITION.TOP_RIGHT,
      //   }
      // );
      toast.error(t('no_participant_is_present_in_breakout_room', { roomName: breakoutRoomName }), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setParticipantList(valuesList);
    }
  };

  console.log("selEvent", selEvent);

  const [canCreateBreakoutRoom, setCanCreateBreakoutRoom] =
    React.useState(false);

  useEffect(() => {
    // let myRoomId = null;
    jitsiObject.getRoomsInfo().then((roomInfo) => {
      console.log("getRoomsInfo", roomInfo);
      if (
        roomInfo.rooms[0].participants.some(
          (row) => row.id === jitsiObject._myUserID && row.role === "moderator"
        )
      ) {
        setCanCreateBreakoutRoom(true);
      }
      roomInfo.rooms.forEach((roomDto) => {
        if (
          roomDto.participants.some(
            (row) => row.id === jitsiObject._myUserID
          ) &&
          !roomInfo.isMainRoom
        ) {
          console.log("getRoomsInfo2", roomDto);
          setBreakoutRoomId(roomDto.jid);
        }
      });
    });

    getChairpersonOrConvenerList(selEvent.eventId, "convener").then(
      (response) => {
        console.log(" -->>>", response.data);
        if (response.data && response.data.length > 0) {
          if (response.data[0].userId === userDetails.userId) {
            setCanCreateBreakoutRoom(true);
          }
        }
      }
    );
  });

  return (
    <>
      <div className="meetRightHeader">
        <div className="metRtTitle"> {t("breakout_rooms")} </div>
        <div className="metRtAction">
          <IconButton
            aria-label="Close"
            onClick={() => handleMenuCloseButton()}
          >
            <XLg />
          </IconButton>
        </div>
      </div>

      <div className="MeetingTranscriptionContainer">
        <div className="Meeting_Transcription">
          <div className="transcripHeader">
            {/* {selEvent &&
        selEvent !== null &&
        (selEvent.chairperson === "Y" || selEvent.convener === "Y") && ( */}
            {canCreateBreakoutRoom && (
              <TextField
                className="transcripAutoComplete"
                id="transcripAutoComplete"
                placeholder={t("create_breakout_room")}
                value={breakoutRoomName}
                onChange={(event) => {
                  setBreakoutRoomName(event.target.value.trim());
                }}
                disabled={false}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={t("create_breakout_room")}>
                        <IconButton
                          aria-label="Create  Breakout Room"
                          onClick={() => {
                            console.log("Breakout Room Name", breakoutRoomName);
                            if (
                              breakoutRoomName === "" ||
                              breakoutRoomName.trim() === ""
                            ) {
                              toast.error(t("please_enter_breakout_room_name"), {
                                position: toast.POSITION.TOP_RIGHT,
                              });
                              return;
                            }

                            jitsiObject.executeCommand(
                              "addBreakoutRoom",
                              breakoutRoomName
                            );
                            // toast.success(
                            //   `Breakout room ${breakoutRoomName} created`,
                            //   {
                            //     position: toast.POSITION.TOP_RIGHT,
                            //   }
                            // );
                            toast.success(t('breakout_room_created', { roomName: breakoutRoomName }), {
                              position: toast.POSITION.TOP_RIGHT,
                            });
                            refreshBreakoutRooms();
                            return;
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            )}

            {/* // )} */}
          </div>

          <div className="transcriptionContainArea">
            <div>Current Room: {getCurrentRoom()}</div>
            {breakoutRoomList && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ color: "white" }}>
                      {t("room_name")}
                      </TableCell>
                      <TableCell style={{ color: "white" }}>
                      {t("join_leave")}
                      </TableCell>
                      <TableCell style={{ color: "white" }}>
                      {t("participants")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {breakoutRoomList.map((row) => (
                      <TableRow key={row.jid}>
                        <TableCell sx={{ color: "white" }}>
                          {row.name}
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => handleJoinLeaveClick(row)}
                          >
                            {breakoutRoomId !== row.jid && (
                              <MeetingRoom
                                sx={{ color: "green", marginRight: "10px" }}
                              />
                            )}
                            {breakoutRoomId === row.jid && (
                              <ExitToApp
                                sx={{ color: "red", marginRight: "10px" }}
                              />
                            )}
                            {breakoutRoomId !== row.jid ? "Join" : "Leave"}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => handleParticipantsClick(row)}
                          >
                            <Badge
                              badgeContent={
                                Object.values(row.participants).length
                              }
                              sx={{ color: "white" }}
                            >
                              <PeopleAlt sx={{ color: "white" }} />
                            </Badge>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {participantList.length > 0 && (
              <Modal
                open={participantList.length > 0}
                onClose={() => setParticipantList([])}
                aria-labelledby="modal-modal-invite-person"
                aria-describedby="modal-modal-invite-person"
                className="coustomModel"
              >
                <Box
                  className="createEvent responsiveModelAdmin"
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    border: "1px solid #000",
                    borderRadius: "5px",
                    boxShadow: 24,
                    width: "20%",
                    p: 2,
                  }}
                >
                  <Grid container mb={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <div className="brParticipants">
                        <label>Participants</label>
                        <br />
                        <div
                          style={{ cursor: "pointer" }}
                          className="closeDilog"
                          onClick={() => setParticipantList([])}
                        >
                          <Cancel />
                        </div>
                      </div>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Participant Name</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {participantList.map((participant) => (
                              <TableRow key={participant.id}>
                                <TableCell>{participant.displayName}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid> */}
                  </Grid>
                  {/* <Grid
                    container
                    // m={2}
                    p={1}
                    sx={{ borderBottom: "1px solid black" }}
                  >
                    {" "}
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      Participant Name
                    </Grid>
                  </Grid> */}
                  <Grid
                    container
                    // m={2}
                    p={1}
                    // sx={{ border: "1px solid black" }}
                  >
                    {/* {participantList.map((participant) => (
                        <>{participant.displayName}</>
                      ))} */}
                    {participantList.map((parDto, index) => {
                      return (
                        <>
                          {" "}
                          <Grid mb={1} item xs={12} sm={12} md={12} lg={12}>{`${
                            index + 1
                          })   ${parDto.displayName}`}</Grid>
                        </>
                      );
                    })}
                  </Grid>
                  <Button
                    onClick={() => setParticipantList([])}
                    sx={{ float: "right" }}
                  >
                   {t("close")}
                  </Button>
                </Box>
              </Modal>
            )}
          </div>
        </div>
      </div>

      <div className="MeetRightFooter">
        <div className="meetRightFootBtnGrp">
          <Button
            className="cancleTransBtn"
            variant="outlined"
            onClick={() => handleMenuCloseButton()}
          >
            {t("close")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default BreakOutRoom;
