import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { getLoginUserDetails } from "../../../../utils/Utils";
import ReactDatePicker from "react-datepicker";
import { NumericFormatCustom } from "../../../task/view/component/Forms/NumericFormatCustom";
import { SalaryTable } from "./SalaryTable";
import { Bank, Trash } from "react-bootstrap-icons";
import { Add, Edit, Gavel } from "@mui/icons-material";
import {
  getAllDepartment,
  getContractList,
  getCountryList,
  getCurrencyList,
  getEmployees,
  getItems,
  getOrgDetailsByOrgId,
  getPendingAppointmentCandidat,
  getPositionsOfOrganization,
  getSalaryBreakUpOfLoi,
  getStateList,
  getStaticDDOfOrg,
  getUnits,
  unitDetilsForProforma,
  getEmpForRelease,
  issueReleaseLetter,
} from "../../../../services/AdminService";
import DashboardServices from "../../../../services/DashboardServices";
import IntegerTextField from "../../../../common/IntegerTextField";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { VendorPaymentMethods } from "../../../task/view/component/Forms/VendorPaymentMethods";
import TaskService from "../../../../services/TaskService";
import { TermsAndConditions } from "../../../task/view/component/Forms/TermsAndConditions";
import { FloatTextField } from "../../../../common/FloatTextField";
import dayjs from "dayjs";
import DateUtils from "../../../../utils/DateUtils";

export const ReleaseLetter = forwardRef(
  ({ formData, setFormData, handleShowPreview , setSelectActivity}, releaseLetterRef) => {
    const loginUserDetails = getLoginUserDetails();
    const loginUserDetail = getLoginUserDetails();
    const [salaryInfoList, setSalaryInfoList] = useState([]);
    const [positionList, setPositionList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [countryList, setCountryList] = useState([{}]);
    const [stateList, setStateList] = useState([]);
    const [lastWorkingdate, setLastWorkingdate] = useState(new Date());
    const [dateOfResignation, setDateOfResignation] = useState(new Date());
    const [dateOfBirth, setDateOfBirth] = useState(new Date());
    const [loiCandidateList, setLoiCandidateList] = useState([]);
    const [fromIssuedLoi, setFromIssuedLoi] = useState(false);
    const { t } = useTranslation();
    const [unitsData, setUnitData] = useState([]);
    const [emplyeeUnits, setEmplyeeUnits] = useState("");
    const [tableDataList, setTableDataList] = useState([]);
    const [showBankDetails, setShowBankDetails] = useState(false);
    const [selectedMethodId, setSelectedMethodId] = useState(0);

    const [selectedDepartment, setSelectedDepartment] = useState("");
    const [selectedPosition, setSelectedPosition] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [salaryBrkUps, setSalaryBrkUps] = useState([]);
    const [orgData, setOrgData] = useState(null);
    const [unitList, setUnitList] = useState([]);
    const [corCountry, setCorCountry] = useState("");
    const [corState, setCorState] = useState("");
    const tacRef = useRef();
    const [saveForFutureAppoint, setSaveForFutureAppoint] = useState(false);

    const [selectedReportingHead, setSelectedReportingHead] = useState(null);
    const [userList, setUserList] = useState([]);

    const [staticDodList, setStaticDodList] = useState([]);
    const [staticDodListCopy, setStaticDodListCopy] = useState([]);
    const [selectedContractId, setSelectedContractId] = useState("");
    const [shipToData, setShipToData] = useState("");
    const [itemData, setItemData] = useState([]);
    const [defultCurrency, setdefultCurrency] = useState("");
    const [sellerTaxId, setsellerTaxId] = useState("");
    const [sellerEmailValue, setsellerEmailValue] = useState([]);
    const [sellerEmailId, setsellerEmailId] = useState("");
    const [sellerEmailInputId, setsellerEmailInputId] = useState("");

    const [contractData, setContractData] = useState([]);
    const [buyerTaxId, setBuyerTaxId] = useState("");
    const [buyerEmailId, setBuyerEmailId] = useState("");
    const [notesSpecialInstruction, setNotesSpecialInstruction] = useState("");
    const [buyerCountryCode, setBuyerCountryCode] = useState("");
    const [selectSellerState, setSelectSellerState] = useState("");
    const [selectBuyerState, setSelectBuyerState] = useState("");
    const [roundOffAmount, setRoundOffAmount] = useState();
    const [totalAmount, setTotalAmount] = useState();
    const [letterContent, setLetterContent] = useState("");
    const [Signature, setSignature] = useState("");
    const [emplyuserId, setEmplyUserId] = useState("");

    const [paymentMethods, setPaymentMethods] = useState([]);

    const [addTableData, setAddTableData] = useState({
      proformaInvoId: 1,
      itemId: 0,
      itemName: "",
      uom: "",
      quantity: "",
      rate: 0,
      disePc: 0,
      sgstPc: 0,
      cgstPc: 0,
      igstPc: 0,
      taxPc: 0,
      diseAmt: 0,
      sgstAmt: 0,
      amount: 0,
      unitDetails: "",
    });

    console.log("addTableData", addTableData);

    const [orgTacList, setOrgTacList] = useState([]);
    const [selectedConditionList, setSelectedConditionList] = useState([]);

    const [showTaCDialog, setShowTaCDialog] = useState(false);
    const [allTermsAndConditions, setAllTermsAndConditions] = useState(null);

    const [employName, setEmployName] = useState("");
    const [employId, setEmployId] = useState("");
    const [employPosition, setEmployPosition] = useState([]);
    const [employDepartment, setEmployDepartment] = useState("");

    //////////////////////////////////////

    const [employsData, setEmploysData] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date());

    const [selectedEmploy, setSelectedEmploy] = useState(null);

    const [employInputString, setEmployInputString] = useState("");

    const [orgPersonPosition, setOrgPersonPosition] = useState("");

    // const fetchOrgTermsAndConditionData = () => {
    //   TaskService.getOrgTermsAndConditions(loginUserDetail.orgId, "RFP").then(
    //     (response) => {
    //       if (response.data) {
    //         const tmpCondList = response.data;
    //         setOrgTacList(tmpCondList);
    //         setSelectedConditionList(tmpCondList);
    //       }
    //     }
    //   );
    // };

    // const fetchPaymentMethods = () => {
    //   TaskService.getPaymentMethodsOfUser(loginUserDetails.userId).then(
    //     (response) => {
    //       if (response.data) {
    //         setPaymentMethods(response.data);
    //       }
    //     }
    //   );
    // };

    const [tableData, setTableData] = useState([]);

    const [currencyList, setCurrencyList] = useState([]);

    const subTotalAmount = useMemo(() => {
      if (!tableData || tableData.length === 0) {
        setTotalAmount(0);
        setRoundOffAmount(0);
        return 0;
      } else {
        let totalValue = 0;
        tableData?.forEach((item) => {
          totalValue += parseFloat(item.amount);
        });
        totalValue = parseFloat(totalValue.toFixed(2));
        const total = Math.round(totalValue);

        const roudnOff = parseFloat((total - totalValue).toFixed(2));
        setTotalAmount(total);
        setRoundOffAmount(roudnOff);
        return totalValue;
      }
    }, [tableData]);

    // useEffect(() => {
    //   fetchPaymentMethods();
    //   getCurrencyList().then((response) => {
    //     console.log("getCurrencyList", response.data);
    //     setCurrencyList(response.data);
    //   });
    // }, []);

    const handleLastWorkingDate = (date) => {
      setLastWorkingdate(date);
    };

    const handleDateOfResignation = (date) => {
      setDateOfResignation(date);
    };

    const { control, getValues, setValue, reset } = useForm({
      defaultValues: {
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        address1: "",
        address2: "",
        city: "",
        pin: "",
        cor_address: "",
        cor_address1: "",
        cor_address2: "",
        cor_city: "",
        cor_pin: "",
        probationDay: "",
        priorAddressChangeDays: "",
        priorLeaveNoticeDays: "",
        absentDays: "",
        noticePeriod: "",
        employmentTitle: "",
        gpm: "",
      },
    });

    useEffect(() => {
      if (
        (selectedCountry && selectedCountry.countryCode) ||
        (corCountry && corCountry.countryCode)
      ) {
        const cCode = selectedCountry.countryCode || corCountry.countryCode;
        if (stateList && stateList.length === 0) {
          getStateList(cCode).then((response) => {
            if (response.data) {
              setStateList(response.data);
            }
          });
        }
      }
    }, [selectedCountry, corCountry]);

    useEffect(() => {
      if (loginUserDetail.signleUnitId !== "0") {
        setEmplyeeUnits(loginUserDetail.signleUnitId);
      }

      if (loginUserDetail.signleUnitId == "0" && unitsData.length === 1) {
        setEmplyeeUnits(unitsData[0].unitId);
      }
    }, [unitsData]);

    const handleChangEemplyeeUnits = (event) => {
      setEmplyeeUnits(event.target.value);
    };

    // useEffect(() => {
    //   fetchContractData();
    //   fetchOrgTermsAndConditionData();
    // }, []);

    const fetchContractData = () => {
      getContractList(loginUserDetail.orgId).then((response) => {
        console.log("getContractList", response.data);
        setContractData(response.data);
      });
    };

    console.log("contractData", contractData);

    const calculatedAnnualTotal = useMemo(() => {
      let total = 0;
      let infoList = [];
      //
      if (salaryBrkUps && salaryBrkUps.length > 0) {
        infoList = [...salaryBrkUps];
      } else {
        infoList = [...salaryInfoList];
      }

      for (let i = 0; i < infoList.length; i++) {
        if (salaryBrkUps && salaryBrkUps.length > 0) {
          total += infoList[i].yearly;
        } else {
          total += infoList[i].annual;
        }
      }

      if (isNaN(total)) {
        total = 0;
      }
      return total;
    }, [salaryInfoList]);

    useEffect(() => {
      setValue("ctc", calculatedAnnualTotal);
    }, [calculatedAnnualTotal]);

    // useEffect(() => {
    //   unitDetilsForProforma(loginUserDetail.orgId).then((response) => {
    //     console.log("response unit of proforma", response.data);
    //     setUnitData(response.data);
    //   });
    // }, []);

    // useEffect(() => {
    //   getItems(loginUserDetail.orgId).then((response) => {
    //     if (response.data) {
    //       setItemData(response.data);
    //     }
    //   });
    // }, []);

    useEffect(() => {
      if (emplyeeUnits && unitsData) {
        const result = unitsData.find((val) => val.unitId === emplyeeUnits);

        if (result) {
          setdefultCurrency(result.currency || "");
          setsellerTaxId(result.taxNo || "");
          setSelectSellerState(result.stateId || "");
          const orgEmail = result.orgEmail;
          const unitEmail = result.unitEmail;
          const userEmail = loginUserDetail.userEmailId;
          console.log("orgEmail", orgEmail);
          console.log("unitEmail", unitEmail);
          console.log("userEmail", userEmail);
          console.log("loginUserDetail", loginUserDetail);
          setsellerEmailValue([unitEmail, orgEmail, userEmail]);

          if (unitEmail) {
            setsellerEmailId(unitEmail);
          } else if (orgEmail) {
            setsellerEmailId(orgEmail);
          } else {
            setsellerEmailId(userEmail);
          }
        } else {
          console.log("No matching unit found");
          setdefultCurrency("");
          setsellerTaxId("");
          setsellerEmailValue([]);
          setSelectSellerState();
        }
      }
    }, [emplyeeUnits, unitsData]);

    useEffect(() => {
      if (selectedContractId && contractData) {
        const result = contractData.find(
          (val) => val.id === selectedContractId
        );

        console.log("buyer", result);

        if (result) {
          setBuyerTaxId(result.taxId || "");
          setBuyerEmailId(result.emailId || "");
          setShipToData(result.address || "");
          setBuyerCountryCode(result.countryCode || "");
          setSelectBuyerState(result.stateId || "");
        } else {
          console.log("No matching unit found");
          setBuyerTaxId("");
          setBuyerEmailId("");
          setShipToData("");
          setBuyerCountryCode("");
          setSelectBuyerState();
        }
      }
    }, [selectedContractId, contractData]);

    const calculateAmount1 = (data) => {
      const { quantity, rate, disePc, cgstPc, sgstPc, igstPc, taxPc } = data;

      console.log("rate", rate);

      const qty = parseFloat(quantity) || 0;
      const r = parseFloat(rate) || 0;
      const discount = parseFloat(disePc) || 0;

      // Calculate the basic amount
      let amount = qty * r;

      // Apply discount
      const diseAmt = amount * (discount / 100);
      let sgstAmt = 0;
      let igstAmt = 0;
      let cgstAmt = 0;
      let taxAmt = 0;
      amount -= diseAmt;

      // Add tax based on organization code
      if (loginUserDetail.orgCountryCode === "356") {
        cgstAmt = (amount * (parseFloat(cgstPc) || 0)) / 100;
        sgstAmt = (amount * (parseFloat(sgstPc) || 0)) / 100;
        igstAmt = (amount * (parseFloat(igstPc) || 0)) / 100;
        amount += cgstAmt + sgstAmt + igstAmt;
      } else {
        taxAmt = (amount * (parseFloat(taxPc) || 0)) / 100;
        amount += taxAmt;
      }

      // Update the amount in the state
      // setAddTableData(prevData => ({
      //     ...prevData,
      //     amount: amount.toFixed(2),  // Store amount as a string with 2 decimal places
      // }));

      return {
        amount: amount.toFixed(2),
        value: amount.toFixed(2),
        diseAmt,
        sgstAmt,
        cgstAmt,
        igstAmt,
        taxAmt,
      };
    };

    const formatDateWithSuffix = (date) => {
      const day = date.getDate();
      const ordinalSuffix = (day) => {
        if (day > 3 && day < 21) return "th"; // For 11th, 12th, 13th, etc.
        switch (day % 10) {
          case 1:
            return "st";
          case 2:
            return "nd";
          case 3:
            return "rd";
          default:
            return "th";
        }
      };

      const dayWithSuffix = day + ordinalSuffix(day);
      const month = date.toLocaleString("default", { month: "short" });
      const year = date.getFullYear();

      return `${dayWithSuffix} ${month} ${year}`;
    };

    const handleAddColumn = () => {
      const nextId =
        tableData.length > 0
          ? tableData[tableData.length - 1].proformaInvoId + 1
          : 1;

      setAddTableData((prevData) => ({
        ...prevData,
        proformaInvoId: nextId,
      }));

      setTableData((prevTableData) => [
        ...prevTableData,
        { ...addTableData, proformaInvoId: nextId },
      ]);

      setAddTableData({
        proformaInvoId: 0,
        itemId: 0,
        itemName: "",
        uom: "",
        quantity: "",
        rate: 0,
        disePc: 0,
        sgstPc: 0,
        cgstPc: 0,
        igstPc: 0,
        taxPc: 0,
        diseAmt: 0,
        sgstAmt: 0,
        amount: 0,
        unitDetails: "",
      });
    };

    console.log("tableData", tableData);

    console.log("addTableData", addTableData);

    const handleDelete = (id) => {
      setTableData((prevTableData) =>
        prevTableData.filter((row) => row.proformaInvoId !== id)
      );
    };

    /////////////////////////////////////////

    useEffect(() => {
      fetchEmployData();
    }, []);

    const fetchEmployData = () => {
      getEmpForRelease(loginUserDetail.orgId)
        .then((response) => {
          console.log("getEmployees", response.data);
          setEmploysData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching units", error);
        });
    };

    console.log("selectedEmploy", selectedEmploy);
    console.log("employInputString", employInputString);

    useEffect(() => {
      if (selectedEmploy && employsData) {
        const result = employsData.find(
          (val) => val.employeeUserId === selectedEmploy.employeeUserId
        );

        console.log("buyer", result);

        if (result) {
          setEmployName(result.employeeName || "");
          setEmployId(result.employeeId || "");
          setEmployDepartment(result.functionName || "");
          setEmployPosition(result.positions || []);
          setEmplyUserId(result.employeeUserId || "");
        } else {
          console.log("No matching unit found");
          setEmployName("");
          setEmployId("");
          setEmployDepartment("");
          setEmployPosition([]);
          setEmplyUserId("");
        }
      }
    }, [selectedEmploy, employsData]);

    useEffect(() => {
      if (loginUserDetail && employsData) {
        const result = employsData.find(
          (val) => val.employeeUserId === loginUserDetail.userId
        );

        console.log("buyer", result);

        if (result) {
          setOrgPersonPosition(result.positions.join("") || "");
        } else {
          console.log("No matching unit found");
          setOrgPersonPosition("");
        }
      }
    }, [selectedEmploy, employsData]);

    useEffect(() => {
      setDateOfResignation(lastWorkingdate);
    }, [lastWorkingdate]);

    useEffect(() => {
      generateLetterContent();
    }, [
      employName,
      employId,
      employPosition,
      employDepartment,
      lastWorkingdate,
      dateOfResignation,
      currentDate,
    ]);

    const resetEmployeeDetails = () => {
      setEmployName("");
      setEmployId("");
      setEmployDepartment("");
      setEmployPosition([]);
    };

    const generateLetterContent = () => {
      const content = `
To

${employName || "N/A"}

${employId || "N/A"}

Designation: ${employPosition || "N/A"}

Department: ${employDepartment || "N/A"}

Date: ${dayjs(currentDate).format("DD-MMM-YYYY") || "N/A"}

This letter is to confirm that your employment with ${
        loginUserDetail.orgName || "N/A"
      }, will conclude on ${
        dayjs(lastWorkingdate).format("DD-MMM-YYYY") || "N/A"
      }, as per your resignation submitted on ${
        dayjs(dateOfResignation).format("DD-MMM-YYYY") || "N/A"
      }. We appreciate your contributions to the company during your tenure.

We wish you all the best in your future endeavours.

Sincerely,

${loginUserDetail.userName || "N/A"}

${orgPersonPosition || "N/A"}

${loginUserDetail.orgName || "N/A"}

${loginUserDetail.orgAddress || "N/A"}
    `;
      setLetterContent(content);
    };

    useEffect(() => {
      fetchUserProfileDetails();
    }, []);

    const fetchUserProfileDetails = () => {
      DashboardServices.getUserProfile(loginUserDetails.userId).then(
        (response) => {
          if (!response.data) {
            return;
          }

          setSignature(response.data.signFileLink);
        }
      );
    };

    const handleSubmit = () => {
      if (emplyuserId.trim() === "") {
        toast.error("Please Select Employee", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      const reqDto = {
        releaseToUserId: emplyuserId,
        lastWorkingDate: DateUtils.getDateInDDMMYYYY(lastWorkingdate),
        resignationDate: DateUtils.getDateInDDMMYYYY(dateOfResignation),
        activityId: 0,
        activityName: "",
        releaseDocumentBody: [letterContent],
      };

      console.log("reqDto", reqDto);
      issueReleaseLetter(
        loginUserDetail.orgId,
        loginUserDetail.userId,
        reqDto
      ).then((response) => {
        console.log("response", response.data);
        if (response.data == "1") {
          toast.success("Issue Release Letter Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setSelectActivity("");
        } else {
          toast.error("Something Went Wrong", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    };

    return (
      <>
        <div className="formElementGrp">
          <div className="formElement">
            <FormControl className="formControl">
              <Autocomplete
                freeSolo
                className="formAutocompleteField"
                variant="outlined"
                options={employsData || []}
                value={selectedEmploy}
                onChange={(e, newValue) => {
                  setSelectedEmploy(newValue);
                }}
                inputValue={employInputString}
                onInputChange={(e, value) => {
                  setEmployInputString(value);
                }}
                getOptionLabel={(option) => option.employeeName || ""}
                //   filterOptions={(options, { inputValue }) => {
                //     return (
                //       options?.filter(
                //         (option) =>
                //           option?.personName
                //             ?.toLowerCase()
                //             ?.includes(inputValue?.toLowerCase()) ||
                //           option?.specializationName
                //             ?.toLowerCase()
                //             ?.includes(inputValue?.toLowerCase())
                //       ) || []
                //     );
                //   }}
                renderOption={(props, option) => {
                  return (
                    <li
                      {...props}
                      style={{ padding: "4px 12px" }}
                      className="sDocOptList"
                    >
                      <div className="sDname">{option.employeeName}</div>
                      <div className="sDElm">{option.employeeId}</div>
                      <div className="sDElmGrp">
                        {/* <div className="sDElm">
                              Designation:{" "}
                                <span>{option.positionName}</span>
                              </div> */}
                        {option.positions.length > 0 && (
                          <div className="sDElm">
                            Designation:{" "}
                            <span>
                              {option.positions.map((name, index) => (
                                <span key={index}>
                                  {name}
                                  {index < option.positions.length - 1
                                    ? ", "
                                    : ""}
                                </span>
                              ))}
                            </span>
                          </div>
                        )}
                        <div className="sDElm">
                          Department:
                          <span>{option.functionName}</span>
                        </div>
                        {/* <div className="sDElm">
                                {t("language")}: <span>{option.languages}</span>
                              </div> */}
                        {/* <div className="sDElm">
                                {t("patient_in_queue")}:{" "}
                                <span>{option.queueCount}</span>
                              </div> */}
                      </div>
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Employee"
                    className="formAutoComInputField autocompFildPlaceholder"
                  />
                )}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <InputLabel id="employeeJoingDate" className="setCompletionDate">
                Last Working Day
              </InputLabel>
              <ReactDatePicker
                showIcon
                labelId="employeeJoingDate"
                className="formDatePicker"
                selected={lastWorkingdate}
                // minDate={new Date()}
                maxDate={new Date()}
                showYearDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
                onChange={(date) => {
                  handleLastWorkingDate(date);
                }}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <InputLabel id="employeeJoingDate" className="setCompletionDate">
                Date of Resignation
              </InputLabel>
              <ReactDatePicker
                showIcon
                labelId="employeeJoingDate"
                className="formDatePicker"
                selected={dateOfResignation}
                // minDate={new Date()}
                maxDate={lastWorkingdate}
                showYearDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
                onChange={(date) => {
                  handleDateOfResignation(date);
                }}
              />
            </FormControl>
          </div>
        </div>

        {/* <div className="elementFormContainer">
                  <div style={{ color: "#fff" }}>
                    <p>
                    To
                    [Employee Name]
                    [Employee ID]
                    Designation: [Last Designation]
                    Department: [Last Department]
                    Date: [Date]

                    This letter is to confirm that your employment with [Company Name] will conclude on [Last Working
                    Day], as per your resignation submitted on [Date of Resignation]. We appreciate your contributions
                    to the company during your tenure.
                    We wish you all the best in your future endeavours.

                    Sincerely,
                    [Your Name]
                    [Your Designation]
                    [Company Name]
                    [Company Address]
                    [Company Contact Information]
                    </p>
                  </div>
                </div> */}
        {employName && (
          <div className="elementFormContainer">
            <div style={{ marginLeft: "280px" }}>
              <h4 style={{ color: "#fff" }}>Document Preview</h4>
            </div>
            <div style={{ marginBottom: "30px" }}>
              <p style={{ margin: 0, padding: 0, color: "#fff" }}>To</p>
              <p style={{ margin: 0, padding: 0, color: "#fff" }}>
                {employName}
              </p>
              <p style={{ margin: 0, padding: 0, color: "#fff" }}>
                EmployId: {employId}
              </p>
              {/* <p style={{ margin: 0, padding: 0, color: "#fff" }}>
              Designation: {employPosition}
            </p> */}
              <p style={{ margin: 0, padding: 0, color: "#fff" }}>
                Designation:{" "}
                {employPosition.map((position, index) => (
                  <span key={index}>{position}</span>
                ))}
              </p>
              <p style={{ margin: 0, padding: 0, color: "#fff" }}>
                Department: {employDepartment}
              </p>
              <p style={{ margin: 0, color: "#fff" }}>
                Date: {dayjs(currentDate).format("DD-MMM-YYYY")}
              </p>
            </div>

            <p style={{ margin: 0, padding: 0, color: "#fff" }}>
              This letter is to confirm that your employment with{" "}
              {loginUserDetail.orgName} , will conclude on{" "}
              {dayjs(lastWorkingdate).format("DD-MMM-YYYY")}, as per your
              resignation submitted on{" "}
              {dayjs(dateOfResignation).format("DD-MMM-YYYY")}. We appreciate
              your contributions to the company during your tenure.
            </p>

            <p style={{ margin: "10px 0 20px 0", padding: 0, color: "#fff" }}>
              We wish you all the best in your future endeavours.
            </p>

            <div
              style={{ textAlign: "left", marginTop: "30px", color: "#fff" }}
            >
              <p style={{ margin: 0, padding: 0, color: "#fff" }}>Sincerely,</p>
              <p style={{ margin: 0, padding: 0, color: "#fff" }}>
                {loginUserDetail.userName}
              </p>
              <p style={{ margin: 0, padding: 0, color: "#fff" }}>
                {orgPersonPosition}
              </p>
              <p style={{ margin: 0, padding: 0, color: "#fff" }}>
                {loginUserDetail.orgName}
              </p>
              <p style={{ margin: 0, padding: 0, color: "#fff" }}>
                {loginUserDetail.orgAddress}
              </p>
              {/* <p style={{ margin: 0, padding: 0 , color: "#fff"}}>[Company Contact Information]</p> */}
              {Signature && (
                <div className="signAvl">
                  <img
                    src={Signature}
                    alt="Signature"
                    onError={(e) => {
                      console.error("Error loading image:", e.target.src);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        <Button
          ref={releaseLetterRef}
          style={{ display: "none" }}
          //   onClick={(e) => {
          //     const selectedBankingMethod = paymentMethods?.find(
          //       (item) => item.id === selectedMethodId
          //     );
          //     const SellerUnitName = unitsData?.find(
          //       (item) => item.unitId === emplyeeUnits
          //     );
          //     const BuyerName = contractData?.find(
          //       (item) => item.id === selectedContractId
          //     );
          //     const data = {
          //       sellerUnitId: emplyeeUnits,
          //       sellerName: SellerUnitName.unitName,
          //       sellerAdress: SellerUnitName.address,
          //       sellerTaxId: sellerTaxId,
          //       sellerEmailId:
          //         sellerEmailInputId !== "" ? sellerEmailInputId : sellerEmailId,
          //       buyerId: selectedContractId,
          //       buyerName: BuyerName ? BuyerName.contractName : "",
          //       buyerAddress: BuyerName ? BuyerName.address : "",
          //       buyerTaxId: buyerTaxId,
          //       buyerEmailId: buyerEmailId,
          //       placeOfSupply: shipToData,
          //       // invoiceDate : formatDateWithSuffix(new Date(joiningdate)) ,
          //       currency: defultCurrency,
          //       bankingMethod: selectedBankingMethod,
          //       allTermsAndConditions: allTermsAndConditions,
          //       tableData: tableData,
          //       subTotalAmount,
          //       totalAmount,
          //       roundOffAmount,
          //     };
          //     handleShowPreview(data);
          //   }}
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>

        {/* {showTaCDialog && (
          <TermsAndConditions
            showTermsAndConditions={showTaCDialog}
            handelCloseLeaveBalanceModel={(e) => {
              setShowTaCDialog(false);
            }}
            conditionList={orgTacList}
            getTermsAndCondition={(data) => {
              setAllTermsAndConditions(data);
            }}
            selectedConditionList={selectedConditionList}
            setSelectedConditionList={setSelectedConditionList}
          />
        )} */}
      </>
    );
  }
);
