import { FormControl, IconButton, MenuItem, Select } from "@mui/material";
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import React, { useState } from "react";
import { ArrowRepeat } from "react-bootstrap-icons";
import { Bar, Line } from "react-chartjs-2";
import DashboardServices from "../../../../../services/DashboardServices";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController
);
export default function CardManagementTrends() {
  const userDetails = jwtDecode(localStorage.getItem("token"));

  const [trendData, settrendData] = useState({});

  const [filterBy, setFilterBy] = useState("Daily");
  const handleChangefilterop = (event) => {
    setFilterBy(event.target.value);
  };

  const [filterByUnitDoc, setFilterByUnitDoc] = useState("Unit");
  const handleChangefilterUnitDoc = (event) => {
    setFilterByUnitDoc(event.target.value);
  };
  const [filterByIncDec, setFilterByIncDec] = useState("increasing");
  const handleChangeIncDec = (event) => {
    setFilterByIncDec(event.target.value);
  };

  useEffect(() => {
    fetchTrendData();
  }, [filterBy, filterByUnitDoc]);

  const fetchTrendData = () => {
    const reqDto = {
      unitId: 0,
      orgId: userDetails.orgId,
      userId: "",
      periodDailyMnthly: filterBy,
      dataLimit: 0,
      feedbackFromPatientDoctor: "",
      fromDate: "",
      toDate: "",
      unitDoctor: filterByUnitDoc,
      increaseDecrease: "",
    };

    console.log("reqDto", reqDto);

    DashboardServices.refreshManagementTrends(reqDto).then((response) => {
      console.log("response", response.data);
      settrendData(response.data);
    });
  };

  // Assuming filterByIncDec and trendData are defined
  const mdIncreaseTrends = trendData.mdIncreaseTrends || [];
  console.log("mdIncreaseTrends", mdIncreaseTrends);

  // // Now map over mdIncreaseTrends safely
  // const dayMnth = mdIncreaseTrends.map((entry) => entry.dayMnth || "");
  // const unitDoctorName = mdIncreaseTrends.map((entry) => parseInt(entry.unitDoctorId) || 0);
  // const consultationCount = mdIncreaseTrends.map((entry) => entry.consultationCount || 0);

  const mdDecreaseTrends = trendData.mdDecreaseTrends || [];

  console.log("mdDecreaseTrends", mdDecreaseTrends);

  // // Now map over mdIncreaseTrends safely
  // const dayDecreaseMnth = mdDecreaseTrends.map((entry) => entry.dayMnth || "");
  // const unitDoctorDecreaseName = mdDecreaseTrends.map((entry) => entry.unitDoctorId || 0);
  // const consultationDecreaseCount = mdDecreaseTrends.map((entry) => entry.consultationCount || 0);
  let lableSet = new Map();
  let mapofdatasets = new Map();
  let unitlablemap = new Map();
  for (let itr of mdIncreaseTrends) {
    if (!lableSet.has(itr.dayMnth)) {
      lableSet.set(itr.dayMnth, true);
    }

    if (!unitlablemap.has(itr.unitDoctorId)) {
      unitlablemap.set(itr.unitDoctorId, itr.unitDoctorName);
    }
    if (mapofdatasets.has(itr.unitDoctorId)) {
      let dataarry = mapofdatasets.get(itr.unitDoctorId);
      dataarry.push(itr.consultationCount);
      mapofdatasets.set(itr.unitDoctorId, dataarry);
    } else {
      let dataarry = [];
      dataarry.push(itr.consultationCount);
      mapofdatasets.set(itr.unitDoctorId, dataarry);
    }
  }

  let mapofdatasetsdec = new Map();
  for (let itr of mdDecreaseTrends) {
    if (!unitlablemap.has(itr.unitDoctorId)) {
      unitlablemap.set(itr.unitDoctorId, itr.unitDoctorName);
    }
    if (mapofdatasetsdec.has(itr.unitDoctorId)) {
      let dataarry = mapofdatasetsdec.get(itr.unitDoctorId);
      dataarry.push(itr.consultationCount);
      mapofdatasetsdec.set(itr.unitDoctorId, dataarry);
    } else {
      let dataarry = [];
      dataarry.push(itr.consultationCount);
      mapofdatasetsdec.set(itr.unitDoctorId, dataarry);
    }
  }
  let listlable = [];
  lableSet.forEach((values, keys) => {
    listlable.push(keys);
  });
  let charconfidataset = [];
  mapofdatasets.forEach((values, keys) => {
    var obj = {
      label: unitlablemap.get(keys),
      type: "line",
      data: values,
      borderColor: "rgba(0, 128, 0, 1)",
      backgroundColor: "rgba(0, 128, 0, .5)",
      fill: false,
      borderWidth: 1,
      tension: 0.3,
    };
    charconfidataset.push(obj);
  });

  mapofdatasetsdec.forEach((values, keys) => {
    var obj = {
      label: unitlablemap.get(keys),
      type: "line",
      data: values,
      borderColor: "rgba(255, 0, 0, 1)",
      backgroundColor: "rgba(255, 0, 0, .5)",
      fill: false,
      borderWidth: 1,
      tension: 0.3,
    };
    charconfidataset.push(obj);
  });

  console.log(lableSet, mapofdatasets);

  // Bar Chart Data
  const barLineData = {
    // labels: [
    //   "1-Sep-24",
    //   "2-Sep-24",
    //   "3-Sep-24",
    //   "4-Sep-24",
    //   "5-Sep-24",
    //   "6-Sep-24",
    //   "7-Sep-24",
    //   "8-Sep-24",
    //   "9-Sep-24",
    //   "10-Sep-24",
    //   "11-Sep-24",
    //   "12-Sep-24",
    // ],
    labels: listlable,
    datasets: charconfidataset,
  };

  const barLineOptions = {
    responsive: true,
    scales: {
      x: {
        grid: {
          color: "#545454",
        },
      },
      y: {
        grid: {
          color: "#545454",
        },
        beginAtZero: true,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 16,
          padding: 10,
        },
      },
      title: {
        display: false,
        text: "",
      },
    },
    barThickness: 10,
    maxBarThickness: 20,
    minBarLength: 2,
  };

  return (
    <>
      <div className="anDashIndCard manageWidth50">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">
              Trends
              <IconButton
                className={`refreshIcon`}
                onClick={() => fetchTrendData()}
              >
                <ArrowRepeat />
              </IconButton>
            </div>
            <div className="andCrdUnitCount cardFrom">
              <FormControl size="small" className="formControl w90">
                <Select
                  className="formInputField"
                  labelId="filterBy"
                  id="filterBy-select"
                  value={filterByUnitDoc}
                  onChange={handleChangefilterUnitDoc}
                >
                  <MenuItem value={"Unit"}>Unit</MenuItem>
                  <MenuItem value={"Doctor"}>Doctor</MenuItem>
                </Select>
              </FormControl>
              <FormControl size="small" className="formControl w110">
                <Select
                  className="formInputField"
                  labelId="filterBy"
                  id="filterBy-select"
                  value={filterBy}
                  onChange={handleChangefilterop}
                >
                  <MenuItem value={"Monthly"}>Monthly</MenuItem>
                  <MenuItem value={"Daily"}>Daily</MenuItem>
                </Select>
              </FormControl>
              {/* <FormControl size="small" className="formControl w110">
                <Select
                  className="formInputField"
                  labelId="filterBy"
                  id="filterBy-IncDec"
                  value={filterByIncDec}
                  onChange={handleChangeIncDec}
                >
                  <MenuItem value={"increasing"}>Increasing </MenuItem>
                  <MenuItem value={"decreasing"}>Decreasing</MenuItem>
                </Select>
              </FormControl> */}
            </div>
          </div>

          <div className="andCrdGraf ">
            <div className="anDRightGrf fullheightwidth">
              <Line
                className="hw100"
                type="line"
                data={barLineData}
                options={barLineOptions}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
