import { Add, ExpandMoreRounded, Refresh } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Download, Eye, Trash } from "react-bootstrap-icons";
import { downloadFileFromUrl } from "../../../../../../../utils/FileUtils";
import { CustomModal } from "../../../../../../task/view/component/Modals/CustomModal";
import PdfViewer from "../../../../../../../common/PdfViewer";
import { useTranslation } from "react-i18next";
import zhPatientService from "../../../../../../../services/ZhPatientService";
import { toast } from "react-toastify";
import { apiEndPoint } from "../../../../../../../constants/url_provider";
import { getLoginUserDetails } from "../../../../../../../utils/Utils";

export default function TestResult({ data, extraData }) {
  const loginUserDetails = getLoginUserDetails();
  const { t } = useTranslation();
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const [showPdf, setShowPdf] = useState(false);
  const pdfLink = useRef();
  const [testResult, setTestResult] = useState(null);

  const getTestResult = () => {
    if (extraData?.queuePatientId && data?.investigation) {
      let reqDto = data?.investigation?.map((item) => {
        return item.testId;
      });
      zhPatientService
        .getTestResultsExceptRequisitionIds(extraData.queuePatientId, reqDto)
        .then((response) => {
          if (response.data) {
            setTestResult(response.data);
          }
        });
    }
  };

  useEffect(() => {
    getTestResult();
  }, []);

  const handleDownLoadFile = (patient) => {
    //TBD
    console.log("prescription", patient);
    if (patient.fileName !== null) {
      toast.info(t("please_wait_file_is_getting_get_downloaded"), {
        position: toast.POSITION.TOP_RIGHT,
      });

      const newLink = patient.fileName.replace(/\//g, "~");
      console.log("newLink", newLink);
      console.log("fileName", patient);

      // let newFolderLink = patient.folderName
      //   .replace(/\//g, "~")
      //   .replace(/\\/g, "~");
      let newFolderLink =
        patient.fileData.split("/")[3] + "~" + patient.fileData.split("/")[4];
      newFolderLink = newFolderLink.replace(/\//g, "~").replace(/\\/g, "~");

      console.log("newFlderLink", newFolderLink);

      window.open(
        apiEndPoint + `/appTest/downloadFileTest/${newLink}/${newFolderLink}`,
        "_blank",
        "noopener,noreferrer"
      );
    } else {
      toast.error(t("the_file_cannot_be_downloaded"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            {t("test_result")}:
            <span>{/*symptoms gose here when acordion not expend*/}</span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <div className="tskElemHeding hilightHeading">{"Test Results"}</div>

            <IconButton
              className="goPrevousBtn"
              onClick={() => {
                getTestResult();
              }}
            >
              <Refresh />
            </IconButton>
          </div>
          <div className="testResult">
            <table className="testResulttable">
              <thead>
                <tr>
                  <th className="w50p">{t("test_name")}</th>
                  <th className="w15p textCenter">{t("value")}</th>
                  <th className="w15p textCenter">{t("unit")}</th>
                  <th className="w20p">{t("bio_ref_interval")}</th>
                </tr>
              </thead>
              <tbody>
                {testResult?.map((item) => {
                  return (
                    <>
                      <tr>
                        <td colSpan={4} className="singlRow">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <div className="testNameH">{item.testName}</div>
                            {item.fileData && (
                              <>
                                {/* <span
                                onClick={() => {
                                  setShowPdf(true);
                                  pdfLink.current = item.fileData;
                                }}
                              >
                                <Eye />
                              </span> */}
                                <IconButton
                                  className="goPrevousBtn"
                                  style={{
                                    height: "32px",
                                    width: "32px",
                                  }}
                                  onClick={() => {
                                    // downloadFileFromUrl(
                                    //   item.fileData,
                                    //   `${extraData.queuePatientName}_(${extraData.queuePatientId})_${item.testName}`
                                    // );
                                    handleDownLoadFile(item);
                                  }}
                                >
                                  <Download
                                    style={{ height: "42px", width: "24px" }}
                                  />
                                </IconButton>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                      {item?.testResult?.map((parItem) => {
                        let isResultOutOfRange = false;
                        try {
                          if (
                            parItem.parameterRefRange?.split("-").length === 2
                          ) {
                            isResultOutOfRange =
                              parseFloat(parItem.parameterValue) <
                                parseFloat(
                                  parItem.parameterRefRange?.split("-")[0]
                                ) ||
                              parseFloat(parItem.parameterValue) >
                                parseFloat(
                                  parItem.parameterRefRange?.split("-")[1]
                                );
                          } else if (
                            parItem.parameterRefRange?.split("<").length === 2
                          ) {
                            isResultOutOfRange =
                              parseFloat(parItem.parameterValue) < 0 ||
                              parseFloat(parItem.parameterValue) >
                                parseFloat(
                                  parItem.parameterRefRange?.split(">")[1]
                                );
                          }
                        } catch (err) {}

                        return (
                          <tr>
                            <td>
                              <div className="testElmGrp">
                                <div className="testElmNM">
                                  {parItem.parameterName}
                                </div>
                                <div className="testElmDtl">
                                  {/* Method: ENZymatic */}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                className={`testValue textCenter  ${
                                  isResultOutOfRange
                                    ? "outOfRange"
                                    : "riskValue"
                                }`}
                              >
                                {parItem.parameterValue}
                              </div>
                            </td>
                            <td>
                              <div className="testUnit textCenter">
                                {parItem.parameterUnit}
                              </div>
                            </td>
                            <td>
                              {parItem.parameterRefRange && (
                                <div className="testNrmlRange">
                                  <div className="tstnRangeElm">
                                    {t("desirable")} :{" "}
                                    {parItem.parameterRefRange}
                                  </div>
                                </div>
                              )}
                              {/* <div className="testNrmlRange">
                                  {parItem.parameterRange && (
                                    <div className="tstnRangeElm">
                                      Desirable : &lt;{" "}
                                      {parItem.parameterRange.split("-")[0]}
                                    </div>
                                  )}
                                  <div className="tstnRangeElm">
                                    Borderline: {parItem.parameterRange}
                                  </div>

                                  {parItem.parameterRange && (
                                    <div className="tstnRangeElm">
                                      High : &gt;/=
                                      {parItem.parameterRange.split("-")[1]}
                                    </div>
                                  )}
                                </div> */}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="bb"></div>
        </AccordionDetails>
      </Accordion>
      {showPdf && (
        <CustomModal
          modalTitle={t("test_result")}
          // style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowPdf(false);
          }}
          className={"width90vwModel"}
        >
          <PdfViewer pdfUrl={pdfLink.current} />
        </CustomModal>
      )}
    </>
  );
}
