import {
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { getLoginUserDetails } from "../../../../utils/Utils";
import { useTranslation } from "react-i18next";
import {
  getClientOfOrg,
  getCurrencyList,
  getItems,
  unitDetilsForProforma,
} from "../../../../services/AdminService";
import TaskService from "../../../../services/TaskService";
import { Trash } from "react-bootstrap-icons";
import { convertFileToBase64_Split } from "../../../../utils/FileUtils";
import ReactDatePicker from "react-datepicker";
import IntegerTextField from "../../../../common/IntegerTextField";
import { FloatTextField } from "../../../../common/FloatTextField";
import { toast } from "react-toastify";

export const PoInvoice = () => {
  const loginUserDetail = getLoginUserDetails();
  const [salaryInfoList, setSalaryInfoList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const { t } = useTranslation();
  const [unitsData, setUnitData] = useState([]);
  const [emplyeeUnits, setEmplyeeUnits] = useState("");
  const [showBankDetails, setShowBankDetails] = useState(false);
  const [selectedMethodId, setSelectedMethodId] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [corCountry, setCorCountry] = useState("");

  const [selectedContractId, setSelectedContractId] = useState("");
  const [shipToData, setShipToData] = useState("");
  const [itemData, setItemData] = useState([]);
  const [defultCurrency, setdefultCurrency] = useState("");
  const [sellerTaxId, setsellerTaxId] = useState("");
  const [sellerEmailValue, setsellerEmailValue] = useState([]);
  const [sellerEmailId, setsellerEmailId] = useState("");
  const [sellerEmailInputId, setsellerEmailInputId] = useState("");

  const [contractData, setContractData] = useState([]);
  const [buyerTaxId, setBuyerTaxId] = useState("");
  const [buyerEmailId, setBuyerEmailId] = useState("");
  const [notesSpecialInstruction, setNotesSpecialInstruction] = useState("");
  const [buyerCountryCode, setBuyerCountryCode] = useState("");
  const [selectSellerState, setSelectSellerState] = useState("");
  const [selectBuyerState, setSelectBuyerState] = useState("");
  const [roundOffAmount, setRoundOffAmount] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [itemType, setItemType] = useState(null);
  const [refNo, setRefNo] = useState("");
  const [itemUOMList, setItemUOMList] = useState([]);
  const [refDocument, setRefDocument] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const fileUploadRef = useRef(null);
  const [discountType, setDiscountType] = useState("discountOnItem");

  const [orgTacList, setOrgTacList] = useState([]);
  const [selectedConditionList, setSelectedConditionList] = useState([]);

  const [showTaCDialog, setShowTaCDialog] = useState(false);
  const [allTermsAndConditions, setAllTermsAndConditions] = useState(null);
  const [totalDiscountAmount, setTotalDiscountAmount] = useState("");
  const [tableData, setTableData] = useState([]);

  const [currencyList, setCurrencyList] = useState([]);

  const [addTableData, setAddTableData] = useState({
    proformaInvoId: 1,
    itemId: 0,
    itemName: "",
    item: null,
    itemDescription: "",
    uom: "",
    quantity: "",
    rate: 0,
    disePc: 0,
    sgstPc: 0,
    cgstPc: 0,
    igstPc: 0,
    taxPc: 0,
    diseAmt: 0,
    sgstAmt: 0,
    amount: 0,
    unitDetails: "",
  });

  const handleChangEemplyeeUnits = (event) => {
    setEmplyeeUnits(event.target.value);
  };

  const fetchContractData = () => {
    // getContractList(loginUserDetail.orgId).then((response) => {
    //   console.log("getContractList", response.data);
    //   setContractData(response.data);
    // });
    getClientOfOrg(loginUserDetail.orgId).then((response) => {
      console.log("getClientOfOrg", response.data);
      setContractData(response.data);
    });
  };

  const fetchOrgTermsAndConditionData = () => {
    TaskService.getOrgTermsAndConditions(
      loginUserDetail.orgId,
      "PROFORMA INVOICE"
    ).then((response) => {
      if (response.data) {
        const tmpCondList = response.data;
        setOrgTacList(tmpCondList);
        setSelectedConditionList(tmpCondList);
      }
    });
  };

  useEffect(() => {
    fetchContractData();
    fetchOrgTermsAndConditionData();

    unitDetilsForProforma(loginUserDetail.orgId).then((response) => {
      console.log("response unit of proforma", response.data);
      setUnitData(response.data);
    });

    getItems(loginUserDetail.orgId).then((response) => {
      if (response.data) {
        setItemData(response.data);
      }
    });

    getCurrencyList().then((response) => {
      console.log("getCurrencyList", response.data);
      setCurrencyList(response.data);
    });
  }, []);

  useEffect(() => {
    if (loginUserDetail.signleUnitId !== "0") {
      setEmplyeeUnits(loginUserDetail.signleUnitId);
    }

    if (loginUserDetail.signleUnitId == "0" && unitsData.length === 1) {
      setEmplyeeUnits(unitsData[0].unitId);
    }
  }, [unitsData]);

  const getFileDataObj = async (file) => {
    const dto = {
      fileName: file.name,
      fileDataLink: await convertFileToBase64_Split(file),
    };
    return dto;
  };

  const filteredItemList = useMemo(() => {
    if (itemData && itemType) {
      return itemData?.filter((item) => item.type === itemType) || [];
    }
    return [];
  }, [itemData, itemType]);

  const calculateAmount1 = (data) => {
    const { quantity, rate, disePc, cgstPc, sgstPc, igstPc, taxPc } = data;
    console.log("rate", rate);

    const qty = parseFloat(quantity) || 0;
    const r = parseFloat(rate) || 0;
    const discount = parseFloat(disePc) || 0;

    // Calculate the basic amount
    let amount = qty * r;

    // Apply discount
    const diseAmt = amount * (discount / 100);
    let sgstAmt = 0;
    let igstAmt = 0;
    let cgstAmt = 0;
    let taxAmt = 0;
    amount -= diseAmt;

    // Add tax based on organization code
    if (cgstPc) {
      cgstAmt = (amount * (parseFloat(cgstPc) || 0)) / 100;
    }
    if (sgstPc) {
      sgstAmt = (amount * (parseFloat(sgstPc) || 0)) / 100;
    }
    if (igstPc) {
      igstAmt = (amount * (parseFloat(igstPc) || 0)) / 100;
    }
    if (taxPc) {
      taxAmt = (amount * (parseFloat(taxPc) || 0)) / 100;
    }
    amount += cgstAmt + sgstAmt + igstAmt + taxAmt;

    // Update the amount in the state
    // setAddTableData(prevData => ({
    //     ...prevData,
    //     amount: amount.toFixed(2),  // Store amount as a string with 2 decimal places
    // }));

    return {
      amount: amount.toFixed(2),
      value: amount.toFixed(2),
      diseAmt: diseAmt.toFixed(2),
      sgstAmt: sgstAmt.toFixed(2),
      cgstAmt: cgstAmt.toFixed(2),
      igstAmt: igstAmt.toFixed(2),
      taxAmt: taxAmt.toFixed(2),
    };
  };

  const handleJoiningDate = (date) => {
    setInvoiceDate(date);
  };

  const handleAddColumn = () => {
    if (!addTableData.item) {
      toast.error("Please select item");
      return;
    }
    if (!addTableData.quantity) {
      toast.error("Please enter quentity");
      return;
    }
    if (!addTableData.rate) {
      toast.error("Please enter rate");
      return;
    }

    const nextId =
      tableData.length > 0
        ? tableData[tableData.length - 1].proformaInvoId + 1
        : 1;

    setAddTableData((prevData) => ({
      ...prevData,
      proformaInvoId: nextId,
    }));

    setTableData((prevTableData) => [
      ...prevTableData,
      { ...addTableData, proformaInvoId: nextId },
    ]);

    setAddTableData({
      proformaInvoId: 0,
      itemId: 0,
      itemName: "",
      item: null,
      itemDescription: "",
      uom: "",
      quantity: "",
      rate: 0,
      disePc: 0,
      sgstPc: 0,
      cgstPc: 0,
      igstPc: 0,
      taxPc: 0,
      diseAmt: 0,
      sgstAmt: 0,
      amount: 0,
      unitDetails: "",
    });
  };

  const handleDelete = (id) => {
    setTableData((prevTableData) =>
      prevTableData.filter((row) => row.proformaInvoId !== id)
    );
  };

  const subTotalAmount = useMemo(() => {
    if (!tableData || tableData.length === 0) {
      setTotalAmount(0);
      setRoundOffAmount(0);
      return 0;
    } else {
      let totalValue = 0;
      tableData?.forEach((item) => {
        totalValue += parseFloat(item.amount);
      });
      totalValue = parseFloat(totalValue.toFixed(2));
      const total = Math.round(totalValue);

      const roudnOff = parseFloat((total - totalValue).toFixed(2));
      setTotalAmount(total);
      setRoundOffAmount(roudnOff);
      return totalValue;
    }
  }, [tableData]);

  return (
    <>
      <div class="tskElemHeding mt10">Seller Details</div>

      <div className="formElementGrp">
        <div className="formElement ">
          <FormControl className="formControl">
            <InputLabel id="selectUnits">Seller*</InputLabel>
            <Select
              required
              labelId="selectUnits"
              id="employee-units"
              value={
                emplyeeUnits === undefined || emplyeeUnits === "undefined"
                  ? ""
                  : emplyeeUnits
              }
              label="Seller"
              onChange={handleChangEemplyeeUnits}
              className="formInputField"
              variant="outlined"
              renderValue={(value) => {
                const selectedContract = unitsData.find(
                  (doc) => doc.unitId === value
                );
                return `${selectedContract?.unitName}`;
              }}
            >
              {unitsData.map((unit, index) => (
                <MenuItem key={index} value={unit.unitId}>
                  <div style={{ padding: "4px 12px" }} className="sDocOptList">
                    <div className="sDname">{unit.unitName}</div>
                    <div className="sDElm">{unit.address}</div>
                    {/* <div className="sDElmGrp">
                                  {unit?.unitEmail && (
                                  <div className="sDElm">
                                      unitEmail :{" "}
                                      <span>
                                        {unit.unitEmail}
                                      </span>
                                    </div>
                                  )}
                                    {unit?.currency && (
                                    <div className="sDElm">
                                    currency:{" "}
                                      <span>{unit.currency}</span>
                                    </div>
                                    )}
                                  </div> */}
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="formElement">
          <FormControl className="formControl">
            <TextField
              label="Tax/GSTIN Id"
              required
              placeholder="Enter Seller Tax/GSTIN Id"
              variant="outlined"
              className="formTextFieldArea"
              autoComplete="off"
              value={sellerTaxId}
              onChange={(e) => setsellerTaxId(e.target.value)}
            />
          </FormControl>
        </div>

        <div className="formElement">
          <FormControl className="formControl">
            <Autocomplete
              noOptionsText={"Please Type email Id"}
              className="formAutocompleteField"
              variant="outlined"
              value={sellerEmailId}
              options={sellerEmailValue}
              getOptionLabel={(option) => option}
              freeSolo
              inputValue={sellerEmailInputId}
              onChange={(e, newValue) => {
                console.log("new value = ", newValue);
                setsellerEmailId(newValue);
              }}
              onInputChange={(e, newValue) => {
                console.log("new value = ", newValue);
                setsellerEmailInputId(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="formAutoComInputField autocompFildPlaceholder"
                  label={"Email Id"}
                  placeholder={"Seller Email Id"}
                  required
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </FormControl>
        </div>
      </div>

      <div class="tskElemHeding mt10">Buyer Details</div>

      <div className="formElementGrp">
        <div className="formElement">
          <FormControl className="formControl">
            <InputLabel id="employeeTitle">Buyer Details*</InputLabel>
            {/* <Controller
                name="employeeTitle"
                control={control}
                defaultValue={"Mr"}
                render={({ field }) => (
                )}
              /> */}
            <Select
              labelId="employeeTitle"
              id="selectemployeeTitle"
              label="Buyer"
              className="formInputField"
              value={
                selectedContractId === undefined ||
                selectedContractId === "undefined"
                  ? ""
                  : selectedContractId
              }
              onChange={(e) => setSelectedContractId(e.target.value)}
              variant="outlined"
              renderValue={(value) => {
                const selectedContract = contractData.find(
                  (doc) => doc.userId === value
                );
                return `${selectedContract?.userName}`;
              }}
            >
              {contractData.map((contract) => (
                <MenuItem
                  key={contract.userId}
                  value={contract.userId}
                  // value={contract.id}
                >
                  <div style={{ padding: "4px 12px" }} className="sDocOptList">
                    <div className="sDname">{contract.userName}</div>
                    {/* <div className="sDname">{contract.contractName}</div> */}
                    {contract?.address && (
                      <div className="sDElm">{contract.address}</div>
                    )}
                    <div className="sDElmGrp">
                      {contract?.emailId && (
                        <div className="sDElm">
                          emailId: <span>{contract.emailId}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="formElement">
          <FormControl className="formControl">
            <TextField
              label="Tax Id"
              required
              placeholder="Enter Buyer Tax Id"
              variant="outlined"
              className="formTextFieldArea"
              autoComplete="off"
              value={buyerTaxId}
              onChange={(e) => setBuyerTaxId(e.target.value)}
            />
          </FormControl>
        </div>

        <div className="formElement">
          <FormControl className="formControl">
            <TextField
              label="Email"
              required
              placeholder="Enter Email"
              variant="outlined"
              className="formTextFieldArea"
              autoComplete="off"
              value={buyerEmailId}
              onChange={(e) => setBuyerEmailId(e.target.value)}
            />
          </FormControl>
        </div>
      </div>

      <div class="tskElemHeding mt10"></div>

      <div className="formElementGrp flex-wrap">
        <div className="formElement width13">
          <FormControl className="formControl">
            <TextField
              label="Place of Supply"
              required
              placeholder="Enter Place of Supply"
              variant="outlined"
              className="formTextFieldArea"
              autoComplete="off"
              value={shipToData}
              onChange={(e) => setShipToData(e.target.value)}
            />
          </FormControl>
        </div>

        <div className="formElement width13">
          <FormControl className="formControl">
            <InputLabel id="employeeJoingDate" className="setCompletionDate">
              Invoice Date*
            </InputLabel>
            <ReactDatePicker
              showIcon
              labelId="employeeJoingDate"
              className="formDatePicker"
              selected={invoiceDate}
              // minDate={new Date()}
              maxDate={new Date()}
              showYearDropdown
              yearDropdownItemNumber={100}
              scrollableYearDropdown
              showMonthDropdown
              dateFormat="dd/MM/yyyy"
              onChange={(date) => {
                handleJoiningDate(date);
              }}
            />
          </FormControl>
        </div>

        <div className="formElement width13">
          <FormControl className="formControl">
            <InputLabel id="selectUnits">Currency*</InputLabel>
            <Select
              required
              labelId="selectUnits"
              id="employee-units"
              value={defultCurrency}
              label="Currency"
              onChange={(e) => setdefultCurrency(e.target.value)}
              className="formInputField"
              variant="outlined"
            >
              {currencyList.map((currency) => (
                <MenuItem key={currency} value={currency}>
                  {currency}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="formElement width13">
          <FormControl className="formControl">
            <TextField
              label="Reference No."
              // required
              placeholder="Enter Reference Number"
              variant="outlined"
              className="formTextFieldArea"
              autoComplete="off"
              value={refNo}
              onChange={(e) => setRefNo(e.target.value)}
            />
          </FormControl>
        </div>

        <div className="formBtnElement">
          <Button
            variant="Outlined"
            color="primary"
            onClick={() => fileUploadRef.current.click()}
            sx={{ marginBottom: "10px" }}
            className={` ${
              refDocument ? "dfultDarkSecondaryBtn" : "comBtnOutline"
            }`}
          >
            {t("select_files")}
          </Button>
          <input
            ref={fileUploadRef}
            type="file"
            style={{ display: "none" }}
            onChange={async (e) => {
              const fileList = e.target.files;
              const data = await getFileDataObj(fileList[0]);
              fileUploadRef.current.value = null;
              setRefDocument(data);
            }}
          />
        </div>
        <div className="formElement width13">
          {refDocument && (
            <>
              <span style={{ color: "#eee" }}>{refDocument.fileName}</span>{" "}
              <IconButton
                style={{ color: "red" }}
                onClick={() => {
                  setRefDocument(null);
                }}
              >
                <Trash />
              </IconButton>
            </>
          )}
        </div>
      </div>

      <div className="formElementGrp flex-wrap">
        <div className="formElement width13">
          <FormControl className="formControl">
            <InputLabel id="selectItemType">Discount Type</InputLabel>
            <Select
              required
              labelId="selectItemType"
              id="employee-units"
              value={discountType}
              label="Currency"
              onChange={(e) => setDiscountType(e.target.value)}
              className="formInputField"
              variant="outlined"
            >
              <MenuItem key={"discountOnItem"} value={"discountOnItem"}>
                {"Discount On Item Level"}
              </MenuItem>
              <MenuItem key={"discountOnTotal"} value={"discountOnTotal"}>
                {"Discount On Total"}
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div class="tskElemHeding mt10">Items Details</div>

      <div className="indentFormtable">
        <div className="indtableResponsive">
          <table>
            <thead>
              <tr>
                <th className="w200">Item Name</th>
                <th className="w200">Description</th>
                <th>Quantity</th>
                <th>Rate</th>
                <th>UOM</th>
                <th>Discount</th>
                {buyerCountryCode == "356" &&
                loginUserDetail.orgCountryCode == "356" ? (
                  <>
                    {selectSellerState !== "" &&
                    selectBuyerState !== "" &&
                    selectSellerState === selectBuyerState ? (
                      <>
                        <th className="indntQunt">CGST</th>
                        <th className="indntQunt">SGST</th>
                      </>
                    ) : (
                      <th className="indntQunt">IGST</th>
                    )}
                  </>
                ) : (
                  <th className="indntQunt">Tax</th>
                )}
                <th className="widthMin120">Amount</th>
                <th className="mw80"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="employeeTitle">Item Name</InputLabel>

                      <Select
                        labelId="employeeTitle"
                        id="selectemployeeTitle"
                        label="Item Name"
                        className="formTextFieldArea"
                        variant="outlined"
                        value={addTableData.item}
                        renderValue={(value) => {
                          const hsnCd = value.hsnCd ? `(${value.hsnCd})` : "";
                          return `${value?.itemName} ${hsnCd}`;
                        }}
                        onChange={(e) => {
                          const selectedItem = e.target.value;
                          setAddTableData((prevData) => ({
                            ...prevData,
                            itemId: selectedItem.itemId,
                            itemName: selectedItem ? selectedItem.itemName : "",
                            item: selectedItem,
                            itemDescription: selectedItem.itemDescription,
                            // uom: selectedItem.uom,
                          }));
                          // field.onChange(e.target.value);
                        }}
                      >
                        {filteredItemList?.map((item) => (
                          <MenuItem value={item}>
                            <div
                              style={{ padding: "4px 12px" }}
                              className="sDocOptList"
                            >
                              <div className="sDname">{item.itemName}</div>
                              {/* <div className="sDname">{contract.contractName}</div> */}
                              {/* {item?.itemDescription && (
                                  <div className="sDElm">
                                    {item.itemDescription}
                                  </div>
                                )} */}
                              <div className="sDElmGrp">
                                {item?.hsnCd && (
                                  <div className="sDElm">
                                    hsnCd: <span>{item.hsnCd}</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </td>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label="Description"
                        variant="outlined"
                        className="descptionTextFild"
                        // disabled={!item.editMode}
                        multiline
                        minRows={3}
                        // required
                        fullWidth
                        size="small"
                        autoComplete="off"
                        value={addTableData.itemDescription}
                        onChange={(e) => {
                          setAddTableData((prevData) => {
                            let temp = {
                              ...prevData,
                              itemDescription: e.target.value,
                            };
                            return temp;
                          });
                        }}
                      />
                    </FormControl>
                  </div>
                </td>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <IntegerTextField
                        label="Quantity"
                        variant="outlined"
                        className="formTextFieldArea"
                        // disabled={!item.editMode}
                        required
                        fullWidth
                        size="small"
                        autoComplete="off"
                        value={addTableData.quantity}
                        onChange={(data) => {
                          if (data) {
                            setAddTableData((prevData) => {
                              let temp = {
                                ...prevData,
                                quantity: data,
                              };
                              const amount = calculateAmount1(temp);
                              return { ...temp, ...amount };
                            });
                          }
                        }}
                      />
                    </FormControl>
                  </div>
                </td>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <FloatTextField
                        label="Rate"
                        variant="outlined"
                        className="formTextFieldArea"
                        // disabled={!item.editMode}
                        required
                        fullWidth
                        size="small"
                        autoComplete="off"
                        value={addTableData.rate}
                        //   onChange={(e) => {
                        //     setAddTableData((prevData) => ({
                        //       ...prevData,
                        //       rate: e.target.value,
                        //     }));
                        //     calculateAmount();
                        // }}
                        onChange={(data) => {
                          setAddTableData((prevData) => {
                            let temp = {
                              ...prevData,
                              rate: data,
                            };
                            const amount = calculateAmount1(temp);
                            return { ...temp, ...amount };
                          });
                        }}
                      />
                    </FormControl>
                  </div>
                </td>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      {/* <TextField
                          label="Uom"
                          variant="outlined"
                          className="formTextFieldArea"
                          // disabled={!item.editMode}
                          // required
                          fullWidth
                          size="small"
                          autoComplete="off"
                          value={addTableData.uom}
                          onChange={(e) => {
                            setAddTableData((prevData) => {
                              let temp = {
                                ...prevData,
                                uom: e.target.value,
                              };
                              // const amount = calculateAmount1(temp);
                              return { ...temp };
                            });
                          }}
                        /> */}

                      <InputLabel id="selectUOM">UOM</InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="selectUOM"
                        id="select-UOM"
                        label="UOM"
                        value={addTableData.uom}
                        onChange={(e) => {
                          setAddTableData((prevData) => {
                            let temp = {
                              ...prevData,
                              uom: e.target.value,
                            };
                            return { ...temp };
                          });
                        }}
                      >
                        {itemUOMList.length > 0 &&
                          itemUOMList.map((item) => (
                            <MenuItem value={item} key={item}>
                              {item}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </td>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <FloatTextField
                        label="Discount(%)"
                        variant="outlined"
                        className="formTextFieldArea"
                        // disabled={!item.editMode}
                        //   required
                        autoComplete="off"
                        fullWidth
                        size="small"
                        value={addTableData.disePc}
                        max={100}
                        min={0}
                        onChange={(data) => {
                          setAddTableData((prevData) => {
                            let temp = {
                              ...prevData,
                              disePc: data,
                            };
                            const amount = calculateAmount1(temp);
                            return { ...temp, ...amount };
                          });
                        }}
                      />
                    </FormControl>
                  </div>
                </td>

                {buyerCountryCode == "356" &&
                loginUserDetail.orgCountryCode == "356" ? (
                  <>
                    {selectSellerState !== "" &&
                    selectBuyerState !== "" &&
                    selectSellerState === selectBuyerState ? (
                      <>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <FloatTextField
                                label="CGST(%)"
                                variant="outlined"
                                className="formTextFieldArea"
                                autoComplete="off"
                                // disabled={!item.editMode}
                                //   required
                                fullWidth
                                size="small"
                                value={addTableData.cgstPc}
                                max={100}
                                min={0}
                                onChange={(data) => {
                                  setAddTableData((prevData) => {
                                    const temp = {
                                      ...prevData,
                                      cgstPc: data,
                                    };
                                    const amount = calculateAmount1(temp);
                                    return { ...temp, ...amount };
                                  });
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>

                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <FloatTextField
                                label="SGST(%)"
                                variant="outlined"
                                className="formTextFieldArea"
                                autoComplete="off"
                                // disabled={!item.editMode}
                                //   required
                                fullWidth
                                size="small"
                                value={addTableData.sgstPc}
                                max={100}
                                min={0}
                                onChange={(data) => {
                                  setAddTableData((prevData) => {
                                    const temp = {
                                      ...prevData,
                                      sgstPc: data,
                                    };
                                    const amount = calculateAmount1(temp);
                                    return { ...temp, ...amount };
                                  });
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>
                      </>
                    ) : (
                      <td>
                        <div className="formElement">
                          <FormControl className="formControl">
                            <FloatTextField
                              label="IGST(%)"
                              variant="outlined"
                              className="formTextFieldArea"
                              autoComplete="off"
                              // disabled={!item.editMode}
                              //   required
                              fullWidth
                              size="small"
                              value={addTableData.igstPc}
                              max={100}
                              min={0}
                              onChange={(data) => {
                                setAddTableData((prevData) => {
                                  const temp = {
                                    ...prevData,
                                    igstPc: data,
                                  };
                                  const amount = calculateAmount1(temp);
                                  return { ...temp, ...amount };
                                });
                              }}
                            />
                          </FormControl>
                        </div>
                      </td>
                    )}
                  </>
                ) : (
                  <td>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <FloatTextField
                          label="Tax(%)"
                          variant="outlined"
                          className="formTextFieldArea"
                          autoComplete="off"
                          // disabled={!item.editMode}
                          // required
                          fullWidth
                          size="small"
                          value={addTableData.taxPc}
                          max={100}
                          min={0}
                          onChange={(data) => {
                            setAddTableData((prevData) => {
                              const temp = {
                                ...prevData,
                                taxPc: data,
                              };
                              const amount = calculateAmount1(temp);
                              return { ...temp, ...amount };
                            });
                          }}
                        />
                      </FormControl>
                    </div>
                  </td>
                )}

                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label="Amount"
                        variant="outlined"
                        className="formTextFieldArea"
                        autoComplete="off"
                        disabled={true}
                        // required
                        fullWidth
                        size="small"
                        // value={item.itemDescription}
                        // onChange={(e) => {
                        //   item.itemDescription = e.target.value;
                        //   setRefreshCount((r) => r + 1);
                        // }}
                        value={addTableData.amount || 0}
                        readOnly
                      />
                    </FormControl>
                  </div>
                </td>
                <td>
                  <div className="iconBtnGroup">
                    <Button className="saveBtn" onClick={handleAddColumn}>
                      Save
                    </Button>
                  </div>
                </td>
              </tr>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td>{`${row.itemName}${
                    row?.item?.hsnCd ? `( HSN Code:${row?.item?.hsnCd} )` : ""
                  }`}</td>
                  <td>{row.itemDescription}</td>
                  <td>
                    <div className="textOnly">{row.quantity}</div>
                  </td>
                  <td>
                    <div className="textOnly">{row.rate}</div>
                  </td>
                  <td>
                    <div className="textOnly">{row.uom}</div>
                  </td>
                  <td>
                    <div className="textOnly">{row.disePc}</div>
                  </td>
                  {buyerCountryCode == "356" &&
                  loginUserDetail.orgCountryCode == "356" ? (
                    <>
                      {selectSellerState !== "" &&
                      selectBuyerState !== "" &&
                      selectSellerState === selectBuyerState ? (
                        <>
                          <td>
                            <div className="textOnly">{row.cgstPc}</div>
                          </td>
                          <td>
                            <div className="textOnly">{row.sgstPc}</div>
                          </td>
                        </>
                      ) : (
                        <td>
                          <div className="textOnly">{row.igstPc}</div>
                        </td>
                      )}
                    </>
                  ) : (
                    <td>
                      <div className="textOnly">{row.taxPc}</div>
                    </td>
                  )}
                  <td>
                    <div className="textOnly">{row.amount}</div>
                  </td>
                  <td>
                    {/* <div className="iconBtnGroup">
                      <IconButton>
                        <Edit />
                      </IconButton>
                    </div> */}
                    <div className="iconBtnGroup">
                      <IconButton
                        onClick={() => handleDelete(row.proformaInvoId)}
                      >
                        <Trash />
                      </IconButton>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>

            <tfoot>
              <tr>
                <td
                  colSpan={
                    selectSellerState !== "" &&
                    selectBuyerState !== "" &&
                    selectSellerState === selectBuyerState
                      ? 8
                      : 7
                  }
                >
                  <div className="textOnly tvText">Sub Total</div>
                </td>
                <td>
                  <div className="textOnly fw600">{subTotalAmount}</div>
                </td>
              </tr>
              {discountType === "discountOnTotal" && (
                <tr>
                  <td
                    colSpan={
                      selectSellerState !== "" &&
                      selectBuyerState !== "" &&
                      selectSellerState === selectBuyerState
                        ? 8
                        : 7
                    }
                  >
                    <div className="textOnly tvText">Discount Amount</div>
                  </td>
                  <td>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <FloatTextField
                          label="Discount Amount"
                          variant="outlined"
                          className="formTextFieldArea"
                          autoComplete="off"
                          // disabled={!item.editMode}
                          // required
                          min={0}
                          max={subTotalAmount}
                          fullWidth
                          size="small"
                          value={totalDiscountAmount}
                          onChange={(data) => {
                            setTotalDiscountAmount(data);
                          }}
                        />
                      </FormControl>
                    </div>
                  </td>
                </tr>
              )}
              {/* {otherCharges &&
                  otherCharges.length > 0 &&
                  otherCharges.map((item) => {
                    return (
                      <tr>
                        <td colSpan={6}></td>
                        <td colSpan={1}>{item.itemCharge}</td>
                        <td colSpan={1}>
                          {item.chargesRate}
                          <span style={{ float: "right" }}>
                            <Tooltip title={t("remove")}>
                              <Trash
                                height={"1.2rem"}
                                width={"1.2rem"}
                                cursor={"pointer"}
                                onClick={() => {
                                  let tmpList = otherCharges.filter(
                                    (obj) =>
                                      JSON.stringify(obj) !==
                                      JSON.stringify(item)
                                  );
                                  setOtherCharges(tmpList);
                                }}
                              />
                            </Tooltip>
                          </span>
                        </td>
                      </tr>
                    );
                  })} */}

              {/* {showAddOtherCharges && (
                  <tr>
                    {!loginUserDetail.orgCountryCode == "356" && (
                      <td colSpan={3}></td>
                    )}
                    <td colSpan={2}>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            size="small"
                            label={t("charge_description")}
                            className="formTextFieldArea"
                            value={otherChargesDescription}
                            onChange={(e) => {
                              setOtherChargesDescription(e.target.value);
                            }}
                          />
                        </FormControl>
                      </div>
                    </td>
                    <td colSpan={2}>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            type="number"
                            className="formTextFieldArea"
                            size="small"
                            label={t("price")}
                            value={otherChargesValue}
                            onChange={(e) => {
                              setOtherChargesValue(e.target.value);
                            }}
                          />
                        </FormControl>
                      </div>
                    </td>

                    {loginUserDetail.orgCountryCode == "356" ? (
                      <>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="CGST(%)"
                                variant="outlined"
                                className="formTextFieldArea"
                                // disabled={!item.editMode}
                                //   required
                                fullWidth
                                size="small"
                                value={otherCgst}
                                //   onChange={(e) => {
                                //     setAddTableData((prevData) => ({
                                //       ...prevData,
                                //       cgstPc: e.target.value,
                                //     }));
                                //     calculateAmount();
                                // }}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setOtherCgst(value);
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>

                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="SGST(%)"
                                variant="outlined"
                                className="formTextFieldArea"
                                // disabled={!item.editMode}
                                //   required
                                fullWidth
                                size="small"
                                value={otherSgst}
                                //   onChange={(e) => {
                                //     setAddTableData((prevData) => ({
                                //       ...prevData,
                                //       sgstPc: e.target.value,
                                //     }));
                                //     calculateAmount();
                                // }}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setOtherSgst(value);
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>

                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="IGST(%)"
                                variant="outlined"
                                className="formTextFieldArea"
                                // disabled={!item.editMode}
                                //   required
                                fullWidth
                                size="small"
                                value={otherIgst}
                                //   onChange={(e) => {
                                //     setAddTableData((prevData) => ({
                                //       ...prevData,
                                //       igstPc: e.target.value,
                                //     }));
                                //     calculateAmount();
                                // }}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setOtherIgst(value);
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>
                      </>
                    ) : (
                      <td>
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              label="Tax(%)"
                              variant="outlined"
                              className="formTextFieldArea"
                              // disabled={!item.editMode}
                              // required
                              fullWidth
                              size="small"
                              value={otherTax}
                              //     onChange={(e) => {
                              //       setAddTableData((prevData) => ({
                              //         ...prevData,
                              //         taxPc: e.target.value,
                              //       }));
                              //       calculateAmount();
                              //   }}
                              onChange={(e) => {
                                const value = e.target.value;
                                setOtherTax(value);
                              }}
                            />
                          </FormControl>
                        </div>
                      </td>
                    )}

                    <td colSpan={1}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Tooltip title={t("add_charge")}>
                          <Button
                            onClick={() => {
                              const temp = {
                                itemCharge: otherChargesDescription,
                                chargesRate: parseFloat(otherChargesValue),
                                chargesAmount: 0,
                                sgstPc: otherSgst,
                                cgstPc: otherCgst,
                                igstPc: otherIgst,
                                taxPc: otherTax,
                                sgstAmt: 0,
                                igstAmt: 0,
                                cgstAmt: 0,
                                taxAmt: 0,
                                taxInvoiceId: 0,
                              };
                              if (otherChargesDescription.trim() !== "") {
                                setOtherCharges((prev) => [...prev, temp]);
                                setOtherChargesDescription("");
                                setOtherChargesValue(0);
                                setShowAddOtherCharges(false);
                              }
                            }}
                          >
                            {t("save")}
                          </Button>
                        </Tooltip>
                        <Tooltip title={t("close")}>
                          <Button
                            onClick={() => {
                              setShowAddOtherCharges(false);
                            }}
                          >
                            <Cancel />
                          </Button>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                )} */}

              {/* {!showAddOtherCharges && (
                  <tr>
                    <td colSpan={7}></td>
                    <td colSpan={1}>
                      <Tooltip title={t("add_other_charges")}>
                        <Button
                          disabled={showAddOtherCharges}
                          onClick={() => {
                            setShowAddOtherCharges(true);
                          }}
                        >
                          <Add />
                        </Button>
                      </Tooltip>
                    </td>
                  </tr>
                )} */}

              <tr>
                <td
                  colSpan={
                    selectSellerState !== "" &&
                    selectBuyerState !== "" &&
                    selectSellerState === selectBuyerState
                      ? 7
                      : 6
                  }
                ></td>
                <td colSpan={1}>Round off</td>
                {/* <td colSpan={1}>{calculateTotal(itemList, otherCharges)}</td> */}
                <td colSpan={1}>{roundOffAmount}</td>
              </tr>
              <tr>
                <td
                  colSpan={
                    selectSellerState !== "" &&
                    selectBuyerState !== "" &&
                    selectSellerState === selectBuyerState
                      ? 7
                      : 6
                  }
                ></td>
                <td colSpan={1}>Total</td>
                {/* <td colSpan={1}>{calculateTotal(itemList, otherCharges)}</td> */}
                <td colSpan={1}>{totalAmount}</td>
              </tr>
            </tfoot>

            {/* <tfoot>
                <tr>
                  <td colSpan={8}>
                    <div className="textOnly tvText">Item Charges</div>
                  </td>
                  <td>
                    <div className="textOnly fw600">1000</div>
                  </td>
                </tr>
              </tfoot> */}
          </table>
        </div>
      </div>
    </>
  );
};
