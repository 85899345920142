import {
  Add,
  DeleteOutlineOutlined,
  EditOutlined,
  SaveOutlined,
} from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { Trash } from "react-bootstrap-icons";

export default function ListAgeGroupModel({ handleCloseListAgeGroupModel }) {
  return (
    <>
      <Box
        className="ModelBox ListAgeGroupModel"
        sx={{ boxShadow: 24, p: 4, zIndex: 10 }}
      >
        <div className="addMoreAgendaModel">
          <div className="modelTitle">
            List of age group
            <Tooltip title="Add New Age Group">
              <IconButton className="listAgegroup">
                <Add />
              </IconButton>
            </Tooltip>
          </div>

          <div className="elementFormContainer">
            <ul className="listOfAgeGrp">
              <li className="ageListInd">
                <div className="ageListInfo">
                  <span>Newborn</span>: <span>Up to 3 months old</span>
                </div>
                <div className="updateListBtn">
                  <IconButton>
                    <EditOutlined />
                  </IconButton>
                  <IconButton>
                    <SaveOutlined />
                  </IconButton>
                  <IconButton>
                    <DeleteOutlineOutlined />
                  </IconButton>
                </div>
              </li>
              <li className="ageListInd">
                <div className="ageListInfo">
                  <span>Infant</span>: <span>3 - 12 months old</span>
                </div>
                <div className="updateListBtn">
                  <IconButton>
                    <EditOutlined />
                  </IconButton>
                  <IconButton>
                    <SaveOutlined />
                  </IconButton>
                  <IconButton>
                    <DeleteOutlineOutlined />
                  </IconButton>
                </div>
              </li>
            </ul>
          </div>

          <div className="modActionBtnGrp">
            <Button className="dfultPrimaryBtn">Submit</Button>
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={handleCloseListAgeGroupModel}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Box>
    </>
  );
}
