import React, { useDebugValue, useEffect } from "react";
import { useState, useRef } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { toast } from "react-toastify";
import { convertFileToBase64_Split } from "../../../utils/FileUtils";
import FeedbackService from "../../../services/FeedbackService";
import {
  MAX_FILE_SIZE_FEEDBACK,
  VALID_FILE_EXTENSION_FEEDBACK,
} from "../../../constants/const_values";
import { Attachment, Send } from "iconoir-react";
import { getLoginUserDetails } from "../../../utils/Utils";
import "../css/Support.css";
import { SELECT_MenuProps } from "../../../constants/const_string";
import { Ticket, XLg } from "react-bootstrap-icons";
import { v4 } from "uuid";
import axios from "axios";
import DashboardServices from "../../../services/DashboardServices";
import { Skeleton, Zoom } from "@mui/material";
import SupportBotLogo from "../assets/images/SupportBotLogo.svg";
import { CopyAllOutlined, SendSharp } from "@mui/icons-material";
import "../css/zoeyBot.css";
import TicketForm from "./TicketForm";
import SupportService from "../../../services/SupportService";
import TicketBodyRightSide from "./TicketBodyRightSide";

function SupportTicketDetailsRightSide({
  setShowSupportTicketDetailsRightSide,
  supportTicketData,
  refreshData,
}) {
  const userDetails = getLoginUserDetails();
  const [loading, setLoading] = useState(false);

  const [ticketAllData, setTicketAllData] = useState(null);

  useEffect(() => {
    fetchSupportTicketDetailsBySupportRowId();
  }, [supportTicketData]);

  const fetchSupportTicketDetailsBySupportRowId = async () => {
    setLoading(true);
    const response = await SupportService.getSupportTicketDetailsByTicketRowId(
      supportTicketData.supportRowId
    );
    console.log(response.data);
    if (response.data) {
      setTicketAllData(response.data);
    }
    setLoading(false);
  };

  const [addedComment, setAddedComment] = useState(null);

  const commentInputRef = useRef();

  const sendComment = (e) => {
    const comment = commentInputRef.current.value;
    if (comment.trim() === "") {
      toast.error("Please enter some comments");
      return false;
    }
    const reqDto = {
      taskDetailId: supportTicketData.taskId,
      progress: 0,
      comments: comment,
      referenceId: 0,
      commentsType: null,
    };
    console.log("reqDto = ", reqDto);
    const commentsAt = new Date().toISOString();
    // return;
    if (comment.trim() !== "") {
      SupportService.sendSupportTaskComments(
        reqDto,
        userDetails.userId,
        supportTicketData.supportRowId
      ).then((response) => {
        console.log("out response data= ", response);
        if (response.data === 1) {
          setAddedComment({
            comments: comment,
            commentsType: null,
            commentsByUserName: userDetails.userName,
            commentsByUserProfileUrl: userDetails.userProfileImage,
            progress: 0,
            commentsAt: commentsAt,
          });

          try {
            refreshData();
          } catch (err) {
            console.log(err);
          }
          // setComments((prevComments) => [
          //   ...prevComments,
          //   {
          //     comments: comment,
          //     commentsType: null,
          //     commentsByUserName: userDetails.userName,
          //     commentsByUserProfileUrl: userDetails.userProfileImage,
          //     progress: 0,
          //     commentsAt: commentsAt,
          //   },
          // ]);
          commentInputRef.current.value = "";
          // const timeoutValue = setTimeout(() => {
          //   scrollRef.current.scrollIntoView({ behavior: "smooth" });
          //   clearTimeout(timeoutValue);
          // }, 500);
        } else {
          toast.error("something went wrong");
        }
      });
    }
  };

  return (
    <div className="supportContainer">
      <div className="supportHeader">
        <span className="supportTitle">
          Ticket Details {supportTicketData.supportDisplayId}
        </span>
        <div className="supportClose">
          <IconButton
            onClick={() => setShowSupportTicketDetailsRightSide(false)}
          >
            <XLg />
          </IconButton>
        </div>
      </div>

      <div className="supportBody">
        {loading && (
          <div className="center-progress" style={{ height: "65vh" }}>
            <CircularProgress sx={{ marginTop: "180px" }} />
          </div>
        )}
        {!loading && ticketAllData && (
          <>
            <TicketBodyRightSide
              ticket={ticketAllData}
              addedComment={addedComment}
            />
          </>
        )}
      </div>

      {/* <Button
              className="dfultPrimaryBtn"
              onClick={() => {}}
              variant="contained"
              endIcon={<Send />}
            >
              {"Send Feedback"}
            </Button> */}
      {/* <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => {
                setShowSupportTicketDetailsRightSide(false);
              }}
            >
              {"Cancel"}
            </Button> */}
      {ticketAllData && (
        <div className="taskFooterPanel">
          <div className="tskInputGrp">
            <input
              ref={commentInputRef}
              type="text"
              className="supportSendMessage"
              style={{ paddingTop: "10px !important" }}
              placeholder={`${
                ticketAllData.ticketStatus === "Open"
                  ? "Send message"
                  : "Send message disabled as task is closed"
              }`}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  console.log("Enter key pressed");
                  sendComment(event);
                }
              }}
              disabled={ticketAllData.ticketStatus === "Closed"}
            />
            <IconButton
              disabled={ticketAllData.ticketStatus === "Closed"}
              className="tskComtSndBtn"
              onClick={sendComment}
            >
              <Send />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
}

export default SupportTicketDetailsRightSide;
