import { Add, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Trash } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { CustomModal } from "../../../task/view/component/Modals/CustomModal";
import { useTranslation } from "react-i18next";

export const JDevdModal = ({
  open,
  onClose,
  jdData,
  positionData,
  updateData,
}) => {
  const [showPreview, setShowPreview] = useState(false);
  const { t } = useTranslation();
  const [previewData, setPreviewData] = useState();
  const [keyResponsibility, setKeyResponsibility] = useState([]);
  const [jdDataCopy, setJdDataCopy] = useState(null);
  const [jdSummary, setJdSummary] = useState(null);

  const [educationList, setEducationList] = useState([]);
  const [experianceList, setExperianceList] = useState([]);
  const [certificateList, setCertificateList] = useState([]);
  const [SkillsList, setSkillsList] = useState([]);

  const [jdModalData, setJdModalData] = useState(null);
  const [showJdModal, setShowJdModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);

  useEffect(() => {
    if (jdData && jdData.length > 0) {
      setJdDataCopy(jdData.map((data) => ({ ...data, tempId: uuidv4() })));
      setShowPreview(true);

      let keyRes = jdData.filter(
        (item) => item.label === "Key Responsibilities:"
      );

      if (keyRes.length === 0) {
        keyRes = [
          {
            jdId: 0,
            positionId: positionData.positionId,
            label: "Key Responsibilities:",
            title: "",
            detail: "",
            detailHighlighter: "",
            slNo: 1,
            tempId: uuidv4(),
          },
        ];
      }

      setKeyResponsibility(groupKeyResponsibility(keyRes));

      dataGroupingHandler(jdData);
    } else {
      const tempJdData = [
        {
          jdId: 0,
          positionId: positionData.positionId,
          label: "Key Responsibilities:",
          title: "",
          detail: "",
          detailHighlighter: "Board Leadership:",
          slNo: 1,
          tempId: uuidv4(),
        },
        {
          jdId: 0,
          positionId: positionData.positionId,
          label: "Qualifications:",
          title: "Education:",
          detail: "",
          detailHighlighter: null,
          slNo: 2,
          tempId: uuidv4(),
        },
        {
          jdId: 0,
          positionId: positionData.positionId,
          label: "Qualifications:",
          title: "Experience:",
          detail: "",
          detailHighlighter: null,
          slNo: 3,
          tempId: uuidv4(),
        },
        {
          jdId: 0,
          positionId: positionData.positionId,
          label: "Qualifications:",
          title: "Certificates:",
          detail: "",
          detailHighlighter: null,
          slNo: 4,
          tempId: uuidv4(),
        },

        {
          jdId: 0,
          positionId: positionData.positionId,
          label: "Skills and Attributes:",
          title: null,
          detail: "",
          detailHighlighter: "",
          slNo: 5,
          tempId: uuidv4(),
        },
      ];
      setKeyResponsibility(
        groupKeyResponsibility([
          {
            jdId: 0,
            positionId: positionData.positionId,
            label: "Key Responsibilities:",
            title: "",
            detail: "",
            detailHighlighter: "",
            slNo: 1,
            tempId: uuidv4(),
          },
        ])
      );

      setJdDataCopy(tempJdData);
    }

    if (positionData && positionData.jdSummary) {
      setJdSummary(positionData.jdSummary);
    }
  }, []);

  const groupKeyResponsibility = (data) => {
    const dta = data.reduce((acc, item) => {
      const { label, title } = item;

      if (!acc[title]) {
        acc[title] = [];
      }
      acc[title].push(item);
      return acc;
    }, {});

    const titles = Object.keys(dta);
    const tempData = [];
    titles.forEach((title) => {
      const dataItem = dta[title];
      tempData.push({
        title,
        titleId: uuidv4(),
        data: dataItem.map((itm) => ({ ...itm, tempId: uuidv4() })),
      });
    });
    console.log("Temp Data =", tempData);

    return tempData;
  };

  useEffect(() => {
    if (jdDataCopy) {
      dataGroupingHandler(jdDataCopy);

      let educationList = jdDataCopy.filter(
        (item) =>
          item.label === "Qualifications:" && item.title === "Education:"
      );

      let experianceList = jdDataCopy.filter(
        (item) =>
          item.label === "Qualifications:" && item.title === "Experience:"
      );
      let certificateList = jdDataCopy.filter(
        (item) =>
          item.label === "Qualifications:" && item.title === "Certificates:"
      );

      let skillList = jdDataCopy.filter(
        (item) => item.label === "Skills and Attributes:"
      );

      if (educationList.length === 0) {
        educationList = [
          {
            jdId: 0,
            positionId: positionData.positionId,
            label: "Qualifications:",
            title: "Education:",
            detail: null,
            detailHighlighter: null,
            slNo: 0,
            tempId: uuidv4(),
          },
        ];
      }

      if (experianceList.length === 0) {
        experianceList = [
          {
            jdId: 0,
            positionId: positionData.positionId,
            label: "Qualifications:",
            title: "Experience:",
            detail: null,
            detailHighlighter: null,
            slNo: 0,
            tempId: uuidv4(),
          },
        ];
      }

      if (certificateList.length === 0) {
        certificateList = [
          {
            jdId: 0,
            positionId: positionData.positionId,
            label: "Qualifications:",
            title: "Certificates:",
            detail: null,
            detailHighlighter: null,
            slNo: 0,
            tempId: uuidv4(),
          },
        ];
      }

      if (skillList.length === 0) {
        skillList = [
          {
            jdId: 0,
            positionId: positionData.positionId,
            label: "Skills and Attributes:",
            title: null,
            detail: null,
            detailHighlighter: null,
            slNo: 0,
            tempId: uuidv4(),
          },
        ];
      }

      setEducationList(educationList);
      setExperianceList(experianceList);
      setCertificateList(certificateList);
      setSkillsList(skillList);
    }
  }, [jdDataCopy]);

  const dataGroupingHandler = (dataSrc) => {
    const nonKeyRes = dataSrc.filter(
      (item) => item.label !== "Key Responsibilities:"
    );

    setPreviewData(groupByLabelAndTitle(nonKeyRes));
  };

  const groupByLabelAndTitle = (data) => {
    const dta = data.reduce((acc, item) => {
      const { label, title } = item;
      if (!acc[label]) {
        acc[label] = {};
      }
      if (!acc[label][title]) {
        acc[label][title] = [];
      }
      acc[label][title].push(item);
      return acc;
    }, {});

    return dta;
  };

  const checkAndAddQualification = (item, title) => {
    if (item.detail === null || item.detail === "null" || item.detail === "") {
      toast.error(t("please_provide_description"));
      return;
    }

    const tempJdItem = {
      jdId: 0,
      positionId: positionData.positionId,
      label: "Qualifications:",
      title: title,
      detail: null,
      detailHighlighter: null,
      slNo: 0,
      tempId: uuidv4(),
    };

    const temp = [...jdDataCopy];
    temp.push(tempJdItem);
    setJdDataCopy(temp);
  };

  const checkSaveValidity = () => {
    let isValid = true;
    const OtherLabelList = Object.keys(previewData);
    OtherLabelList.forEach((label) => {
      const titleList = Object.keys(previewData[label]);
      titleList.forEach((title) => {
        const detailList = previewData[label][title];
        detailList.forEach((dtl, index) => {
          if (
            dtl.detail === null ||
            dtl.detail === "" ||
            dtl.detail === "null"
          ) {
            // toast.error(
            //   `Pleae enter target for ${label} ${
            //     title === "null" ? "" : "->" + title
            //   } at index ${index + 1}`
            // );
            toast.error(
              t("please_enter_target", {
                label,
                title: title === "null" ? "" : `->${title}`,
                index: index + 1,
              })
            );
            isValid = false;
          }
        });
      });
    });

    return isValid;
  };

  return (
    <>
      <div className="bgOfset">
        <div className="bgOfsetOverlay"></div>
        <Box className="ModelBox width800" sx={{ boxShadow: 24, p: 4 }}>
          <div className="addMoreAgendaModel">
            <div className="modelTitle">{t("job_description")}</div>
            <div className="JDKRAContainer">
              <div className="jdkraHeader">
                <div className="jdkTitle">
                {t("position")}:
                  <span>
                    {positionData.position ||
                      positionData.designationName +
                        "," +
                        positionData.functionName}
                  </span>
                </div>
                {showPreview && (
                  <div className="jdkActBtn">
                    <IconButton
                      onClick={(e) => {
                        setShowPreview(false);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </div>
                )}
              </div>

              {showPreview && jdDataCopy && (
                <div className="JDKRAContainEdit">
                  {positionData.jdSummary && (
                    <div className="jdkElement">
                      <div className="subElmntHead">{t("job_summary")}:</div>
                      <div className="formElement">
                        <p>{positionData.jdSummary}</p>
                      </div>
                    </div>
                  )}

                  {/* Responsibilities */}

                  <div className="jdkElement">
                    <div className="subElmntHead">
                      {"Key Responsibilities:"}
                    </div>
                    <ol className="">
                      {keyResponsibility.map((item, subIndex) => {
                        const { title, data } = item;
                        return (
                          <li className="topList">
                            <div className="jdFromElement">
                              <div className="KratitleAdd">
                                <div className="formElement">
                                  <p>{title}</p>
                                </div>
                              </div>
                              <ul>
                                {data.map((item) => {
                                  return (
                                    <li>
                                      <div className="subElmntList">
                                        <div className="formElement">
                                          <p>
                                            <b style={{ marginRight: "4px" }}>
                                              {item.detailHighlighter}
                                            </b>{" "}
                                            {item.detail}
                                          </p>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </li>
                        );
                      })}
                    </ol>
                  </div>

                  {/* Other Than Responsibilities */}

                  {Object.keys(previewData).map((label, index) => {
                    return (
                      <div className="jdkElement">
                        <div className="subElmntHead">{label}</div>

                        <ol className="">
                          {previewData[label] !== "null" &&
                            Object.keys(previewData[label]).map(
                              (title, subIndex) => {
                                return (
                                  <>
                                    {title === "null" && (
                                      <>
                                        {previewData[label][title].map(
                                          (item) => {
                                            return (
                                              <li>
                                                <div className="subElmntList">
                                                  <div className="formElement">
                                                    <p>
                                                      <b>
                                                        {item.detailHighlighter}
                                                      </b>
                                                      {item.detail}
                                                    </p>
                                                  </div>
                                                </div>
                                              </li>
                                            );
                                          }
                                        )}
                                      </>
                                    )}
                                    {title !== "null" && (
                                      <li className="toplist">
                                        <div className="jdFromElement">
                                          <div className="subElmntHead">
                                            {title !== "null" ? title : ""}
                                          </div>
                                          <ul>
                                            {previewData[label][title].map(
                                              (item) => {
                                                return (
                                                  <li>
                                                    <div className="subElmntList">
                                                      <div className="formElement">
                                                        <p>
                                                          <b>
                                                            {
                                                              item.detailHighlighter
                                                            }
                                                          </b>
                                                          {item.detail}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </li>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </div>
                                      </li>
                                    )}
                                  </>
                                );
                              }
                            )}
                        </ol>
                      </div>
                    );
                  })}

                  {/* <div className="jdkElement">
                    <div className="subElmntHead">Skills and Attributes:</div>
                    <ol className="">
                      <li className="topList">
                        <div className="jdFromElement">
                          <div className="KratitleAdd">
                            <div className="formElement">
                              <p>
                                <b>Heading:</b>
                                Lorem ipsum dolor sit amet consectetur,
                                adipisicing elit. Rem, repellendus.
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="topList">
                        <div className="jdFromElement">
                          <div className="KratitleAdd">
                            <div className="formElement">
                              <p>
                                <b>Heading:</b>
                                Lorem ipsum dolor sit amet consectetur,
                                adipisicing elit. Rem, repellendus.
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>{" "}
                      <li className="topList">
                        <div className="jdFromElement">
                          <div className="KratitleAdd">
                            <div className="formElement">
                              <p>
                                <b>Heading:</b>
                                Lorem ipsum dolor sit amet consectetur,
                                adipisicing elit. Rem, repellendus.
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ol>
                  </div> */}
                </div>
              )}

              {!showPreview && jdDataCopy && (
                <div className="JDKRAContainEdit">
                  <div className="jdkElement">
                    <div className="subElmntHead">{t("job_summary")}:</div>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          required
                          className="modelTextFild"
                          label={t("enter_summary")}
                          variant="outlined"
                          multiline
                          minRows={3}
                          maxRows={5}
                          value={jdSummary}
                          onChange={(e) => {
                            // audfd=3asdf
                            setJdSummary(e.target.value);
                          }}
                        />
                      </FormControl>
                    </div>
                  </div>
                  <div className="jdkElement">
                    <div className="subElmntHead">{t("key_responsibilities")}:</div>
                    <ol className="">
                      {keyResponsibility.map((titleItem, subIndex) => {
                        const { title, data, titleId } = titleItem;

                        return (
                          <>
                            <li className="topList" key={titleId}>
                              <div className="jdFromElement">
                                <div className="KratitleAdd">
                                  <div className="formElement">
                                    <FormControl className="formControl">
                                      <TextField
                                        required
                                        className="modelTextFild"
                                        label={t("enter_title")}
                                        variant="outlined"
                                        multiline
                                        value={title}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          const temp = [...keyResponsibility];

                                          const presentIndex = temp.findIndex(
                                            (key) => key.title === value
                                          );

                                          if (presentIndex !== -1) {
                                            toast.error(t("key_already_present"));
                                            setJdModalData({
                                              currentData: title,
                                              inputData: value,
                                              titleId,
                                            });
                                            setModalTitle(value);
                                            setShowJdModal(true);
                                            return;
                                          }

                                          const currentIndex = temp.findIndex(
                                            (item) => item.titleId === titleId
                                          );
                                          temp[currentIndex].title = value;

                                          setKeyResponsibility(temp);
                                        }}
                                      />
                                    </FormControl>
                                  </div>

                                  <div className="jdKraBtnFlex">
                                    {keyResponsibility.length - 1 ===
                                      subIndex && (
                                      <Tooltip arrow title={t("add_new_title")}>
                                        <IconButton
                                          onClick={() => {
                                            let isValid = true;

                                            keyResponsibility.forEach(
                                              (item, index) => {
                                                if (
                                                  item.title === null ||
                                                  item.title === "" ||
                                                  item.title === "null"
                                                ) {
                                                  // toast.error(
                                                  //   `Plese Enter Title in 'Key Responsibility' at index ${
                                                  //     index + 1
                                                  //   }`
                                                  // );
                                                  toast.error(
                                                    t("please_enter_title_index", { index: index + 1 })
                                                  );
                                                  isValid = false;
                                                }
                                              }
                                            );
                                            if (!isValid) {
                                              return;
                                            }

                                            keyResponsibility.forEach(
                                              (item, index) => {
                                                const descriptionData =
                                                  item.data;
                                                descriptionData.forEach(
                                                  (
                                                    descriptionItem,
                                                    desIndex
                                                  ) => {
                                                    if (
                                                      descriptionItem.description ===
                                                        "" ||
                                                      descriptionItem.description ===
                                                        null
                                                    ) {
                                                      // toast.error(
                                                      //   `Plese Enter Description  at index ${
                                                      //     desIndex + 1
                                                      //   }`
                                                      // );
                                                      toast.error(
                                                        t("please_enter_desscription_index", { index: desIndex + 1 })
                                                      );
                                                      isValid = false;
                                                    }
                                                  }
                                                );
                                              }
                                            );

                                            if (!isValid) {
                                              return;
                                            }

                                            const temp = [...keyResponsibility];
                                            temp.push({
                                              titleId: uuidv4(),
                                              title: "",
                                              data: [
                                                {
                                                  jdId: 0,
                                                  positionId:
                                                    positionData.positionId,
                                                  label:
                                                    "Key Responsibilities:",
                                                  title: "",
                                                  detail: "",
                                                  detailHighlighter: "",
                                                  slNo: 0,
                                                },
                                              ],
                                            });

                                            setKeyResponsibility(temp);
                                          }}
                                        >
                                          <Add />
                                        </IconButton>
                                      </Tooltip>
                                    )}

                                    {keyResponsibility.length > 1 && (
                                      <IconButton
                                        onClick={() => {
                                          let temp = keyResponsibility.filter(
                                            (item) => item.titleId !== titleId
                                          );
                                          setKeyResponsibility(temp);
                                        }}
                                      >
                                        <Trash />
                                      </IconButton>
                                    )}
                                  </div>
                                </div>
                                <ul>
                                  {data.map((dataItem, itmIndex) => {
                                    const { tempId } = dataItem;
                                    return (
                                      <li key={tempId}>
                                        <div className="subElmntList">
                                          <div className="formElement">
                                            <FormControl className="formControl">
                                              <TextField
                                                required
                                                className="modelTextFild"
                                                label={t("enter_description")}
                                                variant="outlined"
                                                multiline
                                                value={dataItem.detail}
                                                onChange={(e) => {
                                                  const value = e.target.value;
                                                  const temp = [
                                                    ...keyResponsibility,
                                                  ];
                                                  const itemIndex =
                                                    temp.findIndex(
                                                      (item) =>
                                                        item.titleId === titleId
                                                    );
                                                  if (itemIndex >= 0) {
                                                    const data =
                                                      temp[itemIndex].data;
                                                    data.forEach((item) => {
                                                      if (
                                                        item.tempId === tempId
                                                      ) {
                                                        item.detail = value;
                                                      }
                                                    });
                                                  }

                                                  setKeyResponsibility(temp);
                                                }}
                                              />
                                            </FormControl>
                                          </div>

                                          <div className="jdKraBtnFlex">
                                            {itmIndex === data.length - 1 && (
                                              <Tooltip
                                                arrow
                                                title={t("add_new_description")}
                                              >
                                                <IconButton
                                                  onClick={() => {
                                                    let isValid = true;
                                                    const temp = [
                                                      ...keyResponsibility,
                                                    ];
                                                    let dataList = [];

                                                    const titleIndex =
                                                      temp.findIndex(
                                                        (item) =>
                                                          item.titleId ===
                                                          titleId
                                                      );
                                                    if (titleIndex >= 0) {
                                                      dataList =
                                                        temp[titleIndex].data;
                                                      dataList.forEach(
                                                        (data) => {
                                                          if (
                                                            data.detail ===
                                                              "" ||
                                                            data.detail === null
                                                          ) {
                                                            toast.error(
                                                              t("please_enter_the_descriptions")
                                                            );
                                                            isValid = false;
                                                          }
                                                        }
                                                      );
                                                    }

                                                    if (!isValid) {
                                                      return;
                                                    }

                                                    dataList.push({
                                                      jdId: 0,
                                                      positionId:
                                                        positionData.positionId,
                                                      label:
                                                        "Key Responsibilities:",
                                                      title: title,
                                                      detail: "",
                                                      detailHighlighter: null,
                                                      slNo: 0,
                                                      tempId: uuidv4(),
                                                    });
                                                    setKeyResponsibility(temp);
                                                  }}
                                                >
                                                  <Add />
                                                </IconButton>
                                              </Tooltip>
                                            )}

                                            {data.length > 0 && (
                                              <IconButton
                                                onClick={() => {
                                                  let temp = [
                                                    ...keyResponsibility,
                                                  ];

                                                  const titleIndex =
                                                    temp.findIndex(
                                                      (item) =>
                                                        item.titleId === titleId
                                                    );
                                                  const data =
                                                    temp[titleIndex].data;

                                                  if (data.length === 1) {
                                                    data[0] = {
                                                      jdId: 0,
                                                      positionId:
                                                        positionData.positionId,
                                                      label:
                                                        "Key Responsibilities:",
                                                      title: title,
                                                      detail: null,
                                                      detailHighlighter: null,
                                                      tempId: uuidv4(),
                                                      slNo: 0,
                                                    };
                                                  } else {
                                                    const dataIndex =
                                                      data.findIndex(
                                                        (item) =>
                                                          item.tempId === tempId
                                                      );
                                                    data.splice(dataIndex, 1);
                                                  }
                                                  setKeyResponsibility(temp);
                                                }}
                                              >
                                                <Trash />
                                              </IconButton>
                                            )}
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ol>
                  </div>

                  {previewData && (
                    <>
                      <div className="jdkElement">
                        <div className="subElmntHead">{t("qualifications")}:</div>

                        <ol className="">
                          <li className="toplist">
                            <div className="jdFromElement">
                              <div className="subElmntHead">{t("education")}</div>
                              <ul>
                                {educationList.map((item, tIndex) => {
                                  return (
                                    <li key={item.tempId}>
                                      <div className="subElmntList">
                                        <div className="formElement">
                                          <FormControl className="formControl">
                                            <TextField
                                              className="modelTextFild"
                                              label={t("enter_description")}
                                              variant="outlined"
                                              multiline
                                              value={item.detail}
                                              onChange={(e) => {
                                                const value = e.target.value;
                                                const temp = [...jdDataCopy];
                                                const tempIndex =
                                                  temp.findIndex(
                                                    (tempJdData) =>
                                                      tempJdData.tempId ===
                                                      item.tempId
                                                  );
                                                if (tempIndex >= 0) {
                                                  temp[tempIndex].detail =
                                                    value;
                                                } else {
                                                  temp.push({
                                                    ...item,
                                                    detail: value,
                                                  });
                                                }

                                                setJdDataCopy(temp);
                                              }}
                                            />
                                          </FormControl>
                                        </div>

                                        <div className="jdKraBtnFlex">
                                          {tIndex ===
                                            educationList.length - 1 && (
                                            <>
                                              <div className="jdKraBtnFlex">
                                                <Tooltip
                                                  arrow
                                                  title={t("add_new_education")}
                                                >
                                                  <IconButton
                                                    onClick={() => {
                                                      checkAndAddQualification(
                                                        item,
                                                        "Education:"
                                                      );
                                                    }}
                                                  >
                                                    <Add />
                                                  </IconButton>
                                                </Tooltip>
                                              </div>
                                            </>
                                          )}

                                          {(educationList.length > 1 ||
                                            (educationList.length === 1 &&
                                              educationList[0].detail !==
                                                null &&
                                              educationList[0].detail !==
                                                "")) && (
                                            <Tooltip title={t("delete_education")}>
                                              <IconButton
                                                onClick={() => {
                                                  let temp = [...jdDataCopy];

                                                  const tempIndex =
                                                    temp.findIndex(
                                                      (jdTempItem) =>
                                                        jdTempItem.tempId ===
                                                        item.tempId
                                                    );
                                                  if (tempIndex >= 0) {
                                                    temp.splice(tempIndex, 1);
                                                    if (
                                                      educationList.length === 1
                                                    ) {
                                                      temp.push({
                                                        jdId: 0,
                                                        positionId:
                                                          positionData.positionId,
                                                        label:
                                                          "Qualifications:",
                                                        title: "Education:",
                                                        detail: null,
                                                        detailHighlighter: null,
                                                        slNo: 0,
                                                        tempId: uuidv4(),
                                                      });
                                                    }
                                                  }
                                                  setJdDataCopy(temp);
                                                }}
                                              >
                                                <Trash />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </li>

                          <li className="toplist">
                            <div className="jdFromElement">
                              <div className="subElmntHead">{t("experience")}</div>
                              <ul>
                                {experianceList.map((item, tIndex) => {
                                  return (
                                    <li key={item.tempId}>
                                      <div className="subElmntList">
                                        <div className="formElement">
                                          <FormControl className="formControl">
                                            <TextField
                                              className="modelTextFild"
                                              label={t("enter_description")}
                                              variant="outlined"
                                              multiline
                                              value={item.detail}
                                              onChange={(e) => {
                                                const value = e.target.value;
                                                const temp = [...jdDataCopy];
                                                const tempIndex =
                                                  temp.findIndex(
                                                    (tempJdData) =>
                                                      tempJdData.tempId ===
                                                      item.tempId
                                                  );
                                                if (tempIndex >= 0) {
                                                  temp[tempIndex].detail =
                                                    value;
                                                } else {
                                                  temp.push({
                                                    ...item,
                                                    detail: value,
                                                  });
                                                }

                                                setJdDataCopy(temp);
                                              }}
                                            />
                                          </FormControl>
                                        </div>
                                        <div className="jdKraBtnFlex">
                                          {tIndex ===
                                            experianceList.length - 1 && (
                                            <>
                                              <div className="jdKraBtnFlex">
                                                <Tooltip
                                                  arrow
                                                  title={t("add_new_experience")}
                                                >
                                                  <IconButton
                                                    onClick={() => {
                                                      checkAndAddQualification(
                                                        item,
                                                        "Experience:"
                                                      );
                                                    }}
                                                  >
                                                    <Add />
                                                  </IconButton>
                                                </Tooltip>
                                              </div>
                                            </>
                                          )}

                                          {(experianceList.length > 1 ||
                                            (experianceList.length === 1 &&
                                              experianceList[0].detail !==
                                                null &&
                                              experianceList[0].detail !==
                                                "")) && (
                                            <Tooltip title={t("delete_experience")}>
                                              <IconButton
                                                onClick={() => {
                                                  let temp = [...jdDataCopy];

                                                  const tempIndex =
                                                    temp.findIndex(
                                                      (jdTempItem) =>
                                                        jdTempItem.tempId ===
                                                        item.tempId
                                                    );
                                                  if (tempIndex >= 0) {
                                                    temp.splice(tempIndex, 1);
                                                    if (
                                                      experianceList.length ===
                                                      1
                                                    ) {
                                                      temp.push({
                                                        jdId: 0,
                                                        positionId:
                                                          positionData.positionId,
                                                        label:
                                                          "Qualifications:",
                                                        title: "Experience:",
                                                        detail: null,
                                                        detailHighlighter: null,
                                                        slNo: 0,
                                                        tempId: uuidv4(),
                                                      });
                                                    }
                                                  }
                                                  setJdDataCopy(temp);
                                                }}
                                              >
                                                <Trash />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </li>

                          <li className="toplist">
                            <div className="jdFromElement">
                              <div className="subElmntHead">{t("certificates")}</div>
                              <ul>
                                {certificateList.length > 0 &&
                                  certificateList.map((item, tIndex) => {
                                    return (
                                      <li key={item.tempId}>
                                        <div className="subElmntList">
                                          <div className="formElement">
                                            <FormControl className="formControl">
                                              <TextField
                                                className="modelTextFild"
                                                label={t("enter_description")}
                                                variant="outlined"
                                                multiline
                                                value={item.detail}
                                                onChange={(e) => {
                                                  const value = e.target.value;
                                                  const temp = [...jdDataCopy];
                                                  const tempIndex =
                                                    temp.findIndex(
                                                      (tempJdData) =>
                                                        tempJdData.tempId ===
                                                        item.tempId
                                                    );
                                                  if (tempIndex >= 0) {
                                                    temp[tempIndex].detail =
                                                      value;
                                                  } else {
                                                    temp.push({
                                                      ...item,
                                                      detail: value,
                                                    });
                                                  }

                                                  setJdDataCopy(temp);
                                                }}
                                              />
                                            </FormControl>
                                          </div>
                                          <div className="jdKraBtnFlex">
                                            {tIndex ===
                                              certificateList.length - 1 && (
                                              <>
                                                <div className="jdKraBtnFlex">
                                                  <Tooltip
                                                    arrow
                                                    title={t("add_new_certificate")}
                                                  >
                                                    <IconButton
                                                      onClick={() => {
                                                        checkAndAddQualification(
                                                          item,
                                                          "Certificates:"
                                                        );
                                                      }}
                                                    >
                                                      <Add />
                                                    </IconButton>
                                                  </Tooltip>
                                                </div>
                                              </>
                                            )}

                                            {(certificateList.length > 1 ||
                                              (certificateList.length === 1 &&
                                                certificateList[0].detail !==
                                                  null &&
                                                certificateList[0].detail !==
                                                  "")) && (
                                              <Tooltip title={t("delete_certificate")}>
                                                <IconButton
                                                  onClick={() => {
                                                    let temp = [...jdDataCopy];

                                                    const tempIndex =
                                                      temp.findIndex(
                                                        (jdTempItem) =>
                                                          jdTempItem.tempId ===
                                                          item.tempId
                                                      );
                                                    if (tempIndex >= 0) {
                                                      temp.splice(tempIndex, 1);
                                                      if (
                                                        certificateList.length ===
                                                        1
                                                      ) {
                                                        temp.push({
                                                          jdId: 0,
                                                          positionId:
                                                            positionData.positionId,
                                                          label:
                                                            "Qualifications:",
                                                          title:
                                                            "Certificates:",
                                                          detail: null,
                                                          detailHighlighter:
                                                            null,
                                                          slNo: 0,
                                                          tempId: uuidv4(),
                                                        });
                                                      }
                                                    }
                                                    setJdDataCopy(temp);
                                                  }}
                                                >
                                                  <Trash />
                                                </IconButton>
                                              </Tooltip>
                                            )}
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}
                                {/* {certificateList.length ===0 && } */}
                              </ul>
                            </div>
                          </li>

                          {/* <li className="topList">
                        <div className="jdFromElement">
                          <div className="KratitleAdd">
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  className="modelTextFild"
                                  label="Enter Highlight"
                                  variant="outlined"
                                  multiline
                                  value={item.detailHighlighter}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const temp = [...jdDataCopy];
                                    temp.forEach((element) => {
                                      if (
                                        element.title === null &&
                                        element.detailHighlighter ===
                                          item.detailHighlighter
                                      ) {
                                        element.detailHighlighter = value;
                                      }
                                    });

                                    setJdDataCopy(temp);
                                  }}
                                />
                              </FormControl>
                            </div>
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  required
                                  className="modelTextFild"
                                  label="Enter Skills or Attributes"
                                  variant="outlined"
                                  multiline
                                  value={item.detail}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const temp = [...jdDataCopy];
                                    temp.forEach((element) => {
                                      if (
                                        (element.title === null ||
                                          element.title === "null") &&
                                        element.detail === item.detail
                                      ) {
                                        element.detail = value;
                                      }
                                    });

                                    setJdDataCopy(temp);
                                  }}
                                />
                              </FormControl>
                            </div>

                            {previewIndex ===
                              previewData[label][title].length - 1 && (
                              <Tooltip
                                arrow
                                title="Add New Skills or Attributes"
                              >
                                <IconButton
                                  onClick={() => {
                                    let isValid = checkSaveValidity();
                                    if (!isValid) {
                                      return;
                                    }

                                    const tempItem = {
                                      jdId: 0,
                                      positionId: positionData.positionId,
                                      label: label,
                                      title: title,
                                      detail: "",
                                      detailHighlighter: null,
                                      slNo: 5,
                                      localyAdded: true,
                                    };
                                    const tempList = [...jdDataCopy];
                                    tempList.push(tempItem);
                                    setJdDataCopy(tempList);
                                  }}
                                >
                                  <Add />
                                </IconButton>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </li> */}
                        </ol>
                      </div>
                      <div className="jdkElement">
                        <div className="subElmntHead">
                        {t("skills_and_attributes")}:
                        </div>

                        <ol className="">
                          {SkillsList.map((item, previewIndex) => (
                            <li className="topList" key={item.tempId}>
                              <div className="jdFromElement">
                                <div className="KratitleAdd">
                                  <div className="formElement">
                                    <FormControl className="formControl">
                                      <TextField
                                        className="modelTextFild"
                                        label={t("enter_title_skill_or_attribute")}
                                        variant="outlined"
                                        autoComplete="off"
                                        multiline
                                        value={item.detailHighlighter}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          const temp = [...jdDataCopy];
                                          const tempIndex = temp.findIndex(
                                            (tempItem) =>
                                              tempItem.tempId === item.tempId
                                          );
                                          if (tempIndex >= 0) {
                                            temp[tempIndex].detailHighlighter =
                                              value;
                                          } else {
                                            temp.push({
                                              ...item,
                                              detailHighlighter: value,
                                            });
                                          }

                                          setJdDataCopy(temp);
                                        }}
                                      />
                                    </FormControl>
                                  </div>
                                  <div className="formElement">
                                    <FormControl className="formControl">
                                      <TextField
                                        className="modelTextFild"
                                        label={t("enter_skills_or_attributes")}
                                        variant="outlined"
                                        multiline
                                        autoComplete="off"
                                        value={item.detail}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          const temp = [...jdDataCopy];
                                          const tempIndex = temp.findIndex(
                                            (tempItem) =>
                                              tempItem.tempId === item.tempId
                                          );
                                          if (tempIndex >= 0) {
                                            temp[tempIndex].detail = value;
                                          } else {
                                            temp.push({
                                              ...item,
                                              detail: value,
                                            });
                                          }

                                          setJdDataCopy(temp);
                                        }}
                                      />
                                    </FormControl>
                                  </div>

                                  <div className="jdKraBtnFlex">
                                    {previewIndex === SkillsList.length - 1 && (
                                      <Tooltip
                                        arrow
                                        title={t("add_new_skills_or_attributes")}
                                      >
                                        <IconButton
                                          onClick={() => {
                                            if (
                                              item.detail === "null" ||
                                              item.detail === null ||
                                              item.detail === ""
                                            ) {
                                              toast.error(
                                                t("please_provide_proper_skills_and_attribute")
                                              );
                                              return;
                                            }

                                            const tempItem = {
                                              jdId: 0,
                                              positionId:
                                                positionData.positionId,
                                              label: "Skills and Attributes:",
                                              title: null,
                                              detail: "",
                                              detailHighlighter: null,
                                              slNo: 0,
                                              tempId: uuidv4(),
                                            };
                                            const tempList = [...jdDataCopy];
                                            tempList.push(tempItem);
                                            setJdDataCopy(tempList);
                                          }}
                                        >
                                          <Add />
                                        </IconButton>
                                      </Tooltip>
                                    )}

                                    {SkillsList.length > 0 && (
                                      <IconButton
                                        onClick={() => {
                                          let temp = [...jdDataCopy];

                                          const tempIndex = temp.findIndex(
                                            (jdTempItem) =>
                                              jdTempItem.tempId === item.tempId
                                          );
                                          if (tempIndex >= 0) {
                                            temp.splice(tempIndex, 1);
                                            if (SkillsList.length === 1) {
                                              temp.push({
                                                jdId: 0,
                                                positionId:
                                                  positionData.positionId,
                                                label: "Skills and Attributes:",
                                                title: null,
                                                detail: "",
                                                detailHighlighter: null,
                                                slNo: 0,
                                                tempId: uuidv4(),
                                              });
                                            }
                                          }
                                          setJdDataCopy(temp);
                                        }}
                                      >
                                        <Trash />
                                      </IconButton>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ol>
                      </div>
                    </>
                  )}

                  {/* </div> */}
                  {/* <div className="jdkElement">
                  <div className="subElmntHead">Skills and Attributes:</div>
                  <ol className="">
                    <li className="topList">
                      <div className="jdFromElement">
                        <div className="KratitleAdd">
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                required
                                className="modelTextFild"
                                label="Enter Highlight"
                                variant="outlined"
                                multiline
                                value=""
                              />
                            </FormControl>
                          </div>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                required
                                className="modelTextFild"
                                label="Enter Skills or Attributes"
                                variant="outlined"
                                multiline
                                value=""
                              />
                            </FormControl>
                          </div>
                          <Tooltip arrow title="Add New Skills or Attributes">
                            <IconButton>
                              <Add />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </li>
                  </ol>
                </div> */}
                </div>
              )}

              {/* <ZoyelRTE /> */}
            </div>
            <div className="modActionBtnGrp">
              {!showPreview && (
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    // setShowPreview(true);

                    //  keyResponsibility

                    let isValid = true;

                    if (jdSummary === null || jdSummary === "") {
                      toast.error(t("please_enter_job_summary"));
                      return;
                    }

                    keyResponsibility.forEach((item, index) => {
                      if (
                        item.title === null ||
                        item.title === "" ||
                        item.title === "null"
                      ) {
                        toast.error(t("title_is_needed_in_key_responsibility"));
                        isValid = false;
                      }
                    });

                    keyResponsibility.forEach((item, index) => {
                      const descriptionList = item.data;

                      descriptionList.forEach((item, subIndex) => {
                        if (
                          item.detail === null ||
                          item.detail === "" ||
                          item.detail === "null"
                        ) {
                          toast.error(t("please_provide_description"));
                          isValid = false;
                        }
                      });
                    });

                    if (!isValid) {
                      return;
                    }

                    // const OtherLabelList = Object.keys(previewData);
                    // OtherLabelList.forEach((label) => {
                    //   const titleList = Object.keys(previewData[label]);
                    //   titleList.forEach((title) => {
                    //     const detailList = previewData[label][title];
                    //     detailList.forEach((dtl, index) => {
                    //       if (
                    //         dtl.detail === null ||
                    //         dtl.detail === "" ||
                    //         dtl.detail === "null"
                    //       ) {
                    //         toast.error(
                    //           `Pleae enter target for ${label} ${
                    //             title === "null" ? "" : "->" + title
                    //           } at index ${index + 1}`
                    //         );
                    //         isValid = false;
                    //       }
                    //     });
                    //   });
                    // });

                    // if (!isValid) {
                    //   return;
                    // }

                    const filteredKeyResponsibility = [];

                    keyResponsibility.forEach((item) => {
                      const { title, data } = item;
                      data.forEach((dataItem) => {
                        filteredKeyResponsibility.push({ ...dataItem, title });
                      });
                    });

                    const filteredData = jdDataCopy
                      .filter(
                        (item) =>
                          item.label !== "Key Responsibilities:" &&
                          !(
                            item.detail === null ||
                            item.detail === "null" ||
                            item.detail === ""
                          )
                      )
                      .map((item) => {
                        const { tempId, ...other } = item;
                        return other;
                      });

                    const totalData = [
                      ...filteredKeyResponsibility,
                      ...filteredData,
                    ];
                    console.log("filtered Data=", totalData);

                    onClose();
                    const data = { jdDataCopy: totalData, jdSummary };
                    updateData(data);
                  }}
                >
                  {t("save")}
                </Button>
              )}
              <Button className="dfultDarkSecondaryBtn" onClick={onClose}>
              {t("cancel")}
              </Button>
            </div>
          </div>
        </Box>
      </div>
      {showJdModal && (
        <CustomModal
          modalTitle={t("title_conflict_title_already_exist_consider_changing")}
          style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowJdModal(false);
          }}
          className={""}
        >
          <>
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  required
                  className="modelTextFild"
                  label={t("enter_title")}
                  variant="outlined"
                  multiline
                  value={modalTitle}
                  onChange={(e) => {
                    setModalTitle(e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <Button
              className="dfultPrimaryBtn"
              onClick={() => {
                if (modalTitle === jdModalData.inputData) {
                  toast.error(t("title_already_present"));
                  return;
                } else {
                  const temp = [...keyResponsibility];

                  const index = temp.findIndex(
                    (item) => item.titleId === jdModalData.titleId
                  );
                  if (index >= 0) {
                    temp[index].title = modalTitle;
                  }

                  setKeyResponsibility(temp);
                  setShowJdModal(false);
                  setModalTitle(null);
                  setJdModalData(null);
                }
              }}
            >
              {t("save")}
            </Button>
          </>
        </CustomModal>
      )}
    </>
  );
};
