import { Add, Edit, Search } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { Trash } from "react-bootstrap-icons";
import {
  deactivateStatutoryInsurancePaymentsHead,
  getStatutoryInsurancePaymentsList,
} from "../../../../services/AdminService";
import { getLoginUserDetails } from "../../../../utils/Utils";
import { useState } from "react";
import { toast } from "react-toastify";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function DefineTaxStatutory({
  handelOpencreateTaxStatutory,
  refreshTaxStatutory,
}) {
  const loginUserDetail = getLoginUserDetails();
  const { t } = useTranslation();
  const [statutoryList, setStatutoryList] = useState([]);
  const [searchString, setSearchString] = useState("");
  const fetchStatutory = () => {
    getStatutoryInsurancePaymentsList(loginUserDetail.orgId).then(
      (response) => {
        if (response.data) {
          setStatutoryList(response.data);
        }
      }
    );
  };

  const filteredStatutoryList = useMemo(() => {
    if (searchString === "") {
      return statutoryList;
    } else {
      return statutoryList.filter((item) =>
        item.name.toLowerCase().includes(searchString.toLowerCase())
      );
    }
  }, [searchString, statutoryList]);

  useEffect(() => {
    fetchStatutory();
  }, [refreshTaxStatutory]);

  const handleDisableItem = (id) => {
    deactivateStatutoryInsurancePaymentsHead(id, loginUserDetail.userId).then(
      (response) => {
        if (response.data) {
          if (response.data.returnValue === "1") {
            toast.success(response.data.message);
            fetchStatutory();
          } else {
            toast.error(response.data.message);
          }
        }
      }
    );
  };

  return (
    <>
      <div className="fullContainArea">
        <div className="cuHeaderGrp">
          {/* <div className="cuhederTxt">Tax & Statutory</div> */}
          <div className="searchTasklist">
            <input
              type="text"
              class="serchTaskInput"
              placeholder={t("search")}
              onChange={(e) => {
                setSearchString(e.target.value);
              }}
            />
            <IconButton className="seacrhIcon">
              <Search />
            </IconButton>
          </div>

          <div className="addUnitsBtn">
            {/* <Button
              startIcon={<AccountTree />}
              className="dfultDarkSecondaryBtn"
              // onClick={() => handleOpenUnitTreeView()}
            >
              Departmment Tree
            </Button> */}
            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => handelOpencreateTaxStatutory()}
            >
              {t("add")}
            </Button>
          </div>
        </div>

        <div className="cuContainArea">
          <div className="TableContainer">
            <table className="taskListTable">
              <thead className="taskListtableHeader">
                <tr>
                  <th>{t("name")}</th>
                  <th>{t("category")}</th>
                  <th>{t("due_date_renewal_date")}</th>
                  <th>{t("last_payment_date")}</th>
                  <th>{t("last_payment_amount")}</th>
                  <th>{t("notify_to_positions")}</th>
                  <th>{t("lead_time")}</th>
                  <th className="width100">{t("action")}</th>
                </tr>
              </thead>
              <tbody className="scrolableTableBody">
                {filteredStatutoryList &&
                  filteredStatutoryList.map((item) => (
                    <tr>
                      <td>
                        <div className="tskLstTaskNM">{item.name}</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">{item.category}</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          {item.dueRenewalDate}
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          {item.lastPaymentDate}
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          {item.lastPaymentAmount}
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          {item.notifyToPositionName}
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          {item.notificationLeadTime}
                        </div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <IconButton
                            className="removeRowBtn"
                            onClick={(e) => handleDisableItem(item.id)}
                          >
                            <Trash />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
