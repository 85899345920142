import { Box, Button, Grid } from "@mui/material";
import React, { useContext } from "react";
import {
  apiEndPoint,
  collaboraDomain,
} from "../../../../constants/url_provider";
import { getLoginUserDetails } from "../../../../utils/Utils";
import DashboardServices from "../../../../services/DashboardServices";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import { AddTabContext } from "../../../dashboard/view/DashboardPage";
import { useTranslation } from "react-i18next";

const DocumentTemplateViewer = ({
  file,
  closeTemplateView,
  viewedTemplate,
  setAddedTemplateList,
  addedTemplateList,
  setRecommendedTemplateList,
  recommendedTemplateList,
  tabKey,
}) => {
  const [officeUrl, setOfficeUrl] = useState(null);
  const { t } = useTranslation();

  const loginUserDetail = getLoginUserDetails();
  const { updateTab } = useContext(AddTabContext);

  useEffect((e) => {
    if (tabKey) {
      updateTab({ data: file }, tabKey);
    }

    let fileName = file.templateId;
    if (file.fileName.includes("DF")) {
      fileName = file.fileName;
    }
    let officeUrl = `${collaboraDomain}/browser/abd9205/cool.html?WOPISrc=${apiEndPoint}/wopi/files/${fileName}~${file.taskDetailId}`;
    setOfficeUrl(officeUrl);
  }, []);
  const draftTemplate = () => {
    // console.log(viewedTemplate);
    // console.log(viewedTemplate.fileName);
    // console.log(viewedTemplate.fileName.split(".")[1]);

    let tempFileName = `DF_${loginUserDetail.userId}_${
      viewedTemplate.taskMasterId
    }_${new Date().getTime()}_${viewedTemplate.fileName.split(".")[1]}`;

    let reqDto = {
      sourceTemplateFileName: viewedTemplate.fileName,
      sourceTemplateFolderPath: viewedTemplate.fileFolder,
      tempFileName: tempFileName,
    };
    // console.log("draftTemplate::", reqDto);
    DashboardServices.draftTemplate(reqDto, loginUserDetail.userId).then(
      (response) => {
        console.log(response.data);
        if (response.data.returnValue === "1") {
          toast.success(`Template Added to Task!`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          viewedTemplate.fileName = tempFileName;

          setAddedTemplateList([...addedTemplateList, viewedTemplate]);
          let notaddedTemplateList = recommendedTemplateList.filter(
            (template) => template.templateId !== viewedTemplate.templateId
          );
          // console.log("viewedTemplate", viewedTemplate);
          // console.log("addedTemplateList", addedTemplateList);
          // console.log("notaddedTemplateList", notaddedTemplateList);
          setRecommendedTemplateList(notaddedTemplateList);
        } else {
          toast.error(`Something went wrong!`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  return (
    <>
      <Box sx={{ backgroundColor: "white" }}>
        {viewedTemplate != null && !viewedTemplate.fileName.includes("DF") && (
          <Button
            className="selectedBtn"
            onClick={() => draftTemplate()}
            autoFocus
          >
            {t("select_template")}
          </Button>
        )}
      </Box>
      {officeUrl && (
        <div style={{ width: "-webkit-fill-available" }}>
          <iframe
            title="collabora"
            style={{
              height: "90vh",
            }}
            name="embed_office"
            src={officeUrl}
            width="100%"
            height="100vh"
          ></iframe>
        </div>
      )}

      {/* <Box className="taskContainerArea" sx={{ bgcolor: "dark.pageBg" }}>
        <Grid container spacing={0} className="taskContainArea">
          <Grid item xs={12} className="pRelative">
            <div className="taskContainer">
              <div className="taskListHeader">{file.fileDisplayName}</div>
              <div className="taskListTableContainer">
                
              </div>
            </div>
          </Grid>
        </Grid>
      </Box> */}
    </>
  );
};

export default DocumentTemplateViewer;
