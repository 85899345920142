import React, { useState, useEffect } from "react";
import { Document, Page,pdfjs } from "react-pdf";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PdfViewer = ({ pdfUrl }) => {

const [data,setData]=useState(null)
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  useEffect(() => {
    const loadDocument = async () => {

      const response = await fetch(pdfUrl);
      const pdfBlob = await response.blob();
      setData(URL.createObjectURL(pdfBlob));
    };
    loadDocument();
  }, [pdfUrl]);

  const onPageChange = (number) => {
    setPageNumber(number);
  };

  return (
    <div>
      <Document
        file={data}
        onLoadSuccess={() => {
          console.log("Document loaded successfully");
        }}
      >
        <Page
          pageNumber={pageNumber}
          onLoadSuccess={({ numPages }) => {
            setNumPages(numPages);
            console.log("Page loaded");
          }}
        />
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p>
    </div>
  );
};

export default PdfViewer;
