import { Button, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { getLoginUserDetails } from "../../../utils/Utils";
import RightSideMeetingsContainer from "../../dashboard/view/component/right-panel/RightSideMeetingsContainer";
import { Trash3, XLg } from "react-bootstrap-icons";
import DateUtils, { weekDays } from "../../../utils/DateUtils";
import { Forward, Trash } from "iconoir-react";
import {
  ArrowForward,
  ArrowForwardIos,
  ArrowRightAlt,
  Forward5,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function SchRightAdditionalEventDtls({
  additionalEventDetails,
  closeFromDashboard,
  setSelectedEvent,
}) {
  const [eventDay, setEventDay] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    console.log("additionalEventDetails", additionalEventDetails);
    // if (additionalEventDetails.length >= 2) {
    console.log(
      "SchRightAdditionalEventDtls useEffect",
      new Date(additionalEventDetails[0].start).getDay()
    );
    // let day = additionalEventDetails[2].start.getDay();
    console.log("SchRightAdditionalEventDtls useEffect");
    const formattedDate = new Date(additionalEventDetails[0].start)
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      })
      .replace(/\//g, "-");
    setEventDay(
      weekDays[new Date(additionalEventDetails[0].start).getDay()] +
        ", " +
        formattedDate
    );
    // }
  }, []);

  return (
    <>
      <div
        className="SchduleRightFlotingContainer"
        style={{ width: "30vw !important" }}
      >
        <div className="flotingAreaContainer">
          <div className="flotingAreaClose">
            <div className="facHeader">{eventDay}</div>
            <IconButton className="CloseBtn" onClick={closeFromDashboard}>
              <XLg />
            </IconButton>
          </div>
          <div className="elementFormContainerAdditionalEvents">
            <div className="formElement">
              {additionalEventDetails.map((eventDto) => {
                return (
                  <Tooltip
                    // title={`View details of ${eventDto.title} `}
                    title={t("view_details_of", { title: eventDto.title })}
                    // placement="right-end"
                  >
                    <div
                      className={`e-appointment-details additionalEvents ${
                        eventDto.source === "google"
                          ? "gCalendar"
                          : "myCalendar"
                      } `}
                      onClick={() => {
                        // closeFromDashboard();
                        eventDto.additional = true;
                        setSelectedEvent(eventDto);
                      }}
                    >
                      <div>
                        <div className="additionalEventsTitle">
                          {eventDto.title}
                        </div>
                        <div className="additionalEventsDate">
                          {DateUtils.displayDateMonthViewToolTip(eventDto)}
                        </div>
                      </div>
                      <div>
                        <IconButton className="deleteAdditionalEvent">
                          <ArrowForwardIos />
                        </IconButton>
                      </div>
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          </div>

          {/* <div className="elementFooter">
            <div className="formBtnElement">
              <Button className="dfultPrimaryBtn">Save</Button>
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => closeFromDashboard()}
              >
                Cancel
              </Button>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
