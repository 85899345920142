import {
  Add,
  CheckCircle,
  DownloadForOffline,
  Translate,
  Verified,
} from "@mui/icons-material";
import {
  Button,
  IconButton,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  Box,
  Tooltip,
  TextField,
  Popover,
  FormControl,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { XLg } from "react-bootstrap-icons";
import { useTable } from "react-table";
import { jwtDecode } from "jwt-decode";
import {
  getLoginUserDetails,
  validateEmail,
} from "../../../../../../utils/Utils";
import {
  getEventParticipants,
  sendMeetingInviteFromOrgInternalUser,
} from "../../../../../../services/MeetingService";
import { getChatAllContacts } from "../../../../../../services/ChatService";
import { toast } from "react-toastify";
import { sendCallInvitePersonal } from "../../../../../../services/VideoConferenceService";
import { meetingSocket } from "../../../../../../socket";
import { sendMeetingInviteToExternalUsers } from "../../../../../../services/SchedulerService";
import { useTranslation } from "react-i18next";

const InviteUsers = ({
  handleMenuCloseButton,
  jitsiObject,
  selEvent,
  setInvitedUsersMain,
  invitedUsersMain,
}) => {
  const loginUser = getLoginUserDetails();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "simple-popover" : undefined;

  const [meetingParticipants, setMeetingParticipants] = useState([]);
  const [newContactButton, setNewContactButton] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [loaderForGuest, setLoaderForGuest] = useState(false);

  const handleNewContactButton = (event) => {
    setAnchorEl(event.currentTarget);
    setNewContactButton(true);
  };

  const handleCloseNeCwontactButtonModal = () => {
    setAnchorEl(null);
    setNewContactButton(false);
    setUserName("");
    setUserEmail("");
  };

  const handleUserNameChange = (event) => {
    console.log("handleUserNameChange", event.target.value);
    setUserName(event.target.value);
  };

  const handleUserEmailChange = (event) => {
    setUserEmail(event.target.value);
  };

  const handleFormSubmit = () => {
    setLoaderForGuest(true);

    if (userName.trim() === "") {
      toast.error(t("please_enter_user_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoaderForGuest(false);
      return;
    }

    if (userEmail.trim() === "") {
      toast.error(t("please_enter_user_email"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoaderForGuest(false);
      return;
    }

    if (userEmail.trim() !== "" && !validateEmail(userEmail)) {
      toast.error(t("please_enter_valid_email"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoaderForGuest(false);
      return;
    }

    const isDuplicate = meetingParticipants.some(
      (participant) =>
        participant.userName === userName + " ( " + userEmail + " )" ||
        participant.userName.includes(userEmail)
    );

    if (isDuplicate) {
      toast.error(t("participant_with_this_email_already_exists"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoaderForGuest(false);
      return;
    }

    const newParticipant = {
      calledBy: "",
      chairperson: "",
      convener: "",
      id: null,
      orgId: "",
      outSide: "",
      profileImage:
        "https://ahlan-s3.s3.me-south-1.amazonaws.com/images/no-profile.png",
      userId: "",
      userName: userName + " ( " + userEmail + " )",
    };

    const newGuestReqDto = {
      personName: userName,
      officialEmailId: userEmail,
    };

    const meetingId = selEvent.link ? selEvent.link : selEvent.eventLink;

    console.log("meetingId", meetingId);
    console.log("newGuestReqDto", newGuestReqDto);
    console.log("loginUser.userId", loginUser.userId);

    sendMeetingInviteToExternalUsers(
      meetingId,
      loginUser.userId,
      newGuestReqDto
    ).then((response) => {
      console.log("guest response.data", response.data);
      if (response.data.returnValue == "1") {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setMeetingParticipants([...meetingParticipants, newParticipant]);
        setNewContactButton(false);
        setAnchorEl(null);
        setUserName("");
        setUserEmail("");
        setLoaderForGuest(false);
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });

    // toast.success('New Guest added successfully', {
    //   position: toast.POSITION.TOP_RIGHT,
    // });
  };

  console.log("userDetails", loginUser);

  const [myJitsiParticipantId, setMyJitsiParticipantId] = useState(null);
  const [invitedUsers, setInvitedUsers] = useState(invitedUsersMain);
  const [participantsListSize, setParticipantsListSize] = useState(0);

  const [selectedGuest, setSelectedGuest] = useState(null);
  const [selectedGuestInput, setSelectedGuestInput] = useState("");
  // const [meetingParticipants, setMeetingParticipants] = useState([]);
  const [allUsersList, setAllUsersList] = useState([]);
  useEffect(() => {
    console.log("selEvent", selEvent);
    getChatAllContacts(loginUser.userId, loginUser.orgId).then((response) => {
      console.log("getChatAllContacts", response.data);
      setAllUsersList(response.data);
    });
    getEventParticipants(selEvent.eventId).then((response) => {
      let sortedData = [];
      // if (response.data.length > 1) {
      //   // sortedData = response.data.sort(
      //   //   (a, b) => (b.convener === "Y") - (a.convener === "Y")
      //   // );
      //   // sortedData = response.data.sort((a, b) => {
      //   //   if (a.convener === "Y" && b.convener !== "Y") return -1;
      //   //   if (a.chairperson === "Y" && b.chairperson !== "Y") return -1;
      //   //   if (b.convener === "Y" && a.convener !== "Y") return 1;
      //   //   if (b.chairperson === "Y" && a.chairperson !== "Y") return 1;
      //   //   return 0;
      //   // });
      //   sortedData = response.data.sort((a, b) => {
      //     if (a.convener === "Y" && b.convener !== "Y") return -1;
      //     if (b.convener === "Y" && a.convener !== "Y") return 1;
      //     if (a.chairperson === "Y" && b.chairperson !== "Y") return -1;
      //     if (b.chairperson === "Y" && a.chairperson !== "Y") return 1;
      //     return 0;
      //   });
      // } else {
      //   sortedData = response.data;
      // }
      // console.log("sortedData-->>>", sortedData);
      setMeetingParticipants(response.data);
      setMeetingParticipants((prevList) => [...prevList, ...invitedUsersMain]);
    });
  }, [selEvent.eventId]);

  const orgIntUserSendInvite = async (participantDto) => {
    orgIntUserSendInviteFinal();

    // console.log("orgIntUserSendInvite", peopleInfo);
    // console.log("orgIntUserSendInvite", outSidePersonList);

    // if (peopleInfo.length <= 0 && outSidePersonList.length <= 0) {
    //   toast.error("Please select atleast one internal or external user", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return;
    // }

    // let busyUserTempList = await getAllBusyUserListForInvite([
    //   ...peopleInfo,
    //   ...outSidePersonList,
    // ]);
    // console.log("orgIntUserSendInvite", busyUserTempList);
    // console.log(
    //   "orgIntUserSendInvite",
    //   busyUserTempList.filter((userDto) => !userDto.isSameMeeting)
    // );
    // console.log(
    //   "orgIntUserSendInvite",
    //   busyUserTempList.filter((userDto) => userDto.isSameMeeting)
    // );
    // setBusyUserList(
    //   busyUserTempList.filter((userDto) => !userDto.isSameMeeting)
    // );
    // setSameMeetingUser(
    //   busyUserTempList.filter((userDto) => userDto.isSameMeeting)
    // );
    // if (busyUserTempList.length > 0) {
    //   setShowBusyUserCallDialog(true);
    // } else {
    //   orgIntUserSendInviteFinal();
    // }
  };

  const [guestData, setGuestData] = useState([
    { guestName: "", guestEmail: "" },
  ]);

  console.log("guestData", guestData);

  const orgIntUserSendInviteFinal = (event, participantDto) => {
    console.log("participantDto", participantDto);

    toast.info(t("sending_Call_invite_please_wait"), {
      position: toast.POSITION.TOP_RIGHT,
    });

    if (!participantDto.userId) {
      const formattedParticipant = participantDto.userName.split("(");
      const newformattedParticipantData = {
        guestName: formattedParticipant[0].trim(),
        guestEmail: formattedParticipant[1].split(")")[0].trim(),
      };

      console.log(newformattedParticipantData);
      fetchInviteExternalUser(newformattedParticipantData, event);
      setGuestData(newformattedParticipantData);
      return;
    }

    // textContent = "Please Wait";
    console.log(event.target.textContent);
    event.target.textContent = t("please_wait");
    event.target.disabled = true;
    console.log(loginUser.orgId);
    // return;
    // setSendingInvite(true);

    if (loginUser.orgId === "ORG000000000000") {
      let contactDto = {
        id: participantDto.userId,
        contactUserId: participantDto.userId,
        contactEmailId: participantDto.emailId,
      };
      let userList = [];
      userList.push(contactDto);

      sendCallInvitePersonal(
        userList,
        selEvent.link ? selEvent.link : selEvent.eventLink,
        loginUser.userId,
        selEvent.eventType
      ).then((response) => {
        console.log("sendCallInvite", response.data);
        if (response.data.returnValue === "1") {
          // toast.success("Call Invite Sent", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
          toast.success(t("Call_invite_sent"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          event.target.textContent = t("send_invite");
          event.target.disabled = false;
          setInvitedUsers((prevList) => [...prevList, contactDto]);
          setInvitedUsersMain((prevList) => [...prevList, contactDto]);
          try {
            const socketDto = {
              userId: loginUser.userId,
              message: `${loginUser.userName} is inviting you to an event`,
              meetingLink: selEvent.link ? selEvent.link : selEvent.eventLink,
              eventParticipants: userList.map((obj) => obj.contactUserId),
            };
            console.log("socketDto", socketDto);
            meetingSocket.emit("sendCallInviteToUsers", socketDto);
          } catch (err) {
            console.log(err);
          }

          // setPeopleInfo([]);
          // setOutSidePersonList([]);
          // setSelectExternalContactList([]);
          // setSelContacts([]);
          // setInvitePerModal(false);
        } else {
          toast.error(t("something_went_wrong"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    } else {
      let internalUserList = [];
      let externalUserList = [];

      if (participantDto.outSide === "N") {
        internalUserList.push(participantDto.userId);
      } else {
        externalUserList.push(participantDto.userId);
      }

      let reqDto = {
        internalUserIds: internalUserList,
        externalUserIds: externalUserList,
      };

      console.log(selEvent, "selEvent");
      sendMeetingInviteFromOrgInternalUser(
        selEvent.link ? selEvent.link : selEvent.eventLink,
        loginUser.userId,
        reqDto
      ).then((response) => {
        console.log(response.data);
        // setSendingInvite(false);
        if (response.data.returnValue === "1") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          event.target.textContent = t("send_invite");
          event.target.disabled = false;
          setInvitedUsers((prevList) => [...prevList, participantDto]);
          setInvitedUsersMain((prevList) => [...prevList, participantDto]);
          let usersToInvite = reqDto.internalUserIds.concat(
            reqDto.externalUserIds
          );
          meetingSocket.emit("sendCallInviteToUsers", {
            userId: loginUser.userId,
            message: `${loginUser.userName} is inviting you to an event`,
            meetingLink: selEvent.link ? selEvent.link : selEvent.eventLink,
            eventParticipants: usersToInvite,
          });

          // setInvitePerModal(false);
        }
      });
    }
  };

  const fetchInviteExternalUser = (newformattedParticipantData, event) => {
    event.target.textContent = t("please_wait");
    event.target.disabled = true;

    const newGuestReqDto = {
      personName: newformattedParticipantData.guestName,
      officialEmailId: newformattedParticipantData.guestEmail,
    };

    console.log("newGuestReqDto", newGuestReqDto);

    const meetingId = selEvent.link ? selEvent.link : selEvent.eventLink;

    console.log("meetingId", meetingId);

    sendMeetingInviteToExternalUsers(
      meetingId,
      loginUser.userId,
      newGuestReqDto
    ).then((response) => {
      console.log("guest response.data", response.data);
      if (response.data.returnValue == "1") {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      event.target.textContent = t("send_invite");
      event.target.disabled = false;
    });
  };

  const searchUserFilteredList = useMemo(() => {
    let filtered = [];
    if (selectedGuestInput.length > 0 && selectedGuestInput.trim() !== "") {
      filtered = allUsersList.filter((option) =>
        option.userName.toLowerCase().includes(selectedGuestInput.toLowerCase())
      );
    }

    return filtered;
  }, [selectedGuestInput, allUsersList]);
  return (
    <>
      <div className="meetRightHeader">
        <div className="metRtTitle"> {t("invite_user")} </div>
        <div className="metRtAction">
          <IconButton
            aria-label="Close"
            onClick={() => handleMenuCloseButton()}
          >
            <XLg />
          </IconButton>
        </div>
      </div>

      {/* Heading */}

      <div className="MeetingTranscriptionContainer">
        <div
          className="meetGustDtlElement inviteUser"
          style={{ marginTop: "10px" }}
        >
          <div className="metGustDtlElementTitle">
            <span>{t("add_guests")}</span>
          </div>
          <div className="meetingAddGuestGrup">
            {/* <Button>Add Guests</Button> */}
            <div className="searchGustAC">
              <Autocomplete
                noOptionsText={t("please_type_to_search")}
                id="participantSelect"
                sx={{
                  width: 280,
                  backgroundColor: "#333",
                  borderRadius: "10px",
                  color: "white",
                }}
                value={selectedGuest}
                onChange={(event, selectedValue) => {
                  console.log("newValue", selectedValue);
                  setMeetingParticipants((prevList) => [
                    ...prevList,
                    {
                      id: null,
                      userId: selectedValue.userId,
                      userName: selectedValue.userName,
                      profileImage: selectedValue.profileUrl,
                      convener: "",
                      chairperson: "",
                      outSide: selectedValue.userType === "I" ? "N" : "Y",
                      calledBy: "N",
                      orgId: loginUser.orgId,
                      emailId: selectedValue.userDtl,
                    },
                  ]);
                  setSelectedGuest(null);
                  setSelectedGuestInput("");
                }}
                inputValue={selectedGuestInput}
                onInputChange={(event, newInputValue) => {
                  setSelectedGuestInput(newInputValue);
                }}
                // options={allUsersList}
                options={searchUserFilteredList}
                autoHighlight
                getOptionLabel={(option) => option.userName}
                getOptionDisabled={(option) =>
                  meetingParticipants.some(
                    (userDto) => userDto.userId === option.userId
                  )
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      srcSet={`${option.profileUrl}`}
                      src={`${option.profileUrl}`}
                      alt=""
                    />
                    {option.userName}
                    {option.userType === "I" ? (
                      <span style={{ padding: "5px" }}>
                        <Tooltip title={t("belongs_to_your_organization")}>
                          {/* <BadgeCheck /> */}
                          <Verified />
                        </Tooltip>
                      </span>
                    ) : (
                      <></>
                    )}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Select a participant"

                    // inputProps={{
                    //   ...params.inputProps,
                    //   autoComplete: "new-password", // disable autocomplete and autofill
                    // }}
                  />
                )}
              />
            </div>
            <div className="addGustNewMeetBtn">
              <Tooltip title={t("add_a_new_guest")}>
                <Button
                  startIcon={<Add />}
                  style={{ width: "150px", height: "40px" }}
                  className="dfultPrimaryBtn"
                  onClick={handleNewContactButton}
                >
                  {t("new_guest")}
                </Button>
              </Tooltip>
              <Popover
                id={popoverId}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleCloseNeCwontactButtonModal}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <div
                  className="popOverContain"
                  style={{ padding: 20, minWidth: "280px" }}
                >
                  <div className="modelTitle">{t("enter_user_details")}</div>
                  <div
                    className="elementFormContainer "
                    style={{ margin: "5px 0px 5px 0px" }}
                  >
                    <FormControl className="formControl">
                      <TextField
                        required
                        label={t("user_name")}
                        variant="outlined"
                        className="modInputFieldGuestAdd"
                        value={userName}
                        onChange={handleUserNameChange}
                      />
                    </FormControl>

                    <FormControl className="formControl">
                      <TextField
                        required
                        className="modInputFieldGuestAdd"
                        label={t("user_email")}
                        variant="outlined"
                        value={userEmail}
                        onChange={handleUserEmailChange}
                      />
                    </FormControl>
                  </div>
                  <div className="modActionBtnGrp">
                    <Button
                      className="dfultPrimaryBtn"
                      onClick={handleFormSubmit}
                      disabled={loaderForGuest}
                    >
                      {loaderForGuest ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        <>{t("add")}</>
                      )}
                    </Button>
                    {!loaderForGuest && (
                      <Button
                        className="dfultDarkSecondaryBtn"
                        onClick={handleCloseNeCwontactButtonModal}
                      >
                        {t("cancel")}
                      </Button>
                    )}
                  </div>
                </div>
              </Popover>
            </div>
          </div>
          <div className="metGustLstGrp">
            {meetingParticipants.map((participantDto) => {
              return (
                <>
                  <div className={`metGustLstInd`} style={{ cursor: "unset" }}>
                    <div className="metGstImg">
                      <img src={participantDto.profileImage} alt="" />
                      {/* <span className="metConVerd">
                              <CheckCircle />
                            </span> */}
                    </div>
                    <div className="metGstName">
                      {participantDto.userName}
                      {participantDto.outSide === "N" ? (
                        <span style={{ padding: "5px" }}>
                          <Tooltip title={"Belongs to your organization"}>
                            {/* <BadgeCheck /> */}
                            <Verified />
                          </Tooltip>
                        </span>
                      ) : (
                        <></>
                      )}

                      {participantDto.convener === "Y" && (
                        <span className="metConvenor">({t("convener")})</span>
                      )}
                      {participantDto.chairperson === "Y" && (
                        <span className="metConvenor">({t("chairperson")})</span>
                      )}
                    </div>
                    {participantDto.userId !== loginUser.userId && (
                      <div
                        className="mkAsConvenorBtnGrpInv"
                        style={{ display: "block !important" }}
                      >
                        <Button
                          onClick={(event) => {
                            orgIntUserSendInviteFinal(event, participantDto);
                          }}
                        >
                          {t("send_invite")}
                        </Button>
                      </div>
                    )}
                    {/* {participantDto.convener !== "Y" && (
                        <div className="mkAsConvenorBtnGrp">
                          <Button onClick={() => makeConvenor(participantDto)}>
                            Make Convener
                          </Button>
                        </div>
                      )}
                      {participantDto.chairperson !== "Y" &&
                        selectedEvent.eventType === "Meeting" && (
                          <div className="mkAsConvenorBtnGrp">
                            <Button
                              onClick={() => makeChairperson(participantDto)}
                            >
                              Make Chairperson
                            </Button>
                          </div>
                        )} */}
                  </div>
                </>
              );
            })}
            {/* <div className="metGustLstInd gustAsConvenor">
                  <div className="metGstImg">
                    <img src={userImg1} alt="" />
                    <span className="metConVerd">
                      <CheckCircle />
                    </span>
                  </div>
                  <div className="metGstName">
                    Aiyasha Hasan<span className="metConvenor">(Convenor)</span>
                  </div>
                  <div className="mkAsConvenorBtnGrp">
                    <Button>Make Convenor</Button>
                  </div>
                </div> */}
            {/*
                <div className="metGustLstInd">
                  <div className="metGstImg">
                    <img src={userImg2} alt="" />
                    <span className="metConVerd">
                      <CheckCircle />
                    </span>
                  </div>
                  <div className="metGstName">
                    Sanket Santra<span className="metConvenor">(Convenor)</span>
                  </div>
                  <div className="mkAsConvenorBtnGrp">
                    <Button>Make Convenor</Button>
                  </div>
                </div>

                <div className="metGustLstInd">
                  <div className="metGstImg">
                    <img src={userImg3} alt="" />
                    <span className="metConVerd">
                      <CheckCircle />
                    </span>
                  </div>
                  <div className="metGstName">
                    Prashant Saha<span className="metConvenor">(Convenor)</span>
                  </div>
                  <div className="mkAsConvenorBtnGrp">
                    <Button>Make Convenor</Button>
                  </div>
                </div>

                <div className="metGustLstInd">
                  <div className="metGstImg">
                    <img src={userImg4} alt="" />
                    <span className="metConVerd">
                      <CheckCircle />
                    </span>
                  </div>
                  <div className="metGstName">
                    Sk. Aftabuddin<span className="metConvenor">(Convenor)</span>
                  </div>
                  <div className="mkAsConvenorBtnGrp">
                    <Button>Make Convenor</Button>
                  </div>
                </div> */}
          </div>
        </div>
      </div>

      {/* body */}

      <div className="MeetRightFooter">
        <div className="meetRightFootBtnGrp">
          <Button
            className="cancleTransBtn"
            variant="outlined"
            onClick={() => handleMenuCloseButton()}
          >
            {t("close")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default InviteUsers;
