import React, { useState } from "react";
import { Search } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SupportService from "../../../services/SupportService";
import { getLoginUserDetails } from "../../../utils/Utils";
import DateUtils from "../../../utils/DateUtils";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RightSideSupportTicketViewDetails from "./RightSideSupportTicketViewDetails";
import { supportSocket } from "../../../socket";

export default function SupportTicketView({ selectedTicket }) {
  const userDetails = getLoginUserDetails();
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");

  const [supportTickets, setSupportTickets] = useState([]);
  const [filteredSupportTickets, setFilteredSupportTickets] = useState([]);
  const [loading, setLoading] = useState(true);

  const [searchFilter, setSearchFilter] = useState("supportDisplayId");
  const [searchPlaceHolder, setSearchPlaceHolder] = useState("by ticket id");

  const [primaryPosdate, setPrimaryPosdate] = useState(
    new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)
  );
  const handleJoiningprimaryPosdate = (newDate) => {
    setPrimaryPosdate(newDate);
  };

  const [primaryToDate, setPrimaryToDate] = useState(new Date());

  const handleToDateChange = (newDate) => {
    setPrimaryToDate(newDate);
  };

  useEffect(() => {
    fetchSupportTicket();
  }, []);

  const fetchSupportTicket = async () => {
    setLoading(true);
    console.log("fetchSupportTicket", primaryPosdate, primaryToDate);
    console.log(
      "fetchSupportTicket",
      DateUtils.getDateInYYYYMMDD(primaryPosdate),
      DateUtils.getDateInYYYYMMDD(primaryToDate)
    );
    const response = await SupportService.getSupportTicketsOfUnit(
      userDetails.signleUnitId,
      DateUtils.getDateInYYYYMMDD(primaryPosdate),
      DateUtils.getDateInYYYYMMDD(primaryToDate)
    );
    console.log("fetchSupportTicket", response.data);
    if (response.data) {
      setSupportTickets(response.data);
      setFilteredSupportTickets(response.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (supportTickets && supportTickets.length > 0 && selectedTicket) {
      setSearchQuery(selectedTicket);

      const filteredData = supportTickets.filter(
        (supportTicketDto) =>
          supportTicketDto[searchFilter] &&
          supportTicketDto[searchFilter]
            .toLowerCase()
            .includes(selectedTicket.toLowerCase())
      );

      setFilteredSupportTickets(filteredData);
    }
  }, [supportTickets]);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = supportTickets.filter(
      (supportTicketDto) =>
        supportTicketDto[searchFilter] &&
        supportTicketDto[searchFilter]
          .toLowerCase()
          .includes(query.toLowerCase())
    );

    setFilteredSupportTickets(filteredData);
  };

  const [
    showSupportTicketDetailsRightSide,
    setShowSupportTicketDetailsRightSide,
  ] = useState(false);

  const [selectedSupportTicket, setSelectedSupportTicket] = useState(false);

  useEffect(() => {
    supportSocket.emit("joinSupportUnit", { unitId: userDetails.signleUnitId });
    supportSocket.on("refreshSupportList", async (data) => {
      console.log("refreshSupportList", data);
      const response = await SupportService.getSupportTicketsOfUnit(
        userDetails.signleUnitId,
        DateUtils.getDateInYYYYMMDD(primaryPosdate),
        DateUtils.getDateInYYYYMMDD(primaryToDate)
      );
      console.log("fetchSupportTicket", response.data);
      if (response.data) {
        setSupportTickets(response.data);
        setFilteredSupportTickets(response.data);
      }
    });
  }, [supportSocket]);

  const refreshData = async () => {
    const response = await SupportService.getSupportTicketsOfUnit(
      userDetails.signleUnitId,
      DateUtils.getDateInYYYYMMDD(primaryPosdate),
      DateUtils.getDateInYYYYMMDD(primaryToDate)
    );
    console.log("fetchSupportTicket", response.data);
    if (response.data) {
      setSupportTickets(response.data);
      setFilteredSupportTickets(response.data);
    }
  };

  return (
    <>
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader">
          {/* <IconButton
            onClick={() => {
              handelCloseViewExternalUser();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton> */}
          {"Support Tickets"}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              {/* <div className="formElementGrp"> */}
              {/* <div className="formElement mxW-100"> */}

              {/* </div> */}
              {/* </div> */}
              <div className="searchTicketSupport">
                <FormControl className="formControl supportMWSearchBy">
                  <InputLabel id="exUserType"> {"Search By"}</InputLabel>
                  <Select
                    labelId="searchByLabelId"
                    id="searchByLabel"
                    value={searchFilter}
                    label="Search By"
                    onChange={(e) => {
                      setSearchFilter(e.target.value);
                      if (e.target.value === "supportDisplayId") {
                        setSearchPlaceHolder("by ticket id");
                      } else if (e.target.value === "issueCategory") {
                        setSearchPlaceHolder("by issue category");
                      } else if (e.target.value === "issueName") {
                        setSearchPlaceHolder("by issue name");
                      } else if (e.target.value === "issueDescription") {
                        setSearchPlaceHolder("by issue description");
                      } else if (e.target.value === "priority") {
                        setSearchPlaceHolder("by priority");
                      }
                    }}
                    className="formInputField"
                    variant="outlined"
                  >
                    <MenuItem value="supportDisplayId">Ticket Id</MenuItem>
                    <MenuItem value="issueCategory">Issue Category</MenuItem>
                    <MenuItem value="issueName">Issue Name</MenuItem>
                    <MenuItem value="issueDescription">
                      Issue Description
                    </MenuItem>
                    <MenuItem value="priority">Priority</MenuItem>
                  </Select>
                </FormControl>
                <input
                  type="text"
                  class="serchTaskInput"
                  placeholder={`Type to search ${searchPlaceHolder}`}
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <IconButton className="seacrhIconSupport">
                  <Search />
                </IconButton>
              </div>

              <div className="formSearchGrp">
                <div className="formElement width180">
                  <FormControl className="formControl">
                    <InputLabel id="primPosition" className="setCompletionDate">
                      {t("from_date")}
                    </InputLabel>
                    <ReactDatePicker
                      toggleCalendarOnIconClick
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      showMonthDropdown
                      showIcon
                      labelId="primPosition"
                      className="formDatePicker"
                      selected={primaryPosdate}
                      maxDate={primaryToDate}
                      dateFormat="dd-MMM-yyyy"
                      onChange={(date) => {
                        handleJoiningprimaryPosdate(date);
                      }}
                    />
                  </FormControl>
                </div>
                <div className="formElement width180">
                  <FormControl className="formControl">
                    <InputLabel id="primPosition" className="setCompletionDate">
                      {t("to_date")}
                    </InputLabel>
                    <ReactDatePicker
                      toggleCalendarOnIconClick
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      showMonthDropdown
                      showIcon
                      labelId="primPosition"
                      className="formDatePicker"
                      selected={primaryToDate}
                      minDate={primaryPosdate}
                      maxDate={new Date()}
                      dateFormat="dd-MMM-yyyy"
                      onChange={(date) => {
                        handleToDateChange(date);
                      }}
                    />
                  </FormControl>
                </div>
                <Button
                  className="dfultPrimaryBtn"
                  onClick={fetchSupportTicket}
                  startIcon={<Search />}
                >
                  {t("search")}
                </Button>
              </div>

              {/* <div className="addUnitsBtn">
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn"
                  onClick={() => handelOpenAddExternalUser()}
                >
                  {t("external_user")}
                </Button>
              </div> */}
            </div>

            <div className="cuContainArea">
              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : supportTickets.length === 0 ? (
                <div class="noDataCard ">
                  <span>{t("oops")}</span>
                  {"No data found in selected date range"}
                </div>
              ) : (
                <div className="TableContainer">
                  <table className="taskListTable">
                    <thead className="taskListtableHeader">
                      <tr>
                        <th className="width100">{t("sl_no")}</th>
                        <th>Ticket Id</th>
                        <th>Issue Category</th>
                        <th>Issue Name</th>
                        <th>Issue Description</th>
                        <th>Priority</th>
                        <th>Ticket Creation Time</th>
                        <th>Pending Action</th>
                        <th>Ticket Status</th>
                        {/* <th className="width100"></th> */}
                      </tr>
                    </thead>
                    <tbody className="scrolableTableBody">
                      {filteredSupportTickets.map((supportTicketDto, index) => (
                        <tr
                          key={supportTicketDto.supportRowId}
                          onClick={() => {
                            setSelectedSupportTicket(supportTicketDto);
                            setShowSupportTicketDetailsRightSide(true);
                          }}
                        >
                          <td>
                            <div className="tskLstTaskNM">{index + 1}.</div>
                          </td>
                          <td>
                            <div
                              className="tskLstTaskNM supportIdLink"
                              // onClick={() => {
                              //   setSelectedSupportTicket(supportTicketDto);
                              // }}
                            >
                              {supportTicketDto.supportDisplayId}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {supportTicketDto.issueCategory}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {supportTicketDto.issueName}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {supportTicketDto.issueDescription}
                            </div>
                          </td>
                          <td>
                            <div
                              className={`tskLstTaskNM supportPriority${supportTicketDto.priority}`}
                            >
                              {supportTicketDto.priority}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
                                supportTicketDto.createdDateTime
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {supportTicketDto.lastTicketUpdatedBy ===
                              userDetails.userId ? (
                                "Action pending from support end"
                              ) : (
                                <div class="star-text-support">
                                  Action pending from your end
                                </div>
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {/* {Math.floor(Math.random() * 21)} */}
                              {supportTicketDto.ticketStatus
                                ? supportTicketDto.ticketStatus
                                : "Open"}
                            </div>
                          </td>
                          {/* <td>
                            <div className="tblActionBtnGrp">
                              <IconButton
                                onClick={() =>
                                  handelOpenEditExternaluser(userData)
                                }
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                onClick={() => handleDelete(userData.userId)}
                              >
                                <Trash />
                              </IconButton>
                            </div>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                    {/* <tbody className="scrolableTableBody">
                    <tr>
                      <td>
                        <div className="tskLstTaskNM">1.</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Mr. Sanket Santra</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Client</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <input type="hidden" ref={mailRef} value="sanketsantra1990@gmail.com" />
                          <span>sankexxxxxxxx@xxx.com</span>
                          <span className='copyIcon'>
                            <IconButton onClick={() => mailTextCopy(mailRef.current.value)}>
                              <ContentCopy />
                            </IconButton>
                            {mailMessage && <Message text={mailMessage} />}
                          </span>
                          
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <input type="hidden" ref={phoneRef} value="+917384979442" />
                          <span>+91-73xxxxxx42</span>
                          <span className='copyIcon'>
                            <IconButton onClick={() => phoneTextCopy(phoneRef.current.value)}>
                              <ContentCopy />
                            </IconButton>
                            {phoneMessage && <Message text={phoneMessage} />}
                          </span>
                          
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskDescription">12/1A/29 Bidhannagar, South Chowbagha Rd.,Kol 700039 India.</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">+44-208-1234567</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">GSTIN-0009817</div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <IconButton>
                            <Edit />
                          </IconButton>
                          <IconButton>
                            <Trash />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody> */}
                  </table>
                </div>
              )}
              {/* <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    // width: "250px",
                    // height: "250px",
                    maxWidth: "600px",
                    minHeight: "150px",
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <h2 id="modal-modal-title">
                    {t("do_you_want_to_delete_this_external_user")}
                  </h2>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      // variant="contained"
                      // color="secondary"
                      className="dfultPrimaryBtn"
                      onClick={confirmDelete}
                    >
                      {t("yes")}
                    </Button>
                    <Button
                      //  variant="contained"
                      className="dfultDarkSecondaryBtn"
                      onClick={handleCloseModal}
                    >
                      {t("no")}
                    </Button>
                  </div>
                </div>
              </Modal> */}
              {showSupportTicketDetailsRightSide && selectedSupportTicket && (
                <RightSideSupportTicketViewDetails
                  setShowSupportTicketDetailsRightSide={
                    setShowSupportTicketDetailsRightSide
                  }
                  supportTicketData={selectedSupportTicket}
                  refreshData={refreshData}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
