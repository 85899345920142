import { AccountTree, Add } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import {
  getOrgDetails,
  getCountryList,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";

export default function DefineOrganization({
  handelOpenEditUpdateOrg,
  refreshOrganisation,
  orgDetailsData,
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();
  // const [orgDetailsData, setOrgDetailsData] = useState(null);s
  const [loading, setLoading] = useState(false);
  const [CountryList, setCountryList] = useState("");

  const orgTypeMapping = {
    "": "None",
    OT0001: "Public Limited Company",
    OT0002: "Private Limited Company",
    OT0003: "Joint-Venture Company",
    OT0004: "Partnership Firm",
    OT0005: "One Person Company",
    OT0006: "Sole Proprietorship",
    OT0007: "Branch Office",
    OT0008: "Non-Government Organization (NGO)",
  };

  // useEffect(() => {
  //   fetchgetOrgDetails();
  // }, [refreshOrganisation]);

  // const fetchgetOrgDetails = () => {
  //   getOrgDetails(userDetails.userId)
  //     .then((response) => {
  //       console.log("getOrgDetails", response.data);
  //       setOrgDetailsData(response.data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching getOrgDetails", error);
  //       setLoading(false);
  //     });
  // };

  useEffect(() => {
    if (orgDetailsData) {
      fetchCountry(orgDetailsData.countryCode);
    }
  }, [orgDetailsData]);

  const fetchCountry = (countryCode) => {
    getCountryList()
      .then((response) => {
        const countryValue = response.data;
        console.log("countryValue", countryValue);
        const country = countryValue.find(
          (value) => value.countryCode === countryCode
        );
        console.log("country", country);
        setCountryList(country ? country.countryName : "");
      })
      .catch((error) => {
        console.error("Error fetching country list", error);
      });
  };

  console.log("CountryList", CountryList);

  return (
    <>
      <div className="createUnitsArea">
        <div className="cuHeaderGrp">
          <div className="cuhederTxt">{t("organization_details")}</div>
        </div>
        <div className="cuContainArea">
          {loading ? (
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          ) : !orgDetailsData ? (
            <div class="noDataCard ">
              <span>{t("oops")}</span>{t("no_organization_details_not_define_yet")}
              <br />
            </div>
          ) : (
            <div className="cuCardList">
              <Card
                className="cardContent widthfalf orgContainerView"
                style={{ width: "50% !important" }}
              >
                <CardContent>
                  <div className="CUcardTitle">{orgDetailsData.orgName}</div>

                  <div className="cuCheckItem">
                  {t("registered_office_address")} :{" "}
                    <span>
                      {`${
                        orgDetailsData.regBuildingNameNo &&
                        orgDetailsData.regBuildingNameNo !== ""
                          ? orgDetailsData.regBuildingNameNo + ", "
                          : ""
                      }
                      ${
                        orgDetailsData.regArea && orgDetailsData.regArea !== ""
                          ? orgDetailsData.regArea + ", "
                          : ""
                      }${
                        orgDetailsData.regCity && orgDetailsData.regCity !== ""
                          ? orgDetailsData.regCity + ", "
                          : ""
                      }${
                        orgDetailsData.regPinCd &&
                        orgDetailsData.regPinCd !== ""
                          ? orgDetailsData.regPinCd + ", "
                          : ""
                      }`}
                    </span>
                  </div>

                  <div className="cuCheckItem">
                  {t("corresponding_office_address")} :{" "}
                    <span>
                      {`${
                        orgDetailsData.corrBuildingNameNo &&
                        orgDetailsData.corrBuildingNameNo !== ""
                          ? orgDetailsData.corrBuildingNameNo + ", "
                          : ""
                      }
                      ${
                        orgDetailsData.corrArea &&
                        orgDetailsData.corrArea !== ""
                          ? orgDetailsData.corrArea + ", "
                          : ""
                      }${
                        orgDetailsData.corrCity &&
                        orgDetailsData.corrCity !== ""
                          ? orgDetailsData.corrCity + ", "
                          : ""
                      }${
                        orgDetailsData.corrPinCd &&
                        orgDetailsData.corrPinCd !== ""
                          ? orgDetailsData.corrPinCd + ", "
                          : ""
                      }`}
                    </span>
                  </div>

                  <div className="cuCheckItem">
                   {t("organization_email_id")}:{" "}
                    <span>{orgDetailsData.orgEmailId}</span>
                  </div>

                  <div className="cuCheckItem">
                   {t("organization_contact_no")} :{" "}
                    <span>{orgDetailsData.contactNo}</span>
                  </div>

                  <div className="cuCheckItem">
                  {t("organization_type")} :{" "}
                    <span>{orgTypeMapping[orgDetailsData.orgType]}</span>
                  </div>

                  <div className="cuCheckItem">
                  {t("date_of_incorporation")} : <span>{orgDetailsData.doi}</span>
                  </div>

                  <div className="cuCheckItem">
                  {t("authorized_person")} : <span>{orgDetailsData.authPerson}</span>
                  </div>

                  <div className="cuCheckItem">
                  {t("authorized_person_contact_number")} :{" "}
                    <span>{orgDetailsData.authPerContactNo}</span>
                  </div>

                  <div className="cuCheckItem">
                  {t("trade_license")} : <span>{orgDetailsData.tradeLicNo}</span>
                  </div>

                  <div className="cuCheckItem">
                  {t("company_identification_number")} :{" "}
                    <span>{orgDetailsData.cin}</span>
                  </div>

                  <div className="cuCheckItem">
                  {t("tax_number")} : <span>{orgDetailsData.taxNo}</span>
                  </div>

                  {/* <div className="cuCheckItem">
                    Address For Correspondence :{" "}
                    <span>{orgDetailsData.corrAddress}</span>
                  </div> */}

                  <div className="cuCheckItem">
                  {t("time_zone")} : <span>{orgDetailsData.timeZone}</span>
                  </div>

                  {orgDetailsData &&
                    orgDetailsData.singleUnit &&
                    orgDetailsData.singleUnit === "Y" && (
                      <>
                        <div className="cuCheckItem">
                        {t("unit_name")} : <span>{orgDetailsData.unitName}</span>
                        </div>

                        <div className="cuCheckItem">
                        {t("balance_sheet_maintain_by_own")} :{" "}
                          <span>
                            {orgDetailsData.ownBalanceSheet === "Y"
                              ? t("yes")
                              : t("no")}
                          </span>
                        </div>

                        <div className="cuCheckItem">
                        {t("statutory_compliances")} :{" "}
                          <span>
                            {orgDetailsData.statutoryCompliances === "Y"
                              ? t("yes")
                              : t("no")}
                          </span>
                        </div>

                        <div className="cuCheckItem">
                         {t("local_tax")} :{" "}
                          <span>
                            {orgDetailsData.localTa === "Y" ? "Yes" : "No"}
                          </span>
                        </div>
                        <div className="cuCheckItem">
                        {t("pan")} : <span>{orgDetailsData.pan}</span>
                        </div>
                        <div className="cuCheckItem">
                        {t("tan")} : <span>{orgDetailsData.tan}</span>
                        </div>
                        <div className="cuCheckItem">
                         {t("epf_no")} : <span>{orgDetailsData.epfNo}</span>
                        </div>
                        <div className="cuCheckItem">
                        {t("esi_no")} : <span>{orgDetailsData.esiNo}</span>
                        </div>
                        <div className="cuCheckItem">
                        {t("gst_no")} : <span>{orgDetailsData.gstin}</span>
                        </div>
                      </>
                    )}

                  {/* <div className="cuCheckItem">
                    Country : <span>{CountryList}</span>
                  </div> */}
                </CardContent>
                <CardActions>
                  <div className="taskVDContBtn">
                    {/* <Button
                        variant="contained"
                        startIcon={<Trash />}
                        onClick={() => handleDelete(unit.unitId)}
                      >
                        Delete Unit
                      </Button> */}
                  </div>
                  <div className="taskVDContBtn">
                    <Button
                      variant="outlined"
                      startIcon={<PencilSquare />}
                      onClick={() => handelOpenEditUpdateOrg(orgDetailsData)}
                    >
                     {t("edit_organization")}
                    </Button>
                  </div>
                </CardActions>
              </Card>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
