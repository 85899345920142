import dayjs from "dayjs";

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const monthsOfYear = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const DateUtils = {
  convert: function (d) {
    // Converts the date in d to a date-object. The input can be:
    //   a date object: returned without modification
    //  an array      : Interpreted as [year,month,day]. NOTE: month is 0-11.
    //   a number     : Interpreted as number of milliseconds
    //                  since 1 Jan 1970 (a timestamp)
    //   a string     : Any format supported by the javascript engine, like
    //                  "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
    //  an object     : Interpreted as an object with year, month and date
    //                  attributes.  **NOTE** month is 0-11.
    return d.constructor === Date
      ? d
      : d.constructor === Array
      ? new Date(d[0], d[1], d[2])
      : d.constructor === Number
      ? new Date(d)
      : d.constructor === String
      ? new Date(d)
      : typeof d === "object"
      ? new Date(d.year, d.month, d.date)
      : NaN;
  },
  compare: function (a, b) {
    // Compare two dates (could be of any type supported by the convert
    // function above) and returns:
    //  -1 : if a < b
    //   0 : if a = b
    //   1 : if a > b
    // NaN : if a or b is an illegal date
    // NOTE: The code inside isFinite does an assignment (=).
    return isFinite((a = this.convert(a).valueOf())) &&
      isFinite((b = this.convert(b).valueOf()))
      ? (a > b) - (a < b)
      : NaN;
  },

  inRange: function (d, start, end) {
    // Checks if date in d is between dates in start and end.
    // Returns a boolean or NaN:
    //    true  : if d is between start and end (inclusive)
    //    false : if d is before start or after end
    //    NaN   : if one or more of the dates is illegal.
    // NOTE: The code inside isFinite does an assignment (=).
    return isFinite((d = this.convert(d).valueOf())) &&
      isFinite((start = this.convert(start).valueOf())) &&
      isFinite((end = this.convert(end).valueOf()))
      ? start <= d && d <= end
      : NaN;
  },

  //RETURN DATE IN YYYY-MM-DD FORMAT
  getDateInYYYYMMDD: function (date) {
    let dateInStr =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) +
      "-" +
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate());
    return dateInStr;
  },

  getDateInFormalFormat: (date) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Function to add ordinal suffix to the day
    function getOrdinalSuffix(day) {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  },

  getDateInDDMMYYYY: function (date) {
    let dateInStr =
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
      "-" +
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) +
      "-" +
      date.getFullYear();

    return dateInStr;
  },
  formatddMMMYYHM: (ts) => {
    const dt = new Date(ts);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let hours = dt.getHours();
    let minutes = dt.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    const strTime = `${hours}:${minutes} ${ampm}`;
    const retDate = `${dt.getDate()} ${
      months[dt.getMonth()]
    } ${dt.getFullYear()} / ${strTime}`;
    return retDate;
  },

  getDateInDDMMYYYYHH24MM: function (date) {
    let dateInStr =
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
      "-" +
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) +
      "-" +
      date.getFullYear() +
      "-" +
      date.getHours() +
      "-" +
      date.getMinutes() +
      "-" +
      date.getSeconds();

    return dateInStr;
  },
  getDateInDDMMYYYYHH12MM: (ts) => {
    const dt = new Date(ts);
    let hours = dt.getHours();
    let minutes = dt.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours %= 12;
    hours = hours || 12;
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    const strTime = `${hours}:${minutes} ${ampm}`;
    const retDate = `${
      dt.getDate() > 9 ? `${dt.getDate()}` : `0${dt.getDate()}`
    }-${
      dt.getMonth() + 1 > 9 ? `${dt.getMonth() + 1}` : `0${dt.getMonth() + 1}`
    }-${dt.getFullYear()} ${strTime}`;
    return retDate;
  },
  getDateInDDMMYYYYHH12MM: (ts) => {
    const dt = new Date(ts);
    let hours = dt.getHours();
    let minutes = dt.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours %= 12;
    hours = hours || 12;
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    const strTime = `${hours}:${minutes} ${ampm}`;
    const retDate = `${
      dt.getDate() > 9 ? `${dt.getDate()}` : `0${dt.getDate()}`
    }-${
      dt.getMonth() + 1 > 9 ? `${dt.getMonth() + 1}` : `0${dt.getMonth() + 1}`
    }-${dt.getFullYear()} ${strTime}`;
    return retDate;
  },
  getDateInDDMMYYYYHH12MMSSMonthName: (ts) => {
    const dt = new Date(ts);
    let hours = dt.getHours();
    let minutes = dt.getMinutes();
    let seconds = dt.getSeconds();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours %= 12;
    hours = hours || 12;
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;
    const strTime = `${hours}:${minutes} ${ampm}`;
    const retDate = `${
      dt.getDate() > 9 ? `${dt.getDate()}` : `0${dt.getDate()}`
    }-${
      dt.getMonth() + 1 > 9
        ? `${monthsOfYear[dt.getMonth() + 1]}`
        : `${monthsOfYear[dt.getMonth() + 1]}`
    }-${dt.getFullYear()} ${strTime}`;
    return retDate;
  },

  printDateAMPM: function (date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    var ddmmyyyy = this.printDatePrettyFormat(date);
    return ddmmyyyy + " " + strTime;
  },

  printAMPM: function (date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes + " " + ampm;
  },

  prettyDate: function (time) {
    var date = new Date(parseInt(time));
    var localeSpecificTime = date.toLocaleTimeString();
    return localeSpecificTime.replace(/:\d+ /, " ");
  },

  getDiffInHoursMinutesAndSecs: function (seconds) {
    // console.log("getDiffInHoursMinutesAndSecs", seconds);
    // calculate (and subtract) whole days
    // var days = Math.floor(seconds / 86400);
    // seconds -= days * 86400;

    // calculate (and subtract) whole hours
    try {
      // console.log("getDiffInHoursMinutesAndSecs", seconds);
      let hours = Math.floor(seconds / 3600);
      seconds -= hours * 3600;
      // console.log("getDiffInHoursMinutesAndSecs", hours);
      // calculate (and subtract) whole minutes
      let minutes = Math.floor(seconds / 60) % 60;
      seconds -= minutes * 60;
      // console.log("getDiffInHoursMinutesAndSecs", minutes);
      // what's left is seconds
      let second = seconds % 60;
      hours = parseInt(hours);
      minutes = parseInt(minutes);
      second = parseInt(second);

      if (hours > 0 && minutes > 0 && seconds > 0) {
        return hours + " hr(s) " + minutes + " min(s)" + second + " secs";
      } else if (hours > 0 && minutes > 0) {
        return hours + " hr(s) " + minutes + " min(s)";
      } else if (minutes > 0 && seconds > 0) {
        return minutes + " min(s) " + second + " sec(s)";
      } else if (hours > 0) {
        return hours + " hr(s)";
      } else if (minutes > 0) {
        return minutes + " min(s)";
      } else if (seconds > 0) {
        return seconds + " sec(s)";
      }
    } catch (err) {
      console.log(err);
    }

    return "";
  },

  getDateInYYYYMM: function (date) {
    let dateInStr =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1));
    return dateInStr;
  },

  getDateInYYYYMMDDJs: function (date) {
    let dateInStr =
      date.getFullYear() +
      "/" +
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) +
      "/" +
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate());

    return dateInStr;
  },

  getDaysInMonth: function (month, year) {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  },

  getMonthName: function (date) {
    return monthNames[date.getMonth()];
  },

  tConvert: function (time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  },

  convert24hrTODate: function (dateIn24Hr) {
    let date =
      this.getDateInYYYYMMDD(new Date()) +
      " " +
      this.tConvert(
        dateIn24Hr > 9 ? dateIn24Hr + ":00" : "0" + dateIn24Hr + ":00"
      );
    return new Date(date);
  },

  getTimeInHHMM: (ts) => {
    let dt = new Date(ts);
    return `${dt.getHours()}:${dt.getMinutes()}`;
  },

  getTimeInHHMMInDoubleDigit: (date) => {
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  },

  getTimeInHHMMAMPM: (ts) => {
    const dt = new Date(ts);
    let hours = dt.getHours();
    let minutes = dt.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutes} ${ampm}`;
  },

  convert24hrTODateStartDate: function (dateIn24Hr, startDate) {
    let date =
      this.getDateInYYYYMMDD(startDate) +
      " " +
      this.tConvert(
        dateIn24Hr > 9 ? dateIn24Hr + ":00" : "0" + dateIn24Hr + ":00"
      );
    return new Date(date);
  },

  convertDateToUTC: function (date) {
    var utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

    let dateInStr =
      monthNames[utc.getMonth()].substring(0, 3) +
      " " +
      (utc.getDate() > 9 ? utc.getDate() : "0" + utc.getDate()) +
      " " +
      utc.getFullYear() +
      ", " +
      utc.getHours() +
      ":" +
      utc.getMinutes() +
      " (UTC)";

    return dateInStr;
  },

  getDifferenceInMinutes: function (startDateTime, endDateTime) {
    const startDtTime = new Date(startDateTime).valueOf();
    const endDtTime = new Date(endDateTime).valueOf();
    let diffTime = endDtTime.valueOf() - startDtTime.valueOf();
    return diffTime / (60 * 1000);
  },

  getDiffToHoursAndMinutes: function (startDateTime, endDateTime) {
    const startDtTime = new Date(startDateTime).valueOf();
    const endDtTime = new Date(endDateTime).valueOf();
    let diffTime = endDtTime.valueOf() - startDtTime.valueOf();
    let minutes = diffTime / (60 * 1000);
    var hours = Math.floor(minutes / 60);
    var remainingMinutes = minutes % 60;
    // Format the result
    var result = "";
    if (hours > 0) {
      result += hours + "h ";
    }
    if (remainingMinutes > 0 || result === "") {
      result += remainingMinutes + "m";
    }

    return result;
  },

  getDifferenceInDays: (start, end) => {
    const startDay = new Date(start);
    const endDay = new Date(end);
    let diffTime = endDay.valueOf() - startDay.valueOf();
    let days = diffTime / (24 * 60 * 60 * 1000);
    return Math.floor(days + 1);
  },

  printDatePrettyFormat: function (date) {
    var prettyDate = new Date(date.getTime());

    let dateInStr =
      monthNames[prettyDate.getMonth()].substring(0, 3) +
      " " +
      (prettyDate.getDate() > 9
        ? prettyDate.getDate()
        : "0" + prettyDate.getDate()) +
      " " +
      prettyDate.getFullYear() +
      ", ";

    return dateInStr;
  },

  convert24hrIn12: function (timeToConvert) {
    let convertedTime = "";
    if (parseInt(timeToConvert) < 12) {
      if (parseInt(timeToConvert) === 0) {
        convertedTime = "12:00 AM";
      } else if (parseInt(timeToConvert) < 10) {
        convertedTime = "0" + timeToConvert + ":00 AM";
      } else {
        convertedTime = timeToConvert + ":00 AM";
      }
    } else {
      timeToConvert = parseInt(timeToConvert) - 12;
      if (parseInt(timeToConvert) === 0) {
        convertedTime = "12:00 PM";
      } else if (parseInt(timeToConvert) < 10) {
        convertedTime = "0" + timeToConvert + ":00 PM";
      } else {
        convertedTime = timeToConvert + ":00 PM";
      }
    }

    return convertedTime;
  },

  convertToDate: function (dateString) {
    //  Convert a "dd/MM/yyyy" string into a Date object
    let d = dateString.split("-");
    let dat = new Date(d[1] + "-" + d[0] + "-" + d[2]);
    return dat;
  },

  getTimeInMMSS: (ts) => {
    let dt = new Date(ts);
    return `${dt.getMinutes()}:${dt.getSeconds()}`;
  },
  add24HoursTDate: (date) => {
    if (date.getHours() >= 12) {
      let newDate = new Date(new Date(date).getTime() + 24 * 60 * 60 * 1000);
      // newDate.setDate(newDate.getDate() + 1);
      // console.log("add12HoursTDate 1", newDate);
      return newDate;
    } else {
      let newDate = new Date(date);
      newDate.setHours(newDate.getHours() + 24);
      // console.log("add12HoursTDate 2", newDate);
      return newDate;
    }
  },
  add12HoursTDateWithinDay: (date) => {
    if (date.getHours() >= 12) {
      // let newDate = new Date(); // get the current date and time
      // let time = "23:59:00"; // example time string in format "HH:mm:ss"
      // let [hours, minutes, seconds] = time.split(":"); // split the time string into hours, minutes, and seconds

      // // set the hours, minutes, and seconds of the date object to match the input time
      // newDate.setHours(hours, minutes, seconds);
      // console.log("add12HoursTDate 1", newDate);
      return null;
    } else {
      let newDate = new Date(date);
      newDate.setHours(newDate.getHours() + 12);
      // console.log("add12HoursTDate 2", newDate);
      return newDate;
    }
  },
  getDateInddmmyy_hhmmss: (today) => {
    return `${
      today.getMonth() + 1
    }${today.getDate()}${today.getFullYear()}_${today.getHours()}${today.getMinutes()}${today.getSeconds()}`;
  },
  formatDayWithLeadingZero(date) {
    const dayOfMonth = date.getDate();
    return dayOfMonth < 10 ? "0" + dayOfMonth : dayOfMonth.toString();
  },
  getDateDisplayWeekViewHeader(date) {
    const dayOfWeek = daysOfWeek[date.getDay()];
    const month = monthsOfYear[date.getMonth()];
    const year = date.getFullYear();

    return `${dayOfWeek} - ${month} - ${year}`;
  },
  displayDateMonthViewToolTip(eventDto) {
    if (this.areDatesEqual(new Date(eventDto.start), new Date(eventDto.end))) {
      return `${this.prettyDate(
        new Date(eventDto.start).getTime()
      )} - ${this.prettyDate(new Date(eventDto.end).getTime())}`;
    } else {
      return `${this.getDateInDDMMYYYYHH12MMSSMonthName(
        eventDto.start
      )} - ${this.getDateInDDMMYYYYHH12MMSSMonthName(eventDto.end)}`;
    }
  },

  areDatesEqual(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  },
  isToday(date) {
    return (
      date.getFullYear() === new Date().getFullYear() &&
      date.getMonth() === new Date().getMonth() &&
      date.getDate() === new Date().getDate()
    );
  },
  formatTimeHHMM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();

    // Add leading zeros if necessary
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return hours + ":" + minutes;
  },
  timeToMinutes(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();

    // Calculate total minutes
    let totalMinutes = hours * 60 + minutes;

    return totalMinutes;
  },
  formatDateTo12HourFormat(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? (hours < 10 ? "0" + hours : hours) : 12; // Ensure two digits for hours and handle midnight (0 hours)
    minutes = minutes < 10 ? "0" + minutes : minutes;
    if (hours === 12 && ampm === "AM") {
      return "00:" + minutes + " " + ampm;
    } else {
      return hours + ":" + minutes + " " + ampm;
    }
  },
  generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(baseDate) {
    const intervals = [];
    const totalMinutesInDay = 24 * 60;

    // Get the current date and time
    // const currentDate = new Date();
    const currentHours = baseDate.getHours();
    const currentMinutes = baseDate.getMinutes();

    // Calculate the total minutes from the current time to the end of the day
    const remainingMinutesInDay =
      totalMinutesInDay - (currentHours * 60 + currentMinutes);

    // console.log(
    //   "remainingMinutesInDay",
    //   remainingMinutesInDay,
    //   currentHours,
    //   currentMinutes
    // );

    // Calculate the next multiple of 15 minutes from the current time
    const nextMultipleOf15 = Math.ceil(currentMinutes / 15) * 15;
    // console.log(nextMultipleOf15);

    for (
      let minutes = nextMultipleOf15;
      currentHours + Math.floor(minutes / 60) <= 23 && minutes % 60 <= 45;
      minutes += 15
    ) {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;

      let period = "AM";
      let formattedHours = currentHours + Math.floor(minutes / 60);

      if (formattedHours >= 12) {
        period = "PM";
        formattedHours = formattedHours === 12 ? 12 : formattedHours - 12;
      }

      const formattedTime = `${String(formattedHours).padStart(
        2,
        "0"
      )}:${String(mins).padStart(2, "0")} ${period}`;

      // console.log(minutes, hours, mins, formattedHours, period, formattedTime);
      intervals.push(formattedTime);
    }

    return intervals;
  },
  convertMinutesToTime(minutes) {
    // Calculate hours and minutes
    var hours = Math.floor(minutes / 60);
    var mins = parseInt(minutes % 60);

    // Format hours and minutes
    var formattedHours = (hours < 10 ? "0" : "") + hours;
    var formattedMins = (mins < 10 ? "0" : "") + mins;

    // Determine whether it's AM or PM
    var period = hours >= 12 ? "PM" : "AM";

    // Adjust hours for AM/PM format
    if (hours > 12) {
      formattedHours = (hours - 12 < 10 ? "0" : "") + (hours - 12);
    } else if (hours === 0) {
      formattedHours = "12";
    }

    // Construct the final time string
    var timeString = formattedHours + ":" + formattedMins + " " + period;

    return timeString;
  },
  roundTimeToNearest15Minutes(dateTime) {
    var roundedDateTime = new Date(dateTime);
    var minutes = roundedDateTime.getMinutes();
    var remainder = minutes % 15;

    if (remainder !== 0) {
      roundedDateTime.setMinutes(minutes - remainder);
    }

    var year = roundedDateTime.getFullYear();
    var month = ("0" + (roundedDateTime.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based month
    var day = ("0" + roundedDateTime.getDate()).slice(-2);
    var hours = ("0" + roundedDateTime.getHours()).slice(-2);
    var minutes = ("0" + roundedDateTime.getMinutes()).slice(-2);
    var seconds = ("0" + roundedDateTime.getSeconds()).slice(-2);

    var timezoneOffset = roundedDateTime.getTimezoneOffset();
    var timezoneOffsetHours = Math.floor(Math.abs(timezoneOffset) / 60);
    var timezoneOffsetMinutes = Math.abs(timezoneOffset) % 60;
    var timezoneOffsetSign = timezoneOffset >= 0 ? "-" : "+";

    var timezoneOffsetString =
      timezoneOffsetSign +
      ("0" + timezoneOffsetHours).slice(-2) +
      ":" +
      ("0" + timezoneOffsetMinutes).slice(-2);

    return (
      year +
      "-" +
      month +
      "-" +
      day +
      "T" +
      hours +
      ":" +
      minutes +
      ":" +
      seconds +
      timezoneOffsetString
    );
  },
  getFullTimeZoneName() {
    // Get the current date
    const now = new Date();

    // Get the timezone options
    const options = Intl.DateTimeFormat().resolvedOptions();

    // Return the timezone ID
    return options.timeZone;
  },
  formatDate(date) {
    const isoString = date.toISOString();
    const timezoneOffset = -date.getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(timezoneOffset) / 60);
    const offsetMinutes = Math.abs(timezoneOffset) % 60;
    const offsetSign = timezoneOffset >= 0 ? "+" : "-";
    const offsetString = `${offsetSign}${offsetHours
      .toString()
      .padStart(2, "0")}:${offsetMinutes.toString().padStart(2, "0")}`;
    return isoString.slice(0, 19) + offsetString;
  },
  generateDatesForGoogleCalendar(selectedDate) {
    // Convert the selectedDate to a JavaScript Date object
    console.log("selectedDate", selectedDate);
    let date = new Date(selectedDate);

    // Get the first day of the month
    let firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

    // Get the last day of the month
    let lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    // Calculate fromDate (less than 7 days from the start of the month)
    let startDate = new Date(firstDayOfMonth);
    startDate.setDate(startDate.getDate() - 7); // fromDate = firstDayOfMonth + 6 days

    // Calculate toDate (7 days extra from the end of the month)
    let endDate = new Date(lastDayOfMonth);
    endDate.setDate(endDate.getDate() + 7); // toDate = lastDayOfMonth + 7 days
    console.log(
      "selectedDate",
      startDate,
      endDate,
      firstDayOfMonth,
      lastDayOfMonth
    );
    return { startDate, endDate };
  },
  printEventDate(startDate, endDate) {
    const userLocale = navigator.language || navigator.userLanguage;
    console.log("userLocale", userLocale);
    if (
      startDate.getFullYear() === endDate.getFullYear() &&
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getDate() === endDate.getDate()
    ) {
      const fullDayName = startDate.toLocaleDateString(userLocale, {
        weekday: "long",
      });
      const formattedDate = startDate
        .toLocaleDateString(userLocale, {
          year: "numeric",
          month: "long",
          day: "2-digit",
        })
        .replace(/\//g, "-");
      const formattedStartTime = startDate.toLocaleTimeString(userLocale, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      const formattedEndTime = endDate.toLocaleTimeString(userLocale, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      return `${formattedDate} | ${formattedStartTime} – ${formattedEndTime}`;
    } else {
      const fullStartDayName = startDate.toLocaleDateString("en-US", {
        weekday: "long",
      });
      const formattedStartDate = startDate
        .toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "2-digit",
        })
        .replace(/\//g, "-");
      const formattedStartTime = startDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      const fullEndDayName = endDate.toLocaleDateString("en-US", {
        weekday: "long",
      });
      const formattedEndDate = endDate
        .toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "2-digit",
        })
        .replace(/\//g, "-");
      const formattedEndTime = endDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      return `${formattedStartDate}-${formattedStartTime} |  ${formattedEndTime}-${formattedEndDate} `;
    }
  },

  convertDateFormat: (inputDate, inputFormat, outputFormat = "YYYY-MM-DD") => {
    const separatorRegex = /[-/. ,]/;
    // Split the format and date by the detected separator
    const formatParts = inputFormat.split(separatorRegex);
    const dateParts = inputDate.split(separatorRegex);

    // Map the format parts to the corresponding date parts
    const dateObj = {};

    formatParts.forEach((part, index) => {
      dateObj[part] = dateParts[index];
    });

    // Construct the date string in "YYYY-MM-DD" format for dayjs
    let formattedDate = outputFormat
      .replace("DD", dateObj["DD"])
      .replace("MM", dateObj["MM"])
      .replace("YYYY", dateObj["YYYY"]);

    return formattedDate;
  },

  getAgeFromDateOfBirth: (dateOfBirth) => {
    const today = new Date();

    let years = today.getFullYear() - dateOfBirth.getFullYear();
    let months = today.getMonth() - dateOfBirth.getMonth();
    let days = today.getDate() - dateOfBirth.getDate();

    // Adjust if the current month and day are before the DOB's month and day
    if (days < 0) {
      months -= 1;
      days += new Date(today.getFullYear(), today.getMonth(), 0).getDate(); // Days in the previous month
    }

    if (months < 0) {
      years -= 1;
      months += 12;
    }

    return { years, months, days };
  },

  getAgeFromDateOfBirthStr: (dateOfBirth) => {
    const today = new Date();

    let years = today.getFullYear() - dateOfBirth.getFullYear();
    let months = today.getMonth() - dateOfBirth.getMonth();
    let days = today.getDate() - dateOfBirth.getDate();

    // Adjust if the current month and day are before the DOB's month and day
    if (days < 0) {
      months -= 1;
      days += new Date(today.getFullYear(), today.getMonth(), 0).getDate(); // Days in the previous month
    }

    if (months < 0) {
      years -= 1;
      months += 12;
    }

    return `${years}Y ${months}M ${days}D`;
  },
  convertStringToDateUtils: (dateString) => {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day); // Month is 0-indexed
  },
};
export default DateUtils;
