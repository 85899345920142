import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ArrowClockwise, XLg } from "react-bootstrap-icons";
import lrLogin from "../../images/login.png";
import {
  ArrowForward,
  CheckCircleOutline,
  Send,
  Visibility,
  VisibilityOff,
  Cancel,
  CheckCircle,
  CancelOutlined,
} from "@mui/icons-material";
import LoginSignupService from "../../../../services/LoginSignupService";
import { toast } from "react-toastify";
import {
  PERSONAL_ORG_ID,
  SELECT_MenuProps,
} from "../../../../constants/const_string";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword } from "aws-amplify/auth";
import AppUtils from "../../../../utils/AppUtils";
import { getLoginUserDetails } from "../../../../utils/Utils";
import DashboardServices from "../../../../services/DashboardServices";
import { jwtDecode } from "jwt-decode";
import { getActiveOtpServices } from "../../../../services/AdminService";

export default function RegisterViaInvitePersonal({
  handelCloseLogRegContain,
  state,
}) {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [refLinkCode, setRefLinkCode] = useSearchParams();
  const [refContactData, setRefContactData] = React.useState(null);

  // register user password start

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isValid, setIsValid] = useState(false);

  // to check if all password policy conditions are met
  useEffect(() => {
    const checkPasswordPolicy = () => {
      const hasUpperCase = newPassword.match(/[A-Z]/);
      const hasLowerCase = newPassword.match(/[a-z]/);
      const hasNumber = newPassword.match(/[0-9]/);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|~<>]/.test(newPassword);
      const isMinLength = newPassword.length >= 8;
      const isMatching = newPassword === confirmPassword;

      return (
        hasUpperCase &&
        hasLowerCase &&
        hasNumber &&
        hasSpecialChar &&
        isMinLength &&
        isMatching
      );
    };

    setIsValid(checkPasswordPolicy());
  }, [newPassword, confirmPassword]);

  const handleChangeNewPassword = (event) => {
    setNewPassword(event.target.value);
    getPasswordStrength(event.target.value);
  };

  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getPasswordStrength = (password) => {
    const meterBar = document.getElementById("meter-bar");
    const meterStatus = document.getElementById("meter-status");

    let strength = 0;

    if (password.match(/[A-Z]/)) {
      strength += 1;
    }

    if (password.match(/[a-z]/)) {
      strength += 1;
    }

    if (password.match(/[0-9]/)) {
      strength += 1;
    }

    if (password.match(/[!@#$%^&*(),.?":{}|~<>]/)) {
      strength += 1;
    }

    if (password.length >= 16) {
      strength += 1;
    }

    if (password.length < 8) {
      meterBar.style.background = "#a7a7a7";
      meterBar.style.width = "5%";
      meterStatus.style.color = "#a7a7a7";
      meterStatus.innerText = "Poor";
      return;
    }

    if (strength === 3) {
      meterBar.style.background = "#e6ae01";
      meterBar.style.width = "50%";
      meterStatus.style.color = "#e6ae01";
      meterStatus.innerText = "Medium";
    } else if (strength === 4) {
      meterBar.style.background = "#0f98e2";
      meterBar.style.width = "75%";
      meterStatus.style.color = "#0f98e2";
      meterStatus.innerText = "Strong";
    } else if (strength === 5) {
      meterBar.style.background = "#00ff33";
      meterBar.style.width = "100%";
      meterStatus.style.color = "#00ff33";
      meterStatus.innerText = "Very Strong";
    } else {
      meterBar.style.background = "#ff0000";
      meterBar.style.width = "25%";
      meterStatus.style.color = "#ff0000";
      meterStatus.innerText = "Weak";
    }
  };

  //signup section

  const [userName, setUserName] = useState(null);
  const [userLoginIdSU, setUserLoginIdSU] = useState(null);
  const [orgListSU, setOrgListSU] = useState([]);
  const [userPasswordSU, setUserPasswordSU] = useState(null);
  const [userConfPasswordSU, setUserConfPasswordSU] = useState(null);
  const [otpSU, setOtpSU] = useState(null);
  const [otpIdSU, setOtpIdSU] = useState(null);
  const [fetchingOrgListSU, setFetchingOrgListSU] = useState(false);
  const [selectOrgSU, setSelectOrgSU] = React.useState("");
  const [otpValidatedSU, setOtpValidatedSU] = useState(false);
  const [sendingOtpSU, setSendingOtpSU] = useState(false);
  const [validatingOtpSU, setValidatingOtpSU] = useState(false);

  useEffect(() => {
    let link = searchParams.get("r");
    // link = "046720";
    console.log("link", link);
    if (link) {
      localStorage.clear();
      setRefLinkCode(link);
      DashboardServices.getContactDetailsByReferralLink(link).then(
        (response) => {
          console.log("getContactDetailsByReferralLink", response.data);
          response.data.inviteLink = link;
          console.log(response.data);
          setRefContactData(response.data);
          setUserName(response.data.contactName);
          setUserLoginIdSU(response.data.contactEmailId);
        }
      );
    }
  }, []);

  const getAllOrgsAssociatedWithUserSU = async () => {
    // toast.info(
    //   "Otp has been sent successfully, please check your spam folder if not received"
    // );
    setFetchingOrgListSU(true);

    if (!userLoginIdSU || userLoginIdSU.trim() === "") {
      toast.error("Please enter your Email id");
      setFetchingOrgListSU(false);
      return false;
    }

    if (!AppUtils.validateEmail(userLoginIdSU)) {
      toast.error("Please enter a valid Email id");
      setFetchingOrgListSU(false);
      return false;
    }

    if (!userName || userName.trim() === "") {
      toast.error("Please enter your name");
      setFetchingOrgListSU(false);
      return false;
    }

    const resPerProfileExists =
      await LoginSignupService.checkIfPersonalProfileExists(
        userLoginIdSU.toLowerCase()
      );
    console.log(resPerProfileExists.data);
    if (resPerProfileExists.data.returnValue === "1") {
      toast.error(resPerProfileExists.data.message);
      setFetchingOrgListSU(false);
    } else {
      toast.info("Sending OTP, please wait....");
      const resSendOtp = await LoginSignupService.generateOtpForEmailValidation(
        userLoginIdSU.toLowerCase(),
        "NA"
      );

      if (resSendOtp.data.returnValue === "1") {
        toast.info(
          "Otp has been sent successfully, please check your spam folder if not received"
        );
        setOtpIdSU(resSendOtp.data.message);
        setFetchingOrgListSU(false);
      } else {
        toast.error("Something went wrong");
      }

      console.log(resSendOtp.data);
    }
  };

  const validateOTPSU = async () => {
    if (!otpSU || otpSU.trim() === "") {
      toast.error("Please enter your OTP");
      return false;
    }
    setValidatingOtpSU(true);
    const resOTP = await LoginSignupService.validateOtp(otpIdSU, otpSU);
    if (resOTP.data.returnValue === "-1") {
      toast.error(resOTP.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidatingOtpSU(false);
    } else if (resOTP.data.returnValue === "0") {
      toast.error(resOTP.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidatingOtpSU(false);
    } else {
      toast.info(`OTP verified successfully`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidatingOtpSU(false);
      setOtpValidatedSU(true);
    }
  };

  const signupUser = async () => {
    let reqDto = {
      userName:
        userName && userName !== "" && userName.replace(/\s+/g, " ").trim(),
      userEmailId: userLoginIdSU.toLowerCase(),
      userPassword: newPassword,
      userType: "ADMIN",
      loginMode: "WEB",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      linkMeetingId: state?.eventData?.eventLink,
      referralLinkId: null != refContactData ? refContactData.inviteLink : null,
    };
    console.log("signupUser(reqDto)", reqDto, refContactData, refLinkCode);

    // return;
    setIsValid(false);
    const resSignup = await LoginSignupService.signupUser(reqDto);
    if (resSignup.data.loginReturnValue === "0") {
      toast.error(resSignup.data.loginMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.info(resSignup.data.loginMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });

      let reqDto = {
        username: userLoginIdSU.toLowerCase() + "~" + PERSONAL_ORG_ID,
        password: newPassword,
      };

      // console.log("signupdto", reqDto, state);
      const resLogin = await LoginSignupService.userLogin(reqDto);
      localStorage.clear();
      localStorage.setItem("token", resLogin.data.token);
      const tokenData = jwtDecode(resLogin.data.token);
      localStorage.setItem("userProfileImg", tokenData.userProfileImage);
      if(tokenData.orgId){
        getActiveOtpServices(tokenData.orgId)
        .then((response)=>{
          console.log('responseotpservicedata ===1300' , response.data);
        if (Array.isArray(response.data) && response.data.length > 0) {
          localStorage.setItem("otpServices", JSON.stringify(response.data));
        }
        })
        };

      if (refContactData?.meetingId) {
        const responseReg = await DashboardServices.registerWithLink(
          refContactData.inviteLink
        );
        console.log("registerWithLink", responseReg.data);
      }

      navigate(`/dashboard`, {
        state: state,
      });
    }
  };

  //
  //

  return (
    <>
      <div className="logRegFullContainer">
        <div className="closelrCon">
          <IconButton onClick={handelCloseLogRegContain}>
            <XLg />
          </IconButton>
        </div>
        <div className="logRegContain">
          <div className="lrContainer">
            <div className="lrLeftContain">
              <div className="statLrImg">
                <img src={lrLogin} alt="statLrImg" />
              </div>
            </div>
            <div className="lrRightContain">
              <div className="logRegForm">
                <div className="lrHead">Registration</div>

                <div className="fromGroup ">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        disabled={otpIdSU != null}
                        label="Full Name"
                        required
                        variant="outlined"
                        className="formTextFieldArea"
                        onChange={(event) => setUserName(event.target.value)}
                        value={userName ? userName : ""}
                      />
                    </FormControl>
                  </div>

                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel htmlFor="userEmailId">Email Id</InputLabel>
                      <OutlinedInput
                        id="userEmailIdSU"
                        className="formTextFieldArea areaWbtn"
                        type="text"
                        disabled={otpIdSU != null}
                        endAdornment={
                          <InputAdornment position="end">
                            {fetchingOrgListSU ? (
                              <>
                                <CircularProgress className="loading-bar-fetch-org" />
                              </>
                            ) : otpIdSU ? (
                              <IconButton
                                edge="end"
                                onClick={() => {
                                  // setUserLoginIdSU("");
                                  // setOrgListSU([]);
                                  setNewPassword("");
                                  setConfirmPassword("");
                                  setOtpIdSU(null);
                                  setOtpValidatedSU(false);
                                  setOtpSU(null);
                                }}
                              >
                                <CancelOutlined />
                              </IconButton>
                            ) : (
                              <IconButton
                                edge="end"
                                onClick={getAllOrgsAssociatedWithUserSU}
                              >
                                <ArrowForward />
                              </IconButton>
                            )}
                          </InputAdornment>
                        }
                        label="Email Id"
                        value={userLoginIdSU ? userLoginIdSU : ""}
                        onChange={(event) =>
                          setUserLoginIdSU(event.target.value)
                        }
                      />
                    </FormControl>
                  </div>

                  {otpIdSU && (
                    <>
                      {!otpValidatedSU && (
                        <div className="formElement">
                          <FormControl className="formControl">
                            <InputLabel htmlFor="userEmailId">
                              Validate OTP
                            </InputLabel>
                            <OutlinedInput
                              id="userEmailId"
                              className="formTextFieldArea areaWbtn padR0"
                              type="text"
                              endAdornment={
                                <InputAdornment position="end">
                                  <Button
                                    endIcon={<CheckCircleOutline />}
                                    edge="end"
                                    className="otpBtn"
                                    onClick={validateOTPSU}
                                  >
                                    Validate
                                  </Button>
                                </InputAdornment>
                              }
                              label="Validate OTP"
                              onChange={(event) => setOtpSU(event.target.value)}
                            />
                          </FormControl>
                        </div>
                      )}

                      {otpValidatedSU && (
                        <>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel htmlFor="outlined-adornment-new-password">
                                New Password
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-new-password"
                                className="formTextFieldArea areaPassword"
                                type={showNewPassword ? "text" : "password"}
                                value={newPassword}
                                onChange={handleChangeNewPassword}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowNewPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showNewPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="New Password"
                              />
                            </FormControl>
                          </div>

                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel htmlFor="outlined-adornment-confirm-password">
                                Confirm Password
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-confirm-password"
                                className="formTextFieldArea areaPassword"
                                type={showConfirmPassword ? "text" : "password"}
                                value={confirmPassword}
                                onChange={handleChangeConfirmPassword}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowConfirmPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showConfirmPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Confirm Password"
                              />
                            </FormControl>
                          </div>

                          <div className="passwordPolocy">
                            <div className="meterWithText">
                              <div className="meter" id="meter">
                                <div className="meter-bar" id="meter-bar"></div>
                              </div>
                              <div className="meter-text" id="meter-text">
                                <span className="meterHead">
                                  Password strength:{" "}
                                </span>
                                <span
                                  className="meter-status"
                                  id="meter-status"
                                >
                                  Too Short
                                </span>
                              </div>
                            </div>

                            <div className="passwrdPlcyCheckGrp">
                              <div className="passwrdPlcyCheckHead">
                                Password should contain
                              </div>

                              <div className="passwrdPlcyCheck">
                                <span id="one-upper-case-check">
                                  {newPassword.match(/[A-Z]/) <= 1 ? (
                                    <Cancel className="cancelIcon" />
                                  ) : (
                                    <CheckCircle className="checkIcon" />
                                  )}
                                </span>
                                <span>One upper case letter</span>
                              </div>
                              <div className="passwrdPlcyCheck">
                                <span id="one-lower-case-check">
                                  {newPassword.match(/[a-z]/) <= 1 ? (
                                    <Cancel className="cancelIcon" />
                                  ) : (
                                    <CheckCircle className="checkIcon" />
                                  )}
                                </span>
                                <span>One lower case letter</span>
                              </div>
                              <div className="passwrdPlcyCheck">
                                <span id="one-number-check">
                                  {newPassword.match(/[0-9]/) < 1 ? (
                                    <Cancel className="cancelIcon" />
                                  ) : (
                                    <CheckCircle className="checkIcon" />
                                  )}
                                </span>
                                <span>One Number</span>
                              </div>
                              <div className="passwrdPlcyCheck">
                                <span id="one-specialChar-check">
                                  {/[!@#$%^&*(),.?":{}|~<>]/.test(newPassword) <
                                  1 ? (
                                    <Cancel className="cancelIcon" />
                                  ) : (
                                    <CheckCircle className="checkIcon" />
                                  )}
                                </span>
                                <span>Special character</span>
                              </div>
                              <div className="passwrdPlcyCheck">
                                <span id="min-length-check">
                                  {newPassword.length < 8 ? (
                                    <Cancel className="cancelIcon" />
                                  ) : (
                                    <CheckCircle className="checkIcon" />
                                  )}
                                </span>
                                <span>Min 8 characters</span>
                              </div>
                              <div className="passwrdPlcyCheck">
                                <span id="pass-match-check">
                                  {newPassword === "" ||
                                  newPassword !== confirmPassword ? (
                                    <Cancel className="cancelIcon" />
                                  ) : (
                                    <CheckCircle className="checkIcon" />
                                  )}
                                </span>
                                <span>Passwords mismatch </span>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>

                {/* <Button
                  variant="contained"
                  className="lrButton"
                  disabled={!isValid}
                  onClick={signupUser}
                  style={{ marginTop: "5px" }}
                >
                  REGISTER
                </Button> */}
                {otpValidatedSU && (
                  <Button
                    variant="contained"
                    className="lrButton"
                    disabled={!isValid}
                    onClick={signupUser}
                    // title={isValid ? "" : "Please enter your password"}
                    style={{ marginTop: "5px" }}
                  >
                    REGISTER
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
