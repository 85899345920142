import { exportConstants } from "./url_provider_dynamic";
// const env = "ahlan.work";
const env = "zoyel.health";
// const env = "zoyel.one";
// const env = "local";
// const env = "local-prashant";

export const {
  collaboraLink,
  stripeKey,
  collaboraDomain,
  apiEndPoint,
  socketEndPoint,
  apiEndPointSSE,
  mailTopicName,
  jitsiDomain,
  jitsiHostName,
  domainArray,
  publicUrl,
  webApp,
} = exportConstants(env);
