import React, { useState, useEffect, useContext } from "react";
import {
  AttachFile,
  CancelRounded,
  CheckCircle,
  DownloadForOffline,
  Notes,
  Translate,
  TranslateSharp,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { XLg } from "react-bootstrap-icons";
import {
  getSubtitlesOfMeeting,
  getBcp47LanguageCodeNameOfUser,
  getOrgImageBase64,
  getParticipantsOfEvent,
  getAllFilesUploaded,
  getMeetingAgendas,
  downloadFile,
  getMeetingParticipantsDetailsAndTiming,
} from "../../../../../../services/VideoConferenceService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import {
  apiEndPoint,
  publicUrl,
} from "../../../../../../constants/url_provider";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AppUtils from "../../../../../../utils/AppUtils";
import DateUtils from "../../../../../../utils/DateUtils";
import { GOOGLE_TRANSLATE_SUPPORTED_LANG } from "../../../../../../constants/const_values";
import ZoyelRTERnd from "../../../../../RTE/zoyelRTERnd";
import { CustomModal } from "../../../../../task/view/component/Modals/CustomModal";
import {
  chatAI,
  getEventParticipants,
  getMeetingRecordingLink,
} from "../../../../../../services/MeetingService";
import { AddTabContext } from "../../../../../dashboard/view/DashboardPage";
import { useTranslation } from "react-i18next";
import "../css/MeetingSummary.css";
import { OneFingerSelectHandGesture, SpockHandGesture } from "iconoir-react";

const MeetingSummary = ({
  handleMenuCloseButton,
  roomNumber,
  tabKey,
  selectedEvent,
  isConvenor,
}) => {
  //demo remove it
  // roomNumber = "448227607";
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [subtitles, setSubtitles] = useState([]);
  const [targetLanguage, setTargetLanguage] = useState(null);

  const userDetails = jwtDecode(localStorage.getItem("token"));
  const [intervalId, setIntervalId] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [generating, setGenerating] = useState(false);

  const [rteHtml, setRteHtml] = useState("");
  const [rteRawJson, setRteRawJson] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [rteText, setRteText] = useState("");
  const [showMom, setShowMom] = useState(false);
  const { updateTab } = useContext(AddTabContext);

  console.log("selectedEvent", selectedEvent);

  const languages = GOOGLE_TRANSLATE_SUPPORTED_LANG;

  console.log("languages", languages);
  console.log("languages[0]", languages[0]);

  const [value, setValue] = useState(languages[0]);

  React.useEffect(() => {
    // console.log("TranscriptionContent UseEffect", props.meetingFileUploaded);
    setLoading(true);
    getSubtitlesOfMeeting(roomNumber).then(async (response) => {
      console.log(response.data);
      if (response.data) {
        if (localStorage.getItem("meetingSubs")) {
          console.log(
            "1",
            localStorage.getItem("meetingSubs"),
            localStorage.getItem("ORG_ID")
          );
          let tempList = JSON.parse(localStorage.getItem("meetingSubs"));
          if (response.data.length > 0) {
            for (
              let index = tempList.length;
              index < response.data.length;
              index++
            ) {
              const subDto = response.data[index];
              if (typeof subDto?.transcript === "string") {
                tempList.push(subDto);
              }
            }
            setSubtitles(tempList);
          }
        } else {
          setSubtitles(response.data);
        }

        if (localStorage.getItem("sub-lang")) {
          setTargetLanguage(localStorage.getItem("sub-lang"));
          let langObj = {
            languageCode: localStorage.getItem("sub-lang"),
            languageName: localStorage.getItem("sub-langName"),
          };
          setValue(langObj);
          //   localStorage.setItem("sub-lang", "en-US");
        } else {
          await getBcp47LanguageCodeNameOfUser(userDetails.userId).then(
            (response) => {
              if (response && response.data && response.data.length > 0) {
                // for (let i = 0; i < response.data.length; i++) {
                //   response.data[i].selected = true;
                //   spokenLanguagesTemp.push(response.data[i]);
                //   spokenLanguagesCode.push(response.data[i].id);
                // }
                let isEnglishPresent = false;
                for (let index = 0; index < response.data.length; index++) {
                  const langDto = response.data[index];
                  if (langDto.name.substring(0, 2).toLowerCase() === "en") {
                    isEnglishPresent = true;
                  }
                }
                if (isEnglishPresent) {
                  setTargetLanguage("en-US");
                  localStorage.setItem("sub-lang", "en-US");
                  localStorage.setItem("sub-langName", "English");
                  let langObj = {
                    languageCode: localStorage.getItem("sub-lang"),
                    languageName: localStorage.getItem("sub-langName"),
                  };
                  setValue(langObj);
                } else {
                  setTargetLanguage(response.data[0].id);
                  localStorage.setItem("sub-lang", response.data[0].id);
                  localStorage.setItem("sub-langName", response.data[0].name);
                  let langObj = {
                    languageCode: localStorage.getItem("sub-lang"),
                    languageName: localStorage.getItem("sub-langName"),
                  };
                  setValue(langObj);
                }
              } else {
                setTargetLanguage("en-US");
                localStorage.setItem("sub-lang", "en-US");
                localStorage.setItem("sub-langName", "English");
                let langObj = {
                  languageCode: localStorage.getItem("sub-lang"),
                  languageName: localStorage.getItem("sub-langName"),
                };
                setValue(langObj);
              }
            }
          );
        }
      }
      setLoading(false);
    });
  }, [roomNumber]);

  React.useEffect(() => {
    if (tabKey) {
      updateTab({ data: selectedEvent }, tabKey);
    }
  }, [tabKey, selectedEvent]);

  const translateSubtitles = async () => {
    setLoading(true);
    console.log("translateSubtitles UseEffect", targetLanguage);
    let tempSubList = [];
    for (let index = 0; index < subtitles.length; index++) {
      let subDto = subtitles[index];
      console.log("translateSubtitles UseEffect", subDto);
      if (
        targetLanguage.substring(0, 2).toLowerCase() !==
        subDto.languageCode.toLowerCase().substring(0, 2)
      ) {
        let translatedText = await AppUtils.googleTranslate(
          subDto.transcript,
          subDto.languageCode,
          targetLanguage
        );
        subDto.transcript = translatedText;
        subDto.languageCode = targetLanguage;
      }
      tempSubList.push(subDto);
    }
    setSubtitles(tempSubList);
    setRefresh(refresh + 1);
    setLoading(false);
    const jsonString = JSON.stringify(tempSubList);
    localStorage.setItem("meetingSubs", jsonString);
  };

  const translateText = async (id, text, fromLang) => {
    let translatedText = await AppUtils.googleTranslate(
      text,
      fromLang,
      targetLanguage
    );
    console.log("translateText--->>", translatedText);
    // subtitles.forEach((subDto) => {
    //   if (subDto.id === id) {
    //     console.log("Here--->>", subDto);
    //     subDto.transcript = text;
    //     subDto.languageCode = targetLanguage;
    //   }
    // });
    await setSubtitles((prevData) => {
      const newData = [...prevData];
      newData[id].transcript = translatedText;
      newData[id].languageCode = targetLanguage;
      return newData;
    });
    let tempList = subtitles;
    const jsonString = JSON.stringify(tempList);
    localStorage.setItem("meetingSubs", jsonString);
    // setRefresh(refresh + 1);
  };

  const refreshSubtitles = () => {
    setLoading(true);
    let isNewSubPresent = false;
    getSubtitlesOfMeeting(roomNumber).then(async (response) => {
      let tempSubs = JSON.parse(localStorage.getItem("meetingSubs")) || [];
      if (response.data && response.data.length > tempSubs.length) {
        isNewSubPresent = true;
        tempSubs = [...tempSubs, ...response.data.slice(tempSubs.length)];
      }
      if (isNewSubPresent) {
        toast.success(t("subtitles_loaded_successfully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setSubtitles(tempSubs);
      } else {
        toast.info(t("no_more_subtitles_present"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setLoading(false);
    });
  };

  const refreshSubtitlesTimer = () => {
    let isNewSubPresent = false;
    getSubtitlesOfMeeting(roomNumber).then(async (response) => {
      let tempSubs = JSON.parse(localStorage.getItem("meetingSubs")) || [];
      if (response.data && response.data.length > tempSubs.length) {
        isNewSubPresent = true;
        tempSubs = [...tempSubs, ...response.data.slice(tempSubs.length)];
      }
      if (isNewSubPresent) {
        setSubtitles(tempSubs);
      }
    });
  };

  const translateAll = () => {
    translateSubtitles();
  };

  const generatePDF = async (data) => {
    console.log("generatePDF- selectedEvent", selectedEvent);
    setDownloading(true);
    toast.info(t("downloading_transcriptions_please_wait"), {
      position: toast.POSITION.TOP_RIGHT,
    });
    const fontUrl = publicUrl + "/zoyel_elements/fonts/ArialUnicodeMS.ttf";
    const fontName = "NotoSans";

    const fontOptions = {
      url: fontUrl,
      name: fontName,
      fontStyle: "normal",
      fontWeight: "normal",
    };

    const doc = new jsPDF();

    doc.addFont(
      fontOptions.url,
      fontOptions.name,
      fontOptions.fontStyle,
      fontOptions.fontWeight
    );

    doc.setFont(fontOptions.name);
    const tableColumnHeaders = ["Time", "Speaker Name", "Speaker Text"];
    const tableRows = [];

    const tableHeaderColor = "#8f95eb";
    const headerColor = "#000000";

    const dateExported = new Date();
    const headerText = `Meeting Transcript of ${selectedEvent.eventTitle}`;
    const footerTextLeft = `Transcript exported at ${DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
      dateExported
    )}`;
    const footerTextRight = userDetails.orgName;
    const response = await getOrgImageBase64(userDetails.orgId);
    const responseParticipants = await getParticipantsOfEvent(
      selectedEvent.eventId
    );

    const listItems = [
      `Description: ${selectedEvent.eventDescription}`,
      `Start Time: ${DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
        selectedEvent.eventStartTime
      )}`,
      `End Time: ${DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
        selectedEvent.eventEndTime
      )}`,
      `Participants: ${responseParticipants.data
        .map((obj) => obj.userName)
        .join(", ")}`,
    ];

    data.forEach((obj) => {
      const dataRow = [
        DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(obj.audioStartTime),
        obj.userName,
        obj.transcript,
      ];
      tableRows.push(dataRow);
    });

    const cellWidth = 40;
    const cellHeight = 5;
    const startY = 40;

    doc.addImage(response.data, "PNG", 15, 5, 15, 15);

    doc.setFontSize(16);
    doc.setTextColor(headerColor);
    doc.text(headerText, doc.internal.pageSize.getWidth() / 2, 15, {
      align: "center",
    });

    const lineY = 25;
    const lineWidth = doc.internal.pageSize.getWidth() - 20;
    const lineSpacing = 2;
    doc.setDrawColor(0);
    doc.setLineDash([lineSpacing]);
    doc.line(15, lineY, 5 + lineWidth, lineY);

    const listItemX = 15;
    const listItemY = 30;
    const listItemSpacing = 1;

    listItems.forEach((item, index) => {
      const listItemPosY = listItemY + index * (cellHeight + listItemSpacing);
      doc.setFontSize(10);
      doc.setTextColor("#000000");
      doc.text(item, listItemX, listItemPosY, {
        maxWidth: 180,
        align: "left",
        baseline: "top",
        angle: 0,
      });
    });

    doc.setLineDash([0]);
    const tableStartY =
      listItemY + listItems.length * (cellHeight + listItemSpacing) + 10;

    doc.autoTable({
      head: [tableColumnHeaders],
      body: tableRows,
      startY: tableStartY,
      theme: "grid",
      styles: {
        font: fontOptions.name,
        fontStyle: "normal",
      },
      headStyles: {
        fillColor: tableHeaderColor,
      },
    });

    const pageCount = doc.internal.getNumberOfPages();

    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.setTextColor("#000000");
      doc.text(footerTextLeft, 15, doc.internal.pageSize.getHeight() - 10, {
        align: "left",
      });
      doc.text(
        footerTextRight,
        doc.internal.pageSize.getWidth() - 15,
        doc.internal.pageSize.getHeight() - 10,
        { align: "right" }
      );
    }

    doc.save(
      `${roomNumber}_${DateUtils.getDateInddmmyy_hhmmss(dateExported)}.pdf`
    );
    setDownloading(false);
  };

  const generateMomPdf = async () => {
    const fontUrl = publicUrl + "/zoyel_elements/fonts/ArialUnicodeMS.ttf";
    const fontName = "NotoSans";

    const fontOptions = {
      url: fontUrl,
      name: fontName,
      fontStyle: "normal",
      fontWeight: "normal",
    };

    const doc = new jsPDF();
    doc.addFont(
      fontOptions.url,
      fontOptions.name,
      fontOptions.fontStyle,
      fontOptions.fontWeight
    );

    doc.setFont(fontOptions.name);

    const dateExported = new Date();
    const headerText = `Meeting MOM of ${selectedEvent.eventTitle}`;
    const footerTextLeft = `MOM exported at ${DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
      dateExported
    )}`;

    const footerTextRight = userDetails.orgName;
    const response = await getOrgImageBase64(userDetails.orgId);
    const responseParticipants = await getParticipantsOfEvent(
      selectedEvent.eventId
    );

    const listItems = [
      `Description: ${selectedEvent.eventDescription}`,
      `Start Time: ${DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
        selectedEvent.eventStartTime
      )}`,
      `End Time: ${DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
        selectedEvent.eventEndTime
      )}`,
      `Participants: ${responseParticipants.data
        .map((obj) => obj.userName)
        .join(", ")}`,
    ];

    const cellWidth = 40;
    const cellHeight = 5;
    const startY = 40;

    doc.addImage(response.data, "PNG", 15, 5, 15, 15);

    const headerColor = "#000000";
    doc.setFontSize(16);
    doc.setTextColor(headerColor);
    doc.text(headerText, doc.internal.pageSize.getWidth() / 2, 15, {
      align: "center",
    });

    const lineY = 25;
    const lineWidth = doc.internal.pageSize.getWidth() - 20;
    const lineSpacing = 2;
    doc.setDrawColor(0);
    doc.setLineDash([lineSpacing]);
    doc.line(15, lineY, 5 + lineWidth, lineY);

    const listItemX = 15;
    const listItemY = 30;
    const listItemSpacing = 1;

    listItems.forEach((item, index) => {
      const listItemPosY = listItemY + index * (cellHeight + listItemSpacing);
      doc.setFontSize(10);
      doc.setTextColor("#000000");
      doc.text(item, listItemX, listItemPosY, {
        maxWidth: 180,
        align: "left",
        baseline: "top",
        angle: 0,
      });
    });

    doc.setLineDash([0]);

    // const options = {
    //   html2canvas: { scale: 2 },
    //   jsPDF: { unit: "pt", format: "a4" },
    // };

    // Use html2pdf plugin
    // doc.html(rteHtml, options).then(() => {
    //   // Save the PDF
    //   doc.save("document.pdf");
    // });

    const marginLeft = 10;
    const marginTop = 64;
    const maxLineWidth = 180;
    const splitText = doc.splitTextToSize(rteText, maxLineWidth);

    doc.text(splitText, marginLeft, marginTop);

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.setTextColor("#000000");
      doc.text(footerTextLeft, 15, doc.internal.pageSize.getHeight() - 10, {
        align: "left",
      });
      doc.text(
        footerTextRight,
        doc.internal.pageSize.getWidth() - 15,
        doc.internal.pageSize.getHeight() - 10,
        { align: "right" }
      );
    }

    doc.save(
      `${roomNumber}_${DateUtils.getDateInddmmyy_hhmmss(dateExported)}.pdf`
    );

    setDownloading(false);
  };

  const generateMOM = () => {
    getSubtitlesOfMeeting(roomNumber).then((response) => {
      if (response.data && response.data.length > 0) {
        let tempString = response.data
          .map(
            (item) =>
              `${DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
                item.audioStartTime
              )} [${item.userName}]  ${item.transcript}`
          )
          .join(",");

        tempString =
          tempString + ", this is the transcript,generate MOM,Please ";

        const reqDto = {
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "user",
              content: tempString,
            },
          ],
        };

        chatAI(reqDto).then((res) => {
          console.log(res.data);
          const msg = res.data.choices[0].message.content;
          setRteText(msg);
          setShowMom(true);
        });
      } else {
        toast.error(t("could_not_generate_mom"));
      }
    });
  };

  //Meeting summary code

  const [recordingLink, setRecordingLink] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [meetingAgendasList, setMeetingAgendasList] = useState([]);
  const [meetingStartTime, setMeetingStartTime] = useState(null);
  const [meetingEndTime, setMeetingEndTime] = useState(null);
  const [
    meetingParticipantsDetailsAndTiming,
    setMeetingParticipantsDetailsAndTiming,
  ] = React.useState([]);
  const [meetingParticipants, setMeetingParticipants] = useState([]);
  useEffect(() => {
    //testing

    if (selectedEvent) {
      // selectedEvent.eventLink = "9702757653";
      // selectedEvent.eventId = 8742;
      getMeetingRecordingLink(selectedEvent.eventLink).then((response) => {
        console.log(response.data);
        if (response.data.returnValue === "1") {
          setRecordingLink(response.data.message);
        }
      });
      getAllFilesUploaded(selectedEvent.eventLink).then((response) => {
        console.log("getFiles Details", response.data);
        setFileList(response.data);
      });
      getMeetingAgendas(selectedEvent.eventId).then((response) => {
        setMeetingAgendasList(response.data);
      });
      getEventParticipants(selectedEvent.eventId).then((response) => {
        let sortedData = [];
        if (response.data.length > 1) {
          sortedData = response.data.sort(
            (a, b) => (b.convener === "Y") - (a.convener === "Y")
          );
        } else {
          sortedData = response.data;
        }
        console.log("sortedData-->>>", sortedData);
        setMeetingParticipants(sortedData);
      });
      getMeetingParticipantsDetailsAndTiming(selectedEvent.eventLink).then(
        (response) => {
          console.log("getMeetingParticipantsDetailsAndTiming", response.data);

          if (response.data && response.data.length > 0) {
            console.log("startTime", selectedEvent.eventStartTime);
            console.log("startTime", response.data[0].joinTime);
            setMeetingStartTime(response.data[0].joinTime);
          } else {
            setMeetingStartTime(selectedEvent.eventStartTime);
          }
          let meetingEndTimeTemp = null;
          for (let index = 0; index < response.data.length; index++) {
            const meetingParDto = response.data[index];
            if (null != meetingParDto.leaveTime) {
              if (null === meetingEndTimeTemp) {
                meetingEndTimeTemp = meetingParDto.leaveTime;
              }
              if (
                null !== meetingEndTimeTemp &&
                new Date(meetingEndTimeTemp) -
                  new Date(meetingParDto.leaveTime) <
                  0
              ) {
                meetingEndTimeTemp = meetingParDto.leaveTime;
              }
            }
          }
          if (!meetingEndTimeTemp) {
            meetingEndTimeTemp = selectedEvent.eventEndTime;
          }
          setMeetingEndTime(meetingEndTimeTemp);
          setMeetingParticipantsDetailsAndTiming(response.data);
        }
      );
    }
  }, [selectedEvent.eventLink]);

  const handleDownloadFile = (fileName, folderName, fileType) => {
    toast.info("Downloading file please wait", {
      position: toast.POSITION.TOP_RIGHT,
    });
    window.open(
      apiEndPoint + `/appTest/downloadFileTest/${fileName}/${folderName}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handelDownloadFileFromUrl = (files) => {
    files.forEach((file) => {
      downloadFile(file.fileName, userDetails.orgId).then((response) => {
        window.open(
          apiEndPoint +
            `/appTest/downloadFileTest/${file.fileName}/${userDetails.orgId}`,
          "_blank",
          "noopener,noreferrer"
        );
      });
    });
  };

  return (
    <>
      <div className="MeetingTranscriptionContainer">
        <div className="Meeting_Transcription">
          <div className="transcripHeader"></div>
          <div className="transcriptionContainArea">
            <div class="meeting-summary">
              <div class="left-section">
                <h2>Meeting Details</h2>
                <p>
                  <strong>Meeting Name:</strong> {selectedEvent?.eventTitle}
                </p>
                <p>
                  <strong>Meeting Description:</strong>{" "}
                  {selectedEvent?.eventDescription}
                </p>
                <p>
                  <strong>Meeting Scheduled Time:</strong>{" "}
                  {DateUtils.printEventDate(
                    new Date(selectedEvent.eventStartTime),
                    new Date(selectedEvent.eventEndTime)
                  )}
                </p>

                <p>
                  <strong>Meeting Convened Time:</strong>{" "}
                  {DateUtils.printEventDate(
                    new Date(meetingStartTime),
                    new Date(meetingEndTime)
                  )}
                </p>

                <div class="meeting-details">
                  <h3>Guests</h3>
                  {meetingParticipants.map((participantDto) => {
                    return (
                      <>
                        <div className={`metGustLstInd gustAsConvenor`}>
                          <div className="metGstImg">
                            <img src={participantDto.profileImage} alt="" />

                            {participantDto.availability === "Y" && (
                              <span className="metConVerd" title="available">
                                <CheckCircle />
                              </span>
                            )}
                            {participantDto.availability === "N" && (
                              <span className="metConRej" title="unavailable">
                                <CancelRounded />
                              </span>
                            )}
                            {participantDto.availability !== "N" &&
                              participantDto.availability !== "Y" && (
                                <span className="metConNS">
                                  <CheckCircle />
                                </span>
                              )}
                          </div>
                          <div className="metGstName">
                            {participantDto.userName}
                            {participantDto.convener === "Y" && (
                              <span className="metConvenor">(Convenor)</span>
                            )}
                            {participantDto.chairperson === "Y" && (
                              <span className="metConvenor">(Chairperson)</span>
                            )}
                          </div>
                        </div>
                        <ul>
                          {meetingParticipantsDetailsAndTiming.map(
                            (meetingParDto) => {
                              if (
                                meetingParDto.partcipantId ===
                                participantDto.userId
                              ) {
                                return (
                                  <>
                                    <li>
                                      {/* <strong>
                                        {meetingParDto.participantName}:
                                      </strong>{" "} */}
                                      {/* <ul>
                                   <li>
                                     {!meetingParDto.joinTime
                                       ? ""
                                       : "Joined at " +
                                         DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
                                           new Date(meetingParDto.joinTime)
                                         )}
                                   </li>
   
                                   <li>
                                     {!meetingParDto.joinTime
                                       ? ""
                                       : "Left at " +
                                         DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
                                           new Date(meetingParDto.leaveTime)
                                         )}
                                   </li>
                                 </ul> */}
                                      {!meetingParDto.joinTime
                                        ? ""
                                        : "Joined at " +
                                          DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
                                            new Date(meetingParDto.joinTime)
                                          )}
                                      {!meetingParDto.joinTime
                                        ? ""
                                        : ", Left at " +
                                          DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
                                            new Date(meetingParDto.leaveTime)
                                          )}
                                    </li>

                                    <br></br>
                                  </>
                                );
                              } else {
                                return <></>;
                              }
                            }
                          )}
                        </ul>
                        <br></br>
                      </>
                    );
                  })}
                </div>
              </div>
              <div class="right-section">
                {/* recording links */}
                {isConvenor && (
                  <>
                    <h2>Meeting Recordings</h2>
                    <div class="section meeting-links">
                      {recordingLink != null
                        ? recordingLink.split("~").map((data, index) => {
                            return (
                              <a href={data} rel="noreferrer" target="_blank">
                                Recording {index + 1}
                              </a>
                            );
                          })
                        : "No recordings present"}
                    </div>
                  </>
                )}

                {/*meeting transcripts  */}
                {isConvenor && (
                  <div class="section meeting-transcript">
                    <h2>Meeting Transcript</h2>
                    <div class="buttons">
                      <Autocomplete
                        className="transcripAutoCompleteView"
                        disablePortal
                        id="transcripAutoComplete"
                        options={languages}
                        value={value}
                        onChange={(event, newValue) => {
                          setTargetLanguage(newValue?.languageCode);
                          localStorage.setItem(
                            "sub-lang",
                            newValue?.languageCode
                          );
                          localStorage.setItem(
                            "sub-langName",
                            newValue?.languageName
                          );
                          setValue(newValue);
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        getOptionLabel={(option) => option?.languageName}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            hiddenLabel
                            placeholder={t("select_translate_language")}
                          />
                        )}
                      />
                      <Button
                        className="transAllBtn"
                        startIcon={<Translate />}
                        onClick={() => {
                          translateAll();
                        }}
                      >
                        {t("translate_all")}
                      </Button>

                      <Button
                        className="transAllBtn"
                        onClick={() => {
                          generateMOM();
                        }}
                      >
                        {t("generate_mom")}
                      </Button>

                      <Button
                        className="transAllBtn"
                        startIcon={<DownloadForOffline />}
                        onClick={() => {
                          generatePDF(subtitles);
                        }}
                        disabled={downloading}
                      >
                        {downloading ? t("please_wait") : t("download")}
                      </Button>
                      {/* <button>Download MOM</button> */}
                      {/* <button>Download PDF</button> */}
                    </div>
                    <div class="transcript">
                      {/* <p>
                      <strong>John:</strong> Welcome everyone to the meeting...
                    </p>
                    <p>
                      <strong>Jane:</strong> Thank you, John. Today we'll
                      discuss...
                    </p>
                    <p>
                      <strong>Mike:</strong> I'd like to add on...
                    </p> */}
                      {subtitles &&
                        targetLanguage &&
                        subtitles.map((subDto, index) => {
                          return (
                            <p key={subDto.id}>
                              <span>
                                {DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
                                  subDto.audioStartTime
                                )}
                                :
                              </span>
                              <span>
                                &nbsp;&nbsp;[{subDto.userName}]&nbsp;&nbsp;
                              </span>
                              <span>
                                {typeof subDto.transcript === "string" &&
                                  subDto.transcript}
                              </span>
                              {targetLanguage &&
                                subDto.languageCode
                                  .substring(0, 2)
                                  .toLowerCase() !==
                                  targetLanguage
                                    .substring(0, 2)
                                    .toLowerCase() && (
                                  <span>
                                    <button
                                      className="translateEachBtn"
                                      onClick={() =>
                                        translateText(
                                          index,
                                          subDto.transcript,
                                          subDto.languageCode
                                        )
                                      }
                                      title={t("translate")}
                                    >
                                      {/* {t("translate")} */}
                                      <TranslateSharp />
                                    </button>
                                  </span>
                                )}
                            </p>
                          );
                        })}
                      {subtitles &&
                        subtitles.length === 0 &&
                        t("no_subtitles_present")}
                      <span className="loadMoreSpn">
                        <Button
                          onClick={() => {
                            refreshSubtitles();
                          }}
                          className="loadMoreBtn"
                        >
                          {subtitles && subtitles.length === 0
                            ? t("load_subtitles")
                            : t("load_more")}
                        </Button>
                      </span>
                    </div>
                  </div>
                )}

                {/*meeting files uploaded  */}
                <h2>Meeting Files</h2>
                <div class="section meeting-files">
                  {/* <a href="#">File 1</a>
                  <a href="#">File 2</a>
                  <a href="#">File 3</a> */}
                  {(fileList === null || fileList?.length === 0) &&
                    "No files were uploaded during the meeting"}
                  {fileList.map((file, index) => (
                    <>
                      <a
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleDownloadFile(
                            file.field1,
                            file.field3,
                            file.field4
                          )
                        }
                      >
                        {index + 1 + ") "}
                        {file.field1}
                      </a>
                    </>
                  ))}
                </div>

                {/*meeting agenda  */}
                <h2>Meeting Agenda</h2>
                <div class="section meeting-agenda">
                  {meetingAgendasList !== null &&
                  meetingAgendasList.length > 0 ? (
                    <table>
                      <thead>
                        <tr>
                          <th>Sl No</th>
                          <th>Subject</th>
                          <th>Details</th>
                          <th>Polling/Show of hands</th>
                          <th>files</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <tr>
                        <td>10:00 AM</td>
                        <td>Introduction</td>
                        <td>John</td>
                        <td>Overview of the meeting</td>
                      </tr> */}
                        {meetingAgendasList.map((agenda, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{agenda.noticeSubject}</td>
                            <td>{agenda.noticeDetail}</td>
                            <td>
                              {agenda.polling === "Y" && (
                                <Tooltip
                                  title={t("polling")}
                                  arrow
                                  placement="right"
                                >
                                  <OneFingerSelectHandGesture className="attachIcoSelected" />
                                </Tooltip>
                              )}
                              {agenda.showOfHand === "Y" && (
                                <Tooltip
                                  title={t("show_of_hand")}
                                  arrow
                                  placement="right"
                                >
                                  <SpockHandGesture className="attachIcoSelected" />
                                </Tooltip>
                              )}
                            </td>
                            <td style={{ cursor: "pointer" }}>
                              {agenda.fileLinks.length > 0 && (
                                <>
                                  <Tooltip
                                    title={
                                      <ul>
                                        {agenda.fileLinks.map(
                                          (file, fileIndex) => (
                                            <li key={fileIndex}>
                                              {file.fileName}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    }
                                  >
                                    <AttachFile
                                      className="attachIcoSelected"
                                      onClick={() =>
                                        handelDownloadFileFromUrl(
                                          agenda.fileLinks
                                        )
                                      }
                                    />
                                  </Tooltip>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    "No meeting agendas present"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showMom && (
        <CustomModal
          modalTitle={t("update_mom")}
          // style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowMom(false);
          }}
          className={"width90vwModel"}
        >
          <div>
            <ZoyelRTERnd
              setRteHtml={setRteHtml}
              setRteText={setRteText}
              setImageList={setImageList}
              imageList={imageList}
              placeholder={t("write_about_your_product_here")}
              rteRawJson={rteRawJson}
              rteText={rteText}
              setRteRawJson={setRteRawJson}
            />
            <Button
              onClick={() => {
                console.log("Creating Pdf");
                generateMomPdf();
              }}
            >
              {t("download_pdf")}
            </Button>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default MeetingSummary;
