import React from "react";

export const ViewCompoundItem = ({ compoundItem, compoundType }) => {
  const classMap = { or: "compOr", then: "compThen" };

  return (
    <>
      <div
        className={`compoundIndv ${compoundType ? classMap[compoundType] : ""}`}
      >
        {compoundType && (
          <div className="compChild">
            {compoundType === "or" ? "Or" : "Then"}
          </div>
        )}
        <div className="compfulDtl">
          <div className="compHeader">
            <div className="compName">{compoundItem?.compoundName}</div>
          </div>
          <div className="medRecStpGrp">
            <div className="medRecStpInd">
              <span>Form:</span>
              {compoundItem.formName}
            </div>
            <div className="medRecStpInd">
              <span>Route:</span>
              {compoundItem.routeName}
            </div>

            <div className="medRecStpInd">
              <span>Dose:</span>
              {compoundItem.dosages}
            </div>
            <div className="medRecStpInd">
              <span>Dose unit:</span>
              {compoundItem.dosagesUnit}
            </div>
            <div className="medRecStpInd">
              <span>Dose limit:</span>
              {compoundItem.dosagesLimit}
            </div>
            <div className="medRecStpInd">
              <span>Duration:</span>
              {compoundItem.duration}
            </div>
            <div className="medRecStpInd">
              <span>Frequency:</span>
              {compoundItem.frequency}
            </div>
          </div>
        </div>
        {compoundItem?.thenList?.map((item) => {
          return <ViewCompoundItem compoundItem={item} compoundType={"then"} />;
        })}

        {compoundItem?.orList?.map((item) => {
          return <ViewCompoundItem compoundItem={item} compoundType={"or"} />;
        })}
      </div>
    </>
  );
};
