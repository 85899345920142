import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardServices from "../../../../../services/DashboardServices";
import { PersonDown } from "react-bootstrap-icons";
import { ConfirmProvider } from "material-ui-confirm";
import BiometricMarkAttendanceFace from "./BiometricMarkAttendanceFace";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function MarkAttendance({ openDialog, dated, closeDialog, loginUser }) {
  const { t } = useTranslation();
  const [faceId, setFaceId] = useState(null);
  const [showFaceReg, setShowFaceReg] = useState(false);
  const [locationAccess, setLocationAccess] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [error, setError] = useState(null);

  const handleClickMarkAttendance = (locAccess) => {
    if (locAccess) {
      DashboardServices.getFaceId(loginUser.userId).then((response) => {
        if (!response.data) {
          setFaceId(null);
        } else {
          setFaceId(response.data);
        }
        console.log("getFaceId-->>>", response.data);

        setShowFaceReg(true);
      });
    } else {
      toast.info(t("please_provide_location_access_to_mark_attendance"));
      // fetchGeoLocation();
    }
  };

  const fetchGeoLocation = async () => {
    try {
      console.log("fetchGeoLocation", new Date().getTime());
      const permissionResult = await navigator.permissions.query({
        name: "geolocation",
      });
      console.log("fetchGeoLocation", new Date().getTime(), permissionResult);

      permissionResult.onchange = () => {
        console.log("onChangePermission", permissionResult.state);
        setLocationAccess(permissionResult.state === "granted");
        if (permissionResult.state === "granted") {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              console.log("latitude-->>>", position.coords.latitude);
              console.log("longitude-->>>", position.coords.longitude);
              setLocationAccess(true);
              setLatitude(position.coords.latitude);
              setLongitude(position.coords.longitude);
              handleClickMarkAttendance(permissionResult.state === "granted");
            },
            (err) => {
              setError(err.message);
            }
          );
        } else if (permissionResult.state === "denied") {
          toast.error(
            t("mark_attendance_is_unavailable_as_you_have_denied_location_access_to_this_site_please_allow_access_from_your_browser_settings")
          );
        } else {
          toast.info(t("please_provide_location_access_to_mark_attendance"));
        }
      };

      if (permissionResult.state === "granted") {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log("latitude-->>>", position.coords.latitude);
            console.log("longitude-->>>", position.coords.longitude);
            setLocationAccess(true);
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
            handleClickMarkAttendance(permissionResult.state === "granted");
          },
          (err) => {
            setError(err.message);
          }
        );
      } else {
        if (permissionResult.state === "denied") {
          toast.error(
            t("mark_attendance_is_unavailable_as_you_have_denied_location_access_to_this_site_please_allow_access_from_your_browser_settings")
          );
        } else {
          toast.info(t("please_provide_location_access_to_mark_attendance"));
        }

        navigator.geolocation.getCurrentPosition(
          () => {},
          () => {}
        );
        // console.log("result", result);
        // setLocationAccess(result.state === "granted");
      }

      // Handle initial permission state
      // setLocationAccess(permissionResult.state === "granted");

      // if (permissionResult.state !== "granted") {
      //   toast.info("Please provide location access to mark attendance");
      //   const result = await navigator.geolocation.getCurrentPosition(
      //     () => {},
      //     () => {}
      //   );
      //   setLocationAccess(result.state === "granted");

      //   return; // Exit if not granted
      // }

      // navigator.geolocation.getCurrentPosition(
      //   (position) => {
      //     console.log("latitude-->>>", position.coords.latitude);
      //     console.log("longitude-->>>", position.coords.longitude);
      //     setLatitude(position.coords.latitude);
      //     setLongitude(position.coords.longitude);
      //     handleClickMarkAttendance();
      //   },
      //   (err) => {
      //     setError(err.message);
      //   }
      // );
    } catch (err) {
      setError(
        "Error determining geolocation status. Please check browser settings."
      );
    }
  };

  // useEffect(() => {
  //   if (locationAccess) {
  //     handleClickMarkAttendance();
  //   }
  // }, [locationAccess]);

  return (
    <>
      <Tooltip title={t("mark_attendance")}>
        <IconButton
          onClick={() => {
            fetchGeoLocation();
          }}
          className="markAttendanceIcon"
        >
          <PersonDown />
        </IconButton>
      </Tooltip>
      <ConfirmProvider>
        <BiometricMarkAttendanceFace
          open={showFaceReg}
          onClose={() => setShowFaceReg(false)}
          faceId={faceId}
          setFaceId={setFaceId}
          setShowRegFace={setShowFaceReg}
          latitude={latitude}
          longitude={longitude}
        />
      </ConfirmProvider>
    </>
  );
}

export default MarkAttendance;
