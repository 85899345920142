import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
  RadialLinearScale,
  PolarAreaController,
} from "chart.js";
import React from "react";

import { Add } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { Bar, Pie, PolarArea } from "react-chartjs-2";
import { ArrowRepeat } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

export default function CardPatientDocument({
  handelOpenViewPatientDocument,
  chartDataOfItem,
  fetchDashBoardData,
}) {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController,
    RadialLinearScale,
    PolarAreaController
  );

  const { t } = useTranslation();

  // const polarChartlabel = chartDataOfItem?.todayUnitRxMedInvRefCount ? Object.keys(chartDataOfItem.todayUnitRxMedInvRefCount) : [];

  // const polartChartData = chartDataOfItem?.todayUnitRxMedInvRefCount ? Object.values(chartDataOfItem.todayUnitRxMedInvRefCount) : [];

  // const data = {
  //   // labels: ["Completed", "Queue", "New", "Review"],
  //   labels : polarChartlabel ,
  //   datasets: [
  //     {
  //       label: "",
  //       // data: [12, 19, 3, 5],
  //       data : polartChartData ,
  //       backgroundColor: [
  //         "rgba(79, 212, 0, 1)",
  //         "rgba(234, 184, 0, 1)",
  //         "rgba(241, 61, 60, 1)",
  //         "rgba(254, 162, 59, 1)",
  //       ],
  //       borderColor: [
  //         "rgba(255, 255, 255, .6)",
  //         "rgba(255, 255, 255, .6)",
  //         "rgba(255, 255, 255, .6)",
  //         "rgba(255, 255, 255, .6)",
  //       ],
  //       borderWidth: 1,
  //     },
  //   ],
  // };

const { todayUnitRxMedInvRefCount } = chartDataOfItem || {};

const noOfPrescription = todayUnitRxMedInvRefCount?.noOfPrescription || 0;

const labels = [
  todayUnitRxMedInvRefCount?.noMedicine === 0 ? "" : "No Medicine",
  todayUnitRxMedInvRefCount?.overmedication === 0 ? "" : "Overmedication",
  todayUnitRxMedInvRefCount?.test === 0 ? "" : "Test",
  todayUnitRxMedInvRefCount?.refer === 0 ? "" : "Refer"
];

const dataValues = [
  todayUnitRxMedInvRefCount?.noMedicine === 0 ? noOfPrescription : todayUnitRxMedInvRefCount?.noMedicine,
  todayUnitRxMedInvRefCount?.overmedication === 0 ? noOfPrescription : todayUnitRxMedInvRefCount?.overmedication,
  todayUnitRxMedInvRefCount?.test === 0 ? noOfPrescription : todayUnitRxMedInvRefCount?.test,
  todayUnitRxMedInvRefCount?.refer === 0 ? noOfPrescription : todayUnitRxMedInvRefCount?.refer
];

const backgroundColors = [
  todayUnitRxMedInvRefCount?.noMedicine === 0 ? "rgb(84, 84, 84, 1)" : "rgba(79, 212, 0, 1)",
  todayUnitRxMedInvRefCount?.overmedication === 0 ? "rgb(84, 84, 84 , 1)" : "rgba(241, 61, 60, 1)",
  todayUnitRxMedInvRefCount?.test === 0 ? "rgb(84, 84, 84, 1)" : "rgba(254, 162, 59, 1)",
  todayUnitRxMedInvRefCount?.refer === 0 ? "rgb(84, 84, 84, 1)" : "rgba(234, 184, 0, 1)"
];

const data = todayUnitRxMedInvRefCount
  ? {
      labels,
      datasets: [
        {
          label: "",
          data: dataValues,
          backgroundColor: backgroundColors,
          borderColor: "rgba(255, 255, 255, .6)",
          borderWidth: 1,
        },
      ],
    }
  : { labels: [], datasets: [] };

const options = {
  responsive: true,
  scales: {
    r: {
      ticks: {
        display: false,
      },
      max: todayUnitRxMedInvRefCount?.noOfPrescription || 100,
      min: 0,
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
};


  // const options = {
  //   cutout: "80%",
  //   responsive: true,
  //   scales: {
  //     r: {
  //       ticks: {
  //         display: false, 
  //         stepSize: 10, 
  //         max: 100,
  //         min: 0,
  //       },
  //       // grid: {
  //       //   color: "rgba(255, 255, 255, 0.2)", 
  //       //   lineWidth: 1,
  //       // },
  //       // angleLines: {
  //       //   color: '#292929',
  //       //   lineWidth: 1,
  //       // },
  //     },
  //   },
  //   plugins: {
  //     datalabels: {
  //       display: false,
  //     },
  //     legend: {
  //       display: false,
  //       position: "bottom",
  //       labels: {
  //         boxWidth: 6,
  //         color: "#d2d2d2",
  //         font: {
  //           size: 12,
  //           weight: "light",
  //         },
  //       },
  //     },
  //   },
  // };

  // const options = {
  //   cutout: "80%",
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       display: false,
  //       position: "bottom",
  //       labels: {
  //         boxWidth: 6,
  //         color: "#d2d2d2",
  //         font: {
  //           size: 12,
  //           weight: "light",
  //         },
  //       },
  //     },
  //   },
  // };

  const barLineData = {
    labels:
      chartDataOfItem?.todayUnitDiagonistic?.length > 0
        ? chartDataOfItem.todayUnitDiagonistic.map((item) => item.label)
        : [],
    datasets: [
      {
        label: "",
        data:
          chartDataOfItem?.todayUnitDiagonistic?.length > 0
            ? chartDataOfItem.todayUnitDiagonistic.map((item) => item.count)
            : [],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const barLineOoptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  return (
    <>
      <div className="anDashIndCard">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">
            {t("patient_documents")}
              <IconButton
                className={`refreshIcon`}
                onClick={fetchDashBoardData}
              >
                <ArrowRepeat />
              </IconButton>
            </div>
            <div className="andCrdUnitCount">
              {/* <span title="Attrition"> */}
                {/* <Male /> */}
                {/* This Month: <span className="fbold"> 215421</span> */}
              {/* </span> */}

              {/* <span title="Tenure"> */}
                {/* <Female /> */}
                {/* This week: <span className="fbold">12154</span> */}
              {/* </span> */}
            </div>
          </div>

          <div className="andCrdGraf">
            <div className="anDLftGrf">
              <div className="centTxtShow">
                {/* <Pie
                  className="hw100"
                  type="doughnut"
                  data={data}
                  options={options}
                /> */}
                <PolarArea
                  className="hw100"
                  type="polarArea"
                  data={data}
                  options={options}
                />
                <div className="highlighted-text">
                  {/* <span>Total</span> */}
                  {/* <span className="highDataCount">1602</span> */}
                </div>
              </div>
            </div>
            <div className="anDRightGrf">
              <Bar
                className="hw100"
                type="bar"
                data={barLineData}
                options={barLineOoptions}
              />
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>

          {/* <div className="andCrdData"></div> */}
          <div className="andCrdFooter">
            <Button
              className="dfultPrimaryBtn "
              onClick={() => handelOpenViewPatientDocument()}
            >
              {t("view")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
