import { Edit, OpenInNew, TextSnippet } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Tooltip,
  styled,
  tooltipClasses,
} from "@mui/material";
import { Eye } from "iconoir-react";
import React, { useState } from "react";
import { XLg } from "react-bootstrap-icons";
import PrescriptionEdit from "./consultation/PrescriptionEdit";
import PrescriptionView from "./consultation/PrescriptionView";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { CancelConsultation } from "./CancelConsultation";
import dayjs from "dayjs";
import { getLoginUserDetails } from "../../../../../../utils/Utils";
import DashboardServices from "../../../../../../services/DashboardServices";
import { doctorSocket } from "../../../../../../socket";
import DateUtils from "../../../../../../utils/DateUtils";

export default function DoctorConsultations(props) {
  const { t } = useTranslation();
  let loginUserDetail = getLoginUserDetails();
  // coustom tooltip start
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 320,
      minWidth: 180,
      maxHeight: 200,
      overflow: "overlay",
      padding: "16px 20px",
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));
  // coustom tooltip end

  const [showPrescription, setShowPrescription] = useState(true);
  const [showCancellationModule, setShowCancellationModule] = useState(false);

  const validatePescriptionData = () => {
    //Validate Symptoms
    let isValid = true;
    if (
      !props.prescriptionData.symptoms ||
      props.prescriptionData.symptoms.length === 0
    ) {
      toast.error(t("symptoms_cannot_be_empty"));
      isValid = false;
    }
    if (
      props.prescriptionData.referType &&
      props.prescriptionData.referType === "otherSpeciality" &&
      (!props.prescriptionData.referDoctorSpeciality ||
        props.prescriptionData.referDoctorSpeciality === "")
    ) {
      toast.error(t("please_select_doctor_speciality"));
      isValid = false;
    }

    if (
      !props.prescriptionData.doctorNote &&
      !props.prescriptionData.audioData
    ) {
      toast.error("Please enter doctor note or add audio note");
      isValid = false;
    }

    // if (
    //   !prescriptionData.riskFactor ||
    //   prescriptionData.riskFactor.length === 0
    // ) {
    //   toast.error("Riskfactor cannot be empty.");
    //   isValid = false;
    // }

    // if (
    //   !prescriptionData.investigation ||
    //   prescriptionData.investigation.length === 0
    // ) {
    //   toast.error("Investigation cannot be empty.");
    //   isValid = false;
    // }

    // if (
    //   !prescriptionData.diagonesis ||
    //   prescriptionData.diagonesis.length === 0
    // ) {
    //   toast.error("Diagonesis cannot be empty.");
    //   isValid = false;
    // }

    // if (
    //   !prescriptionData.procedure ||
    //   prescriptionData.procedure.length === 0
    // ) {
    //   toast.error("Procedure cannot be empty.");
    //   isValid = false;
    // }

    if (!isValid) {
      return false;
    }
    return true;
  };

  const getReferStringFromType = (refer) => {
    switch (refer) {
      case "higherFacility":
        return "Advise higher facility";

      case "immediateHospitalization":
        return "Advise immediate hospitalization";

      case "otherSpeciality":
        return "Refer to other speciality";

      case "medicoLegal":
        return "Refer to medico legal";

      default:
        return "";
    }
  };

  const handleClickShowPrescription = () => {
    const isValid = validatePescriptionData();
    if (isValid) {
      setShowPrescription(!showPrescription);
    }
  };

  const submitPrescription = () => {
    console.log("Code Comitting");
    console.log("all Data=", props.prescriptionData);

    let data = props.prescriptionData;
    let extraData = props.extraData;
    let procedures = [];
    let medicines = [];
    let dieseases = [];
    let lifeStyles = [];
    let diets = [];
    let paramsToMonitor = [];
    let tests = [];
    let vitals = [];
    let foodAllergies = [];
    let signs = [];
    let symptoms = [];
    let riskfactors = [];
    let medicineAllergies = [];
    let comorbidities = [];
    let pastProcedures = [];
    let pastDieseases = [];
    let possibleConditions = [];
    let possibleInvestigations = [];
    let hereditaryDiseases = [];
    let phyExams = [];

    if (data.procedure) {
      procedures = data.procedure.map((item, index) => {
        return {
          procedureName: item.procedure.procedureName,
          procedureId: item.procedure.locallyAdded ? 0 : item.procedure.id,
          planDate: dayjs(item.date).format("DD-MM-YYYY"),
          slNo: index + 1,
        };
      });
    }

    if (data.medication) {
      medicines = data.medication.map((item, index) => {
        let brandName = "";
        let brandId = 0;
        if (item.brandName) {
          brandName = item.brandName.brandName;
          brandId = item.brandName.id;
        }
        return {
          genericId: item.genericName.locallyAdded ? 0 : item.genericName.id,
          brandId: brandId,
          genericName: item.genericName.generic,
          brandName: brandName,
          dosages: item?.dosage?.dosage || "",
          dosagesUnit: item?.dosage?.dosageUnit || "",
          route: item?.root?.route || "",
          durationLabel: item?.duration?.durationName || "",
          durationDays: item?.duration?.days || "",
          timing: item?.timing?.join(",") || "",
          note: item.note,
          adq: 0,
          preExisting: "",
          slNo: index + 1,
          frequency: item?.frequency?.frequency || "",
        };
      });
    }

    if (data.diagonesis) {
      dieseases = data.diagonesis.map((item, index) => {
        const diseaseId = item?.diseaseObj.locallyAdded
          ? 0
          : item.diseaseObj.id;
        return {
          diseaseName: item?.diseaseObj?.diseaseName,
          diseaseId,
          nature: item.status,
          snowmedCt: item?.diseaseObj?.snowmedCt,
          icd11: item?.diseaseObj?.icd11code,
          slNo: index + 1,
        };
      });
    }

    if (data.lifeStyle) {
      lifeStyles = data.lifeStyle.map((item, index) => {
        const lifeStyleId = item.locallyAdded ? 0 : item.id;
        return {
          lifeStyleName: item.lifeStyle,
          lifeStyleId: lifeStyleId,
          slNo: index + 1,
        };
      });
    }

    if (data.diet) {
      diets = data.diet.map((item, index) => {
        let dietId = item.locallyAdded ? 0 : item.id;
        return {
          dietName: item.diet,
          dietId,
          slNo: index + 1,
        };
      });
    }

    if (data.vitals) {
      vitals = data.vitals.map((item) => {
        let vitalValue = item.vitalValue;
        if (item.vitalCode === "BP") {
          if (vitalValue === "/") vitalValue = "";
        }
        if (item.vitalCode === "LMP") {
          vitalValue = dayjs(new Date(vitalValue)).format("DD-MM-YYYY");
        }
        return {
          vitalName: item.vitalName,
          unit: item.unit,
          vitalValue: vitalValue,
          recodedTime: item.recodedTime,
          isNormal: item.isNormal,
          vitalCode: item.vitalCode,
        };
      });
    }

    if (data.investigation) {
      tests = data.investigation.map((item) => {
        return {
          testId: item.testId,
          testName: item.testName,
          location: item.location,
          view: item.view,
          technique: item.technique,
        };
      });
    }

    if (data.itemToMonitor) {
      paramsToMonitor = data.itemToMonitor;
    }

    if (data.allergicFood) {
      foodAllergies = data.allergicFood
        .filter((item) => !item.previouslyAdded)
        .map((item) => {
          return item.foodName;
        });
    }

    if (data.singObj) {
      signs = data.singObj.map((item, index) => {
        let signId = item?.sign?.locallyAdded ? 0 : item?.sign?.id;
        return {
          signName: item.sign.signName,
          signId,
          signNote: item.userNote,
          slNo: index + 1,
        };
      });
    }

    if (data.symptoms) {
      symptoms = data.symptoms.map((item, index) => {
        let symptomId = item.selectedSymptom.locallyAdded
          ? 0
          : item.selectedSymptom.id;
        return {
          symptomName: item.selectedSymptom.symptomName,
          symptomId,
          severity: item.severity,
          since: item.since,
          slNo: index + 1,
        };
      });
    }

    if (data.riskFactor) {
      riskfactors = data.riskFactor.map((item, index) => {
        let riskFactorId = item.riskFactor.locallyAdded
          ? 0
          : item.riskFactor.id;
        return {
          since: item.since,
          riskFactorId,
          riskFactorName: item.riskFactor.riskFactor,
          slNo: index,
        };
      });
    }

    if (data.allergicDrug) {
      medicineAllergies = data.allergicDrug
        ?.filter((item) => !(item.previouslyAdded === true))
        ?.map((item, index) => {
          let type = "Generic";
          let genericBrandId = 0;
          let genericBrandName = "";
          if (item.brand) {
            type = "Brand";
            genericBrandId = item.brand.id;
            genericBrandName = item.brand.brandName;
          } else {
            genericBrandId = item.genericItem.locallyAdded
              ? 0
              : item.genericItem.id;
            genericBrandName = item.genericItem.generic;
          }
          return {
            type: type,
            genericBrandId: genericBrandId,
            genericBrandName: genericBrandName,
          };
        });
    }

    if (data?.comorbidity) {
      comorbidities = data?.comorbidity?.map((item, index) => {
        return {
          slNo: index + 1,
          diseaseName: item.disease.comorbiditiesDiseasesName,
          diseaseIcdIICode: item.disease.diseaseIcdIICode || "",
          since: item.since,
        };
      });
    }

    if (data?.prevSurgery) {
      pastProcedures = data?.prevSurgery
        ?.filter((item) => !item?.surgery?.previouslyAdded)
        ?.map((item) => {
          return {
            procedureName: item.surgery.procedureName,
            procedureDate: item.date,
          };
        });
    }

    if (data?.pastIllness) {
      pastDieseases = data?.pastIllness
        ?.filter((item) => !item.illness?.previouslyAdded)
        ?.map((item) => {
          return {
            diseaseName: item.illness.diseaseName,
            diagnosisDate: dayjs(item.date).format("DD-MM-YYYY"),
          };
        });
    }

    if (data?.possibleDiagonesis) {
      possibleConditions = data?.possibleDiagonesis?.map((item) => {
        return {
          diseaseName: item.diseaseName,
          diseaseId: item.locallyAdded ? 0 : item.id,
        };
      });
    }

    if (data?.investigation) {
      possibleInvestigations = data?.investigation?.map((item) => {
        return {
          testId: item.locallyAdded ? 0 : item.testId,
          testName: item.testName,
          location: item.location,
          view: item.view,
          technique: item.technique,
        };
      });
    }

    if (data?.familyHistory) {
      hereditaryDiseases = data?.familyHistory
        ?.filter((item) => !item.previouslyAdded)
        ?.map((item) => {
          return {
            diseaseName: item.disease.diseaseName,
            relationship: item.relationShip,
          };
        });
    }

    if (data?.physicalExamList) {
      phyExams = data?.physicalExamList?.map((item, index) => {
        return {
          slNo: index,
          phyExamId: item.issue.phyExamId,
          phyExamImmediateParId: item.issue.phyExamParId,
          phyExamFreeText: item.issue.phyExamName,
        };
      });
    }
    const reqDto = {
      visitId: extraData.queueVisitId,
      doctorUserId: loginUserDetail.userId,
      reviewDate: data.reviewDate ? data.reviewDate : "",
      doctorNote: data.doctorNote,
      patientId: extraData.queuePatientId,
      procedures,
      medicines,
      dieseases,
      lifeStyles,
      diets,
      paramsToMonitor,
      vitals,
      tests,
      foodAllergies,
      signs,
      symptoms,
      riskfactors,
      medicineAllergies,
      comorbidities,
      pastProcedures,
      pastDieseases,
      possibleConditions,
      possibleInvestigations,
      hereditaryDiseases,
      phyExams,
      bmiValue: data.bmiValue || "",
      bmiType: data.bmiType || "",
      referralNote: getReferStringFromType(data.referType) || "",
      refferedToHospital:
        data.referType === "immediateHospitalization" ||
        data.referType === "higherFacility"
          ? data.hospital?.hospitalName
          : "" || "",

      refferedToHospitalId:
        data.referType === "immediateHospitalization" ||
        data.referType === "higherFacility"
          ? data.hospital?.hospitalId
          : "" || "",

      refferedToDoctor:
        data.referType === "otherSpeciality" ? data.doctorName : "" || "",

      refferedToSpecialist:
        data.referType === "otherSpeciality"
          ? data?.referDoctorSpeciality?.specializationName
          : "" || "",

      refferedToSpecialistId:
        data.referType === "otherSpeciality"
          ? data?.referDoctorSpeciality?.specializationId
          : "" || "",

      prescriptionType: data?.prescriptionType || "",
      watchOutFor: data?.watchoutFor,
      doctorAudioNote: data?.audioData || "",
    };
    console.log("reqDto= ", reqDto);

    // return;
    DashboardServices.submitPrescription(reqDto).then((response) => {
      if (response.data.returnValue === "1") {
        props.closeMeeting(true, "Doctor");
        if (extraData?.isDoctor) {
          doctorSocket.emit("consultationDone", {
            pvId: extraData?.queueVisitId,
          });
        }
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    });
  };

  return (
    <>
      <div className="meetRightHeader">
        <HtmlTooltip
          title={
            <>
              <div className="centerDetail">
                <div className="centerName">
                  {t("consultation_with")}{" "}
                  {`${props?.extraData?.queueClinicName}`}
                </div>
                {/* <div className="centrDtlInd">
                  <span className="hedingCCD">{t("technician")} :</span>
                  <span className="ccInfoData">
                    {props.extraData && props.extraData.queuePatientName}
                  </span>
                </div> */}
                {props?.extraData?.queueClinicContactNo && (
                  <div className="centrDtlInd">
                    <span className="hedingCCD">{t("contact")} :</span>
                    <span className="ccInfoData">
                      {props?.extraData?.queueClinicContactNo}
                    </span>
                  </div>
                )}
                {/* <div className="centrDtlInd">
                  <span className="hedingCCD">{t("location")} :</span>
                  <span className="ccInfoData">
                    1104, The Galleria, Miniontech, New Town, Kolkata, 700156,
                    IND.
                  </span>
                </div> */}
              </div>
            </>
          }
        >
          <div className="metRtTitle">
            {/* {`Consultation With ${props?.extraData?.queueClinicName}`} */}
            {t("consultationWithClinic", {
              clinicName: props?.extraData?.queueClinicName,
            })}
          </div>
        </HtmlTooltip>

        <div className="metRtAction">
          {/* <IconButton aria-label="openInNewTab">
            <OpenInNew />
          </IconButton> */}
          <IconButton
            aria-label="Close"
            onClick={() => props.handleMenuCloseButton()}
          >
            <XLg />
          </IconButton>
        </div>
      </div>
      {/* {showPrescription ? (
        <PrescriptionEdit
          updateData={props.updatePrescriptionData}
          data={props.prescriptionData}
          extraData={props.extraData}
        />
      ) : (
        <PrescriptionView
          data={props.prescriptionData}
          extraData={props.extraData}
          closeMeeting={props.closeMeeting}
          updateData={props.updatePrescriptionData}
        />
      )} */}

      <div
        className={
          !showPrescription ? `dNone` : "MeetingTranscriptionContainer"
        }
      >
        <PrescriptionEdit
          updateData={props.updatePrescriptionData}
          data={props.prescriptionData}
          extraData={props.extraData}
        />
      </div>
      <div
        className={showPrescription ? `dNone` : "MeetingTranscriptionContainer"}
      >
        <PrescriptionView
          data={props.prescriptionData}
          extraData={props.extraData}
          closeMeeting={props.closeMeeting}
          updateData={props.updatePrescriptionData}
        />
      </div>
      {/* <PrescriptionEdit />
      <PrescriptionView /> */}

      {props.extraData?.isDoctor && (
        <div className="MeetRightFooter">
          <div className="meetRightFootBtnGrp">
            <div className="footBtnGroup">
              <Button
                className={
                  showPrescription ? "dfultPrimaryBtn" : "editPrescBtnDark"
                }
                startIcon={showPrescription ? <Eye /> : <Edit />}
                onClick={handleClickShowPrescription}
              >
                {t("prescription")}
              </Button>

              {!showPrescription && (
                <Button
                  startIcon={<TextSnippet />}
                  className="dfultPrimaryBtn"
                  onClick={submitPrescription}
                >
                  {t("submit")}
                </Button>
              )}
            </div>

            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => {
                setShowCancellationModule(true);
              }}
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      )}

      {showCancellationModule && (
        <CancelConsultation
          closeModal={() => {
            setShowCancellationModule(false);
          }}
          closeMeeting={props.closeMeeting}
          extraData={props.extraData}
        />
      )}
    </>
  );
}
