import {
  Add,
  ArrowBack,
  BiotechRounded,
  ChatRounded,
  Edit,
  HourglassBottomRounded,
  HourglassEmptyRounded,
  HourglassFullRounded,
  HourglassTopRounded,
  LoginRounded,
  LogoutRounded,
  QueryBuilderRounded,
  Search,
  SosRounded,
  StarsRounded,
  SwapHorizRounded,
  VideoCameraFrontRounded,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Download, Play } from "react-bootstrap-icons";
import DashboardServices from "../../../../../services/DashboardServices";
import { apiEndPoint } from "../../../../../constants/url_provider";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { PatientCancelConsultation } from "./PatientCancelConsultation";
import { SwitchDoctorModal } from "./SwitchDoctorModal";
import { ConfirmProvider } from "material-ui-confirm";
import MeetingsTabContainer from "../../../../scheduler/view/component/meetings/view/MeetingsTabContainer";
import { AddTabContext } from "../../../../dashboard/view/DashboardPage";
import PatientMeetingsTabContainer from "../../../../scheduler/view/component/meetings/view/PatientMeetingsTabContainer";
import { centerSocket } from "../../../../../socket";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import { useTranslation } from "react-i18next";

export default function ViewBookConsultation({
  handelCloseViewBookConsultation,
  handelOpenAddBookConsultation,
  closeAll,
  shouldUpdateQueueList,
  handleOpenAddTestResult,
  openConversation,
}) {
  const loginUserDetail = getLoginUserDetails();
  const { t } = useTranslation();
  const audioUrl = useRef();
  const [showAudioModal, setShowAudioModal] = useState(false);
  const patientThreshold = 2;
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { addTab, updateTab } = useContext(AddTabContext);
  const [patientQueueData, setPatientQueueData] = useState([]);
  const [filterPatientQueueData, setFilterPatientQueueData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [patientLogoutModal, setPatientLogoutModal] = useState(false);
  const [patientLogoutVisitId, setPatientLogoutVisitId] = useState(null);

  const [doctorReassignModal, setDoctorReassignModal] = useState(false);
  const [doctorReassignVisitdata, setDoctorReassignVisitdata] = useState(null);

  // const Hourglass = () => {
  //   const [hourglassState, setHourglassState] = useState(
  //     <HourglassEmptyRounded />
  //   );
  //   const [isRotated, setIsRotated] = useState(false);

  //   useEffect(() => {
  //     const hourglassFill = () => {
  //       setHourglassState(<HourglassTopRounded />);

  //       setTimeout(() => {
  //         setHourglassState(<HourglassBottomRounded />);
  //       }, 1000);

  //       setTimeout(() => {
  //         setHourglassState(<HourglassFullRounded />);
  //       }, 2000);

  //       setTimeout(() => {
  //         setIsRotated(true);
  //       }, 3000);

  //       setTimeout(() => {
  //         setIsRotated(false);
  //         setHourglassState(<HourglassEmptyRounded />);
  //       }, 3500);
  //     };

  //     hourglassFill();
  //     const intervalId = setInterval(hourglassFill, 4000);

  //     return () => clearInterval(intervalId);
  //   }, []);

  //   return (
  //     <div id="hourglass" className={isRotated ? "rotated" : ""}>
  //       {hourglassState}
  //     </div>
  //   );
  // };

  const Hourglass = ({ completed }) => {
    const [hourglassState, setHourglassState] = useState(
      <HourglassEmptyRounded />
    );
    const [isRotated, setIsRotated] = useState(false);

    useEffect(() => {
      if (!completed) {
        const hourglassFill = () => {
          setHourglassState(<HourglassTopRounded />);

          setTimeout(() => {
            setHourglassState(<HourglassBottomRounded />);
          }, 1000);

          setTimeout(() => {
            setHourglassState(<HourglassFullRounded />);
          }, 2000);

          setTimeout(() => {
            setIsRotated(true);
          }, 3000);

          setTimeout(() => {
            setIsRotated(false);
            setHourglassState(<HourglassEmptyRounded />);
          }, 3500);
        };

        hourglassFill();
        const intervalId = setInterval(hourglassFill, 4000);

        return () => clearInterval(intervalId);
      }
    }, [completed]);

    if (completed) return null;

    return (
      <div id="hourglass" className={isRotated ? "rotated" : ""}>
        {hourglassState}
      </div>
    );
  };

  useEffect(() => {
    handleFetchClinicQueueData();
  }, [patientLogoutModal, doctorReassignModal, shouldUpdateQueueList]);

  const handleFetchClinicQueueData = () => {
    DashboardServices.getClinicQueue(userDetails.signleUnitId).then(
      (response) => {
        console.log("Queue response", response.data);
        setPatientQueueData(response.data);
        setFilterPatientQueueData(response.data);
        setLoading(false);
      }
    );
  };

  const doctorCanceled = (data) => {
    toast.success(t("doctor_canceled_patient_updating_queue"));
    handleFetchClinicQueueData();
  };

  const doctorSwitched = (data) => {
    toast.success(t("doctor_switched_patient_updating_queue"));
    handleFetchClinicQueueData();
  };

  const refreshQueue = (data) => {
    toast.info(t("doctor_switched_patient"));
    handleFetchClinicQueueData();
  };
  const handleReceiveMessageFromDoctor = (data) => {
    const { type, message, visitId } = data;
    if (type === "message") {
      const temp = [...filterPatientQueueData];
      const tempIndex = temp.findIndex((item) => item.visitId === visitId);
      if (tempIndex !== -1) {
        temp[tempIndex].messaeCount = temp[tempIndex].messaeCount
          ? temp[tempIndex].messaeCount + 1
          : 1;
      }
      setFilterPatientQueueData(temp);
    }
  };

  useEffect(() => {
    if (centerSocket) {
      centerSocket.on("cancelPatientFromVideoByDr", doctorCanceled);
      centerSocket.on("refreshPatientQueue", refreshQueue);
      centerSocket.on("refreshCenterQueue", handleFetchClinicQueueData);
      centerSocket.on(
        "receiveMessageFromDoctor",
        handleReceiveMessageFromDoctor
      );
    }
    return () => {
      centerSocket.off("cancelPatientFromVideoByDr", doctorCanceled);
      centerSocket.off("refreshPatientQueue", refreshQueue);
      centerSocket.off("refreshCenterQueue", handleFetchClinicQueueData);
      centerSocket.off(
        "receiveMessageFromDoctor",
        handleReceiveMessageFromDoctor
      );
    };
  }, [centerSocket]);

  //   const calculateWaitTime = (queueNo, checkInTimeTimestamp, avgConsultTime) => {
  //     const currentDate = new Date(); // Get the current date
  //     const [hours, minutes, seconds] = checkInTimeTimestamp.split(':').map(Number);

  //     // Create a new Date object for check-in time using the current date but provided time
  //     const checkInTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), hours, minutes, seconds);

  //    console.log('currentDate' , currentDate);
  //    console.log('checkInTime' , checkInTime);

  //     const waitTimeMinutes = queueNo * avgConsultTime;
  //     const expectedFinishTime = new Date(checkInTime.getTime() + waitTimeMinutes * 60000);
  //     const currentTime = new Date();

  //     const waitTime = Math.max((expectedFinishTime - currentTime) / 60000, 0);

  //     if (waitTime <= 0) {
  //       return '';
  //     }

  //     if (waitTime > 60) {
  //       const hours = Math.floor(waitTime / 60);
  //       const minutes = Math.round(waitTime % 60);
  //       return `${hours}h ${minutes}m`;
  //     }

  //     return `${Math.round(waitTime)}m`;
  // };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPatientQueueData((prevData) => [...prevData]);
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const calculateWaitTime = (queueNo, checkInTimeTimestamp, avgConsultTime) => {
    // console.log('queueNo' , queueNo);
    // console.log('checkInTimeTimestamp' , checkInTimeTimestamp);
    // console.log('avgConsultTime' , avgConsultTime);

    const currentDate = new Date();
    // console.log('currentDate' , currentDate)
    const [hours, minutes, seconds] = checkInTimeTimestamp
      .split(":")
      .map(Number);

    const checkInTime = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      hours,
      minutes,
      seconds
    );

    // console.log('checkInTime' , checkInTime)

    const currentTime = new Date();

    // console.log('currentTime' , currentTime)

    // console.log('currentTime' , currentTime);
    // console.log('checkInTime' , checkInTime);

    // if (currentTime > checkInTime) {
    //   return "";
    // }

    const waitTimeMinutes = queueNo * (avgConsultTime / 60);
    // console.log('waitTimeMinutes' , waitTimeMinutes)
    const expectedFinishTime = new Date(
      checkInTime.getTime() + waitTimeMinutes * 60000
    );
    // console.log('expectedFinishTime' , expectedFinishTime)

    const waitTime = Math.max((expectedFinishTime - currentTime) / 60000, 0);

    // console.log('waitTime' , waitTime)

    if (waitTime <= 0) {
      return "0m";
    }

    if (waitTime > 60) {
      const hours = Math.floor(waitTime / 60);
      const minutes = Math.round(waitTime % 60);
      return `${hours}h ${minutes}m`;
    }

    return `${Math.round(waitTime)}m`;
  };

  const handleDownLoadFile = (patient) => {
    console.log("patient", patient);
    if (patient.prescriptionFileName !== null) {
      toast.info(t("please_wait_the_file_is_being_downloaded"), {
        position: toast.POSITION.TOP_RIGHT,
      });

      const newLink = patient.fileName.replace(/\//g, "~");
      console.log("newLink", newLink);

      let newFolderLink = patient.folderName
        .replace(/\//g, "~")
        .replace(/\\/g, "~");
      console.log("newLink", newFolderLink);

      window.open(
        apiEndPoint + `/appTest/downloadFileTest/${newLink}/${newFolderLink}`,
        "_blank",
        "noopener,noreferrer"
      );
    } else {
      toast.error(t("file_cannot_get_downloaded"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = patientQueueData.filter(
      (patientDocument) =>
        patientDocument.patientName &&
        patientDocument.patientName.toLowerCase().includes(query.toLowerCase())
    );

    setFilterPatientQueueData(filteredData);
  };

  const handleConnectDoctor = (patient) => {
    console.log("patient", patient);
    DashboardServices.connectDoctor(patient.visitId).then((response) => {
      console.log("doctor connect", response.data);
      if (response.data === 1) {
        toast.success(t("connecting_to_the_doctor"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        handlePatientVideoCall(patient);
        handleFetchClinicQueueData();
        centerSocket.emit("requestRefreshDoctorQueue", {
          doctorId: patient.doctorId,
        });
      } else {
        toast.error(t("error_in_connecting_to_the_doctor"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handlePatientVideoCall = async (data) => {
    const visitData = {
      visitId: data.visitId,
      patientId: data.patientId,
      orgId: loginUserDetail.orgId,
      doctorId: loginUserDetail.userId,
      unitId: 0,
    };

    const reqValue = await DashboardServices.getVisitInfoBeforeConsult(
      visitData
    );
    const itemData = {
      queuePatientName: data.patientName,
      queuePatientAge: data.patientAge,
      queuePatientGender: data.patientGender,
      queuePatientProfileUrl: data.patientProfileUrl,
      queueConsultationType: data.consultationType,
      queueVitals: data.vitals,
      // queueVitals: "",
      queueComplaints: data.complaints,
      queueReferredBy: data.referredBy,
      queueClinicName: data.clinicName,
      queuePatientType: data.patientType,
      queueVisitId: data.visitId,
      queuePatientId: data.patientId,
      queuePatientUnitId: data.unitId,
      queueRoomId: data.roomId,
      isDoctor: false,
      doctorId: data.doctorId,
      doctorName: data.doctorName,
      reqValue: reqValue.data,
      symptoms: data.symptoms,
      bloodGroup: data.bloodGroup,
    };

    const eventDto = {
      eventTitle: `${itemData.queueVisitId}) ${itemData.queuePatientName}`,
      eventDescription: `Consultation with ${itemData.queuePatientName}`,
      eventStartTime: new Date().toString(),
      eventEndTime: new Date(Date.now() + 20 * 60 * 1000).toString(),
      eventId: itemData.queueVisitId,
      eventType: "Consultation",
      eventLength: 20,
      eventLink: itemData.queueRoomId,
    };

    addTab({
      key: eventDto.eventId,
      title: eventDto.eventTitle,
      content: (
        <ConfirmProvider>
          <PatientMeetingsTabContainer
            eventData={eventDto}
            tabKey={eventDto.eventId}
            extraData={itemData}
          />
        </ConfirmProvider>
      ),
      isDraggable: true,
      type: "Meeting",
    });
  };

  const handlePatientLogout = (patient) => {
    let visitId = patient.visitId;
    console.log("visitId", visitId);
    setPatientLogoutModal(true);
    setPatientLogoutVisitId(visitId);
    centerSocket.emit("requestRefreshDoctorQueue", {
      doctorId: patient.visitId,
    });
  };

  const handlePatientLogin = (patient) => {
    let visitId = patient.visitId;
    console.log("visitId", visitId);
    DashboardServices.patientBack(visitId, userDetails.userId)
      .then((response) => {
        console.log("response", response.data);
        if (response.data === 1) {
          toast.success(t("successfully_logged_in_for_consultation"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          handleFetchClinicQueueData();
          centerSocket.emit("requestRefreshDoctorQueue", {
            doctorId: patient.visitId,
          });
        } else {
          toast.error(t("failed_to_log_in_for_consultation_please_try_again"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(t("an_unexpected_error_occurred_please_try_again_later"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleDoctorReassignModal = (visitdata) => {
    console.log("visitdata", visitdata);
    setDoctorReassignModal(true);
    setDoctorReassignVisitdata(visitdata);
  };

  return (
    <>
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader">
          <IconButton
            onClick={() => {
              handelCloseViewBookConsultation();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("patient_queue")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              {/* <div className="cuhederTxt">Departmment List</div> */}
              <div className="searchTasklist">
                <input
                  type="text"
                  className="serchTaskInput"
                  placeholder={t("search_patient")}
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div>

              <div className="addUnitsBtn">
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn"
                  onClick={() => handelOpenAddBookConsultation()}
                >
                  {t("book_consultation")}
                </Button>
              </div>
            </div>

            <div className="cuContainArea">
              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : patientQueueData.length === 0 ? (
                <div class="noDataCard ">
                  <span>{t("oops")}</span>
                  {t("no_patient_queue")}
                  <br />
                  <span className="sallMsg">
                    {t(
                      "click_add_book_consultation_button_to_create_new_patient_queue"
                    )}
                  </span>
                </div>
              ) : (
                <div className="TableContainer">
                  <table className="taskListTable">
                    <thead className="taskListtableHeader">
                      <tr>
                        <th>{t("sl_no")}</th>
                        <th>{t("patient")}</th>
                        <th>{t("check_in_time")}</th>
                        <th>{t("doctor")}</th>
                        <th className="textCenter">{t("doctor_queue")}</th>
                        <th>
                          <Tooltip arrow title={t("estimated_wait_time")}>
                            {t("ewt")}
                          </Tooltip>
                        </th>
                        <th>{t("status")}</th>
                        <th>{t("remark")}</th>
                        <th className="width100">{t("action")}</th>
                      </tr>
                    </thead>
                    <tbody className="scrolableTableBody">
                      {filterPatientQueueData?.map((patient, index) => {
                        const waitTime = calculateWaitTime(
                          patient.queueNo,
                          patient.checkInTimeTimestamp,
                          patient.avgConsultTime
                        );
                        return (
                          <tr key={patient.visitId}>
                            <td>
                              <div className="tskLstTaskNM">{index + 1}</div>
                            </td>
                            <td>
                              <div className="tskLstTaskNM tdFdC">
                                <span className="tdHtext">
                                  {patient.patientName}
                                </span>
                                <span className="tdStext">
                                  ({patient.patientGender}, {patient.patientAge}
                                  )
                                </span>
                                {patient.sos === "Y" && (
                                  <Tooltip arrow title={t("emergency_patient")}>
                                    <span className="sosP">
                                      <SosRounded />
                                    </span>
                                  </Tooltip>
                                )}
                                {patient.booked === "Y" && (
                                  <Tooltip
                                    arrow
                                    title={t("appointment_consultation")}
                                  >
                                    <span className="prebook">
                                      <StarsRounded />
                                    </span>
                                  </Tooltip>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="checkinTime WaitTime">
                                <QueryBuilderRounded />
                                {patient.checkInTime}
                              </div>
                            </td>
                            <td>
                              <div className="tskLstTaskNM tdFdC">
                                <span className="tdHtext">
                                  {patient.doctorName}
                                </span>
                                {patient.doctorSpecilization && (
                                  <span className="tdStext">
                                    ({patient.doctorSpecilization})
                                  </span>
                                )}
                              </div>
                            </td>
                            <td>
                              {["CD", "PS", "WV"].includes(patient.status) ? (
                                <div className="tskLstTaskNM jcc">
                                  {patient.queueNo}
                                </div>
                              ) : (
                                ""
                              )}
                            </td>

                            <td>
                              {/* {["CD", "PS", "WV"].includes(patient.status) && (
                                <div className="tskLstTaskPriority WaitTime">
                                  <Hourglass completed={waitTime === ""} />
                                  <span>{waitTime}</span>
                                </div>
                              )} */}
                              {patient.queueNo > 0 && (
                                <div className="tskLstTaskPriority WaitTime">
                                  <Hourglass completed={waitTime === ""} />
                                  {/* {console.log('waitTime' , waitTime)} */}
                                  <span>{waitTime}</span>
                                </div>
                              )}
                            </td>
                            <td>
                              {patient.status === "PG" && (
                                <div className="tskLstTaskNM">
                                  Prescription Generated
                                </div>
                              )}
                              {patient.status === "CD" &&
                                patient.queueNo <= patientThreshold && (
                                  <Button
                                    className="conctDoc"
                                    startIcon={<VideoCameraFrontRounded />}
                                    onClick={() => handleConnectDoctor(patient)}
                                  >
                                    {t("connect_to_doctor")}
                                  </Button>
                                )}
                              {patient.status === "CO" && (
                                <div className="tskLstTaskNM">
                                  {t("consultation_in_progress")}
                                </div>
                              )}
                              {patient.status === "LF" && (
                                <div className="tskLstTaskNM">
                                  {t("patient_left")}
                                </div>
                              )}
                              {patient.status === "WV" && (
                                <div className="tskLstTaskNM">
                                  {t("patient_waiting_in_video")}
                                </div>
                              )}
                              {patient.status === "WP" && (
                                <div className="tskLstTaskNM">
                                  {t("doctor_writing_prescription")}
                                </div>
                              )}
                              {patient.status === "DC" && (
                                <div className="tskLstTaskNM">
                                  {t("doctor_cancelled")}
                                </div>
                              )}
                              {patient.status === "PS" && (
                                <div className="tskLstTaskNM">
                                  {t("patient_switched")}
                                </div>
                              )}
                            </td>
                            <td>
                              {["DC", "LF", "PS"].includes(patient.status) && (
                                <div className="tskLstTaskNM">
                                  {/* {patient.rmks} */}
                                  {patient.rmks.replace(/^"|"$/g, "")}
                                </div>
                              )}
                            </td>
                            <td>
                              <div className="tblActionBtnGrp jcc">
                                {(patient.status !== "PP" ||
                                  patient.status !== "PG") && (
                                  <Tooltip
                                    arrow
                                    title={t("start_conversation")}
                                  >
                                    {patient.messaeCount ? (
                                      <>
                                        <Badge
                                          color="secondary"
                                          badgeContent={patient.messaeCount}
                                        >
                                          <IconButton
                                            className="queIbtn"
                                            onClick={() => {
                                              console.log("Open Conversation");
                                              const temp = [
                                                ...filterPatientQueueData,
                                              ];
                                              temp[index].messaeCount = 0;
                                              setFilterPatientQueueData(temp);
                                              openConversation(patient);
                                            }}
                                          >
                                            <ChatRounded />
                                          </IconButton>
                                        </Badge>
                                      </>
                                    ) : (
                                      <>
                                        <IconButton
                                          className="queIbtn"
                                          onClick={() => {
                                            console.log("Open Conversation");
                                            openConversation(patient);
                                          }}
                                        >
                                          <ChatRounded />
                                        </IconButton>
                                      </>
                                    )}
                                  </Tooltip>
                                )}
                                {/* {["CD", "PS", "DC", "WV"].includes(
                                  patient.status
                                ) && (
                                  <Tooltip title="Edit patient info" arrow>
                                    <IconButton className="queIbtn">
                                      <Edit />
                                    </IconButton>
                                  </Tooltip>
                                )} */}
                                {["CD", "PS", "DC", "WV"].includes(
                                  patient.status
                                ) && (
                                  <Tooltip
                                    title={t("patient_leave_center")}
                                    arrow
                                  >
                                    <IconButton
                                      className="queIbtn"
                                      onClick={() =>
                                        handlePatientLogout(patient)
                                      }
                                    >
                                      <LogoutRounded />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {patient.status === "LF" && (
                                  <Tooltip
                                    title={t("patient_back_to_center")}
                                    arrow
                                  >
                                    <IconButton
                                      className="queIbtn"
                                      onClick={() =>
                                        handlePatientLogin(patient)
                                      }
                                    >
                                      <LoginRounded />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {patient.status === "PG" && (
                                  <>
                                    <Tooltip
                                      title={t("download_prescription")}
                                      arrow
                                    >
                                      <IconButton
                                        className="queIbtn"
                                        onClick={() =>
                                          handleDownLoadFile(patient)
                                        }
                                      >
                                        <Download />
                                      </IconButton>
                                    </Tooltip>
                                    {patient.doctorAudioNoteFileUrl && (
                                      <Tooltip
                                        title={t("play_audio_message")}
                                        arrow
                                      >
                                        <IconButton
                                          variant="outlined"
                                          onClick={(e) => {
                                            audioUrl.current =
                                              patient.doctorAudioNoteFileUrl;
                                            setShowAudioModal(true);
                                          }}
                                        >
                                          <Play />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </>
                                )}
                                {patient.status === "DC" && (
                                  <Tooltip title={t("switch_doctor")} arrow>
                                    <IconButton
                                      className="queIbtn"
                                      onClick={() =>
                                        handleDoctorReassignModal(patient)
                                      }
                                    >
                                      <SwapHorizRounded />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {patient.status === "PP" && (
                                  <Tooltip title={t("add_test_result")} arrow>
                                    <IconButton
                                      className="queIbtn"
                                      onClick={() => {
                                        console.log("add test data");
                                        handleOpenAddTestResult(patient);
                                      }}
                                    >
                                      <BiotechRounded />
                                    </IconButton>
                                  </Tooltip>
                                )}

                                {(patient.status === "CO" ||
                                  patient.status === "WV") && (
                                  <Tooltip title={t("reconnect_doctor")} arrow>
                                    <IconButton
                                      className="queIbtn"
                                      onClick={() => {
                                        handlePatientVideoCall(patient);
                                      }}
                                    >
                                      <VideoCameraFrontRounded />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                      {(!filterPatientQueueData ||
                        filterPatientQueueData?.length === 0) && (
                        <tr>
                          <td style={{ columnSpan: "9" }}>
                            <div className="flex-wrap">
                              <div className="noDataCard ">
                                <span>{t("oops")}</span>
                                {t("no_patient_found")}
                                <br />
                                {/* <span className="sallMsg">
                              small msg
                            </span> */}
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}

              {showAudioModal && (
                <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
                  <div className="addMoreAgendaModel">
                    <div className="modelTitle">{t("audio_message")}</div>
                    <div className="modActionBtnGrp">
                      <audio controls>
                        <source src={audioUrl.current} />
                      </audio>
                    </div>

                    <div className="modActionBtnGrp">
                      <Button
                        className="dfultPrimaryBtn"
                        onClick={() => {
                          setShowAudioModal(false);
                        }}
                      >
                        {t("cancel")}
                      </Button>
                    </div>
                  </div>
                </Box>
              )}

              {patientLogoutModal && (
                <PatientCancelConsultation
                  visitId={patientLogoutVisitId}
                  closeModal={() => {
                    setPatientLogoutModal(false);
                  }}
                />
              )}

              {doctorReassignModal && (
                <SwitchDoctorModal
                  visitData={doctorReassignVisitdata}
                  closeModal={() => {
                    setDoctorReassignModal(false);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
