import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import { jwtDecode } from "jwt-decode";

const DiseaseTooltip = ({ data, x, y }) => {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const ref = useRef();
  // if (!data) return null;

  useEffect(() => {
    if (!data.units) return;

    const width = 450,
      height = 350;
    const svg = d3
      .select(ref.current)
      //.append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", "0 0  450 350")
      .attr("preserveAspectRatio", "xMinYMin");

    // const projection = d3
    //   .geoMercator()
    //   .scale(500)
    //   .center([-101, 24])
    //   .translate([width / 2.2, height / 2]);

    var projection;

    // const generator = d3.geoPath().projection(projection);

    const poly = svg.append("g");
    const line = svg.append("g");
    const bubble = svg.append("g");

    const dataURL =
      "https://raw.githubusercontent.com/GDS-ODSSS/unhcr-dataviz-platform/master/data/geospatial/bubble_map.csv";
    // const polygonsURL =
    //   "https://raw.githubusercontent.com/strotgen/mexico-leaflet/refs/heads/master/states.geojson";
    let polygonsURL = "https://r2.datahub.io/clvyjaryy0000la0cxieg4o8o/master/raw/data/countries.geojson";
    if(userDetails.orgCountryCode == 356){
      polygonsURL="https://raw.githubusercontent.com/Subhash9325/GeoJson-Data-of-Indian-States/refs/heads/master/Indian_States"
      projection=d3.geoMercator()
      .scale(350)
      // .center([-101, 24])
      .center([79.40 , 21.97])
      .translate([width / 2.2, height / 2]);
    } 
    if(userDetails.orgCountryCode == 484){
      polygonsURL = "https://raw.githubusercontent.com/strotgen/mexico-leaflet/refs/heads/master/states.geojson";
       projection = d3
      .geoMercator()
      .scale(500)
      .center([-101, 24])
      //.center([79.40 , 21.97])
      .translate([width / 2.2, height / 2]);
    } 

    if(!projection){
      projection=d3.geoMercator()
      .scale(350)
      // .center([-101, 24])
      .center([79.40 , 21.97])
      .translate([width / 2.2, height / 2]);
    }
    const polylinesURL =
      "https://raw.githubusercontent.com/GDS-ODSSS/unhcr-dataviz-platform/master/data/geospatial/world_lines_simplified.json";

    const generator = d3.geoPath().projection(projection);

    d3.csv(dataURL).then(function (population) {
      const datapoints = data.units.map((unit) => ({
        gis_name: unit.unitName,
        lat: unit.latitude,
        lon: unit.longitude,
        ref: unit.occurrence,
      }));

      // var obj ={
      //     gis_name: "Mexico",
      //     iso3: "MEX",
      //     lat: "24.225130568124673",
      //     lon: "-103.22757400703873",
      //     ref: "16403"
      // }

      // var datapoints =[];
      // datapoints.push(obj);
      //  obj ={
      //     gis_name: "UnitMX",
      //     iso3: "UnitMx",
      //     lat: "19.17538",
      //     lon: "-99.09072",
      //     ref: "5000"
      // }
      // datapoints.push(obj);

      const tooltip = d3.select("body").append("div").attr("class", "tooltip");

      // const mouseover = function(d) {
      // tooltip
      //   .style("opacity", 1)
      // d3.select(this)
      //   .style("fill", "#589BE5")
      //   .style("stroke", "#EF4A60")
      //   .style("opacity", 1)
      // };
      const mouseover = function (event, d) {
        console.log("d", d);
        //   setTooltipData({
        //     name: d.gis_name,
        //     ref: d.ref,
        //     consultationShare: d.consultationShare,
        //     rating: d.rating ,
        //     avgDailyConsultation: d.avgDailyConsultation,
        //     diseases: d.diseases ,
        //     dailyConsultation :d.dailyConsultation
        //   });
      };

      // const mousemove = function(event,d) {

      // tooltip
      // .html("<div style='color: #0072BC'><b>" + d.gis_name + "</b></div><div>Number of Refugee: " + d.ref+"</div>")
      // .style("top", event.pageY - 10 + "px")
      // .style("left", event.pageX + 10 + "px")
      // };
      const mousemove = function (event, d) {
        // Update tooltip position based on mouse movement
        //   setTooltipPosition({
        //     x: event.pageX + 20,
        //     y: event.pageY - 10,
        //   });
        console.log("");
      };

      // const mouseleave = function(d) {
      // tooltip
      // .style("opacity", 0)
      // d3.select(this)
      // .style("stroke", "#0072BC")
      // .style("opacity", 1)
      // };

      const mouseleave = function () {
        // Clear the tooltip data when leaving the element
        //   setTooltipData(null);
        console.log("");
      };

      const valueScale = d3.extent(datapoints, (d) => +d.ref);
      const size = d3.scaleSqrt().domain(valueScale).range([5, 25]);

      bubble
        .selectAll("circle")
        .style("position", "relative")
        .data(datapoints)
        .join("circle")
        .attr("cx", (d) => projection([+d.lon, +d.lat])[0])
        .attr("cy", (d) => projection([+d.lon, +d.lat])[1])
        .attr("r", (d) => size(+d.ref))
        .style("fill", "#589BE5")
        .attr("stroke", "#0072BC")
        .attr("stroke-width", 0.5)
        .attr("fill-opacity", 0.6)
        .on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseleave", mouseleave);
    });

    d3.json(polygonsURL).then(function (topology) {
      poly
        .selectAll("path")
        .data(topology.features)
        .join("path")
        .attr("fill", "#CCCCCC")
        .attr("d", generator);
    });

    svg
      .append("text")
      .attr("class", "note")
      .attr("x", width * 0.01)
      .attr("y", height * 0.96)
      .attr("text-anchor", "start")
      .style("font-size", 7)
      .style("fill", "#666666");
    // .text('Source: Consultation data of each unit');
  }, [data]);

  if (!data) return null;

  return (
    <div
      className="disease-tooltip"
      style={{
        position: "absolute",
        // top: `${y}px`,
        // left: `${x}px`,
        bottom: "0%",
        left: "50%",
        transform: "translate(-50% ,-10%)",
        backgroundColor: "white",
        border: "1px solid #ccc",
        padding: "10px",
        minWidth: "350px",
        borderRadius: "6px",
        pointerEvents: "none",
        boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
        zIndex: 10,
      }}
    >
      <div>
      <b>{data.diseaseName}</b>
      </div>
      <div className="centerHilights">
        {data.genderCount.Male && (
        <div className="cenCardInd half">
          <span className="smlTxt">Male</span>
          <span className="keyTxt">{data.genderCount.Male}</span>
        </div>
        )}
        {data.genderCount.Female && (
        <div className="cenCardInd half">
          <span className="smlTxt">Female</span>
          <span className="keyTxt">{data.genderCount.Female}</span>
        </div>
        )}
      </div>
      <div className="centerHilights">
        {data.last30DaysCount && (
        <div className="cenCardInd half">
          <span className="smlTxt">Last 30 Days Coun</span>
          <span className="keyTxt">{data.last30DaysCount}</span>
        </div>
        )}
        {data.lastOccurrence && (
        <div className="cenCardInd half">
          <span className="smlTxt">Last Occurrence</span>
          <span className="keyTxt">{data.lastOccurrence}</span>
        </div>
        )}
      </div>

      {/* <div>Male: {data.genderCount.Male}</div>
      <div>Female: {data.genderCount.Female}</div>
      <div>Last 30 Days Count: {data.last30DaysCount}</div>
      <div>Last Occurrence: {data.lastOccurrence}</div> */}
      <svg id="viz_container" ref={ref}></svg>
      {/* Add more fields from the `data` object if needed */}
    </div>
  );
};

DiseaseTooltip.propTypes = {
  data: PropTypes.object,
  position: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
};

export default DiseaseTooltip;
