import { Add } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { CompoundItem } from "./CompoundItem";
import AddCompound from "./AddCompound";
import { Trash } from "react-bootstrap-icons";

export const CompoundComponent = ({
  compound,
  parentId,
  updateCompound,
  compoundList,
}) => {
  return (
    <>
      <div className="CompoundDtlgrp">
        <ul className="compoundGrpUl">
          {compound?.compound?.map((citm) => {
            return (
              <li className="compoundGrpList">
                <CompoundItem
                  compoundList={compoundList}
                  compoundItem={citm}
                  parentId={compound.localId}
                  updateCompound={updateCompound}
                />

                {/* {citm?.compoundOrThen && (
                  <AddCompound
                    handleAddCompound={(data) => {
                      ;

                      if (citm?.compoundOrThen === "or") {
                        const dto = {
                          type: "addor",
                          payload: {
                            parentId,
                            data,
                          },
                        };
                        updateCompound(dto);
                      } else if (citm?.compoundOrThen === "then") {
                        const dto = {
                          type: "addThen",
                          payload: {
                            itemId: citm.localId,
                            data,
                          },
                        };
                        updateCompound(dto);
                      }
                    }}
                  />
                )} */}

                {/* <div className="compoundIndv compOr">
                  <div className="compChild">Or</div>
                  <div className="compfulDtl">
                    <div className="compHeader">
                      <div className="compName">Compound Four</div>
                      <div className="orThenCompGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <RadioGroup
                              className="formRadioGroup"
                              labelId="setTasktype"
                              name="regimeCondition"
                              // value={regimeCondition}
                              // onChange={handleChangeRegimeCondition}
                            >
                              <FormControlLabel
                                className="formRadioField"
                                value="or"
                                control={<Radio />}
                                label="Or"
                              />
                              <FormControlLabel
                                className="formRadioField"
                                value="them"
                                control={<Radio />}
                                label="Then"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        <IconButton className="rmvCompound">
                          <Trash />
                        </IconButton>
                      </div>
                    </div>
                    <div className="medRecStpGrp">
                      <div className="medRecStpInd">
                        <span>Form:</span>Tablet
                      </div>
                      <div className="medRecStpInd">
                        <span>Route:</span>Oral
                      </div>

                      <div className="medRecStpInd">
                        <span>Dose:</span>1
                      </div>
                      <div className="medRecStpInd">
                        <span>Dose unit:</span> mg/kg
                      </div>
                      <div className="medRecStpInd">
                        <span>Dose limit:</span> 2
                      </div>
                      <div className="medRecStpInd">
                        <span>Duration:</span>10 Days
                      </div>
                      <div className="medRecStpInd">
                        <span>Frequency:</span>BID
                      </div>
                    </div>
                  </div>
                </div> */}

                {}
                <div className="addAndCompound">
                  <Button
                    startIcon={<Add />}
                    className="addAndCompBtn"
                    onClick={() => {
                      const dto = {
                        type: "addNewCompound",
                        payload: {
                          parentId: parentId,
                          data: true,
                        },
                      };
                      updateCompound(dto);
                    }}
                  >
                    And
                  </Button>
                </div>
              </li>
            );
          })}
          {/* <li className="compoundGrpList">
                      <div className="compoundIndv">
                        <div className="compfulDtl">
                          <div className="compHeader">
                            <div className="compName">Compound Five</div>
                            <div className="orThenCompGrp">
                              <div className="formElement">
                                <FormControl className="formControl">
                                  <RadioGroup
                                    className="formRadioGroup"
                                    labelId="setTasktype"
                                    name="regimeCondition"
                                    value={regimeCondition}
                                    onChange={handleChangeRegimeCondition}
                                  >
                                    <FormControlLabel
                                      className="formRadioField"
                                      value="or"
                                      control={<Radio />}
                                      label="Or"
                                    />
                                    <FormControlLabel
                                      className="formRadioField"
                                      value="them"
                                      control={<Radio />}
                                      label="Then"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </div>
                              <IconButton className="rmvCompound">
                                <Trash />
                              </IconButton>
                            </div>
                          </div>
                          <div className="medRecStpGrp">
                            <div className="medRecStpInd">
                              <span>Form:</span>Tablet
                            </div>
                            <div className="medRecStpInd">
                              <span>Route:</span>Oral
                            </div>

                            <div className="medRecStpInd">
                              <span>Dose:</span>1
                            </div>
                            <div className="medRecStpInd">
                              <span>Dose unit:</span> mg/kg
                            </div>
                            <div className="medRecStpInd">
                              <span>Dose limit:</span> 2
                            </div>
                            <div className="medRecStpInd">
                              <span>Duration:</span>10 Days
                            </div>
                            <div className="medRecStpInd">
                              <span>Frequency:</span>BID
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="addAndCompound">
                        <Button startIcon={<Add />} className="addAndCompBtn">
                          And
                        </Button>
                      </div>
                    </li> */}

          {compound?.addNewCompound && (
            <li className="compoundGrpList">
              <AddCompound
                compoundList={compoundList}
                handleAddCompound={(data) => {
                  const dto = {
                    type: "addAnd",
                    payload: {
                      parentId,
                      data,
                    },
                  };
                  updateCompound(dto);
                }}
              />
            </li>
          )}
        </ul>
      </div>
    </>
  );
};
