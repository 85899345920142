import { Verified } from "@mui/icons-material";
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { Trash } from "iconoir-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { getLoginUserDetails } from "../../../utils/Utils";
import { getChatAllContacts } from "../../../services/ChatService";
import {
  getUsersWithFileAccess,
  updateFileAccess,
  updateUserFilePermission,
} from "../../../services/DriveService";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import { XLg } from "react-bootstrap-icons";
import { driveSocket } from "../../../socket";
import { useTranslation } from "react-i18next";

export const ShareFileDrive = ({ selectedFile, onClose }) => {
  ///////////////////////////////////////
  const { t } = useTranslation();
  const loginUser = getLoginUserDetails();
  const [anchorElRP, setAnchorElRP] = useState(null);
  const [allUsersList, setAllUsersList] = useState([]);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [selectedGuestInput, setSelectedGuestInput] = useState("");
  const openRP = Boolean(anchorElRP);
  const idRP = openRP ? "remove-participant-popover" : undefined;
  const buttonRPRef = useRef(null);
  const buttonRPCancelRef = useRef(null);
  const buttonApplyForAllRec = useRef(null);
  const buttonApplyForAllRecReschedule = useRef(null);
  const buttonApplyForAllRecAddParticipant = useRef(null);
  const buttonApplyForAllRecRemoveParticipant = useRef(null);

  const handleClickRP = (event) => {
    setAnchorElRP(event.currentTarget);
  };

  const handleCloseRP = () => {
    setAnchorElRP(null);
  };
  const [selectedUsersList, setSelectedUsersList] = useState([]);
  const [selectedUsersListOrg, setSelectedUsersListOrg] = useState([]);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  ////////////////////////

  const [usersWithAccess, setUsersWithAccess] = useState([]);

  const [changesMade, setChangesMade] = useState(false);
  useEffect(() => {
    console.log("selectedFile", selectedFile);

    getChatAllContacts(loginUser.userId, loginUser.orgId).then((response) => {
      console.log("getChatAllContacts", response.data);
      setAllUsersList(response.data);
      getUsersWithFileAccess(selectedFile.fileId).then((response2) => {
        console.log("getUsersWithFileAccess", response2.data);
        for (let index = 0; index < response2.data.length; index++) {
          const userDto = response2.data[index];
          const userDetails = response.data.find(
            (user) => user.userId === userDto.userId
          );
          setSelectedUsersList((prevList) => [
            ...prevList,
            {
              id: v4(),
              userId: userDto.userId,
              userName: userDto.personName,
              profileImage: userDetails.profileUrl,
              convener: "",
              chairperson: "",
              outSide: userDetails.userType === "I" ? "N" : "Y",
              calledBy: "N",
              orgId: loginUser.orgId,
              permission: userDto.permission,
            },
          ]);
          setSelectedUsersListOrg((prevList) => [
            ...prevList,
            {
              id: v4(),
              userId: userDto.userId,
              userName: userDto.personName,
              profileImage: userDetails.profileUrl,
              convener: "",
              chairperson: "",
              outSide: userDetails.userType === "I" ? "N" : "Y",
              calledBy: "N",
              orgId: loginUser.orgId,
              permission: userDto.permission,
            },
          ]);
        }
      });
    });
  }, [selectedFile]);

  const searchUserFilteredList = useMemo(() => {
    let filtered = [];
    if (selectedGuestInput.length > 0 && selectedGuestInput.trim() !== "") {
      filtered = allUsersList.filter((option) =>
        option.userName.toLowerCase().includes(selectedGuestInput.toLowerCase())
      );
    }

    return filtered;
  }, [selectedGuestInput, allUsersList]);

  useEffect(() => {
    let changesMade = false;
    selectedUsersListOrg.forEach((item) => {
      const userDetails = selectedUsersList.find(
        (user) => user.userId === item.userId
      );
      if (userDetails.permission !== item.permission) {
        changesMade = true;
      }
    });

    if (selectedUsersListOrg.length !== selectedUsersList?.length) {
      changesMade = true;
    }

    if (changesMade) {
      setChangesMade(true);
    } else {
      setChangesMade(false);
    }
  }, [selectedUsersList]);

  const handleChangePermission = (event, selUserDto) => {
    console.log("handleChangePermission", event.target.value, selUserDto);
    if (selUserDto.id == null && event.target.value === "R") {
      setSelectedUsersList((prevItems) =>
        prevItems.filter((item) => item.userId !== selUserDto.userId)
      );
    } else {
      setSelectedUsersList((prevItems) =>
        prevItems.map((item) =>
          item.userId === selUserDto.userId
            ? { ...item, permission: event.target.value }
            : item
        )
      );
    }
  };

  const [loading, setLoading] = useState(false);

  const shareFile = () => {
    // if(!changesMade){
    //   toast.info("Saving changes, please wait...");
    // }
    toast.info("Saving changes, please wait...");
    const revokeAccessList = selectedUsersList.filter(
      (item) => item.id != null && item.permission === "R"
    );

    const revokeAccessListFinal = revokeAccessList.map(
      (item) => `${item.userId}`
    );

    ////
    const newlyAddedUsers = selectedUsersList.filter((item) => item.id == null);
    const grantAccessListFinal = newlyAddedUsers.map(
      (item) => `${item.userId}~${item.permission}`
    );

    const reqDto = {
      fileId: selectedFile.fileId,
      orgId: loginUser.orgId,
      grantAccessToUserIds: grantAccessListFinal,
      revokeAccessFromUserIds: revokeAccessListFinal,
    };

    console.log(reqDto);
    // return;

    selectedUsersListOrg.forEach(async (userDto) => {
      const userDetails = selectedUsersList.find(
        (user) => user.userId === userDto.userId
      );

      if (
        userDetails.permission !== userDto.permission &&
        userDetails.permission !== "R"
      ) {
        setLoading(true);
        await updateUserFilePermission(
          userDetails.permission,
          selectedFile.fileId,
          userDetails.userId
        ).then((response) => {
          console.log("updateFileAccessPermission", userDto, response.data);
          if (response.data.returnValue === "1") {
            driveSocket.emit("updateFileAccessPermission", {
              userIdToUpdate: userDetails.userId,
              userName: loginUser.userName,
              fileName: selectedFile.name,
              fileId: selectedFile.fileId,
              permission: userDetails.permission,
            });
          }

          if (
            response.data.returnValue === "1" &&
            revokeAccessListFinal.length === 0 &&
            grantAccessListFinal.length === 0
          ) {
            toast.success(
              `Access updated for ${selectedFile.name} successfully`,
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
            setLoading(false);
            setChangesMade(false);
            onClose();
          }
          if (response.data.returnValue !== "1") {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
      }
    });

    if (revokeAccessListFinal.length > 0 || grantAccessListFinal.length > 0) {
      setLoading(true);
      updateFileAccess(reqDto, loginUser.userId).then(async (response) => {
        console.log("updateFileAccess-->>>>", response);
        if (response && response.data && response.data.returnValue === "1") {
          toast.success(
            `Access updated for ${selectedFile.name} successfully`,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          revokeAccessListFinal.forEach((userId) => {
            setSelectedUsersList((prevItems) =>
              prevItems.map((item) =>
                item.userId === userId ? { ...item, deleted: true } : item
              )
            );
            setSelectedUsersListOrg((prevItems) =>
              prevItems.map((item) =>
                item.userId === userId ? { ...item, deleted: true } : item
              )
            );
            driveSocket.emit("updateFileAccessPermission", {
              userIdToUpdate: userId,
              userName: loginUser.userName,
              fileName: selectedFile.name,
              fileId: selectedFile.fileId,
              permission: "R",
            });
          });
          grantAccessListFinal.forEach((userId) => {
            setSelectedUsersList((prevItems) =>
              prevItems.map((item) =>
                item.userId === userId.split("~")[0]
                  ? { ...item, id: v4() }
                  : item
              )
            );
            setSelectedUsersListOrg((prevItems) =>
              prevItems.map((item) =>
                item.userId === userId.split("~")[0]
                  ? { ...item, id: v4() }
                  : item
              )
            );
            driveSocket.emit("updateFileAccessPermission", {
              userIdToUpdate: userId.split("~")[0],
              userName: loginUser.userName,
              fileName: selectedFile.name,
              fileId: selectedFile.fileId,
              permission: userId.split("~")[1],
            });
          });

          // driveSocket.emit("refreshOnShare", {
          //   revokeAccessListFinal: revokeAccessListFinal,
          //   grantAccessListFinal: grantAccessListFinal,
          //   userName: loginUser.userName,
          //   fileName: selectedFile.name,
          // });

          setLoading(false);
          setChangesMade(false);
          onClose();
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  return (
    <>
      {" "}
      <IconButton
        disabled={loading}
        className="closeContactModelBtn"
        onClick={onClose}
      >
        <XLg />
      </IconButton>
      <div className="">
        <div className="">
          <span>{t("select_users")}</span>
        </div>
        <div style={{ paddingTop: "10px" }}>
          <div>
            <Autocomplete
              disabled={loading}
              noOptionsText={t("please_type_to_search")}
              fullWidth
              size="small"
              id="sharedUserSelect"
              sx={{
                width: 300,
                // backgroundColor: "#333",
                borderRadius: "10px",
                // color: "white",
                border: "1px solid black",
              }}
              value={selectedGuest}
              onChange={(event, selectedValue) => {
                console.log("newValue", selectedValue);
                setSelectedUsersList((prevList) => [
                  ...prevList,
                  {
                    id: null,
                    userId: selectedValue.userId,
                    userName: selectedValue.userName,
                    profileImage: selectedValue.profileUrl,
                    convener: "",
                    chairperson: "",
                    outSide: selectedValue.userType === "I" ? "N" : "Y",
                    calledBy: "N",
                    orgId: loginUser.orgId,
                    permission: "V",
                  },
                ]);
                setSelectedGuest(null);
                setSelectedGuestInput("");
              }}
              inputValue={selectedGuestInput}
              onInputChange={(event, newInputValue) => {
                setSelectedGuestInput(newInputValue);
              }}
              options={searchUserFilteredList}
              autoHighlight
              getOptionLabel={(option) => option.userName}
              getOptionDisabled={(option) =>
                selectedUsersList.some(
                  (userDto) => userDto.userId === option.userId
                )
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    srcSet={`${option.profileUrl}`}
                    src={`${option.profileUrl}`}
                    alt=""
                  />
                  {option.userName}
                  {option.userType === "I" ? (
                    <span style={{ padding: "5px" }}>
                      <Tooltip title={t("belongs_to_your_organization")}>
                        <Verified />
                      </Tooltip>
                    </span>
                  ) : (
                    <></>
                  )}
                </Box>
              )}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        </div>
        <div className="sharedToUsersGroup custom-scrollbar">
          {selectedUsersList.map((participantDto) => {
            if (participantDto.deleted === true) {
              return <></>;
            }
            return (
              <div className="shareToGuestListMainDiv">
                <div
                  className={`shareToGuestListDtlsDiv`}
                  style={{
                    cursor: "unset",
                    opacity: participantDto.permission === "R" ? 0.3 : 1,
                  }}
                >
                  <div className="shareToGuestImage">
                    <Badge
                      variant="dot"
                      color="primary"
                      invisible={participantDto.id != null}
                    >
                      <img src={participantDto.profileImage} alt="" />
                    </Badge>
                  </div>

                  <div className="shareToGuestName">
                    {participantDto.userName}
                    {participantDto.outSide === "N" ? (
                      <span style={{ padding: "5px" }}>
                        <Tooltip title={t("belongs_to_your_organization")}>
                          <Verified />
                        </Tooltip>
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="shareToGuestListActionDiv">
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      disabled={loading}
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={participantDto.permission}
                      onChange={(event) =>
                        handleChangePermission(event, participantDto)
                      }
                    >
                      <MenuItem value={"E"}>{t("editor")}</MenuItem>
                      <MenuItem value={"V"}> {t("viewer")}</MenuItem>
                      <MenuItem value={"R"}>{t("remove_access")}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="modelFooter">
        <div
          className="modActionBtnGrp"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            disabled={loading}
            className="dfultDarkSecondaryBtn"
            onClick={onClose}
          >
            {t("cancel")}
          </Button>
          {!loading && (
            <>
              {changesMade ? t("changes_made") : t("no_changes_made")}
              {""}
              <Button
                className="dfultPrimaryBtn"
                onClick={() => {
                  shareFile();
                }}
                disabled={!changesMade}
              >
                {t("submit")}
              </Button>
            </>
          )}
          {loading && (
            <Button className="dfultPrimaryBtn shareDriveLoader">
              <CircularProgress />
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
