import {
  AccessibleRounded,
  MonetizationOnOutlined,
  Search,
  TranslateRounded,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { XLg } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateUtils from "../../../../../utils/DateUtils";
import EXP from "../../../../dashboard/image/EXP";
import dayjs from "dayjs";
import zhPatientService from "../../../../../services/ZhPatientService";
import { getLoginUserDetails, validateEmail } from "../../../../../utils/Utils";
import DashboardServices from "../../../../../services/DashboardServices";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  getCallingCodeList,
  getCountryDtlByCode,
} from "../../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";

export default function AddNewAppointBooking({
  handelCloseAppointmentBooking,
  appoinmentData,
  refreshBookAppointmentChange,
  handelCloseAllViewBookAppointment,
}) {
  // console.log("appoinmentData", appoinmentData);
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();
  const [baPatEmailId, setBaPatEmailId] = useState("");
  const [baPatTitle, setBaPatTitle] = useState("");
  const [patientGender, setPatientGender] = useState("");
  const [patientMaritalStatus, setPatientMaritalStatus] = useState("");
  const [selectedDob, setSelectedDob] = useState(null);
  const [age, setAge] = useState({ years: null, months: null, days: null });
  const loginUserDetail = getLoginUserDetails();
  const [patientList, setPatientList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patientInfo, setPatientInfo] = useState(null);
  const [previousMedicalRecords, setPreviousMedicalRecords] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [patientIsdCode, setPatyientIsdCode] = useState("");
  const [phoneRegexPattern, setPhoneRegexPattern] = useState(/^[0-9]{10,}$/);
  const [contactNumber, setContactNumber] = useState("");
  const [patientEmailId, setPatientEmailId] = useState("");
  const [isNewPatient, setIsNewPatient] = useState(false);
  const [cdCodeList, setCdCodeList] = useState([]);
  const [showParagraph, setShowParagraph] = useState(false);
  const [guardianName, setGuardianName] = useState("");
  const [emergencyCallingCode, setEmergencyCallingCode] = useState("");
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState("");
  const [insuranceProvider, setInsuranceProvider] = useState("");
  const [insuranceNumber, setInsuranceNumber] = useState("");

  const handleKeyDown = () => {
    if (firstName.trim()) {
      setShowParagraph(true);
    } else {
      setShowParagraph(false);
    }
  };

  const handelChangeBaPatEmailId = (event) => {
    if (!event.target.value) {
      setFilteredList(patientList);
    }
    setBaPatEmailId(event.target.value);
    setPatientEmailId(event.target.value);
  };
  const handleChangebaPatTitle = (event) => {
    setBaPatTitle(event.target.value);
  };

  const handleChangeMaritalStatus = (event) => {
    setPatientMaritalStatus(event.target.value);
  };

  const handleChangepatientGender = (event) => {
    setPatientGender(event.target.value);
  };

  const handleAgeChange = (e) => {
    const { name, value } = e.target;
    let newAge = {
      ...age,
      [name]: value ? Number(value) : null,
    };
    setAge(newAge);
    calculateDOB(newAge);
    // setRefreshDOB(refreshDOB + 1);
  };

  const calculateDOB = (age) => {
    // console.log("calculateDOB", age);
    const today = new Date();
    const years = age.years || 0;
    const months = age.months || 0;
    const days = age.days || 0;
    // console.log("calculateDOB", years, months, days);
    // console.log(years, today.getFullYear());
    const birthDate = new Date(
      today.getFullYear() - years,
      today.getMonth() - months,
      today.getDate() - days
    );
    // console.log(birthDate);
    setSelectedDob(birthDate);
    const formattedDate = format(birthDate, "dd-MM-yyyy");
  };

  const handleChangeemplyPhnCC = (event) => {
    setPatyientIsdCode(event.target.value);
    switch (event.target.value) {
      case "+91": // India
        setPhoneRegexPattern(/^[6-9]\d{9}$/);
        break;
      case "+52": // Mexico
        setPhoneRegexPattern(/^[1-9]\d{9}$/);
        break;
      case "+971": // UAE
        setPhoneRegexPattern(/^(?:\50|51|52|55|56|2|3|4|6|7|9)\d{7}$/);
        break;
      default:
        setPhoneRegexPattern(/^[0-9]{10,}$/);
        break;
    }
  };

  const filterData = () => {
    if (!baPatEmailId) {
      return patientList;
    }
    const filteredData = patientList?.filter((patient) => {
      return patient?.patientEmailId === baPatEmailId;
    });
    if (filteredData?.length === 0) {
      setIsNewPatient(true);
    }
    return filteredData || [];
  };

  const getTodaysPatientList = async () => {
    let date = DateUtils.getDateInYYYYMMDD(new Date());
    // const resGetTodayPatList = await zhPatientService.getPatListInUnitByDate(
    //   date,
    //   date,
    //   loginUserDetail.signleUnitId,
    //   loginUserDetail.orgId
    // );
    // getPatientListByEmailId
    if (baPatEmailId.trim() === "") {
      toast.error(t("please_enter_email_id"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (baPatEmailId && !validateEmail(baPatEmailId)) {
      toast.error(t("please_enter_valid_email"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const resGetTodayPatList = await DashboardServices.getPatientListByEmailId(
      loginUserDetail.orgId,
      baPatEmailId
    );

    // console.log("getTodaysPatientList", resGetTodayPatList.data);
    setPatientList(resGetTodayPatList.data);

    if (resGetTodayPatList.data.length > 0) {
      setFilteredList(resGetTodayPatList.data);
      // setSelectedPatient(resGetTodayPatList.data);
      setIsNewPatient(false);
      setSelectedPatient(null);
      setShowParagraph(false);
    } else {
      setFilteredList([]);
      setIsNewPatient(true);
      setSelectedPatient(null);
    }

    // if (appoinmentData?.slot?.patientId) {
    //   const tempPatient = resGetTodayPatList.data?.find(
    //     (item) => item.rowPatientId === appoinmentData?.slot?.patientId
    //   );
    //   if (tempPatient) {
    //     setSelectedPatient(tempPatient);
    //     setFilteredList([tempPatient])
    //   }
    // }
    // setFilteredList(filterData())
  };
  // const [filteredList, setFilteredList] = useState([]);
  // const [isNewPatient, setIsNewPatient] = useState(false);
  const handlePatientData = async () => {
    if (appoinmentData?.patientData) {
      const resGetTodayPatList =
        await DashboardServices.getPatientListByEmailId(
          loginUserDetail.orgId,
          appoinmentData?.patientData?.patientEmailId
        );

      const tempPatList = resGetTodayPatList.data?.find(
        (item) => item.patientId === appoinmentData?.patientData?.rowPatientId
      );
      if (tempPatList) {
        setFilteredList([tempPatList]);
        setSelectedPatient(tempPatList);
      }
    }
  };

  useEffect(() => {
    // console.log(("appoinmentData =", appoinmentData));
    handlePatientData();
  }, []);

  useEffect(() => {
    getCallingCodeList().then((response) => {
      // console.log("getCallingCodeList", response.data);
      setCdCodeList(response.data);
    });
  }, []);

  useEffect(() => {
    if (userDetails.orgCountryCode) {
      getCountryDtlByCode(userDetails.orgCountryCode).then((response) => {
        // console.log('response' , response.data);
        setPatyientIsdCode(response.data.callingCd);
        setEmergencyCallingCode(response.data.callingCd);
        switch (response.data.callingCd) {
          case "+91": // India
            setPhoneRegexPattern(/^[6-9]\d{9}$/);
            break;
          case "+52": // Mexico
            setPhoneRegexPattern(/^[1-9]\d{9}$/);
            break;
          case "+971": // UAE
            setPhoneRegexPattern(/^(?:\50|51|52|55|56|2|3|4|6|7|9)\d{7}$/);
            break;
          default:
            setPhoneRegexPattern(/^[0-9]{10,}$/);
            break;
        }
      });
    }
  }, [userDetails.orgCountryCode]);

  const checkDate = (testDate) => {
    // Get today's date without time
    const today = new Date();
    const todayDateOnly = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );

    // Get testDate without time
    const testDateOnly = new Date(
      testDate.getFullYear(),
      testDate.getMonth(),
      testDate.getDate()
    );

    // Compare dates and return the appropriate value
    return testDateOnly.getTime() === todayDateOnly.getTime()
      ? testDate
      : testDate;
  };

  return (
    <>
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">{t("appointment_booking")}</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseAppointmentBooking()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            <div className="rfContContainDetails">
              <div className="doctorDetails bookedSlot">
                <div className="doctorInfoWthImg">
                  {/* <div className="docImage">
                      <img src={appoinmentData?.imageLink} alt="zoyel_doctor" />
                    </div> */}
                  <div className="docImage">
                    <img
                      src={
                        appoinmentData?.imageLink ??
                        "https://ahlan-s3.s3.me-south-1.amazonaws.com/images/no-profile.png"
                      }
                      alt={t("doctor_image")}
                    />
                  </div>

                  <div className="doctorFullInfo">
                    <div className="doctorNmSpc">
                      <div className="docName">{appoinmentData.doctorName}</div>
                      {/* <div className="docSpclty">
                         {appoinmentData.specialization}
                      </div> */}
                      <div className="docSpclty">
                        {appoinmentData.specialization
                          ? `(${appoinmentData.specialization})`
                          : ""}
                      </div>
                    </div>
                    <div className="docExpFeeLan">
                      <div className="docEFL">
                        <div className="eflicon fillnone">
                          <EXP />
                        </div>
                        <div className="eflText">
                          {appoinmentData.experience}
                        </div>
                      </div>
                      {/* <div className="docEFL">
                        <div className="eflicon">
                          <TranslateRounded />
                        </div>
                        <div className="eflText">{appoinmentData.language}</div>
                      </div> */}
                      <div className="docEFL">
                        <div className="eflicon">
                          <MonetizationOnOutlined />
                        </div>
                        <div className="eflText">
                          {appoinmentData?.fees}
                          <span>{appoinmentData?.feesCurrency}</span>
                        </div>
                      </div>
                      <div className="docEFL">
                        <div className="eflicon">
                          <TranslateRounded />
                        </div>
                        <div className="eflText">
                          {appoinmentData?.language
                            .split(",")
                            .sort((a, b) => a.trim().localeCompare(b.trim()))
                            .join(", ")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bdtSlotInfo">
                  <div className="bookingDateTime">
                    {t("booking_date_time")}
                  </div>
                  <div className="slotInfo">
                    <div className="icon">
                      {/* <StarsRounded /> */}
                      <AccessibleRounded />
                    </div>
                    <div className="slotTime">
                      <span className="date">
                        {dayjs(appoinmentData?.date).format("DD-MMM-YYYY")}
                      </span>
                      <span className="time">{`${appoinmentData?.slot?.fromTime} - ${appoinmentData?.slot?.toTime}`}</span>
                    </div>
                  </div>
                </div>
              </div>

              {!isNewPatient &&
              // appoinmentData?.slot?.patientName &&
              // appoinmentData.slot.patientName.trim() !== "" &&
              // appoinmentData?.slot?.patientGender &&
              // appoinmentData.slot.patientGender?.trim() !== ""
              appoinmentData?.slot?.patientId &&
              appoinmentData.slot.patientId !== 0 ? (
                <div className="elementFormContainer">
                  <div style={{ color: "#fff" }}>
                    {/* <p>
                      {`${
                        appoinmentData?.slot?.patientName
                      } has scheduled an appointment with ${
                        appoinmentData?.doctorName
                      } 
                      on ${dayjs(appoinmentData?.date).format("DD-MMM-YYYY")} from ${
                        appoinmentData?.slot?.fromTime
                      } to ${appoinmentData?.slot?.toTime}.`}
                    </p> */}
                    <p>
                      <b>{appoinmentData?.slot?.patientName}</b> has scheduled a
                      consultation with <b>{appoinmentData?.doctorName}</b> on{" "}
                      <b>{dayjs(appoinmentData?.date).format("DD-MMM-YYYY")}</b>{" "}
                      at <b>{appoinmentData?.slot?.fromTime}</b> -{" "}
                      <b>{appoinmentData?.slot?.toTime}</b>. Please ensure you
                      reach the center at least 15 minutes before your
                      appointment time to avoid any delays and to allow for a
                      smooth consultation process.
                    </p>
                  </div>
                </div>
              ) : (
                <>
                  <div className="elementFormContainer">
                    <div className="tskElemHeding mt10">
                      {t("patient_info")}
                    </div>
                    <div className="elementWithAddMrElm">
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            // hiddenLabel
                            label={t("email_id")}
                            autoComplete="off"
                            required
                            variant="outlined"
                            className="formTextFieldArea"
                            value={baPatEmailId}
                            onChange={handelChangeBaPatEmailId}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                getTodaysPatientList();
                              }
                            }}
                          />
                        </FormControl>
                      </div>

                      <Tooltip arrow title={t("add_or_search_by_mail_id")}>
                        <IconButton
                          className="addMrElemBtn"
                          // onClick={() => {
                          //   setFilteredList(filterData());
                          // }}

                          onClick={() => getTodaysPatientList()}
                        >
                          <Search />
                        </IconButton>
                      </Tooltip>
                    </div>

                    <div className="filterPatlist appoinBookPatList">
                      {/* {console.log("filteredList", filteredList)} */}
                      {filteredList?.map((patient) => {
                        const isSelected =
                          selectedPatient?.patientId === patient.patientId;

                        return (
                          <div
                            key={patient.patientId}
                            className={`filterPatlistInd ${
                              isSelected ? "active" : ""
                            }`}
                            onClick={() => {
                              setSelectedPatient(isSelected ? null : patient);
                            }}
                          >
                            <div className="filPatFullinfo">
                              <div className="filPatImg">
                                <img
                                  src={
                                    patient.patientImage ||
                                    "https://ahlan-s3.s3.me-south-1.amazonaws.com/images/no-profile.png"
                                  }
                                  alt={t("patient_image")}
                                />
                              </div>
                              <div className="filtPatDtl">
                                <div className="filtPatNm">
                                  {patient?.patientName}{" "}
                                  <span>{`( ${patient?.gender}, ${patient?.age} )`}</span>
                                </div>
                                <div className="filtPatOthDtl">
                                  <span>{`${patient?.callingCountryCode} ${patient?.contactNo}`}</span>
                                  {/* <span>{patient?.patientEmailId}</span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="bb"></div>
                    {isNewPatient && (
                      <>
                        <div className="formElementGrp ">
                          <div className="formElement mxW-100">
                            <FormControl className="formControl">
                              <InputLabel id="patient-select-label">
                                {t("title")}
                              </InputLabel>
                              <Select
                                labelId="patient-select-label"
                                id="patient-select-title"
                                label={t("title")}
                                className="formInputField"
                                variant="outlined"
                                size="small"
                                value={baPatTitle}
                                onChange={handleChangebaPatTitle}
                              >
                                <MenuItem value="Mr">{t("mr")}</MenuItem>
                                <MenuItem value="Ms">{t("ms")}</MenuItem>
                                <MenuItem value="Mrs">{t("mrs")}</MenuItem>
                                <MenuItem value="Miss">{t("miss")}</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label={t("first_name")}
                                required
                                autoComplete="off"
                                placeholder={t("first_name")}
                                variant="outlined"
                                className="formTextFieldArea"
                                value={firstName}
                                onChange={(e) => {
                                  setFirstName(e.target.value);
                                }}
                                onKeyDown={handleKeyDown}
                              />
                            </FormControl>
                          </div>

                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                // hiddenLabel
                                label={t("middle_name")}
                                autoComplete="off"
                                placeholder={t("middle_name")}
                                variant="outlined"
                                className="formTextFieldArea"
                                value={middleName}
                                onChange={(e) => {
                                  setMiddleName(e.target.value);
                                }}
                              />
                            </FormControl>
                          </div>

                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                // hiddenLabel
                                required
                                autoComplete="off"
                                label={t("last_name")}
                                placeholder={t("last_name")}
                                variant="outlined"
                                className="formTextFieldArea"
                                value={lastName}
                                onChange={(e) => {
                                  setLastName(e.target.value);
                                }}
                              />
                            </FormControl>
                          </div>
                        </div>

                        <div className="formElementGrp">
                          <div className="formElement mxW-100">
                            <FormControl className="formControl">
                              <InputLabel id="demo-simple-select-label">
                                {t("gender")}*
                              </InputLabel>
                              <Select
                                required
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={patientGender}
                                label={t("gender")}
                                onChange={handleChangepatientGender}
                                className="formInputField"
                                variant="outlined"
                              >
                                <MenuItem value="Male">{t("male")}</MenuItem>
                                <MenuItem value="Female">
                                  {t("female")}
                                </MenuItem>
                                <MenuItem value="Others">
                                  {t("others")}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>

                          <div className="formElement mxW174">
                            <FormControl className="formControl">
                              <InputLabel
                                id="employeeJoingDate"
                                className="setCompletionDate"
                              >
                                {t("dob")} *
                              </InputLabel>
                              <ReactDatePicker
                                toggleCalendarOnIconClick
                                showYearDropdown
                                yearDropdownItemNumber={100}
                                scrollableYearDropdown
                                showMonthDropdown
                                showIcon
                                labelId="employeeJoingDate"
                                className="formDatePicker"
                                maxDate={new Date()}
                                dateFormat="dd-MMM-yyyy"
                                // selected={baPatDOB}
                                // onChange={(date) => setBaPatDOB(date)}

                                selected={selectedDob}
                                onChange={(dateOfBirth) => {
                                  setSelectedDob(dateOfBirth);

                                  if (dateOfBirth) {
                                    const formattedDate = format(
                                      dateOfBirth,
                                      "dd-MM-yyyy"
                                    );

                                    setAge(
                                      DateUtils.getAgeFromDateOfBirth(
                                        dateOfBirth
                                      )
                                    );
                                  }
                                }}
                              />
                            </FormControl>
                          </div>
                          <div className="patientAgeBreack">
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  // hiddenLabel
                                  autoComplete="off"
                                  label={t("years")}
                                  variant="outlined"
                                  className="formTextFieldArea"
                                  value={age?.years || ""}
                                  name="years"
                                  onChange={handleAgeChange}
                                  disabled
                                />
                              </FormControl>
                            </div>
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  // hiddenLabel
                                  autoComplete="off"
                                  label={t("months")}
                                  variant="outlined"
                                  className="formTextFieldArea"
                                  value={age?.months || ""}
                                  name="months"
                                  onChange={handleAgeChange}
                                  disabled
                                />
                              </FormControl>
                            </div>
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  // hiddenLabel
                                  autoComplete="off"
                                  label={t("days")}
                                  variant="outlined"
                                  className="formTextFieldArea"
                                  value={age?.days || ""}
                                  name="days"
                                  onChange={handleAgeChange}
                                  disabled
                                />
                              </FormControl>
                            </div>
                          </div>
                        </div>

                        <div className="formElementGrp">
                          <div className="formElement mxW120">
                            <FormControl className="formControl">
                              <InputLabel id="demo-simple-select-label">
                                {t("civil_status")}
                              </InputLabel>
                              <Select
                                required
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={patientMaritalStatus}
                                label={t("civil_status")}
                                onChange={handleChangeMaritalStatus}
                                className="formInputField"
                                variant="outlined"
                              >
                                <MenuItem value="single">
                                  {t("single")}
                                </MenuItem>
                                <MenuItem value="married">
                                  {t("married")}
                                </MenuItem>
                                <MenuItem value="separated">
                                  {t("separated")}
                                </MenuItem>
                                <MenuItem value="divorced">
                                  {t("divorced")}
                                </MenuItem>
                                <MenuItem value="widowed">
                                  {t("widowed")}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <div className="custContactNo">
                            <select
                              name="countryCode"
                              id="countryCode"
                              className="countryCode"
                              value={patientIsdCode}
                              // onChange={(e) => {
                              //   setPatyientIsdCode(e.target.value);
                              // }}
                              onChange={handleChangeemplyPhnCC}
                            >
                              {/* <option value="+91" selected>
                                +91
                              </option>
                              <option value="+1">+1</option>
                              <option value="+635">+635</option> */}
                              {cdCodeList.map((cdCodeList) => (
                                <option key={cdCodeList} value={cdCodeList}>
                                  {cdCodeList}
                                </option>
                              ))}
                            </select>
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  // hiddenLabel
                                  // required
                                  autoComplete="off"
                                  label={t("contact_number")}
                                  variant="outlined"
                                  className="formTextFieldArea"
                                  value={contactNumber}
                                  onChange={(e) => {
                                    const numericValue = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    setContactNumber(numericValue);
                                  }}
                                  inputProps={{
                                    inputMode: "numeric",
                                    pattern: "[0-9]*",
                                  }}
                                />
                              </FormControl>
                            </div>
                          </div>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                // hiddenLabel
                                disabled
                                required
                                autoComplete="off"
                                label={t("email_id")}
                                variant="outlined"
                                className="formTextFieldArea"
                                value={patientEmailId}
                                onChange={(e) => {
                                  setPatientEmailId(e.target.value);
                                }}
                              />
                            </FormControl>
                          </div>
                        </div>
                        <div className="formElementGrp">
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                // hiddenLabel
                                autoComplete="off"
                                label={t("guardian_name")}
                                variant="outlined"
                                className="formTextFieldArea"
                                value={guardianName}
                                onChange={(event) =>
                                  setGuardianName(event.target.value)
                                }
                              />
                            </FormControl>
                          </div>
                          <div className="custContactNo">
                            <select
                              name="countryCode"
                              id="countryCode"
                              className="countryCode"
                              value={emergencyCallingCode}
                              onChange={(event) =>
                                setEmergencyCallingCode(event.target.value)
                              }
                              // onChange={(event) => {
                              //   const selectedValue =
                              //     event.target.value !== ""
                              //       ? event.target.value
                              //       : patientData?.emergencyContactCallingCode &&
                              //         patientData.emergencyContactCallingCode !== ""
                              //       ? patientData.emergencyContactCallingCode
                              //       : emplyPhnCC && emplyPhnCC !== ""
                              //       ? emplyPhnCC
                              //       : "";
                              //   updatePatientData(
                              //     "emergencyContactCallingCode",
                              //     selectedValue
                              //   );
                              //   switch(selectedValue) {
                              //     case "+91": // India
                              //       setPhoneRegexPattern(/^[6-9]\d{9}$/);
                              //       break;
                              //     case "+52": // Mexico
                              //       setPhoneRegexPattern(/^[1-9]\d{9}$/);
                              //       break;
                              //     case "+971": // UAE
                              //       setPhoneRegexPattern(/^(?:\50|51|52|55|56|2|3|4|6|7|9)\d{7}$/);
                              //       break;
                              //     default:
                              //       setPhoneRegexPattern(/^[0-9]{10,}$/); // Default pattern for other countries
                              //       break;
                              //   }
                              // }}
                            >
                              {/* <option value="+91" selected>
                              +91
                            </option>
                            <option value="+1">+1</option>
                            <option value="+635">+635</option> */}
                              {cdCodeList.map((cdCodeList) => (
                                <option key={cdCodeList} value={cdCodeList}>
                                  {cdCodeList}
                                </option>
                              ))}
                            </select>
                            {/* <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                // hiddenLabel
                                autoComplete="off"
                                label="Emergency Contact No"
                                variant="outlined"
                                className="formTextFieldArea"
                                value={emergencyPhoneNumber}
                                onChange={(event) =>
                                  setEmergencyPhoneNumber(event.target.value)
                                }
                              />
                            </FormControl>
                          </div> */}
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  autoComplete="off"
                                  label={t("emergency_contact_no")}
                                  variant="outlined"
                                  className="formTextFieldArea"
                                  value={emergencyPhoneNumber}
                                  onChange={(event) => {
                                    const value = event.target.value;
                                    if (/^\d*$/.test(value)) {
                                      setEmergencyPhoneNumber(value);
                                    }
                                  }}
                                  inputProps={{
                                    inputMode: "numeric",
                                    pattern: "[0-9]*",
                                  }}
                                />
                              </FormControl>
                            </div>
                          </div>
                        </div>

                        <div className="formElementGrp">
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                // hiddenLabel
                                autoComplete="off"
                                label={t("insurance_provider_name")}
                                variant="outlined"
                                className="formTextFieldArea"
                                value={insuranceProvider}
                                onChange={(event) =>
                                  setInsuranceProvider(event.target.value)
                                }
                              />
                            </FormControl>
                          </div>

                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                // hiddenLabel
                                autoComplete="off"
                                label={t("insurance_provider_no")}
                                variant="outlined"
                                className="formTextFieldArea"
                                value={insuranceNumber}
                                onChange={(event) =>
                                  setInsuranceNumber(event.target.value)
                                }
                              />
                            </FormControl>
                          </div>
                        </div>
                      </>
                    )}

                    {/* {showParagraph && (
                    <div style={{ color: "#fff" }}>
                    <p>
                    <b>{`${firstName} ${middleName} ${lastName}`.trim()}</b> has scheduled
                     a consultation with <b>{appoinmentData?.doctorName}</b> on{" "}
                    <b>{dayjs(appoinmentData?.date).format("DD-MMM-YYYY")}</b> at{" "}
                    <b>{appoinmentData?.slot?.fromTime}</b> -{" "}
                    <b>{appoinmentData?.slot?.toTime}</b>. Please ensure you reach the
                    center at least 15 minutes before your appointment time to avoid any
                    delays and to allow for a smooth consultation process.
                   </p>
                   </div>
                    )} */}
                    {!selectedPatient && showParagraph && (
                      <div style={{ color: "#fff" }}>
                        <p>
                          {t("appointmentMessage", {
                            fullName: `${firstName} ${middleName} ${lastName}`,
                            doctorName: appoinmentData?.doctorName,
                            date: dayjs(appoinmentData?.date).format(
                              "DD-MMM-YYYY"
                            ),
                            fromTime: appoinmentData?.slot?.fromTime,
                            toTime: appoinmentData?.slot?.toTime,
                          })}
                        </p>
                      </div>
                    )}

                    {!isNewPatient && selectedPatient && (
                      <div style={{ color: "#fff" }}>
                        {/* <p>
                          {" "}
                          {`${
                            selectedPatient?.patientName
                          } has scheduled an appointment with ${
                            appoinmentData?.doctorName
                          }
                          on ${dayjs(appoinmentData?.date).format(
                            "DD-MMM-YYYY"
                          )} from ${appoinmentData?.slot?.fromTime} to ${
                            appoinmentData?.slot?.toTime
                          }.`}
                        </p> */}
                        {/* <p>
                          <b>{selectedPatient?.patientName}</b> has scheduled a
                          consultation with <b>{appoinmentData?.doctorName}</b>{" "}
                          on{" "}
                          <b>
                            {dayjs(appoinmentData?.date).format("DD-MMM-YYYY")}
                          </b>{" "}
                          at <b>{appoinmentData?.slot?.fromTime}</b> -{" "}
                          <b>{appoinmentData?.slot?.toTime}</b>. Please ensure
                          you reach the center at least 15 minutes before your
                          appointment time to avoid any delays and to allow for
                          a smooth consultation process.
                        </p> */}
                        <p>
                          {t("consultationMessage", {
                            patientName: selectedPatient?.patientName,
                            doctorName: appoinmentData?.doctorName,
                            date: dayjs(appoinmentData?.date).format(
                              "DD-MMM-YYYY"
                            ),
                            fromTime: appoinmentData?.slot?.fromTime,
                            toTime: appoinmentData?.slot?.toTime,
                          })}
                        </p>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="rfContFooter">
            <div className="doctorSelectWthSubBtn">
              <div className="formBtnElement">
                {/* {console.log("appoinmentData *****", appoinmentData)} */}
                {(appoinmentData?.slot?.patientName === "" ||
                  appoinmentData?.slot?.patientName === null) &&
                  (appoinmentData?.slot?.patientGender === "" ||
                    appoinmentData?.slot?.patientGender === null) && (
                    <>
                      <Button
                        className="dfultPrimaryBtn"
                        disabled={!selectedPatient && !isNewPatient}
                        onClick={() => {
                          if (isNewPatient && firstName.trim() === "") {
                            toast.error(t("please_enter_first_name"), {
                              position: toast.POSITION.TOP_RIGHT,
                            });
                            return;
                          }

                          if (isNewPatient && lastName.trim() === "") {
                            toast.error(t("please_enter_last_name"), {
                              position: toast.POSITION.TOP_RIGHT,
                            });
                            return;
                          }

                          if (isNewPatient && !patientGender) {
                            toast.error(t("please_select_gender"), {
                              position: toast.POSITION.TOP_RIGHT,
                            });
                            return;
                          }

                          if (isNewPatient && selectedDob === null) {
                            toast.error(t("please_select_date_of_birth"), {
                              position: toast.POSITION.TOP_RIGHT,
                            });
                            return;
                          }

                          // if (isNewPatient && contactNumber.trim() === "") {
                          //   toast.error(t("please_enter_contact_number"), {
                          //     position: toast.POSITION.TOP_RIGHT,
                          //   });
                          //   return;
                          // }

                          if (
                            isNewPatient &&
                            contactNumber &&
                            !phoneRegexPattern.test(contactNumber)
                          ) {
                            toast.error(
                              t("please_enter_a_valid_phone_number"),
                              {
                                position: toast.POSITION.TOP_RIGHT,
                              }
                            );
                            return;
                          }

                          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                          if (
                            isNewPatient &&
                            !emailPattern.test(patientEmailId)
                          ) {
                            toast.error(t("please_enter_a_valid_email"), {
                              position: toast.POSITION.TOP_RIGHT,
                            });
                            return;
                          }

                          const reqDto = {
                            slotId: appoinmentData?.slot?.slotId,
                            doctorId: appoinmentData?.doctorId,
                            patientId: selectedPatient?.patientId || 0,
                            title: selectedPatient?.patientTitle || baPatTitle,
                            firstName:
                              selectedPatient?.patientFirstName ||
                              firstName ||
                              "",
                            middleName:
                              selectedPatient?.patientMiddleName ||
                              middleName ||
                              "",
                            lastName:
                              selectedPatient?.patientLastName ||
                              lastName ||
                              "",
                            gender: selectedPatient?.gender || patientGender,
                            // dob:
                            //   checkDate(selectedDob) === ""
                            //     ? ""
                            //     : DateUtils.getDateInDDMMYYYY(selectedDob),
                            dob: selectedDob
                              ? checkDate(selectedDob) === ""
                                ? ""
                                : DateUtils.getDateInDDMMYYYY(selectedDob)
                              : "",
                            callingId: "",
                            contactNo:
                              selectedPatient?.contactNo || contactNumber,
                            emailId:
                              selectedPatient?.patientEmailId || patientEmailId,
                            unitId: loginUserDetail?.signleUnitId || 0,
                            orgId: loginUserDetail?.orgId,
                            civilStatus: "",
                            patientUserId: selectedPatient?.patientUserId || "",
                            patientIdDisplay: "",
                            emergencyContactNo: emergencyPhoneNumber || "",
                            emergencyContactCallingCode:
                              emergencyCallingCode || "",
                            emergencyContactName: guardianName || "",
                            insuranceProvider: insuranceProvider || "",
                            insuranceNo: insuranceNumber || "",
                          };

                          // console.log("reqDto= ", reqDto);
                          // return;

                          zhPatientService
                            .bookAppointmentFromUnit(
                              loginUserDetail.userId,
                              reqDto
                            )
                            .then((response) => {
                              if (response.data != 0) {
                                toast.success(
                                  t("appoinment_booked_successfully")
                                );
                                handelCloseAppointmentBooking();
                                refreshBookAppointmentChange();
                                handelCloseAllViewBookAppointment();
                              } else {
                                toast.error(
                                  t("appoinment_booking_unsuccessful")
                                );
                              }
                            });
                        }}
                      >
                        {t("confirm_booking")}
                      </Button>

                      <Button
                        className="dfultDarkSecondaryBtn"
                        onClick={() => handelCloseAppointmentBooking()}
                      >
                        {t("cancel")}
                      </Button>
                    </>
                  )}
                {/* {appoinmentData?.slot?.patientName?.trim() !== "" &&
                  appoinmentData?.slot?.patientGender?.trim() !== "" && (
                    <Button
                      className="dfultDarkSecondaryBtn"
                      onClick={() => handelCloseAppointmentBooking()}
                    >
                      Close
                    </Button>
                  )} */}
                {/* {appoinmentData?.slot?.patientName !== "" &&
                  appoinmentData?.slot?.patientName !== null &&
                  appoinmentData?.slot?.patientGender !== "" &&
                  appoinmentData?.slot?.patientGender !== null && (
                    <Button
                      className="dfultDarkSecondaryBtn"
                      onClick={() => handelCloseAppointmentBooking()}
                    >
                      {t("close")}
                    </Button>
                  )} */}
                {appoinmentData?.slot?.patientId > 0 && (
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={() => handelCloseAppointmentBooking()}
                  >
                    {t("close")}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
