import React, { useContext, useEffect } from "react";
import { useState, useRef } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { toast } from "react-toastify";
import { convertFileToBase64_Split } from "../../../utils/FileUtils";
import FeedbackService from "../../../services/FeedbackService";
import {
  MAX_FILE_SIZE_FEEDBACK,
  VALID_FILE_EXTENSION_FEEDBACK,
} from "../../../constants/const_values";
import { Attachment, Send } from "iconoir-react";
import { getLoginUserDetails } from "../../../utils/Utils";
import "../css/Support.css";
import { SELECT_MenuProps } from "../../../constants/const_string";
import { Ticket, XLg } from "react-bootstrap-icons";
import { v4 } from "uuid";
import axios from "axios";
import DashboardServices from "../../../services/DashboardServices";
import { Skeleton, Zoom } from "@mui/material";
import SupportBotLogo from "../assets/images/SupportBotLogo.svg";
import { CopyAllOutlined, SendSharp } from "@mui/icons-material";
import "../css/zoeyBot.css";
import TicketForm from "./TicketForm";
import SupportTicketView from "./SupportTicketView";
import { AddTabContext } from "../../dashboard/view/DashboardPage";
import { webApp } from "../../../constants/url_provider";
// import SupportTicketView from "../../support/view/SupportTicketView";
// import { AddTabContext } from "./DashboardPage";

function Support({ setShowSupportRightPanel }) {
  const userDetails = getLoginUserDetails();
  const { addTab, getAllTabs } = useContext(AddTabContext);
  // const expendFeedbackRef = useRef(null);
  const hideChatIoc = useRef(null);
  const ffCAconvo = useRef(null);
  const inputFileRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState("ffSupport");

  const [selectedModule, setSelectedModule] = useState("");
  const [comment, setComment] = useState("");
  const [uploadFileList, setUploadFileList] = useState([]);

  // const expendFeedback = async () => {
  //   expendFeedbackRef.current.classList.add("ffenter");
  //   expendFeedbackRef.current.classList.add("ffexpand");
  //   hideChatIoc.current.classList.add("dNone");
  //   ffCAconvo.current.classList.add("ffCAenter");
  // };

  // const collapsFeedback = async () => {
  //   expendFeedbackRef.current.classList.remove("ffenter");
  //   expendFeedbackRef.current.classList.remove("ffexpand");
  //   hideChatIoc.current.classList.remove("dNone");
  //   ffCAconvo.current.classList.remove("ffCAenter");
  // };

  const handleChangeRadio = (e) => {
    setSelectedRadio(e.target.value);
    setSelectedModule("");
    setComment("");
    setUploadFileList([]);
  };

  const handleChangeFileUpload = async (e) => {
    const files = e.target.files;
    let tmpList = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > 1024 * 1024 * MAX_FILE_SIZE_FEEDBACK) {
        toast.warning(
          `'${file.name}' Need To Be Less Then ${MAX_FILE_SIZE_FEEDBACK}MB`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else if (
        !VALID_FILE_EXTENSION_FEEDBACK.includes(file.name.split(".").pop())
      ) {
        toast.warning(`Invalid File Type Of '${file.name}'`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        tmpList.push({
          fileName: file.name,
          fileData: await convertFileToBase64_Split(file),
          fileDisplayName: "",
        });
      }
    }

    setUploadFileList(tmpList);
    inputFileRef.current.value = null;
  };

  const handleSubmitFeedback = () => {
    if (comment === "") {
      toast.error(`Please Enter Your Comment`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const reqDto = {
        bugsSuggestion: `${selectedRadio === "ffSuggestion" ? `S` : ``}${
          selectedRadio === "ffBugs" ? `B` : ``
        }`,
        module: selectedModule,
        comments: comment,
        documents: uploadFileList,
      };
      // console.log(reqDto);
      setLoading(true);
      FeedbackService.betaFeedback(userDetails.userId, reqDto).then(
        (response) => {
          if (response.data) {
            if (response.data.returnValue === "1") {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              setSelectedModule("");
              setComment("");
              setUploadFileList([]);
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            setLoading(false);
          }
        }
      );
    }
  };

  const [activeDrags, setActiveDrags] = useState(0);
  const handleDrag = {
    onStart: () => setActiveDrags(activeDrags + 1),
    onStop: () => setActiveDrags(activeDrags - 1),
  };

  const [showZoeyBotIoc, setShowZoeyBotIoc] = useState(true);
  const [showZoey, setShowZoey] = useState(false);
  const [loadingReply, setLoadingReply] = useState(false);
  const [conversation, setConversation] = useState([]);
  const [messagesCount, setMessagesCount] = React.useState(0);
  const [profileImage, setProfileImage] = useState(null);
  const inputRef = useRef(null);
  const sessionId = v4();
  const handleCloseZoey = () => {
    setShowZoey(false);
    setShowZoeyBotIoc(true);
  };
  const handleShowZoey = () => {
    setShowZoey(true);
    setShowZoeyBotIoc(false);
    setMessagesCount(messagesCount + 1);
  };

  const sendMessage = async (message) => {
    const query = inputRef.current.value?.trim();
    inputRef.current.value = "";
    if (!query) {
      toast.error("Please ask a question!!");
      inputRef.current.focus();
      // inputRef.current.style.border = "2px solid red";
      return;
    }
    setMessagesCount(messagesCount + 1);

    // let chatDataSession = JSON.parse(localStorage.getItem("zoyel-chat-data"));
    // let sessionId = null;
    // if (chatDataSession) {
    //   sessionId = chatDataSession.sessionId;
    // } else {
    //   sessionId = v4();
    // }

    let reqDto = {
      user_message: query,
      session_id: sessionId,
    };

    setConversation((prevList) => [
      ...prevList,
      { id: prevList.length + 1, message: reqDto.user_message, type: "user" },
    ]);

    setLoadingReply(true);

    let apiUrl =
      webApp === "zoyel.health"
        ? "https://aichatbot.zoyel.health/chat"
        : "https://aichatbot.ahlan.work/chat";

    const response = await axios.post(apiUrl, reqDto);
    console.log(response.data);
    setLoadingReply(false);

    setConversation((prevConversation) => [
      ...prevConversation,
      {
        id: prevConversation.length + 1,
        message: response.data.response,
        type: "bot",
      },
    ]);

    // setConversation(prevConversation => [
    //   ...prevConversation,
    //   ...newConversations
    // ]);

    // if (chatDataSession) {
    //   let chatHistory = chatDataSession.chatHistory;
    //   chatHistory.push({
    //     id: chatHistory.length + 1,
    //     message: reqDto.user_message,
    //     type: "user",
    //   });
    //   chatHistory.push({
    //     id: chatHistory.length + 1,
    //     message: response.data.response,
    //     type: "bot",
    //   });
    //   let chatData = {
    //     sessionId: reqDto.session_id,
    //     chatHistory: chatHistory,
    //   };
    //   localStorage.setItem("zoyel-chat-data", JSON.stringify(chatData));
    //   setConversation(chatHistory);
    // } else {
    //   let chatHistory = [];
    //   chatHistory.push({
    //     id: chatHistory.length + 1,
    //     message: reqDto.user_message,
    //     type: "user",
    //   });
    //   chatHistory.push({
    //     id: chatHistory.length + 1,
    //     message: response.data.response,
    //     type: "bot",
    //   });
    //   let chatData = {
    //     sessionId: reqDto.session_id,
    //     chatHistory: chatHistory,
    //   };
    //   localStorage.setItem("zoyel-chat-data", JSON.stringify(chatData));
    //   setConversation(chatHistory);
    // }
  };

  useEffect(() => {
    localStorage.setItem("zoyel-chat-data", null);
    // const chatDataSession = JSON.parse(localStorage.getItem("zoyel-chat-data"));
    // console.log("useEffect chat", chatDataSession);
    // if (chatDataSession) {
    //   console.log("useEffect chat", chatDataSession.chatHistory);
    //   setConversation(chatDataSession.chatHistory);
    // }
    DashboardServices.getUserProfile(userDetails.userId).then((response) => {
      if (response.data && response.data.profileImageFileDataLink) {
        setProfileImage(
          "data:image/png;base64," + response.data.profileImageFileDataLink
        );
      } else {
        setProfileImage(
          "https://ahlan-s3.s3.me-south-1.amazonaws.com/images/no-profile.png"
        );
      }
    });
  }, []);

  const parseResponse = (content) => {
    let contentTemp = content.replaceAll("\n", "<br/>");
    return <div dangerouslySetInnerHTML={{ __html: contentTemp }} />;
  };

  const chatEndRef = React.useRef(null);

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      console.log(
        "scroll",
        chatEndRef.current.scrollHeight,
        chatEndRef.current.scrollTop
      );
      chatEndRef.current.scrollTop = chatEndRef.current.scrollHeight;
    }
  };

  React.useEffect(() => {
    console.log("scroll", messagesCount);
    scrollToBottom();
  }, [messagesCount]);

  const [refreshState, setRefreshState] = useState(0);

  const createTicket = async () => {
    setLoadingReply(true);
    console.log("Create software ticket");
    setMessagesCount(messagesCount + 1);

    // let chatDataSession = JSON.parse(localStorage.getItem("zoyel-chat-data"));
    // let sessionId = null;
    // if (chatDataSession) {
    //   sessionId = chatDataSession.sessionId;
    // } else {
    //   sessionId = v4();
    // }

    let reqDto = {
      user_message: "Create a ticket",
      session_id: sessionId,
    };

    setConversation((prevList) => [
      ...prevList,
      { id: prevList.length + 1, message: reqDto.user_message, type: "user" },
    ]);

    const autoReply = "Software";
    console.log("autoReply", autoReply);
    setRefreshState(refreshState + 1);
    setLoadingReply(false);
    setConversation((prevConversation) => [
      ...prevConversation,
      {
        id: prevConversation.length + 1,
        message: autoReply,
        type: "bot",
        autoReply: true,
        openForm: true,
      },
    ]);
    // if (chatDataSession) {
    //   let chatHistory = chatDataSession.chatHistory;
    //   chatHistory.push({
    //     id: chatHistory.length + 1,
    //     message: reqDto.user_message,
    //     type: "user",
    //   });
    //   chatHistory.push({
    //     id: chatHistory.length + 1,
    //     message: autoReply,
    //     type: "bot",
    //     autoReply: true,
    //     openForm: true,
    //   });
    //   let chatData = {
    //     sessionId: reqDto.session_id,
    //     chatHistory: chatHistory,
    //   };
    //   localStorage.setItem("zoyel-chat-data", JSON.stringify(chatData));
    //   setConversation(chatHistory);
    // } else {
    //   let chatHistory = [];
    //   chatHistory.push({
    //     id: chatHistory.length + 1,
    //     message: reqDto.user_message,
    //     type: "user",
    //   });
    //   chatHistory.push({
    //     id: chatHistory.length + 1,
    //     message: autoReply,
    //     type: "bot",
    //     autoReply: true,
    //     openForm: true,
    //   });
    //   let chatData = {
    //     sessionId: reqDto.session_id,
    //     chatHistory: chatHistory,
    //   };
    //   localStorage.setItem("zoyel-chat-data", JSON.stringify(chatData));
    //   setConversation(chatHistory);
    // }
  };

  // const [createdTicketDetails, setCreatedTicketDetails] = useState(null);

  return (
    <div className="supportContainer">
      <div className="supportHeader">
        <span className="supportTitle">Support</span>
        <div className="supportClose">
          <IconButton onClick={() => setShowSupportRightPanel(false)}>
            <XLg />
          </IconButton>
        </div>
      </div>

      <div className="supportBody">
        {loading && "Please wait"}
        {/* <div className="supportOptions">
          <p className="supportBodyTitle">
            If you need support, have suggestions, or encounter any bugs, kindly
            let us know
          </p>

          <FormControl>
            <RadioGroup row defaultValue="ffSupport">
              <FormControlLabel
                value="ffSupport"
                control={<Radio />}
                label="Support"
                checked={selectedRadio === "ffSupport"}
                onChange={handleChangeRadio}
              />
              <FormControlLabel
                value="ffSuggestion"
                control={<Radio />}
                label="Suggestion"
                checked={selectedRadio === "ffSuggestion"}
                onChange={handleChangeRadio}
              />
              <FormControlLabel
                value="ffBugs"
                control={<Radio />}
                label="Report a Bug"
                checked={selectedRadio === "ffBugs"}
                onChange={handleChangeRadio}
              />
            </RadioGroup>
          </FormControl>
        </div> */}

        <div className="showFFcontainGrp">
          {selectedRadio !== "" && (
            <>
              <div
                className="showSuggetionContainer"
                id="showSuggetionContainer"
              >
                {selectedRadio === "ffSupport" && (
                  <>
                    <div className="botFlotingContainer">
                      <div className="botContContain">
                        <div className="botContContainDetails" ref={chatEndRef}>
                          <div className="botMsg" key={-1}>
                            <div className="bMsg msgFrmBot">
                              <div className="msgImg">
                                <img src={SupportBotLogo} alt="bot_Img" />
                              </div>
                              <div className="msgBody">
                                <div className="msgUserNm">Zoey</div>
                                <div className="msgBodyTxt">
                                  {parseResponse(
                                    "Hi, I am Zoey, your support assistant, you can either chat with me or create a ticket"
                                  )}
                                  <Button
                                    className="createTicketBtn"
                                    endIcon={<SendSharp />}
                                    onClick={() => createTicket()}
                                  >
                                    Create a ticket
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>

                          {conversation.map((convDto) => {
                            console.log("convDto-->>>", convDto);
                            if (convDto.type === "user") {
                              return (
                                <div className="botMsg" key={convDto.id}>
                                  <div className="bMsg msgFrmUser">
                                    <div className="msgImg">
                                      <img src={profileImage} alt="user_Img" />
                                    </div>
                                    <div className="msgBody">
                                      <div className="msgUserNm">You</div>
                                      <div className="msgBodyTxt">
                                        <p>{convDto.message}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            } else {
                              return (
                                <div className="botMsg" key={convDto.id}>
                                  <div className="bMsg msgFrmBot">
                                    <div className="msgImg">
                                      <img
                                        src={SupportBotLogo}
                                        alt="user_Img"
                                      />
                                    </div>
                                    <div className="msgBody">
                                      <div className="msgUserNm">Zoey</div>
                                      <div className="msgBodyTxt">
                                        {convDto.autoReply &&
                                          convDto.openForm && (
                                            <TicketForm
                                              onTicketCreated={(ticketData) => {
                                                console.log(
                                                  "onTicketCreated",
                                                  ticketData
                                                );

                                                setConversation(
                                                  (prevConversation) =>
                                                    prevConversation.map(
                                                      (item) =>
                                                        item.id === convDto.id
                                                          ? {
                                                              ...item,
                                                              openForm: false,
                                                              openTicketCreatedForm: true,
                                                              createdTicketDetails:
                                                                ticketData,
                                                            }
                                                          : item
                                                    )
                                                );
                                              }}
                                            />
                                          )}

                                        {convDto.autoReply &&
                                          convDto.openTicketCreatedForm && (
                                            <>
                                              {" "}
                                              <p>
                                                {
                                                  "Your ticket has been created, please find the ticket id below"
                                                }
                                                <br></br>
                                                {
                                                  convDto.createdTicketDetails
                                                    .supportDisplayId
                                                }
                                              </p>
                                              <Button
                                                className="createTicketBtn"
                                                endIcon={<SendSharp />}
                                                onClick={() => {
                                                  const tabKey = v4();
                                                  console.log("key", tabKey);
                                                  addTab({
                                                    key: tabKey,
                                                    title: "Support Dashboard",
                                                    content: (
                                                      <SupportTicketView
                                                        selectedTicket={
                                                          convDto
                                                            .createdTicketDetails
                                                            .supportDisplayId
                                                        }
                                                      />
                                                    ),
                                                    isDraggable: true,
                                                    tabIndex:
                                                      getAllTabs().length,
                                                    driveKey: tabKey,
                                                  });
                                                }}
                                              >
                                                View Created Ticket Details
                                              </Button>
                                              <Button
                                                className="createTicketBtn"
                                                endIcon={<SendSharp />}
                                                onClick={() => createTicket()}
                                              >
                                                Create another ticket
                                              </Button>
                                            </>
                                          )}

                                        {/* {convDto.autoReply &&
                                          !convDto.openForm && (
                                            <div className="typeOfTickets">
                                              <Button
                                                className="createTicketBtn"
                                                endIcon={<SendSharp />}
                                                onClick={() =>
                                                  createSoftwareTicket()
                                                }
                                              >
                                                Software issue ticket
                                              </Button>
                                              <Button
                                                className="createTicketBtn"
                                                endIcon={<SendSharp />}
                                                onClick={() =>
                                                  createHardwareTicket()
                                                }
                                              >
                                                Hardware issue ticket
                                              </Button>
                                            </div>
                                          )} */}
                                        {!convDto.autoReply && (
                                          <>
                                            <p>
                                              {parseResponse(convDto.message)}
                                            </p>
                                            <div className="zoeyActions">
                                              <Tooltip
                                                title={"Copy"}
                                                placement="top-end"
                                                arrow
                                                TransitionComponent={Zoom}
                                              >
                                                <CopyAllOutlined
                                                  onClick={() => {
                                                    toast.success(
                                                      "Response copied"
                                                    );
                                                    navigator.clipboard.writeText(
                                                      convDto.message.replaceAll(
                                                        "\n",
                                                        "<br/>"
                                                      )
                                                    );
                                                  }}
                                                />
                                              </Tooltip>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                          {loadingReply && (
                            <div className="botMsg" key={0}>
                              <div className="bMsg msgFrmBot">
                                <div className="msgImg">
                                  <img src={SupportBotLogo} alt="bot_Img" />
                                </div>
                                <div className="msgBody">
                                  <div className="msgUserNm">Zoey</div>
                                  <div className="msgBodyTxt">
                                    <Skeleton
                                      variant="text"
                                      sx={{
                                        fontSize: "0.9rem",
                                        bgcolor: "#ff9900",
                                        width: "31vw",
                                        lineHeight: "20px",
                                      }}
                                    />
                                    <Skeleton
                                      variant="text"
                                      sx={{
                                        fontSize: "1rem",
                                        bgcolor: "#ff9900",
                                        width: "31vw",
                                        lineHeight: "20px",
                                      }}
                                    />
                                    <Skeleton
                                      variant="text"
                                      sx={{
                                        fontSize: "1rem",
                                        bgcolor: "#ff9900",
                                        width: "14vw",
                                        lineHeight: "20px",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {(selectedRadio === "ffSuggestion" ||
                  selectedRadio === "ffBugs") && (
                  <>
                    <FormControl fullWidth size="small" className="coustomDDFF">
                      <InputLabel id="fedbSugdwon">Select Section</InputLabel>
                      <Select
                        labelId="fedbSugdwon"
                        id="fedbSugdwon"
                        label="Select Section"
                        value={selectedModule}
                        onChange={(e) => setSelectedModule(e.target.value)}
                        MenuProps={SELECT_MenuProps}
                      >
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value="Scheduler">Scheduler</MenuItem>
                        <MenuItem value="Meetings">Meetings</MenuItem>
                        <MenuItem value="Chat">Chat</MenuItem>
                        <MenuItem value="Drive">Drive</MenuItem>
                        <MenuItem value="Assistant">Assistant</MenuItem>
                        <MenuItem value="Sheets">Sheets</MenuItem>
                        <MenuItem value="Word Processor">
                          Word Processor
                        </MenuItem>
                        <MenuItem value="Slides">Slides</MenuItem>
                        <MenuItem value="Profile">Profile</MenuItem>

                        {userDetails.orgId !== "ORG000000000000" &&
                          userDetails.userType !== "EXTERNAL" && (
                            <MenuItem value="Admin">Admin</MenuItem>
                          )}

                        {userDetails.orgId !== "ORG000000000000" &&
                          userDetails.userType !== "EXTERNAL" && (
                            <MenuItem value="Analysis">Analytics</MenuItem>
                          )}

                        {userDetails.orgId !== "ORG000000000000" &&
                          userDetails.userType !== "EXTERNAL" && (
                            <MenuItem value="Task">Task</MenuItem>
                          )}

                        {userDetails.orgId === "ORG000000000000" && (
                          <MenuItem value="Add Contacts">Add Contacts</MenuItem>
                        )}

                        {userDetails.orgId === "ORG000000000000" && (
                          <MenuItem value="Create Organization">
                            Create Organization
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>

                    <TextareaAutosize
                      className="coustomTextArea"
                      minRows={6}
                      maxRows={6}
                      placeholder={`${
                        selectedRadio === "ffSuggestion"
                          ? `Please enter here your valuable Suggestion`
                          : ``
                      } ${
                        selectedRadio === "ffBugs"
                          ? `Please enter brief description of bug that you facing`
                          : ``
                      }`}
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </>
                )}
                {selectedRadio === "ffBugs" && (
                  <>
                    <div className="showBugsContainer" id="showBugsContainer">
                      <Tooltip
                        title={
                          uploadFileList.length > 0 ? (
                            <ul>
                              {uploadFileList.map((file, index) => (
                                <li key={index}>{file.fileName}</li>
                              ))}
                            </ul>
                          ) : (
                            `Maximum File Size Allowed ${MAX_FILE_SIZE_FEEDBACK}MB, Extensions Allowed ${VALID_FILE_EXTENSION_FEEDBACK.map(
                              (item) => ` ${item}`
                            )}`
                          )
                        }
                        placement="top"
                      >
                        <Button
                          className="attachSrcBtn"
                          variant="contained"
                          startIcon={<Attachment />}
                          onClick={() => inputFileRef.current.click()}
                        >
                          Attach Screenshot
                          <input
                            type="file"
                            hidden
                            multiple
                            ref={inputFileRef}
                            onChange={handleChangeFileUpload}
                          />
                        </Button>
                      </Tooltip>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      {/* <div className="supportFooter">
        <Button
          className="sendFeedBtn"
          variant="contained"
          endIcon={<Send />}
          onClick={handleSubmitFeedback}
          disabled={loading}
        >
          Send Feedback
        </Button>
      </div> */}
      {selectedRadio !== "ffSupport" && (
        <div className="supportFooter">
          <div className="formBtnElement">
            <Button
              className="dfultPrimaryBtn"
              onClick={handleSubmitFeedback}
              variant="contained"
              endIcon={<Send />}
            >
              {"Send Feedback"}
            </Button>
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => {
                setShowSupportRightPanel(false);
              }}
            >
              {"Cancel"}
            </Button>
            {/* <Button
                className="dfultPrimaryBtn"
                onClick={() => finalSubmit()}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <>{t("submit")}</>
                )}
              </Button>
              {!loading && (
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => handelCloseAddPeople()}
                >
                  {t("cancel")}
                </Button>
              )} */}
          </div>
        </div>
      )}
      {selectedRadio === "ffSupport" && (
        <div className="botContFooter">
          <div className="botInputGrp">
            <input
              ref={inputRef}
              type="text"
              className="botComntInput"
              id="botComntInput"
              placeholder="How may I help you ?"
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  sendMessage();
                }
              }}
            />
            <IconButton
              className="botComtSndBtn"
              onClick={() => {
                sendMessage();
              }}
            >
              <Send />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
}

export default Support;
