import React, { useState } from "react";
import styles from "../css/TicketForm.module.css";
import { getLoginUserDetails } from "../../../utils/Utils";
import SupportService from "../../../services/SupportService";
import { toast } from "react-toastify";
import { Trash } from "react-bootstrap-icons";

const TicketForm = ({ onTicketCreated }) => {
  const loginUserDetails = getLoginUserDetails();
  const [ticketType, setTicketType] = useState("Software");
  const [formData, setFormData] = useState({
    issueCategory: "Software",
    issueName: "",
    issueDescription: "",
    priority: "",
    uploadedFiles: [],
    orgId: loginUserDetails.orgId,
    unitId: loginUserDetails.signleUnitId,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleTicketTypeChange = (type) => {
    setTicketType(type);
    setFormData((prevData) => ({
      ...prevData,
      issueCategory: type,
    }));
  };

  const handleImageUpload = (e) => {
    const files = e.target.files;
    const fileArray = Array.from(files);

    fileArray.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const extension = file.name.split(".").pop();
        setFormData((prevData) => ({
          ...prevData,
          uploadedFiles: [
            ...prevData.uploadedFiles,
            {
              field1: file.name,
              field2: reader.result,
              field3: extension,
            },
          ],
        }));
      };
      reader.readAsDataURL(file); // Converts the image to base64
    });
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const toastLoaderId = toast.loading(
      "Creating support ticket, Please do not close the page..."
    );
    console.log("Form data: ", formData);

    const response = await SupportService.createSupportTicket(
      formData,
      loginUserDetails.userId
    );
    console.log("createSupportTicket::", response.data);
    if (response.data.returnValue === "1") {
      setLoading(false);
      toast.update(toastLoaderId, {
        render: "Ticket created successfully",
        type: "success",
        isLoading: false,
        autoClose: true,
      });
      onTicketCreated(response.data);
    } else {
      setLoading(false);
      toast.update(toastLoaderId, {
        render: "Something went wrong",
        type: "error",
        isLoading: false,
        autoClose: true,
      });
    }
  };

  return (
    <div className={styles.ticketFormContainer}>
      <div className={styles.header}>
        <h2>{ticketType} Ticket</h2>
        <div className={styles.ticketSwitch}>
          <button
            className={ticketType === "Software" ? styles.active : ""}
            onClick={() => handleTicketTypeChange("Software")}
          >
            Software
          </button>
          <button
            className={ticketType === "Hardware" ? styles.active : ""}
            onClick={() => handleTicketTypeChange("Hardware")}
          >
            Hardware
          </button>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <label htmlFor="summary">Issue Name</label>
        <input
          type="text"
          id="issueName"
          name="issueName"
          value={formData.issueName}
          onChange={handleChange}
          required
        />

        <label htmlFor="description">Specifics (Max 2000 characters)</label>
        <textarea
          id="issueDescription"
          name="issueDescription"
          value={formData.issueDescription}
          onChange={handleChange}
          maxLength="2000"
          required
        />

        <div className={styles.prioritySection}>
          <label>Priority</label>
          <div className={styles.priorityOptions}>
            <label>
              <input
                type="radio"
                name="priority"
                value="Low"
                checked={formData.priority === "Low"}
                onChange={handleChange}
                required
              />
              Low
            </label>
            <label>
              <input
                type="radio"
                name="priority"
                value="Medium"
                checked={formData.priority === "Medium"}
                onChange={handleChange}
                required
              />
              Medium
            </label>
            <label>
              <input
                type="radio"
                name="priority"
                value="High"
                checked={formData.priority === "High"}
                onChange={handleChange}
                required
              />
              High
            </label>
          </div>
        </div>

        <div className={styles.uploadSection}>
          <label htmlFor="images">Images of the issue:</label>
          <input
            type="file"
            id="uploadedFiles"
            name="uploadedFiles"
            accept="image/*"
            multiple
            onChange={handleImageUpload}
          />
          <div className={styles.imagePreview}>
            {formData.uploadedFiles.map((file, index) => (
              <>
                <img src={`${file.field2}`} alt={`Uploaded Preview ${index}`} />
                {/* <Trash/> */}
              </>
            ))}
          </div>
        </div>

        <button type="submit" disabled={loading}>
          {loading ? "Please wait..." : "Submit Ticket"}
        </button>
      </form>
    </div>
  );
};

export default TicketForm;
