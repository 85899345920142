import React, { useContext } from "react";
import { Box, Button, Grid, IconButton } from "@mui/material";
import ExcalidrawApp from "./ExcalidrawApp";
import { XLg } from "react-bootstrap-icons";
import { OpenInBrowser, OpenInNew } from "@mui/icons-material";
import { AddTabContext } from "../../../../../dashboard/view/DashboardPage";
import { getLoginUserDetails } from "../../../../../../utils/Utils";
import { v4 as uuidv4 } from "uuid";
import { meetingSocket } from "../../../../../../socket";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function WhiteBoard(props) {
  const { t } = useTranslation();
  const { addTab } = useContext(AddTabContext);
  const loginUser = getLoginUserDetails();
  const [roomId, setRoomId] = React.useState(props.roomNumber);
  const [userName, setUserName] = React.useState("");
  // const [isWhiteBoardOpened, setIsWhiteBoardOpened] = useState(false);
  React.useEffect(() => {
    setRoomId(props.roomNumber);
    if (!props.isWhiteBoardOpened) {
      meetingSocket.emit("openWhiteBoard", {
        userName: loginUser.userName,
        userId: loginUser.userId,
        roomId: props.roomNumber,
        isWhiteBoardOpened: props.isWhiteBoardOpened,
      });
      props.setIsWhiteBoardOpened(true);
    }
  }, [props.roomNumber]);

  React.useEffect(() => {
    console.log("white board data =", props);
  }, []);

  return (
    <>
      <div className="meetRightHeader">
        <div className="metRtTitle">{t("whiteboard")}</div>
        <div className="metRtAction">
          <IconButton
            aria-label="openInNewTab"
            onClick={() => {
              addTab({
                key: uuidv4(),
                title: "WB_" + roomId,
                content: <ExcalidrawApp roomNumber={roomId} newTab={true} />,
                isDraggable: true,
                type: "Whiteboard",
              });
            }}
          >
            <OpenInNew />
          </IconButton>
          <IconButton
            aria-label="Close"
            onClick={() => props.handleMenuCloseButton()}
          >
            <XLg />
          </IconButton>
        </div>
      </div>

      <div className="MeetingTranscriptionContainer">
        <div className="Meeting_Transcription">
          {roomId != null && (
            <ExcalidrawApp roomNumber={roomId} userName={userName} />
          )}
        </div>
      </div>
      <div className="MeetRightFooter">
        <div className="meetRightFootBtnGrp">
          <Button
            className="cancleTransBtn"
            variant="outlined"
            onClick={() => props.handleMenuCloseButton()}
          >
            {t("close")}
          </Button>
        </div>
      </div>
    </>

    // <Box className="meetingsTabContainerArea" sx={{ bgcolor: "dark.pageBg" }}>
    //   <Grid container spacing={0} className="meetingsTabContainArea">
    //     <Grid item xs={12}>
    //       <div className="meetingsShowAreaContain">
    //         <div className="excaliWhiteboard">
    //           {roomId != null && (
    //             <ExcalidrawApp roomNumber={roomId} userName={userName} />
    //           )}
    //         </div>
    //       </div>
    //     </Grid>
    //   </Grid>
    // </Box>
  );
}
