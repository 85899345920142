import React, { useRef } from "react";
import { Apple, Eye, GooglePlay } from "react-bootstrap-icons";
import appDnCTA from "../../images/appDownloadCTA.png";
// import featursCTA from "../../images/featursImg.jpg";
import featursCTATask from "../../images/HomeTabTask.png";
import featursCTASchedular from "../../images/SchedulerWeek.png";
import TaskZoom from "../../images/taskZoom.png";
import SchedularZoom from "../../images/ShedularZoom.png";
import "../../css/features.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  ArrowBackRounded,
  ArrowForwardRounded,
  CalendarMonth,
} from "@mui/icons-material";
import { useState } from "react";
// features logos
import OmniPresence from "../../images/slider/omni-Presence1.jpg";
import Organization from "../../images/slider/organization.jpg";
import TaskManagement from "../../images/slider/task-management.jpg";
import Productivity from "../../images/slider/productivity.jpg";
import Compliances from "../../images/slider/compliances.jpg";
import Collaboration from "../../images/slider/collaboration.jpg";
import Security from "../../images/slider/security.jpg";
import TimeSpaceLanguage from "../../images/slider/timeSpaceLanguage.jpg";
import Meetings from "../../images/slider/meetings.jpg";
import completeWorkflows from "../../images/slider/complete-Workflows.jpg";
import KillWastage from "../../images/slider/kill-wastage.jpg";
import Analytics from "../../images/slider/analytics.jpg";
import HealthCare from "../../images/slider/healthcare.jpg";
// clients logo
import cl1 from "../../images/clientLogo/1.jpg";
import cl2 from "../../images/clientLogo/2.jpg";
import cl3 from "../../images/clientLogo/3.jpg";
import cl4 from "../../images/clientLogo/4.jpg";
import cl5 from "../../images/clientLogo/5.jpg";
import cl6 from "../../images/clientLogo/6.jpg";
import cl7 from "../../images/clientLogo/7.jpg";
import cl8 from "../../images/clientLogo/8.jpg";
import cl9 from "../../images/clientLogo/9.jpg";
import cl10 from "../../images/clientLogo/10.jpg";
import { Button, IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function HomePage({ clientRef, handelOpenFeatures }) {
  const { t } = useTranslation();
  const latestNewsSettings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    setScrollPosition(scrollTop);
  };

  // funtion slider start
  let funSlider = useRef(null);
  const next = () => {
    funSlider.slickNext();
  };
  const previous = () => {
    funSlider.slickPrev();
  };
  const funSliderSettings = {
    dots: false,
    infinite: true,

    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    draggable: true,
    swipe: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // funtion slider end

  return (
    <>
      <section className="heroSectionLP">
        <div className="overlayScreen"></div>
        <video autoPlay loop muted>
          <source
            src="https://videos.pexels.com/video-files/5788981/5788981-hd_1920_1080_25fps.mp4"
            type="video/mp4"
          />
        </video>
      </section>

      <section className="funtionDtlSec">
        <div className="FuntionSlider-container">
          <Slider
            ref={(slider) => {
              funSlider = slider;
            }}
            {...funSliderSettings}
          >
            <div key={1} className="indFuntionSlider">
              <div className="funtionViewPoint">
                <div className="funtionContent">
                  <div className="funContainDtl light">
                    <div className="funName"> {t("omni_presence")}</div>
                    {/* <div className="fundtel">
                      Create your web presence with a few clicks. No need for
                      domains, web hosting or IT Infra.
                    </div> */}
                    <div className="fundtel">
                      {t("omni_presence_description")}
                    </div>
                  </div>
                  <figure className="funImgContainer">
                    <picture className="funPic">
                      <img src={OmniPresence} alt="ZoyelFeatures" />
                    </picture>
                  </figure>
                  <Tooltip title={t("view_details")}>
                    <IconButton className="viewFunDtlBtn">
                      <Eye />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>

            <div key={2} className="indFuntionSlider">
              <div className="funtionViewPoint">
                <div className="funtionContent">
                  <div className="funContainDtl light">
                    <div className="funName"> {t("organization")}</div>
                    {/* <div className="fundtel">
                      Create and define your organization and its structure, add
                      people,assign departments, functions and go live.
                    </div> */}
                    <div className="fundtel">
                      {t("organization_description")}
                    </div>
                  </div>
                  <figure className="funImgContainer">
                    <picture className="funPic">
                      <img src={Organization} alt="ZoyelFeatures" />
                    </picture>
                  </figure>
                  <Tooltip title={t("view_details")}>
                    <IconButton className="viewFunDtlBtn">
                      <Eye />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>

            <div key={3} className="indFuntionSlider">
              <div className="funtionViewPoint">
                <div className="funtionContent">
                  <div className="funContainDtl light">
                    <div className="funName">{t("task_management")}</div>
                    {/* <div className="fundtel">
                      Easily create tasks and monitor them. Remove management
                      overheads.
                    </div> */}
                    <div className="fundtel">
                      {t("task_management_description")}
                    </div>
                  </div>
                  <figure className="funImgContainer">
                    <picture className="funPic">
                      <img src={TaskManagement} alt="ZoyelFeatures" />
                    </picture>
                  </figure>
                  <Tooltip title={t("view_details")}>
                    <IconButton className="viewFunDtlBtn">
                      <Eye />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>

            <div key={4} className="indFuntionSlider">
              <div className="funtionViewPoint">
                <div className="funtionContent">
                  <div className="funContainDtl light">
                    <div className="funName"> {t("compliances")}</div>
                    {/* <div className="fundtel">
                      Hold Board / AGM meetings, issue Agenda, take polls,
                      record them . Keep track of compliances.
                    </div> */}
                    <div className="fundtel">
                      {t("compliances_description")}
                    </div>
                  </div>
                  <figure className="funImgContainer">
                    <picture className="funPic">
                      <img src={Compliances} alt="ZoyelFeatures" />
                    </picture>
                  </figure>
                  <Tooltip title={t("view_details")}>
                    <IconButton className="viewFunDtlBtn">
                      <Eye />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>

            <div key={5} className="indFuntionSlider">
              <div className="funtionViewPoint">
                <div className="funtionContent">
                  <div className="funContainDtl light">
                    <div className="funName">{t("productivity")}</div>
                    {/* <div className="fundtel">
                      Create your web presence with a few clicks. No need for
                      domains, web hosting or IT Infra.
                    </div> */}
                    <div className="fundtel">
                      {t("productivity_description")}
                    </div>
                  </div>
                  <figure className="funImgContainer">
                    <picture className="funPic">
                      <img src={Productivity} alt="ZoyelFeatures" />
                    </picture>
                  </figure>
                  <Tooltip title={t("view_details")}>
                    <IconButton className="viewFunDtlBtn">
                      <Eye />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>

            <div key={6} className="indFuntionSlider">
              <div className="funtionViewPoint">
                <div className="funtionContent">
                  <div className="funContainDtl light">
                    <div className="funName">{t("collaboration")}</div>
                    {/* <div className="fundtel">
                      Complete collaboration suite without subscribing to any
                      other provider.
                    </div> */}
                    <div className="fundtel">
                      {t("collaboration_description")}
                    </div>
                  </div>
                  <figure className="funImgContainer">
                    <picture className="funPic">
                      <img src={Collaboration} alt="ZoyelFeatures" />
                    </picture>
                  </figure>
                  <Tooltip title={t("view_details")}>
                    <IconButton className="viewFunDtlBtn">
                      <Eye />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>

            <div key={7} className="indFuntionSlider">
              <div className="funtionViewPoint">
                <div className="funtionContent">
                  <div className="funContainDtl light">
                    <div className="funName">{t("security")}</div>
                    {/* <div className="fundtel">
                      Ensure data security with best of class network security,
                      encryption and data safety.
                    </div> */}
                    <div className="fundtel">{t("security_description")}</div>
                  </div>
                  <figure className="funImgContainer">
                    <picture className="funPic">
                      <img src={Security} alt="ZoyelFeatures" />
                    </picture>
                  </figure>
                  <Tooltip title={t("view_details")}>
                    <IconButton className="viewFunDtlBtn">
                      <Eye />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>

            <div key={8} className="indFuntionSlider">
              <div className="funtionViewPoint">
                <div className="funtionContent">
                  <div className="funContainDtl light">
                    <div className="funName">{t("time_space_language")}</div>
                    {/* <div className="fundtel">
                      With multi language, time zone support, let work go with
                      teams, clients and vendors across the world.
                    </div> */}
                    <div className="fundtel">
                      {t("time_space_language_description")}
                    </div>
                  </div>
                  <figure className="funImgContainer">
                    <picture className="funPic">
                      <img src={TimeSpaceLanguage} alt="ZoyelFeatures" />
                    </picture>
                  </figure>
                  <Tooltip title={t("view_details")}>
                    <IconButton className="viewFunDtlBtn">
                      <Eye />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div key={9} className="indFuntionSlider">
              <div className="funtionViewPoint">
                <div className="funtionContent">
                  <div className="funContainDtl light">
                    <div className="funName">{t("meetings")}</div>
                    {/* <div className="fundtel">
                      Synchronous and asynchronous meetings - scheduled and
                      instant with private rooms and webinars.
                    </div> */}
                    <div className="fundtel">{t("meetings_description")}</div>
                  </div>
                  <figure className="funImgContainer">
                    <picture className="funPic">
                      <img src={Meetings} alt="ZoyelFeatures" />
                    </picture>
                  </figure>
                  <Tooltip title={t("view_details")}>
                    <IconButton className="viewFunDtlBtn">
                      <Eye />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div key={10} className="indFuntionSlider">
              <div className="funtionViewPoint">
                <div className="funtionContent">
                  <div className="funContainDtl light">
                    <div className="funName">{t("complete_workflows")}</div>
                    {/* <div className="fundtel">
                      HR, Accounts, Finance, Business, Sales & Marketing,
                      customizable workflows.
                    </div> */}
                    <div className="fundtel">
                      {t("complete_workflows_description")}
                    </div>
                  </div>
                  <figure className="funImgContainer">
                    <picture className="funPic">
                      <img src={completeWorkflows} alt="ZoyelFeatures" />
                    </picture>
                  </figure>
                  <Tooltip title={t("view_details")}>
                    <IconButton className="viewFunDtlBtn">
                      <Eye />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div key={11} className="indFuntionSlider">
              <div className="funtionViewPoint">
                <div className="funtionContent">
                  <div className="funContainDtl light">
                    <div className="funName">{t("kill_wastage")}</div>
                    {/* <div className="fundtel">
                      With task linked meetings and Result based Tasks, stop the
                      drift and save crucial time
                    </div> */}
                    <div className="fundtel">
                      {t("kill_wastage_description")}
                    </div>
                  </div>
                  <figure className="funImgContainer">
                    <picture className="funPic">
                      <img src={KillWastage} alt="ZoyelFeatures" />
                    </picture>
                  </figure>
                  <Tooltip title={t("view_details")}>
                    <IconButton className="viewFunDtlBtn">
                      <Eye />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div key={12} className="indFuntionSlider">
              <div className="funtionViewPoint">
                <div className="funtionContent">
                  <div className="funContainDtl light">
                    <div className="funName">{t("analytics")}</div>
                    {/* <div className="fundtel">
                      Manage by easily monitoring what is happening and allocate
                      resources , time & money more effectively
                    </div> */}
                    <div className="fundtel">{t("analytics_description")}</div>
                  </div>
                  <figure className="funImgContainer">
                    <picture className="funPic">
                      <img src={Analytics} alt="ZoyelFeatures" />
                    </picture>
                  </figure>
                  <Tooltip title={t("view_details")}>
                    <IconButton className="viewFunDtlBtn">
                      <Eye />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div key={13} className="indFuntionSlider">
              <div className="funtionViewPoint">
                <div className="funtionContent">
                  <div className="funContainDtl light">
                    <div className="funName">{t("zoyel_health")}</div>
                    {/* <div className="fundtel">
                      Manage by easily monitoring what is happening and allocate
                      resources , time & money more effectively
                    </div> */}
                    <div className="fundtel">
                      {t("zoyel_health_description")}
                    </div>
                  </div>
                  <figure className="funImgContainer">
                    <picture className="funPic">
                      <img src={HealthCare} alt="ZoyelFeatures" />
                    </picture>
                  </figure>
                  <Tooltip title={t("view_details")}>
                    <IconButton className="viewFunDtlBtn">
                      <Eye />
                    </IconButton>
                  </Tooltip>
                  <div className="newTag">NEW</div>
                </div>
              </div>
            </div>
          </Slider>
          <div className="indFunSlidCtrl">
            <IconButton className="button" onClick={previous}>
              <ArrowBackRounded />
            </IconButton>
            <IconButton className="button" onClick={next}>
              <ArrowForwardRounded />
            </IconButton>
          </div>
          <div className="viewallBtncont">
            <Button className="vabtn" onClick={handelOpenFeatures}>
              {t("more_features")}
            </Button>
          </div>
        </div>
      </section>

      {/* <section className="showFuntionSec">
        <div className="funtionDescribeText">
          <div className="fdtContainer">
            <div className="fdtTitle">
              {scrollPosition < 90
                ? "Use Tasks to manage your organisation"
                : "Multiple calendars in a single view"}
            </div>
            <div className="fdtSubtitle">
              {scrollPosition < 90
                ? "Register today and move to a more collaborative and efficient environment."
                : "You can import and manage your other calendars from a single interface"}
            </div>
          </div>

          <svg
            width="250"
            height="200"
            viewBox="0 0 250 200"
            xmlns="http://www.w3.org/2000/svg"
            className="connectWithZoom"
            style={
              scrollPosition < 90
                ? {
                    position: "absolute",
                    right: "-119px",
                    top: "34%",
                    transform: "rotate(-9deg)",
                    transition: "all .6s",
                  }
                : {
                    position: "absolute",
                    right: "-119px",
                    top: "38%",
                    transform: "rotate(9deg)",
                    transition: "all .6s",
                  }
            }
          >
            <circle cx="5" cy="100" r="5" fill="#fff"></circle>
            <path
              d="M5,100 Q135,160 245,100"
              fill="none"
              stroke="#fff"
              stroke-width="2"
            ></path>
            <circle cx="245" cy="100" r="5" fill="#fff"></circle>
          </svg>

          <svg
            className="zoomFeatures"
            width="200"
            height="200"
            viewBox="0 0 200 200"
            xmlns="http://www.w3.org/2000/svg"
            style={
              scrollPosition < 90
                ? {
                    position: "absolute",
                    right: "-287px",
                    zIndex: "1",
                    top: "25%",
                    transition: "all .6s",
                  }
                : {
                    position: "absolute",
                    right: "-291px",
                    zIndex: "1",
                    top: "34%",
                    transition: "all .6s",
                  }
            }
          >
            <circle
              cx="100"
              cy="100"
              r="90"
              fill="none"
              stroke="#ffffff"
              strokeWidth="4"
            />

            <clipPath id="circleClip">
              <circle cx="100" cy="100" r="90" />
            </clipPath>
            <g clipPath="url(#circleClip)">
              <circle
                cx="100"
                cy="100"
                r="90"
                fill="none"
                stroke="#ffffff"
                strokeWidth="2"
              />
              <image
                x="10"
                y="10"
                width="180"
                height="180"
                xlinkHref={scrollPosition < 90 ? TaskZoom : SchedularZoom}
              />
            </g>
          </svg>
        </div>

        <div className="showFuntionContainer">
          <div className="device-mockup imac mb-small">
            <div className="device">
              <div
                className="screen"
                style={{ overflowY: "auto", pointerEvents: "all" }}
                onScroll={handleScroll}
              >
                <img
                  src={
                    scrollPosition < 90 ? featursCTATask : featursCTASchedular
                  }
                  alt="featuresImg"
                />
                <img
                  src={
                    scrollPosition >= 90 ? featursCTASchedular : featursCTATask
                  }
                  alt="featuresImg"
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="appDownloadSec">
        <div className="leftArea">
          <div className="appDownloadInfo">
            <div className="adnTitle">
              {t("join_the_zoyel_network_and")} <span>{t("streamline")}</span>{" "}
              {t("your_business")}
            </div>
            {/* <div className="adnText">
              In today's fast-paced and competitive business environment,
              efficiency and innovation are crucial for success. The Zoyel
              Network offers a comprehensive solution designed to help your
              business thrive. By joining the Zoyel Network, you can streamline
              your operations, enhance productivity, and unlock new growth
              opportunities.
            </div> */}
            <div className="adnText">{t("join_zoyel_network_detail")}</div>
            <div className="adnLinkGrp">
              <a href="https://zoyel.one/" target="blank">
                <div className="appStore appleStore">
                  <div className="appstoreIcon">
                    <Apple />
                  </div>
                  <div className="appstoreText">
                    <div className="subHeadTxt">{t("app_coming_soon")}</div>
                    {/* <div className="headTxt">App Store</div> */}
                  </div>
                </div>
              </a>
              <a href="https://zoyel.one/" target="blank">
                <div className="appStore googleStore">
                  <div className="appstoreIcon">
                    <GooglePlay />
                  </div>
                  <div className="appstoreText">
                    <div className="subHeadTxt">{t("app_coming_soon")}</div>
                    {/* <div className="headTxt">Google Play</div> */}
                  </div>
                </div>
              </a>
            </div>
            {/* <div className="adnInfo">
              <div className="adnInfoElement">
                <span className="highlighted counter">1 M</span>
                <span className="elmtxt">Downloads</span>
              </div>
              <div className="adnInfoElement">
                <span className="highlighted counter">520K</span>
                <span className="elmtxt">Active Users</span>
              </div>
              <div className="adnInfoElement">
                <span className="highlighted counter">4.6</span>
                <span className="elmtxt">34K Ratings</span>
              </div>
            </div> */}
          </div>
        </div>
        <div className="rightArea">
          <div className="adnImgPresent">
            <img src={appDnCTA} alt="appDownloadCTA" />
          </div>
        </div>
      </section>

      <section className="latestNewsSec">
        <div className="latestNewsSecContainer mapBg">
          <div className="sectionTitle">
            {t("latest")} <span>{t("news")}</span>
          </div>

          <div className="lnSliderContainer">
            <Slider {...latestNewsSettings}>
              <div>
                <div className="sliderElement">
                  <div className="slidNewsImg">
                    <img
                      src="https://images.unsplash.com/photo-1665686308827-eb62e4f6604d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="Zoyel-featured"
                    />
                  </div>
                  <div className="slidTitle">{t("featuredIn")}</div>
                  {/* <div className="slidTxt">
                    Zoyel proudly took center stage at SecCon 2024, the premier
                    conference for security and technology professionals. This
                    year, Zoyel showcased its latest advancements in
                    cybersecurity and business solutions, drawing significant
                    attention from industry leaders and experts.
                  </div> */}
                  <div className="slidTxt">{t("description1")}</div>
                  <div className="slidPublishDT">
                    <CalendarMonth />
                    <span>{t("feb12")}</span>
                  </div>
                </div>
              </div>

              <div>
                <div className="sliderElement">
                  <div className="slidNewsImg">
                    <img
                      src="https://images.unsplash.com/photo-1483058712412-4245e9b90334?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="Zoyel-featured"
                    />
                  </div>
                  <div className="slidTitle">{t("taskEffectiveness")}</div>
                  {/* <div className="slidTxt">
                    Zoyel One is a powerful task management and collaboration
                    platform designed to streamline operations and enhance team
                    productivity. Here’s how using Zoyel One can save time and
                    make your teams more effective:
                  </div> */}
                  <div className="slidTxt">{t("description2")}</div>
                  <div className="slidPublishDT">
                    <CalendarMonth />
                    <span>{t("feb6")}</span>
                  </div>
                </div>
              </div>

              <div>
                <div className="sliderElement">
                  <div className="slidNewsImg">
                    <img
                      src="https://images.unsplash.com/photo-1594122230689-45899d9e6f69?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="Zoyel-featured"
                    />
                  </div>
                  <div className="slidTitle">{t("allInOne")}</div>
                  {/* <div className="slidTxt">
                    Zoyel One is revolutionizing the way businesses operate by
                    offering a comprehensive, all-in-one solution that caters to
                    every aspect of modern business management. Here’s why Zoyel
                    One stands out as the ultimate choice for businesses seeking
                    efficiency, growth, and innovation:
                  </div> */}
                  <div className="slidTxt">{t("description3")}</div>
                  <div className="slidPublishDT">
                    <CalendarMonth />
                    <span>{t("jan28")}</span>
                  </div>
                </div>
              </div>

              <div>
                <div className="sliderElement">
                  <div className="slidNewsImg">
                    <img
                      src="https://images.unsplash.com/photo-1651341050677-24dba59ce0fd?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="Zoyel-featured"
                    />
                  </div>
                  <div className="slidTitle">{t("recordGrowth")}</div>
                  {/* <div className="slidTxt">
                    Zoyel One is rapidly expanding its presence in new and
                    existing markets. The platform's versatility and
                    adaptability make it an attractive choice for businesses of
                    all sizes, from startups to large enterprises, across
                    various industries.
                  </div> */}
                  <div className="slidTxt">{t("description4")}</div>
                  <div className="slidPublishDT">
                    <CalendarMonth />
                    <span>{t("jan21")}</span>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>

      {/* <section
        className="clientSec"
        ref={clientRef}
        style={{ display: "none !important" }}
      >
        <div className="ourClientContainer">
          <div className="sectionTitle">
            Our <span>Clients</span>
          </div>

          <div className="cleintsDtlsWrap">
            <div className="clientInfo">
              <div className="ciElemnt">
                <div className="cliNumber">105</div>
                <div className="cliText">Clients</div>
              </div>
              <div className="ciElemnt">
                <div className="cliNumber">21</div>
                <div className="cliText">Countries</div>
              </div>
            </div>
            <div className="clientsLogos">
              <div class="hexagon-gallery">
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl1} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl2} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl3} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl4} alt="some" />
                  </div>
                </div>
                <div class="hex hex-hide">
                  <div className="cleintLogo">
                    <img src={cl5} alt="some" />
                  </div>
                </div>
                <div class="hex hex-noImg">
                  <div className="cleintLogo">
                    <img src={cl6} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl7} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl8} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl9} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl10} alt="some" />
                  </div>
                </div>
                <div class="hex hex-hide">
                  <div className="cleintLogo">
                    <img src={cl2} alt="some" />
                  </div>
                </div>
                <div class="hex hex-noImg">
                  <div className="cleintLogo">
                    <img src={cl3} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl4} alt="some" />
                  </div>
                </div>
                <div class="hex hex-hide">
                  <div className="cleintLogo">
                    <img src={cl5} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl6} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl7} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl8} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl9} alt="some" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}
