import { Close, Send } from "@mui/icons-material";
import { Checkbox, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getLoginUserDetails } from "../../../../utils/Utils";
import {
  forwardMessages,
  getChatAllContacts,
  getGroupChatUsers,
  getMessageExchangedChats,
} from "../../../../services/ChatService";
import { toast } from "react-toastify";
import { chatSocket } from "../../../../socket";
import { useTranslation } from "react-i18next";

export default function ForwardUserList({
  selectedMessageList,
  selectedChatId,
  onClickClose,
  onSuccess,
}) {
  const loginUser = getLoginUserDetails();
  const { t } = useTranslation();
  const [contactList, setContactList] = useState([]);
  const [selectedUserList, setSelectedUserList] = useState([]);

  useEffect(() => {
    if (loginUser != null) {
      fetchData();
    }
    return () => {
      setContactList([]);
      setSelectedUserList([]);
    };
  }, []);

  // fetch contact details start
  const fetchData = async () => {
    let exchangeIdList = [];
    let tempContactList = [];

    await getMessageExchangedChats(loginUser.userId).then((response) => {
      if (response.data != null) {
        if (response.data.length > 0) {
          for (let index = 0; index < response.data.length; index++) {
            const {
              chatId,
              chatUserId,
              chatProfileUrl,
              chatName,
              lastMessageContent,
              chatType,
            } = response.data[index];

            exchangeIdList.push(chatUserId);

            if (selectedChatId > 0 && selectedChatId !== chatId) {
              tempContactList.push({
                chatId: chatId,
                userId: chatUserId,
                userImg: chatProfileUrl,
                userName: chatName,
                userDesc: lastMessageContent,
                userType: chatType,
              });
            }
          }
        }
      }
    });

    await getChatAllContacts(loginUser.userId, loginUser.orgId).then(
      (response) => {
        if (response.data != null) {
          if (response.data.length > 0) {
            for (let index = 0; index < response.data.length; index++) {
              const { userId, userName, userDtl, profileUrl, userType } =
                response.data[index];

              if (!exchangeIdList.includes(userId)) {
                tempContactList.push({
                  chatId: 0,
                  userId: userId,
                  userImg: profileUrl,
                  userName: userName,
                  userDesc: userDtl,
                  userType: userType,
                });
              }
            }
          }
        }
      }
    );

    setContactList(tempContactList);
  };
  // fetch contact details end

  // handle select user start
  const handleSelectUser = (user) => {
    if (!selectedUserList.includes(user)) {
      setSelectedUserList([...selectedUserList, user]);
    } else {
      setSelectedUserList(selectedUserList.filter((x) => x !== user));
    }
  };
  // handle select user end

  // handle submit forward chat start
  const handleSubmitForward = async () => {
    if (selectedUserList && selectedUserList.length > 0) {
      let messagesIds = [];
      let chatIds = [];
      let newUserIds = [];

      for (let index = 0; index < selectedMessageList.length; index++) {
        const { messageId } = selectedMessageList[index];
        messagesIds.push(messageId);
      }

      for (let index = 0; index < selectedUserList.length; index++) {
        const { chatId, userId } = selectedUserList[index];
        if (chatId > 0) {
          chatIds.push(chatId);
        } else {
          newUserIds.push(userId);
        }
      }

      const body = {
        chatIds,
        messagesIds,
        newUserIds,
        orgId: loginUser.orgId,
      };

      await forwardMessages(loginUser.userId, body).then(async (response) => {
        if (response.data != null) {
          if (response.data > 0) {
            toast.success(t("message_forwarded_successfully"));

            for (let i = 0; i < selectedUserList.length; i++) {
              let message = selectedUserList[i];
              message = {
                ...message,
                senderUserName: loginUser.userName,
                senderUserId: loginUser.userId,
              };
              for (let j = 0; j < selectedUserList.length; j++) {
                const { userType, userId, chatId } = selectedUserList[j];
                if (userType === "G") {
                  let uList = [];
                  await getGroupChatUsers(chatId).then((response) => {
                    if (response.data != null) {
                      for (let k = 0; k < response.data.length; k++) {
                        const { activeFlag, userId } = response.data[k];
                        if (activeFlag === "Y") {
                          uList.push(userId);
                        }
                      }
                    }
                  });
                  chatSocket.emit("sendChat", message, [uList]);
                } else {
                  chatSocket.emit("sendChat", message, [userId]);
                }
              }
            }

            onSuccess();
          } else {
            toast.error(t("something_went_wrong"));
          }
        }
      });
    } else {
      toast.error(t("please_select_user"));
    }
  };
  // handle submit forward chat end

  return (
    <>
      <div className="chatFrwUsrLstContainer">
        {/* close forward close start */}
        <div className="chatFrwUsrLstHeader">
          <div className="chtGrpHedText">{t("forward_chat")}</div>
          <div className="chatGrpInfoClosBtn">
            <IconButton aria-label="Close Group info" onClick={onClickClose}>
              <Close />
            </IconButton>
          </div>
        </div>
        {/* close forward close end*/}

        {/* user listing start */}
        <div className="chatFrwUsrLstArea ">
          {contactList && contactList.length > 0 && (
            <>
              <div className="frwContLstSlct">
                <div className="contactListGroup">
                  {contactList.map((user) => (
                    <div className="chatContactList ">
                      <div className="chtFrwUsrChck">
                        <Checkbox
                          checked={selectedUserList.includes(user)}
                          onClick={() => handleSelectUser(user)}
                        />
                      </div>
                      <div class="contUserImg">
                        <img alt="Profile" src={user.userImg} />
                      </div>
                      <div className="userDtlWlMsg">
                        <div className="usrNmDT">
                          <div className="usrNmIn">{user.userName}</div>
                        </div>
                        <div className="usrLstMsgDtStus">
                          <div className="lstMsgShw">{user.userDesc}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
        {/* user listing end */}

        <div className="grpCretFooter">
          <IconButton
            aria-label="Create Group"
            className="cretGrpButton"
            onClick={handleSubmitForward}
          >
            <Send />
          </IconButton>
        </div>
      </div>
    </>
  );
}
