import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Send } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { getLoginUserDetails } from "../../../../../../../utils/Utils";
import zhPatientService from "../../../../../../../services/ZhPatientService";
import { ExpandMoreRounded } from "@mui/icons-material";
import { centerSocket } from "../../../../../../../socket";
import { useTranslation } from "react-i18next";

export const Message = ({ data, extraData, updateMessage }) => {
  const { t } = useTranslation();
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const [accordionString, setAccordionString] = useState("");
  const loginUserDetail = getLoginUserDetails();
  const [messageList, setMessageList] = useState([]);
  const [message, setMessage] = useState();
  const [isSending, setIsSending] = useState(false);

  const getMessage = () => {
    zhPatientService.getMessages(extraData?.queueVisitId).then((response) => {
      if (response.data) {
        setMessageList(response.data);
      }
    });
  };

  const handleReceiveMessageFromDoctor = (data) => {
    const { type, message, visitId } = data;
    if (type === "message") {
      getMessage();
    }
  };

  useEffect(() => {
    if (centerSocket) {
      centerSocket.on(
        "receiveMessageFromDoctor",
        handleReceiveMessageFromDoctor
      );
    }
    return () => {
      centerSocket.off(
        "receiveMessageFromDoctor",
        handleReceiveMessageFromDoctor
      );
    };
  }, [centerSocket]);

  useEffect(() => {
    getMessage();
  }, []);

  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
          {t("message")}:
            {/* <span>symptoms gose here when acordion not expend</span> */}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="taskUpCommentGrp">
            {messageList?.map((item) => {
              return (
                <div className="taskUserComment">
                  <div className="tskUderImgDT">
                    <div className="tskUseComImg">
                      <div className="docIcons">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="56"
                          height="64"
                          viewBox="0 0 56 64"
                          fill="none"
                        >
                          <path d="M28 32C32.2435 32 36.3131 30.3143 39.3137 27.3137C42.3143 24.3131 44 20.2435 44 16C44 11.7565 42.3143 7.68687 39.3137 4.68629C36.3131 1.68571 32.2435 0 28 0C23.7565 0 19.6869 1.68571 16.6863 4.68629C13.6857 7.68687 12 11.7565 12 16C12 20.2435 13.6857 24.3131 16.6863 27.3137C19.6869 30.3143 23.7565 32 28 32ZM16 38.9C6.75 41.6125 0 50.1625 0 60.2875C0 62.3375 1.6625 64 3.7125 64H52.2875C54.3375 64 56 62.3375 56 60.2875C56 50.1625 49.25 41.6125 40 38.9V45.25C43.45 46.1375 46 49.275 46 53V58C46 59.1 45.1 60 44 60H42C40.9 60 40 59.1 40 58C40 56.9 40.9 56 42 56V53C42 50.7875 40.2125 49 38 49C35.7875 49 34 50.7875 34 53V56C35.1 56 36 56.9 36 58C36 59.1 35.1 60 34 60H32C30.9 60 30 59.1 30 58V53C30 49.275 32.55 46.1375 36 45.25V38.1125C35.25 38.0375 34.4875 38 33.7125 38H22.2875C21.5125 38 20.75 38.0375 20 38.1125V46.2875C22.8875 47.15 25 49.825 25 53C25 56.8625 21.8625 60 18 60C14.1375 60 11 56.8625 11 53C11 49.825 13.1125 47.15 16 46.2875V38.9ZM18 56C18.7956 56 19.5587 55.6839 20.1213 55.1213C20.6839 54.5587 21 53.7956 21 53C21 52.2044 20.6839 51.4413 20.1213 50.8787C19.5587 50.3161 18.7956 50 18 50C17.2044 50 16.4413 50.3161 15.8787 50.8787C15.3161 51.4413 15 52.2044 15 53C15 53.7956 15.3161 54.5587 15.8787 55.1213C16.4413 55.6839 17.2044 56 18 56Z" />
                        </svg>
                      </div>
                    </div>
                    <div className="tskUsrCNM">
                      <div className="tskUCNM">{item.messageFrom}</div>
                      {/* <div className="tskUCDT">Orthopedics</div> */}
                    </div>
                  </div>
                  <div className="taskUPComnt">{item.message}</div>
                </div>
              );
            })}
          </div>

          <div className="taskFooterPanel">
            <div className="tskInputGrp">
              <input
                type="text"
                className="tskComntInput"
                placeholder={t("update_comment_here")}
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
              <IconButton
                className="tskComtSndBtn"
                disabled={isSending ? true : false}
                onClick={() => {
                  if (!message) {
                    toast.error(t("enter_your_message"));
                    return;
                  }
                  setIsSending(true);
                  const reqDto = {
                    messageFrom: loginUserDetail.userName,
                    message: message,
                    messageTime: "",
                    userName: loginUserDetail.userName,
                    unitName: "",
                    userId: loginUserDetail.userId,
                    unitId: loginUserDetail.signleUnitId,
                  };
                  zhPatientService
                    .sendMessage(extraData?.queueVisitId, reqDto)
                    .then((response) => {
                      console.log("message =", response.data);
                      if (response.data == "1") {
                        toast.success(t("message_sent_successfully"));
                        setMessage("");
                        getMessage();
                        centerSocket.emit("sendMessageToDoctor", {
                          doctorId: extraData.doctorId,
                          data: "hello",
                        });
                      }
                    })
                    .finally(() => {
                      setIsSending(false);
                    });
                }}
              >
                <Send />
              </IconButton>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
