import {
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
  } from "@mui/material";
  import React from "react";
  import { useState, useEffect } from "react";
  import { XLg } from "react-bootstrap-icons";
  import {
    getRightsToSelect,
    editTermsAndConditions,
    getOrgTermsAndConditions
  } from "../../../../services/AdminService";
  import { jwtDecode } from "jwt-decode";
  import { toast } from "react-toastify";
  import { useTranslation } from "react-i18next";
  
  export default function UpdateTermsConditions({
    handelCloseEditTermsCondition,
    refreshTermsConditionsData,
    selecttedViewTermsCondition
  }) {
    const userDetails = jwtDecode(localStorage.getItem("token"));
    const { t } = useTranslation();
  
    console.log('selecttedViewTermsCondition', selecttedViewTermsCondition)


    const [termsAndConditionData, setTermsAndConditionData] = useState([]);
  
    const [loading, setLoading] = useState(false);
  
    const [termsConditionFor, setTermsConditionFor] = useState(selecttedViewTermsCondition.transType);
    const [termsConditionForData, setTermsConditionForData] = useState([]);
  
    const handleChangeTermsConditionFor = (event) => {
      console.log("handleChangeTermsConditionFor", event.target.value);
      setTermsConditionFor(event.target.value);
    };
  
    useEffect(() => {
  
        getRightsToSelect('TERMS_CONDITIONS_FOR').then((response) => {
          console.log("termsConditionFor", response.data);
          setTermsConditionForData(response.data);
        });
      
    }, []);
  
    useEffect(() => {
      const fetchDataTermCondition = async () => {
        const newData = []; 
        for (let i = 0; i < termsConditionForData.length; i++) {
          const id = termsConditionForData[i].id;
          try {
            const response = await getOrgTermsAndConditions(userDetails.orgId, id);
            console.log(`Data for ${id}:`, response.data);
            newData.push(response.data); 
          } catch (error) {
            console.error(`Error fetching data for ${id}:`, error);
          }
        }
        console.log('newData',newData);
        const MyData = newData.flat()
        console.log('MyData', MyData)
        setTermsAndConditionData(MyData);
      };
    
      fetchDataTermCondition();
    }, [termsConditionForData, userDetails.orgId]);
    
  
    const [termsConditionType, setTermsConditionType] = useState(selecttedViewTermsCondition.termsConditionsType);
    const [termsConditionTypeData, setTermsConditionTypeData] = useState([]);
  
    const handleChangeTermsConditionType = (event) => {
      console.log("handleChangeTermsConditionType", event.target.value);
      setTermsConditionType(event.target.value);
    };
  
    useEffect(() => {
  
      getRightsToSelect('TERMS_CONDITIONS_TYPE').then((response) => {
        console.log("termsConditionFor", response.data);
        setTermsConditionTypeData(response.data);
      });
    
  }, []);
  
    const [termsConditionHeading, setTermsConditionHeading] = useState(selecttedViewTermsCondition.termsConditionsHeading);
  
    const handleChangeTermsConditionHeading = (event) => {
      console.log("handleChangeTermsConditionHeading", event.target.value);
      setTermsConditionHeading(event.target.value);
    };
  
    const [termsConditionDescription, setTermsConditionDescription] =
      useState(selecttedViewTermsCondition.termsConditions);
  
    const handleChangeTermsConditiondescription = (event) => {
      console.log("handleChangeTermsConditionHeading", event.target.value);
      setTermsConditionDescription(event.target.value);
    };
  
    const [termsConditionSerialNumber, setTermsConditionSerialNumber] =
      useState(selecttedViewTermsCondition.slNo);
  
    const handleChangeTermsConditionSerialNumber = (event) => {
      const numericValue = event.target.value.replace(/[^0-9]/g, "");
      console.log("handleChangeTermsConditionHeading", numericValue);
      setTermsConditionSerialNumber(numericValue);
    };
  
    // useEffect(() => {
    //   if (termsAndConditionData.length > 0) {
    //     setTermsConditionSerialNumber(termsAndConditionData.length + 1);
    //   }
    // }, [termsAndConditionData]);
    
  
    const finalSubmit = () => {
      console.log("finalSubmit");
      setLoading(true);
  
      if (termsConditionFor.trim() === "") {
        toast.error(t("please_select_t_and_c_for"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }
  
      if (termsConditionType.trim() === "") {
        toast.error("please_select_t_and_c_type", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }
  
      if (termsConditionDescription.trim() === "") {
        toast.error(t("please_enter_terms_and_condition"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }
  
      const reqDto = {
        id: selecttedViewTermsCondition.id,
        slNo: termsConditionSerialNumber,
        termsConditionsHeading: termsConditionHeading,
        termsConditions: termsConditionDescription,
        termsConditionsType: termsConditionType,
        transType: termsConditionFor,
      };
  
      console.log("reqDto", reqDto);
  
      editTermsAndConditions(userDetails.orgId, userDetails.userId, reqDto).then(
        (response) => {
          console.log("editTermsAndConditions", response.data);
          setLoading(false);
          if (typeof response.data === "number" && response.data === 1) {
            toast.success(t("terms_and_conditions_updated_successfully"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            refreshTermsConditionsData();
            handelCloseEditTermsCondition();
            setTermsConditionFor("");
            setTermsConditionType("");
            setTermsConditionHeading("");
            setTermsConditionDescription("");
            setTermsConditionSerialNumber("");
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );
    };
  
    return (
      <>
        <div className="flotingAreaContainer">
          <div className="flotingAreaClose">
            <div className="facHeader">{t("edit_terms_and_conditions")}</div>
            <IconButton
              className="CloseBtn"
              onClick={() => handelCloseEditTermsCondition()}
            >
              <XLg />
            </IconButton>
          </div>
  
          {loading ? (
            <div className="meetingVEContainer">
              <div className="center-progress" style={{ height: "65vh" }}>
                <CircularProgress sx={{ marginTop: "180px" }} />
              </div>
            </div>
          ) : (
            <div className="elementFormContainer">
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="tCType">{t("t_and_c_for")}*</InputLabel>
                  <Select
                    className="formInputField"
                    variant="outlined"
                    labelId="tCType"
                    id="select-tCType"
                    label={t("t_and_c_for")}
                    value={termsConditionFor}
                    onChange={handleChangeTermsConditionFor}
                  >
                   {termsConditionForData.map((termsType) => (
                      <MenuItem key={termsType.id} value={termsType.id}>
                        {termsType.name}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value={"dd B"}>Deliverable B</MenuItem>
                  <MenuItem value={"dd C"}>Deliverable C</MenuItem> */}
                  </Select>
                </FormControl>
              </div>
  
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="tCType">{t("t_and_c_type")}*</InputLabel>
                  <Select
                    className="formInputField"
                    variant="outlined"
                    labelId="tCType"
                    id="select-tCType"
                    label={t("t_and_c_type")}
                    value={termsConditionType}
                    onChange={handleChangeTermsConditionType}
                  >
                    {termsConditionTypeData.map((termsType) => (
                      <MenuItem key={termsType.id} value={termsType.id}>
                        {termsType.name}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value={"dd"}>Deliverable</MenuItem>
                  <MenuItem value={"dd B"}>Deliverable B</MenuItem>
                  <MenuItem value={"dd C"}>Deliverable C</MenuItem> */}
                  </Select>
                </FormControl>
              </div>
  
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("t_and_c_heading")}
                    variant="outlined"
                    className="formTextFieldArea"
                    value={termsConditionHeading}
                    onChange={handleChangeTermsConditionHeading}
                  />
                </FormControl>
              </div>
  
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("terms_and_condition")}
                    required
                    variant="outlined"
                    className="descptionTextFild"
                    multiline
                    minRows={5}
                    maxRows={12}
                    value={termsConditionDescription}
                    onChange={handleChangeTermsConditiondescription}
                  />
                </FormControl>
              </div>
  
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("serial_number")}
                    variant="outlined"
                    className="formTextFieldArea"
                    value={termsConditionSerialNumber}
                    onChange={handleChangeTermsConditionSerialNumber}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                  />
                </FormControl>
              </div>
            </div>
          )}
  
          <div className="elementFooter">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={() => finalSubmit()}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <>{t("save")}</>
                )}
              </Button>
              {!loading && (
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => handelCloseEditTermsCondition()}
                >
                  {t("cancel")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
  