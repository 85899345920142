import React, { useReducer } from "react";
import { useState } from "react";
import { useEffect } from "react";
import TaskService from "../../../../../services/TaskService";
import DateUtils from "../../../../../utils/DateUtils";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { CloseFullscreen, OpenInFull } from "@mui/icons-material";
import { XLg } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import { IrisScan } from "iconoir-react";
import { PoPreviewDialog } from "./PoPreviewDialog";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import { CustomModal } from "../Modals/CustomModal";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export const PoForm = ({
  taskNature,
  formData,
  handleClose,
  handleSubmitPO,
}) => {
  const fullScreenStyle = {
    width: "100%",
  };
  const { t } = useTranslation();

  const loginUserDetails = getLoginUserDetails();
  const [comparisonDataList, setComparisonDataList] = useState([]);
  const [uniqueVendorList, setUniqueVendorList] = useState([]);
  const [uniqueItemList, setUniqueItemList] = useState([]);
  const [viewOnlyIndDetails, setViewOnlyIndDetails] = useState(undefined);
  const [effectiveDate, setEffectiveDate] = useState(new Date());
  const [poPreviewData, setPoPreviewData] = useState(undefined);
  const [itemLedgerInfoList, setItemLedgerInfoList] = useState([]);
  const [orgLedgerHeadList, setOrgLedgerHeadList] = useState([]);
  const [ledgerChecked, setLedgerChecked] = useState(0);
  const [showItemLedgerDialog, setShowItemLedgerDialog] = useState(false);
  const [showCostCenterUpdate, setShowOpenCostCenterUpdate] = useState(false);

  const [costCenterList, setCostCenterList] = useState([]);
  const [selectedCostCenter, setSelectedCostCenter] = useState();

  const initialState = { isExpanded: false };

  const reducer = (state, action) => {
    switch (action.type) {
      case "expandOrShrinkWindow":
        return { ...state, isExpanded: !state.isExpanded };
      default:
        return { ...state };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (taskNature === "PO_APPROVAL_REQUEST" && formData) {
      TaskService.getQuotationComparisonForApproval(formData.expenseId).then(
        (response) => {
          if (response.data) {
            setViewOnlyIndDetails(response.data);
            const data = response.data.quotations;
            // console.log("getQuotationComparisonForApproval", data);
            let vendorsUniqueByKey = [
              ...new Map(
                data.map((item) => [
                  item["vendorUserId"],
                  {
                    vendorName: item.vendorName,
                    vendorUserId: item.vendorUserId,
                    rfpVendorId: item.rfpVendorId,
                  },
                ])
              ).values(),
            ];
            const itemsUniqueByName = [
              ...new Map(
                data.map((item) => [
                  item["itemId"],
                  { itemId: item.itemId, itemName: item.itemName },
                ])
              ).values(),
            ];

            for (let i = 0; i < vendorsUniqueByKey.length; i++) {
              const { vendorUserId } = vendorsUniqueByKey[i];
              const element = data.find(
                (value) =>
                  value.vendorUserId === vendorUserId &&
                  value.sortedForPo === "Y"
              );
              if (element) {
                vendorsUniqueByKey[i].isPoVisited = false;
              } else {
                vendorsUniqueByKey[i].isPoVisited = null;
              }
            }

            setComparisonDataList(data);
            setUniqueVendorList(vendorsUniqueByKey);
            setUniqueItemList(itemsUniqueByName);
          }
        }
      );
    }
  }, []);

  const handleClickPoPreview = (id) => {
    let tmpUnique = uniqueVendorList;
    const index = tmpUnique.findIndex((obj) => obj.rfpVendorId === id);
    tmpUnique[index].isPoVisited = true;
    setUniqueVendorList(tmpUnique);

    TaskService.getPreviewPoData(id).then((response) => {
      if (response.data) {
        setPoPreviewData(response.data);
      }
    });
  };

  const handleClickVisited = () => {
    const element = uniqueVendorList.find(
      ({ isPoVisited }) => !isPoVisited && isPoVisited != null
    );

    // if (ledgerChecked === 0) {
    //   toast.error(`Please Check All Items With Ledger Head`, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // } else if (element) {
    //   toast.error(`Please Visit The PO of ${element.vendorName} `, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // } else {
    // }
    handleSubmitPO(dayjs(effectiveDate).format("DD-MM-YYYY"));
  };

  const handleSubmitEditLedgerHead = (preValue, newValue, indDtlId) => {
    if (preValue !== newValue) {
      TaskService.updateLedgerPosting(
        indDtlId,
        loginUserDetails.userId,
        newValue
      ).then((response) => {
        if (response.data) {
          if (response.data.returnValue === "1") {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            handelClickViewItemLedgerInfo();
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    }
  };

  const updateCostCenter = () => {
    console.log("selected costcenter id =", selectedCostCenter);

    const costcenterId = selectedCostCenter;
    TaskService.updateCostCenter(
      formData.expenseId,
      selectedCostCenter,
      loginUserDetails.userId
    ).then((response) => {
      if (response.data == 1) {
        toast.success("Cost Center updated successfully");
        // const dtl = { indentDetails };
        // dtl.costCenterId = selectedCostCenter;
        // setIndentDetails(dtl);
        setShowOpenCostCenterUpdate(false);
      } else {
        toast.error("Cost Center Could not be updated");
      }
    });
  };

  const renderItemDetailsViewOnly = (itemId, vendorUserId) => {
    const data = comparisonDataList.find(
      (item) => item.vendorUserId === vendorUserId && item.itemId === itemId
    );
    if (data) {
      return (
        <>
          <th className={data.sortedForPo === "Y" ? `selectedItem` : ``}>
            {data.quotationQty}
          </th>
          <th className={data.sortedForPo === "Y" ? `selectedItem` : ``}>
            {data.quotationRate}
          </th>
        </>
      );
    } else {
      return <th colSpan={3}></th>;
    }
  };

  const handelClickViewItemLedgerInfo = () => {
    TaskService.getItemsWithLeadgerHeadByIndentId(formData.expenseId).then(
      (response) => {
        if (response.data) {
          setItemLedgerInfoList(response.data);
        }
      }
    );
    TaskService.getBudgetHeads(loginUserDetails.orgId).then((response) => {
      if (response.data) {
        setOrgLedgerHeadList(response.data);
      }
    });
    setLedgerChecked((r) => {
      console.log("r = ", r);
      return r + 1;
    });
    setShowItemLedgerDialog(true);
  };

  const handleClickValidateCostCenter = () => {
    TaskService.getInventoryCostCenters(loginUserDetails.orgId).then(
      (response) => {
        setCostCenterList(response.data);
        setShowOpenCostCenterUpdate(true);
      }
    );
    setSelectedCostCenter(formData.costCenterId);
  };

  return (
    <>
      <div
        className="RightFlotingFormContainerArea"
        style={state.isExpanded ? fullScreenStyle : {}}
      >
        <div className="flottingContainer">
          <div className="flotHeaderPanel">
            <div class="flotHedCon">{t("purchase_order_preview")}</div>
            <div class="flotActionBtnGrp">
              <div class="flotActBtn closeBtnD10">
                <Tooltip
                  arrow
                  title={
                    state.isExpanded ? t("exit_full_screen") : t("enter_full_screen")
                  }
                >
                  <IconButton
                    onClick={(e) => {
                      dispatch({ type: "expandOrShrinkWindow" });
                    }}
                  >
                    {state.isExpanded ? <CloseFullscreen /> : <OpenInFull />}
                    {/* <Expand /> */}
                  </IconButton>
                </Tooltip>
              </div>
              <div class="flotActBtn closeBtnD10">
                <IconButton onClick={handleClose}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="flotContain">
            <div className="elementFormContainer  ">
              <div className="indentFormtable">
                <div className="indtableResponsive">
                  <table>
                    <thead>
                      <tr>
                        <th>{t("indent_no")}.</th>
                        <th>{t("contract")}</th>
                        <th>{t("approved_on")}</th>
                        <th>{t("recommendation_reason")}</th>
                      </tr>
                    </thead>
                    {viewOnlyIndDetails && (
                      <tbody>
                        <tr>
                          <th>{viewOnlyIndDetails.indentNo}</th>
                          <th>{viewOnlyIndDetails.contract}</th>
                          <th>
                            {DateUtils.getDateInDDMMYYYY(
                              new Date(viewOnlyIndDetails.approvedOn)
                            )}
                          </th>
                          <th>{viewOnlyIndDetails.recommendationReason}</th>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
                <div className="formElementGroup">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel
                        id="setRepeatUntilDate"
                        className="setCompletionDate"
                      >
                       {t("po_effective_date")}
                      </InputLabel>
                      <ReactDatePicker
                        showIcon
                        labelId="setRepeatUntilDate"
                        className="formDatePicker"
                        minDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        selected={effectiveDate}
                        onChange={(date) => setEffectiveDate(date)}
                      />
                    </FormControl>
                  </div>
                </div>

                <div className="indtableResponsive">
                  <table className="hrActivityGroupTable">
                    <thead>
                      <tr style={{ textAlign: "center" }}>
                        <th>{t("details")}</th>
                        {uniqueVendorList.map((vend) => {
                          return (
                            <th colSpan={2} key={vend.vendorUserId}>
                              <div
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                              >
                                {vend.vendorName}
                                {vend.isPoVisited != null && (
                                  <Tooltip title={t("po_preview")}>
                                    <IrisScan
                                      className={
                                        vend.isPoVisited ? `selectedIcon` : ``
                                      }
                                      cursor={"pointer"}
                                      onClick={() =>
                                        handleClickPoPreview(vend.rfpVendorId)
                                      }
                                    />
                                  </Tooltip>
                                )}
                              </div>
                            </th>
                          );
                        })}
                      </tr>

                      <tr style={{ textAlign: "center" }}>
                        <th>Item Name</th>
                        {uniqueVendorList.map(() => {
                          return (
                            <>
                              <th>{t("quantity")}</th>
                              <th>{t("rate")}</th>
                            </>
                          );
                        })}
                      </tr>
                    </thead>

                    <tbody>
                      {uniqueItemList.map((item) => {
                        return (
                          <tr style={{ textAlign: "center" }}>
                            <th>{item.itemName}</th>
                            {uniqueVendorList.map((vend) => {
                              return renderItemDetailsViewOnly(
                                item.itemId,
                                vend.vendorUserId
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                <div className={`formBtnElement`}>
                  <Button
                    variant="outlined"
                    className={`comBtnOutline `}
                    onClick={handelClickViewItemLedgerInfo}
                  >
                    {t("validate_item_ledger")}
                  </Button>

                  <Button
                    variant="outlined"
                    className={`comBtnOutline `}
                    onClick={handleClickValidateCostCenter}
                  >
                    {t("validate_cost_center")}
                  </Button>

                  <Button
                    variant="outlined"
                    className={`comBtnOutline `}
                    onClick={handleClickVisited}
                  >
                    {t("ok")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {poPreviewData && (
        <PoPreviewDialog
          data={poPreviewData}
          effectiveDate={DateUtils.getDateInDDMMYYYY(effectiveDate)}
          closeDialog={() => setPoPreviewData(undefined)}
        />
      )}

      {showItemLedgerDialog && (
        <CustomModal
          modalTitle={t("compare_quotation")}
          style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowItemLedgerDialog(false);
          }}
          className={"width90vwModel"}
        >
          <div className="modBody">
            <table>
              <thead>
                <tr>
                  <th className="minWidth160">{t("item_name")}</th>
                  <th>{t("item_type")}</th>
                  <th>{t("item_category")}</th>
                  <th>{t("ledger_head")}</th>
                </tr>
              </thead>
              <tbody>
                {itemLedgerInfoList &&
                  itemLedgerInfoList.map((item, index) => (
                    <tr>
                      <td>{item.itemName}</td>
                      <td>{item.itemType}</td>
                      <td>{item.itemCategory}</td>
                      <td>
                        <div className="formElement">
                          <FormControl className="formControl">
                            <InputLabel id="setContract">{t("contract")}</InputLabel>
                            <Select
                              className="formInputField"
                              variant="outlined"
                              labelId="setContract"
                              id="contract-select"
                              label={t("contract")}
                              value={item.budgetHeadId}
                              onChange={(e) =>
                                handleSubmitEditLedgerHead(
                                  item.budgetHeadId,
                                  e.target.value,
                                  item.indentDetailId
                                )
                              }
                            >
                              {orgLedgerHeadList.length > 0 &&
                                orgLedgerHeadList.map((itemName, index) => (
                                  <MenuItem value={itemName.id} key={index}>
                                    {itemName.head}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </CustomModal>
      )}

      {showCostCenterUpdate && (
        <CustomModal
          modalTitle={t("update_cost_center")}
          style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowOpenCostCenterUpdate(false);
          }}
          // className={"width90vwModel"}
        >
          <div className="modBody">
            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="setContract">{t("cost_center")}</InputLabel>
                <Select
                  className="formInputField"
                  variant="outlined"
                  labelId="setContract"
                  id="contract-select"
                  label={t("cost_center")}
                  value={selectedCostCenter}
                  onChange={(e) => {
                    console.log("selected costcenter=", e.target.value);
                    setSelectedCostCenter(e.target.value);
                  }}
                >
                  {costCenterList &&
                    costCenterList.map((costCenter) => {
                      return (
                        <MenuItem value={costCenter.costCenterId}>
                          {costCenter.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="modActionBtnGrp actionsCreateOrg">
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={(e) => {
                updateCostCenter(e);
              }}
            >
             {t("update_cost_center")}
            </Button>
            <Button className="dfultPrimaryBtn">{t("submit")}</Button>
          </div>
        </CustomModal>
      )}
    </>
  );
};
