import { CloseFullscreen, OpenInFull } from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useReducer, useState } from "react";
import { Download, XLg } from "react-bootstrap-icons";
import { useForm } from "react-hook-form";
import { VendorPaymentMethods } from "./VendorPaymentMethods";
import TaskService from "../../../../../services/TaskService";
import { toast } from "react-toastify";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import { downloadFileFromUrl } from "../../../../../utils/FileUtils";
import { useTranslation } from "react-i18next";

export const PaymentAgainstSIP = ({
  taskId,
  formDataTrans,
  taskNature,
  formData,
  handleClose,
}) => {
  const fullScreenStyle = {
    width: "100%",
  };
  const { t } = useTranslation();

  const loginUserDetail = getLoginUserDetails();
  const { register, reset, handleSubmit } = useForm();

  const [description, setDescription] = useState("");
  const [referenceNo, setReferenceNo] = useState("");
  const [showPaymentMehtod, setShowPaymentMehtod] = useState(false);
  const [selectedMethodId, setSelectedMethodId] = useState(0);

  const initialState = { isExpanded: false };
  const reducer = (state, action) => {
    switch (action.type) {
      case "expandOrShrinkWindow":
        return { ...state, isExpanded: !state.isExpanded };
      default:
        return { ...state };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const submitRequest = (data) => {
    let isValid = true;
    if (description.trim() === "") {
      toast.error("Please Provide Remark");
      isValid = false;
    }

    if (referenceNo.trim() === "") {
      toast.error("Please provide reference no");
      isValid = false;
    }

    if (selectedMethodId === 0) {
      toast.error("Please select payment method");
      isValid = false;
    }

    if (isValid) {
      const reqDto = {
        expenseId: formData.expenseId,
        expenseType: "reimbursement",
        paymentMethodId: selectedMethodId,
        paymentAmount: parseInt(formData.estimatedCost),
        notes: description,
        accountHeadId: 0,
        costCenterId: formData.costCenter,
        taskDetailId: taskId,
        orgId: loginUserDetail.orgId,
        referenceNo: referenceNo,
      };

      console.log("requet Dto=", reqDto);
      // return;

      TaskService.releasePayment(
        loginUserDetail.orgId,
        loginUserDetail.userId,
        reqDto
      ).then((response) => {
        // console.log("response =",respo)
        if (response.data.returnValue === "1") {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      });
    }
  };

  return (
    <>
      <div
        className="RightFlotingFormContainerArea"
        style={state.isExpanded ? fullScreenStyle : {}}
      >
        <div className="flottingContainer">
          <div className="flotHeaderPanel">
            <div class="flotHedCon">{t("payment_against_sip")}</div>
            <div class="flotActionBtnGrp">
              <div class="flotActBtn closeBtnD10">
                <Tooltip
                  arrow
                  title={
                    state.isExpanded ? t('exitFullScreen') : t('enterFullScreen')
                  }
                >
                  <IconButton
                    onClick={(e) => {
                      dispatch({ type: "expandOrShrinkWindow" });
                    }}
                  >
                    {state.isExpanded ? <CloseFullscreen /> : <OpenInFull />}
                    {/* <Expand /> */}
                  </IconButton>
                </Tooltip>
              </div>
              <div class="flotActBtn closeBtnD10">
                <IconButton onClick={handleClose}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="flotContain">
            <div className="elementFormContainer previewSpace ">
              <div className="indentFormtable">
                <div className="indtableResponsive">
                  <table>
                    <thead>
                      {/* <tr>
                        <th className="w200">Indent No.</th>
                        <th>Purpose</th>
                        <th>Schedule Date</th>
                        <th className="indntQunt">Created By</th>
                        <th className="widthMin120">Approved By</th>
                      </tr> */}
                      <tr>
                    <th className="w200">{t('indentNo')}</th>
                    <th>{t('purpose')}</th>
                    <th>{t('scheduleDate')}</th>
                    <th className="indntQunt">{t('createdBy')}</th>
                    <th className="widthMin120">{t('approvedBy')}</th>
                  </tr>
                    </thead>
                    <tbody>
                      <td>
                        <div className="textOnly">{formData.expenseNo}</div>
                      </td>
                      <td>
                        <div className="textOnly">{formData.purpose}</div>
                      </td>
                      <td>
                        <div className="textOnly">{formData.scheduledDate}</div>
                      </td>
                      <td>
                        <div className="textOnly">{formData.createdByName}</div>
                      </td>
                      <td>
                        <div className="textOnly">
                          {formData.approvedByName}
                        </div>
                      </td>
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>
                <div style={{ margin: "40px 0" }}></div>
                <div className="indtableResponsive">
                  <table>
                    <thead>
                      {/* <tr>
                        <th className="indntQunt">Types</th>
                        <th className="indntQunt">Budget Head</th>
                        <th className="widthMin120">Due Date</th>
                        <th className="indntQunt">Amount</th>
                        <th className="indntQunt">Description</th>
                        <th className="indntQunt">Document</th>
                      </tr> */}
                       <tr>
          <th className="indntQunt">{t('types')}</th>
          <th className="indntQunt">{t('budgetHead')}</th>
          <th className="widthMin120">{t('dueDate')}</th>
          <th className="indntQunt">{t('amount')}</th>
          <th className="indntQunt">{t('description')}</th>
          <th className="indntQunt">{t('document')}</th>
        </tr>
                    </thead>
                    <tbody>
                      {formData &&
                        formData.sips &&
                        formData.sips.map((item) => {
                          return (
                            <tr>
                              <td>
                                <div className="textOnly">{item.type}</div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {item.budgetHeadName}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">{item.dueDate}</div>
                              </td>
                              <td>
                                <div className="textOnly">{item.amount}</div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {item.description}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {item.sipFileName}
                                  {item.sipFileName &&
                                    item.sipFileName !== "" && (
                                      <IconButton
                                        onClick={() => {
                                          downloadFileFromUrl(
                                            item.sipFileDataLink,
                                            item.sipFileName
                                          );
                                        }}
                                      >
                                        <Download />
                                      </IconButton>
                                    )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>

                <div className="formElement ">
                  <FormControl className="formControl">
                    <TextField
                      required
                      className="descptionTextFild"
                      fullWidth
                      id="outlined-basic"
                      label={t('remark')}
                      variant="outlined"
                      multiline
                      rows={4}
                      minRows={4}
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    />
                  </FormControl>
                </div>

                <div className="formElementGroup">
                  <div className="formElement width13">
                    <FormControl className="formControl">
                      <TextField
                        required
                        fullWidth
                        className="descptionTextFild"
                        id="outlined-basic"
                        label={t('referenceNumber')}
                        variant="outlined"
                        value={referenceNo}
                        size="small"
                        onChange={(e) => {
                          setReferenceNo(e.target.value);
                        }}
                      />
                    </FormControl>
                  </div>

                  <div className="formBtnElement">
                    <Button
                      className={`comBtnOutline  ${
                        selectedMethodId !== 0 ? "activeStatus" : ""
                      }`}
                      onClick={() => {
                        console.log("payment Method Clicked");
                        setShowPaymentMehtod(true);
                      }}
                    >
                     {t('paymentMethod')}
                    </Button>
                  </div>
                </div>
              </div>
              {/* <div className="indentFormtable"></div> */}
            </div>
          </div>

          <div className="flotFooterPanel">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={handleSubmit(submitRequest)}
              >
                {t('approveRequest')}
              </Button>
              <Button className="dfultDarkSecondaryBtn" onClick={handleClose}>
              {t('cancel')}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {showPaymentMehtod && (
        <VendorPaymentMethods
          closeModal={() => {
            setShowPaymentMehtod(false);
          }}
          selectedMethodId={selectedMethodId}
          setSelectedMethodId={setSelectedMethodId}
          vendorUserId={formData.createdById}
        />
      )}
    </>
  );
};
